import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { UsageGatedModal } from './usage-gated-modal';

export const UsageLimitedModal = observer(() => {
  const store = useStore();
  const isOpen = store.ui.openModals.includes('limited');

  if (!isOpen) {
    return null;
  }

  const data = store.ui.modalData.get('limited') || {};

  return <UsageGatedModal open={isOpen} data={data} onClose={() => store.ui.closeModal('limited')} />;
});

import { CHART_COLOR_MAP } from '.';

/**
 * Iterates our chart colors and returns an array of a desired size
 * @param n number of colors to return
 * @returns an array of chart colors
 */
export function getChartColors(n: number): string[] {
  const colors: string[] = [];
  let options: string[] = [];
  while (n > 0) {
    if (!options.length) {
      options = Object.values(CHART_COLOR_MAP);
    }
    const color = options.shift();
    if (color) {
      colors.push(color);
    }
    n -= 1;
  }
  return colors;
}

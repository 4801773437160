export * from './add-champions.gql';
export * from './ask-introduction-to-connection.gql';
export * from './create-composer.gql';
export * from './create-person-note.gql';
export * from './create-persona.gql';
export * from './create-segment.gql';
export * from './delete-person-note.gql';
export * from './delete-persona.gql';
export * from './delete-segment.gql';
export * from './edit-person-note.gql';
export * from './retract-proof.gql';
export * from './set-primary-attribute.gql';
export * from './test-composer-message.gql';
export * from './update-community-info.gql';
export * from './update-community-slug.gql';
export * from './update-segment.gql';
export * from './upsert-persona.gql';
export * from './set-can-receive-references-references.gql';
export * from './upsert-proof.gql';

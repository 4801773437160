import { Column } from '@caravel/components/src';
import { Grid } from '@mui/material';
import { useDualPanels } from 'helpers';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from 'stores';

import { ReactComponent as ExportPeople } from '../../../assets/svgs/align-arrow-down.svg';
import { AppToolbar } from '../../app-toolbar';
import { OrgDetails } from './org-details';
import { OrgPeopleList } from './org-people-list';

export const OrganizationDetail = observer(() => {
  const store = useStore();
  const history = useHistory();
  const previousPage: { from: string } | undefined = history.location.state as {
    from: string;
  };
  const { containerStyle, panelStyle } = useDualPanels();
  const { orgId } = useParams<{ orgId: string }>();

  const inMemoryOrg = store.teams.organizations.get(orgId);
  const org = store.teams.organizations.organization ?? inMemoryOrg;
  const canLoad = store.team && !store.teams.organizations.organization && !store.teams.organizations.loading;

  useEffect(() => {
    return () => {
      store.teams.organizations.resetOrganization();
    };
  }, [store.teams.organizations]);

  useEffect(() => {
    if (!canLoad) return;
    store.ui.workOn(async () => {
      await store.teams.organizations.fetchOrganization(orgId);
    });
  }, [canLoad, orgId, store.teams.organizations, store.ui]);

  if (!org) return null;

  const menuOptions = [
    {
      enabled: true,
      icon: <ExportPeople style={{ width: '20px', height: '20px' }} />,
      label: 'Export people',
      onClick: () => {
        store.ui.workOn(() => store.teams.organizations.exportPeople(orgId));
      },
    },
  ];

  return (
    <Column sx={containerStyle}>
      <AppToolbar title="Organizations" subtitle={org.name} backButton kebabMenuOptions={menuOptions} />
      <Grid container sx={containerStyle}>
        <Grid item xs={12} md={6} lg={5} xl={4} sx={panelStyle}>
          <OrgDetails organization={org} />
        </Grid>
        <Grid item xs={12} md={6} lg={7} xl={8} sx={panelStyle}>
          <OrgPeopleList organization={org} previousPage={previousPage?.from ?? undefined} />
        </Grid>
      </Grid>
    </Column>
  );
});

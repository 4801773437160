import { CaravelSpinner, Column, Flex, Row, TextFieldBorderless } from '@caravel/components/src';
import { Note as NoteType } from '@caravel/types/src';
import { makeInitials, NEUTRALS, PRIMARY_PALETTE } from '@caravel/utils';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  IconButtonProps,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { formatDistance } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useStore } from 'stores';

import { ReactComponent as EditIcon } from '../../assets/svgs/edit-pencil.svg';
import { ReactComponent as TrashIcon } from '../../assets/svgs/trash-can.svg';

function SmallIconButton(props: IconButtonProps) {
  return (
    <IconButton
      sx={{
        padding: 0,
        width: '20px',
        height: '20px',
      }}
      {...props}
    />
  );
}

export interface NoteProps {
  note: NoteType;
  onSubmit: (text: string) => Promise<void>;
  onDelete: () => Promise<void>;
}

export const Note = observer((props: NoteProps) => {
  const { note, onDelete, onSubmit } = props;
  const store = useStore();
  const theme = useTheme();
  const user = store.auth.user?.uid;
  const [showActions, setShowActions] = useState(false);
  const [editing, setEditing] = useState(note.pending);
  const [text, setText] = useState(note.text);
  const [working, setWorking] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const hasChanged = text !== note.text;

  const handleDelete = async () => {
    await onDelete();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {
    setEditing(false);
    if (!hasChanged && note.pending) {
      handleDelete();
    }
    if (!hasChanged) return;
    setWorking(true);
    await onSubmit(text);
    setWorking(false);
  };

  const authorMember = store.teams.teamMembers.get(note.author.firebaseUserId);

  return (
    <Grow in={true}>
      <Row
        borderBottom={`1px solid ${NEUTRALS.JASMINE}`}
        padding="16px 0"
        gap="10px"
        sx={{ position: 'relative' }}
        onMouseEnter={() => {
          if (user !== note.author.firebaseUserId) {
            return;
          }
          setShowActions(true);
        }}
        onMouseLeave={() => setShowActions(false)}
      >
        <Column>
          <Avatar sx={{ width: '30px', height: '30px' }}>
            {makeInitials({
              name: authorMember?.name,
              givenName: note.author.firstName,
              familyName: note.author.lastName,
            })}
          </Avatar>
        </Column>
        <ClickAwayListener
          onClickAway={() => {
            if (editing) {
              handleSubmit();
            }
          }}
        >
          <Column
            flex={1}
            sx={{
              backgroundColor: editing || working ? PRIMARY_PALETTE[50] : 'none',
              border: `1px solid ${editing || working ? PRIMARY_PALETTE[100] : 'transparent'}`,
              borderRadius: '4px',
              padding: '4px',
              gap: '10px',
              transition: 'border .2s, backgroundColor .1s',
            }}
          >
            <Row alignItems="center" gap="5px" sx={{ height: '20px' }}>
              <Typography variant="chip" component="strong">
                {note.author.firstName} {note.author.lastName}
              </Typography>
              <Typography variant="tooltip" color={NEUTRALS.EARL_GREY} fontWeight={500}>
                {formatDistance(new Date(note.createdAt), new Date()) + ' ago'}
              </Typography>
              <Flex flex={1} />
              {showActions && (
                <Row gap="5px">
                  {!note.pending && (
                    <SmallIconButton
                      onClick={() => {
                        setEditing(true);
                        inputRef.current?.focus();
                      }}
                    >
                      <EditIcon width="20px" />
                    </SmallIconButton>
                  )}
                  <SmallIconButton onClick={handleDelete}>
                    <TrashIcon width="20px" />
                  </SmallIconButton>
                </Row>
              )}
            </Row>
            {!editing && (
              <Box
                sx={{
                  padding: '0',
                  lineHeight: 1.5,
                  '& p': {
                    lineHeight: '22px',
                    margin: '0 0 22px',
                  },
                  '& p:last-child': {
                    margin: 0,
                  },
                  '& *': {
                    ...theme.typography.bodySmallest,
                  },
                }}
              >
                <Markdown components={{ a: Link }} remarkPlugins={[remarkGfm]}>
                  {note.text}
                </Markdown>
              </Box>
            )}
            {editing && (
              <TextFieldBorderless
                disabled={!note.pending && !editing}
                inputRef={inputRef}
                customVariant="multiline"
                multiline={true}
                value={text}
                onChange={handleChange}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    handleSubmit();
                  }
                }}
                sx={{
                  '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                    WebkitTextFillColor: 'unset',
                  },
                }}
              />
            )}
            {working && (
              <Flex
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                }}
              >
                <CaravelSpinner />
              </Flex>
            )}
          </Column>
        </ClickAwayListener>
      </Row>
    </Grow>
  );
});

import { ProofTag } from '@caravel/types/src';
import { GREY_PALETTE } from '@caravel/utils';
import { Chip } from '@mui/material';
import React from 'react';

export const ProofTags = ({ tags }: { tags: ProofTag[] | undefined }) => {
  if (!tags) return null;
  return (
    <>
      {tags.map((tag, index) => (
        <Chip
          sx={{
            background: GREY_PALETTE[2],
            borderRadius: '8px',
            marginRight: '4px',
            color: GREY_PALETTE[8],
            padding: '4px 8px',
            marginTop: '4px',
            fontSize: '12px',
            lineHeight: '16px',
          }}
          key={index}
          label={tag.name}
        />
      ))}
    </>
  );
};

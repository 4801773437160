import { AddButton } from '@caravel/components';
import { Column, NarrowColumn, Row } from '@caravel/components/src';
import { FormSectionHeader } from '@caravel/components/src/forms/form-section-header';
import { Segment } from '@caravel/types';
import { BUTTERFLYPEA, CHARCOAL_BLACK, DARK_GREY, NEUTRALS } from '@caravel/utils/src';
import { Alert, Button, MenuItem, SvgIcon, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { TeamMember } from 'models';
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { AppToolbar } from '../app-toolbar';
import { AppsIcon, TargetIcon } from '../segments/segments-icons';
import { SelectBox } from './select-box';

export const NewComposer: FC = observer(() => {
  const store = useStore();
  const {
    ui,
    teams: { segments, channels, teamMembers, composers },
  } = store;

  const [teamMemberOptions, setTeamMemberOptions] = useState([{ value: '', label: '' }]);
  const [segmentsCollection, setSegmentsCollection] = useState<Segment[]>([]);
  const [peopleLoading, setPeopleLoading] = useState(true);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [channelId, setChannelId] = useState<string>('');
  const [segmentId, setSegmentId] = useState<string>('');
  const [messageTitle, setMessageTitle] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');
  const [segmentMemberError, setSegmentMemberError] = useState<number | null>(null);

  const history = useHistory();
  const isStatic = (type?: string) => type === ':segment.kind/static';
  const canSendMessage = !isSendingMessage && segmentId && messageTitle && messageContent && channelId;

  const fetchTeamMembers = () => {
    const mappedTeamMemberOptions: SetStateAction<{ value: string; label: string }[]> = [];
    ui.workOn(async () => {
      await channels.fetchChannels();
      const channelOptions = new Set(
        channels.collection
          .slice()
          .filter(channel => channel.kind === ':slack' && channel.createdBy !== null)
          .map(channel => ({ personId: channel.createdBy, channelName: channel.name, channelId: channel.id })),
      );

      const fromOptions: TeamMember[] = [];
      for (const channel of channelOptions) {
        const personId = channel.personId;
        const found = fromOptions.find(option => option.id === personId);
        if (found) {
          continue;
        }
        const member = personId && teamMembers.get(personId);
        member &&
          mappedTeamMemberOptions.push({
            label: `${member.name} | ${channel.channelName}`,
            value: channel.channelId,
          });
      }
      if (mappedTeamMemberOptions.length > 0) {
        setPeopleLoading(false);
        setTeamMemberOptions(mappedTeamMemberOptions);
      }
    });
  };

  const fetchSegments = () => {
    ui.workOn(async () => {
      await segments.fetchSegments();
      setSegmentsCollection(segments.collection.slice().filter(n => n.id));
    });
  };

  const onSendMessage = async () => {
    if (isSendingMessage) {
      return;
    } else {
      try {
        await store.ui.workOn(async () => {
          await composers.createComposerMessage(messageTitle, messageContent, segmentId, channelId);
          store.notifications.display({
            message: 'Message sent successfully',
            severity: 'success',
            duration: 3000,
          });
          history.push('/composer');
        });
      } catch (e) {
        console.error(e);
        store.notifications.display({
          message: 'Failed to send message',
          severity: 'error',
          duration: 3000,
        });
      } finally {
        setIsSendingMessage(false);
      }
    }
  };

  const onSendTestMessage = async () => {
    if (isSendingMessage) {
      return;
    } else {
      try {
        await store.ui.workOn(async () => {
          await composers.testComposerMessage(messageContent, channelId);
          store.notifications.display({
            message: 'Test Message sent successfully',
            severity: 'success',
            duration: 3000,
          });
        });
      } catch (e) {
        console.error(e);
        store.notifications.display({
          message: 'Failed to send test message',
          severity: 'error',
          duration: 3000,
        });
      } finally {
        setIsSendingMessage(false);
      }
    }
  };

  const handleOnClickSendMessage = () => {
    setIsSendingMessage(true);
    onSendMessage();
  };

  const handleOnSelectSegment = async (selectedSegmentId: string) => {
    setSegmentId(selectedSegmentId);
    if (selectedSegmentId && channelId) {
      const selectedSegment = segmentsCollection.find(segment => segment.id === selectedSegmentId);
      const peopleCountInSegment = selectedSegment?.people?.length || 0;
      try {
        await store.ui.workOn(async () => {
          await composers.segmentChannelMembership(channelId, selectedSegmentId);
          const peopleDifference = peopleCountInSegment - composers.segmentChannelMemberCount.length;
          peopleDifference > 0 ? setSegmentMemberError(peopleDifference) : setSegmentMemberError(null);
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    fetchTeamMembers();
    fetchSegments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column sx={{ height: '100%' }}>
      <AppToolbar title="Composer message" backButton />
      <NarrowColumn sx={{ maxWidth: '799px', margin: '65px auto' }}>
        <FormSectionHeader
          title={'Compose a Message'}
          subtitle={'Send a bulk message to your community'}
          info={'More information about this section'}
          divider={true}
        />
        <Typography fontSize={20}>From</Typography>
        <Typography fontSize={14} color={NEUTRALS.OOLONG} marginTop="10px" marginBottom="24px">
          This message will be sent from the person who connected your Slack integration.
        </Typography>
        <SelectBox disabled={peopleLoading || isSendingMessage} value={channelId}>
          <MenuItem value="" disabled sx={{ padding: '8px 12px', fontSize: '13px' }}>
            Select a Sender
          </MenuItem>
          {teamMemberOptions.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ padding: '8px 12px', fontSize: '13px' }}
              onClick={() => setChannelId(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </SelectBox>
        <Typography fontSize={20} marginTop="30px" marginBottom="24px">
          To
        </Typography>
        <Typography fontSize={14} marginBottom="10px" fontWeight="500">
          Select a segment
        </Typography>
        <SelectBox disabled={segments.loading || isSendingMessage || !channelId} value={segmentId ? segmentId : ''}>
          <MenuItem value="" disabled sx={{ padding: '8px 12px', fontSize: '13px' }}>
            Select a Segment
          </MenuItem>
          {segmentsCollection.length > 0 &&
            segmentsCollection.map(segment => (
              <MenuItem
                key={segment.id}
                value={segment.id}
                sx={{ minHeight: '60px', padding: '8px', width: '100%' }}
                onClick={() => handleOnSelectSegment(segment.id)}
              >
                <Row alignItems="center" sx={{ width: 'inherit' }}>
                  <SvgIcon
                    component={isStatic(segment.kind) ? AppsIcon : TargetIcon}
                    sx={{ fill: 'none', marginRight: '8px' }}
                  />
                  <Column sx={{ width: '96%' }}>
                    <div
                      style={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                      }}
                    >
                      {segment.name}
                    </div>
                    {segment.description && (
                      <span
                        className="segment-description"
                        style={{
                          fontSize: '12px',
                          marginTop: '8px',
                          color: NEUTRALS.OOLONG,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%',
                        }}
                      >
                        {segment.description}
                      </span>
                    )}
                  </Column>
                </Row>
              </MenuItem>
            ))}
        </SelectBox>
        {segmentMemberError && (
          <Alert
            severity="info"
            sx={{
              marginTop: '20px',
              fontWeight: '500',
              fontSize: '13px',
              border: `2px solid ${BUTTERFLYPEA[300]}`,
              borderRadius: '5px',
              background: BUTTERFLYPEA[50],
              color: BUTTERFLYPEA[300],
              maxHeight: '40px',
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                color: BUTTERFLYPEA[300],
              },
            }}
          >
            {segmentMemberError} member(s) of this segment are not currently in your workspace and so will not get this
            message.
          </Alert>
        )}
        <Typography fontSize={20} marginTop="36px" marginBottom="30px">
          Message
        </Typography>
        <Typography fontSize={14} marginBottom="4px" fontWeight="500">
          Message title
        </Typography>
        <Typography fontSize={13} color={NEUTRALS.OOLONG} marginBottom="10px">
          This is not captured in your sent message
        </Typography>
        <TextField
          autoFocus
          disabled={isSendingMessage}
          placeholder="Message title"
          onChange={e => setMessageTitle(e.currentTarget.value)}
          inputProps={{
            sx: {
              boxSizing: 'border-box',
              fontSize: 14,
              height: 48,
            },
          }}
          sx={{
            '& label.Mui-focused': {
              color: `${NEUTRALS.IRISH_BREAKFAST}`,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: `${NEUTRALS.IRISH_BREAKFAST}`,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: `${NEUTRALS.IRISH_BREAKFAST}`,
              },
              '&:hover fieldset': {
                borderColor: `${NEUTRALS.IRISH_BREAKFAST}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: `${NEUTRALS.IRISH_BREAKFAST}`,
              },
            },
          }}
        />

        <Typography fontSize={14} marginBottom="4px" marginTop="20px" fontWeight="500">
          Enter your message
        </Typography>
        <Typography fontSize={13} color={NEUTRALS.OOLONG} marginBottom="10px">
          You can style messages and add a dynamic name using Slack’s API Docs.
        </Typography>
        <TextField
          placeholder="Hello,"
          multiline
          minRows={5}
          disabled={isSendingMessage}
          onChange={e => setMessageContent(e.currentTarget.value)}
          inputProps={{
            sx: {
              height: 'unset',
              fontSize: '14px',
              '::placeholder': {
                fontSize: '14px',
              },
            },
          }}
          sx={{
            '& label.Mui-focused': {
              color: `${NEUTRALS.IRISH_BREAKFAST}`,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: `${NEUTRALS.IRISH_BREAKFAST}`,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: `${NEUTRALS.IRISH_BREAKFAST}`,
              },
              '&:hover fieldset': {
                borderColor: `${NEUTRALS.IRISH_BREAKFAST}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: `${NEUTRALS.IRISH_BREAKFAST}`,
              },
            },
          }}
        />
        <Row
          minHeight="76px"
          marginTop="20px"
          marginBottom="30px"
          padding="15px"
          borderRadius="5px"
          style={{
            background: NEUTRALS.CHAMOMILE,
          }}
          alignItems="center"
        >
          <Column width="610px">
            <Typography fontSize={14} fontWeight="600" color={CHARCOAL_BLACK} lineHeight="1.8">
              Send a test message
            </Typography>
            <Typography fontSize={14} fontWeight="500" marginTop="10px" color={DARK_GREY} lineHeight="1.8">
              Make sure everything looks good. Send a test message to yourself.
            </Typography>
          </Column>
          <AddButton
            startIcon=""
            sx={{
              width: '149px',
              height: '32px',
              padding: '6px 12px',
              lineHeight: '1',
              fontSize: '12px',
            }}
            onClick={onSendTestMessage}
          >
            Send test message
          </AddButton>
        </Row>
        <Row justifyContent="flex-end" marginBottom="96px">
          <Button
            variant="contained"
            disabled={!canSendMessage}
            sx={{
              width: '132px',
              height: '48px',
              border: 'none',
              lineHeight: '1',
              padding: '14px 20px',
              '&:disabled': {
                background: NEUTRALS.JASMINE,
                color: NEUTRALS.EARL_GREY,
              },
            }}
            onClick={handleOnClickSendMessage}
          >
            Send Message
          </Button>
        </Row>
      </NarrowColumn>
    </Column>
  );
});

import { Column, Flex } from '@caravel/components/src';
import { Divider, styled, TextField, Typography } from '@mui/material';

import CommsorGarden from '../../assets/images/onboarding/commsor-garden.png';

export const OnboardingBg = styled(Column)(() => ({
  height: '100vh',
  width: '100%',
  backgroundImage: `url(${CommsorGarden})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

export const OnboardingTextField = styled(TextField)({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: 18,
      '& fieldset': {
        borderColor: '#231F20',
        borderRadius: 10,
        borderWidth: 2,
      },
    },
  },
});

export const OnboardingMain = styled(Flex)(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflowY: 'scroll',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

export const OnboardingMainContent = styled(Flex)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  maxWidth: 400,
  padding: theme.spacing(8, 2),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 2, 2, 2),
  },
}));

export const OnboardingMessage = styled(Flex)(({ theme }) => ({
  fontSize: 20,
  marginBottom: theme.spacing(6),
}));

export const OnboardingH2 = styled(Typography)(({ theme }) => ({
  fontSize: 34,
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    fontSize: 32,
  },
}));

export const OnboardingImage = styled('span')({
  marginRight: '10px',
});

export const OnboardingDividerContainer = styled(Flex)(() => ({
  position: 'relative',
  flexDirection: 'row',
  alignItems: 'center',
  height: 22,
  width: '100%',
}));

export const OnboardingDivider = styled(Divider)(() => ({
  backgroundColor: '#B4BED4',
  flex: 1,
  width: '100%',
}));

export const OnboardingDividerText = styled(Typography)(({ theme }) => ({
  color: '#B4BED4',
  fontWeight: 500,
  fontSize: 18,
  textAlign: 'center',
  padding: theme.spacing(0, 1),
}));

export const OnboardingBody2 = styled(Typography)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(2),
  backgroundColor: `rgba(0, 0, 0, 0.05)`,
  borderRadius: 10,
  fontSize: 18,
}));

export const OnboardingLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

export const OnboardingDarkLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

import { gql } from '@apollo/client';
import { ChampionReferenceStatus, Organization } from '@caravel/types';

export const GET_CHAMPIONS_REFERENCES = gql`
  query getReferences(
    $referenceId: String
    $championId: String
    $prospectId: String
    $status: championReferenceStatus
  ) {
    community {
      references(referenceId: $referenceId, championId: $championId, prospectId: $prospectId, status: $status) {
        id
        message
        prospect {
          id
          name
          givenName
          familyName
          employmentTitle
          primaryOrganization {
            id
            name
          }
          email
          avatar
        }
        champion {
          id
          name
          givenName
          familyName
          employmentTitle
          primaryOrganization {
            id
            name
          }
          email
          avatar
          referenceCount
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export interface GqlChampionsReferencesRequestType {
  championId?: string;
  referenceId?: string;
  prospectId?: string;
  status?: ChampionReferenceStatus;
}

export interface GqlChampionsReferencesResponseType {
  community: {
    references: {
      id: string;
      message: string;
      prospect?: {
        id: string;
        name: string;
        givenName: string;
        familyName: string;
        employmentTitle: string;
        primaryOrganization?: Organization;
        email: string;
        avatar: string;
      };
      champion?: {
        id: string;
        name: string;
        givenName: string;
        familyName: string;
        employmentTitle: string;
        primaryOrganization?: Organization;
        email: string;
        avatar: string;
        referenceCount: number;
      };
      status: ChampionReferenceStatus;
      createdAt: Date;
      updatedAt: Date;
    }[];
  };
}

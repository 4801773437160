import { Column, Row } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { ProofTagsInput } from './proof-tags-input';

export const CaseStudyForm = observer(() => {
  const store = useStore();
  const { ui } = store;
  const form = ui.ProofForm;

  const formProps = form.formProps;
  if (!formProps) {
    return null;
  }
  return (
    <Column sx={{ gap: 2, width: '100%', marginTop: '20px' }}>
      <Row>
        <FormInput
          label={'Description or title'}
          value={formProps.content}
          required
          error={form.showErrors && form.contentInvalid}
          helperText={form.showErrors && form.contentInvalid ? 'Please enter a description or title' : ''}
          placeholder={'Description or title'}
          fullWidth
          multiline
          onChange={e =>
            form.changeProof({
              ...formProps,
              content: e.target.value,
            })
          }
        />
      </Row>
      <Row>
        <FormInput
          label={'URL'}
          value={formProps.sourceLink}
          required
          error={form.showErrors && form.sourceLinkInvalid}
          helperText={form.showErrors && form.sourceLinkInvalid ? 'Please enter a valid link' : ''}
          placeholder={'Link to article'}
          fullWidth
          onChange={e =>
            form.changeProof({
              ...formProps,
              sourceLink: e.target.value,
            })
          }
        />
      </Row>
      <Row>
        <FormInput
          label={'Organization'}
          value={formProps.organizationName}
          placeholder={'Gong'}
          fullWidth
          aria-label={'organization name'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              organizationName: e.target.value,
            })
          }
        />
      </Row>
      <ProofTagsInput />
    </Column>
  );
});

import { NotificationProps } from '@caravel/types';
import { sleepAsync, uuid } from '@caravel/utils';
import { collection, deleteDoc, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { DependentDisposer, getDb } from 'helpers';
import { runInAction } from 'mobx';
import { Notification, NotificationTemplates, PENDING_ID, Team } from 'models';
import { FirebaseStore } from 'stores/base';
import { RootStore } from 'stores/root';

export interface LocalNotificationProps {
  severity?: 'success' | 'info' | 'error';
  resourceType?: string;
  resourceName?: string;
  templateName?: NotificationTemplates;
  duration?: number;
  message?: string;
}

export class NotificationStore extends FirebaseStore<Notification, NotificationProps> {
  private teamSubs = new DependentDisposer(() => this.rootStore.team, 'team-subs');

  constructor(rootStore: RootStore) {
    super({ rootStore, modelConstructor: Notification });
  }

  async setup() {
    await super.setup();
    await this.teamSubs.initialize();
    this.teamSubs.addEffect('notifications', this.subToNotifications);
    this.events.on('added', id => {
      const notification = this.get(id);
      if (notification?.autoDismiss) {
        setTimeout(() => this.dismiss(notification), 5000);
      }
    });
  }

  teardown() {
    this.teamSubs.teardown();
    super.teardown();
  }

  display = async (localProps: LocalNotificationProps) => {
    const { severity = 'info', duration = 6000, message, templateName, ...rest } = localProps;
    try {
      const { teamId, userId } = await this.getTeamAndToken();
      let taskStatus: string;
      switch (severity) {
        case 'error':
          taskStatus = 'failed';
          break;
        case 'info':
          taskStatus = 'closed';
          break;
        case 'success':
          taskStatus = 'completed';
          break;
      }
      const notification = new Notification({
        ...rest,
        initiatedBy: userId,
        teamId,
        resourceId: `local-${uuid()}`,
        resourceType: rest.resourceType || 'none',
        taskId: PENDING_ID,
        taskStatus,
        taskType: '',
        customMessage: message,
        templateName: message ? 'custom' : templateName,
      });
      if (notification.id === PENDING_ID) {
        runInAction(() => (notification.id = uuid()));
      }
      this.addToCollection(notification);
      if (duration > 0) {
        setTimeout(() => this.dismiss(notification), duration);
      }
      return notification;
    } catch (e) {
      console.error('Error displaying notification', e);
    }
  };

  displaySuccess = async (message: string, localProps: LocalNotificationProps = {}) => {
    return this.display({ duration: 5000, ...localProps, severity: 'success', message });
  };

  displayError = async (message: string, localProps: LocalNotificationProps = {}) => {
    return this.display({ duration: 5000, ...localProps, severity: 'error', message });
  };

  dismiss = async (notification: Notification) => {
    runInAction(() => {
      notification.dismissing = true;
    });
    await sleepAsync(150); // give the component animation time to run
    try {
      await deleteDoc(notification.docRef);
    } finally {
      this.remove(notification.id);
    }
  };

  private subToNotifications = (team: Team) => {
    const notificationsRef = collection(getDb(), team.docRef.path, 'notifications');
    return onSnapshot(
      query(
        notificationsRef,
        where('initiatedBy', 'in', ['global', this.rootStore.members.current!.id]),
        orderBy('scheduledAt', 'asc'),
      ),
      this.updateFromSnapshot,
    );
  };
}

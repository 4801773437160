import { GREY_PALETTE, PRIMARY_PALETTE, WARM_SHADES_PALETTE } from '@caravel/utils';
import { Skeleton, StandardTextFieldProps, TextField, Typography } from '@mui/material';
import React from 'react';

import { Column } from '../flex';
import { FormLabel } from './form-label';
import { TextFieldWithStartAdornment } from './text-field-with-start-adornment';

/*
 * MUI TextField Wrapper with extra customizations
 */
export interface FormInputProps extends StandardTextFieldProps {
  prefixText?: string; // For start adornment
  description?: string;
  loading?: boolean;
}

// eslint-disable-next-line complexity
export const FormInput = (props: FormInputProps) => {
  return (
    <Column sx={{ gap: '4px' }} width={props.fullWidth ? '100%' : 'auto'}>
      {props.label && !props.loading && (
        <FormLabel required={props.required} sx={{ opacity: props.disabled ? 0.5 : 1.0 }}>
          {props.label}
        </FormLabel>
      )}
      {props.label && props.loading && (
        <Skeleton>
          <FormLabel required={props.required} sx={{ opacity: props.disabled ? 0.5 : 1.0 }}>
            {props.label}
          </FormLabel>
        </Skeleton>
      )}
      {props.description && !props.loading && (
        <Typography
          variant="inputLabelLine"
          color={WARM_SHADES_PALETTE[3]}
          sx={{ opacity: props.disabled ? 0.5 : 1.0 }}
        >
          {props.description}
        </Typography>
      )}
      {props.description && props.loading && (
        <Skeleton>
          <Typography
            variant="inputLabelLine"
            color={WARM_SHADES_PALETTE[3]}
            sx={{ opacity: props.disabled ? 0.5 : 1.0 }}
          >
            {props.description}
          </Typography>
        </Skeleton>
      )}
      {props.prefixText && !props.loading && (
        <TextFieldWithStartAdornment
          {...{
            ...props,
            label: undefined,
            inputProps: {
              style: {
                fontSize: '14px',
                padding: '15px 16px',
                backgroundColor: props.disabled ? GREY_PALETTE[1] : 'white',
              },
            },
            sx: {
              marginTop: '6px',
            },
          }}
        />
      )}
      {props.prefixText && props.loading && (
        <Skeleton>
          <TextFieldWithStartAdornment
            {...{
              ...props,
              label: undefined,
              inputProps: {
                style: {
                  fontSize: '14px',
                  padding: '15px 16px',
                  backgroundColor: props.disabled ? GREY_PALETTE[1] : 'white',
                },
              },
              sx: { marginTop: '6px' },
            }}
          />
        </Skeleton>
      )}
      {!props.prefixText && !props.loading && (
        <TextField
          {...{
            ...props,
            label: undefined,
            inputProps: {
              style: {
                fontSize: '14px',
                padding: props.multiline ? '4px 4px' : '15px 16px',
                height: props.multiline ? '95px' : '1.4375em',
                backgroundColor: props.disabled ? GREY_PALETTE[1] : 'white',
              },
            },
            sx: {
              marginTop: '6px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: GREY_PALETTE[4] },
                '&:hover fieldset': {
                  borderColor: PRIMARY_PALETTE[200],
                },
                '&:focused fieldset': {
                  borderColor: GREY_PALETTE[5],
                },
                '&.Mui-focused fieldset': {
                  borderColor: PRIMARY_PALETTE[200],
                },
                input: {
                  '&::placeholder': {
                    color: GREY_PALETTE[4],
                    opacity: 1,
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                  },
                },
              },
            },
          }}
        />
      )}
      {!props.prefixText && props.loading && (
        <Skeleton sx={{ width: '100%', maxWidth: 'none' }}>
          <TextField
            {...{
              ...props,
              label: undefined,
              inputProps: {
                style: {
                  fontSize: '14px',
                  padding: props.multiline ? '4px 4px' : '15px 16px',
                  height: props.multiline ? '95px' : '1.4375em',
                  backgroundColor: props.disabled ? GREY_PALETTE[1] : 'white',
                },
              },
              sx: {
                marginTop: '6px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: GREY_PALETTE[4] },
                  '&:hover fieldset': {
                    borderColor: PRIMARY_PALETTE[200],
                  },
                  '&:focused fieldset': {
                    borderColor: GREY_PALETTE[5],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: PRIMARY_PALETTE[200],
                  },
                  input: {
                    '&::placeholder': {
                      color: GREY_PALETTE[4],
                      opacity: 1,
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '22px',
                    },
                  },
                },
              },
            }}
          />
        </Skeleton>
      )}
    </Column>
  );
};

import { getEditedProps } from '@caravel/helpers/src/comparison';
import {
  Person as PersonProps,
  Segment as SegmentProps,
  SegmentRule as SegmentRuleProps,
  SegmentRuleCondition as SegmentRuleConditionProps,
  SegmentRuleConditionOperator as SegmentRuleConditionOperatorProps,
} from '@caravel/types/src';
import {
  CREATE_SEGMENT,
  createGQLClient,
  GET_COMMUNITY,
  GqlCommunityRequestType,
  GqlCommunityResponseType,
  GqlCreateSegmentRequestType,
  GqlCreateSegmentResponseType,
  GqlUpdateSegmentRequestType,
  GqlUpdateSegmentResponseType,
  SegmentInterConditionOperator,
  SegmentInterRuleOperator,
  SegmentRuleCondition,
  SegmentRuleConditionField,
  SegmentRuleConditionOperator,
  UPDATE_SEGMENT,
} from '@caravel/utils';
import cloneDeep from 'lodash/cloneDeep';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Person } from 'models/person';

import { BaseStore } from '../base';
import { RootStore } from '../root';
const host = process.env.GRAPHQL_HOST!;

export class SegmentForm extends BaseStore {
  initialProps?: Partial<SegmentProps> = undefined;
  formProps?: Partial<SegmentProps> = undefined;
  loading = false;
  creating = false;
  updating = false;
  newSegmentModalOpen = false;

  get isValid(): boolean {
    switch (this.formProps?.kind) {
      case ':segment.kind/static':
        return Boolean(this.formProps!.name && this.formProps!.people!.length > 0);
      case ':segment.kind/dynamic':
        return Boolean(
          this.formProps.name &&
            this.formProps.segmentRules?.length &&
            // every condition on every rule has field, operator, values.length
            this.formProps.segmentRules.every(
              rule =>
                rule.conditions.length &&
                rule.conditions.every(condition => condition.field && condition.operator && condition.values.length),
            ),
        );
      case ':segment.kind/icp':
        return Boolean(
          this.formProps.segmentRules?.length &&
            // every condition on every rule has field, operator, values.length
            this.formProps.segmentRules.every(
              rule =>
                rule.conditions.length &&
                rule.conditions.every(condition => condition.field && condition.operator && condition.values.length),
            ),
        );
      default:
        return false;
    }
  }

  get changedProps(): string[] {
    if (!this.formProps || !this.initialProps) {
      return [];
    }
    return getEditedProps(this.formProps, this.initialProps, ['clearCache', 'getCachedProp', 'toJS', 'update']);
  }

  get hasChanges() {
    if (!this.formProps && !this.initialProps) {
      return false;
    }
    if (this.formProps && !this.initialProps) {
      return true;
    }
    return this.changedProps.length > 0;
  }

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      initialProps: observable,
      formProps: observable,
      creating: observable,
      updating: observable,
      loading: observable,
      newSegmentModalOpen: observable,
      isValid: computed,
      hasChanges: computed,
    });
  }

  createGraphQLClient = async () => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    return createGQLClient(teamId, token, host);
  };

  newStaticSegment = () => {
    const props: Partial<SegmentProps> = {
      name: '',
      description: '',
      kind: ':segment.kind/static',
      people: [],
    };

    this.resetFormProps();
    this.setSegment(props);
  };

  newDynamicSegment = () => {
    const props: Partial<SegmentProps> = {
      name: '',
      description: '',
      kind: ':segment.kind/dynamic',
      segmentRules: [initialRule],
    };

    this.resetFormProps();
    this.setSegment(props);
  };

  newICPSegment = () => {
    const props: Partial<SegmentProps> = {
      name: 'Ideal Customer Profile',
      description: '',
      kind: ':segment.kind/icp',
      segmentRules: [initialRule],
    };

    this.resetFormProps();
    this.setSegment(props);
  };

  editSegment = (segmentId: string) => {
    const segment = this.rootStore.teams.segments.collection.find(n => n.id === segmentId);

    if (segment) {
      this.resetFormProps();
      this.setSegment(segment);
    }
  };

  editICPSegment = async () => {
    await this.rootStore.teams.segments.fetchICPSegment();
    const segment = this.rootStore.teams.segments.icpSegment;

    if (segment) {
      this.resetFormProps();
      this.setSegment(segment);
    } else this.newICPSegment();
  };

  onChange = (segment: Partial<SegmentProps>) => {
    if (!this.formProps) {
      return;
    }
    runInAction(() => {
      this.formProps = {
        ...this.formProps,
        ...segment,
      };
    });
  };

  setSegment = (segment: Partial<SegmentProps>) => {
    const cloned = cloneDeep(segment);
    runInAction(() => {
      this.initialProps = cloned;
      this.formProps = {
        ...segment,
      };
    });
  };

  // Rules
  addRule = () => {
    const newRules = this.formProps!.segmentRules!.slice();
    newRules.push(initialRule);
    runInAction(() => {
      this.formProps!.segmentRules = newRules;
    });
  };

  removeRule = (index: number) => {
    const newRules = this.formProps!.segmentRules!.slice();
    newRules.splice(index, 1);
    runInAction(() => {
      this.formProps!.segmentRules = newRules;
    });
  };

  onRuleChange = (rule: Partial<SegmentRuleProps>, index: number) => {
    const newRules = this.formProps!.segmentRules!.slice();
    newRules[index] = {
      ...newRules[index],
      ...rule,
    };

    runInAction(() => {
      this.formProps!.segmentRules = newRules;
    });
  };

  // Conditions

  addCondition = (ruleIndex: number) => {
    const conditions = this.formProps!.segmentRules![ruleIndex].conditions.slice();
    conditions.push(initialCondition);
    runInAction(() => {
      this.formProps!.segmentRules![ruleIndex].conditions = conditions;
    });
  };

  removeCondition = (ruleIndex: number, conditionIndex: number) => {
    const conditions = this.formProps!.segmentRules![ruleIndex].conditions.slice();
    conditions.splice(conditionIndex, 1);
    runInAction(() => {
      this.formProps!.segmentRules![ruleIndex].conditions = conditions;
    });
  };
  onConditionChange = (condition: Partial<SegmentRuleConditionProps>, ruleIndex: number, conditionIndex: number) => {
    runInAction(() => {
      this.formProps!.segmentRules![ruleIndex].conditions[conditionIndex] = {
        ...this.formProps!.segmentRules![ruleIndex].conditions[conditionIndex],
        ...condition,
      };
    });
  };

  resetFormProps = () => {
    runInAction(() => {
      this.formProps = undefined;
      this.initialProps = undefined;
    });
  };
  openModal = () => {
    runInAction(() => {
      this.newSegmentModalOpen = true;
    });
  };

  closeModal = () => {
    runInAction(() => {
      this.newSegmentModalOpen = false;
    });
  };

  fetchPeople = async (query: string, callback: (results: PersonProps[]) => void) => {
    const graphqlClient = await this.createGraphQLClient();
    runInAction(() => (this.loading = true));
    try {
      const response = await graphqlClient.query<GqlCommunityRequestType, GqlCommunityResponseType>(GET_COMMUNITY, {
        query,
      });
      const people: Person[] = response.community.people.edges
        ?.filter(edge => edge.node)
        .map(edge => new Person(edge.node));

      runInAction(() => {
        this.loading = false;
      });
      return callback(people);
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to fetch people');
    }
  };

  mapSegmentRules = (segmentRules: SegmentRuleProps[]) => {
    return segmentRules.map(({ interConditionOperator, interRuleOperator, conditions }) => {
      return {
        interRuleOperator: `:segment.rule.inter-rule-operator/${interRuleOperator}` as SegmentInterRuleOperator,
        interConditionOperator:
          `:segment.rule.conditions.inter-condition-operator/${interConditionOperator}` as SegmentInterConditionOperator,
        conditions: conditions.map((condition): SegmentRuleCondition => {
          return {
            field: kebabCaseToCamelCase(condition.field) as SegmentRuleConditionField,
            operator: formatOperator(condition.operator),
            values: formatValues(condition),
          };
        }),
      };
    });
  };

  createSegment = async () => {
    const graphqlClient = await this.createGraphQLClient();
    runInAction(() => (this.creating = true));
    try {
      const response = await graphqlClient.query<GqlCreateSegmentRequestType, GqlCreateSegmentResponseType>(
        CREATE_SEGMENT,
        {
          input: {
            name: this.formProps?.name,
            description: this.formProps?.description,
            segmentKind: this.formProps?.kind,
            memberIds:
              this.formProps?.kind === ':segment.kind/static'
                ? this.formProps?.people?.map(person => person.id)
                : undefined,
            segmentRules: this.formProps?.segmentRules ? this.mapSegmentRules(this.formProps.segmentRules) : undefined,
          },
        },
      );
      const id = response.upsertSegment.result.id;
      runInAction(() => {
        this.creating = false;
      });
      this.rootStore.notifications.display({
        severity: 'success',
        message: `Successfully created ${this.formProps?.name}`,
        duration: 5000,
      });
      return id;
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError(`Failed to create ${this.formProps?.name}`);
      runInAction(() => (this.creating = false));
    }
  };

  updateSegment = async () => {
    const graphqlClient = await this.createGraphQLClient();
    runInAction(() => (this.updating = true));
    try {
      const response = await graphqlClient.query<GqlUpdateSegmentRequestType, GqlUpdateSegmentResponseType>(
        UPDATE_SEGMENT,
        {
          input: {
            id: this.formProps?.id,
            name: this.formProps?.name,
            description: this.formProps?.description,
            segmentKind: this.formProps?.kind,
            memberIds:
              this.formProps?.kind === ':segment.kind/static'
                ? this.formProps?.people?.map(person => person.id)
                : undefined,
            segmentRules: this.formProps?.segmentRules ? this.mapSegmentRules(this.formProps.segmentRules) : undefined,
          },
        },
      );
      const id = response.upsertSegment.result.id;
      runInAction(() => {
        this.updating = false;
        this.initialProps = cloneDeep(this.formProps);
      });
      this.rootStore.notifications.display({
        severity: 'success',
        message: `Successfully updated ${this.formProps?.name}`,
        duration: 5000,
      });
      return id;
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError(`Failed to update ${this.formProps?.name}`);
      runInAction(() => (this.updating = false));
    }
  };
}

const initialCondition: SegmentRuleConditionProps = {
  field: 'layer',
  operator: 'is',
  values: [],
};

const initialRule: SegmentRuleProps = {
  interConditionOperator: 'all',
  interRuleOperator: 'and',
  conditions: [initialCondition],
};

const kebabCaseToCamelCase = (s: string) => s.replace(/-./g, x => x[1].toUpperCase());
const titleCaseToCamelCase = (s: string) => {
  return s[0].toLocaleLowerCase() + s.slice(1).replace(/ ./g, x => x[1].toUpperCase());
};
function formatDisplayToISOString(date: string): string {
  switch (date) {
    case '1 day ago':
      return ':1-day-ago';
    case '1 week ago':
      return ':1-week-ago';
    case '1 month ago':
      return ':1-month-ago';
    case '3 months ago':
      return ':3-months-ago';
    default:
      if (isNaN(Date.parse(date))) return date;
      return new Date(date).toISOString();
  }
}

const formatValues = (condition: SegmentRuleConditionProps) => {
  switch (condition.field) {
    case 'last-active':
    case 'first-active':
      // case 'signup-date':
      return condition.values.map(value => formatDisplayToISOString(value));
    case 'consistency':
    case 'frequency':
    case 'impact':
    case 'layer':
    case 'seniority':
      return condition.values.map(value => titleCaseToCamelCase(value));
    case 'grid-status':
      return condition.values.map(value => (value === 'Super User' ? 'superfan' : titleCaseToCamelCase(value)));
    case 'source':
    case 'organization':
    case 'title':
    case 'city':
    case 'country':
    default:
      return condition.values;
  }
};

const formatOperator = (operator: SegmentRuleConditionOperatorProps): SegmentRuleConditionOperator => {
  switch (operator) {
    case 'any-of':
    case 'not':
    case 'not-any-of':
    case 'greater-than':
    case 'less-than':
      return `:segment.rule.condition.operator/is-${operator}`;
    case 'includes-any-of':
    case 'includes-all-of':
    case 'includes-none-of':
    case 'is':
    case 'after':
    case 'before':
    default:
      return `:segment.rule.condition.operator/${operator}`;
  }
};

import { Column, Row } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { ProofSourceType } from '@caravel/types/src';
import { GREY_PALETTE } from '@caravel/utils';
import { styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from 'stores';

import { LinkedInIcon, TwitterIcon } from '../source-icons';
import { ProofTagsInput } from './proof-tags-input';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    display: 'flex-row',
    gap: 2,
    justifyContent: 'center',
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
    '&.MuiToggleButton-root': {
      textTransform: 'none',
      width: '100%',
      flex: 'flex-row',
      padding: '8px',
      justifyContent: 'start',
      margin: 0,
      gap: '16px',
      border: `1px solid ${GREY_PALETTE[2]}`,
      '&.Mui-selected': {
        backgroundColor: GREY_PALETTE[2],
        border: `1px solid ${GREY_PALETTE[3]}`,
      },
    },
  },
}));

const socialMediaDisplayNames = {
  TWITTER: 'Twitter',
  LINKEDIN: 'LinkedIn',
};

const getURlLabelCopy = (socialMediaType: string) => {
  const displayNames = new Map(Object.entries(socialMediaDisplayNames));
  const display = displayNames.get(socialMediaType);
  return `${display} post URL`;
};

export const SocialMediaForm = observer(() => {
  const store = useStore();
  const { ui } = store;
  const [socialMediaType, setSocialMediaType] = React.useState(Object.keys(socialMediaDisplayNames)[0]);
  const form = ui.ProofForm;

  const handleSocialMediaType = (event: React.MouseEvent<HTMLElement>, selected: string) => {
    form.changeProof({ sourceType: selected as ProofSourceType });
    setSocialMediaType(selected);
  };

  const formProps = form.formProps;
  if (!formProps) {
    return null;
  }
  return (
    <Column sx={{ gap: 2, width: '100%', marginTop: '20px' }}>
      <Row>
        <FormInput
          label={'Customer name'}
          value={formProps.customerName}
          required
          placeholder={'Fullname'}
          fullWidth
          error={form.showErrors && form.customerNameInvalid}
          helperText={form.showErrors && form.customerNameInvalid ? 'Customer name is required' : ''}
          aria-label={'customer name'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              customerName: e.target.value,
            })
          }
        />
      </Row>
      <Row sx={{ gap: 2 }}>
        <FormInput
          label={'Title'}
          value={formProps.employmentTitle}
          placeholder={'Head of Sales'}
          fullWidth
          aria-label={'customer employment title'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              employmentTitle: e.target.value,
            })
          }
        />
        <FormInput
          label={'Organization'}
          value={formProps.organizationName}
          placeholder={'Google'}
          fullWidth
          aria-label={'customer organization name'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              organizationName: e.target.value,
            })
          }
        />
      </Row>
      <StyledToggleButtonGroup
        size="small"
        value={formProps.sourceType}
        exclusive
        onChange={handleSocialMediaType}
        aria-label="social media type"
        sx={{ width: '100%', gap: '10px' }}
      >
        <ToggleButton value="TWITTER" aria-label="twitter">
          <TwitterIcon style={{ width: '20px', height: '20px' }} />
          <Typography variant="inputLabel">Twitter</Typography>
        </ToggleButton>
        <ToggleButton value="LINKEDIN" aria-label="linkedIn">
          <LinkedInIcon style={{ width: '20px', height: '20px' }} />
          <Typography variant="inputLabel">LinkedIn</Typography>
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Row>
        <FormInput
          label={getURlLabelCopy(socialMediaType)}
          value={formProps.sourceLink}
          required
          error={form.showErrors && form.sourceLinkInvalid}
          helperText={form.showErrors && form.sourceLinkInvalid ? 'Please enter a valid link' : ''}
          placeholder={
            formProps.sourceType === 'TWITTER'
              ? 'https://twitter.com/example_username/status/1234567890'
              : 'https://linkedin.com/posts/example_username-post_title'
          }
          fullWidth
          onChange={e =>
            form.changeProof({
              ...formProps,
              sourceLink: e.target.value,
            })
          }
        />
      </Row>
      <ProofTagsInput />
    </Column>
  );
});

import { commsorRebranding } from '@caravel/components/src/theme';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { history } from 'helpers';
import React, { FC, PropsWithChildren } from 'react';
import { RootProvider } from 'react-day-picker';
import { Router } from 'react-router-dom';
import { StoreProvider } from 'stores';

export const AppProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <Router history={history}>
      <RootProvider>
        <StoreProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={commsorRebranding}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </StoreProvider>
      </RootProvider>
    </Router>
  );
};

export default AppProvider;

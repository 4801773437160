import { Facet, Filter, FilterOptionsConfig, FilterType, PeopleFilterType } from '@caravel/types/src';
import { facetTypeFromFilterCondition, parseDate } from '@caravel/utils';
import { addDays, addMonths, addWeeks, formatISO } from 'date-fns';
import { computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';

import { BaseStore } from './base';
import { RootStore } from './root';

export const generateYearOptions = () => {
  const currentYear = new Date(Date.now()).getUTCFullYear();
  const years = [];
  for (let i = 0; i < 50; i++) {
    years.push(Number(currentYear - i).toString());
  }
  return years;
};

export const PEOPLE_FILTER_OPTIONS_CONFIG: Record<PeopleFilterType, FilterOptionsConfig> = {
  search: { type: 'search', values: [], conditions: [] },
  layer: {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['Audience', 'Member', 'Internal'],
  },
  source: {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  seniority: {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['Executive', 'Manager', 'Director'],
  },
  organization: {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  title: {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  'grid-status': {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['Super User', 'Contributor', 'Potential', 'Regular', 'Neutral', 'Observer', 'Inactive'],
  },
  'first-active': {
    type: 'date',
    conditions: ['before', 'after'],
    values: [],
  },
  'last-active': {
    type: 'date',
    conditions: ['before', 'after'],
    values: [],
  },
  // 'signup-date': {
  //   type: 'date',
  //   conditions: ['before', 'after'],
  //   values: [],
  // },
  country: {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  city: {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  frequency: {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['Low', 'Medium', 'High'],
  },
  consistency: {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['Low', 'Medium', 'High'],
  },
  impact: {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['Low', 'Medium', 'High'],
  },
  'organization-kind': {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['Private', 'Public', 'Nonprofit', 'Education', 'Personal', 'Government'],
  },
  'organization-industry': {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  'organization-employee-range': {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['1-10', '11-50', '51-250', '251-1K', '1K-5K', '5K-10K', '10K-50K', '50K-100K', '100K+'],
  },
  'organization-estimated-annual-revenue': {
    type: 'multi',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: ['$1M-$10M', '$10M-$50M', '$50M-$100M', '$100M-$250M', '$250M-$500M', '$500M-$1B', '$1B-$10B', '$10B+'],
  },
  'organization-founded-year': {
    type: 'multi',
    conditions: ['is', 'any-of', 'greater-than', 'less-than'],
    values: generateYearOptions(),
  },
  'organization-city': {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  'organization-state': {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  'organization-country': {
    type: 'multi-search',
    conditions: ['is', 'not', 'any-of', 'not-any-of'],
    values: [],
  },
  'organization-annual-revenue': {
    type: 'number',
    conditions: ['is', 'greater-than', 'less-than'],
    values: [],
  },
  'organization-employee-count': {
    type: 'number',
    conditions: ['is', 'greater-than', 'less-than'],
    values: [],
  },
  'organization-twitter-followers': {
    type: 'number',
    conditions: ['is', 'greater-than', 'less-than'],
    values: [],
  },
  'organization-funds-raised': {
    type: 'number',
    conditions: ['is', 'greater-than', 'less-than'],
    values: [],
  },
};

export const CHAMPIONS_FILTER_OPTIONS: (Filter & { bottomDivider?: boolean })[] = [
  {
    type: 'seniority',
    graphProperty: 'employmentSeniority',
    label: 'Seniority',
    pluralized: 'seniorities',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization',
    graphProperty: 'organization',
    label: 'Organization',
    pluralized: 'organizations',
    condition: 'is',
    value: [],
  },
  {
    type: 'title',
    graphProperty: 'employmentTitle',
    label: 'Title',
    pluralized: 'titles',
    condition: 'is',
    value: [],
  },
  {
    type: 'last-active',
    graphProperty: 'lastActive',
    label: 'Last Active',
    pluralized: '',
    condition: 'before',
    value: [],
    bottomDivider: true,
  },
  {
    type: 'country',
    graphProperty: 'country',
    label: 'Country',
    pluralized: 'countries',
    condition: 'is',
    value: [],
  },
  {
    type: 'city',
    graphProperty: 'city',
    label: 'City',
    pluralized: 'cities',
    condition: 'is',
    value: [],
  },
];

export const PEOPLE_FILTER_OPTIONS: Filter[] = [
  {
    type: 'layer',
    graphProperty: 'position',
    label: 'Layer',
    pluralized: 'layers',
    condition: 'is',
    value: [],
  },
  {
    type: 'source',
    graphProperty: 'channel',
    label: 'Source',
    pluralized: 'sources',
    condition: 'includes',
    value: [],
  },
  {
    type: 'seniority',
    graphProperty: 'employmentSeniority',
    label: 'Seniority',
    pluralized: 'seniorities',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization',
    graphProperty: 'organization',
    label: 'Organization',
    pluralized: 'organizations',
    condition: 'is',
    value: [],
  },
  {
    type: 'title',
    graphProperty: 'employmentTitle',
    label: 'Title',
    pluralized: 'titles',
    condition: 'is',
    value: [],
  },
  {
    type: 'grid-status',
    graphProperty: 'gridStatus',
    label: 'Grid Status',
    pluralized: 'statuses',
    condition: 'is',
    value: [],
    bottomDivider: true,
  },
  {
    type: 'first-active',
    graphProperty: 'firstActive',
    label: 'First Active',
    pluralized: '',
    condition: 'before',
    value: [],
  },
  {
    type: 'last-active',
    graphProperty: 'lastActive',
    label: 'Last Active',
    pluralized: '',
    condition: 'before',
    value: [],
    bottomDivider: true,
  },
  // {
  //   type: 'signup-date',
  //   graphProperty: 'signupDate',
  //   label: 'Signup Date',
  //   pluralized: '',
  //   condition: 'before',
  //   value: [],
  //   bottomDivider: true,
  // },
  {
    type: 'country',
    graphProperty: 'country',
    label: 'Country',
    pluralized: 'countries',
    condition: 'is',
    value: [],
  },
  {
    type: 'city',
    graphProperty: 'city',
    label: 'City',
    pluralized: 'cities',
    condition: 'is',
    value: [],
    bottomDivider: true,
  },
  {
    type: 'frequency',
    graphProperty: 'frequency',
    label: 'Frequency',
    pluralized: 'frequencies',
    condition: 'is',
    value: [],
  },
  {
    type: 'consistency',
    graphProperty: 'consistency',
    label: 'Consistency',
    pluralized: 'consistencies',
    condition: 'is',
    value: [],
  },
  {
    type: 'impact',
    graphProperty: 'impact',
    label: 'Impact',
    pluralized: 'impacts',
    condition: 'is',
    value: [],
  },
];
export const PEOPLE_ORG_FILTER_OPTIONS: (Filter & { bottomDivider?: boolean })[] = [
  {
    type: 'organization-kind',
    graphProperty: 'organizationKind',
    label: 'Organization: Kind',
    pluralized: 'kinds',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-industry',
    graphProperty: 'organizationIndustry',
    label: 'Organization: Industry',
    pluralized: 'industries',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-employee-count',
    graphProperty: 'organizationEmployeeCount',
    label: 'Organization: Employee Count',
    pluralized: 'counts',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-employee-range',
    graphProperty: 'organizationEmployeeRange',
    label: 'Organization: Employee Range',
    pluralized: 'ranges',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-annual-revenue',
    graphProperty: 'organizationAnnualRevenue',
    label: 'Organization: Annual Revenue',
    pluralized: 'amounts',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-estimated-annual-revenue',
    graphProperty: 'organizationEstimatedAnnualRevenue',
    label: 'Organization: Estimated Annual Revenue',
    pluralized: 'amounts',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-funds-raised',
    graphProperty: 'organizationFundsRaised',
    label: 'Organization: Funds Raised',
    pluralized: 'amounts',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-twitter-followers',
    graphProperty: 'organizationTwitterFollowers',
    label: 'Organization: Twitter Followers',
    pluralized: 'amounts',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-founded-year',
    graphProperty: 'organizationFoundedYear',
    label: 'Organization: Founded Year',
    pluralized: 'years',
    condition: 'is',
    value: [],
    bottomDivider: true,
  },
  {
    type: 'organization-city',
    graphProperty: 'organizationCity',
    label: 'Organization: City',
    pluralized: 'cities',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-state',
    graphProperty: 'organizationState',
    label: 'Organization: State',
    pluralized: 'states',
    condition: 'is',
    value: [],
  },
  {
    type: 'organization-country',
    graphProperty: 'organizationCountry',
    label: 'Organization: Country',
    pluralized: 'countries',
    condition: 'is',
    value: [],
  },
];

function formatDate(str: string): Date {
  switch (str) {
    case '1 day ago':
      return addDays(new Date(), -1);
    case '1 week ago':
      return addWeeks(new Date(), -1);
    case '1 month ago':
      return addMonths(new Date(), -1);
    case '3 months ago':
      return addMonths(new Date(), -3);
    default:
      return new Date(str);
  }
}

interface FilterAttributes {
  filters: IObservableArray<Filter>;
  order: 'asc' | 'desc';
  orderBy: string;
  defaultFilters: Filter[];
  resetCollection: () => void;
  resetFilters: () => void;
  refreshCollection: () => void;
}

const defaultSearchFilter: Filter = {
  type: 'search',
  graphProperty: 'query',
  label: 'Search...',
  pluralized: 'Searches',
  condition: 'is',
  value: [],
};

const defaultChampionsSourceFilter: Filter = {
  type: 'source',
  graphProperty: 'channel',
  label: 'Source',
  pluralized: 'sources',
  condition: 'includes',
  value: ['champions'],
};

const defaultChampionsFilters: Filter[] = [defaultSearchFilter, defaultChampionsSourceFilter];

/**
 * Store to manage filtering data and views
 */
export class FilterStore extends BaseStore {
  peopleFilters = observable.array<Filter>([defaultSearchFilter]);

  peopleOrder: 'asc' | 'desc' = 'desc';
  peopleOrderBy = 'lastActive';

  championsFilters = observable.array<Filter>(defaultChampionsFilters);
  championsOrder: 'asc' | 'desc' = 'desc';
  championsOrderBy = 'lastActive';

  getAttributes = (type: FilterType): FilterAttributes => {
    switch (type) {
      case 'people':
        return {
          filters: this.peopleFilters,
          order: this.peopleOrder,
          orderBy: this.peopleOrderBy,
          defaultFilters: [defaultSearchFilter],
          resetCollection: () => this.rootStore.teams.people.resetCollection(),
          resetFilters: () => {
            this.peopleFilters = observable.array<Filter>([defaultSearchFilter]);
          },
          refreshCollection: async () => this.rootStore.teams.people.refreshCommunity(),
        };
      case 'champions':
        return {
          filters: this.championsFilters,
          order: this.championsOrder,
          orderBy: this.championsOrderBy,
          defaultFilters: defaultChampionsFilters,
          resetCollection: () => this.rootStore.teams.champions.resetChampions(),
          resetFilters: () => {
            this.championsFilters = observable.array<Filter>(defaultChampionsFilters);
          },
          refreshCollection: async () => this.rootStore.teams.champions.refreshChampions(),
        };
    }
  };

  setOrderBy = (type: FilterType, orderBy: string) => {
    runInAction(() => {
      switch (type) {
        case 'people':
          return (this.peopleOrderBy = orderBy);
        case 'champions':
          return (this.championsOrderBy = orderBy);
      }
    });
  };

  setOrder = (type: FilterType, order: 'asc' | 'desc') => {
    runInAction(() => {
      switch (type) {
        case 'people':
          return (this.peopleOrder = order);
        case 'champions':
          return (this.championsOrder = order);
      }
    });
  };

  getFacets = (type: FilterType): Facet[] => {
    const filter = this.getAttributes(type).filters;
    if (!filter) {
      throw new Error(`No filter found for type ${type}`);
    }
    return filter
      .filter(f => f.type !== 'search')
      .map(f => {
        const name = f.graphProperty;
        let values = f.value.slice();
        if (name === 'gridStatus') {
          if (values.includes('Super User')) {
            values.push('superfan');
          }
          values = values.map(v => v.toLowerCase().replace(' ', ''));
        } else if (f.condition && ['before', 'after'].includes(f.condition)) {
          values = values.map(v => {
            return formatISO(parseDate(formatDate(v))!);
          });
        }
        return {
          name,
          values,
          type: facetTypeFromFilterCondition(f.condition ?? 'is'),
        };
      });
  };

  orderByOptions = (
    type: FilterType,
  ): {
    field: string;
    direction: string;
  } => {
    const filterAttributes = this.getAttributes(type);
    return {
      field: filterAttributes.orderBy,
      direction: filterAttributes.order.toUpperCase(),
    };
  };

  searchQuery = (type: FilterType): string => {
    const filter = this.getAttributes(type).filters;
    if (!filter) {
      throw new Error(`No filter found for type ${type}`);
    }
    return (
      filter
        .filter(f => f.type === 'search')
        .at(0)
        ?.value.at(0) ?? ''
    );
  };

  get peopleFacets(): Facet[] {
    return this.peopleFilters
      .filter(f => f.type !== 'search')
      .map(f => {
        const name = f.graphProperty;
        let values = f.value.slice();
        if (name === 'gridStatus') {
          if (values.includes('Super User')) {
            values.push('superfan');
          }
          values = values.map(v => v.toLowerCase().replace(' ', ''));
        } else if (f.condition && ['before', 'after'].includes(f.condition)) {
          values = values.map(v => {
            return formatISO(parseDate(formatDate(v))!);
          });
        }
        return {
          name,
          values,
          type: facetTypeFromFilterCondition(f.condition ?? 'is'),
        };
      });
  }

  get peopleOrderByOptions() {
    return {
      field: this.peopleOrderBy,
      direction: this.peopleOrder.toUpperCase(),
    };
  }

  get peopleSearchQuery() {
    return (
      this.peopleFilters
        .filter(f => f.type === 'search')
        .at(0)
        ?.value.at(0) ?? ''
    );
  }

  get championsSearchQuery() {
    return (
      this.championsFilters
        .filter(f => f.type === 'search')
        .at(0)
        ?.value.at(0) ?? ''
    );
  }

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      peopleFilters: observable,
      peopleOrder: observable,
      peopleOrderBy: observable,
      peopleFacets: computed,
      peopleOrderByOptions: computed,
      peopleSearchQuery: computed,
      championsFilters: observable,
      championsOrder: observable,
      championsOrderBy: observable,
      championsSearchQuery: computed,
    });
  }

  onChangeOrder = (type: FilterType, orderBy: string) => {
    const filterAttributes = this.getAttributes(type);
    const isAsc = orderBy === filterAttributes.orderBy && filterAttributes.order === 'asc';
    runInAction(() => {
      this.setOrder(type, isAsc ? 'desc' : 'asc');
      this.setOrderBy(type, orderBy);
      filterAttributes.resetCollection();
    });
  };

  onChangePeopleOrder = (orderBy: string) => {
    const isAsc = orderBy === this.peopleOrderBy && this.peopleOrder === 'asc';
    runInAction(() => {
      this.peopleOrder = isAsc ? 'desc' : 'asc';
      this.peopleOrderBy = orderBy;
      this.rootStore.teams.people.resetCollection();
    });
  };

  resetPeopleFilters = () => {
    runInAction(() => {
      this.peopleFilters = observable.array<Filter>([defaultSearchFilter]);
    });
  };

  resetFilters = (type: FilterType) => {
    const filterAttributes = this.getAttributes(type);
    runInAction(() => {
      filterAttributes.resetFilters();
    });
  };

  onAddPeopleFilter = async (filter: Filter) => {
    if (this.peopleFilters.findIndex(f => f.graphProperty == filter.graphProperty) > 0) {
      return;
    }
    runInAction(() => {
      this.peopleFilters.push({
        ...filter,
        condition: null,
        value: [],
      });
    });
  };

  onAddFilter = async (type: FilterType, filter: Filter) => {
    const filterAttributes = this.getAttributes(type);
    if (filterAttributes.filters.findIndex(f => f.graphProperty == filter.graphProperty) > 0) {
      return;
    }
    runInAction(() => {
      filterAttributes.filters.push({
        ...filter,
        condition: null,
        value: [],
      });
    });
  };

  onChangePeopleFilter = async (index: number, filter: Filter, skipRefresh = false) => {
    runInAction(() => {
      const next = this.peopleFilters.slice();
      const existing = next[index];
      next.splice(index, 1, { ...existing, ...filter });
      this.peopleFilters.replace(next);
    });
    if (!skipRefresh) {
      await this.rootStore.teams.people.refreshCommunity();
    }
  };

  onChangeFilter = async (type: FilterType, index: number, filter: Filter, skipRefresh = false) => {
    const filterAttributes = this.getAttributes(type);
    runInAction(() => {
      const next = filterAttributes.filters.slice();
      const existing = next[index];
      next.splice(index, 1, { ...existing, ...filter });
      filterAttributes.filters.replace(next);
    });
    if (!skipRefresh) {
      await filterAttributes.refreshCollection();
    }
  };

  onRemovePeopleFilter = async (index: number) => {
    runInAction(() => {
      const next = this.peopleFilters.slice();
      next.splice(index, 1);
      this.peopleFilters.replace(next);
    });
    await this.rootStore.teams.people.refreshCommunity();
  };

  onRemoveFilter = async (type: FilterType, index: number) => {
    const filterAttributes = this.getAttributes(type);
    runInAction(() => {
      const next = filterAttributes.filters.slice();
      next.splice(index, 1);
      filterAttributes.filters.replace(next);
    });
    await filterAttributes.refreshCollection();
  };

  clearPeopleFilters = async () => {
    runInAction(() => {
      this.resetPeopleFilters();
    });
  };

  clearFilters = async (type: FilterType) => {
    const filterAttributes = this.getAttributes(type);
    runInAction(() => {
      filterAttributes.resetFilters();
    });
  };

  forceAddPeopleFilter = async (filter: Filter) => {
    if (filter)
      runInAction(() => {
        this.resetPeopleFilters();
        this.peopleFilters.push({
          ...filter,
        });
      });
    await this.rootStore.teams.people.refreshCommunity();
  };

  forceAddFilters = async (type: FilterType, filter: Filter) => {
    const filterAttributes = this.getAttributes(type);
    if (filter)
      runInAction(() => {
        filterAttributes.resetFilters();
        filterAttributes.filters.push({
          ...filter,
        });
      });
    await filterAttributes.refreshCollection();
  };
}

import 'react-day-picker/dist/style.css';

import { GREY_PALETTE, PRIMARY_PALETTE, WHITE } from '@caravel/utils';
import { IconButton } from '@mui/material';
import { addMonths, isAfter, isBefore } from 'date-fns';
import React from 'react';
import { DayPicker, useNavigation } from 'react-day-picker';

import { Flex, Row } from '..';
import { Arrow, MonthSelect, YearSelect } from './date-range-select';

export interface DaySelectProps {
  minDate: Date;
  maxDate: Date;
  date: Date;
  onChange: (date: Date) => void;
  calendar?: Date;
  onChangeCalendar: (calendar: Date | undefined) => void;
}

export function DaySelect(props: DaySelectProps) {
  const { minDate, maxDate, date, onChange, calendar, onChangeCalendar } = props;
  const { nextMonth, previousMonth } = useNavigation();

  const handleChangeCalendar = (d: Date | null) => {
    if (!d) {
      onChangeCalendar(new Date());
      return;
    }
    if (isAfter(d, maxDate)) {
      onChangeCalendar(maxDate);
    } else if (isBefore(d, minDate)) {
      onChangeCalendar(minDate);
    } else {
      onChangeCalendar(d);
    }
  };

  return (
    <Row
      sx={{
        alignItems: 'center',
        '& .caravel-date-range-picker': {
          '& .DayPicker-Month': {
            borderCollapse: 'separate',
            borderSpacing: '0 10px',
            width: '100%',
            maxWidth: `${32 * 7}px`,
            margin: '1em 25px',
          },

          '& .DayPicker-Week': {},
          '& .DayPicker-wrapper': { padding: 0 },

          '& .DayPicker-Day': {
            borderRadius: '50%',
            height: '32px',
            width: '32px',
            overflow: 'hidden',
            fontSize: '14px',
            margin: 0,
            padding: 0,
            position: 'relative',
            zIndex: 1,
          },

          '& .DayPicker-Day--today': {
            fontWeight: 'unset',
          },

          '& .DayPicker-Day--start:not(.DayPicker-Day--outside)': {
            borderRadius: '16px',

            '&::after': {
              content: `""`,
              backgroundColor: PRIMARY_PALETTE[200],
              borderRadius: '50%',
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
            },
          },

          '& .DayPicker-Day--end:not(.DayPicker-Day--outside)': {
            borderRadius: '16px',

            '&::after': {
              content: `""`,
              backgroundColor: PRIMARY_PALETTE[200],
              borderRadius: '50%',
              position: 'absolute',
              height: '100%',
              width: '100%',
              margin: '0',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
            },
          },

          '& .DayPicker-Day--outside': {
            color: GREY_PALETTE[3],
          },

          '& .DayPicker-Week :not(.DayPicker-Day--outside).DayPicker-Day--selected:nth-of-type(1)': {
            borderRadius: '16px',
          },
          '& .DayPicker-Week :not(.DayPicker-Day--outside).DayPicker-Day--selected:last-child': {
            borderRadius: '16px',
          },

          '& .DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
            '&:not(.DayPicker-Day--start):not(.DayPicker-Day--end)': {
              backgroundColor: PRIMARY_PALETTE[200],
              color: WHITE,
              '&:hover': {
                backgroundColor: PRIMARY_PALETTE[300],
              },
            },
            '&.DayPicker-Day--start, &.DayPicker-Day--end': {
              backgroundColor: PRIMARY_PALETTE[100],
              color: WHITE,
              '&:hover': {
                backgroundColor: GREY_PALETTE[2],
              },
            },
          },

          '& .DayPicker-Day:not(.DayPicker-Day--selected):hover': {
            backgroundColor: `${GREY_PALETTE[2]} !important`,
          },
        },
      }}
    >
      <DayPicker
        className="caravel-date-range-picker"
        mode="single"
        numberOfMonths={1}
        selected={date}
        month={calendar}
        fromMonth={minDate}
        toMonth={maxDate}
        showOutsideDays
        fixedWeeks
        onDayClick={day => {
          onChange(day);
        }}
        components={{
          Caption: props => {
            return (
              <div className="DayPicker-Caption">
                <Row
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <MonthSelect
                    minDate={minDate}
                    maxDate={maxDate}
                    month={props.displayMonth}
                    onChange={handleChangeCalendar}
                  />
                  <YearSelect
                    minDate={minDate}
                    maxDate={maxDate}
                    year={props.displayMonth}
                    onChange={handleChangeCalendar}
                  />
                  {previousMonth ? (
                    <IconButton onClick={() => handleChangeCalendar(addMonths(props.displayMonth, -1))}>
                      <Arrow direction="left" />
                    </IconButton>
                  ) : (
                    <Flex flex={1} />
                  )}
                  {nextMonth && (
                    <IconButton onClick={() => handleChangeCalendar(addMonths(props.displayMonth, 1))}>
                      <Arrow direction="right" />
                    </IconButton>
                  )}
                </Row>
              </div>
            );
          },
        }}
      />
    </Row>
  );
}

import { observer } from 'mobx-react';
import { Toast } from 'models';
import React, { FC } from 'react';
import { useStore } from 'stores';

import { AppSnackbarView } from './app-snackbar-view';

export const AppSnackbarContainer: FC = observer(() => {
  const store = useStore();

  const onCloseToast = (toast: Toast) => {
    store.ui.appToasts.closeToast(toast);
  };

  return (
    <AppSnackbarView
      opened={store.ui.appToasts.opened}
      toasts={store.ui.appToasts.toasts.slice()}
      onClose={store.ui.appToasts.handleClose}
      onCloseToast={onCloseToast}
    />
  );
});

export default AppSnackbarContainer;

import { GridStatus } from '@caravel/types/src/commsor';
import { BLACK, TEAM_MEMBER_STATUS_PALETTE, WHITE } from '@caravel/utils/src';
import { Typography } from '@mui/material';
import { SxProps, TypographyProps } from '@mui/system';
import React from 'react';

const colorFromStatus: Record<GridStatus, string | undefined> = {
  none: undefined,
  regular: TEAM_MEMBER_STATUS_PALETTE.regular,
  superfan: TEAM_MEMBER_STATUS_PALETTE.superUser,
  'super-user': TEAM_MEMBER_STATUS_PALETTE.superUser,
  neutral: TEAM_MEMBER_STATUS_PALETTE.neutral,
  contributor: TEAM_MEMBER_STATUS_PALETTE.contributor,
  observer: TEAM_MEMBER_STATUS_PALETTE.observer,
  potential: TEAM_MEMBER_STATUS_PALETTE.potential,
  inactive: TEAM_MEMBER_STATUS_PALETTE.inactive,
};

const textColorFromStatus: Record<GridStatus, string | undefined> = {
  none: undefined,
  regular: WHITE,
  superfan: WHITE,
  'super-user': WHITE,
  neutral: BLACK,
  contributor: BLACK,
  observer: WHITE,
  potential: WHITE,
  inactive: BLACK,
};

const textFromStatus: Record<GridStatus, string | undefined> = {
  none: undefined,
  regular: 'Regular',
  superfan: 'Super User',
  'super-user': 'Super User',
  contributor: 'Contributor',
  neutral: 'Neutral',
  observer: 'Observer',
  potential: 'Potential',
  inactive: 'Inactive',
};

export const GridStatusChip = React.forwardRef<
  HTMLSpanElement,
  { gridStatus?: GridStatus; sx?: SxProps } & TypographyProps
>((props, ref) => {
  if (!props.gridStatus) {
    return null;
  }
  const { gridStatus, sx, ...rest } = props;
  const text = textFromStatus[gridStatus];
  const backgroundColor = colorFromStatus[gridStatus];
  const color = textColorFromStatus[gridStatus];
  if (!text || !color || !backgroundColor) {
    return null;
  }

  return (
    <Typography
      {...rest}
      ref={ref}
      variant="bodySmallest"
      sx={{
        height: '24px',
        borderRadius: '8px',
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-start',
        backgroundColor,
        color,
        whiteSpace: 'nowrap',
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
});

GridStatusChip.displayName = 'GridStatusChip';

import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

// copied from /web/src/helpers/misc.ts for use in backend
export const getEditedProps = (a: any, b: any, exclude: string[] = []) => {
  const changed: string[] = [];
  const all = uniq([...Object.keys(a), ...Object.keys(b)]);
  all.forEach(k => {
    const aprop = a[k];
    const bprop = b[k];
    if (Array.isArray(aprop)) {
      if (!isEqual(aprop, bprop)) {
        changed.push(k);
      }
    } else {
      if (!isEqual(aprop, bprop)) {
        changed.push(k);
      }
    }
  });
  return changed.filter(k => !exclude.includes(k));
};

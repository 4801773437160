import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';

import { TroveForm } from '../../sources/trove/trove-form';

export const SurveyDetail = observer(() => {
  const { surveyId } = useParams<{ surveyId: string }>();
  return <TroveForm basePath={`/feedback/${surveyId}`} />;
});

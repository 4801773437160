import { AccountStanding } from '@caravel/types';
import { notEmptyString } from '@caravel/utils';
import firebase from 'firebase/compat/app';
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDb, Logger, reactTo } from 'helpers';
import { createTeam } from 'helpers/services/teams';
import { DateTime } from 'luxon';
import { action, computed, makeObservable, observable, runInAction, when } from 'mobx';
import { Team, TeamProps } from 'models';
import { NotificationStore } from 'stores/notifications';
import { RootStore } from 'stores/root';

import { BaseStore } from './base';
import { ChampionStore } from './champions';
import { ChannelStore } from './channels';
import { ComposerStore } from './composers';
import { DashboardStore } from './dashboard';
import { FilterStore } from './filters';
import { IntegrationStore } from './integrations';
import { InviteStore } from './invites';
import { OrganizationStore } from './organizations';
import { PeopleStore } from './people';
import { ProofLibraryStore } from './proof-library';
import { SegmentStore } from './segments';
import { TeamMemberStore } from './team-members';
import { TeamFormStore } from './ui/team-form';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('team-store');

export type MaybeFunctionError = firebase.functions.HttpsError | undefined;

export class TeamStore extends BaseStore {
  debugName = 'TeamStore';

  invites = new InviteStore(this.rootStore);

  /**
   * Observable store for teams that the current user is a member of.
   */
  teams = observable.array<Team>([], { name: 'teams' });

  /**
   * Substore for team creation
   */
  teamForm = new TeamFormStore(this.rootStore);

  /**
   * Substore representing the 'team-members' collection
   */
  teamMembers = new TeamMemberStore(this.rootStore);

  notifications = new NotificationStore(this.rootStore);

  organizations = new OrganizationStore(this.rootStore);

  people = new PeopleStore(this.rootStore);

  champions = new ChampionStore(this.rootStore);

  proofLibrary = new ProofLibraryStore(this.rootStore);

  segments = new SegmentStore(this.rootStore);

  dashboard = new DashboardStore(this.rootStore);

  integrations = new IntegrationStore(this.rootStore);

  composers = new ComposerStore(this.rootStore);

  channels = new ChannelStore(this.rootStore);

  filters = new FilterStore(this.rootStore);

  standing: AccountStanding = 'normal';

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      loadingTeam: observable,
      standing: observable,
      currentTeam: computed,
      isLoading: computed,
      _currentTeamId: observable,
      createTeam: action,
    });
  }

  /**
   * The current team that is 'loaded'
   */
  get currentTeam(): Team | undefined {
    return this.teams.find(t => t.id === this._currentTeamId);
  }

  get isLoading() {
    return this.loadingTeam;
  }

  _currentTeamId?: string = undefined;
  loadingTeam = false;

  async setup() {
    await super.setup();
    this.addDisposable(this.reactToMemberships());
    this.addDisposable(this.reactToTeam());
  }

  teardown() {
    this._currentTeamId = undefined;
    this.teams.clear();
    this.unmount();
    super.teardown();
  }

  async mount() {
    debug('mounting team store');
    await this.teamMembers.initialize();
    await this.invites.initialize();
    await this.teamForm.initialize();
    await this.notifications.initialize();
    await this.organizations.initialize();
    await this.people.initialize();
    await this.dashboard.initialize();
    await this.integrations.initialize();
    await this.champions.initialize();
    await this.proofLibrary.initialize();
    await this.channels.initialize();
    await this.filters.initialize();
  }

  unmount() {
    debug('unmounting team store');
    this.teamForm.teardown();
    this.invites.teardown();
    this.teamMembers.teardown();
    this.notifications.teardown();
    this.organizations.teardown();
    this.people.teardown();
    this.dashboard.teardown();
    this.integrations.teardown();
    this.champions.teardown();
    this.proofLibrary.teardown();
    this.channels.teardown();
    this.filters.teardown();
  }

  /**
   * Calls the `createTeam` cloud function with higher security permissions to ensure a team is created with a single
   * unique slug (team id) and gives the current user an 'admin' team role
   */
  createTeam = async (displayName: string, slug: string, logoUrl: string | undefined): Promise<{ teamId: string }> => {
    try {
      const { token, userId } = await this.getToken();
      const result = await createTeam({ displayName, slug, logoUrl }, { token, userId });
      this.rootStore.analytics.trackIdentity(userId);
      this.rootStore.analytics.trackOnboardingEvent('Team Created');
      this.rootStore.sendHubEvent(`created a new team named *${displayName}*`);

      return { teamId: result.teamId };
    } catch (error: any) {
      debug('error creating team', error);
      this.rootStore.notifications.display({
        message: 'Error creating team',
        severity: 'error',
      });
      return Promise.reject(error);
    }
  };

  /**
   * Initialize substores with a team id
   */
  loadTeam = async (teamId: string, timeout = 60000) => {
    if (this.loadingTeam) {
      return false;
    }
    runInAction(() => {
      this.loadingTeam = true;
      this._currentTeamId = undefined;
    });
    this.unmount();
    try {
      debug('loading team', teamId);
      await this.rootStore.features.getTeamFeatures(teamId);
      const teamDoc = await getDoc(doc(getDb(), 'teams', teamId));
      const props = teamDoc.data() as TeamProps;
      const index = this.teams.findIndex(t => t.id === teamId);
      runInAction(() => {
        if (index > -1) {
          const team = this.teams[index];
          team.update(props);
          this.teams.splice(index, 1, team);
        } else {
          this.teams.push(
            new Team({
              ...props,
              id: teamId,
            }),
          );
        }
        this._currentTeamId = teamId;
      });
      await when(() => this.currentTeam !== undefined, { timeout });
      await this.mount();
      await this.rootStore.members.current?.updateLastTeam(teamId);
    } catch (error) {
      debug(`Couldn't load team`, teamId, error);
      this.rootStore.notifications.display({
        message: 'Failed to load team',
        severity: 'error',
      });
      return false;
    } finally {
      runInAction(() => {
        this.loadingTeam = false;
      });
    }
    this.rootStore.analytics.trackIdentity(this.rootStore.auth.user?.uid);

    //Segment Analytics
    const workspaceName = this.currentTeam?.displayName;
    const membershipRole = this.rootStore.members.getMembershipRole(teamId);
    const usersWorkspaces: string[] = this.rootStore.teams.teams.slice().map(team => team.id);
    analytics.identify(this.rootStore.auth.user!.uid, {
      name: this.rootStore.auth.user!.displayName,
      email: this.rootStore.auth.user!.email,
      avatar: this.rootStore.auth.user!.photoURL,
      createdAt: this.rootStore.auth.user!.metadata.creationTime,
      groupId: usersWorkspaces,
    });
    analytics.group(teamId, {
      groupId: teamId,
      name: workspaceName,
      plan: this.currentTeam?.plan?.name,
      employees: this.rootStore.teams.teamMembers.collection.slice().length,
      slug: this.currentTeam?.slug,
      createdAt: this.currentTeam?.createdAt?.seconds
        ? DateTime.fromSeconds(this.currentTeam?.createdAt?.seconds).toUTC().toISO()
        : undefined,
    });
    analytics.track(
      'Workspace Loaded',
      {
        workspace_name: workspaceName,
        workspace_role: membershipRole,
        groupId: teamId,
      },
      {
        context: {
          groupId: teamId,
        },
      },
    );

    debug('loaded team', teamId);
    return true;
  };

  updateCompanyDomains = async (companyDomains: string[]) => {
    if (!this.currentTeam) {
      return;
    }
    try {
      await updateDoc(this.currentTeam.docRef, {
        companyDomains: companyDomains.filter(notEmptyString),
      });
      this.rootStore.notifications.display({
        severity: 'success',
        message: 'Company domains successfully updated',
        duration: 3000,
      });
    } catch (e) {
      console.error(e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to update company domains',
        duration: 10000,
      });
    }
  };

  private reactToMemberships = () =>
    reactTo(
      () => this.rootStore.members.memberships.collection.slice(),
      memberships => {
        const nextTeams = memberships.map(m => m.id);
        const orphans = this.teams.filter(t => !nextTeams.includes(t.id));
        orphans.forEach(m => {
          const index = this.teams.findIndex(t => t.id === m.id);
          if (index > -1) {
            this.teams.splice(index, 1);
          }
          this.authSubs.removeEffect(m.id);
        });
        memberships.forEach(m => this.authSubs.addEffect(m.id, this.subToTeam(m.id)));
      },
      'teams-from-memberships',
    );

  private reactToTeam = () =>
    reactTo(
      () => this.currentTeam,
      team => {
        if (team) {
          const standingRef = doc(getDb(), team.docRef.path, 'usage', 'standing');
          this.addDisposable(
            onSnapshot(standingRef, snap => {
              const { standing } = snap.data() || { standing: 'normal' };
              runInAction(() => {
                this.standing = standing;
              });
            }),
          );
        } else {
          this.disposeOf('account-standing');
        }
      },
    );

  private subToTeam = (teamId: string) => () => {
    const teamRef = doc(getDb(), 'teams', teamId);
    return onSnapshot(teamRef, snapshot => {
      runInAction(() => {
        const index = this.teams.findIndex(t => t.id === snapshot.id);
        const props = snapshot.data() as TeamProps;
        if (!snapshot.exists) {
          this.authSubs.removeEffect(teamId);
          this.teams.splice(index, 1);
        } else if (index > -1) {
          const team = this.teams[index];
          team.update(props);
          this.teams.splice(index, 1, team);
        } else {
          this.teams.push(
            new Team({
              ...props,
              id: teamId,
            }),
          );
        }
      });
    });
  };
}

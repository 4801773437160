import { CaravelSpinner, Column, Flex, NoResults, SortableTableHead } from '@caravel/components/src';
import { Champion, ChampionReference, Prospect } from '@caravel/types/src';
import { GREY_PALETTE, parseDate, SUCCESS_PALETTE } from '@caravel/utils';
import { TabContext } from '@mui/lab';
import {
  Avatar,
  AvatarGroup,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Person } from 'models/person';
import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-cool-inview';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { ReactComponent as NoReferencesIcon } from '../../assets/svgs/no-results-references.svg';
import { AppToolbar } from '../app-toolbar';

// Refereneces - Formerly References
export const ReferencesList = observer(() => {
  const store = useStore();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('INVITED');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [currentPerson, setCurrentPerson] = useState<Person | undefined>(undefined);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const {
    ui,
    teams: { champions, people },
  } = store;
  const references: ChampionReference[] = champions.references.slice();
  const hasReferences = references.length > 0;

  const canLoad = store.team && !champions.championsLoading;

  const filterReferences = (view: string) => {
    return references.slice().filter(c => c.status === view);
  };

  const loadMore = useCallback(() => {
    if (!canLoad && hasLoaded) return;
    ui.workOn(async () => {
      await champions.fetchReferences();
      setHasLoaded(true);
    });
  }, [canLoad, champions, hasLoaded, ui]);

  const { observe } = useInView({
    onEnter: () => {
      if (!hasLoaded) {
        loadMore();
      }
    },
  });

  useEffect(() => {
    if (!hasLoaded) {
      loadMore();
    }
  }, [references.length, hasLoaded, loadMore]);

  function handleRequestSort(id: string): void {
    throw new Error('Function not implemented.');
  }

  const viewChampionDetails = async (championId: string) => {
    const hasCRM = store.features.hasCrm;
    if (hasCRM) {
      history.push(`/people/${championId}`, { from: 'champions' });
      return;
    }
    try {
      history.push(`/champions/${championId}`, { from: 'champions-only' });
    } catch (e) {
      console.warn(e);
      setCurrentPerson(undefined);
      setOpenDrawer(false);
    }
  };

  const viewProspectDetails = async (prospectId: string) => {
    const hasCRM = store.features.hasCrm;
    if (hasCRM) {
      history.push(`/people/${prospectId}`, { from: 'champions' });
      return;
    }
    try {
      setCurrentPerson(undefined);
      setOpenDrawer(true);
      await people.fetchPerson(prospectId);
      setCurrentPerson(people.person);
    } catch (e) {
      console.warn(e);
      setCurrentPerson(undefined);
      setOpenDrawer(false);
    }
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const getChampionLink = (id: string, placeholder: string, email?: string, firstName?: string, lastName?: string) => {
    let displayName = email ?? placeholder;
    if (firstName && lastName) {
      displayName = `${firstName} ${lastName}`;
    }
    return (
      <Link
        variant="headline4"
        sx={{ color: 'inherit', cursor: 'pointer' }}
        onClick={() => {
          viewChampionDetails(id);
        }}
      >
        {displayName}
      </Link>
    );
  };

  const getProspectLink = (id: string, placeholder: string, email?: string, firstName?: string, lastName?: string) => {
    let displayName = email ?? placeholder;
    if (firstName && lastName) {
      displayName = `${firstName} ${lastName}`;
    }
    return (
      <Link
        variant="headline4"
        sx={{ color: 'inherit', cursor: 'pointer' }}
        onClick={() => {
          viewProspectDetails(id);
        }}
      >
        {displayName}
      </Link>
    );
  };

  const getReferenceName = (champion?: Champion, prospect?: Prospect) => {
    return (
      <>
        {champion && champion.id
          ? getChampionLink(champion.id, 'Unknown Champion', champion.email, champion.givenName, champion.familyName)
          : 'Unknown Champion'}
        {' & '}
        {prospect && prospect.id
          ? getProspectLink(prospect.id, 'Unknown Prospect', prospect.email, prospect.givenName, prospect.familyName)
          : 'Unknown Prospect'}
      </>
    );
  };

  return (
    <>
      <Column sx={{ height: '100%' }}>
        <AppToolbar
          title="References"
          subtitle={!champions.championsLoading && hasReferences ? `${references.length}` : ''}
        />

        {hasReferences ? (
          <Column>
            <TabContext value={currentTab}>
              <Tabs
                value={currentTab}
                onChange={(e, v) => {
                  setCurrentTab(v);
                }}
              >
                <Tab
                  label={`${store.teams.champions.requestedReferencesCount} Requested`}
                  id="requested-tab"
                  value="INVITED"
                />
                <Tab
                  label={`${store.teams.champions.acceptedReferencesCount} Accepted`}
                  id="accepted-tab"
                  value="ACCEPTED"
                />
              </Tabs>
            </TabContext>
            <TableContainer sx={{ overflowX: 'unset' }}>
              <Table stickyHeader size="small">
                <SortableTableHead
                  cells={[
                    {
                      id: '',
                      label: '',
                      disablePadding: true,
                      minWidth: '10px',
                      width: '10px',
                      maxWidth: '10px',
                    },
                    {
                      id: 'reference',
                      label: 'Reference (Champion & Prospect)',
                      disablePadding: true,
                      minWidth: '200px',
                      width: `calc(100%/${2})`,
                    },
                    {
                      id: 'introduced',
                      label: 'Introduced',
                      width: `calc(100%/${4})`,
                      maxWidth: '100px',
                    },
                    {
                      id: 'status',
                      label: 'Status',
                      width: `calc(100%/${4})`,
                      maxWidth: '100px',
                    },
                  ]}
                  order={'desc'}
                  orderBy={'requested'}
                  onSort={handleRequestSort}
                />
                <TableBody>
                  {filterReferences(currentTab).map((row, i) => {
                    const championFirstName = row.champion?.givenName ?? 'Unknown';
                    const championLastName = row.champion?.familyName ?? 'Unknown';
                    const prospectFirstName = row.prospect?.givenName ?? 'Unknown';
                    const prospectLastName = row.prospect?.familyName ?? 'Unknown';
                    return (
                      <TableRow key={i} sx={{ minHeight: '50px', height: '100%' }}>
                        <TableCell />
                        <TableCell
                          padding="none"
                          sx={{
                            tableLayout: 'fixed',
                            padding: '10px 0',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <AvatarGroup
                            max={2}
                            sx={{
                              marginRight: '10px',
                              '& .MuiAvatarGroup-avatar': {
                                width: '30px',
                                height: '30px',
                              },
                            }}
                          >
                            <Avatar
                              src={row.champion?.avatar}
                              alt={`${championFirstName} ${championLastName}`}
                              sx={{ width: 30, height: 30, fontSize: '12px' }}
                            >
                              {champions.getInitials(championFirstName, championLastName)}
                            </Avatar>
                            <Avatar
                              src={''}
                              alt={`${prospectFirstName} ${prospectLastName}`}
                              sx={{ width: 30, height: 30, fontSize: '12px' }}
                            >
                              {champions.getInitials(prospectFirstName, prospectLastName)}
                            </Avatar>
                          </AvatarGroup>
                          <Typography variant="headline4">{getReferenceName(row.champion, row.prospect)}</Typography>
                        </TableCell>
                        <TableCell>{parseDate(row.createdAt)?.toLocaleDateString()}</TableCell>
                        <TableCell>
                          <Typography
                            variant={'headline4'}
                            color={row.status === 'ACCEPTED' ? SUCCESS_PALETTE[200] : GREY_PALETTE[3]}
                          >
                            {row.status && row.status?.charAt(0).toUpperCase() + row.status?.substring(1)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Column sx={{ padding: '28px' }}>
              {!champions.referencesLoading && <Column ref={observe} />}
              {champions.referencesLoading && <CaravelSpinner />}
              {!champions.referencesLoading && <Typography>No more references</Typography>}
              <Flex flex={1} sx={{ paddingTop: '20px', width: '100%' }} />
            </Column>
          </Column>
        ) : (
          <NoResults
            icon={<NoReferencesIcon />}
            title="No reference connections"
            message="Unlock the full potential of your brand by connecting your champions with prospects."
          />
        )}
      </Column>
    </>
  );
});

import { Column, Row } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { ProofTagsInput } from './proof-tags-input';

export const TextQuoteForm = observer(() => {
  const store = useStore();
  const { ui } = store;
  const form = ui.ProofForm;

  const formProps = form.formProps;
  if (!formProps) {
    return null;
  }
  return (
    <Column sx={{ gap: 2, width: '100%', marginTop: '20px' }}>
      <Row>
        <FormInput
          label={'Customer name'}
          value={formProps.customerName}
          required
          error={form.showErrors && form.customerNameInvalid}
          helperText={form.showErrors && form.customerNameInvalid ? 'Customer name is required' : ''}
          placeholder={'Fullname'}
          fullWidth
          aria-label={'customer name'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              customerName: e.target.value,
            })
          }
        />
      </Row>
      <Row sx={{ gap: 2 }}>
        <FormInput
          label={'Title'}
          value={formProps.employmentTitle}
          placeholder={'Head of Sales'}
          fullWidth
          aria-label={'customer employment title'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              employmentTitle: e.target.value,
            })
          }
        />
        <FormInput
          label={'Organization'}
          value={formProps.organizationName}
          placeholder={'Google'}
          fullWidth
          aria-label={'customer organization name'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              organizationName: e.target.value,
            })
          }
        />
      </Row>
      <Row>
        <FormInput
          label={'Text quote'}
          value={formProps.content}
          required
          error={form.showErrors && form.contentInvalid}
          helperText={form.showErrors && form.contentInvalid ? 'Text quote is required' : ''}
          multiline
          placeholder={'Description'}
          fullWidth
          onChange={e =>
            form.changeProof({
              ...formProps,
              content: e.target.value,
            })
          }
        />
      </Row>
      <ProofTagsInput />
    </Column>
  );
});

import {
  AttributeField,
  AttributeFieldOption,
  Column,
  ConnectionsDetails,
  Flex,
  GridStatusChip,
  Row,
  TextAttributeField,
} from '@caravel/components/src';
import { ReferencesDetails } from '@caravel/components/src/references-details';
import { PersonaType } from '@caravel/types';
import { GridStatus } from '@caravel/types/src/commsor';
import { DARK_GREY, GREY_PALETTE, NEUTRALS, parseDate, upperFirst } from '@caravel/utils';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
} from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Person } from 'models/person';
import React, { forwardRef, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { ReactComponent as FacebookIcon } from '../../../assets/svgs/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/svgs/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/svgs/twitter.svg';

const KeyTableCell = forwardRef<HTMLTableCellElement, TableCellProps>((props, ref) => (
  <TableCell
    {...props}
    ref={ref}
    sx={{
      color: GREY_PALETTE[5],
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
      padding: '5px 25px 5px 0 !important',
      ...props.sx,
    }}
  />
));
KeyTableCell.displayName = 'KeyTableCell';

const ValueTableCell = forwardRef<HTMLTableCellElement, TableCellProps>((props, ref) => (
  <TableCell
    {...props}
    ref={ref}
    sx={{
      color: GREY_PALETTE[7],
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      ...props.sx,
    }}
  />
));
ValueTableCell.displayName = 'ValueTableCell';

const getLayerFromPosition = (position = '') => {
  const layer = (position ?? '').split('/').at(-1);
  return upperFirst(layer);
};

interface PersonDetailsProps {
  person: Person;
  variant?: 'permanent' | 'persistent' | 'temporary' | undefined;
  isOpen?: boolean;
  previousPage?: string;
}

export const personaTypeLabelMap: Record<PersonaType, string> = {
  city: 'City',
  state: 'State',
  country: 'Country',
  email: 'Email Address',
  emails: 'Email Addresses',
  employmentTitle: 'Job Title',
  facebookHandle: 'Facebook',
  githubHandle: 'Github',
  linkedinHandle: 'LinkedIn',
  twitterHandle: 'Twitter',
  website: 'Website',
};

// eslint-disable-next-line complexity
export const PersonDetails = observer((props: PersonDetailsProps) => {
  const store = useStore();
  const history = useHistory();
  const {
    teams: { people, teamMembers },
  } = store;
  const [updatingAttributes, setUpdatingAttributes] = useState<PersonaType[]>([]);
  const { person, previousPage } = props;
  const fromChampions = previousPage && previousPage === 'champions';
  const layer = getLayerFromPosition(person.position);
  const title = person.employmentTitle;
  const org = person.organization?.name;
  const headline = useMemo(() => {
    if (title && org) {
      return `${title} at ${org}`;
    } else if (title) {
      return title;
    } else if (org) {
      return org;
    }
  }, [title, org]);

  const onChangeAttribute = async (attributeType: PersonaType, option: AttributeFieldOption, personaId?: string) => {
    const isUpdating = updatingAttributes.includes(attributeType);
    if (isUpdating) {
      return;
    }
    setUpdatingAttributes([...updatingAttributes, attributeType]);
    await store.ui.workOn(async () => {
      try {
        personaId
          ? await people.setPrimaryAttribute(person.id!, attributeType, option, personaId)
          : await people.createManualPersona(person.id!, attributeType, option);
        store.notifications.display({
          message: personaTypeLabelMap[attributeType] + ' updated',
          severity: 'success',
          duration: 3000,
        });
      } catch (e) {
        console.error(e);
        store.notifications.display({
          message: 'Failed to update ' + personaTypeLabelMap[attributeType],
          severity: 'error',
          duration: 3000,
        });
      } finally {
        setUpdatingAttributes(updatingAttributes.filter(at => at === attributeType));
      }
    });
  };

  const onDeleteAttribute = async (attributeType: PersonaType) => {
    await store.ui.workOn(async () => {
      await people.deleteManualPersona(person.id!, attributeType);
    });
  };

  const attributeOptions = (personaType: PersonaType) => {
    const personas = toJS(person.personas);
    if (personas) {
      if (personaType === 'emails') {
        // Modify to include personID as required by setPrimaryAttribute query
        const options: AttributeFieldOption[] = [];
        personas.forEach(persona => {
          persona.emails?.forEach(email => options.push({ value: email, personaId: persona.id }));
        });
        return options;
      } else {
        return personas.map(values => {
          return {
            value: values[personaType],
            personaId: values.id,
            custom: values.channel.kind === ':manual',
          };
        });
      }
    }
    return [];
  };

  const teamConnections = useMemo(() => {
    return teamMembers.getTeamConnections(person.connectedTo);
  }, [person.connectedTo, teamMembers]);

  if (!person) {
    return null;
  }
  return (
    <Column sx={{ width: '100%', padding: '20px 24px' }}>
      <Card elevation={0} variant="elevation" sx={{ backgroundColor: NEUTRALS.CHAMOMILE, borderRadius: '8px' }}>
        <CardContent sx={{ padding: '30px !important' }}>
          <Row sx={{ marginBottom: '30px', justifyContent: 'space-between', columnGap: '1rem' }}>
            <Column>
              <Typography variant="headline1" sx={{ marginBottom: '5px' }} component="h1">
                {person.name}
              </Typography>
              <Typography
                variant="bodySmallest"
                sx={{
                  color: DARK_GREY,
                }}
              >
                {headline}
              </Typography>
              <Row sx={{ marginTop: '16px' }}>
                {layer && (
                  <Typography
                    variant="bodySmallest"
                    sx={{
                      alignItems: 'center',
                      alignSelf: 'flex-start',
                      backgroundColor: NEUTRALS.EARL_GREY,
                      borderRadius: '8px',
                      color: 'white',
                      display: 'flex',
                      height: '24px',
                      justifyContent: 'center',
                      marginRight: '5px',
                      padding: '0 8px',
                    }}
                  >
                    {layer}
                  </Typography>
                )}
                <GridStatusChip gridStatus={person.engagement?.gridStatus as GridStatus} />
              </Row>
            </Column>

            {person.organization && (
              <Badge
                overlap="circular"
                badgeContent={
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      boxShadow: `0 0 0 2px ${NEUTRALS.CHAMOMILE}`,
                    }}
                    src={person.organizationIcon}
                  />
                }
              >
                <Avatar sx={{ width: 85, height: 85 }} src={person.avatar ?? ''} />
              </Badge>
            )}
            {!person.organization && <Avatar sx={{ width: 85, height: 85 }} src={person.avatar ?? ''} />}
          </Row>
          <Table
            size="small"
            sx={{
              '& .MuiTableCell-root': {
                border: 'unset',
                padding: '5px 0',
              },
            }}
          >
            <TableBody>
              <TableRow>
                <KeyTableCell>{personaTypeLabelMap['employmentTitle']}</KeyTableCell>
                <ValueTableCell>
                  <TextAttributeField
                    value={{ value: person.employmentTitle ?? '' }}
                    options={attributeOptions('employmentTitle')}
                    onChangeAttribute={onChangeAttribute}
                    customizable={true}
                    attributeType="employmentTitle"
                    onDeleteAttribute={onDeleteAttribute}
                    loading={updatingAttributes.includes('employmentTitle')}
                  />
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Organization</KeyTableCell>
                <ValueTableCell>
                  {person.organization && (
                    <Link
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/organizations/${person.organization!.id}`, { from: previousPage });
                      }}
                    >
                      {person.organization!.name}
                    </Link>
                  )}
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Member Since</KeyTableCell>
                {person.firstSeen && (
                  <ValueTableCell>{parseDate(person.firstSeen)?.toLocaleDateString()}</ValueTableCell>
                )}
              </TableRow>
              <TableRow>
                <KeyTableCell>First Active</KeyTableCell>
                {person.firstSeen && (
                  <ValueTableCell>{parseDate(person.firstSeen)?.toLocaleDateString()}</ValueTableCell>
                )}
              </TableRow>
              <TableRow>
                <KeyTableCell>Last Active</KeyTableCell>
                {person.lastSeen && <ValueTableCell>{parseDate(person.lastSeen)?.toLocaleDateString()}</ValueTableCell>}
              </TableRow>
              <TableRow>
                <KeyTableCell>{personaTypeLabelMap['email']}</KeyTableCell>
                <ValueTableCell>
                  <AttributeField
                    attributeType="email"
                    value={{ value: person.email }}
                    options={attributeOptions('emails')}
                    onChangeAttribute={onChangeAttribute}
                    onDeleteAttribute={onDeleteAttribute}
                    customizable={false}
                  />
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>{personaTypeLabelMap['city']}</KeyTableCell>
                <ValueTableCell>
                  <AttributeField
                    value={{ value: person.city }}
                    options={attributeOptions('city')}
                    onChangeAttribute={onChangeAttribute}
                    customizable={true}
                    attributeType="city"
                    onDeleteAttribute={onDeleteAttribute}
                    autoFocus={true}
                    loading={updatingAttributes.includes('city')}
                  />
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>{personaTypeLabelMap['state']}</KeyTableCell>
                <ValueTableCell>
                  <AttributeField
                    value={{ value: person.state }}
                    options={attributeOptions('state')}
                    onChangeAttribute={onChangeAttribute}
                    customizable={true}
                    attributeType="state"
                    onDeleteAttribute={onDeleteAttribute}
                    autoFocus={true}
                    loading={updatingAttributes.includes('state')}
                  />
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>{personaTypeLabelMap['country']}</KeyTableCell>
                <ValueTableCell>
                  <AttributeField
                    value={{ value: person.country }}
                    options={attributeOptions('country')}
                    onChangeAttribute={onChangeAttribute}
                    customizable={true}
                    attributeType="country"
                    onDeleteAttribute={onDeleteAttribute}
                    autoFocus={true}
                    loading={updatingAttributes.includes('country')}
                  />
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>{personaTypeLabelMap['website']}</KeyTableCell>
                <ValueTableCell
                  sx={{
                    overflow: 'hidden',
                  }}
                >
                  <TextAttributeField
                    value={{ value: person.website ?? '' }}
                    options={attributeOptions('website')}
                    onChangeAttribute={onChangeAttribute}
                    onDeleteAttribute={onDeleteAttribute}
                    customizable={true}
                    attributeType="website"
                    loading={updatingAttributes.includes('website')}
                  />
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>{personaTypeLabelMap['facebookHandle']}</KeyTableCell>
                <ValueTableCell>
                  <TextAttributeField
                    value={{ value: person.facebookHandle ?? '' }}
                    options={attributeOptions('facebookHandle')}
                    onChangeAttribute={onChangeAttribute}
                    customizable={true}
                    attributeType="facebookHandle"
                    onDeleteAttribute={onDeleteAttribute}
                    loading={updatingAttributes.includes('facebookHandle')}
                  />
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>{personaTypeLabelMap['linkedinHandle']}</KeyTableCell>
                <ValueTableCell>
                  <TextAttributeField
                    value={{ value: person.linkedinHandle ?? '' }}
                    options={attributeOptions('linkedinHandle')}
                    onChangeAttribute={onChangeAttribute}
                    customizable={true}
                    attributeType="linkedinHandle"
                    onDeleteAttribute={onDeleteAttribute}
                    loading={updatingAttributes.includes('linkedinHandle')}
                  />
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Social Media</KeyTableCell>
                <ValueTableCell>
                  <Flex gap="10px">
                    {person.twitterHandle ? (
                      <Link
                        target="_blank"
                        href={`https://twitter.com/${person.twitterHandle}`}
                        title={`View ${person.name} on Twitter`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <TwitterIcon />
                      </Link>
                    ) : null}

                    {person.facebookHandle ? (
                      <Link
                        target="_blank"
                        href={`http://facebook.com/${person.facebookHandle}`}
                        title={`View ${person.name} on Facebook`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <FacebookIcon />
                      </Link>
                    ) : null}

                    {person.linkedinHandle ? (
                      <Link
                        target="_blank"
                        href={`https://linkedin.com/${person.linkedinHandle}`}
                        title={`View ${person.name} on LinkedIn`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <LinkedinIcon />
                      </Link>
                    ) : null}
                  </Flex>
                </ValueTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {!fromChampions && teamConnections.length > 0 && (
        <Box marginY="8px">
          <ConnectionsDetails teamConnections={teamConnections} />
        </Box>
      )}
      <Box marginY="8px">
        <ReferencesDetails firstName={person.name ?? 'Champion'} references={person.references} />
      </Box>
    </Column>
  );
});

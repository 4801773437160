import { gql } from '@apollo/client';

export const ASK_INTRODUCTION_TO_CONNECTION = gql`
  mutation askIntroductionToConnection($input: AskConnectionIntroductionInput!) {
    askIntroductionToConnection(input: $input) {
      clientMutationId
      result
    }
  }
`;

export interface GqlAskIntroductionToConnectionRequestType {
  input: {
    teamMemberId: string;
    connectionId: string;
    message: string;
  };
}

export interface GqlAskIntroductionToConnectionResponseType {
  askIntroductionToConnection: {
    clientMutationId: string;
    result: string;
  };
}

import { GREY_PALETTE } from '@caravel/utils';
import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export const TextFieldBorderless = React.forwardRef<
  HTMLDivElement,
  TextFieldProps & {
    customVariant: 'singleline' | 'multiline';
    textColor?: string;
  }
>(({ customVariant, textColor, ...props }, ref) => (
  <TextField
    {...props}
    ref={ref}
    sx={{
      '& .MuiInputBase-input': {
        ...(customVariant === 'singleline'
          ? {
              fontSize: '18px',
            }
          : {
              fontSize: '12px',
              letterSpacing: '-0.6%',
            }),
        color: textColor || GREY_PALETTE[7],
        lineHeight: '22px',
        padding: 0,
      },
      '& .MuiOutlinedInput-root': {
        background: 'none',
        ...(customVariant === 'singleline'
          ? {}
          : {
              padding: '0',
            }),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        ...(customVariant === 'singleline'
          ? {
              padding: '0',
            }
          : {}),
      },
      ...props.sx,
    }}
  />
));

TextFieldBorderless.displayName = 'TextFieldBorderless';

// function TextInput(props: {
//   value: string;
//   onChange: (value: string) => void;
//   placeholder: string;
//   inputPadding?: string;
//   outlinePadding?: string;
//   color?: string;
//   fontSize?: string;
//   letterSpacing?: string;
//   autoFocus?: boolean;
// }) {
//   const {
//     value,
//     onChange,
//     placeholder,
//     inputPadding,
//     outlinePadding,
//     color = CHARCOAL_BLACK,
//     fontSize = '18px',
//     letterSpacing = '-1.2%',
//     autoFocus = true,
//   } = props;
//   return (
//     <TextField
//       placeholder={placeholder}
//       value={value}
//       onChange={e => onChange(e.target.value)}
//       autoFocus={autoFocus}
//       sx={{
//         '& .MuiInputBase-input': {
//           color,
//           fontSize,
//           letterSpacing,
//           lineHeight: '22px',
//           padding: 0,
//         },
//         '& .MuiOutlinedInput-root': {
//           background: 'none',
//           padding: inputPadding,
//         },
//         '& .MuiOutlinedInput-notchedOutline': {
//           border: 'none',
//           padding: outlinePadding,
//         },
//       }}
//     />
//   );
// }

import { gql } from '@apollo/client';
import { Person } from '@caravel/types';

export const GET_COMMUNITY = gql`
  query getCommunity($after: String, $first: Int, $query: String, $facets: [FacetFilter], $orderBy: ItemOrder) {
    community {
      id
      stats {
        peopleCount
      }
      people(after: $after, first: $first, query: $query, filterBy: { facets: $facets }, orderBy: $orderBy) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          node {
            id
            canReceiveReferences
            name
            email
            emails
            city
            country
            state
            website
            facebookHandle
            linkedinHandle
            avatar
            firstSeen
            lastSeen
            givenName
            familyName
            twitterHandle
            githubHandle
            avatar
            position
            employmentTitle
            employmentSeniority
            customerFit
            referenceCount
            primaryOrganization {
              id
              name
              domain
            }
            organizations {
              id
              name
              domain
            }
            engagement {
              frequency
              impact
              consistency
              gridStatus
            }
            connectedTo {
              connectedSince
              lastExchange
              totalMeetings
              userId
            }
          }
        }
        meta {
          facets {
            counts {
              count
              value
            }
            name
            totalValues
          }
          hits
          query
        }
      }
    }
  }
`;

export interface GqlCommunityRequestType {
  after?: string;
  first?: string;
  query?: string;
  facets?: any[];
  orderBy?: { field: string; direction: string };
}

export interface GqlCommunityResponseType {
  community: {
    id: string;
    slug?: string;
    stats: {
      peopleCount: number;
    };
    people: {
      edges: {
        node: Person;
      }[];
      meta: {
        facets:
          | {
              counts: {
                count: number;
                value: string;
              }[];
              name: string;
              totalValues: number;
            }[]
          | null;
        hits: number;
        query: string;
      };
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  };
}

export interface GqlPeopleFacetRequestType {
  query?: string;
  first?: number;
  after?: string;
}

export interface GqlPeopleFacetResponseType {
  community: {
    id: string;
    peopleFacets: {
      facet: string;
      query: string;
      totalHits: number;
      hits: {
        highlighted: string;
        value: string;
      }[];
    };
  };
}

import { MembershipDocument } from '@caravel/types';
import { FirestoreTimestamp } from 'helpers';
import { makeObservable, observable } from 'mobx';

import { BaseModel, makeToJS, makeUpdate, PENDING_ID } from './base';

export type MembershipProps = MembershipDocument & Record<string, any>;

const skeletonProps: MembershipProps = {
  createdAt: undefined,
  updatedAt: undefined,
  archivedAt: undefined,
  id: PENDING_ID,
  role: 'reader',
};

export class Membership extends BaseModel<MembershipProps> implements MembershipProps {
  createdAt?: FirestoreTimestamp = undefined;
  updatedAt?: FirestoreTimestamp = undefined;
  archivedAt?: FirestoreTimestamp = undefined;

  id = skeletonProps.id;
  role = skeletonProps.role;

  constructor(props: MembershipProps) {
    super();

    makeObservable(this, {
      createdAt: observable,
      updatedAt: observable,
      archivedAt: observable,
      id: observable,
      role: observable,
    });

    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

import { Column } from '@caravel/components/src';
import { DARK_GREY, GREY_PALETTE, makeInitials } from '@caravel/utils';
import RemoveIcon from '@mui/icons-material/Remove';
import { Avatar, CardContent, IconButton, SvgIcon, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { PeopleSearchInput } from './people-search-input';

export const StaticSegmentForm = observer(() => {
  const store = useStore();
  const {
    ui: { SegmentForm },
  } = store;

  const theme = useTheme();
  const formProps = SegmentForm.formProps;
  if (!formProps) {
    return null;
  }

  function handleRemovePerson(id: string | undefined) {
    const people = formProps?.people?.filter((person: any) => person.id !== id);
    SegmentForm.onChange({
      people,
    });
  }

  return (
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: `${theme.spacing(4)} 0`,
        height: '100%',
      }}
    >
      <Column
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Typography>Search + Add People</Typography>

        <PeopleSearchInput />
      </Column>
      <Column sx={{ height: '100%', overflow: 'auto' }}>
        {formProps.people?.map(person => {
          return (
            <Column
              key={person.id}
              sx={{
                padding: '8px',
                background: '#F7F9FC',
                width: '100%',
                gap: '8px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '2.5px 0',
              }}
            >
              <Column>
                <Avatar
                  src={person.avatar}
                  alt={person.name}
                  sx={{ width: 30, height: 30, marginRight: '10px', fontSize: '12px' }}
                >
                  {makeInitials({
                    givenName: person.givenName,
                    familyName: person.familyName,
                    name: person.name,
                  })}
                </Avatar>
              </Column>
              <Column flex={1}>
                <Typography variant="headline4" fontWeight={400} fontSize={13}>
                  {person.name}
                </Typography>
                <Typography variant="headline4" fontWeight={400} fontSize={12} color={GREY_PALETTE[5]}>
                  {person.email}
                </Typography>
              </Column>
              <IconButton
                sx={{ width: '32px' }}
                size="small"
                color="success"
                onClick={() => handleRemovePerson(person.id)}
              >
                <SvgIcon component={RemoveIcon} sx={{ fill: DARK_GREY }} />
              </IconButton>
            </Column>
          );
        })}
      </Column>
    </CardContent>
  );
});

export const youtubeThumbnail = (sourceLink: string): string => {
  // TODO see if we can make this more resilient
  const videoId = sourceLink?.split('v=')[1] ?? sourceLink?.split('be/')[1];

  return `https://img.youtube.com/vi/${videoId}/default.jpg`;
};

export const vimeoThumbnail = async (sourceLink: string): Promise<string | undefined> => {
  const id = sourceLink.split('/').pop();
  if (!id) {
    return undefined;
  }
  const res = await fetch(`http://vimeo.com/api/v2/video/${id}.json`);
  const json = await res.json();
  const thumbnail = json[0].thumbnail_small;
  return thumbnail;
};

/**
 * Really basic way of determining the source, might be improved with another lib as needed
 * @param sourceLink
 * @returns
 */
export const getThumbnail = async (sourceLink: string): Promise<string | undefined> => {
  if (sourceLink.includes('vimeo.com')) {
    return await vimeoThumbnail(sourceLink);
  }

  if (sourceLink.includes('youtube.com') || sourceLink.includes('youtu.be')) {
    return youtubeThumbnail(sourceLink);
  }

  return undefined;
};

import { BillingPlanId } from '@caravel/types';
import { DARK_GREY } from '@caravel/utils/src/constants/colors';
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isBasicPlan, isEnterprisePlan, isFreePlan, isTeamPlan, isUpgrade } from 'helpers/billing-plans';
import React, { useState } from 'react';

import { FeatureColumn } from './feature-column';
import FeaturesContainer from './features-container';

const CompactSelect = styled(Select)(() => ({
  width: '100%',
  paddingTop: 0,
  paddingBottom: 0,
  height: '32px',
}));

export interface FeatureFlagsType {
  title: string;
  free: boolean;
  basic: boolean;
  team: boolean;
  enterprise: boolean;
}

export interface PlanFeatureType {
  title: string;
  features: FeatureFlagsType[];
}

export interface PlansInfoProps {
  planId?: BillingPlanId;
}

const features: PlanFeatureType[] = [
  {
    title: 'Hub features',
    features: [
      {
        title: 'Unified feedback search',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Custom designed microsurveys',
        free: false,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Reports + Charts',
        free: false,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Automations',
        free: false,
        basic: false,
        team: true,
        enterprise: true,
      },
    ],
  },
  {
    title: 'AI Features',
    features: [
      {
        title: 'Aspect-based Sentiment Analysis',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Pre-built Classifiers',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Custom Classifiers',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Supervised Classification',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Unsupervised Zero-shot Classification',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Ultra-fast Analysis',
        free: false,
        basic: false,
        team: false,
        enterprise: true,
      },
    ],
  },
  {
    title: 'Integrations + API',
    features: [
      {
        title: 'Import/Export CSV',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'App Store Reviews',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Play Store Reviews',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Slack',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Classifier API Access',
        free: true,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Webhooks',
        free: false,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Zendesk',
        free: false,
        basic: false,
        team: true,
        enterprise: true,
      },
      {
        title: 'Front',
        free: false,
        basic: false,
        team: true,
        enterprise: true,
      },
    ],
  },
  {
    title: 'Professional Services',
    features: [
      {
        title: 'Concierge Onboarding and Training',
        free: false,
        basic: false,
        team: true,
        enterprise: true,
      },
      {
        title: 'Dedicated analyst',
        free: false,
        basic: false,
        team: false,
        enterprise: true,
      },
      {
        title: 'Taxonomy Design',
        free: false,
        basic: false,
        team: false,
        enterprise: true,
      },
      {
        title: 'Text Tagging & Annotation',
        free: false,
        basic: false,
        team: false,
        enterprise: true,
      },
      {
        title: 'AI Model Development & Optimization',
        free: false,
        basic: false,
        team: false,
        enterprise: true,
      },
    ],
  },
  {
    title: 'Admin + Security',
    features: [
      {
        title: 'User Privilige Control',
        free: false,
        basic: true,
        team: true,
        enterprise: true,
      },
      {
        title: 'Automatic PII Redaction',
        free: false,
        basic: false,
        team: false,
        enterprise: true,
      },
      {
        title: '99.9% Uptime SLA',
        free: false,
        basic: false,
        team: false,
        enterprise: true,
      },
      {
        title: 'Flexible Payment Options',
        free: false,
        basic: false,
        team: false,
        enterprise: true,
      },
    ],
  },
];

export const PlansInfo = (props: PlansInfoProps) => {
  const { planId } = props;
  const [responsesBasicPlan, setResponsesBasicPlan] = useState<number>(1);
  const [responsesTeamPlan, setResponsesTeamPlan] = useState<number>(1);
  const [responsesEnterprisePlan, setResponsesEnterprisePlan] = useState<number>(30000);

  const handleChangeResponsesBasicPlan = (e: any) => {
    setResponsesBasicPlan(e.target.value as number);
  };

  const handleChangeResponsesTeamPlan = (e: any) => {
    setResponsesTeamPlan(e.target.value as number);
  };

  const handleChangeResponsesEnterprisePlan = (e: any) => {
    setResponsesEnterprisePlan(e.target.value as number);
  };

  const handleUpgrade = (plan: BillingPlanId) => () => {
    window.open(`mailto:sales@caravelapp.com?subject=${encodeURI(`Switch to ${plan} plan`)}`, '_blank');
  };

  // const handlePurchaseApi = () => {
  //   window.open(`mailto:sales@caravelapp.com?subject=${encodeURI(`Classifier API`)}`, '_blank');
  // };

  return (
    <Grid container sx={{ flexGrow: 1, paddingTop: '30px' }}>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <FeatureColumn />
          <FeatureColumn>
            <Typography variant="headline2">Free</Typography>
          </FeatureColumn>
          <FeatureColumn>
            <Typography variant="headline2">Basic</Typography>
          </FeatureColumn>
          <FeatureColumn>
            <Typography variant="headline2">Team</Typography>
          </FeatureColumn>
          <FeatureColumn>
            <Typography variant="headline2">Enterprise</Typography>
          </FeatureColumn>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <FeatureColumn />
          <FeatureColumn>
            <Typography variant="headline3" sx={{ color: DARK_GREY, display: 'block', margin: '10px 0 20px' }}>
              $0 / mo
            </Typography>
          </FeatureColumn>
          <FeatureColumn>
            <Typography variant="headline3" sx={{ color: DARK_GREY, display: 'block', margin: '10px 0 20px' }}>
              {responsesBasicPlan === 1 && '$33'}
              {responsesBasicPlan === 2 && '$65'}
              {responsesBasicPlan === 3 && '$111'}
              {` / mo`}
            </Typography>
          </FeatureColumn>
          <FeatureColumn>
            <Typography variant="headline3" sx={{ color: DARK_GREY, display: 'block', margin: '10px 0 20px' }}>
              {responsesTeamPlan === 1 && '$199'}
              {responsesTeamPlan === 2 && '$399'}
              {responsesTeamPlan === 3 && '$799'}
              {` / mo`}
            </Typography>
          </FeatureColumn>
          <FeatureColumn>
            <Typography variant="headline3" sx={{ color: DARK_GREY, display: 'block', margin: '10px 0 20px' }}>
              Custom
            </Typography>
          </FeatureColumn>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2} sx={{ marginBottom: '20px' }}>
          <FeatureColumn>
            <Typography variant="inputLabel">Usage</Typography>
          </FeatureColumn>
          <FeatureColumn>
            <Button
              disabled={isFreePlan(planId)}
              onClick={handleUpgrade('free')}
              sx={{ width: '100%' }}
              variant="contained"
            >
              {isFreePlan(planId) ? 'Current Plan' : planId && isUpgrade(planId, 'free') ? 'Upgrade' : 'Select'}
            </Button>
          </FeatureColumn>
          <FeatureColumn>
            <Button
              disabled={isBasicPlan(planId)}
              onClick={handleUpgrade(`basic-${responsesBasicPlan}` as BillingPlanId)}
              sx={{ width: '100%' }}
              variant="contained"
            >
              {isBasicPlan(planId)
                ? 'Current Plan'
                : planId && isUpgrade(planId, `basic-${responsesBasicPlan}` as BillingPlanId)
                ? 'Upgrade'
                : 'Select'}
            </Button>
          </FeatureColumn>
          <FeatureColumn>
            <Button
              disabled={isTeamPlan(planId)}
              onClick={handleUpgrade(`team-${responsesTeamPlan}` as BillingPlanId)}
              sx={{ width: '100%' }}
              variant="contained"
            >
              {isTeamPlan(planId)
                ? 'Current Plan'
                : planId && isUpgrade(planId, `team-${responsesTeamPlan}` as BillingPlanId)
                ? 'Upgrade'
                : 'Select'}
            </Button>
          </FeatureColumn>
          <FeatureColumn>
            <Button
              disabled={isEnterprisePlan(planId)}
              onClick={handleUpgrade('enterprise')}
              sx={{ width: '100%' }}
              variant="contained"
            >
              {isEnterprisePlan(planId) ? 'Current Plan' : 'Contact us'}
            </Button>
          </FeatureColumn>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Responses / mo
            </Typography>
          </FeatureColumn>
          <FeatureColumn>
            <CompactSelect value={50} displayEmpty variant="outlined">
              <MenuItem value={50}>50</MenuItem>
            </CompactSelect>
          </FeatureColumn>
          <FeatureColumn>
            <CompactSelect
              value={responsesBasicPlan}
              onChange={handleChangeResponsesBasicPlan}
              displayEmpty
              variant="outlined"
            >
              <MenuItem value={1}>250</MenuItem>
              <MenuItem value={2}>500</MenuItem>
              <MenuItem value={3}>1,000</MenuItem>
            </CompactSelect>
          </FeatureColumn>
          <FeatureColumn>
            <CompactSelect value={responsesTeamPlan} onChange={handleChangeResponsesTeamPlan}>
              <MenuItem value={1}>2,000</MenuItem>
              <MenuItem value={2}>5,000</MenuItem>
              <MenuItem value={3}>10,000</MenuItem>
            </CompactSelect>
          </FeatureColumn>
          <FeatureColumn>
            <CompactSelect value={responsesEnterprisePlan} onChange={handleChangeResponsesEnterprisePlan}>
              <MenuItem value={30000}>30,000</MenuItem>
              <MenuItem value={50000}>50,000</MenuItem>
              <MenuItem value={100000}>100,000</MenuItem>
              <MenuItem value={250000}>250,000</MenuItem>
              <MenuItem value={500000}>500,000</MenuItem>
              <MenuItem value={1000000}>1,000,000</MenuItem>
            </CompactSelect>
          </FeatureColumn>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Included team members
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              1
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              3
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              10
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Unlimited
            </Typography>
          </FeatureColumn>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Microsurveys
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Unlimited
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Unlimited
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Unlimited
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Unlimited
            </Typography>
          </FeatureColumn>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Real-time Classifiers
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              1
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              3
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              5
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Unlimited
            </Typography>
          </FeatureColumn>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Teams
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              1
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              1
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              1
            </Typography>
          </FeatureColumn>
          <FeatureColumn divider>
            <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
              Unlimited
            </Typography>
          </FeatureColumn>
        </Grid>
      </Grid>
      {features.map((feature: PlanFeatureType, idx: any) => (
        <FeaturesContainer key={idx} feature={feature} />
      ))}
      {/* <FlexBox margin="66px 0 30px" flexDirection="column">
        <Typography variant="headline2">Add-ons</Typography>
        <Typography variant="headline4" sx={{ color: DARK_GREY, marginTop: '10px' }}>
          Access Caravel’s API to use our classifiers in your own projects
        </Typography>
      </FlexBox>
      <Box
        sx={{
          display: 'flex',
          padding: '40px',
          flexDirection: 'row',
          borderRadius: '5px',
          border: `2px solid ${MEDIUM_GREY}`,
          height: '242px',
          width: '100%',
        }}
      >
        <FlexBox flexDirection="column" width="100%" maxWidth="370px">
          <Typography variant="headline2">Classifier API</Typography>
          <Typography variant="headline3" sx={{ color: DARK_GREY, marginTop: '10px', marginBottom: '20px' }}>
            $25 / mo
          </Typography>
          <Button onClick={handlePurchaseApi} sx={{ width: '100%' }} variant="contained">
            Purchase
          </Button>
        </FlexBox>
        <Divider orientation="vertical" sx={{ margin: ' 0 30px' }} flexItem />
        <FlexBox justifyContent="center" flexDirection="column" width="100%" maxWidth="370px">
          <Typography variant="body" sx={{ color: DARK_GREY }}>
            Connect to the Classifier API to send text from your apps to Caravel and get predictions.
          </Typography>
        </FlexBox>
      </Box> */}
    </Grid>
  );
};

import { AddButton, Column, Row } from '@caravel/components/src';
import { CHARCOAL_BLACK, DARK_GREY, hasEmptyStrings, isEqual, LIGHT_GREY, WHITE } from '@caravel/utils';
import { IconButton, InputAdornment, List, ListItem, SvgIcon, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStore } from 'stores';

import { ReactComponent as CloseIcon } from '../../assets/svgs/close-small.svg';

export interface DomainsFormProps {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DomainsForm = observer((props: DomainsFormProps) => {
  const store = useStore();
  const team = store.team;
  const initialDomains = team ? team.companyDomains?.slice() ?? [''] : [''];
  const [companyDomains, setCompanyDomains] = useState<string[]>(initialDomains);
  const [working, setWorking] = useState(false);

  const handleSave = async (domains?: Array<string>) => {
    if (isEqual(initialDomains, domains ?? companyDomains)) {
      return;
    }
    await store.ui.workOn(async () => {
      setWorking(true);
      try {
        await store.teams.updateCompanyDomains(domains ?? companyDomains);
      } finally {
        setWorking(false);
      }
    });
  };

  if (!team || !store.features.hasDomains) {
    return null;
  }

  return (
    <Column>
      <Typography variant="headline4">Domains</Typography>
      <Row alignItems="center" justifyContent="space-between">
        <Typography
          variant="inputLabelLine"
          sx={{
            margin: '10px 0',
            flex: 1,
            color: DARK_GREY,
            maxWidth: '375px',
            width: '100%',
          }}
        >
          Messages sent from these domains will automatically be added to the Team segment
        </Typography>
        <AddButton
          disabled={working || (companyDomains.length > 0 && hasEmptyStrings(companyDomains))}
          onClick={() => setCompanyDomains([...companyDomains, ''])}
          sx={{
            marginLeft: theme => theme.spacing(2),
          }}
        >
          Add domain
        </AddButton>
      </Row>
      <Column alignItems="flex-start">
        <List sx={{ width: '100%', marginTop: '10px' }}>
          {companyDomains.map((companyDomain, i) => {
            return (
              <ListItem
                key={`company-domain-${i}`}
                sx={{
                  padding: '5px 0',
                }}
              >
                <form
                  onSubmit={async e => {
                    e.preventDefault();
                    await handleSave();
                  }}
                  style={{
                    width: '100%',
                  }}
                >
                  <TextField
                    name="domain"
                    placeholder="yourdomain.com"
                    disabled={working}
                    value={companyDomain || ''}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            backgroundColor: 'none',
                          }}
                        >
                          <IconButton
                            sx={{
                              marginLeft: theme => theme.spacing(1),
                            }}
                            onClick={() => {
                              if (window.confirm('Are you sure?')) {
                                const domains = companyDomains.slice();
                                domains.splice(i, 1);
                                setCompanyDomains(domains);
                                handleSave(domains);
                              }
                            }}
                          >
                            <SvgIcon
                              viewBox="0 0 20 21"
                              sx={{ width: 20, height: 20, fill: CHARCOAL_BLACK }}
                              component={CloseIcon}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => {
                      const domains = companyDomains.slice();
                      domains.splice(i, 1, e.target.value);
                      setCompanyDomains([...domains]);
                    }}
                    onFocus={e => {
                      e.target.select();
                    }}
                    sx={{
                      borderRadius: '5px',
                      '& .MuiInputBase-root': {
                        boxSizing: 'border-box',
                        height: '52px',
                        borderRadius: '5px',
                        backgroundColor: LIGHT_GREY,
                        borderColor: LIGHT_GREY,
                      },
                      '& .Mui-focused.MuiInputBase-root': {
                        backgroundColor: WHITE,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: LIGHT_GREY,
                      },
                    }}
                  />
                </form>
              </ListItem>
            );
          })}
        </List>
      </Column>
    </Column>
  );
});

import { gql } from '@apollo/client';

export const GET_PEOPLE_ORG_COUNTRIES = gql`
  query getPeopleChannels($query: String, $first: Int, $after: String) {
    community {
      id
      peopleFacets(facet: "organizationCountry", query: $query, first: $first, after: $after) {
        facet
        query
        totalHits
        hits {
          highlighted
          value
        }
      }
    }
  }
`;

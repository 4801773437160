import { Column } from '@caravel/components/src';
import { Person as PersonProps } from '@caravel/types/src';
import { DARK_GREY, GREY_PALETTE } from '@caravel/utils';
import { makeInitials } from '@caravel/utils';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Avatar,
  IconButton,
  InputAdornment,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'stores';

import { ReactComponent as AddXSmall } from '../../../assets/svgs/add-x-small.svg';

export const PeopleSearchInput = observer(() => {
  const store = useStore();
  const [options, setOptions] = useState<PersonProps[]>([]);
  const [inputValue, setInputValue] = useState('');
  const {
    ui: { SegmentForm },
  } = store;

  const { formProps } = SegmentForm;
  useEffect(() => {
    if (!formProps) {
      SegmentForm.newStaticSegment();
    }
  }, [SegmentForm, formProps]);

  if (!formProps) {
    return null;
  }

  const handleSubmitSearch = useCallback(
    (request: string, callback: (results: PersonProps[]) => void) => {
      return SegmentForm.fetchPeople(request, callback);
    },
    [SegmentForm],
  );

  const fetch = useMemo(
    () =>
      debounce((request: string, callback: (results: PersonProps[]) => void) => {
        handleSubmitSearch(request, callback);
      }, 400),
    [handleSubmitSearch],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue, (results: PersonProps[]) => {
      if (active && results && results.length) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [formProps.people, inputValue, fetch]);

  return (
    <Autocomplete
      multiple
      sx={{
        fontSize: '14px',
        width: '500px',
      }}
      disableCloseOnSelect
      disableClearable
      popupIcon={null}
      includeInputInList
      value={formProps.people}
      options={options}
      inputValue={inputValue}
      onChange={(e: any, newValue) => {
        SegmentForm.onChange({
          people: newValue,
        });
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason !== 'reset') setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search by name or email"
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: '14px',
              width: '500px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option, state) => (
        <MenuItem {...props}>
          <Column sx={{ width: '100%', gap: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Column>
              <Avatar
                src={option.avatar}
                alt={option.name}
                sx={{ width: 30, height: 30, marginRight: '10px', fontSize: '12px' }}
              >
                {makeInitials({ givenName: option.givenName, familyName: option.familyName, name: option.name })}
              </Avatar>
            </Column>
            <Column flex={1}>
              <Typography variant="headline4" fontWeight={400} fontSize={13}>
                {option.name}
              </Typography>
              <Typography variant="headline4" fontWeight={400} fontSize={12} color={GREY_PALETTE[5]}>
                {option.email}
              </Typography>
            </Column>
            <IconButton sx={{ width: '32px' }} size="small">
              {state.selected ? (
                <SvgIcon component={RemoveIcon} sx={{ fill: DARK_GREY }} />
              ) : (
                <SvgIcon viewBox={'0 0 21 20'} component={AddXSmall} sx={{ fill: DARK_GREY }} />
              )}
            </IconButton>
          </Column>
        </MenuItem>
      )}
      filterOptions={x => x}
      noOptionsText="No people"
      getOptionLabel={option => option.id ?? ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
});

import { Flex } from '@caravel/components/src';
import { Condition, ConditionAttribute, ConditionChange, ConditionParam, ConditionSelectOption } from '@caravel/types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, MenuItem, Select, useTheme } from '@mui/material';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import clsx from 'clsx';
import { servicesConfig } from 'helpers/services/config';
import React, { useEffect, useState } from 'react';

import { ConditionAutocomplete } from './condition-autocomplete';
import { ConditionAutocompleteMulti } from './condition-autocomplete-multi';

const backfillAttribute = (
  condition: Condition<unknown>,
  attributes: ConditionAttribute[],
  normalize = false,
): ConditionAttribute => {
  const attribute = attributes.find(a => a.value === condition.attribute);
  return {
    ...attribute,
    operatorOptions: condition.operatorOptions ?? attribute?.operatorOptions ?? [],
    valueInput: condition.valueInput ?? attribute?.valueInput,
    value: normalize
      ? typeof condition.value === 'string'
        ? { name: condition.value, value: condition.value }
        : condition.value
      : condition.value,
  } as ConditionAttribute;
};

export interface ConditionProps {
  listIndex: number;
  index: number;
  condition: Condition<unknown>;
  attributes: ConditionSelectOption[];
  onChange: (change: ConditionChange) => void;
  onRemove: (listIndex: number, index: number) => void;
  autocompleteParams: { [key: string]: any };
  normalize?: boolean;
}

export function ConditionView({
  listIndex,
  index,
  condition,
  attributes,
  onChange,
  onRemove,
  autocompleteParams,
  normalize,
}: ConditionProps) {
  const theme = useTheme();
  const { attribute, operator, loading = false } = condition;
  const { value, operatorOptions, valueInput } = backfillAttribute(condition, attributes, normalize);

  const [attributeSelectFocused, setAttributeSelectFocused] = useState(attribute === '');
  const [attributeSelectOpen, setAttributeSelectOpen] = useState(attribute === '');

  const [operatorSelectFocused, setOperatorSelectFocused] = useState(false);
  const [operatorSelectOpen, setOperatorSelectOpen] = useState(false);

  const attributeName = attributes.find(a => a.value === attribute)?.name;

  useEffect(() => {
    if (attribute && operator === '') {
      setOperatorSelectFocused(true);
      setOperatorSelectOpen(true);
    }
  }, [attribute, operator]);

  const handleChange = (param: ConditionParam, value: any) => {
    onChange({ listIndex, index, param, value });
  };

  const renderValueInput = () => {
    if (!valueInput) return null;

    if (valueInput.type === 'date') {
      return (
        <FormControl
          variant="outlined"
          sx={{
            paddingRight: theme.spacing(2),
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: `${theme.grey[3]}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: `${theme.palette.primary.dark}`,
              },
            },
          }}
        >
          <DatePicker
            value={value as unknown as Date}
            onChange={d => handleChange('value', d)}
            renderInput={props => <TextField {...props} />}
          />
        </FormControl>
      );
    }

    if (valueInput.type === 'select') {
      return (
        <FormControl
          variant="outlined"
          sx={{
            paddingRight: theme.spacing(2),
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: `${theme.grey[3]}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: `${theme.palette.primary.dark}`,
              },
            },
          }}
        >
          <Select
            labelId="value-select-label"
            id="condition-value"
            displayEmpty
            value={value ?? ''}
            onChange={e => handleChange('value', e.target.value as string)}
            classes={{ root: clsx(value === '' && 'placeholder') } as any}
            disabled={loading}
            sx={{
              padding: theme.spacing(0),
              backgroundColor: '#fff',
              '&:focus': {
                backgroundColor: '#fff',
              },
              '&.placeholder': {
                color: theme.grey[5],
                fontStyle: 'italic',
              },
            }}
          >
            <MenuItem
              value=""
              sx={{
                color: theme.grey[5],
                fontStyle: 'italic',
              }}
            >
              Select {attributeName}
            </MenuItem>
            {valueInput?.options &&
              valueInput.options.map(({ name, value, disabled }) => (
                <MenuItem key={value} value={value} disabled={disabled}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    }

    if (valueInput.type === 'autocomplete') {
      if (valueInput.autocomplete?.multiple) {
        return (
          <ConditionAutocompleteMulti
            endpoint={servicesConfig.endpointFromPath(valueInput.autocomplete?.apiPath)}
            value={((value as unknown as ConditionSelectOption<unknown>[]) || []).map(v => ({
              ...v,
            }))}
            onChangeSelected={value => handleChange('value', value)}
            placeholder={`Search ${attributeName}`}
            autocompleteParams={autocompleteParams}
          />
        );
      }

      return (
        <ConditionAutocomplete
          endpoint={servicesConfig.endpointFromPath(valueInput.autocomplete?.apiPath)}
          value={(value as unknown as ConditionSelectOption<unknown>) || null}
          onChangeSelected={value => handleChange('value', value)}
          placeholder={`Search ${attributeName}`}
          autocompleteParams={autocompleteParams}
        />
      );
    }

    if (valueInput.type === 'text-input') {
      return (
        <TextField
          id="condition-value"
          variant="outlined"
          size="small"
          value={value || ''}
          placeholder={valueInput.placeholder}
          sx={{
            paddingRight: theme.spacing(2),
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: `${theme.grey[3]}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: `${theme.palette.primary.dark}`,
              },
            },
          }}
          onChange={e => handleChange('value', e.currentTarget.value)}
          InputProps={{
            sx: {
              padding: theme.spacing(0),
              backgroundColor: '#fff',
              '&:focus': {
                backgroundColor: '#fff',
              },
              '&.placeholder': {
                color: theme.grey[5],
                fontStyle: 'italic',
              },
            },
          }}
        />
      );
    }

    return 'unrecognized input type';
  };

  return (
    <Flex
      sx={{
        width: '100%',
        padding: theme.spacing(1, 0),
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          width: '25%',
        }}
      >
        <FormControl
          variant="outlined"
          sx={{
            paddingRight: theme.spacing(2),
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: `${theme.grey[3]}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: `${theme.palette.primary.dark}`,
              },
            },
          }}
          focused={attributeSelectFocused}
          onBlur={() => {
            setAttributeSelectFocused(false);
          }}
          onFocus={() => {
            setAttributeSelectFocused(true);
          }}
        >
          <Select
            open={attributeSelectOpen}
            labelId="attribute-select-label"
            id="attribute-select"
            displayEmpty
            value={attribute ?? ''}
            onChange={e => handleChange('attribute', e.target.value as string)}
            onClose={() => setAttributeSelectOpen(false)}
            onOpen={() => setAttributeSelectOpen(true)}
            classes={{ root: clsx(attribute === '' && 'placeholder') } as any}
            sx={{
              padding: theme.spacing(0),
              backgroundColor: '#fff',
              '&:focus': {
                backgroundColor: '#fff',
              },
              '&.placeholder': {
                color: theme.grey[5],
                fontStyle: 'italic',
              },
            }}
          >
            <MenuItem
              value=""
              sx={{
                color: theme.grey[5],
                fontStyle: 'italic',
              }}
            >
              Filter by
            </MenuItem>
            {attributes.length === 0 && <MenuItem value={attribute}>{attribute}</MenuItem>}
            {attributes.map(({ value, name }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Flex>
      <Flex
        sx={{
          width: '25%',
        }}
      >
        {operatorOptions && (
          <FormControl
            variant="outlined"
            sx={{
              paddingRight: theme.spacing(2),
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: `${theme.grey[3]}`,
                },
                '&.Mui-focused fieldset': {
                  borderColor: `${theme.palette.primary.dark}`,
                },
              },
            }}
            focused={operatorSelectFocused}
            onBlur={() => {
              setOperatorSelectFocused(false);
            }}
            onFocus={() => {
              setOperatorSelectFocused(true);
            }}
          >
            <Select
              open={operatorSelectOpen}
              labelId="operator-select-label"
              id="operator-select"
              displayEmpty
              value={operator}
              onChange={e => handleChange('operator', e.target.value as string)}
              // FIXME
              classes={{ root: clsx(operator === '' && 'placeholder') } as any}
              sx={{
                padding: theme.spacing(0),
                backgroundColor: '#fff',
                '&:focus': {
                  backgroundColor: '#fff',
                },
                '&.placeholder': {
                  color: theme.grey[5],
                  fontStyle: 'italic',
                },
              }}
              onClose={() => setOperatorSelectOpen(false)}
              onOpen={() => setOperatorSelectOpen(true)}
            >
              <MenuItem
                value=""
                sx={{
                  color: theme.grey[5],
                  fontStyle: 'italic',
                }}
              >
                Condition
              </MenuItem>
              {operatorOptions.length === 0 && <MenuItem value={operator ?? ''}>{operator}</MenuItem>}
              {operatorOptions.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Flex>
      <Flex sx={{ flexGrow: 1 }}>{operator !== '' && renderValueInput()}</Flex>
      <Flex>
        <Button
          color="inherit"
          onClick={() => onRemove(listIndex, index)}
          sx={{
            minWidth: 'auto',
            padding: '10px',
            width: '35px',
            height: '35px',
            marginRight: '-12px',
            opacity: '0.6',
            transition: 'opacity 250ms linear',
            '&:hover': {
              background: 'none',
              opacity: '1',
            },
          }}
        >
          <CloseIcon sx={{ width: '100%' }} />
        </Button>
      </Flex>
    </Flex>
  );
}

import { Row } from '@caravel/components/src';
import { Box, Divider, Link } from '@mui/material';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';

import { FilterProps } from './filter-props';
import { MultiFilterMenu } from './multi-filter-menu';

export const StaticMultiFilterMenu = observer((props: PropsWithChildren<FilterProps>) => {
  const selectAllConditions = ['any-of', 'not-any-of'];

  return (
    <>
      {selectAllConditions.includes(props.condition!) && (
        <>
          <Row sx={{ alignItems: 'center', width: '100%', justifyContent: 'space-between', padding: '4px 10px' }}>
            <Typography variant="bodySmaller">{props.value.length} Selected</Typography>
            <Link
              component="button"
              variant="bodySmaller"
              onClick={() => {
                let newValues: string[] = [];
                if (props.value.length < props.options.values.length) {
                  newValues = props.options.values;
                }
                props.onChangeFilter({ value: newValues, condition: props.condition }, true);
              }}
            >
              {props.value.length < props.options.values.length ? `Select All` : `Select None`}
            </Link>
          </Row>
          <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />
        </>
      )}
      <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
        <MultiFilterMenu {...props} />
      </Box>
    </>
  );
});

import { Column, ExtendedTroveDesign, Flex, Row } from '@caravel/components/src';
import { TroveCopy, TroveResponse } from '@caravel/types';
import { isEmptyString, isValidEmail } from '@caravel/utils';
import { FormControl, Grid, GridSize, MenuItem, Select, TextField, Typography } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import React, { useState } from 'react';

import { BackButton, DesignedButton, NextButton, RatingButton } from './controls';

// TODO: PROD-1291 - Add question skip logic to widget

export interface QuestionProps {
  question: TroveResponse;
  design: ExtendedTroveDesign;
  copy?: TroveCopy;
  working: boolean;
  last?: boolean;
  inline?: boolean;
  onBack?: () => void;
}

export interface TextQuestionProps extends QuestionProps {
  answer: string;
  onChangeAnswer: (answer: string) => void;
  onSubmit: (text: string) => void;
}

export function TextQuestion(props: TextQuestionProps) {
  const { copy, question, answer, onChangeAnswer, last, working, inline, onBack, onSubmit } = props;
  const minHeight = inline ? { flex: 1 } : { minHeight: '140px' };

  return (
    <Column
      sx={{
        width: '100%',
        height: '100%',
        flex: 1,
        justifyContent: 'space-between',
      }}
    >
      <TextField
        value={answer}
        onChange={e => onChangeAnswer(e.currentTarget.value)}
        multiline
        fullWidth
        autoFocus
        sx={{
          margin: '20px 0',
          height: '100%',
          ...minHeight,
          '& .MuiOutlinedInput-root': {
            ...minHeight,
          },
          '& textarea': {
            ...minHeight,
          },
        }}
      />
      <Row>
        <Flex sx={{ flex: 1 }} />
        {onBack && (
          <BackButton disabled={working} onClick={onBack}>
            Back
          </BackButton>
        )}
        <NextButton
          disabled={(question.required ? isEmptyString(answer) : false) || working}
          onClick={() => onSubmit(answer)}
        >
          {last ? copy?.submitButton ?? 'Send feedback' : 'Next'}
        </NextButton>
      </Row>
    </Column>
  );
}

function trimOtherPrefix(answer: string, otherLabel: string, otherSeperator: string) {
  if (answer.startsWith(otherLabel + otherSeperator)) {
    return answer.substring(otherLabel.length + otherSeperator.length, answer.length);
  } else {
    return answer;
  }
}

function addOtherPrefix(answer: string, otherLabel: string, otherSeperator: string) {
  if (answer.startsWith(otherLabel + otherSeperator)) {
    return answer;
  } else {
    return otherLabel + otherSeperator + answer;
  }
}

function getSelection(placeholder: string, answer: string, otherLabel: string, otherSeperator: string) {
  if (answer === placeholder) {
    return answer;
  }
  if (answer.startsWith(otherLabel + otherSeperator)) {
    return otherLabel;
  }
  return answer;
}

export interface MutipleChoiceQuestionProps extends QuestionProps {
  answer: string;
  otherLabel: string;
  otherSeperator: string;
  placeholder: string;
  onSubmit: (selection: string) => void;
  onChangeAnswer: (selection: string) => void;
  onChangeOther: (otherWriteIn: string) => void;
}

export function MultipleChoiceQuestion(props: MutipleChoiceQuestionProps) {
  const {
    copy,
    design,
    inline,
    answer,
    question,
    last,
    working,
    otherLabel,
    otherSeperator,
    placeholder,
    onBack,
    onSubmit,
    onChangeAnswer,
    onChangeOther,
  } = props;
  const minHeight = inline ? { flex: 1 } : { minHeight: '140px' };
  // Get rid of any blank choices that the survey maker might have left in by mistake
  // This way we can garuntee that if the selected value = '', a user did not make a selection
  const choices = question.choices?.filter(choice => choice != '');
  return (
    <Column
      sx={{
        width: '100%',
        height: '100%',
        flex: 1,
      }}
    >
      <Row sx={{ margin: '20px 0', alignItems: 'baseline', ...minHeight }}>
        <Column flex={1}>
          <FormControl
            fullWidth
            sx={{
              paddingTop: '4px',
              paddingBottom: '4px',
              marginTop: '26px',
              '& .MuiInputBase-input': {
                border: '1px solid ' + design.answers,
              },
              '& .MuiSelect-icon': {
                color: design.answers,
              },
            }}
          >
            <Select
              value={getSelection(placeholder, answer, otherLabel, otherSeperator)}
              onChange={e => {
                onChangeAnswer(e.target.value as string);
              }}
              sx={{
                marginLeft: '10px',
                marginRight: '10px',
                backgroundColor: design.inputBackground,
                color: design.answers,
              }}
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      backgroundColor: design.inputBackground,
                    },
                  },
                },
              }}
            >
              <MenuItem sx={{ display: 'none' }} key={-1} value={placeholder}>
                {placeholder}
              </MenuItem>
              {choices?.map((choice, i) => {
                return (
                  <MenuItem key={i} value={choice} sx={{ color: design.answers }}>
                    {choice}
                  </MenuItem>
                );
              })}
              {question.otherChoice && (
                <MenuItem sx={{ color: design.answers }} value={otherLabel}>
                  {otherLabel}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Column>
        {answer.startsWith(otherLabel + otherSeperator) && (
          <Column flex={3}>
            <TextField
              placeholder={'Write in your response'}
              sx={{
                '& .MuiInputBase-input': {
                  borderRadius: '4px',
                  border: '1px solid ' + design.answers,
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'red',
                    borderWidth: '0px',
                  },
                },
              }}
              value={trimOtherPrefix(answer, otherLabel, otherSeperator)}
              onChange={e => {
                onChangeOther(addOtherPrefix(e.currentTarget.value, otherLabel, otherSeperator));
              }}
            />
          </Column>
        )}
      </Row>
      <Row>
        <Flex sx={{ flex: 1 }} />
        {onBack && (
          <BackButton disabled={working} onClick={onBack}>
            Back
          </BackButton>
        )}
        <NextButton disabled={answer === '' || working} onClick={() => onSubmit(answer)}>
          {last ? copy?.submitButton ?? 'Send feedback' : 'Next'}
        </NextButton>
      </Row>
    </Column>
  );
}

export interface RatingQuestionProps extends QuestionProps {
  count: number;
  onSubmit: (rating: number) => void;
}

export function RatingQuestion(props: RatingQuestionProps) {
  const { copy, count, design, question, last, working, inline, onBack, onSubmit } = props;

  const Labels = () => (
    <Row
      sx={{
        fontFamily: theme => (theme.typography as TypographyOptions).fontFamily,
        margin: '8px',
        padding: count === 5 ? '8px' : 0,
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="bodySmall" sx={{ color: design.questions, flex: 1 }}>
        {question.minLabel}
      </Typography>
      <Typography variant="bodySmall" sx={{ textAlign: 'right', color: design.questions, flex: 1 }}>
        {question.maxLabel}
      </Typography>
    </Row>
  );

  return (
    <Column
      flex={1}
      justifyContent="space-around"
      sx={{
        padding: !inline ? `32px 0 0 0` : 0,
      }}
    >
      <Flex />

      <Column
        sx={{
          margin: `16px 0`,
          minHeight: '115px',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        {count === 5 && (
          <Column
            sx={{
              alignSelf: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              maxWidth: '360px',
            }}
          >
            <Row
              sx={{
                justifyContent: 'center',
                gap: '10px',
                width: '100%',
              }}
            >
              {new Array(count).fill(true).map((_, i) => {
                const value = i + 1;
                const isSelected = question.rating === value;
                return (
                  <Grid key={`${question.type}-${value}`} item xs={Math.floor(12 / count) as GridSize}>
                    <RatingButton
                      onClick={() => {
                        !last && onSubmit(value);
                      }}
                      isSelected={isSelected}
                    >
                      {value}
                    </RatingButton>
                  </Grid>
                );
              })}
            </Row>
            <Labels />
          </Column>
        )}

        {count === 10 && (
          <Column
            sx={{
              alignSelf: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              maxWidth: '460px',
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {new Array(count).fill(true).map((_, i) => {
                const value = i + 1;
                const isSelected = question.rating === value;
                return (
                  <Grid key={`${question.type}-${value}`} item xs={1 as GridSize}>
                    <RatingButton
                      onClick={() => {
                        !last && onSubmit(value);
                      }}
                      isSelected={isSelected}
                      sx={{ fontSize: '18px' }}
                    >
                      {value}
                    </RatingButton>
                  </Grid>
                );
              })}
            </Grid>
            <Labels />
          </Column>
        )}
      </Column>

      <Row>
        <Flex sx={{ flex: 1 }} />
        {onBack && (
          <BackButton disabled={working} onClick={onBack}>
            Back
          </BackButton>
        )}
        <NextButton
          disabled={question.rating === undefined || working}
          onClick={() => question.rating !== undefined && onSubmit(question.rating)}
        >
          {last ? copy?.submitButton ?? 'Send feedback' : 'Next'}
        </NextButton>
      </Row>
    </Column>
  );
}

export interface EmailQuestionProps extends Omit<QuestionProps, 'question'> {
  required: boolean;
  onSkip: () => void;
  onSubmit: (email: string) => void;
}

export function EmailQuestion(props: EmailQuestionProps) {
  const { required, copy, working, inline, onBack, onSubmit, onSkip } = props;
  const [email, setEmail] = useState('');
  const validEmail = isValidEmail(email);

  return (
    <Column
      sx={{
        height: '100%',
        justifyContent: 'center',
        ...(inline
          ? {
              minHeight: '100px',
            }
          : {
              minHeight: '148px',
            }),
      }}
    >
      <Flex flex={1} />
      <TextField
        value={email}
        onChange={e => setEmail(e.currentTarget.value)}
        fullWidth
        InputProps={{
          type: 'email',
        }}
      />
      <Flex flex={1} />
      <Row>
        {onBack && (
          <BackButton onClick={onBack} disabled={working}>
            Back
          </BackButton>
        )}
        <Flex flex={1} />
        {!required && (
          <DesignedButton
            onClick={onSkip}
            disabled={working}
            color="secondary"
            sx={{
              marginRight: '10px',
            }}
          >
            Skip
          </DesignedButton>
        )}
        <NextButton
          onClick={() => onSubmit(email)}
          disabled={isEmptyString(email) || working || (Boolean(email) && !validEmail)}
        >
          {copy?.submitButton ?? `Send feedback`}
        </NextButton>
      </Row>
    </Column>
  );
}

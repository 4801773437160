import { BLACK, MEDIUM_GREY, WHITE } from '@caravel/utils';
import { AppBar as MuiAppBar } from '@mui/material';
import { DASHBOARD_MENU_WIDTH } from 'components/app-navigation/app-navigation';
import { CaravelLineActivity } from 'components/caravel-loaders/caravel-line-activity';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

export const AppBar = observer(({ children }: { children?: React.ReactNode }) => {
  const store = useStore();
  return (
    <MuiAppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: WHITE,
        border: 'none',
        borderBottom: `1px solid ${MEDIUM_GREY}`,
        boxSizing: 'content-box',
        color: BLACK,
        zIndex: theme => theme.zIndex.modal - 1,
        height: store.ui.appBarHeight,
        marginLeft: DASHBOARD_MENU_WIDTH,
        width: `calc(100% - ${DASHBOARD_MENU_WIDTH}px)`,
        justifyContent: 'center',
      }}
    >
      {children}
      <CaravelLineActivity />
    </MuiAppBar>
  );
});

export default AppBar;

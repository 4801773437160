import { INFO_PALETTE, PRIMARY_PALETTE, SUCCESS_PALETTE } from '@caravel/utils';
import { SxProps, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as DinoLeaping } from '../assets/svgs/dino-leaping.svg';
import { Column } from '../flex';

interface EllipseProps {
  size: number;
  color: string;
  blur: number;
  sx?: SxProps;
}

function Ellipse(props: EllipseProps) {
  const { size, color, blur, sx } = props;
  const translate = size / 8;
  const t = size * 3 + 100;
  return (
    <Column
      sx={{
        height: `${size}px`,
        width: `${size}px`,
        borderRadius: '50%',
        backgroundColor: color,
        filter: `blur(${blur}px)`,
        position: 'absolute',
        border: '1px solid black',
        ...sx,
        // TODO: Talk to a motion designer :D
        animation: `rock ${t}ms ease-in-out infinite`,
        animationDirection: 'alternate',
        '@keyframes rock': {
          '0%': { transform: `translate(0px, ${translate}px) rotateX(0deg)` },
          '50%': { transform: `translate(${translate}px, ${translate}px) rotateX(30deg)` },
          '100%': { transform: `translate(${-translate}x, 0px) rotateX(0deg)` },
        },
      }}
    />
  );
}

interface BackgroundLoaderProps {
  title: string;
  subtitle?: string;
}

export function BackgroundLoader(props: BackgroundLoaderProps) {
  const { title, subtitle } = props;
  return (
    <Column
      sx={{ width: '100%', height: '100%', position: 'relative', alignItems: 'center', justifyContent: 'center' }}
    >
      <Ellipse
        size={328}
        color={SUCCESS_PALETTE[100]}
        blur={150}
        sx={{
          left: '50%',
          marginLeft: '-164px',
          bottom: '-250px',
        }}
      />
      <Ellipse
        size={512}
        color={PRIMARY_PALETTE[200]}
        blur={200}
        sx={{
          left: '-418px',
          bottom: '-234px',
        }}
      />
      <Ellipse
        size={512}
        color={INFO_PALETTE[50]}
        blur={200}
        sx={{
          top: '-395px',
          right: 0,
        }}
      />
      <Column alignItems="center" sx={{ maxWidth: '280px', position: 'relative', zIndex: 5 }}>
        <DinoLeaping />
        <Typography
          variant="headline2"
          sx={{ fontWeight: 500, marginTop: '64px', marginBottom: '10px', textAlign: 'center' }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="bodySmaller" sx={{ textAlign: 'center' }}>
            {subtitle}
          </Typography>
        )}
      </Column>
    </Column>
  );
}

import { DaySelect, DynamicFilterMenu } from '@caravel/components/src';
import { makeDateString, parseDate } from '@caravel/utils';
import { Dialog, MenuProps } from '@mui/material';
import { addYears } from 'date-fns';
import React, { useState } from 'react';

import { DateFilterMenu } from './date-filter-menu';
import { FilterMenu } from './filter-menu';
import { FilterProps, getFilterPropsFrom } from './filter-props';
import { NumberInputFilterMenu } from './number-input-filter-menu';
import { StaticMultiFilterMenu } from './static-multi-filter-menu';
export interface InlineValueMenuProps extends MenuProps, FilterProps {}

export const InlineValueMenu = React.forwardRef<HTMLDivElement, InlineValueMenuProps>((props, ref) => {
  const { menuProps, filterProps } = getFilterPropsFrom(props);
  const { updateDynamicFilterQuery, collectionType } = props;
  const [daySelectOpen, setDaySelectOpen] = useState(false);
  return (
    <>
      <FilterMenu {...menuProps} ref={ref}>
        {filterProps.options.type === 'multi-search' && (
          <DynamicFilterMenu
            {...filterProps}
            updateDynamicFilterQuery={updateDynamicFilterQuery}
            collectionType={collectionType}
          />
        )}
        {filterProps.options.type === 'number' && <NumberInputFilterMenu {...filterProps} />}
        {filterProps.options.type === 'multi' && (
          <StaticMultiFilterMenu
            {...filterProps}
            updateDynamicFilterQuery={updateDynamicFilterQuery}
            collectionType={collectionType}
          />
        )}
        {filterProps.options.type === 'date' && (
          <DateFilterMenu
            {...filterProps}
            onClose={() => filterProps.onCloseDateMenu && filterProps.onCloseDateMenu()}
            onOpenDaySelect={() => setDaySelectOpen(true)}
          />
        )}
      </FilterMenu>
      {filterProps.options.type === 'date' && (
        <Dialog
          open={daySelectOpen}
          onClose={() => {
            setDaySelectOpen(false);
          }}
          sx={{
            '& .MuiDialog-paper': {
              maxWidth: 'unset',
              width: 'auto',
              padding: 0,
            },
          }}
        >
          <DaySelect
            date={parseDate(filterProps.value.at(0)) ?? new Date()}
            calendar={parseDate(filterProps.value.at(0)) ?? new Date()}
            maxDate={new Date()}
            minDate={addYears(new Date(), -2)}
            onChange={d => {
              setDaySelectOpen(false);
              filterProps.onChangeFilter({
                value: [makeDateString(d)],
                condition: filterProps.condition,
              });
              filterProps.onCloseDateMenu && filterProps.onCloseDateMenu();
            }}
            onChangeCalendar={d => {
              if (d) {
                filterProps.onChangeFilter({
                  value: [makeDateString(d)],
                  condition: filterProps.condition,
                });
              }
            }}
          />
        </Dialog>
      )}
    </>
  );
});

InlineValueMenu.displayName = 'InlineValueMenu';

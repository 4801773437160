import { TeamMemberDocument } from '@caravel/types/src';

export const useTeamConnections = (teamConnections: TeamMemberDocument[], userId: string | undefined) => {
  const selfIndex = teamConnections.findIndex(connection => connection.id === userId);
  const teamConnectionsContainsSelf = selfIndex > -1;

  return {
    selfIndex: selfIndex,
    teamConnectionsContainsSelf: teamConnectionsContainsSelf,
  };
};

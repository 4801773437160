import { DARK_GREY } from '@caravel/utils/src';
import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

export const FormLabelDescription = React.forwardRef<HTMLSpanElement, TypographyProps>((props, ref) => (
  <Typography
    ref={ref}
    variant="inputLabelLine"
    {...props}
    sx={{
      color: DARK_GREY,
      marginTop: '4px',
      ...props.sx,
    }}
  />
));

FormLabelDescription.displayName = 'FormLabelDescription';

/* eslint-disable complexity */
import { AuthenticatedRoute } from 'components/authenticated-route';
import { Teams } from 'components/onboarding/teams';
import { useAppReadyCheck, useAuthCheck } from 'helpers';
import { observer } from 'mobx-react';
import React from 'react';
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { AcceptInvite } from '../accept-invite';
import { AppChrome } from '../app-chrome';
import { ExportDownloader } from '../export-downloader';
import { FinishSignInContainer } from '../onboarding/finish/finish-sign-in-container';
import { FinishSignUpContainer } from '../onboarding/finish/finish-sign-up-container';
import { TeamsCreate } from '../onboarding/teams/teams-create';
import { VerifyEmail } from '../verify-email';
import { AutomationRoutes } from './automation-routes';
import { ChampionsRoutes } from './champions-routes';
import { ComposerRoutes } from './composer-routes';
import { FeedbackRoutes } from './feedback-routes';
import { FeedsRoutes } from './feeds-routes';
import { OauthRoutes } from './oauth-routes';
import { OrganizationsRoutes } from './organizations-routes';
import { OverviewRoutes } from './overview-routes';
import { PeopleRoutes } from './people-routes';
import { ReportsRoutes } from './report-routes';
import { SegmentsRoutes } from './segments-routes';
import { SettingsRoutes } from './settings-routes';

export const AuthenticatedRoutes = observer((props: { starRender: RouteProps['render'] }) => {
  const store = useStore();
  const history = useHistory();
  const authReady = useAuthCheck(store);
  const appReady = useAppReadyCheck(store);

  if (authReady && !appReady) {
    return (
      <Switch>
        <AuthenticatedRoute path="/teams/new" component={TeamsCreate} />
        <AuthenticatedRoute path="/teams" component={Teams} />
        <Route path="/verify" component={VerifyEmail} />
      </Switch>
    );
  }

  if (!authReady || !appReady) {
    return null;
  }

  return (
    <Switch>
      <AuthenticatedRoute path="/teams/new" component={TeamsCreate} />
      <AuthenticatedRoute path="/teams" component={Teams} />
      <AuthenticatedRoute path="/settings">
        <AppChrome>
          <SettingsRoutes />
        </AppChrome>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/feed">
        <AppChrome>
          <FeedsRoutes />
        </AppChrome>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/reports">
        <AppChrome>
          <ReportsRoutes />
        </AppChrome>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/automations">
        <AppChrome>
          <AutomationRoutes />
        </AppChrome>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/exports/:exportId">
        <AppChrome>
          <ExportDownloader />
        </AppChrome>
      </AuthenticatedRoute>
      {store.features.hasCrm && (
        <AuthenticatedRoute path="/people">
          <AppChrome>
            <PeopleRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      {store.features.hasSegments && (
        <AuthenticatedRoute path="/segments">
          <AppChrome>
            <SegmentsRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      {store.features.hasOrganizations && (
        <AuthenticatedRoute path="/organizations">
          <AppChrome>
            <OrganizationsRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      {store.features.hasTrove && (
        <AuthenticatedRoute path="/feedback">
          <AppChrome>
            <FeedbackRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      {store.features.hasInsightsOverview && (
        <AuthenticatedRoute path="/overview">
          <AppChrome>
            <OverviewRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      {store.features.hasChampions && (
        <AuthenticatedRoute path="/champions">
          <AppChrome>
            <ChampionsRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      {store.features.hasChampions && (
        <AuthenticatedRoute path="/references">
          <AppChrome>
            <ChampionsRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      {store.features.hasChampions && store.features.hasFeature('proofLibrary') && (
        <AuthenticatedRoute path="/proof-library">
          <AppChrome>
            <ChampionsRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      {store.features.hasComposer && (
        <AuthenticatedRoute path="/composer">
          <AppChrome>
            <ComposerRoutes />
          </AppChrome>
        </AuthenticatedRoute>
      )}
      <Route path="/accept-invite" component={AcceptInvite} />
      <Route path="/verify" component={VerifyEmail} />
      <Route
        path="/sign-out"
        render={() => {
          store.ui.workOn(async () => {
            await store.auth.signOut();
            history.push('/sign-in');
          });
          return null;
        }}
      />
      <Route path="/finish-sign-in" component={FinishSignInContainer} />
      <Route path="/finish-sign-up" component={FinishSignUpContainer} />
      <Route path="/oauth" component={OauthRoutes} />

      <Route path="*" render={props.starRender} />
    </Switch>
  );
});

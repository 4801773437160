import { INFO_PALETTE, SUCCESS_PALETTE } from '@caravel/utils';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, SvgIcon, useTheme } from '@mui/material';
import React from 'react';
import Markdown from 'react-markdown';

import { Column, Flex, Row } from '../flex';
import { CaravelDeterminateProgress, CaravelProgress, CaravelSpinner } from '../loaders';
import { ReactComponent as CheckCircleIcon } from './svgs/check-circle.svg';
import { ReactComponent as CloseIcon } from './svgs/close.svg';
import { ReactComponent as WarningIcon } from './svgs/warning-triangle.svg';

export interface ToastProps {
  message: string;
  onDismiss?: () => void;
  dismissable?: boolean;
  progress?: number;
  icon?: 'spinner' | 'info' | 'check' | 'alert';
  progressType?: 'determinate' | 'indeterminate';
}

const Toast = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
  const { message, dismissable, onDismiss, progress, icon, progressType } = props;
  const theme = useTheme();
  return (
    <Row
      ref={ref}
      sx={{
        minWidth: '300px',
        minHeight: '64px',
        borderRadius: '5px',
        backgroundColor: theme.grey[6],
        boxShadow: theme.shadows[2],
        padding: theme.spacing(1, 1, 1, 2),
        ...(icon === 'alert'
          ? {
              backgroundColor: theme.palette.error.main,
            }
          : {}),
      }}
    >
      <Column
        sx={{
          flex: 1,
          justifyContent: 'center',
          color: theme.grey[0],
        }}
      >
        <Row sx={{ alignItems: 'center' }}>
          {icon === 'alert' && <SvgIcon component={WarningIcon} sx={{ fill: 'none', marginRight: theme.spacing(1) }} />}
          {icon === 'info' && (
            <InfoIcon htmlColor={INFO_PALETTE[200]} sx={{ fontSize: 22, marginRight: theme.spacing(1) }} />
          )}
          {icon === 'check' && (
            <SvgIcon component={CheckCircleIcon} sx={{ fill: 'none', marginRight: theme.spacing(1) }} />
          )}
          {icon === 'spinner' && <CaravelSpinner sx={{ fontSize: 22, marginRight: theme.spacing(1) }} />}

          <Flex
            sx={{
              flex: 1,
              flexDirection: 'column',
              '& *': {
                ...theme.typography.bodySmallest,
              },
              '& p': {
                margin: 0,
              },
              '& strong': {
                fontWeight: 600,
              },
              '& a': {
                color: theme.grey[0],
                '&:hover': {
                  color: SUCCESS_PALETTE[200],
                },
              },
            }}
          >
            <Markdown>{message}</Markdown>
          </Flex>
        </Row>

        <Row>
          {progressType === 'indeterminate' && <CaravelProgress sx={{ margin: '10px 0 0 0' }} />}
          {progressType === 'determinate' && progress !== undefined && progress > 0 && (
            <CaravelDeterminateProgress progress={progress} />
          )}
        </Row>
      </Column>

      <Column sx={{ marginLeft: theme.spacing(1) }}>
        <IconButton
          sx={{
            width: '16px',
            height: '16px',
            padding: 0,
          }}
          onClick={onDismiss}
          disabled={dismissable !== undefined && !dismissable}
        >
          <CloseIcon />
        </IconButton>
      </Column>
    </Row>
  );
});

Toast.displayName = 'Toast';

export { Toast };

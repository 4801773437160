import { Column, Row } from '@caravel/components/src';
import { GREY_PALETTE, PRIMARY_PALETTE, WHITE } from '@caravel/utils';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from 'stores';

import { ReactComponent as ProofLibraryIcon } from '../../assets/svgs/Building-2.svg';
import { ReactComponent as ReferencesIcon } from '../../assets/svgs/circle-intersect.svg';
import { ReactComponent as InfoIcon } from '../../assets/svgs/information.svg';
import { ReactComponent as CommsorLogo } from '../../assets/svgs/logo-white-bg-text.svg';
import { ReactComponent as HelpIcon } from '../../assets/svgs/navigation/help.svg';
import { ReactComponent as InviteIcon } from '../../assets/svgs/navigation/invite.svg';
import { ReactComponent as SettingsMainIcon } from '../../assets/svgs/settings.svg';
import { ReactComponent as SettingsIcon } from '../../assets/svgs/settings-sliders.svg';
import { ReactComponent as YourChampionsIcon } from '../../assets/svgs/user.svg';
import { FlexBox } from '../flex-box';
import { RouterLink } from '../router-link';
import { SettingsDrawer } from './settings/settings-drawer';

export const DASHBOARD_MENU_WIDTH = 240;
export const DASHBOARD_MENU_CLOSED_WIDTH = 60;
export const NAV_FONT_SIZE = 14;

export const StyledList = styled(List)({
  margin: '20px 9px 20px 13px',
});

export const StyledListItem = styled(ListItemButton)({
  padding: '0 8px 0 8px',
  borderRadius: '4px',
  margin: '0 0 5px 0',
  minWidth: '39px',
  height: '32px',
  '&:hover': {
    backgroundColor: GREY_PALETTE[2],
  },
  '&:active': {
    background: PRIMARY_PALETTE['50'],
  },
  '& .MuiListItemText-root': {
    '& > *': {
      fontSize: `${NAV_FONT_SIZE}px`,
      lineHeight: `${NAV_FONT_SIZE}px`,
    },
  },
});

export const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '0px',
  paddingTop: '3px',
  paddingBottom: '3px',
  paddingRight: '10px',
});

const StyledMenuItem = styled(MenuItem)({
  height: '30px',
  fontSize: `${NAV_FONT_SIZE}px`,
  lineHeight: `${NAV_FONT_SIZE}px`,
  '&:hover': {
    backgroundColor: GREY_PALETTE[2],
  },
  '& .MuiListItemText-root': {
    '& > *': {
      fontSize: `${NAV_FONT_SIZE}px`,
      lineHeight: `${NAV_FONT_SIZE}px`,
    },
  },
});

const CoreItem = observer(
  ({
    icon,
    label,
    to,
    otherMatches,
  }: {
    icon: React.ReactNode;
    label: string;
    to: string;
    otherMatches?: string[];
  }) => {
    const location = useLocation();
    const matchesPath = useMemo(() => {
      const matchesTo = location.pathname.startsWith(to);
      if (!otherMatches) {
        return matchesTo;
      }
      const matchesOther = otherMatches.some(m => {
        return location.pathname.startsWith(m);
      });
      return matchesTo || matchesOther;
    }, [location.pathname, to, otherMatches]);

    return (
      <RouterLink to={to} color="textPrimary" id={`main-menu-${label}`} title={label} underline="none">
        <StyledListItem
          sx={{
            ...(matchesPath ? { backgroundColor: PRIMARY_PALETTE['50'] } : {}),
            fill: 'none',
            stroke: 'none',
            '& [data-bg-fill]': {
              fill: matchesPath ? PRIMARY_PALETTE[100] : GREY_PALETTE[2],
            },
            '& [data-bg-stroke]': {
              stroke: matchesPath ? PRIMARY_PALETTE[100] : GREY_PALETTE[2],
            },
            '& [data-fg-fill]': {
              fill: matchesPath ? WHITE : GREY_PALETTE[5],
            },
            '& [data-fg-stroke]': {
              stroke: matchesPath ? WHITE : GREY_PALETTE[5],
            },
          }}
        >
          <StyledListItemIcon>{icon}</StyledListItemIcon>
          <ListItemText primary={label} />
        </StyledListItem>
      </RouterLink>
    );
  },
);

export const AppNavigation = observer(() => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const store = useStore();
  const features = store.features;
  const [anchorElMain, setAnchorElMain] = React.useState<null | HTMLElement>(null);
  const [anchorElHelp, setAnchorElHelp] = React.useState<null | HTMLElement>(null);
  const [lastPage, setLastPage] = React.useState<string>('/');

  const [navStackCounter, setNavStackCounter] = React.useState<number>(0);

  const [settingsOpen, setSettingsOpen] = React.useState(location.pathname.startsWith('/settings'));
  const openMain = Boolean(anchorElMain);
  const openHelp = Boolean(anchorElHelp);
  const highlightMainListItem = Boolean(anchorElMain);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleBetaPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBetaPopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleClickListItemMain = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMain(event.currentTarget);
  };

  const handleClickListItemHelp = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleClickListItemSettings = () => {
    setSettingsOpen(true);
  };

  const handleCloseMain = () => {
    setAnchorElMain(null);
  };

  const handleCloseHelp = () => {
    setAnchorElHelp(null);
  };

  const onClickSignOut = async () => {
    handleCloseMain();
    await store.auth.signOut();
    history.push('/sign-in');
  };

  const handleBack = () => {
    // Hitting the back arrow always closes the settings menu
    history.push(lastPage || '/');
    setSettingsOpen(false);
  };

  useEffect(() => {
    if (!location.pathname.startsWith('/settings')) {
      setLastPage(location.pathname);
      setSettingsOpen(false);
    }
    if (!settingsOpen && location.pathname.startsWith('/settings')) {
      setSettingsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Column
      sx={{
        zIndex: theme.zIndex.modal - 2,
        overflow: 'auto',
        height: '100vh',
        background: theme.grey[0],
        padding: '0',
        width: DASHBOARD_MENU_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        borderRight: `1px solid ${theme.grey[2]}`,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      <Column>
        <StyledList>
          <StyledListItem
            onClick={handleClickListItemMain}
            sx={{
              backgroundColor: highlightMainListItem ? theme.grey[2] : theme.grey[0],
              margin: '0 0 18px 0',
            }}
          >
            <StyledListItemIcon>
              <Avatar
                src={store.teams.currentTeam?.logoUrl}
                alt={store.teams.currentTeam?.displayName}
                sx={{ width: '20px', height: '20px' }}
              >
                {createInitials(store.teams.currentTeam?.displayName || '')}
              </Avatar>
            </StyledListItemIcon>
            <ListItemText
              primary={store.teams.currentTeam?.displayName}
              sx={{
                '& span': {
                  overflow: 'hidden',
                  padding: theme => theme.spacing(1, 0),
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            />
          </StyledListItem>
          <Menu
            id="lock-menu"
            anchorEl={anchorElMain}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }}
            open={openMain}
            sx={{
              marginTop: '8px',
              padding: '8px 0 8px 0',
              '& .MuiMenu-paper': {
                left: '8px !important',
                width: '224px',
                boxShadow: '0px 4px 16px ' + theme.palette.menu.dark,
              },
              '& ul': {
                background: theme.grey[1],
              },
            }}
            onClose={handleCloseMain}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            <StyledMenuItem
              sx={{}}
              onClick={() => {
                history.push(history.location.pathname);
                handleCloseMain();
                setSettingsOpen(true);
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem
              onClick={() => {
                handleCloseMain();
                history.push('/teams');
              }}
            >
              Switch teams
            </StyledMenuItem>
            <StyledMenuItem onClick={onClickSignOut}>Log out</StyledMenuItem>
          </Menu>

          {/* Core navigation items */}
          <CoreItem
            icon={<YourChampionsIcon style={{ width: '24px', height: '24px', color: GREY_PALETTE[7] }} />}
            label="Your Champions"
            to="/champions"
          />
          <CoreItem
            icon={<ReferencesIcon style={{ width: '24px', height: '24px', color: GREY_PALETTE[7] }} />}
            label="References"
            to="/references"
          />
          {features.hasFeature('proofLibrary') && (
            <CoreItem
              icon={<ProofLibraryIcon style={{ width: '24px', height: '24px', color: GREY_PALETTE[7] }} />}
              label="Proof Library"
              to="/proof-library"
            />
          )}

          <Box sx={{ minHeight: '20px' }} />
        </StyledList>
      </Column>
      <SettingsDrawer open={settingsOpen} onBack={handleBack} />
      <FlexBox flex={1} />
      <Column>
        <StyledList>
          {store.members.current?.canWrite && (
            <RouterLink
              to="/settings/team?invite=true"
              color="textPrimary"
              id="main-menu-invite"
              title="Invite"
              underline="none"
            >
              <StyledListItem>
                <StyledListItemIcon>
                  <InviteIcon />
                </StyledListItemIcon>
                <ListItemText primary="Invite to team" />
              </StyledListItem>
            </RouterLink>
          )}

          <Menu
            id="help-lock-menu"
            anchorEl={anchorElHelp}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }}
            open={openHelp}
            sx={{
              '& .MuiMenu-paper': {
                left: '8px !important',
                width: '224px',
                boxShadow: '0px 4px 16px ' + theme.palette.menu.dark,
              },
              '& ul': {
                background: theme.grey[0],
              },
            }}
            onClose={handleCloseHelp}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            <MenuItem component="a" href="mailto:support@commsor.com" target="_blank" rel="noopener noreferrer">
              Contact Support
            </MenuItem>
            <MenuItem component="a" href="https://intercom.help/commsor/en/" target="_blank" rel="noopener noreferrer">
              View docs
            </MenuItem>
          </Menu>
          <StyledListItem onClick={handleClickListItemSettings}>
            <StyledListItemIcon>
              <SettingsMainIcon style={{ width: '24px', height: '24px', color: GREY_PALETTE[7] }} />
            </StyledListItemIcon>
            <ListItemText primary="Settings" />
          </StyledListItem>
          <StyledListItem onClick={handleClickListItemHelp}>
            <StyledListItemIcon>
              <HelpIcon />
            </StyledListItemIcon>
            <ListItemText primary="Help" />
          </StyledListItem>
          <Row sx={{ padding: '10px 8px', gap: 0.5, alignContent: 'center' }}>
            <CommsorLogo style={{ height: '20px' }} />
            <Row
              aria-owns={open ? 'beta-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handleBetaPopoverOpen}
              onMouseLeave={handleBetaPopoverClose}
              sx={{
                justifyContent: 'center',
                alignSelf: 'center',
                borderRadius: '4px',
                padding: '2px 4px',
                background: `linear-gradient(180deg, #FED74D 0%, #FDAB00 100%)`,
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: 700, alignSelf: 'center' }}>BETA</Typography>
              <InfoIcon style={{ color: 'inherit', width: '12px', height: '12px' }} />
            </Row>
            <Popover
              id="beta-popover"
              sx={{
                pointerEvents: 'none',
              }}
              PaperProps={{
                sx: {
                  background: 'none',
                  border: 'none',
                  overflow: 'hidden',
                },
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={handleBetaPopoverClose}
              disableRestoreFocus
            >
              <Column
                sx={{
                  background: '#1B2644',
                  padding: '4px 10px',
                  width: '248px',
                  margin: '16px 0px',
                  borderRadius: '4px',
                  boxShadow: `0px 6px 14px 0px rgba(0, 0, 0, 0.12),0px 10px 18px 0px rgba(0, 0, 0, 0.04), 0px 4px 14px -10px rgba(0, 0, 0, 0.07)`,

                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    right: '50%',
                    bottom: '10px',
                    width: 0,
                    height: 0,
                    borderStyle: 'solid',
                    borderWidth: '8px 8px 0 8px',
                    borderColor: `#1B2644 transparent transparent transparent`,
                    zIndex: 9999,
                  },
                }}
              >
                <Typography
                  sx={{
                    color: GREY_PALETTE[2],
                    fontWeight: 300,
                  }}
                  variant="bodySmall"
                >
                  Open beta ends 2023-11-03. After that time you'll need switch to a free or paid plan. Plan details
                  coming soon.
                </Typography>
              </Column>
            </Popover>
          </Row>
        </StyledList>
      </Column>
    </Column>
  );
});
export default AppNavigation;

const createInitials = (name: string) => {
  if (!name) return null;
  const [first, second] = name.split(' ');
  return `${first[0]}${second ? second[0] : ''}`.toLocaleUpperCase();
};

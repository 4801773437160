// Wrap input with commsor label/description/required pattern
// Should replace old label with this at some point
import { WARM_SHADES_PALETTE } from '@caravel/utils';
import { Skeleton, StandardTextFieldProps, Typography } from '@mui/material';
import React from 'react';

import { Column } from '../flex';
import { FormLabel } from './form-label';

/*
 * MUI TextField Wrapper with extra customizations
 */
export interface FormInputContainerProps extends StandardTextFieldProps {
  description?: string;
  children: React.ReactNode;
  loading?: boolean;
}

export const FormInputContainer = (props: FormInputContainerProps) => {
  return (
    <Column sx={{ gap: '4px' }}>
      {props.label && !props.loading && <FormLabel required={props.required}>{props.label}</FormLabel>}
      {props.label && props.loading && (
        <Skeleton>
          <FormLabel required={props.required}>{props.label}</FormLabel>
        </Skeleton>
      )}
      {props.description && !props.loading && (
        <Typography variant="inputLabelLine" color={WARM_SHADES_PALETTE[3]}>
          {props.description}
        </Typography>
      )}
      {props.description && props.loading && (
        <Skeleton>
          <Typography variant="inputLabelLine" color={WARM_SHADES_PALETTE[3]}>
            {props.description}
          </Typography>
        </Skeleton>
      )}
      {props.loading ? <Skeleton sx={{ maxWidth: 'none' }}>{props.children}</Skeleton> : props.children}
    </Column>
  );
};

import { GREY_PALETTE } from '@caravel/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Divider, Grid, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { Organization } from 'models/organization';
import React from 'react';

const CountDisplay = observer((props: { label: string; count: number; tooltip?: string }) => {
  const { label, count, tooltip } = props;
  return (
    <Grid item xs={4}>
      <Typography variant="bodySmallest" color={GREY_PALETTE[5]} component="p" marginBottom="4px">
        {label}
      </Typography>
      {tooltip && (
        <Tooltip sx={{ margin: '0 0 0 5px', height: '10px', width: '10px', color: GREY_PALETTE[4] }} title={tooltip}>
          <InfoOutlinedIcon color="primary" />
        </Tooltip>
      )}

      <Typography variant="bodySmaller" color={GREY_PALETTE[7]} component="p">
        {count.toLocaleString()}
      </Typography>
    </Grid>
  );
});

export const PeopleSection = observer((props: { organization: Organization }) => {
  const { organization } = props;
  return (
    <>
      <Divider sx={{ margin: '10px 0' }} />

      <Grid container sx={{ justifyContent: 'center', width: '100%' }}>
        <CountDisplay label="People" count={organization.peopleCount} />
        <CountDisplay label="Members" count={organization.memberCount} />
        <CountDisplay label="Active Members" count={organization.activeMemberCount} />
      </Grid>
    </>
  );
});

import { Column, KebabMenuOption } from '@caravel/components/src';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from 'stores';

import { AppToolbar } from '../../app-toolbar';
import { BoltIcon, EditIcon, ExportPeople, SpeechBubbleIcon, TrashCanIcon } from '../segments-icons';
import { SegmentPeopleList } from './segment-people-list';

export const SegmentDetail = observer(() => {
  const store = useStore();
  const history = useHistory();

  const {
    ui,
    teams: { segments },
  } = store;
  const { segmentId } = useParams<{ segmentId: string }>();

  const segment = segments.collection.find(n => n.id === segmentId);
  if (!segment) return null;

  const handleEditSegment = () => {
    history.push(`/segments/${segmentId}/edit`);
  };

  const handleDeleteSegment = (id: string) => {
    if (window.confirm('Are you sure you want to delete this Segment?')) {
      ui.workOn(async () => {
        await segments.deleteSegment(id);
        history.goBack();
      });
    }
  };

  const handleExportSegment = async (id: string) => {
    ui.workOn(async () => {
      await segments.exportSegment(id);
    });
  };

  const menuOptions: KebabMenuOption[] = [
    {
      enabled: false,
      icon: <SpeechBubbleIcon />,
      label: 'Message Segment',
      onClick: () => console.log('Message Segment clicked'),
    },
    {
      enabled: false,
      divider: true,
      icon: <BoltIcon viewBox="0 0 24 24" width="20px" height="20px" />,
      label: 'Enroll in Automation',
      onClick: () => console.log('Enroll in Automation clicked'),
    },
    {
      enabled: true,
      divider: true,
      icon: <ExportPeople style={{ width: '20px', height: '20px' }} />,
      label: 'Export people',
      onClick: () => handleExportSegment(segmentId!),
    },
    {
      enabled: true,
      icon: <TrashCanIcon />,
      label: 'Delete Segment',
      color: '#B23858',
      onClick: () => handleDeleteSegment(segmentId!),
    },
  ];

  return (
    <Column sx={{ height: '100%', width: '100%' }}>
      <AppToolbar
        actionButton={{
          startIcon: EditIcon,
          text: 'Edit Segment',
          onClick: handleEditSegment,
        }}
        backButton
        title={segment.name}
        subtitle={`${segment.peopleCount} ${segment.peopleCount! > 1 ? 'People' : 'Person'}`}
        kebabMenuOptions={menuOptions}
      />

      <SegmentPeopleList segment={segment} />
    </Column>
  );
});

import { NEUTRALS } from '@caravel/utils/src';
import { Select, SelectProps } from '@mui/material';
import React, { forwardRef } from 'react';

import { ReactComponent as ChevronDown } from './svgs/chevron-down.svg';

export const SelectBox = forwardRef<HTMLDivElement, SelectProps>(({ children, disabled }, ref) => (
  <Select
    ref={ref}
    disabled={disabled}
    displayEmpty
    IconComponent={ChevronDown}
    onChange={e => ({})}
    MenuProps={{
      PaperProps: {
        sx: {
          maxHeight: '256px',
          maxWidth: '750px',
          '& .MuiMenuItem-root': {
            '&:hover': {
              background: NEUTRALS.JASMINE,
            },
            '&.Mui-focusVisible': {
              '&:hover': {
                background: NEUTRALS.JASMINE,
              },
              background: 'unset',
            },
          },
        },
      },
    }}
    sx={{
      height: '44px',
      fontSize: '14px',
      borderRadius: '4px',
      borderColor: `${NEUTRALS.IRISH_BREAKFAST}`,
      '& .MuiSelect': {
        '&-icon': {
          top: 'unset',
        },
      },
      '&.MuiOutlinedInput': {
        '&-root': {
          fontSize: '13px',
          '&.Mui-focused fieldset': {
            borderColor: `${NEUTRALS.IRISH_BREAKFAST}`,
          },
          '& .segment-description': {
            display: 'none',
          },
          '& .MuiSvgIcon-root': {
            display: 'none',
          },
        },
      },
    }}
  >
    {children}
  </Select>
));

SelectBox.displayName = 'SelectBox';

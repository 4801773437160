import { Flex, Row } from '@caravel/components/src';
import { GREY_PALETTE, LIGHT_GREY, NEUTRALS } from '@caravel/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, InputAdornment, Popover, Skeleton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';

export interface ColorFieldProps {
  label: string;
  color: string;
  opacity?: number;
  onChange: (color: string) => void;
  onChangeOpacity?: (opacity: number) => void;
  marginBottom?: string;
  loading?: boolean;
}

export function ColorField(props: ColorFieldProps) {
  const { label, color, opacity, onChange, onChangeOpacity, marginBottom, loading } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  const handleChangeOpacity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber;
    onChangeOpacity && onChangeOpacity(value > 100 ? 100 : value < 0 ? 0 : value);
  };

  const handleClickSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Row
      sx={{
        alignItems: 'center',
        width: '100%',
        marginBottom: marginBottom ? marginBottom : theme => theme.spacing(2),
      }}
    >
      <Typography variant="headline4" sx={{ flex: 1, marginRight: '5px' }}>
        {loading ? <Skeleton sx={{ maxWidth: '200px', height: '30px' }} /> : label}
      </Typography>
      {!loading && (
        <TextField
          value={color}
          fullWidth={false}
          onChange={handleTextChange}
          sx={{
            width: 100,
            '& .MuiInputBase-input': {
              textTransform: 'uppercase',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: NEUTRALS.JASMINE,
            },
          }}
          onBlur={() => {
            if (color.startsWith('#')) {
              return;
            }
            onChange(`#${color}`);
          }}
          inputProps={{
            onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            },
            sx: {
              boxSizing: 'border-box',
              fontSize: 13,
              height: 32,
            },
          }}
        />
      )}
      {loading && (
        <Skeleton>
          <TextField
            value={color}
            fullWidth={false}
            onChange={handleTextChange}
            sx={{
              width: 100,
              '& .MuiInputBase-input': {
                textTransform: 'uppercase',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: NEUTRALS.JASMINE,
              },
            }}
            onBlur={() => {
              if (color.startsWith('#')) {
                return;
              }
              onChange(`#${color}`);
            }}
            inputProps={{
              onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              },
              sx: {
                boxSizing: 'border-box',
                fontSize: 13,
                height: 32,
              },
            }}
          />
        </Skeleton>
      )}
      {opacity !== undefined && !loading && (
        <TextField
          type="number"
          value={opacity}
          fullWidth={false}
          onChange={handleChangeOpacity}
          sx={{
            width: 86,
            marginLeft: '10px',
          }}
          inputProps={{
            onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            },
            min: 0,
            max: 100,
            step: 1,
            sx: {
              boxSizing: 'border-box',
              fontSize: 13,
              height: 32,
            },
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      )}
      {opacity !== undefined && loading && (
        <Skeleton>
          <TextField
            type="number"
            value={opacity}
            fullWidth={false}
            onChange={handleChangeOpacity}
            sx={{
              width: 86,
              marginLeft: '10px',
            }}
            inputProps={{
              onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              },
              min: 0,
              max: 100,
              step: 1,
              sx: {
                boxSizing: 'border-box',
                fontSize: 13,
                height: 32,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Skeleton>
      )}
      {!loading && (
        <Button
          variant="outlined"
          onClick={handleClickSelect}
          color="inherit"
          startIcon={
            <Flex
              sx={{
                backgroundColor: color,
                border: `1px solid ${NEUTRALS.JASMINE}`,
                borderRadius: '5px',
                width: 22,
                height: 22,
              }}
            />
          }
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            borderColor: NEUTRALS.JASMINE,
            color: GREY_PALETTE[7],
            height: 32,
            paddingLeft: '5px',
            paddingRight: '5px',
            marginLeft: '10px',
            '& .MuiButton-startIcon': {
              marginRight: 0,
            },
          }}
        />
      )}
      {loading && (
        <Skeleton sx={{ marginLeft: '4px', maxWidth: '60px' }}>
          <Button
            variant="outlined"
            onClick={handleClickSelect}
            color="inherit"
            startIcon={
              <Flex
                sx={{
                  backgroundColor: color,
                  border: `1px solid ${NEUTRALS.JASMINE}`,
                  borderRadius: '5px',
                  width: 22,
                  height: 22,
                }}
              />
            }
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              borderColor: NEUTRALS.JASMINE,
              color: GREY_PALETTE[7],
              height: 32,
              paddingLeft: '5px',
              paddingRight: '5px',
              marginLeft: '10px',
              '& .MuiButton-startIcon': {
                marginRight: 0,
              },
            }}
          />
        </Skeleton>
      )}
      <Popover
        id={open ? `${label}-color-picker` : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseSelect}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          variant: 'elevation',
          sx: {
            overflow: 'visible',
            backgroundColor: LIGHT_GREY,
            padding: theme => theme.spacing(1),
          },
        }}
      >
        <Flex
          sx={{
            '& .react-colorful': {
              borderRadius: 0,
              flexGrow: 0,
              width: '150px',
              height: '150px',
            },
            '& .react-colorful__saturation': {
              borderRadius: 0,
              borderBottomWidth: 0,
            },
            '& .react-colorful__saturation-pointer': {
              borderWidth: 3,
              width: '15px',
              height: '15px',
            },
            '& .react-colorful__hue': {
              borderRadius: 0,
              width: '150px',
              height: '10px',
              marginTop: theme => theme.spacing(1),
            },
            '& .react-colorful__hue-pointer': {
              borderRadius: 0,
              width: '10px',
              height: '20px',
            },
          }}
        >
          <HexColorPicker color={color} onChange={onChange} />
        </Flex>
      </Popover>
    </Row>
  );
}

import { Row, TextWithEmoji } from '@caravel/components/src';
import { Activity } from '@caravel/types/src/activities';
import { GREY_PALETTE } from '@caravel/utils';
import { Box, Typography } from '@mui/material';
import { formatDistance } from 'date-fns';
import React from 'react';

const getActivityTitle = (activity: Activity) => {
  if (!activity.eventId) {
    return <TextWithEmoji text={activity.title ?? activity.primaryLabel} variant="tooltip" />;
  }

  // Until we have control over how we title our activities, I need to do some
  // Funky stuff to extract the title from the sentence.
  const activityTitle = activity.title.substring(15);
  const prefix = activity.title.substring(0, 15);

  if (prefix !== 'Registered for ') {
    return <TextWithEmoji text={activity.title ?? activity.primaryLabel} variant="tooltip" />;
  }

  return (
    <Typography variant="tooltip" sx={{ color: GREY_PALETTE[5], marginLeft: '5px' }}>
      {prefix}
      <a href={`/events/${activity.eventId}/registrations`} target="_blank" rel="noreferrer">
        <TextWithEmoji text={activityTitle ?? activity.primaryLabel} variant="tooltip" />
      </a>
    </Typography>
  );
};

export interface ActivityHeaderProps {
  activity: Activity;
}

export const ActivityHeader = (props: ActivityHeaderProps) => {
  const { activity } = props;
  return (
    <Row sx={{ justifyContent: 'space-between' }}>
      <Row
        sx={{
          alignItems: 'center',
          height: '30px',
          marginLeft: '10px',
        }}
      >
        {getActivityTitle(activity)}
      </Row>
      <Row>
        <Typography variant="tooltip" sx={{ color: GREY_PALETTE[5], marginLeft: '5px' }}>
          {formatDistance(new Date(activity.occurredAt), new Date()) + ' ago'}
        </Typography>
      </Row>
    </Row>
  );
};

import { GREY_PALETTE } from '@caravel/utils/src';
import { Typography } from '@mui/material';
import { SxProps, TypographyProps } from '@mui/system';
import React from 'react';

import { Flex } from './flex';

export const RatingChip = React.forwardRef<
  HTMLSpanElement,
  { rating: number; max: number; sx?: SxProps } & TypographyProps
>((props, ref) => {
  const { rating, max, sx, ...rest } = props;
  return (
    <Typography
      {...rest}
      ref={ref}
      variant="bodySmall"
      sx={{
        height: '27px',
        borderRadius: '5px',
        backgroundColor: GREY_PALETTE[2],
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-start',
        ...sx,
      }}
    >
      <Flex component="strong">{rating}</Flex>
      <Flex component="span" sx={{ padding: '0 5px' }}>
        {'/'}
      </Flex>
      <Flex component="span">{max}</Flex>
    </Typography>
  );
});

RatingChip.displayName = 'RatingChip';

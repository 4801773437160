import Delete from '@mui/icons-material/Delete';
import { Button, ButtonProps } from '@mui/material';
import React, { forwardRef } from 'react';

import { AddXSmall } from './svg-icons';

export interface AddButtonProps extends ButtonProps {}

export const AddButton = forwardRef<HTMLButtonElement, AddButtonProps>(({ children, ...props }, ref) => {
  return (
    <Button variant="outlined" startIcon={<AddXSmall />} {...props} ref={ref}>
      {children}
    </Button>
  );
});
AddButton.displayName = 'AddButton';

export const DeleteIconButton = (props: ButtonProps) => (
  <Button
    {...props}
    variant="outlined"
    size="small"
    color="inherit"
    sx={{
      padding: '6px',
      minWidth: 'unset',
      '& svg': {
        fontSize: '20px',
      },
      ...props.sx,
    }}
  >
    <Delete />
  </Button>
);

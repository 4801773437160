import { CaravelSpinner } from '@caravel/components/src';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { useStore } from 'stores';

import { DynamicSegmentForm } from '../../segments/segment-form/dynamic-segment-form';

export const IdealCustomerProfileView = observer(() => {
  const store = useStore();

  const {
    ui: { SegmentForm },
  } = store;

  async function handleSaveSegment() {
    await store.ui.workOn(async () => {
      if (SegmentForm.formProps?.id) await SegmentForm.updateSegment();
      else {
        const id = await SegmentForm.createSegment();
        if (id) SegmentForm.editICPSegment();
      }
    });
  }

  useEffect(() => {
    SegmentForm.editICPSegment();
    return () => SegmentForm.resetFormProps();
  }, [SegmentForm]);

  return (
    <>
      <DynamicSegmentForm />
      <Button
        variant="outlined"
        disabled={!SegmentForm.isValid || !SegmentForm.hasChanges || SegmentForm.updating || SegmentForm.creating}
        onClick={handleSaveSegment}
        sx={{ marginBottom: '80px' }}
      >
        {SegmentForm.updating || SegmentForm.creating ? <CaravelSpinner /> : 'Save'}
      </Button>
      <Prompt when={SegmentForm.hasChanges} message="Are you sure you want to discard your changes?" />
    </>
  );
});

export default IdealCustomerProfileView;

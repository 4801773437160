import { gql } from '@apollo/client';
import { Activity } from '@caravel/types/src/activities';

export const GET_ACTIVITIES = gql`
  query getPeopleActivity($memberId: ID!, $after: String, $first: Int, $filterBy: ActivityFilter) {
    community {
      id
      people(filterBy: { id__eq: $memberId }) {
        edges {
          node {
            activities(after: $after, first: $first, filterBy: $filterBy) {
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              edges {
                node {
                  id
                  eventId
                  kind
                  title
                  textContent
                  occurredAt
                  fromPersona {
                    name
                  }
                  toPersona {
                    id
                    name
                  }
                  article {
                    __typename
                    createdAt
                    kind
                    classifications
                    sentiment
                  }
                  channel {
                    id
                    kind
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface GqlActivitiesRequestType {
  memberId: string;
  after?: string;
  first?: number;
  filterBy?: { start: string; end: string };
}

export interface GqlActivitiesResponseType {
  community: {
    id: string;
    people: {
      edges: {
        node: {
          activities: {
            edges: {
              node: Activity;
            }[];
            pageInfo: {
              endCursor: string;
              hasNextPage: boolean;
              hasPreviousPage: boolean;
              startCursor: string;
            };
          };
        };
      }[];
    };
  };
}

import { ChannelKind } from '@caravel/types/src';
import React from 'react';

import { CalendarIcon, GoogleIcon, SlackIcon, TwitterIcon } from '../../components/people/people-icons';

export const ChannelIcons: Record<ChannelKind, React.ReactNode> = {
  ':slack': <SlackIcon />,
  ':zendesk': null,
  ':api': null,
  ':activecampaign': null,
  ':circle': null,
  ':clearbit': null,
  ':csv-import': null,
  ':discourse': null,
  ':drip': null,
  ':events': <CalendarIcon />,
  ':github': null,
  ':github-public': null,
  ':hubspot': null,
  ':insided': null,
  ':email': <GoogleIcon />,
  ':mailchimp': null,
  ':manual': null,
  ':override': null,
  ':pipedrive': null,
  ':signup': null,
  ':twitter': <TwitterIcon />,
  ':vanilla': null,
  ':zapier': null,
  ':zoom': null,
};

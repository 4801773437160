import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import React from 'react';

export const TextField = React.forwardRef<
  HTMLDivElement,
  MuiTextFieldProps & {
    endIcon?: React.ReactNode;
  }
>((props, ref) => (
  <MuiTextField
    {...props}
    ref={ref}
    inputProps={{
      ...props.inputProps,
      sx: {
        boxSizing: 'border-box',
        fontSize: 14,
        height: 48,
        ...props.inputProps?.sx,
      },
    }}
  />
));

TextField.displayName = 'TextField';

export const ShortTextField = React.forwardRef<HTMLDivElement, MuiTextFieldProps>((props, ref) => (
  <MuiTextField
    {...props}
    ref={ref}
    inputProps={{
      ...props.inputProps,
      sx: {
        boxSizing: 'border-box',
        fontSize: 14,
        height: 36,
        ...props.inputProps?.sx,
      },
    }}
  />
));

ShortTextField.displayName = 'ShortTextField';

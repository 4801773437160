import request from 'superagent';

import { makeAuthorizationHeader } from './auth';
import { servicesConfig } from './config';

export interface UserIds {
  userId: string;
  token: string;
}

export type Endpoints = keyof typeof servicesConfig;

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE';

/**
 * Creates a service request that can be invoked to retrieve resources
 * @param method HTTP Request method
 * @param endpoint Configured endpoint identifier ('getClassifiers', 'clearSource', etc)
 */
export function makeServiceRequest<Params>(
  method: Methods,
  endpoint: Endpoints,
  mapEndpoint = true,
  query: string | Record<string, unknown> | undefined = undefined,
) {
  /**
   * Creates an authorized superagent request
   */
  return (params: Params, ids: UserIds, args: string[] = []): request.SuperAgentRequest => {
    let serviceEndpoint = mapEndpoint
      ? (servicesConfig[endpoint] as string | ((...args: string[]) => string))
      : endpoint;
    if (typeof serviceEndpoint === 'function') {
      serviceEndpoint = serviceEndpoint(...args);
    }
    const authHeader = makeAuthorizationHeader(ids.token);
    const endpointParams = {
      ...params,
      userId: ids.userId,
    };
    const req = request(method, serviceEndpoint).set(authHeader);
    query && req.query(query);
    if (method === 'GET') {
      return req.query(endpointParams);
    } else {
      return req.send(endpointParams);
    }
  };
}

/**
 * Creates a service helper that returns a typed response body
 * @param method HTTP Request method
 * @param endpoint Configured endpoint identifier ('getClassifiers', 'clearSource', etc)
 */
export function makeServiceHelper<Params, Body>(method: Methods, endpoint: Endpoints, mapEndpoint = true) {
  /**
   * Creates an authorized superagent request
   */
  return async (
    params: Params,
    ids: UserIds,
    args: string[] = [],
    query: string | Record<string, unknown> | undefined = undefined,
  ): Promise<Body> => {
    const req = makeServiceRequest(method, endpoint, mapEndpoint, query);
    const res = await req(params, ids, args);
    return {
      ok: res.ok,
      ...res.body,
    };
  };
}

import { Column, Row } from '@caravel/components/src';
import { ProofSourceType } from '@caravel/types/src';
import { GREY_PALETTE, NEUTRALS } from '@caravel/utils';
import { Avatar, Badge, Link, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';
import { AuthorInfo, Proof } from 'stores/proof-library';

import { truncateText } from '../people/people-list';

const getPersonDescription = (organizationName: string | undefined, employmentTitle: string | undefined) => {
  const limit = 60;

  if (!organizationName && !employmentTitle) {
    return '';
  }
  if (!employmentTitle && organizationName != undefined) {
    return truncateText(organizationName, limit);
  }

  if (employmentTitle != undefined && !organizationName) {
    return truncateText(employmentTitle, limit);
  }
  return truncateText(`${employmentTitle} | ${organizationName}`, limit);
};

const customerOwner = (props: AuthorInfo) => {
  const { customerName, orgIcon, icon, organizationName, employmentTitle } = props;
  return (
    <Row>
      <Column>
        {icon && orgIcon && (
          <Badge
            overlap="circular"
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            badgeContent={
              <Avatar
                sx={{
                  width: 16,
                  height: 16,
                  boxShadow: `0 0 0 2px ${NEUTRALS.CHAMOMILE}`,
                  bottom: '10%',
                }}
                src={orgIcon}
              />
            }
          >
            <Avatar sx={{ width: 30, height: 30 }} src={icon ?? ''} />
          </Badge>
        )}
        {icon && !orgIcon && <Avatar sx={{ width: 30, height: 30 }} src={icon ?? ''} />}
      </Column>
      <Column sx={{ marginLeft: '8px' }}>
        <Typography variant="headline4">{truncateText(customerName ?? 'Anonymous Customer', 50)}</Typography>
        <Typography variant="bodySmallest" sx={{ color: GREY_PALETTE[5] }}>
          {getPersonDescription(organizationName, employmentTitle)}
        </Typography>
      </Column>
    </Row>
  );
};

const organizationOwner = (props: AuthorInfo) => {
  const { orgIcon, icon, organizationName } = props;
  return (
    <Row>
      <Column>{orgIcon && <Avatar sx={{ width: 30, height: 30 }} src={icon ?? ''} />}</Column>
      <Column sx={{ marginLeft: '8px' }}>
        <Typography variant="headline4">{truncateText(organizationName ?? 'Unknown Organization', 50)}</Typography>
      </Column>
    </Row>
  );
};

/**
 * Component which displays the owner of proof, either customer or org
 *
 * Broke this off into independent component due to the complexity of the
 * conditional rendering
 */
export const ProofOwnerCell = observer(
  (props: { authorInfo: AuthorInfo; sourceType: ProofSourceType | undefined }): JSX.Element => {
    return (
      <>{props.sourceType !== 'CASESTUDY' ? customerOwner(props.authorInfo) : organizationOwner(props.authorInfo)}</>
    );
  },
);

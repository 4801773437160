import { Flex, Row } from '@caravel/components/src';
import { Divider, SvgIcon, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';

import { CalendarIcon } from './filter-icons';
import { FilterMenuItem } from './filter-menu-item';
import { FilterProps } from './filter-props';

export const DateFilterMenu = observer(
  (
    props: PropsWithChildren<
      FilterProps & {
        onOpenDaySelect: () => void;
        onClose: () => void;
      }
    >,
  ) => {
    return (
      <>
        <FilterMenuItem
          onClick={() => {
            props.onChangeFilter({
              value: ['1 day ago'],
              condition: props.condition,
            });
            props.onClose();
          }}
        >
          <Typography variant="bodySmaller">1 day ago</Typography>
        </FilterMenuItem>
        <FilterMenuItem
          onClick={() => {
            props.onChangeFilter({
              value: ['1 week ago'],
              condition: props.condition,
            });
            props.onClose();
          }}
        >
          <Typography variant="bodySmaller">1 week ago</Typography>
        </FilterMenuItem>
        <FilterMenuItem
          onClick={() => {
            props.onChangeFilter({
              value: ['1 month ago'],
              condition: props.condition,
            });
            props.onClose();
          }}
        >
          <Typography variant="bodySmaller">1 month ago</Typography>
        </FilterMenuItem>
        <FilterMenuItem
          onClick={() => {
            props.onChangeFilter({
              value: ['3 months ago'],
              condition: props.condition,
            });
            props.onClose();
          }}
        >
          <Typography variant="bodySmaller">3 months ago</Typography>
        </FilterMenuItem>
        <Divider />
        <FilterMenuItem
          onClick={() => {
            props.onOpenDaySelect();
          }}
        >
          <Row sx={{ alignItems: 'center' }}>
            <Flex
              sx={{
                width: '20px',
                height: '20px',
              }}
            >
              <SvgIcon
                component={CalendarIcon}
                sx={{
                  fill: 'none',
                  width: '20px',
                  height: '20px',
                  marginTop: '1px',
                }}
              />
            </Flex>
            <Typography variant="bodySmaller">Select a date</Typography>
          </Row>
        </FilterMenuItem>
      </>
    );
  },
);

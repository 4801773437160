import { isSingleCondition } from '@caravel/utils';
import { Checkbox, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';

import { FilterMenuItem } from './filter-menu-item';
import { FilterProps } from './filter-props';

export const MultiFilterMenu = observer((props: PropsWithChildren<FilterProps>) => {
  return (
    <>
      {props.options?.values.map((option, i) => (
        <FilterMenuItem
          key={`${props.type}-${option}-${i}`}
          sx={{ paddingLeft: 0 }}
          onClick={() => {
            let nextValue: string[];
            if (props.value.includes(option)) {
              nextValue = props.value.filter(v => v !== option);
            } else {
              nextValue = [...props.value, option];
            }
            // We may have to update the condition based on number of selected values
            let nextCondition = props.condition;
            if (isSingleCondition(props.condition) && nextValue.length > 1) {
              // from single to multi
              if (props.condition === 'is') {
                nextCondition = 'any-of';
              } else if (props.condition === 'not') {
                nextCondition = 'not-any-of';
              } else if (props.condition === 'includes') {
                nextCondition = 'any-of';
              } else if (props.condition === 'not-includes') {
                nextCondition = 'not-any-of';
              }
            }
            props.onChangeFilter(
              {
                value: nextValue,
                condition: nextCondition,
              },
              true,
            );
          }}
        >
          <Checkbox
            checked={props.value.includes(option)}
            size="small"
            sx={{
              width: '14px',
              heigth: '14px',
              padding: 0,
              margin: '0 8px',
              '& .MuiSvgIcon-root': {
                width: '14px',
                heigth: '14px',
              },
            }}
          />
          <Typography variant="bodySmaller">{option}</Typography>
        </FilterMenuItem>
      ))}
    </>
  );
});

import { NotificationDocument, NotificationProps } from '@caravel/types';
import { upperFirst } from '@caravel/utils';
import { doc } from 'firebase/firestore';
import { FirestoreTimestamp, getDb } from 'helpers';
import { computed, makeObservable, observable } from 'mobx';

import { BaseModel, makeToJS, makeUpdate, PENDING_ID } from './base';

const skeletonProps: NotificationDocument = {
  createdAt: undefined,
  updatedAt: undefined,
  archivedAt: undefined,
  id: PENDING_ID,
  teamId: PENDING_ID,
  taskId: PENDING_ID,
  taskType: '',
  taskStatus: 'scheduled',
  resourceId: PENDING_ID,
  resourceType: '',
  resourceName: undefined,
  initiatedBy: '',
  progress: undefined,
  templateName: undefined,
  scheduledAt: undefined,
  customMessage: undefined,
  autoDismiss: undefined,
};

export class Notification extends BaseModel<NotificationProps> implements NotificationProps {
  createdAt?: FirestoreTimestamp = undefined;
  updatedAt?: FirestoreTimestamp = undefined;
  archivedAt?: FirestoreTimestamp = undefined;
  scheduledAt?: FirestoreTimestamp = undefined;

  id = skeletonProps.id;
  teamId = skeletonProps.teamId;
  taskId = skeletonProps.taskId;
  taskType = skeletonProps.taskType;
  taskStatus = skeletonProps.taskStatus;
  resourceId = skeletonProps.resourceId;
  resourceType = skeletonProps.resourceType;
  resourceName = skeletonProps.resourceName;
  initiatedBy = skeletonProps.initiatedBy;
  progress = skeletonProps.progress;
  templateName = skeletonProps.templateName;
  customMessage = skeletonProps.customMessage;
  autoDismiss = skeletonProps.autoDismiss;

  dismissing = false;

  get docRef() {
    return doc(getDb(), 'teams', this.teamId, 'notifications', this.id);
  }

  constructor(props: NotificationProps) {
    super();

    makeObservable(this, {
      createdAt: observable,
      updatedAt: observable,
      archivedAt: observable,
      scheduledAt: observable,
      id: observable,
      teamId: observable,
      taskId: observable,
      taskType: observable,
      taskStatus: observable,
      resourceId: observable,
      resourceType: observable,
      resourceName: observable,
      initiatedBy: observable,
      progress: observable,
      templateName: observable,
      customMessage: observable,
      autoDismiss: observable,
      dismissing: observable,
      docRef: computed,
    });

    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

const adjustType = (s: string) => upperFirst(s.replace(/(s)$/, ''));

const NOTIFICATION_TEMPLATES = {
  custom: (n: NotificationProps) => n.customMessage || '',
  'resource-created': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** successfully created.`,
  'resource-updated': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** successfully updated.`,

  'historical-analysis-scheduled': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** is scheduled for analysis.`,
  'historical-analysis-running': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** is analyzing.`,
  'historical-analysis-completed': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** successfully analyzed your sources.`,

  'on-sync-event-scheduled': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** is scheduled for syncing.`,
  'on-sync-event-running': (n: NotificationProps) => `${adjustType(n.resourceType)} **${n.resourceName}** is syncing.`,
  'on-sync-event-completed': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** successfully synced.`,

  'usage-exceeded': (n: NotificationProps) =>
    `**Record limit exceeded** while processing ${adjustType(n.resourceType).toLocaleLowerCase()} **${
      n.resourceName
    }**. Contact sales to increase your limit.`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  'usage-limited': (n: NotificationProps) => `**Record limit reached.** Contact sales to increase your limit.`,

  'test-classifier-scheduled': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** is scheduled for testing.`,
  'test-classifier-running': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** is running tests.`,
  'test-classifier-completed': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** successfully ran tests.`,

  'sync-report-scheduled': () => `Prepping report. This may take a few minutes.`,
  'sync-report-running': () => `Crunching remaining historical data in the background.`,
  'sync-report-completed': (n: NotificationProps) =>
    `${adjustType(n.resourceType)} **${n.resourceName}** successfully synced.`,
};

export type NotificationTemplates = keyof typeof NOTIFICATION_TEMPLATES;

export function renderNotificationTemplate(template: NotificationTemplates | string, n: NotificationProps): string {
  const t = (NOTIFICATION_TEMPLATES as Record<string, (n: NotificationProps) => string>)[template];
  return t ? t(n) : `${adjustType(n.resourceType)} **${n.resourceName}** ${n.taskStatus}`;
}

import { Logger, useAppReadyCheck, useStoreMount } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

import { SegmentDetail, SegmentForm, SegmentsRoute } from '../segments';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('feeds-routes');

export const SegmentsRoutes: FC = observer(() => {
  const store = useStore();
  useStoreMount(store, [store.teams.segments]);

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> rendering segments`);

  return (
    <Switch>
      <Route path="/segments/new" component={SegmentForm} />
      <Route path="/segments/:segmentId/edit" component={SegmentForm} />
      <Route path="/segments/:segmentId" component={SegmentDetail} />
      <Route path="/segments" component={SegmentsRoute} />
    </Switch>
  );
});

import { Column } from '@caravel/components/src';
import { Logger, useAppReadyCheck } from 'helpers';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

const { debug } = new Logger('report-routes');

export const ReportsRoutes = observer(() => {
  const store = useStore();

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> TODO: render reports`);

  return <Column />;
});

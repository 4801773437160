import { MemberDocument, MemberRoles } from '@caravel/types';
import { doc, DocumentReference, setDoc } from 'firebase/firestore';
import { FirestoreTimestamp, getDb, Logger, serverTimestamp } from 'helpers';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { MemberStore } from 'stores/members';

import { BaseModel, makeToJS, makeUpdate, PENDING_ID } from './base';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('member-model');

export type MemberProps = MemberDocument & Record<string, any>;

export const READER_ROLES: MemberRoles[] = ['super', 'analyst', 'admin', 'manager', 'reader'];
export const WRITER_ROLES: MemberRoles[] = ['super', 'analyst', 'admin', 'manager'];

export const MEMBER_ROLES_WEIGHT: Record<MemberRoles, number> = {
  super: 5,
  analyst: 4,
  admin: 3,
  manager: 2,
  reader: 1,
};

const skeletonProps: MemberProps = {
  createdAt: undefined,
  updatedAt: undefined,
  archivedAt: undefined,
  id: PENDING_ID,
  email: '',
  name: '',
  fullName: '',
  username: '',
  lastTeamId: undefined,
  lastTeamProjects: {},
};

export class Member extends BaseModel<MemberProps> implements MemberProps {
  createdAt?: FirestoreTimestamp = undefined;
  updatedAt?: FirestoreTimestamp = undefined;
  archivedAt?: FirestoreTimestamp = undefined;

  id = skeletonProps.id;
  email = skeletonProps.email;
  name = skeletonProps.name;
  fullName = skeletonProps.fullName;
  username = skeletonProps.username;
  lastTeamId?: string = undefined;
  lastTeamProjects = skeletonProps.lastTeamProjects;

  readonly store: MemberStore;

  get docRef(): DocumentReference {
    return doc(getDb(), 'members', this.id);
  }

  get canRead() {
    if (!this.teamMember) {
      return false;
    }
    return READER_ROLES.includes(this.teamMember.role);
  }

  get canWrite() {
    if (!this.teamMember) {
      return false;
    }
    return WRITER_ROLES.includes(this.teamMember.role);
  }

  get isSuper() {
    if (!this.teamMember) {
      return false;
    }
    return (
      this.teamMember.role === 'super' || this.store.supers.includes(this.id) || this.teamMember.role === 'analyst'
    );
  }

  get teamMember() {
    return this.store.rootStore.teams.teamMembers.get(this.id);
  }

  get role() {
    return this.teamMember?.role;
  }

  constructor(props: MemberProps, store: MemberStore) {
    super();

    makeObservable(this, {
      createdAt: observable,
      updatedAt: observable,
      archivedAt: observable,
      id: observable,
      email: observable,
      name: observable,
      fullName: observable,
      username: observable,
      lastTeamId: observable,
      lastTeamProjects: observable,
      docRef: computed,
      canRead: computed,
      canWrite: computed,
      isSuper: computed,
      teamMember: computed,
    });

    this.store = store;
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);

  /**
   * Saves a teamId to the `lastTeamId` field in firestore
   */
  updateLastTeam = async (lastTeamId: string) => {
    await setDoc(this.docRef, { lastTeamId, updatedAt: serverTimestamp() }, { merge: true });
    runInAction(() => (this.lastTeamId = lastTeamId));
  };

  updateLastProject = async (teamId: string, lastProjectId: string | null) => {
    if (lastProjectId === 'autoload') {
      return;
    }
    this.update({
      lastTeamProjects: {
        ...this.lastTeamProjects,
        [teamId]: lastProjectId,
      },
    });
    await setDoc(
      this.docRef,
      { lastTeamProjects: this.toJS().lastTeamProjects, updatedAt: serverTimestamp() },
      { merge: true },
    );
  };
}

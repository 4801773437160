import { AttributeFieldOption } from '@caravel/components/src';
import { Note, PageInfo, PeopleExportTask, Persona, PersonaType } from '@caravel/types/src';
import { Activity } from '@caravel/types/src/activities';
import {
  ASK_INTRODUCTION_TO_CONNECTION,
  createGQLClient,
  GET_COMMUNITY,
  GET_MEMBER,
  GqlAskIntroductionToConnectionRequestType,
  GqlAskIntroductionToConnectionResponseType,
  GqlCommunityRequestType,
  GqlCommunityResponseType,
  GqlMemberRequestType,
  GqlMemberResponseType,
  uuid,
} from '@caravel/utils';
import {
  CREATE_NOTE,
  GqlCreateNoteRequestType,
  GqlCreateNoteResponseType,
} from '@caravel/utils/src/gql/mutations/create-person-note.gql';
import {
  ADD_MANUAL_RECORD,
  GqlCreatePersonaResponsetType,
  GqlManualPersonaRequestType,
} from '@caravel/utils/src/gql/mutations/create-persona.gql';
import { DELETE_NOTE } from '@caravel/utils/src/gql/mutations/delete-person-note.gql';
import {
  DELETE_MANUAL_RECORD,
  GqlDeletePersonaRequestType,
  GqlDeletePersonaResponseType,
} from '@caravel/utils/src/gql/mutations/delete-persona.gql';
import {
  EDIT_NOTE,
  GqlEditNoteRequestType,
  GqlEditNoteResponsetType,
} from '@caravel/utils/src/gql/mutations/edit-person-note.gql';
import {
  GqlMergeMembersRequestType,
  GqlMergeMembersResponseType,
  MERGE_MEMBERS,
} from '@caravel/utils/src/gql/mutations/merge-members.gql';
import {
  GqlSetPrimaryAttributeRequestType,
  GqlSetPrimaryAttributeResponsetType,
  SET_PRIMARY_ATTRIBUTE,
} from '@caravel/utils/src/gql/mutations/set-primary-attribute.gql';
import {
  GqlUpsertCustomAttributeRequestType,
  GqlUpsertCustomAttributeResponseType,
  UPSERT_MANUAL_RECORD,
} from '@caravel/utils/src/gql/mutations/upsert-persona.gql';
import {
  GET_ACTIVITIES,
  GqlActivitiesRequestType,
  GqlActivitiesResponseType,
} from '@caravel/utils/src/gql/queries/get-activities.gql';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Person } from 'models/person';

import { ManualPersonaAttribute } from './../../../types/src/people/people';
import { BaseStore } from './base';
import { RootStore } from './root';

const host = process.env.GRAPHQL_HOST!;

export class PeopleStore extends BaseStore {
  collection = observable.array<Person>([]);
  loading = false;
  pageInfo?: PageInfo = undefined;

  totalHits = 0;
  communitySize = 0;
  numSuperUsers = 0;
  numConnections = 0;
  numTeamConnections = 0;
  numContributors = 0;

  activities = observable.array<Activity>([]);
  activitiesLoading = false;
  activitiesPageInfo?: PageInfo = undefined;

  // Specific for the Activity Graph in person detail drawer
  activityCounts = observable.array<Activity>([]);
  activityCountsLoading = false;
  activityCountsPageInfo?: PageInfo = undefined;

  person?: Person = undefined;
  personLoading = false;

  mergingPeopleModalOpen = false;
  isMerging = false;
  selectedPeople = observable.array<Person>([]);
  lastSelected?: Person = undefined;

  entireSegmentSelected = false;

  askingIntroduction = false;
  // If the length of selected people changes while modal is open, this will close it
  // to prevent issues should length fall below required total
  get canMergePeople() {
    return this.selectedPeople.length >= 2 && this.selectedPeople.length <= 10 && this.mergingPeopleModalOpen;
  }

  get hits() {
    return this.totalHits.toLocaleString();
  }

  get numSelected() {
    return this.collection.filter(person => person.selected).length;
  }

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      communitySize: observable,
      loading: observable,
      pageInfo: observable,
      totalHits: observable,
      numSuperUsers: observable,
      numConnections: observable,
      numTeamConnections: observable,
      numContributors: observable,
      activities: observable,
      activitiesLoading: observable,
      activitiesPageInfo: observable,
      activityCounts: observable,
      activityCountsLoading: observable,
      activityCountsPageInfo: observable,
      person: observable,
      personLoading: observable,
      mergingPeopleModalOpen: observable,
      canMergePeople: computed,
      isMerging: observable,
      hits: computed,
      selectedPeople: observable,
      askingIntroduction: observable,
    });
  }

  teardown() {
    this.collection.clear();
    this.activities.clear();
    this.activityCounts.clear();
    this.selectedPeople.clear();
    this.closeMergingPeopleModal();
    super.teardown();
  }

  get = (personId: string) => this.collection.find(person => person.id === personId);

  toggleSelected = (personId: string) => {
    const person = this.get(personId);
    if (person) {
      runInAction(() => {
        person.selected = !person.selected;
        if (person.selected) {
          this.selectedPeople.push(person);
        } else {
          this.selectedPeople.remove(person);
        }
      });
    }
  };

  toggleAllSelected = () => {
    if (this.numSelected === this.collection.length) {
      runInAction(() => {
        this.entireSegmentSelected = false;
        this.collection.replace(
          this.collection.map(person => {
            person.selected = false;
            this.selectedPeople.remove(person);
            return person;
          }),
        );
      });
    } else {
      runInAction(() => {
        this.collection.replace(
          this.collection.map(person => {
            person.selected = true;
            if (!this.selectedPeople.includes(person)) {
              this.selectedPeople.push(person);
            }
            return person;
          }),
        );
      });
    }
  };

  deselectAll = () => {
    runInAction(() => {
      this.entireSegmentSelected = false;
      this.collection.replace(
        this.collection.map(person => {
          person.selected = false;
          this.selectedPeople.remove(person);
          return person;
        }),
      );
    });
  };

  openMergingPeopleModal = () => {
    if (this.selectedPeople.length < 2 || this.selectedPeople.length > 10) {
      return;
    }

    runInAction(() => {
      this.mergingPeopleModalOpen = true;
    });
  };

  closeMergingPeopleModal = () => {
    runInAction(() => {
      this.mergingPeopleModalOpen = false;
    });
  };

  resetCollection = () => {
    runInAction(() => {
      this.collection.clear();
      this.selectedPeople.clear();
      this.pageInfo = undefined;
      this.entireSegmentSelected = false;
    });
  };

  refreshCommunity = async () => {
    this.resetCollection();
    await this.fetchCommunity();
  };

  resetActivities = () => {
    runInAction(() => {
      this.activities.clear();
      this.activitiesPageInfo = undefined;
    });
  };

  resetActivityCounts = () => {
    runInAction(() => {
      this.activityCounts.clear();
      this.activityCountsPageInfo = undefined;
    });
  };

  resetPerson = () => {
    runInAction(() => {
      this.person = undefined;
    });
  };

  checkNoteOwnership = (noteId: string) => {
    const note = this.person!.notes!.find(v => v.id === noteId);
    if (!note) {
      throw new Error('Note does not exist');
    }

    if (!this.rootStore.auth.user || this.rootStore.auth.user.uid !== note.author.firebaseUserId) {
      throw new Error('User does not have permission to modify this note');
    }
  };

  createPersonNote = async (personId: string, tempId: string, text: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    let note = null; // fix this
    try {
      const response = await graphqlClient.query<GqlCreateNoteRequestType, GqlCreateNoteResponseType>(CREATE_NOTE, {
        personId,
        text,
      });
      runInAction(() => {
        note = response.upsertPersonNote.result;
        const currentNotes = this.person?.notes ?? [];
        const existingIndex = currentNotes.findIndex(n => n.id === tempId);
        currentNotes.splice(existingIndex, 1, note);
        this.person?.update({ notes: currentNotes });
      });
    } catch (e) {
      console.error('create note error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error creating note',
        duration: 5000,
      });
    }
    return note;
  };

  deletePersonNote = async (personId: string, noteId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      this.checkNoteOwnership(noteId);
      await graphqlClient.query(DELETE_NOTE, { personId, id: noteId });
      const note = this.person?.notes?.find(n => n.id === noteId);
      if (note) {
        this.onDeletePersonNote(note);
      }
    } catch (e) {
      console.error('delete note error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error deleting note',
        duration: 5000,
      });
    }
  };

  editPersonNote = async (personId: string, noteId: string, text: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    let note = null;
    if (this.personLoading) {
      return;
    }
    try {
      this.checkNoteOwnership(noteId);
      const response = await graphqlClient.query<GqlEditNoteRequestType, GqlEditNoteResponsetType>(EDIT_NOTE, {
        personId,
        id: noteId,
        text,
      });
      runInAction(() => {
        note = response.upsertPersonNote.result;
        const currentNotes = this.person?.notes ?? [];
        const existingIndex = currentNotes.findIndex(n => n.id === noteId);
        currentNotes.splice(existingIndex, 1, note);
        this.person?.update({ notes: currentNotes });
      });
    } catch (e) {
      console.error('edit note error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error editing note',
        duration: 5000,
      });
    }
    return note;
  };

  fetchActivities = async (personId: string, after?: string, first?: number) => {
    if (this.activitiesLoading) {
      console.debug('Activities already loading');
      return;
    }
    runInAction(() => (this.activitiesLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlActivitiesRequestType, GqlActivitiesResponseType>(GET_ACTIVITIES, {
      memberId: personId,
      after,
      first,
    });
    const pageInfo = response.community.people.edges[0].node.activities.pageInfo;
    const activities: Activity[] = response.community.people.edges[0].node.activities.edges.map(edge => edge.node);
    runInAction(() => {
      this.activitiesPageInfo = pageInfo;
      if (pageInfo.startCursor === '0') {
        this.activities.replace(activities);
      } else {
        this.activities.replace(this.activities.concat(activities));
      }
      this.activitiesLoading = false;
    });
  };

  fetchActivityCounts = async (
    personId: string,
    after?: string,
    first?: number,
    filterBy?: { start: string; end: string },
  ) => {
    if (this.activityCountsLoading) {
      console.debug('Activities already loading');
      return;
    }
    runInAction(() => (this.activityCountsLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlActivitiesRequestType, GqlActivitiesResponseType>(GET_ACTIVITIES, {
      memberId: personId,
      after,
      first,
      filterBy,
    });
    const pageInfo = response.community.people.edges[0].node.activities.pageInfo;
    const activities: Activity[] = response.community.people.edges[0].node.activities.edges.map(edge => edge.node);
    runInAction(() => {
      this.activityCountsPageInfo = pageInfo;
      if (pageInfo.startCursor === '0') {
        this.activityCounts.replace(activities);
      } else {
        this.activityCounts.replace(this.activityCounts.concat(activities));
      }
      this.activityCounts.replace(activities);
      this.activityCountsLoading = false;
    });
  };

  fetchCommunity = async () => {
    if (this.loading) {
      console.debug('Community already loading');
      return;
    }
    runInAction(() => (this.loading = true));
    const after = this.pageInfo?.endCursor;
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const facets = this.rootStore.teams.filters.peopleFacets.slice();
      const response = await graphqlClient.query<GqlCommunityRequestType, GqlCommunityResponseType>(GET_COMMUNITY, {
        after,
        query: this.rootStore.teams.filters.peopleSearchQuery, //this.searchQuery,
        facets,
        orderBy: this.rootStore.teams.filters.peopleOrderByOptions, //this.orderByOptions,
      });
      const pageInfo = response.community.people.pageInfo;
      const people: Person[] = response.community.people.edges
        ?.filter(edge => edge.node)
        .map(edge => new Person(edge.node));
      runInAction(() => {
        this.pageInfo = pageInfo;
        if (pageInfo.startCursor === '0') {
          this.collection.replace(people);
        } else {
          this.collection.replace(this.collection.concat(people));
        }
        this.totalHits = response.community.people.meta.hits ?? 0;
        this.communitySize = response.community.stats.peopleCount ?? 0;

        const facets = response.community.people.meta.facets;
        const gridStatusFacet = facets?.find(f => f.name === 'gridStatus');
        const superuserCt = gridStatusFacet?.counts?.find(c => c.value === 'superuser')?.count ?? 0;
        const superfanCt = gridStatusFacet?.counts?.find(c => c.value === 'superfan')?.count ?? 0;
        this.numSuperUsers = superuserCt + superfanCt;
        this.numContributors = gridStatusFacet?.counts?.find(c => c.value === 'contributor')?.count ?? 0;

        const connectedToFacet = facets?.find(f => f.name === 'connectedTo');
        this.numConnections =
          connectedToFacet?.counts?.find(c => c.value === this.rootStore.auth.user?.uid)?.count ?? 0;
        this.numTeamConnections = connectedToFacet?.counts?.reduce((acc: number, count) => acc + count.count, 0) ?? 0;

        this.loading = false;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to fetch community',
        duration: 5000,
      });
    }
  };

  getPerson = async (personId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlMemberRequestType, GqlMemberResponseType>(GET_MEMBER, {
      memberId: personId,
    });
    return response.community.people?.edges?.at(0)?.node;
  };

  fetchPerson = async (personId: string) => {
    if (this.personLoading) {
      console.debug('Person already loading');
      return;
    }
    runInAction(() => (this.personLoading = true));
    const props = await this.getPerson(personId);
    if (!props) {
      runInAction(() => (this.personLoading = false));
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Person not found',
        duration: 5000,
      });
      return;
    }
    runInAction(() => {
      if (this.person) {
        this.person.update(props);
      } else {
        this.person = new Person(props);
      }
      this.personLoading = false;
      this.collection.replace(this.collection.map(p => (p.id === personId ? this.person! : p)));
    });
  };

  onAddPersonNote = () => {
    const member = this.rootStore.members.current;
    const teamMember = member?.teamMember;
    if (!this.person || !teamMember || !member) {
      return;
    }
    const newNoteId = uuid();
    runInAction(() => {
      const next = this.person?.notes?.slice() ?? [];
      const names = member.name.split(' ');
      const note: Note = {
        text: '',
        pending: true,
        id: newNoteId,
        author: {
          id: member.id,
          firebaseUserId: member.id,
          role: teamMember.role as any, // FIXME
          email: member.email,
          firstName: names[0],
          lastName: names.at(-1) ?? '',
        },
        createdAt: new Date(),
      };
      next.unshift(note);
      this.person!.notes = next;
    });
    return newNoteId;
  };

  onChangePersonNote = (note: Note) => {
    this.checkNoteOwnership(note.id);
    const person = this.person;
    if (!person) {
      return;
    }
    const index = (person.notes ?? []).findIndex(n => n.id === note.id);
    if (index && index > -1) {
      runInAction(() => {
        const next = this.person?.notes?.slice() ?? [];
        next.splice(index, 1, note);
        this.person!.notes = next;
      });
    }
  };

  onDeletePersonNote = (note: Note) => {
    this.checkNoteOwnership(note.id);
    const person = this.person;
    if (!person) {
      return;
    }
    const index = (person.notes ?? []).findIndex(n => n.id === note.id);
    if (index > -1) {
      runInAction(() => {
        const next = this.person?.notes?.slice() ?? [];
        next.splice(index, 1);
        person.notes = next;
        this.person = person;
      });
    }
  };

  createManualPersona = async (personId: string, attributeName: PersonaType, option: AttributeFieldOption) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const attributes: Partial<Persona> = {
      [attributeName]: option.value,
    };
    try {
      const response = await graphqlClient.mutate<GqlManualPersonaRequestType, GqlCreatePersonaResponsetType>(
        ADD_MANUAL_RECORD,
        {
          input: {
            memberId: personId,
            ...attributes,
          },
        },
      );
      runInAction(() => {
        const result = response.addManualPersona.result;
        this.fetchPerson(personId);
        console.log(result);
      });
    } catch (e) {
      console.error('create persona error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error creating persona',
        duration: 5000,
      });
    }
    return {};
  };

  mergeMembers = async (memberIds: string[], winningId: string) => {
    // Prevent fast double clicking
    if (this.isMerging) {
      console.debug('Merge is already underway');
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Merge already in progress',
        duration: 5000,
      });
      return;
    }
    if (!this.canMergePeople) {
      console.debug('Unable to merge selected people');
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Unable to merge selected people',
        duration: 5000,
      });
      return;
    }
    runInAction(() => (this.isMerging = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const response = await graphqlClient.mutate<GqlMergeMembersRequestType, GqlMergeMembersResponseType>(
        MERGE_MEMBERS,
        {
          input: {
            memberIds,
            winningId,
          },
        },
      );
      runInAction(() => {
        const result = response.mergeMembers.result;
        console.log(result);
        this.isMerging = false;
        this.mergingPeopleModalOpen = false;
        this.deselectAll();
        this.refreshCommunity();
        this.rootStore.notifications.display({
          severity: 'success',
          message: 'Personas merged successfully',
          duration: 5000,
        });
      });
    } catch (e) {
      console.error('merge members error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error creating persona',
        duration: 5000,
      });
    }
  };

  setPrimaryAttribute = async (
    personId: string,
    attributeType: PersonaType,
    option: AttributeFieldOption,
    personaId: string,
  ) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const response = await graphqlClient.mutate<
        GqlSetPrimaryAttributeRequestType,
        GqlSetPrimaryAttributeResponsetType
      >(SET_PRIMARY_ATTRIBUTE, {
        input: {
          memberId: personId,
          personaId,
          attribute: attributeType.toUpperCase() as ManualPersonaAttribute,
          value: option.value,
        },
      });
      runInAction(() => {
        const result = response.setPrimaryAttribute.result;
        this.fetchPerson(personId);
        console.log(result);
      });
    } catch (e) {
      console.error('set primary attribute error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error setting primary attribute',
        duration: 5000,
      });
    }
    return {};
  };

  deleteManualPersona = async (personId: string, attributeName: PersonaType) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const deleteResponse = await graphqlClient.mutate<GqlDeletePersonaRequestType, GqlDeletePersonaResponseType>(
        DELETE_MANUAL_RECORD,
        {
          input: {
            memberId: personId,
            attribute: attributeName.toUpperCase() as ManualPersonaAttribute,
          },
        },
      );
      runInAction(() => {
        const result = deleteResponse.deleteManualAttribute.result;
        this.fetchPerson(personId);
        console.log(result);
      });
    } catch (e) {
      console.error('delete attribute error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error deleting attribute',
        duration: 5000,
      });
    }
  };

  upsertManualPersona = async (personIds: string[], attributeName: PersonaType, option: AttributeFieldOption) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const upsertResponse = await graphqlClient.mutate<
        GqlUpsertCustomAttributeRequestType,
        GqlUpsertCustomAttributeResponseType
      >(UPSERT_MANUAL_RECORD, {
        input: {
          memberIds: personIds,
          name: attributeName as PersonaType,
          value: option.value,
        },
      });
      runInAction(() => {
        const result = upsertResponse.upsertCustomAttribute.result;
        this.fetchPerson(personIds[0]);
        console.log(result);
      });
    } catch (e) {
      console.error('upsert attribute error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error deleting attribute',
        duration: 5000,
      });
    }
  };

  exportPeople = async () => {
    try {
      const enqueuePeopleExport = httpsCallable(getFunctions(), 'enqueuePeopleExport');
      const { teamId, userId } = await this.getTeamAndToken();
      const options: PeopleExportTask = {
        communityId: teamId,
        userId,
        facets: this.rootStore.teams.filters.peopleFacets.slice(),
        query: this.rootStore.teams.filters.peopleSearchQuery,
      };
      await enqueuePeopleExport(options);
      await this.rootStore.notifications.displaySuccess('People export started');
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to export people');
    }
  };

  askForIntroduction = async (teamMemberId: string, personId: string, message: string) => {
    if (this.askingIntroduction) {
      console.debug('Already asking for introduction');
      return;
    }
    runInAction(() => (this.askingIntroduction = true));

    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const response = await graphqlClient.mutate<
        GqlAskIntroductionToConnectionRequestType,
        GqlAskIntroductionToConnectionResponseType
      >(ASK_INTRODUCTION_TO_CONNECTION, {
        input: {
          teamMemberId,
          connectionId: personId,
          message,
        },
      });
      if (response) {
        runInAction(() => {
          this.askingIntroduction = false;
          this.rootStore.notifications.displaySuccess('Successfully asked for introduction');
        });
        return response.askIntroductionToConnection.result;
      }
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to ask for introduction');
      runInAction(() => {
        this.askingIntroduction = false;
      });
    }
  };
}

import { TroveDesign } from '@caravel/types/src';
import { ACTION_BLUE, GREY_PALETTE, LIGHT_GREY, NEUTRALS, WHITE } from '@caravel/utils/src';
import chroma from 'chroma-js';
import { useEffect, useState } from 'react';

export interface ExtendedTroveDesign extends TroveDesign {
  borderColor: string;
  linkColor: string;
  buttonHover: string;
}

const defaultDesign: TroveDesign = {
  background: LIGHT_GREY,
  inputBackground: WHITE,
  questions: GREY_PALETTE[7],
  answers: GREY_PALETTE[7],
  buttons: ACTION_BLUE,
  buttonText: WHITE,
  secondaryButtons: WHITE,
  secondaryText: GREY_PALETTE[7],
  ratingButtons: NEUTRALS.JASMINE,
  ratingText: GREY_PALETTE[7],
  location: 'center',
  paddingHorizontal: 20,
  paddingVertical: 20,
  overlay: true,
  overlayColor: GREY_PALETTE[7],
  overlayOpacity: 80,
};

export const useExtendedTroveDesign = (design: TroveDesign = defaultDesign): ExtendedTroveDesign => {
  const [borderColor, setBorderColor] = useState(design.buttons);
  const [linkColor, setLinkColor] = useState(design.questions);
  const [buttonHover, setButtonHover] = useState(design.buttons);

  useEffect(() => {
    let bc: string;
    try {
      bc = chroma.hex(design.buttons).alpha(0.5).css();
    } catch {
      bc = design.buttons;
    }
    setBorderColor(bc);
    let lc: string;
    try {
      lc = chroma.hex(design.questions).alpha(0.5).css();
    } catch {
      lc = design.questions;
    }
    setLinkColor(lc);
    let bh: string;
    try {
      bh = chroma.hex(design.buttons).darken(0.25).css();
    } catch {
      bh = design.buttons;
    }
    setButtonHover(bh);
  }, [design]);

  return {
    ...defaultDesign,
    ...design,
    borderColor,
    linkColor,
    buttonHover,
  };
};

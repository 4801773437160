import { PeopleList } from 'components/people/people-list';
import { Logger, useAppReadyCheck, useStoreMount } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

import { PersonDetail } from '../people/person-detail';

const { debug } = new Logger('feeds-routes');

export const PeopleRoutes: FC = observer(() => {
  const store = useStore();
  useStoreMount(store, [store.teams.people]);

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> rendering crm`);

  // TODO: hash filter object and use as param of /people/f/ route to preserve views?
  return (
    <Switch>
      <Route path="/people/f/:filter" component={PeopleList} />
      <Route path="/people/:personId" component={PersonDetail} />
      <Route path="/people" component={PeopleList} />
    </Switch>
  );
});

import { gql } from '@apollo/client';

export const GET_PEOPLE_CITIES = gql`
  query getPeopleCities($query: String, $first: Int, $after: String) {
    community {
      id
      peopleFacets(facet: "city", query: $query, first: $first, after: $after) {
        facet
        query
        totalHits
        hits {
          highlighted
          value
        }
      }
    }
  }
`;

export interface GqlPeopleCitiesRequestType {
  query?: string;
  first?: number;
  after?: string;
}

export interface GqlPeopleCitiesResponseType {
  community: {
    id: string;
    peopleFacets: {
      facet: string;
      query: string;
      totalHits: number;
      hits: {
        highlighted: string;
        value: string;
      }[];
    };
  };
}

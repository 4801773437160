import { NEUTRALS, WHITE } from '@caravel/utils/src';
import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

export const Select = React.forwardRef<HTMLDivElement, MuiSelectProps>((props, ref) => (
  <MuiSelect
    ref={ref}
    {...props}
    displayEmpty
    sx={{
      ...props.sx,
      backgroundColor: WHITE,
      margin: '5px 0',
      height: '36px',
      fontSize: '14px',
      '&:focus': {
        backgroundColor: WHITE,
      },
      '&.placeholder': {
        color: NEUTRALS.JASMINE,
        fontStyle: 'italic',
      },
    }}
    // FIXME
    classes={{ root: clsx(props.value === '' && 'placeholder') } as any}
  />
));

Select.displayName = 'Select';

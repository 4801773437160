import { Column } from '@caravel/components/src';
import { Button, Link, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as EmailOpenIcon } from '../../../assets/svgs/email-open.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/svgs/google.svg';
import { RouterLink } from '../../router-link';

export interface SignInViewProps {
  onSignInWithGoogle: () => void;
  onSwitchToEmail: () => void;
}

export function SignInView(props: SignInViewProps) {
  const { onSignInWithGoogle, onSwitchToEmail } = props;

  const handleSignInWithGoogle = async () => {
    await onSignInWithGoogle();
  };

  return (
    <Column alignItems="stretch" width="100%" maxWidth="260px">
      <Typography variant="headline1" textAlign="center" component="h1" marginBottom="15px">
        👋
      </Typography>
      <Typography variant="headline1" textAlign="center" component="h1" marginBottom="15px">
        Glad to have you back
      </Typography>
      <Typography variant="bodySmall" textAlign="center" marginBottom="30px">
        By continuing you agree to the
        <br />
        <Link href="https://www.commsor.com/terms" rel="noopener noreferrer" target="_blank">
          terms of service
        </Link>{' '}
        and{' '}
        <Link href="https://www.commsor.com/terms?page=privacy" rel="noopener noreferrer" target="_blank">
          privacy policy
        </Link>
        .
      </Typography>
      <Button
        variant="outlined"
        size="large"
        startIcon={<GoogleIcon width="20px" height="20px" />}
        onClick={handleSignInWithGoogle}
        fullWidth
        sx={{ marginBottom: '8px' }}
      >
        Sign in with Google
      </Button>
      <Button
        variant="outlined"
        size="large"
        startIcon={<EmailOpenIcon width="20px" height="20px" />}
        fullWidth
        sx={{ marginBottom: '18px' }}
        onClick={onSwitchToEmail}
      >
        Sign in with Work Email
      </Button>
      <Typography variant="bodySmallest" textAlign="center">
        Don&rsquo;t have an account? <RouterLink to={`/sign-up?${location.search}`}>Sign up</RouterLink>.
      </Typography>
    </Column>
  );
}

import AssessmentIcon from '@mui/icons-material/Assessment';
import { Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { BaseActionModal } from './base-action-modal';

export function UsageGatedModal({
  open = false,
  data = {},
  onClose,
}: {
  open: boolean;
  data?: any;
  onClose: () => void;
}) {
  const history = useHistory();
  const { message } = data;

  const handleUpgrade = () => {
    history.push('/workspace/plans');
    onClose();
  };

  return (
    <BaseActionModal
      open={open}
      icon={<AssessmentIcon />}
      title="Analyze more records"
      subtitle={message ? message : 'Have more records to analyze?'}
      onClose={onClose}
      onAction={handleUpgrade}
    >
      <Typography>We got you. Increase your limits with an upgrade.</Typography>
    </BaseActionModal>
  );
}

import { BackgroundLoader, Column } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { isEmptyString } from '@caravel/utils';
import { Button, Typography } from '@mui/material';
import { FlexBox } from 'components/flex-box';
import { Logger, useQuery } from 'helpers';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { OnboardingWrapper } from '../onboarding-wrapper';

const { debug } = new Logger('finish-sign-up');

export const FinishSignUpContainer = observer(() => {
  const store = useStore();
  const history = useHistory();
  const query = useQuery();
  const [name, setName] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isEmptyString(name)) {
      return;
    }
    store.ui.workOn(async () => {
      setSubmitting(true);
      try {
        await store.auth.finishSignUp(name!);
        const teamId = query.get('teamId') || undefined;
        const inviteId = query.get('inviteId') || undefined;
        if (teamId && inviteId) {
          debug('Accepting team invitation', { teamId, inviteId });
          await store.teams.invites.acceptInvite({ teamId, inviteId });
        }
        history.push('/teams/new');
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  };

  if (submitting) {
    return (
      <FlexBox height="100vh" width="100vw">
        <BackgroundLoader title="Signing you up..." />
      </FlexBox>
    );
  }

  return (
    <OnboardingWrapper>
      <Column alignItems="stretch" width="100%" maxWidth="360px">
        <Typography variant="headline1" component="h1" marginBottom="50px" textAlign="center">
          Set up your account
        </Typography>
        <form onSubmit={handleSubmit}>
          <Column gap="20px">
            <FormInput label="Full Name" placeholder="Enter Full Name" required fullWidth onChange={onChangeName} />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={isEmptyString(name) || submitting}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Column>
        </form>
      </Column>
    </OnboardingWrapper>
  );
});

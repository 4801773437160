import { Champion, ChampionReference, ChampionsSettingsConfig, Note, PageInfo, Prospect } from '@caravel/types/src';
import {
  CREATE_NOTE,
  createGQLClient,
  DELETE_NOTE,
  EDIT_NOTE,
  GET_COMMUNITY,
  GET_MEMBER,
  GqlCommunityRequestType,
  GqlCommunityResponseType,
  GqlCreateNoteRequestType,
  GqlCreateNoteResponseType,
  GqlEditNoteRequestType,
  GqlEditNoteResponsetType,
  GqlMemberRequestType,
  GqlMemberResponseType,
  GqlSetCanReceiveReferencesRequestType,
  GqlSetCanReceiveReferencesResponseType,
  SET_CAN_RECEIVE_REFERENCES,
  uuid,
} from '@caravel/utils';
import {
  ADD_CHAMPIONS,
  GqlAddChampionsRequestType,
  GqlAddChampionsResponseType,
} from '@caravel/utils/src/gql/mutations/add-champions.gql';
import {
  DELETE_CHAMPION,
  GqlDeleteChampionRequestType,
  GqlDeleteChampionResponseType,
} from '@caravel/utils/src/gql/mutations/delete-champion.gql';
import {
  GET_CHAMPIONS_REFERENCES,
  GqlChampionsReferencesResponseType,
} from '@caravel/utils/src/gql/queries/get-champions-references.gql';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getDb } from 'helpers/firebase';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Person } from 'models/person';

import { BaseStore } from './base';
import { RootStore } from './root';

const host = process.env.GRAPHQL_HOST!;

export class ChampionStore extends BaseStore {
  champions = observable.array<Person>([]);
  championsLoading = false;
  totalHits = 0;
  championsSize = 0;
  pageInfo?: PageInfo = undefined;
  currentChampion: Person | undefined = undefined;
  currentChampionLoading = false;

  prospects = observable.array<Prospect>([]);
  prospectsLoading = false;

  references = observable.array<ChampionReference>([]);
  referencesLoading = false;

  championsSettings: ChampionsSettingsConfig | undefined = undefined;
  championsSettingsLoading = false;

  championsURL = process.env['CHAMPIONS_URL'];
  filters: any;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      champions: observable,
      championsLoading: observable,
      championsSettings: observable,
      championsSettingsLoading: observable,
      prospects: observable,
      prospectsLoading: observable,
      references: observable,
      referencesLoading: observable,
      requestedReferencesCount: computed,
      acceptedReferencesCount: computed,
      rejectedReferencesCount: computed,
      defaultBackupInfoEmail: computed,
      currentChampion: observable,
      currentChampionLoading: observable,
      pageInfo: observable,
      totalHits: observable,
      championsSize: observable,
    });
  }

  async setup() {
    await super.setup();
  }

  teardown(): void {
    this.champions.clear();
    this.prospects.clear();
    this.references.clear();
    super.teardown();
  }

  getURL = async () => {
    const slug = this.rootStore.teams.currentTeam!.slug;
    return `${this.championsURL}/${slug}`;
  };

  getChampion = (id: string) => {
    const champion = this.champions.find(c => c.id === id);
    return champion as Champion;
  };

  getInMemoryChampion = (championId: string) => this.champions.find(champion => champion.id === championId);

  /**
   * Get a person (Champion or Prospect) from the community
   * @param personId
   * @returns person
   */
  getPerson = async (personId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlMemberRequestType, GqlMemberResponseType>(GET_MEMBER, {
      memberId: personId,
    });
    return response.community.people?.edges?.at(0)?.node;
  };

  get requestedReferencesCount(): number {
    return this.references.filter(c => c.status === 'INVITED').length;
  }

  get acceptedReferencesCount(): number {
    return this.references.filter(c => c.status === 'ACCEPTED').length;
  }

  get rejectedReferencesCount(): number {
    return this.references.filter(c => c.status === 'REJECTED').length;
  }

  // Get the email of the team member who is the oldest who will
  // recieve prospect requests if no champion is specified
  get defaultBackupInfoEmail(): string {
    const members = this.rootStore.teams.teamMembers.collection;
    const oldestMember = members
      .filter(m => m.createdAt)
      .sort((a, b) => {
        if (!a.createdAt || !b.createdAt) {
          return -1;
        }
        return a.createdAt!.seconds - b.createdAt!.seconds;
      })[0];
    return oldestMember?.email ?? '';
  }

  resetChampions = () => {
    runInAction(() => {
      this.champions.clear();
      this.championsSettings = undefined;
      this.pageInfo = undefined;
      this.championsLoading = false;
    });
  };

  refreshChampions = async () => {
    this.pageInfo = undefined;
    await this.fetchChampions();
  };

  getProspect = (id: string) => {
    const prospect = this.prospects.find(p => p.id === id);
    return prospect as Prospect;
  };

  getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0).toLocaleUpperCase()}${lastName.charAt(0).toLocaleUpperCase()}`;
  };

  resetCurrentChampion = () => {
    runInAction(() => {
      this.currentChampion = undefined;
    });
  };

  fetchChampion = async (championId: string) => {
    if (this.currentChampionLoading) {
      console.debug('Champion already loading');
      return;
    }
    runInAction(() => (this.championsLoading = true));
    const props = await this.getPerson(championId);
    if (!props) {
      runInAction(() => (this.currentChampionLoading = false));
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Champion not found',
        duration: 5000,
      });
      return;
    }
    runInAction(() => {
      if (this.currentChampion) {
        this.currentChampion.update(props);
      } else {
        this.currentChampion = new Person(props);
      }
      this.currentChampionLoading = false;
      this.champions.replace(this.champions.map(p => (p.id === championId ? this.currentChampion! : p)));
    });
  };

  fetchChampions = async () => {
    if (this.championsLoading) {
      console.debug('Champions already loading');
      return;
    }
    runInAction(() => (this.championsLoading = true));
    const after = this.pageInfo?.endCursor;
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const facets = this.rootStore.teams.filters.getFacets('champions').slice();
      const response = await graphqlClient.query<GqlCommunityRequestType, GqlCommunityResponseType>(GET_COMMUNITY, {
        after,
        query: this.rootStore.teams.filters.searchQuery('champions'),
        facets,
        orderBy: this.rootStore.teams.filters.orderByOptions('champions'),
      });
      const pageInfo = response.community.people.pageInfo;
      const champions: Person[] = response.community.people.edges
        ?.filter(edge => edge.node)
        .map(edge => new Person(edge.node));
      runInAction(() => {
        this.pageInfo = pageInfo;
        if (pageInfo.startCursor === '0') {
          this.champions.replace(champions);
        } else {
          this.champions.replace(this.champions.concat(champions));
        }
        this.totalHits = response.community.people.meta.hits ?? 0;
        this.championsSize = response.community.stats.peopleCount ?? 0;
        this.championsLoading = false;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to fetch champions');
    } finally {
      runInAction(() => {
        this.championsLoading = false;
      });
    }
  };

  fetchReferences = async () => {
    if (this.referencesLoading) {
      console.debug('References already loading');
      return;
    }
    runInAction(() => (this.referencesLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const response = await graphqlClient.query<Record<string, string>, GqlChampionsReferencesResponseType>(
        GET_CHAMPIONS_REFERENCES,
        {},
      );
      const references: ChampionReference[] = response.community.references.map(c => ({
        id: c.id,
        createdAt: c.createdAt,
        prospect: c.prospect,
        champion: c.champion,
        message: c.message,
        status: c.status,
        updatedAt: c.updatedAt,
      }));
      runInAction(() => {
        this.references.replace(references);
        this.referencesLoading = false;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to fetch references');
    } finally {
      runInAction(() => {
        this.referencesLoading = false;
      });
    }
  };

  // This code below is copied from people for now. We are due for a refactor soon with the pivot away
  // from NRM, as this store will replace people.
  checkNoteOwnership = (noteId: string) => {
    const note = this.currentChampion!.notes!.find(v => v.id === noteId);
    if (!note) {
      throw new Error('Note does not exist');
    }

    if (!this.rootStore.auth.user || this.rootStore.auth.user.uid !== note.author.firebaseUserId) {
      throw new Error('User does not have permission to modify this note');
    }
  };

  createPersonNote = async (championId: string, tempId: string, text: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    let note = null; // fix this
    try {
      const response = await graphqlClient.query<GqlCreateNoteRequestType, GqlCreateNoteResponseType>(CREATE_NOTE, {
        personId: championId,
        text,
      });
      runInAction(() => {
        note = response.upsertPersonNote.result;
        const currentNotes = this.currentChampion?.notes ?? [];
        const existingIndex = currentNotes.findIndex(n => n.id === tempId);
        currentNotes.splice(existingIndex, 1, note);
        this.currentChampion?.update({ notes: currentNotes });
      });
    } catch (e) {
      console.error('create note error', e);
      this.rootStore.notifications.displayError('Error creating note');
    }
    return note;
  };

  deletePersonNote = async (championId: string, noteId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      this.checkNoteOwnership(noteId);
      await graphqlClient.query(DELETE_NOTE, { championId, id: noteId });
      const note = this.currentChampion?.notes?.find(n => n.id === noteId);
      if (note) {
        this.onDeletePersonNote(note);
      }
    } catch (e) {
      console.error('delete note error', e);
      this.rootStore.notifications.displayError('Error deleting note');
    }
  };

  editPersonNote = async (championId: string, noteId: string, text: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    let note = null;
    if (this.currentChampionLoading) {
      return;
    }
    try {
      this.checkNoteOwnership(noteId);
      const response = await graphqlClient.query<GqlEditNoteRequestType, GqlEditNoteResponsetType>(EDIT_NOTE, {
        personId: championId,
        id: noteId,
        text,
      });
      runInAction(() => {
        note = response.upsertPersonNote.result;
        const currentNotes = this.currentChampion?.notes ?? [];
        const existingIndex = currentNotes.findIndex(n => n.id === noteId);
        currentNotes.splice(existingIndex, 1, note);
        this.currentChampion?.update({ notes: currentNotes });
      });
    } catch (e) {
      console.error('edit note error', e);
      this.rootStore.notifications.displayError('Error editing note');
    }
    return note;
  };

  onAddPersonNote = () => {
    const member = this.rootStore.members.current;
    const teamMember = member?.teamMember;
    if (!this.currentChampion || !teamMember || !member) {
      return;
    }
    const newNoteId = uuid();
    runInAction(() => {
      const next = this.currentChampion?.notes?.slice() ?? [];
      const names = member.name.split(' ');
      const note: Note = {
        text: '',
        pending: true,
        id: newNoteId,
        author: {
          id: member.id,
          firebaseUserId: member.id,
          role: teamMember.role as any, // FIXME
          email: member.email,
          firstName: names[0],
          lastName: names.at(-1) ?? '',
        },
        createdAt: new Date(),
      };
      next.unshift(note);
      this.currentChampion!.notes = next;
    });
    return newNoteId;
  };

  onChangePersonNote = (note: Note) => {
    this.checkNoteOwnership(note.id);
    const champion = this.currentChampion;
    if (!champion) {
      return;
    }
    const index = (champion.notes ?? []).findIndex(n => n.id === note.id);
    if (index && index > -1) {
      runInAction(() => {
        const next = this.currentChampion?.notes?.slice() ?? [];
        next.splice(index, 1, note);
        this.currentChampion!.notes = next;
      });
    }
  };

  onDeletePersonNote = (note: Note) => {
    this.checkNoteOwnership(note.id);
    const champion = this.currentChampion;
    if (!champion) {
      return;
    }
    const index = (champion.notes ?? []).findIndex(n => n.id === note.id);
    if (index > -1) {
      runInAction(() => {
        const next = this.currentChampion?.notes?.slice() ?? [];
        next.splice(index, 1);
        champion.notes = next;
        this.currentChampion = champion;
      });
    }
  };

  /**
   * Update the canReceiveReference flag on a person
   *
   * @param championId the champion to update
   * @param canReceiveReferences can they recieve references
   */
  updateCanReceiveReferences = async (championId: string, canReceiveReferences: boolean) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      await graphqlClient.mutate<GqlSetCanReceiveReferencesRequestType, GqlSetCanReceiveReferencesResponseType>(
        SET_CAN_RECEIVE_REFERENCES,
        {
          championId: championId,
          canReceiveReferences: canReceiveReferences,
        },
      );
      runInAction(() => {
        this.championsLoading = true;
      });
      //Update our champion in memory, in case other changes have been made
      await this.fetchChampion(championId);
      const current = this.currentChampion;
      runInAction(() => {
        if (current && current.id === championId) {
          const currentLocation = this.champions.findIndex(c => c.id === current.id);
          this.champions[currentLocation] = current;
        }
        this.championsLoading = false;
      });
      this.rootStore.notifications.displaySuccess(
        canReceiveReferences
          ? 'Champion may now receive reference requests'
          : 'Champion will no longer receive reference requests',
      );
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to opt in champion');
    }
  };

  addChampion = async (championEmails: string, canReceiveReferences: boolean) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const response = await graphqlClient.mutate<GqlAddChampionsRequestType, GqlAddChampionsResponseType>(
        ADD_CHAMPIONS,
        {
          championEmails: championEmails,
          canReceiveReferences: canReceiveReferences,
        },
      );
      this.rootStore.notifications.displaySuccess('Champions added successfully');
      return response.addChampions.result;
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to add champions',
        duration: 5000,
      });
    }
  };

  /**
   * Delete Champion from core by ID
   */

  deleteChampion = async (championId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const response = await graphqlClient.mutate<GqlDeleteChampionRequestType, GqlDeleteChampionResponseType>(
        DELETE_CHAMPION,
        { championId: championId },
      );
      this.rootStore.notifications.displaySuccess('Champion deleted successfully');
      return response.deleteChampion.result;
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to delete champion');
    }
  };

  fetchSettings = async () => {
    try {
      runInAction(() => {
        this.championsSettingsLoading = true;
      });
      const championsSettingsRef = doc(getDb(), this.rootStore.teams.currentTeam!.docRef.path, 'settings/references');
      const settings = await getDoc(championsSettingsRef);
      runInAction(() => {
        const currentSettings = settings.data() as ChampionsSettingsConfig;
        this.championsSettings = currentSettings;
        this.championsSettingsLoading = false;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to Fetch Current Settings');
    }
  };

  resetSettings = async () => {
    runInAction(() => {
      this.championsSettingsLoading = false;
      this.championsSettings = undefined;
    });
  };

  updateSettings = async (settings: ChampionsSettingsConfig) => {
    try {
      const championsSettingsRef = doc(getDb(), this.rootStore.teams.currentTeam!.docRef.path, 'settings/references');
      await setDoc(championsSettingsRef, settings, { merge: true });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to Save Settings');
    }
  };
}

import { Divider, Skeleton } from '@mui/material';
import React from 'react';

import { Column, Row } from '../flex';

export function PersonAttributeSkeleton() {
  return (
    <Row gap="20px">
      <Skeleton sx={{ flex: 0.4 }} />
      <Skeleton sx={{ flex: 1 }} />
    </Row>
  );
}

export function PersonNoteSkeleton() {
  return (
    <Skeleton
      sx={{
        width: '100%',
        minHeight: '100px',
        height: '100%',
        margin: 0,
      }}
    />
  );
}

export function PersonSkeleton() {
  return (
    <Column>
      <Row alignItems="center">
        <Skeleton variant="circular" sx={{ width: 85, height: 85, marginRight: '25px' }} />
        <Column flex={1}>
          <Skeleton />
          <Skeleton />
        </Column>
      </Row>
      <Divider sx={{ margin: `20px 0` }} />
      <Column gap="10px">
        <PersonAttributeSkeleton />
        <PersonAttributeSkeleton />
        <PersonAttributeSkeleton />
        <PersonAttributeSkeleton />
        <PersonAttributeSkeleton />
        <PersonAttributeSkeleton />
        <PersonAttributeSkeleton />
        <PersonAttributeSkeleton />
        <PersonAttributeSkeleton />
      </Column>
      <Divider sx={{ margin: `20px 0` }} />
      <Column>
        <PersonNoteSkeleton />
        <PersonNoteSkeleton />
        <PersonNoteSkeleton />
      </Column>
    </Column>
  );
}

import { CaravelSpinner, Column, FormInputContainer, ImageUploader, Row } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { FormSectionHeader } from '@caravel/components/src/forms/form-section-header';
import { ChampionsSettingsConfig } from '@caravel/types/src';
import { GREY_PALETTE, slugify, uuid } from '@caravel/utils';
import { Box, Button, Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { toggleFirebaseObjectPublicStatus } from 'helpers';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useStore } from 'stores';

import { ColorField } from '../../sources/trove/color-field';
import { ChampionsPreview } from './customize-champions-preview';

export const CustomizeChampionsForm = observer(
  (props: { settings: ChampionsSettingsConfig | undefined; url: string }) => {
    const { settings, url } = props;
    const store = useStore();
    const loading = Boolean(!settings);
    const teamName = store.teams.currentTeam?.displayName;
    const defaultEmail = store.teams.champions.defaultBackupInfoEmail;

    const [logo, setLogo] = React.useState<any[]>([]);
    const [uploadLoading, setUploadLoading] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [maxReferencesPerWeek, setMaxReferencesPerWeek] = React.useState('');
    const [connectionPeriod, setConnectionPeriod] = React.useState('');

    const initialSettings: ChampionsSettingsConfig = {
      logo: '',
      enableFollowOnAction: false,
      maxReferencesPerWeek: undefined,
      connectionPeriod: undefined,
      backupInfo: {
        buttonLabel: 'Contact us',
        link: `mailto:${defaultEmail}`,
      },
      followOnAction: {
        link: '',
        message: '',
        button: '',
      },
      productName: '',
      styles: {
        sidebar: {
          background: '#1D0254',
          color: '#FFFFFF',
        },
        buttons: {
          primary: {
            background: '#1D0254',
            color: '#FFFFFF',
          },
        },
      },
    };
    const [currentSettings, setCurrentSettings] = React.useState(initialSettings);

    useMemo(() => {
      if (settings) {
        setCurrentSettings({ ...initialSettings, ...settings });
        setMaxReferencesPerWeek(settings.maxReferencesPerWeek?.toString() ?? '');
        setConnectionPeriod(settings.connectionPeriod?.toString() ?? '');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    const saveSettings = async () => {
      if (isSaving) {
        return;
      }
      if (currentSettings.enableFollowOnAction && currentSettings.followOnAction?.link === '') {
        store.teams.champions.rootStore.notifications.display({
          severity: 'error',
          message: 'Please provide a link for the follow on action',
          duration: 5000,
        });
        return;
      }
      if (!validateMaxReferencesPerWeek()) {
        store.teams.champions.rootStore.notifications.display({
          severity: 'error',
          message: 'Max references per week must be a positive number',
          duration: 5000,
        });
        return;
      }
      setIsSaving(true);
      let newLogo = currentSettings.logo ?? '';
      if (logo[0]) {
        const uploadedLogo = await uploadLogo();
        newLogo = uploadedLogo ?? '';
      }
      await store.teams.champions.updateSettings(currentSettings);
      setIsSaving(false);
      store.teams.champions.rootStore.notifications.display({
        severity: 'success',
        message: 'References Settings Saved!',
        duration: 5000,
      });
    };

    const uploadLogo = async (): Promise<string | undefined> => {
      try {
        setUploadLoading(true);
        const { teamId, ...ids } = await store.getTeamAndToken();
        const fileName = `${uuid()}-${slugify(logo[0].file.name)}`;
        const destinationRef = ref(getStorage(), `/champions/logos/${teamId}/${fileName}`);
        await uploadBytes(destinationRef, logo[0].file);
        await toggleFirebaseObjectPublicStatus(
          {
            teamId,
            status: true,
            bucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
            filePath: `champions/logos/${teamId}/${fileName}`,
          },
          ids,
        );
        const publicURL = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/champions/logos/${teamId}/${fileName}`;
        setUploadLoading(false);
        setCurrentSettings({ ...currentSettings, logo: publicURL });
        return publicURL;
      } catch (e) {
        console.warn(e);
        store.teams.champions.rootStore.notifications.display({
          severity: 'error',
          message: 'Failed to Upload Image',
          duration: 5000,
        });
        setUploadLoading(false);
      }
    };

    const enableFollowOnAction = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentSettings({
        ...currentSettings,
        enableFollowOnAction: event.currentTarget.checked,
      });
    };

    //maxReferencesPerWeek should allow for null, empty, or positive numbers
    const validateMaxReferencesPerWeek = () => {
      if (!maxReferencesPerWeek) {
        return true;
      }

      if (isNaN(parseInt(maxReferencesPerWeek))) {
        return false;
      }

      if (parseInt(maxReferencesPerWeek) < 0) {
        return false;
      }
      return true;
    };

    //Since firestore is a number type, we want to default null, empty, undefined to 0
    const saveMaxReferencesPerWeek = (max: string | undefined) => {
      if (!max) {
        setCurrentSettings({
          ...currentSettings,
          maxReferencesPerWeek: 0,
        });
      } else {
        setCurrentSettings({
          ...currentSettings,
          maxReferencesPerWeek: parseInt(max),
        });
      }
    };

    //maxReferencesPerWeek should allow for null, empty, or positive numbers
    const validateConnectionPeriod = () => {
      if (!connectionPeriod) {
        return true;
      }

      if (isNaN(parseInt(connectionPeriod))) {
        return false;
      }

      if (parseInt(connectionPeriod) < 0) {
        return false;
      }
      return true;
    };

    //Since firestore is a number type, we want to default null, empty, undefined to 0
    const saveConnectionPeriod = (max: string | undefined) => {
      if (!max) {
        setCurrentSettings({
          ...currentSettings,
          connectionPeriod: 0,
        });
      } else {
        setCurrentSettings({
          ...currentSettings,
          connectionPeriod: parseInt(max),
        });
      }
    };

    return (
      <Column sx={{ alignItems: 'center' }} paddingTop="32px">
        <Column
          sx={{
            width: '80%',
            maxWidth: '800px',
            padding: '10px',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Row
            sx={{
              width: '700px',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <FormSectionHeader title="Customize your Reference Page" subtitle="Preview of your Reference Page" />
            {loading && <Skeleton sx={{ width: '300px' }} />}
            {!loading && (
              <Row
                sx={{
                  alignItems: 'flex-end',
                  gap: 1,
                }}
              >
                <Typography variant="headline4" sx={{ color: GREY_PALETTE[5], fontWeight: 400 }}>
                  {url}
                </Typography>
                <IconButton
                  color="primary"
                  sx={{
                    padding: 0,
                    height: 'auto',
                    width: 'auto',
                    '& .MuiButtonBase-root': { padding: 0, height: 'auto' },
                  }}
                  onClick={() => store.ui.copyToClipboard(url)}
                >
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="6.75"
                      y="8.03363"
                      width="9.5"
                      height="9.5"
                      rx="1.25"
                      stroke={GREY_PALETTE[4]}
                      strokeWidth="1.5"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4 4.78363H11C11.2761 4.78363 11.5 5.00749 11.5 5.28363V6.28363H13V5.28363C13 4.17906 12.1046 3.28363 11 3.28363H4C2.89543 3.28363 2 4.17906 2 5.28363V12.2836C2 13.3882 2.89543 14.2836 4 14.2836H5V12.7836H4C3.72386 12.7836 3.5 12.5598 3.5 12.2836V5.28363C3.5 5.00749 3.72386 4.78363 4 4.78363Z"
                      fill={GREY_PALETTE[4]}
                    />
                  </svg>
                </IconButton>
              </Row>
            )}
          </Row>
          {loading && <Skeleton sx={{ height: '400px', width: '100%', transform: 'none' }} />}
          {!loading && (
            <Box
              sx={{
                height: '400px',
                width: '100%',
                background: '#F7F9FC',
                padding: '20px 40px',
              }}
            >
              <ChampionsPreview
                settings={{
                  ...currentSettings,
                  logo: logo[0] ? logo[0].data_url : currentSettings.logo,
                }}
              />
            </Box>
          )}
          <Column sx={{ gap: 4, marginTop: '20px', width: '700px' }}>
            <FormInput
              label="Product Name"
              value={currentSettings.productName}
              loading={loading}
              placeholder={teamName}
              onChange={name => {
                if (!isSaving) {
                  setCurrentSettings({
                    ...currentSettings,
                    productName: name.currentTarget.value,
                  });
                }
              }}
            />
            <FormInputContainer
              label="Champions Logo"
              description="Recommended: 250x250px"
              sx={{ width: 'inherit' }}
              loading={loading}
            >
              <ImageUploader
                image={logo}
                setImage={e => {
                  if (!isSaving) {
                    setLogo(e);
                  }
                }}
                loading={uploadLoading}
                existingImageUrl={currentSettings.logo}
              />
            </FormInputContainer>
            <Row sx={{ justifyContent: 'space-between' }}>
              <Typography variant="headline3">
                {loading ? <Skeleton sx={{ width: '200px', height: '40px' }} /> : 'Customize Call to Action'}
              </Typography>
              {loading && (
                <Skeleton sx={{ fontSize: '30px' }}>
                  <Switch
                    checked={currentSettings.enableFollowOnAction}
                    onChange={enableFollowOnAction}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                          '& + .MuiSwitch-track': {
                            opacity: 1,
                          },
                        },
                      },
                    }}
                  />
                </Skeleton>
              )}
              {!loading && (
                <Switch
                  checked={currentSettings.enableFollowOnAction}
                  onChange={enableFollowOnAction}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="primary"
                  sx={{
                    '& .MuiSwitch-switchBase': {
                      '&.Mui-checked': {
                        '& + .MuiSwitch-track': {
                          opacity: 1,
                        },
                      },
                    },
                  }}
                />
              )}
            </Row>
            <Collapse in={currentSettings.enableFollowOnAction && !loading}>
              <Column sx={{ gap: 1.5 }}>
                <FormInput
                  label="Link"
                  disabled={!currentSettings.enableFollowOnAction}
                  required
                  description="Provide a link to your community or other action for prospects to take after registering to connect with a Champion"
                  placeholder="http://mycommunitylink.com"
                  value={currentSettings.followOnAction?.link}
                  error={!currentSettings.followOnAction?.link}
                  helperText={!currentSettings.followOnAction?.link ? 'Link cannot be blank' : ''}
                  onChange={event => {
                    if (!isSaving) {
                      setCurrentSettings({
                        ...currentSettings,
                        followOnAction: {
                          ...currentSettings.followOnAction,
                          link: event.currentTarget.value,
                        },
                      });
                    }
                  }}
                />
                <FormInput
                  disabled={!currentSettings.enableFollowOnAction}
                  label="Message"
                  placeholder="In the meantime, why not check out"
                  value={currentSettings.followOnAction?.message}
                  onChange={event => {
                    if (!isSaving) {
                      setCurrentSettings({
                        ...currentSettings,
                        followOnAction: {
                          link: currentSettings.followOnAction?.link ?? '',
                          button: currentSettings.followOnAction?.button,
                          message: event.currentTarget.value,
                        },
                      });
                    }
                  }}
                />
                <FormInput
                  disabled={!currentSettings.enableFollowOnAction}
                  label="Button label"
                  placeholder="Visit the Community"
                  value={currentSettings.followOnAction?.button}
                  onChange={event => {
                    if (!isSaving) {
                      setCurrentSettings({
                        ...currentSettings,
                        followOnAction: {
                          link: currentSettings.followOnAction?.link ?? '',
                          message: currentSettings.followOnAction?.message,
                          button: event.currentTarget.value,
                        },
                      });
                    }
                  }}
                />
              </Column>
            </Collapse>
            <Row sx={{ justifyContent: 'space-between' }}>
              <Typography variant="headline3">
                {loading ? <Skeleton sx={{ width: '200px', height: '40px' }} /> : 'Appearance'}
              </Typography>
            </Row>
            <Column>
              <ColorField
                label={'Sidebar and Button Color'}
                color={currentSettings.styles?.sidebar?.background ?? '#FFFFFF'}
                loading={loading}
                onChange={color => {
                  if (!isSaving) {
                    setCurrentSettings({
                      ...currentSettings,
                      styles: {
                        ...currentSettings.styles,
                        sidebar: {
                          ...currentSettings.styles?.sidebar,
                          background: color,
                        },
                        buttons: {
                          ...currentSettings.styles?.buttons,
                          primary: {
                            ...currentSettings.styles?.buttons?.primary,
                            background: color,
                          },
                        },
                      },
                    });
                  }
                }}
              />
              <ColorField
                label={'Sidebar and Button Text Color'}
                color={currentSettings.styles?.sidebar?.color ?? '#FFFFFF'}
                loading={loading}
                onChange={color => {
                  if (!isSaving) {
                    setCurrentSettings({
                      ...currentSettings,
                      styles: {
                        ...currentSettings.styles,
                        sidebar: {
                          ...currentSettings.styles?.sidebar,
                          color: color,
                        },
                        buttons: {
                          ...currentSettings.styles?.buttons,
                          primary: {
                            ...currentSettings.styles?.buttons?.primary,
                            color: color,
                          },
                        },
                      },
                    });
                  }
                }}
              />
              {/*<Column sx={{ gap: 2, marginTop: "65px" }}>
                <FormSectionHeader
                  title="Reference Limit"
                  subtitle="Protect your champions time - limit the number of requests they can receive per week."
                />
                <Box sx={{ marginBottom: "6px" }} />
                <FormInput
                  label="Max references per week"
                  placeholder={"0"}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  error={!validateMaxReferencesPerWeek()}
                  helperText={!validateMaxReferencesPerWeek()
                    ? "Must be a positive number"
                    : ""}
                  value={maxReferencesPerWeek}
                  onChange={(event) => {
                    if (!isSaving) {
                      // Allow user to clear input with empty value using state
                      setMaxReferencesPerWeek(event.currentTarget.value);
                      // Then validate non-numbers and transform empty to 0 for saving
                      if (validateMaxReferencesPerWeek()) {
                        saveMaxReferencesPerWeek(event.currentTarget.value);
                      }
                    }
                  }}
                />
              </Column> */}
              <Column sx={{ gap: 2, marginTop: '65px' }}>
                <FormSectionHeader
                  title="Connection Limit"
                  subtitle="Protect your champions time - Set a time in days for which your champion can receive a connection request."
                />
                <Box />
                <FormInput
                  label="Number of days"
                  description="Only 1 connection in X number of days"
                  placeholder={'0'}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  error={!validateMaxReferencesPerWeek()}
                  helperText={!validateMaxReferencesPerWeek() ? 'Must be a positive number' : ''}
                  value={connectionPeriod}
                  onChange={event => {
                    if (!isSaving) {
                      // Allow user to clear input with empty value using state
                      setConnectionPeriod(event.currentTarget.value);
                      // Then validate non-numbers and transform empty to 0 for saving
                      if (validateConnectionPeriod()) {
                        saveConnectionPeriod(event.currentTarget.value);
                      }
                    }
                  }}
                />
              </Column>
              <Column sx={{ gap: 2, marginTop: '65px' }}>
                <FormSectionHeader
                  title="Backup information"
                  subtitle="Plan ahead - provide an alternate point of contact in case your Champions are unavailable or decline a connection request email."
                />
                <Box sx={{ marginBottom: '6px' }} />
                <FormInput
                  label="Button Label"
                  placeholder={'Speak to our sales rep'}
                  value={currentSettings.backupInfo?.buttonLabel}
                  onChange={event => {
                    if (!isSaving) {
                      setCurrentSettings({
                        ...currentSettings,
                        backupInfo: {
                          ...currentSettings.backupInfo,
                          buttonLabel: event.currentTarget.value,
                        },
                      });
                    }
                  }}
                />
                <FormInput
                  label="Link"
                  description="Alternate link. You can use a web address or direct email link."
                  placeholder={'mailto:sales@email.com'}
                  // https://www.figma.com/file/VvWcPe4cORDbTFM4jikFBn?node-id=15066:313894#450333593
                  value={currentSettings.backupInfo?.link}
                  onChange={event => {
                    if (!isSaving) {
                      setCurrentSettings({
                        ...currentSettings,
                        backupInfo: {
                          ...currentSettings.backupInfo,
                          link: event.currentTarget.value,
                        },
                      });
                    }
                  }}
                />
              </Column>
            </Column>
            <Row
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 2,
              }}
            >
              {isSaving && (
                <>
                  <CaravelSpinner />
                  <Typography variant="bodySmallest">Saving Settings...</Typography>
                </>
              )}
              <Button variant="contained" disabled={isSaving || loading} onClick={saveSettings}>
                Save
              </Button>
            </Row>
          </Column>
        </Column>
      </Column>
    );
  },
);

import { gql } from '@apollo/client';
import { Person } from '@caravel/types';

export const GET_MEMBER = gql`
  query getMember($memberId: ID!) {
    community {
      id
      people(filterBy: { id__eq: $memberId }) {
        edges {
          node {
            id
            canReceiveReferences
            email
            emails
            name
            givenName
            familyName
            firstSeen
            lastSeen
            city
            state
            country
            website
            twitterHandle
            linkedinHandle
            facebookHandle
            githubHandle
            avatar
            position
            employmentSeniority
            employmentTitle
            customerFit
            engagement {
              frequency
              impact
              consistency
              gridStatus
            }
            activities(first: 10) {
              edges {
                node {
                  id
                  kind
                  occurredAt
                  primaryLabel
                  secondaryLabel
                  channel {
                    id
                    kind
                    name
                  }
                }
              }
            }
            notes {
              id
              text
              author {
                id
                firebaseUserId
                firstName
                lastName
              }
              createdAt
            }
            tags {
              id
              name
            }
            personas {
              id
              emails
              name
              city
              country
              state
              website
              facebookHandle
              githubHandle
              linkedinHandle
              twitterHandle
              employmentTitle
              channel {
                id
                kind
                name
              }
            }
            primaryOrganization {
              id
              name
              domain
            }
            organizations {
              id
              name
              domain
            }
            connectedTo {
              connectedSince
              lastExchange
              totalMeetings
              userId
            }
            referenceCount
            references {
              champion {
                name
                avatar
              }
              createdAt
              id
              message
              prospect {
                name
                avatar
              }
              status
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export interface GqlMemberRequestType {
  memberId: string;
}

export interface GqlMemberResponseType {
  community: {
    id: string;
    people: {
      edges: {
        node: Person;
      }[];
    };
  };
}

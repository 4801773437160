import { AddButton, Column, Flex, NarrowColumn, Row } from '@caravel/components/src';
import { ConnectionCard } from '@caravel/components/src/cards';
import { FormSectionHeader } from '@caravel/components/src/forms/form-section-header';
import { ChannelKind } from '@caravel/types/src';
import { NEUTRALS } from '@caravel/utils';
import { List, ListItem, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'stores';

import GmailActivityExample from '../../../assets/images/gmail-activity-example.png';
import { ChannelConnectionDescriptions } from '../channel-descriptions';

export const IntegrationsDetail = observer(() => {
  const store = useStore();
  const params = useParams<{ type: string }>();
  const type = params.type === 'google' ? 'email' : params.type;
  const integrationType = params.type === 'google' ? 'nylas' : params.type;
  const kind = `:${type}` as ChannelKind;
  const integration = store.teams.integrations.collection.find(i => i.id === `:commsor/${integrationType}`);
  const channels = integration?.channels?.slice().map(c => toJS(c)) ?? [];
  const description = ChannelConnectionDescriptions[kind];

  const startConnection = useCallback(async () => {
    // TODO: get the latest integration
    const url = new URLSearchParams(integration?.oauthUrl);
    const state = url.get('state')!;
    Cookies.set('state-cookie', state);
    Cookies.set(state, integration!.id);
    Cookies.set('integration-type', params.type);
    window.location.replace(integration?.oauthUrl ?? '');
  }, [integration, params.type]);

  if (!integration) {
    return null;
  }

  const name = integration.name === 'Email' ? 'Google' : integration.name;

  return (
    <Column alignItems="center">
      <NarrowColumn>
        <FormSectionHeader
          title={name}
          subtitle={description}
          divider={params.type !== 'google'}
          info="Support documentation"
          infoLink="https://support.commsor.com"
        />
        {params.type === 'google' && (
          <Column
            sx={{
              alignItems: 'center',
              backgroundColor: NEUTRALS.CHAMOMILE,
              borderRadius: '5px',
              padding: theme => theme.spacing(2, 0, 1),
              margin: '40px 0',
              '& img': {
                maxWidth: '360px',
                width: '100%',
              },
            }}
          >
            <img src={GmailActivityExample} alt="Google Example" />
          </Column>
        )}
        <Column sx={{ margin: theme => theme.spacing(1, 0) }}>
          <Row
            sx={{
              alignItems: 'center',
            }}
          >
            <Typography variant="inputLabel">Connections</Typography>
            <Flex flex={1} />
            <AddButton onClick={startConnection}>Add Connection</AddButton>
          </Row>
          <List>
            {channels.map(channel => {
              const createdBy = channel.createdBy ? store.teams.teamMembers.get(channel.createdBy) : undefined;
              return (
                <ListItem key={channel.id} disableGutters>
                  <ConnectionCard
                    {...channel}
                    connectedBy={{
                      name: createdBy?.name ?? 'Unknown',
                      avatar: createdBy?.photoUrl,
                    }}
                    kind={kind}
                    onDisconnect={() => {
                      //
                    }}
                    onDelete={() => {
                      //
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Column>
      </NarrowColumn>
    </Column>
  );
});

export default IntegrationsDetail;

import { gql } from '@apollo/client';
import { ManualPersonaAttribute } from '@caravel/types';

export const SET_PRIMARY_ATTRIBUTE = gql`
  mutation setPrimaryAttribute($input: SetPrimaryAttributeInput!) {
    setPrimaryAttribute(input: $input) {
      result {
        id
      }
    }
  }
`;

export interface GqlSetPrimaryAttributeRequestType {
  input: {
    memberId: string;
    personaId: string;
    attribute: ManualPersonaAttribute;
    value: string | undefined;
  };
}

export interface GqlSetPrimaryAttributeResponsetType {
  setPrimaryAttribute: {
    result: {
      id: string;
    };
  };
}

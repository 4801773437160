import { FilterMenuItem, FilterNestedMenuItem } from '@caravel/components/src';
import { AddFilterMenu } from '@caravel/components/src/filters/add-filter-menu';
import { Filter } from '@caravel/types/src';
import { upperFirst } from '@caravel/utils';
import { Divider, Typography } from '@mui/material';
import { forwardRef, PropsWithChildren, useState } from 'react';
import React from 'react';
import { useStore } from 'stores';
import { CHAMPIONS_FILTER_OPTIONS } from 'stores/filters';

export const ChampionsFilterMenu = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<{ onAddFilter: (filter: Filter) => void }>
>((props, ref) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const store = useStore();

  const handleSetAnchor = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    setAnchorEl(event?.currentTarget ?? null);
  };

  return (
    <>
      <AddFilterMenu ref={ref} onAddFilter={props.onAddFilter} anchorEl={anchorEl} handleSetAnchor={handleSetAnchor}>
        <FilterNestedMenuItem label="Champion" parentMenuOpen={Boolean(anchorEl)}>
          {CHAMPIONS_FILTER_OPTIONS.flatMap(filter => {
            const isDisabled = store.teams.filters.peopleFilters.findIndex(
              f => f.graphProperty == filter.graphProperty,
            );
            return [
              <FilterMenuItem
                disabled={isDisabled < 0 ? false : true}
                key={filter.type}
                onClick={() => {
                  props.onAddFilter(filter);
                  setAnchorEl(null);
                }}
                sx={{
                  marginRight: 0,
                }}
              >
                <Typography variant="bodySmaller">{filter.label ?? upperFirst(filter.type)}</Typography>
              </FilterMenuItem>,
              ...(filter.bottomDivider
                ? [
                    <Divider
                      key={`${filter.type}-divider`}
                      sx={{
                        '&.MuiDivider-root': {
                          marginTop: '5px',
                          marginBottom: '5px',
                        },
                      }}
                    />,
                  ]
                : []),
            ];
          })}
        </FilterNestedMenuItem>
      </AddFilterMenu>
    </>
  );
});
ChampionsFilterMenu.displayName = 'ChampionsFilterMenu';

import { Column, Row } from '@caravel/components/src';
import { TroveQuestion } from '@caravel/types/src';
import { Divider, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React from 'react';

/**
 * TODO:
 * - Send question comparator to form to persist per question
 * - Add logic to survey to check value after question submission
 * to determine next question
 */

export interface QuestionLogicProps {
  question: TroveQuestion;
  questions?: TroveQuestion[];
  index: number;
}

export interface QuestionComparator {
  func:
    | { (answer: number, value: number): boolean }
    | { (answer: string, value: string): boolean }
    | { (answer: any): boolean };
  enabledQuestions: { [key: string]: boolean };
}

export const QUESTION_COMPARATORS: { [key: string]: QuestionComparator } = {
  'less than': {
    func: (answer: number, value: number) => {
      return answer < value;
    },
    enabledQuestions: {
      text: false,
      'multiple-choice': false,
      'five-rating': true,
      'ten-rating': true,
    },
  },
  'greater than': {
    func: (answer: number, value: number) => {
      return answer > value;
    },
    enabledQuestions: {
      text: false,
      'multiple-choice': false,
      'five-rating': true,
      'ten-rating': true,
    },
  },
  'less than or equal to': {
    func: (answer: number, value: number) => {
      return answer <= value;
    },
    enabledQuestions: {
      text: false,
      'multiple-choice': false,
      'five-rating': true,
      'ten-rating': true,
    },
  },
  'greater than or equal to': {
    func: (answer: number, value: number) => {
      return answer >= value;
    },
    enabledQuestions: {
      text: false,
      'multiple-choice': false,
      'five-rating': true,
      'ten-rating': true,
    },
  },
  'equal to': {
    func: (answer: any, value: any) => {
      return answer === value;
    },
    enabledQuestions: {
      text: false,
      'multiple-choice': true,
      'five-rating': true,
      'ten-rating': true,
    },
  },
  'not equal to': {
    func: (answer: any, value: any) => {
      return answer !== value;
    },
    enabledQuestions: {
      text: false,
      'multiple-choice': true,
      'five-rating': true,
      'ten-rating': true,
    },
  },
  empty: {
    func: (answer: any) => {
      return !answer;
    },
    enabledQuestions: {
      text: true,
      'multiple-choice': false,
      'five-rating': false,
      'ten-rating': false,
    },
  },
  'not empty': {
    func: (answer: any) => {
      return answer;
    },
    enabledQuestions: {
      text: true,
      'multiple-choice': false,
      'five-rating': false,
      'ten-rating': false,
    },
  },
};

export function QuestionLogic(props: QuestionLogicProps) {
  const { question, questions, index } = props;
  const comparators = [
    'less than',
    'greater than',
    'less than or equal to',
    'greater than or equal to',
    'equal to',
    'not equal to',
    'empty',
    'not empty',
  ];

  return (
    <Column className="question-logic-root">
      <Divider sx={{ marginTop: '20px' }} />
      <Row sx={{ alignItems: 'center' }}>
        <Column>
          <Typography variant="body1">If answer</Typography>
        </Column>
        <Column>
          <FormControl size="small" sx={{ width: '200px', marginLeft: '8px', marginRight: '8px' }}>
            <InputLabel id="comparator">Condition</InputLabel>
            <Select className="logic-comparator">
              {comparators.map((comparator, i) => {
                if (QUESTION_COMPARATORS[comparator].enabledQuestions[question.type])
                  return <MenuItem key={i}>{comparator}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Column>
        {question.type !== 'text' && (
          <Column>
            <Select>
              {question.type === 'multiple-choice' &&
                question.choices!.map((choice, i) => {
                  return <MenuItem key={i}>{choice}</MenuItem>;
                })}
              {question.type === 'five-rating' &&
                Array.from(Array(5).keys()).map(n => {
                  return <MenuItem key={n}>{n + 1}</MenuItem>;
                })}
              {question.type === 'ten-rating' &&
                Array.from(Array(10).keys()).map(n => {
                  return <MenuItem key={n}>{n + 1}</MenuItem>;
                })}
            </Select>
          </Column>
        )}
      </Row>
      <Row sx={{ alignItems: 'center', marginLeft: '16px' }}>
        <Column>
          <Typography>skip to</Typography>
        </Column>
        <Column>
          <Select className="target-question">
            {questions?.slice(index + 1, questions.length).map((question, i) => {
              return (
                <MenuItem key={i}>
                  {questions.indexOf(question) + 1}
                  {'. ' + question.label}
                </MenuItem>
              );
            })}
          </Select>
        </Column>
      </Row>
    </Column>
  );
}

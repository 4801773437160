import { gql } from '@apollo/client';

/**
 * Set the flag which controls whether or not a champion can receive references to true or false
 */
export const SET_CAN_RECEIVE_REFERENCES = gql`
  mutation SetCanReceiveReferences($canReceiveReferences: Boolean, $championId: String) {
    setCanReceiveReferences(input: { canReceiveReferences: $canReceiveReferences, championId: $championId }) {
      result
    }
  }
`;

export interface GqlSetCanReceiveReferencesRequestType {
  canReceiveReferences: boolean;
  championId: string;
}

export interface GqlSetCanReceiveReferencesResponseType {
  data: {
    setCanReceiveReferences: {
      result: string;
    };
  };
}

import { FilterProps, TextField } from '@caravel/components/src';
import { CloseButton } from '@caravel/components/src/filters/close-button';
import { GREY_PALETTE } from '@caravel/utils';
import { SvgIcon } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import { SearchFilterIcon } from './filter-icons';

export const SearchFilter = observer((props: FilterProps) => {
  const { value, label, onChangeFilter: onChange, handleSubmitSearch } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <TextField
      size="small"
      value={value}
      onChange={e => onChange({ value: [e.target.value], condition: props.condition }, true)}
      onBlur={handleSubmitSearch}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          inputRef.current?.blur();
        }
      }}
      placeholder={label ?? 'Search...'}
      inputRef={inputRef}
      InputProps={{
        startAdornment: <SvgIcon component={SearchFilterIcon} sx={{ fill: 'none' }} />,
        endAdornment:
          value.length === 0 ? null : (
            <CloseButton onClose={() => onChange({ value: [], condition: props.condition })} />
          ),
      }}
      sx={{
        maxWidth: '350px',
        width: '100%',
        '& .MuiOutlinedInput-input': {
          height: '32px',
          backgroundColor: GREY_PALETTE[1],
          paddingLeft: '10px',
          '::placeholder': {
            color: GREY_PALETTE[3],
            opacity: 1,
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${GREY_PALETTE[2]}`,
        },

        '& .MuiInputBase-adornedStart': {
          backgroundColor: GREY_PALETTE[1],
          paddingLeft: '10px',
        },
      }}
    />
  );
});

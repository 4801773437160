import { ComposerMessageKind, ComposerMessages as ComposerProps } from '@caravel/types';
import { makeObservable, observable } from 'mobx';
import { BaseModel, makeToJS, makeUpdate } from 'models/base';

const skeletonProps: ComposerProps = {
  id: '',
  title: ':override',
  kind: ':SLACK' as ComposerMessageKind,
  content: '',
  sentAt: new Date(),
  createdBy: '',
  recepientCount: 0,
  segment: {
    id: '',
    name: '',
    createdBy: '',
    createdAt: '',
    segmentRules: [],
  },
  sentTo: {
    id: '',
  },
  channel: {
    id: '',
    kind: ':slack',
    name: '',
    integration: {
      id: '',
      name: '',
      description: '',
      strategy: '',
      authMethod: '',
      launchedAt: new Date(),
      popularity: 0,
      channels: [],
      oauthUrl: '',
    },
    createdAt: new Date(),
    createdBy: '',
    errors: [],
    lastActivity: null,
    status: null,
  },
  messagesSent: [
    {
      messageLink: '',
      member: {
        id: '',
        name: '',
        avatar: '',
      },
    },
  ],
};

export class Composer extends BaseModel<ComposerProps> implements ComposerProps {
  id = skeletonProps.id;
  title = skeletonProps.title;
  kind = skeletonProps.kind;
  content = skeletonProps.content;
  sentAt = skeletonProps.sentAt;
  createdBy = skeletonProps.createdBy;
  segment = skeletonProps.segment;
  sentTo = skeletonProps.sentTo;
  channel = skeletonProps.channel;
  recepientCount = skeletonProps.recepientCount;
  messagesSent = skeletonProps.messagesSent;

  constructor(props: ComposerProps) {
    super();
    makeObservable(this, {
      id: observable,
      title: observable,
      kind: observable,
      content: observable,
      sentAt: observable,
      createdBy: observable,
      segment: observable,
      sentTo: observable,
      channel: observable,
      recepientCount: observable,
      messagesSent: observable,
    });
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

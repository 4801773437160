import { createGQLClient, GET_CHANNELS, GqlChannelsResponseType } from '@caravel/utils';
import { makeObservable, observable, runInAction } from 'mobx';
import { Channel } from 'models/channel';

import { BaseStore } from './base';
import { RootStore } from './root';
export class ChannelStore extends BaseStore {
  collection = observable.array<Channel>([]);
  loading = false;
  loaded = false;
  channel?: Channel = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      channel: observable,
    });
  }

  teardown() {
    this.collection.clear();
    super.teardown();
  }

  resetCollection = () => {
    runInAction(() => {
      this.collection.clear();
    });
  };

  async mount() {
    await this.fetchChannels();
  }

  unmount() {
    this.resetCollection();
  }

  fetchChannels = async () => {
    if (this.loading) {
      console.debug('Channels already loading');
      return;
    }
    runInAction(() => (this.loading = true));

    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);

    try {
      const response: GqlChannelsResponseType = await graphqlClient.query<
        Record<string, string>,
        GqlChannelsResponseType
      >(GET_CHANNELS, {});

      const channels: Channel[] = response.community.channels.map(channel => new Channel(channel));

      runInAction(() => {
        this.collection.replace(channels);
        this.loading = false;
        this.loaded = true;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to fetch channels',
        duration: 5000,
      });
    }
  };
}

import { Champion } from '@caravel/types/src';
import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';

export const DeleteChampionsModal = (props: {
  champion?: Champion;
  handleClose: () => void;
  handleDeleteChampions: (value: string) => void;
  open: boolean;
}) => {
  const { champion, handleClose, handleDeleteChampions, open } = props;
  return (
    <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        sx={{
          background: 'white',
          width: '700px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '35px',
          paddingBottom: '35px',
          gap: '10px',
        }}
      >
        <Typography variant="headline2" sx={{ fontWeight: '500' }}>
          {`Are you sure you want to remove  ${champion?.givenName ?? ''} ${champion?.familyName ?? ''}?`}
        </Typography>
        <Typography variant="body" sx={{ color: '#4A5980' }}>
          {`Please note that removing ${champion?.givenName ?? ''} ${
            champion?.familyName ?? ''
          } also deletes their proof from the proof library if they have any. This action cannot be undone.`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-end',
            gap: '8px',
          }}
        >
          <Button variant="outlined" color="error" sx={{ minWidth: '80px' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ minWidth: '80px' }}
            onClick={() => handleDeleteChampions(champion?.id ?? '')}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

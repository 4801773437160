import { MemberRoles } from '@caravel/types';
import { User } from 'firebase/auth';
import { collection, doc, getDocs, onSnapshot } from 'firebase/firestore';
import { getDb, Logger } from 'helpers';
import { autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { Member, MemberProps } from 'models';
import { RootStore } from 'stores/root';

import { BaseStore } from './base';
import { MembershipStore } from './memberships';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('member-store');

/**
 * This is a data store for Caravel app members, specifically: the currently signed in firebase user.
 */
export class MemberStore extends BaseStore {
  debugName = 'MemberStore';

  /**
   * The current Member model for the signed in firebase user
   */
  current?: Member = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable<MemberStore>(this, {
      current: observable,
      online: computed,
      _online: observable,
    });
  }

  /**
   * Reflects user-presence state
   */
  get online() {
    return this._online;
  }

  memberships = new MembershipStore(this.rootStore);

  supers = observable.array<string>([]);

  _online = false;

  async setup() {
    await super.setup();
    await this.memberships.initialize();
    this.authSubs.addEffect('member', this.subToMember);
    this.addDisposable(
      autorun(() => {
        this.rootStore.analytics.trackIdentity(this.rootStore.auth.user?.uid);
      }),
    );
    const ref = collection(getDb(), 'supers');
    const supers = await getDocs(ref);
    runInAction(() => {
      this.supers.replace(supers.docs.map(doc => doc.id));
    });
  }

  teardown() {
    this.current = undefined;
    this._online = false;
    this.memberships.teardown();
    super.teardown();
  }

  /**
   * Get the current user's membership role from a given team id.
   */
  getMembershipRole = (teamId: string): MemberRoles | undefined => this.memberships.get(teamId)?.role;

  private subToMember = (user: User) => {
    const membersRef = doc(getDb(), 'members', user.uid);
    return onSnapshot(membersRef, snapshot => {
      runInAction(() => {
        if (snapshot.exists()) {
          this.current = new Member(
            {
              ...(snapshot.data() as MemberProps),
              id: snapshot.id,
            },
            this,
          );
        } else {
          this.current = undefined;
        }
      });
    });
  };
}

import { useTextWithEmoji } from '@caravel/components/src';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import React from 'react';

interface ActivityTextContent {
  links: any[]; // Could be JSX.Element or text depending on URL validity
  textContentArray: string[];
  placeholder: string;
}

/*
 ** My assumption is that different integrations will designate link structures
 ** Differently, so I've added this here to select
 ** The appropriate content sieving strategy
 **
 ** Each function should return an ActivityTextContent object
 */
const getActivityContent = (type: string, text: string): ActivityTextContent => {
  switch (type) {
    case ':activity.slack/message':
      return getSlackActivityContent(text);
    default:
      return getSlackActivityContent(text);
  }
};

/*
 ** Slack places clickable text within carets, and uses a | to seperate
 ** link and display text. This function determines what is a link structure
 ** and whether or not its a plain link or formatted and returns
 ** activity content to be rebuilt
 */
const getSlackActivityContent = (text: string): ActivityTextContent => {
  // Grab anything within double carets
  const linkStructureRegEx = /<(.*?)>/gm;
  const matches = [...text.matchAll(linkStructureRegEx)];
  const placeholder = ':::link:::';
  const textWithPlaceholders = text.replace(linkStructureRegEx, placeholder);
  const splitTextWithPlaceholders = textWithPlaceholders.split(new RegExp(`(?=${placeholder})|(?<=${placeholder})`));
  const links = matches.map((m, index) => {
    // Check to see if display text has been provided
    const formattedLink = [...m[1].matchAll(/([^<>]*)\|(.*)/gm)];
    if (formattedLink.length > 0) {
      return createLink(formattedLink[0][1], formattedLink[0][2], index);
    } else {
      return createLink(m[1], m[1], index);
    }
  });
  return {
    links,
    textContentArray: splitTextWithPlaceholders,
    placeholder,
  };
};

const rebuildContent = (activityContent: ActivityTextContent) => {
  const activityChildren: any[] = [];
  let linkPosition = 0;
  activityContent.textContentArray.forEach(e => {
    if (e === activityContent.placeholder) {
      activityChildren.push(activityContent.links[linkPosition]);
      linkPosition++;
    } else {
      activityChildren.push(useTextWithEmoji(e));
    }
  });
  return activityChildren;
};

const createLink = (link: string, text: string, index: number) => {
  // This seemed to be the safest way to validate a URL, since RegEx isn't the best
  let url;
  try {
    url = new URL(link);
  } catch (error) {
    // Not consuming error, as we are just piggybacking off url parse failure to default to plain text
    return text;
  }

  return (
    <Link href={url?.href} target="_blank" key={`${index}-${url?.href}`}>
      {text}
    </Link>
  );
};

/*
 ** Take activity content text and parse it for links. Return with clickable elements
 ** and emojis
 */
export const ActivityFeedContent = (props: any) => {
  const { text, type } = props;
  const activityContent: ActivityTextContent = getActivityContent(type, text);
  const content: any[] = rebuildContent(activityContent);

  return (
    <Typography sx={{ lineHeight: '19px', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} variant="bodySmall">
      {content}
    </Typography>
  );
};

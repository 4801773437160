import {
  CaravelSpinner,
  Column,
  Flex,
  NoResults,
  NumberChip,
  Row,
  RowActionMenu,
  SortableTableHead,
} from '@caravel/components/src';
import { Champion, Filter } from '@caravel/types/src';
import { ERROR_PALETTE, GREY_PALETTE, SENCHA } from '@caravel/utils';
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { observer } from 'mobx-react';
import { Person } from 'models/person';
import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-cool-inview';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from 'stores';

import { ReactComponent as ReferencesIcon } from '../../assets/svgs/circle-intersect.svg';
import { ReactComponent as NoChampionsIcon } from '../../assets/svgs/no-results-champions.svg';
import { ReactComponent as NoSearchResultsIcon } from '../../assets/svgs/no-results-search.svg';
import { AppToolbar } from '../app-toolbar';
import { FlexBox } from '../flex-box';
import { ExportPeople } from '../people/people-icons';
import { AddChampionsModal } from './champions-add-modal';
import { DeleteChampionsModal } from './champions-delete-modal';
import { ChampionsFilters } from './champions-filters';
import { AddChampion, DeleteIcon, FilterIcon } from './champions-icons';
import { OnboardingModal } from './champions-onboarding-modal';
import { ChampionsToolbarSearch } from './champions-toolbar-search';

// Matches to filter param or defaults to no filter
const getChampionsFilter = (filter: string): Filter | undefined => {
  switch (filter) {
    // TODO: Add filters
    default:
      return undefined;
  }
};

// Remove once fully implemented
const tableHead = [
  {
    id: 'name',
    label: 'Name',
    minWidth: '280px',
  },
  {
    id: 'organization',
    label: 'Organization',
    minWidth: '100px',
    width: `calc(100%/${4})`,
    maxWidth: '280px',
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: '100px',
    width: `calc(100%/${3})`,
    maxWidth: '280px',
  },
  {
    id: 'reference',
    label: 'Reference',
    minWidth: '100px',
    width: `calc(100%/${4})`,
    maxWidth: '280px',
  },
  {
    id: 'coversations',
    label: 'Conversations',
    width: `calc(100%/${4})`,
    maxWidth: '100px',
    disabledSort: true,
  },
  {
    id: 'context-menu',
    label: '',
    width: `40px`,
    maxWidth: '40px',
    disabledSort: true,
  },
];

const truncateText = (text: string, limit: number) => {
  if (text && text.length > limit) {
    return text.slice(0, limit).concat('...');
  }
  return text;
};

// Champions List should appear seperate from people, but use the same skeleton
// Can be refactor to be a part of people view eventually, but this is cleanest way for MVP
export const ChampionsList = observer(() => {
  const store = useStore();
  const history = useHistory();
  // Let us pass filters to component from route
  const { filter } = useParams<{ filter: string }>();
  const [addChampionsModalOpen, setAddChampionsModalOpen] = useState(false);
  const [deleteChampionsModalOpen, setDeleteChampionsModalOpen] = useState(false);
  const [currentChampion, setCurrentChampion] = useState<Champion | undefined>(undefined);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = React.useState<string | undefined>(undefined);
  const loadFilter = !(filter === currentFilter);
  const {
    ui,
    teams: { champions },
  } = store;

  const champs: Person[] = champions.champions.slice();
  const filterStore = store.teams.filters;
  const hasChampions = champs.length > 0;
  const canLoad = store.team && (!champions.pageInfo || champions.pageInfo?.hasNextPage) && !champions.championsLoading;
  const hasActiveFilters = Boolean(filterStore.championsFilters.find(f => f.type !== 'search' && f.type !== 'source'));
  const showNoResultsChampions = !hasChampions && !hasActiveFilters && !filterStore.championsSearchQuery;

  const toggleFiltersSection = () => {
    setOpenFilters(!openFilters);
  };

  const openAddChampions = () => {
    setAddChampionsModalOpen(true);
  };

  const closeAddChampions = () => {
    setAddChampionsModalOpen(false);
  };

  const openDeleteChampions = (champion: Champion) => {
    setDeleteChampionsModalOpen(true);
    setCurrentChampion(champion);
  };

  const closeDeleteChampions = () => {
    setDeleteChampionsModalOpen(false);
  };

  const submitChampions = async (value: string, canReceiveReferences: boolean) => {
    ui.workOn(async () => {
      await champions.addChampion(value, canReceiveReferences);
      closeAddChampions();
      await store.teams.champions.refreshChampions();
    });
  };

  const deleteChampion = async (championId: string) => {
    ui.workOn(async () => {
      await store.teams.champions.deleteChampion(championId);
      closeDeleteChampions();
      await store.teams.champions.refreshChampions();
    });
  };

  const handleUpdateOptInReferences = async (championId: string, optIn: boolean) => {
    ui.workOn(async () => {
      await store.teams.champions.updateCanReceiveReferences(championId, optIn);
    });
  };

  const viewOrgDetails = async (orgId: string) => {
    const hasCRM = store.features.hasCrm;
    if (hasCRM) {
      history.push(`/organizations/${orgId}`, { from: 'champions' });
      return;
    }
    history.push(`/champions/organizations/${orgId}`, { from: 'champions' });
    return;
  };

  const { observe } = useInView({
    onEnter: () => {
      loadMore();
    },
  });

  const loadMore = useCallback(() => {
    if (!canLoad && !loadFilter) return;
    ui.workOn(async () => {
      // Check to see if we need to load filters
      if (loadFilter) {
        const currentFilter = getChampionsFilter(filter ?? '');
        if (currentFilter) {
          await filterStore.forceAddFilters('champions', currentFilter);
        } else {
          await filterStore.clearFilters('champions');
          await champions.refreshChampions();
        }
      } else {
        await champions.fetchChampions();
      }
    });
  }, [canLoad, champions, filter, filterStore, loadFilter, ui]);

  function handleRequestSort(id: string): void {
    filterStore.onChangeOrder('champions', id);
    loadMore();
  }

  useEffect(() => {
    return () => {
      filterStore.clearFilters('champions');
      champions.refreshChampions();
    };
  }, [champions, filterStore]);

  useEffect(() => {
    if (!champs.length || loadFilter) {
      loadMore();
    }
  }, [champs.length, loadFilter, loadMore]);

  const getDisplayName = (givenName: string | undefined, familyName: string | undefined, email: string) => {
    if (!givenName || !familyName) {
      return email;
    }
    return `${givenName} ${familyName}`;
  };

  return (
    <>
      <Column height="100%">
        <AppToolbar
          title="Your Champions"
          subtitle={!champions.championsLoading && hasChampions ? `${champs.length}` : ''}
          actionButton={{
            startIcon: <AddChampion width="20px" />,
            onClick: openAddChampions,
            text: 'Add Champion',
          }}
        >
          <FlexBox gap={10} marginRight="18px" alignItems="center">
            <ChampionsToolbarSearch />
            {hasActiveFilters && (
              <Button
                variant="contained"
                color="tertiary"
                onClick={() => {
                  filterStore.resetFilters('champions');
                  champions.refreshChampions();
                }}
              >
                Clear Filters
              </Button>
            )}
            {!hasActiveFilters && (
              <IconButton sx={{ color: GREY_PALETTE[6] }} onClick={toggleFiltersSection}>
                <FilterIcon style={{ width: '20px', height: '20px' }} />
              </IconButton>
            )}
          </FlexBox>
        </AppToolbar>
        {/* <PeopleFilters hideFilters /> */}

        {showNoResultsChampions && !champions.championsLoading ? (
          <NoResults
            icon={<NoChampionsIcon />}
            title="No Champions"
            message="This is where your champions shine! Keep a list of your top raptors who roar the loudest about your brand."
            buttonText="Add Champion"
            buttonIcon={<AddChampion width="20px" />}
            onButtonClick={openAddChampions}
          />
        ) : (
          <>
            {hasChampions ? (
              <Column>
                <TableContainer sx={{ overflowX: 'unset' }}>
                  <ChampionsFilters hideFilters={openFilters} />
                  <Table stickyHeader size="small">
                    <SortableTableHead
                      cells={tableHead}
                      order={filterStore.championsOrder}
                      orderBy={filterStore.championsOrderBy}
                      onSort={handleRequestSort}
                    />
                    <TableBody>
                      {champs.map(row => (
                        <TableRow key={row.id} sx={{ minHeight: '54px', height: '100%' }}>
                          <TableCell
                            padding="none"
                            sx={{
                              tableLayout: 'fixed',
                              padding: '10px 0',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              paddingLeft: '30px',
                            }}
                          >
                            <Avatar
                              src={`${row.avatar ?? ''}`}
                              alt={`${row.givenName ?? 'FirstName'} ${row.familyName ?? 'LastName'}`}
                              sx={{
                                width: 30,
                                height: 30,
                                marginRight: '10px',
                                fontSize: '12px',
                              }}
                            >
                              {champions.getInitials(row.givenName ?? 'Unknown', row.familyName ?? 'Unknown')}
                            </Avatar>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Link
                                variant="headline4"
                                sx={{ color: 'inherit', cursor: 'pointer' }}
                                onClick={() => {
                                  if (row.id) {
                                    history.push(`/champions/${row.id}`, { from: 'champions-only' });
                                  }
                                }}
                              >
                                {truncateText(getDisplayName(row.givenName, row.familyName, row.email ?? ''), 40)}
                              </Link>
                            </Box>
                          </TableCell>
                          <TableCell>
                            {row.primaryOrganization && (
                              <Row
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <Avatar
                                  src={`https://logo.clearbit.com/${row.primaryOrganization?.domain ?? ''}`}
                                  alt={row.primaryOrganization.name}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    marginRight: '10px',
                                    fontSize: '12px',
                                  }}
                                />
                                <Link
                                  variant="headline4"
                                  sx={{ color: 'inherit', cursor: 'pointer' }}
                                  onClick={() => {
                                    if (row.primaryOrganization?.id) {
                                      viewOrgDetails(row.primaryOrganization.id);
                                    }
                                  }}
                                >
                                  {truncateText(row.primaryOrganization.name, 20)}
                                </Link>
                              </Row>
                            )}
                          </TableCell>
                          <TableCell>
                            <Typography variant="bodySmall" sx={{ wordBreak: 'break-word' }}>
                              {truncateText(row.employmentTitle ?? '', 80)}
                            </Typography>
                          </TableCell>
                          <>
                            <TableCell>
                              <Typography variant="headline4" sx={{ wordBreak: 'break-word' }}>
                                {row.canReceiveReferences ? (
                                  <Chip
                                    label="Yes"
                                    sx={{
                                      backgroundColor: SENCHA['50'],
                                      width: 'fit-content',
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label="No"
                                    sx={{
                                      backgroundColor: GREY_PALETTE[1],
                                      width: 'fit-content',
                                    }}
                                  />
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <NumberChip value={row.referenceCount ?? 0} jasmine sx={{ width: 'fit-content' }} />
                            </TableCell>
                          </>
                          <TableCell>
                            <RowActionMenu
                              id={`champions-context-${row.id}`}
                              canOpen={
                                !addChampionsModalOpen && !deleteChampionsModalOpen && !champions.championsLoading
                              }
                              options={[
                                <MenuItem
                                  key={
                                    row.canReceiveReferences
                                      ? 'remove-from-reference-champion '
                                      : 'add-to-reference-champion'
                                  }
                                  sx={{
                                    width: '100%',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                  }}
                                  onClick={() => {
                                    if (row.id) {
                                      handleUpdateOptInReferences(row.id, !row.canReceiveReferences);
                                    }
                                  }}
                                >
                                  <Row
                                    sx={{
                                      alignItems: 'left',
                                      gap: 2,
                                      width: '100%',
                                    }}
                                  >
                                    {row.canReceiveReferences ? (
                                      <ReferencesIcon style={{ width: '16px', height: '16px' }} />
                                    ) : (
                                      <ExportPeople style={{ width: '16px', height: '16px' }} />
                                    )}
                                    <Typography
                                      sx={{
                                        fontSize: '13px',
                                        lineHeight: '16px',
                                      }}
                                    >
                                      {row.canReceiveReferences
                                        ? 'Remove from reference progam'
                                        : 'Add to reference program'}
                                    </Typography>
                                  </Row>
                                </MenuItem>,
                                <MenuItem
                                  key={'delete-champion'}
                                  sx={{
                                    width: '100%',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                  }}
                                  onClick={() => {
                                    openDeleteChampions(row);
                                  }}
                                >
                                  <Row
                                    sx={{
                                      alignItems: 'left',
                                      gap: 2,
                                      width: '100%',
                                    }}
                                  >
                                    <DeleteIcon sx={{ width: '16px', height: '16px', color: ERROR_PALETTE[300] }} />
                                    <Typography
                                      sx={{
                                        fontSize: '13px',
                                        lineHeight: '16px',
                                        color: ERROR_PALETTE[300],
                                      }}
                                    >
                                      Remove from champions
                                    </Typography>
                                  </Row>
                                </MenuItem>,
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Column sx={{ padding: '28px' }}>
                  {!champions.championsLoading && <Column ref={observe} />}
                  {(champions.championsLoading || champions.pageInfo?.hasNextPage) && <CaravelSpinner />}
                  <Flex flex={1} sx={{ paddingTop: '20px', width: '100%' }} />
                </Column>
              </Column>
            ) : (
              <>
                {!champions.championsLoading && (
                  <NoResults
                    icon={<NoSearchResultsIcon />}
                    title="No search results"
                    message="Sorry, we couldn’t find what you were looking for."
                  />
                )}
              </>
            )}
          </>
        )}
      </Column>
      <AddChampionsModal
        handleClose={closeAddChampions}
        handleAddChampions={submitChampions}
        open={addChampionsModalOpen}
      />
      <DeleteChampionsModal
        champion={currentChampion}
        handleClose={closeDeleteChampions}
        handleDeleteChampions={deleteChampion}
        open={deleteChampionsModalOpen}
      />
      <OnboardingModal />
    </>
  );
});

import { Column, Row } from '@caravel/components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StopIcon from '@mui/icons-material/Stop';
import { Button, Grow, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { AppBar } from 'components/app-bar';
import { TroveConfigureTab } from 'components/sources/trove/trove-configure-tab';
import { TroveDesignTab } from 'components/sources/trove/trove-design-tab';
import { TroveEmbedTab } from 'components/sources/trove/trove-embed-tab';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Redirect, Route, useParams } from 'react-router-dom';
import { useStore } from 'stores';

import { SetupTab } from '../setup-tab';
import { TroveTriggersTab } from './trove-triggers-tab';

export type TroveFormTabs = 'setup' | 'configure' | 'design' | 'triggers' | 'embed';
const tabs: TroveFormTabs[] = ['setup', 'configure', 'design', 'triggers', 'embed'];

export interface TroveFormProps {
  basePath: string;
  path?: string;
}

export const TroveForm = observer((props: TroveFormProps) => {
  const { basePath, path } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const store = useStore();
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ sourceId?: string }>();
  const tab = location.pathname.split('/').pop() as TroveFormTabs;
  const tabIndex = tabs.indexOf(tab);
  const sourceId = params.sourceId;
  const source = undefined;
  const form = store.ui.troveForm;
  const canCommit = form.canCommit;

  const fromFeedback = path === '/feedback';
  const headerText = fromFeedback ? 'New feedback form' : 'New Source';
  const backButtonText = fromFeedback ? 'Feedback forms' : 'Sources';

  useEffect(() => {
    form.initializeForm(basePath, source, sourceId);
  }, [basePath, form, source, sourceId]);

  const handleTabChange = (event: SyntheticEvent, value: number) => {
    history.push(`${basePath}/${tabs[value]}`);
  };

  const onClickCancel = () => {
    if (canCommit && window.confirm('Are you sure you want to discard your changes?')) {
      form.resetFormProps();
    }
    history.push(`${path}`);
  };

  const onSaveChanges = async () => {
    const result = await store.ui.workOn(form.saveChanges);
    if (result?.ok) {
      history.push(`${path}`);
    }
  };

  if (tabIndex === -1) {
    return <Redirect to={`${basePath}/${tabs[0]}`} />;
  }

  const name = form.formProps?.name;

  return (
    <Column
      sx={{
        alignSelf: 'center',
        alignItems: 'center',
        flex: 1,
        width: '100%',
      }}
    >
      <AppBar>
        <Toolbar>
          <Row sx={{ width: 200 }}>
            <Button
              variant="outlined"
              color={canCommit ? 'error' : 'primary'}
              onClick={onClickCancel}
              startIcon={canCommit ? <StopIcon /> : <ChevronLeftIcon />}
            >
              {canCommit ? 'Cancel' : backButtonText}
            </Button>
          </Row>
          <Typography sx={{ flex: 1, textAlign: 'center' }}>{name ? name : headerText}</Typography>
          <Row sx={{ width: 200, justifyContent: 'flex-end' }}>
            <Grow in={canCommit}>
              <Button variant="contained" color="primary" onClick={onSaveChanges}>
                Save
              </Button>
            </Grow>
          </Row>
        </Toolbar>
      </AppBar>
      <Column
        sx={{
          flex: 1,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Tabs value={tabIndex > -1 ? tabIndex : 0} onChange={handleTabChange}>
          <Tab label="Setup" />
          <Tab label="Steps" disabled={!form.setupCanContinue} />
          <Tab label="Design" disabled={!form.designCanContinue} />
          <Tab label="Triggers" disabled={!form.designCanContinue} />
          <Tab label="Embed" disabled={!form.designCanContinue} />
        </Tabs>
        <Route exact path={`${basePath}/setup`}>
          <SetupTab
            sourceId={sourceId}
            submitDisabled={!form.setupCanContinue}
            submitText="Next"
            onSubmit={() => history.push(`${basePath}/configure`)}
            name={form.formProps?.name ?? ''}
            onChangeName={form.onChangeName}
            description={form.formProps?.description ?? ''}
            onChangeDescription={form.onChangeDescription}
            isActive={form.formProps?.isActive ?? true}
            onChangeIsActive={form.onChangeIsActive}
            deidentifyRequired={form.formProps?.settings?.deidentifyRequired ?? false}
            onChangeDeidentifyRequired={form.onChangeDeidentifyRequired}
            cleanHtml={form.formProps?.settings?.cleanHtml ?? true}
            onChangeCleanHtml={form.onChangeCleanHtml}
          />
        </Route>
        <Route exact path={`${basePath}/configure`}>
          <TroveConfigureTab />
        </Route>
        <Route exact path={`${basePath}/design`}>
          <TroveDesignTab />
        </Route>
        <Route exact path={`${basePath}/triggers`}>
          <TroveTriggersTab />
        </Route>
        <Route exact path={`${basePath}/embed`}>
          <TroveEmbedTab />
        </Route>
      </Column>
    </Column>
  );
});

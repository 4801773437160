import { CustomerFit } from '@caravel/types/src';
import { NEUTRALS } from '@caravel/utils';
import { Typography } from '@mui/material';
import { SxProps, TypographyProps } from '@mui/system';
import React from 'react';

const style = {
  highFit: {
    backgroundColor: '#D1FADF',
    text: 'High Fit',
  },
  lowFit: {
    backgroundColor: NEUTRALS.JASMINE,
    text: 'Low Fit',
  },
};

export const CustomerFitChip = React.forwardRef<
  HTMLSpanElement,
  { customerFit?: CustomerFit; sx?: SxProps } & TypographyProps
>((props, ref) => {
  const { customerFit, sx, ...rest } = props;

  if (!customerFit) return <>---</>;

  const { backgroundColor, text } = style[customerFit];

  return (
    <Typography
      {...rest}
      ref={ref}
      variant="bodySmallest"
      sx={{
        height: '24px',
        borderRadius: '8px',
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-start',
        backgroundColor,
        whiteSpace: 'nowrap',
        width: 'fit-content',
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
});

CustomerFitChip.displayName = 'CustomerFitChip';

import { Column, Flex, Row } from '@caravel/components/src';
import { DARK_GREY, GREY_PALETTE, NEUTRALS } from '@caravel/utils';
import { abbreviateDollarAmount, locationFormatted } from '@caravel/utils/src/strings';
import {
  Avatar,
  Card,
  CardContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Organization } from 'models/organization';
import React, { forwardRef } from 'react';

import { ReactComponent as FacebookIcon } from '../../../assets/svgs/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/svgs/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/svgs/twitter.svg';
import { PeopleSection } from './people-section';

const KeyTableCell = forwardRef<HTMLTableCellElement, TableCellProps>((props, ref) => (
  <TableCell
    {...props}
    ref={ref}
    sx={{
      color: GREY_PALETTE[5],
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
      padding: '5px 25px 5px 0 !important',
      ...props.sx,
    }}
  />
));
KeyTableCell.displayName = 'KeyTableCell';

const ValueTableCell = forwardRef<HTMLTableCellElement, TableCellProps>((props, ref) => (
  <TableCell
    {...props}
    ref={ref}
    sx={{
      color: GREY_PALETTE[7],
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...props.sx,
    }}
  />
));
ValueTableCell.displayName = 'ValueTableCell';

export const OrgDetails = observer((props: { organization: Organization }) => {
  const { organization } = props;

  return (
    <Column sx={{ width: '100%', padding: '20px 24px' }}>
      <Card elevation={0} variant="elevation" sx={{ backgroundColor: NEUTRALS.CHAMOMILE, borderRadius: '8px' }}>
        <CardContent sx={{ padding: '30px !important' }}>
          <Row sx={{ marginBottom: '35px', justifyContent: 'space-between', columnGap: '1rem' }}>
            <Column>
              <Typography variant="headline1" sx={{ marginBottom: '3px' }} component="h1">
                {organization.name}
              </Typography>
              <Typography
                variant="bodySmallest"
                sx={{
                  color: DARK_GREY,
                }}
              >
                {locationFormatted({
                  city: organization.locationCity,
                  state: organization.locationState,
                  country: organization.locationCountry,
                })}
              </Typography>
            </Column>
            <Avatar sx={{ width: 48, height: 48 }} src={`https://logo.clearbit.com/${organization.domain}`} />
          </Row>

          <Table
            size="small"
            sx={{
              '& .MuiTableCell-root': {
                border: 'unset',
                padding: '5px 0',
              },
            }}
          >
            <TableBody>
              <TableRow>
                <KeyTableCell>Industry</KeyTableCell>
                <ValueTableCell>{organization.industry}</ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Kind</KeyTableCell>
                <ValueTableCell>{organization.type}</ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Founded</KeyTableCell>
                <ValueTableCell>{organization.foundedYear}</ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Employees</KeyTableCell>
                <ValueTableCell>{organization.employeesRange}</ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Annual Revenue</KeyTableCell>
                <ValueTableCell>{abbreviateDollarAmount(organization.annualRevenue)}</ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Funding Raised</KeyTableCell>
                <ValueTableCell>{abbreviateDollarAmount(organization.fundsRaised?.toString())}</ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Website</KeyTableCell>
                <ValueTableCell>
                  {organization.domain ? (
                    <Link target="_blank" href={`https://www.${organization.domain}`} underline="always">
                      www.{organization.domain}
                    </Link>
                  ) : null}
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Description</KeyTableCell>
                <ValueTableCell
                  sx={{
                    overflow: 'visible',
                    textOverflow: 'none',
                    whiteSpace: 'normal',
                  }}
                >
                  {organization.description}
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <KeyTableCell>Social Media</KeyTableCell>
                <ValueTableCell>
                  <Flex gap="10px">
                    {organization.twitterHandle ? (
                      <Link
                        target="_blank"
                        href={`https://twitter.com/${organization.twitterHandle}`}
                        title={`View ${organization.name} on Twitter`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <TwitterIcon />
                      </Link>
                    ) : null}

                    {organization.facebookHandle ? (
                      <Link
                        target="_blank"
                        href={`http://facebook.com/${organization.facebookHandle}`}
                        title={`View ${organization.name} on Facebook`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <FacebookIcon />
                      </Link>
                    ) : null}

                    {organization.linkedinHandle ? (
                      <Link
                        target="_blank"
                        href={`https://linkedin.com/${organization.linkedinHandle}`}
                        title={`View ${organization.name} on LinkedIn`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <LinkedinIcon />
                      </Link>
                    ) : null}
                  </Flex>
                </ValueTableCell>
              </TableRow>
              {/* TODO: Add custom attributes here when available */}
            </TableBody>
          </Table>
          <PeopleSection organization={organization} />
        </CardContent>
      </Card>
    </Column>
  );
});

import { Reference } from '@caravel/types/src';
import { makeInitials, NEUTRALS } from '@caravel/utils/src';
import { Avatar, Card, CardContent, CardHeader, List, ListItem, Typography, useTheme } from '@mui/material';
import { formatDistance } from 'date-fns';
import React, { forwardRef } from 'react';

import { Column, Row } from './flex';
import { NumberChip } from './number-chip';

export interface ReferencesDetailsProps {
  firstName: string;
  references?: Reference[];
}
export const ReferencesDetails = forwardRef<HTMLDivElement, ReferencesDetailsProps>((props, ref) => {
  const theme = useTheme();

  const { references, firstName } = props;

  const hasReferences = Boolean(references && references.length > 0);

  return (
    <Card
      elevation={0}
      variant="elevation"
      sx={{ backgroundColor: NEUTRALS.CHAMOMILE, borderRadius: '8px', padding: '20px 30px', width: '100%' }}
    >
      <CardHeader
        sx={{ padding: 0, marginBottom: '16px' }}
        title={
          <Row sx={{ alignItems: 'center' }}>
            <Typography variant="headline4">References</Typography>
            <NumberChip
              value={references?.length ?? 0}
              jasmine
              sx={{ width: 'fit-content', padding: '2px 4px', marginLeft: '4px' }}
            />
          </Row>
        }
        titleTypographyProps={{ variant: 'headline3' }}
        subheader={hasReferences ? 'Prospects this Champion has connected with' : ''}
        subheaderTypographyProps={{ variant: 'bodySmaller' }}
      />
      <CardContent
        sx={{
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        {references && references.length > 0 && (
          <List>
            {references.map((reference, index) => {
              const updatedAt = reference.updatedAt ? new Date(reference.updatedAt) : undefined;
              return (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    padding: `${theme.spacing(1)} 0`,
                  }}
                >
                  <Avatar
                    src={reference.prospect?.avatar ?? ''}
                    alt={reference.prospect?.name ?? 'Unknown prospect'}
                    sx={{ width: '48px', height: '48px', alignSelf: 'start' }}
                  >
                    {makeInitials({ name: reference.prospect?.name ?? 'Unknown' })}
                  </Avatar>

                  <Column flex={1} paddingLeft={theme.spacing(2)}>
                    <Row paddingBottom={theme.spacing(1)}>
                      <Column flex={1}>
                        <Typography variant="headline4">{reference.prospect?.name ?? 'Unknown'}</Typography>
                        <Typography variant="bodySmallest">
                          {reference.prospect?.employmentTitle ?? 'Unkown Title'}
                        </Typography>
                        <Typography color={NEUTRALS.OOLONG} variant="bodySmallest">
                          {updatedAt ? `Connected ${formatDistance(updatedAt, new Date())} ago` : '---'}
                        </Typography>
                      </Column>
                    </Row>
                  </Column>
                </ListItem>
              );
            })}
          </List>
        )}
        {!hasReferences && (
          <Typography
            variant="bodySmall"
            color={'#696969'}
          >{`${firstName} has not made any reference connections yet`}</Typography>
        )}
      </CardContent>
    </Card>
  );
});

ReferencesDetails.displayName = 'ReferencesDetails';

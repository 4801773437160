import { ConnectedTo, MemberRoles, TeamConnection, TeamMemberDocument } from '@caravel/types';
import { UPDATE_TEAM_MEMBER } from '@caravel/utils';
import { collection, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DependentDisposer, getDb, Logger } from 'helpers';
import { Team, TeamMember, TeamMemberProps } from 'models';

import { FirebaseStore } from './base';
import { RootStore } from './root';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('team-member-store');

export class TeamMemberStore extends FirebaseStore<TeamMember, TeamMemberProps> {
  debugName = 'TeamMemberStore';

  private teamSubs = new DependentDisposer(() => this.rootStore.teams.currentTeam, 'team-subs');

  constructor(rootStore: RootStore) {
    super({
      rootStore,
      modelConstructor: TeamMember,
    });
  }

  async setup() {
    await super.setup();
    await this.teamSubs.initialize();
    this.teamSubs.addEffect('team-members', this.subToTeamMembers);
  }

  teardown() {
    this.teamSubs.teardown();
    super.teardown();
  }

  changeRole = async (teamMember: TeamMember, role: MemberRoles) => {
    teamMember.setWorking(true);
    const updateTeamMember = httpsCallable(getFunctions(), UPDATE_TEAM_MEMBER);
    try {
      await updateTeamMember({
        memberId: teamMember.id,
        teamId: this.rootStore.teams.currentTeam?.id,
        newRole: role,
      });
    } finally {
      teamMember.setWorking(false);
    }
  };

  private subToTeamMembers = (team: Team) => {
    this.collection.clear();
    const teamMembersRef = collection(getDb(), team.docRef.path, 'team-members');
    return onSnapshot(teamMembersRef, this.updateFromSnapshot);
  };

  getTeamConnections = (connectedTo: ConnectedTo[] | undefined): TeamConnection[] => {
    if (!connectedTo) return [];
    return connectedTo.reduce((acc: TeamMemberDocument[], connection) => {
      const teamMember = this.collection.find(teamMember => teamMember.id === connection.userId);
      if (teamMember) acc.push({ ...teamMember, ...connection });
      return acc;
    }, []);
  };
}

import { Person, TeamMemberDocument } from '@caravel/types/src';
import { GREY_PALETTE, makeInitials } from '@caravel/utils/src';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Modal,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { forwardRef, useState } from 'react';

import { Column, Row } from '../flex';
import { FormEditorInput } from '../forms/form-editor-input';
import { FormSelectInput } from '../forms/form-select-input';

export interface IntroductionModalProps {
  teamConnections: TeamMemberDocument[];
  person: Person;
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
  userId: string;
  initialConnection: TeamMemberDocument;
  handleAskIntroduction: (teamMemberId: string, personId: string, message: string) => void;
}

const style = {
  maxHeight: '90vh',
  width: '700px',
  // bgcolor: GREY_PALETTE[1],
  borderRadius: '5px',
  padding: '40px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const IntroductionModal = forwardRef<HTMLDivElement, IntroductionModalProps>((props, ref) => {
  const { teamConnections, onClose, open, initialConnection, handleAskIntroduction, person } = props;

  if (!open) return null;
  const theme = useTheme();

  const [connection, setConnection] = useState<string>(initialConnection.id || teamConnections[0].id);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (event: SelectChangeEvent<string | unknown>) => {
    setConnection(event.target.value as string);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const contentState = editorState.getCurrentContent();
    const message = stateToHTML(contentState);
    await handleAskIntroduction(connection, person.id!, message);
    setSubmitting(false);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={style}>
        <Row sx={{ justifyContent: 'space-between' }}>
          <CardHeader sx={{ padding: `0 0 ${theme.spacing(3)} 0` }} title="Ask for Introduction" />
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Row>
        <CardContent sx={{ padding: 0 }}>
          <Row
            sx={{
              bgcolor: GREY_PALETTE[1],
              display: 'flex',
              alignItems: 'center',
              marginBottom: theme.spacing(1),
              borderRadius: '4px',
            }}
          >
            <Typography variant="inputLabel" margin={`0 ${theme.spacing(2)}`}>
              Ask
            </Typography>

            <FormSelectInput
              style={{ width: '200px', height: '32px', backgroundColor: GREY_PALETTE[2], boxShadow: 'none' }}
              sx={{ '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' } }}
              value={connection}
              onChange={handleChange}
            >
              {teamConnections.map(connection => (
                <MenuItem key={connection.id} value={connection.id}>
                  <Column>
                    <Row sx={{ alignItems: 'center' }}>
                      <Avatar
                        src={connection.photoUrl}
                        alt={connection.name}
                        sx={{ width: '24px', height: '24px', marginRight: theme.spacing(1) }}
                      >
                        {makeInitials({ name: connection.name })}
                      </Avatar>
                      <Typography variant="inputLabel">{connection.name}</Typography>
                    </Row>
                  </Column>
                </MenuItem>
              ))}
            </FormSelectInput>

            <Typography variant="inputLabel" margin={`0 ${theme.spacing(2)}`}>
              to introduce you to
            </Typography>

            <Avatar
              src={person.avatar}
              alt={person.name}
              sx={{ width: '24px', height: '24px', marginRight: theme.spacing(1) }}
            >
              {makeInitials({ name: person.name, givenName: person.givenName, familyName: person.familyName })}
            </Avatar>
            <Typography variant="inputLabel">{person.name}</Typography>
          </Row>

          <FormEditorInput editorState={editorState} onEditorStateChange={value => setEditorState(value)} />
        </CardContent>
        <CardActions sx={{ justifyContent: 'end', padding: `${theme.spacing(3)} 0 0 0` }}>
          <Button sx={{ padding: '10px 24px' }} variant="contained" color="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={submitting} sx={{ padding: '10px 24px' }} variant="contained" onClick={handleSubmit}>
            Ask
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
});

IntroductionModal.displayName = 'IntroductionModal';

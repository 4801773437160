import { IconPill } from '@caravel/components/src/icon-pill';
import { upperFirst } from '@caravel/utils/src/strings';
import { Chip, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { ClassificationTag } from '../people-icons';

const SPECIAL_CASES: Record<string, string> = {
  'layout&design': 'Layout & Design',
};

export function ActivityClassification(props: { classification: string }) {
  const theme = useTheme();
  const classification = useMemo(() => {
    if (SPECIAL_CASES[props.classification]) {
      return SPECIAL_CASES[props.classification];
    }
    return upperFirst(props.classification.replace(/_/g, ' '));
  }, [props.classification]);
  return (
    <IconPill
      text={`${classification}`}
      icon={<ClassificationTag width={16} height={16} />}
      sx={{ color: '#093F5E', background: '#DAF2FF' }}
    />
  );
}

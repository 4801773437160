import { getComparator, stableSort } from '@caravel/utils';
import { Logger } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { TeamsView } from './teams-view';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('team-launcher-container');

export const TeamsContainer: FC = observer(() => {
  const store = useStore();
  const history = useHistory();

  const onLoadTeam = async (teamId: string) => {
    await store.ui.workOn(async () => {
      if (await store.teams.loadTeam(teamId)) {
        history.push('/');
      }
    });
  };

  const sorted = stableSort(store.teams.teams, getComparator('asc', 'displayName'));

  return <TeamsView teams={sorted} onLoadTeam={onLoadTeam} />;
});

// Muted backgrounds
export const GREY_PURPLE = '#EBE5EE';
export const STORMY_SKY = '#DFEEF9';
export const SAND = '#F9EFD9';
export const GIBBERING_GREY = '#F7F8F9';

// Greys
export const CHARCOAL_BLACK = '#231F20';
export const PRIMARY_GREY = '#AEAEAE';
export const DARK_GREY = '#696969';
export const MEDIUM_GREY = '#EBEBEB';
export const SECONDARY_GREY = MEDIUM_GREY;
export const LIGHT_GREY = '#F7F8F9';
export const COOL_DARK_GREY = '#686A7B';
export const STEEL_GREY = '#B8BBCC';
export const BLACK = '#141414';
export const WHITE = '#FFFFFF';
export const TRANSPARENT = '#00000000';

// Sentiment colors
export const LIGHT_GREEN = '#DAF4C5';
export const DARK_GREEN = '#92BE6F';
export const LIGHT_YELLOW = '#FFFAE2';
export const YELLOW = '#F0D471';
export const LIGHT_RED = '#F4C5C5';
export const RED = '#EE5C5C';

/**
 * Caravel's main UI colors
 */
export const ACTION_BLUE = '#006DFF';
export const LIGHT_ACTION_BLUE = '#BFDAFF';
export const VERY_LIGHT_ACTION_BLUE = '#E6F1FF';
export const ACTION = ACTION_BLUE;
export const ACTION_PURPLE = '#F1D4FE';
export const CAUTION_RED = '#C3542E';

// Gradients
export const GOSSIPY_GOLDEN = '#FFECC7';
export const SILENT_BLUE_SKY = '#C2E8FF';
export const LONG_WINDED_LILAC = '#F1D4FE';

export const GLOW_GRADIENT = `linear-gradient(
	89.98deg,
	${SILENT_BLUE_SKY} 0%,
	${GOSSIPY_GOLDEN} 25%,
	${LONG_WINDED_LILAC} 50%,
	${SILENT_BLUE_SKY} 75%,
	${GOSSIPY_GOLDEN} 100%
)`;

export const BROWN = '#DDD3BA';

// Charts
export const CHART_COLOR_MAP = {
  PURP: '#C8AEFF',
  BLUE: '#4F9AFF',
  SKY_BLUE: '#A6DDFF',
  LAV: '#D4DFFE',
  CANARY: '#FFE457',
  YELLOW: '#F0D471',
  ORANGE: '#FFC700',
  PUMPKIN: '#F59855',
  NEG_RED: '#F4C5C5',
  BROWN: '#DDD3BA',
  VERY_NEG: '#EE5C5C',
  HOT_PINK: '#FFA7E6',
  TEAL: '#97BEC7',
  PICKLE: '#92BE6F',
  HOT_GREEN: '#AAF8C9',
  POS_GREEN: '#DAF4C5',
  MUSTARD: '#E1BC5C',
};

// Commsor Rebrand Colors

export const NEUTRALS = {
  WHITE: '#FFFFFF',
  CHAMOMILE: '#F7F9FC', // Modal background
  JASMINE: '#E5EAF5', // Chips, Section Borders
  IRISH_BREAKFAST: '#B4BED4', // Disabled text, Inner Border
  EARL_GREY: '#8B99B5', // Input borders, Icons
  OOLONG: '#4A5980', // Secondary text
  ASSAM: '#192038', // Primary text
  ESPRESSO: '#101426', // Headings?
  CEYLON: '#2A385B', // Toasts
  DARJEELING: '1B2644',
};

export interface COLOR {
  '50': string;
  '100': string;
  '200': string;
  '300': string;
  '400': string;
}

export const LAVENDER: COLOR = {
  '50': '#EFEDFF',
  '100': '#A190F2',
  '200': '#6246EA',
  '300': '#3B2A8C',
  '400': '#271C5E',
};

// Modified Lavender according to FIGMA
export const COMMSOR_PURPLE: COLOR = {
  '50': '#EFEDFF',
  '100': '#A190F2',
  '200': '#693CF3',
  '300': '#3B2A8C',
  '400': '#271C5E',
};

export const ROOBIOS: COLOR = {
  '50': '#FFEAE1',
  '100': '#FFB0A6',
  '200': '#FF6C77',
  '300': '#B73657',
  '400': '#7A1441',
};

export const SENCHA: COLOR = {
  '50': '#D3FAE1',
  '100': '#78E3B5',
  '200': '#23A485',
  '300': '#117671',
  '400': '#06444E',
};

export const HIBISCUS: COLOR = {
  '50': '#FFECFD',
  '100': '#FFA9F1',
  '200': '#DA39BF',
  '300': '#BC22A1',
  '400': '#891975',
};

export const GINGER: COLOR = {
  '50': '#FEF5C6',
  '100': '#FED74D',
  '200': '#FDAB00',
  '300': '#B66E00',
  '400': '#794100',
};

export const BUTTERFLYPEA: COLOR = {
  '50': '#DAF2FF',
  '100': '#67BCEC',
  '200': '#268EC8',
  '300': '#11608C',
  '400': '#093F5E',
};

export const CHAI: COLOR = {
  '50': NEUTRALS.OOLONG,
  '100': NEUTRALS.CHAMOMILE,
  '200': NEUTRALS.JASMINE,
  '300': NEUTRALS.IRISH_BREAKFAST,
  '400': NEUTRALS.CEYLON,
};

import { WARM_SHADES_PALETTE } from '@caravel/utils';
import { Select, SelectProps, styled, Typography } from '@mui/material';
import React from 'react';

import { Column } from '../flex';
import { FormLabel } from './form-label';

// Styled reusable version of MUISelect. Can replace select.tsx eventually
const HarborSelect = styled(Select)<SelectProps>(({ theme }) => ({
  backgroundColor: theme.grey[0],
  margin: '5px 0',
  height: '36px',
  fontSize: '14px',
  '&:focus': {
    backgroundColor: theme.grey[0],
  },
  '&.placeholder': {
    color: theme.grey[3],
    fontStyle: 'italic',
  },
}));

export interface FormSelectInputProps extends SelectProps {
  description?: string;
}
/*
 * Wrapper for Select Class with additional customizations
 */
export const FormSelectInput = (props: FormSelectInputProps) => {
  return (
    <Column sx={{ gap: '4px' }}>
      {props.label && <FormLabel required={props.required}>{props.label}</FormLabel>}
      {props.description && (
        <Typography variant="inputLabelLine" color={WARM_SHADES_PALETTE[3]}>
          {props.description}
        </Typography>
      )}
      <HarborSelect {...{ ...props, label: undefined }} />
    </Column>
  );
};

import { BackgroundLoader } from '@caravel/components';
import { isEmptyString } from '@caravel/utils';
import { FlexBox } from 'components/flex-box';
import { Logger } from 'helpers';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from 'stores';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug, warn, error } = new Logger('accept-invite-container');

export const AcceptInviteContainer = observer(() => {
  const store = useStore();
  const history = useHistory();
  const location = useLocation();

  const { teamId, inviteId } = queryString.parse(location.search) as Record<string, string>;

  if (isEmptyString(teamId as string) || isEmptyString(inviteId as string)) {
    history.push('/');
  }

  useEffect(() => {
    const load = async () => {
      if (store.auth.signedIn) {
        try {
          await store.ui.workOn(async () => {
            debug('already signed in');
            await store.teams.invites.acceptInvite({ teamId, inviteId });
            history.push('/home');
          });
        } catch (e) {
          error('error accepting invite', e);
          history.push(`/sign-up?teamId=${teamId}&inviteId=${inviteId}`);
        }
      } else {
        history.push(`/sign-up?teamId=${teamId}&inviteId=${inviteId}`);
      }
    };

    load();
  }, [history, inviteId, store.auth.signedIn, store.auth.user, store.teams.invites, store.ui, teamId]);

  return (
    <FlexBox height="100vh" width="100vw">
      <BackgroundLoader title="Accepting team invitation..." />
    </FlexBox>
  );
});

export default AcceptInviteContainer;

import { Column, Flex } from '@caravel/components';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { isEmptyString, isValidEmail } from '@caravel/utils';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Button, IconButton, InputAdornment, Link, Typography } from '@mui/material';
import React, { useState } from 'react';

interface SignInEmailProps {
  onSignInWithEmail: (email: string) => void;
  onSignIn: (email: string, password: string) => void;
  onResetPassword: (email: string) => void;
}

export const SignInEmail = (props: SignInEmailProps) => {
  const { onSignInWithEmail, onSignIn, onResetPassword } = props;
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordMode, setPasswordMode] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const onClickShowPassword = () => setShowPassword(show => !show);

  const onMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSignInWithEmail = async () => {
    setSubmitting(true);
    if (isEmptyString(email)) {
      return;
    }
    await onSignInWithEmail(email!);
    setSubmitting(false);
  };

  const handleSignIn = async () => {
    setSubmitting(true);
    if (isEmptyString(email) || isEmptyString(password)) {
      return;
    }
    await onSignIn(email!, password!);
    setSubmitting(false);
  };

  const handleCancelPassword = () => {
    setPassword(undefined);
    setPasswordMode(false);
  };

  const handleResetPassword = () => {
    if (isEmptyString(email) || !isValidEmail(email)) {
      return;
    }
    onResetPassword(email!);
    handleCancelPassword();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (passwordMode) {
      handleSignIn();
    } else {
      handleSignInWithEmail();
    }
  };

  return (
    <Column alignItems="stretch" width="100%" maxWidth="360px">
      <Typography variant="headline1" component="h1" marginBottom="50px" textAlign="center">
        Glad to have you back
      </Typography>
      <form onSubmit={handleSubmit}>
        <Column gap="20px">
          <FormInput label="Work Email Address" placeholder="Enter Email" required fullWidth onChange={onChangeEmail} />

          {!passwordMode && (
            <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={!isValidEmail(email) || submitting}
              >
                Send Secure Link
              </Button>
              <Typography variant="bodySmallest" textAlign="center">
                We&rsquo;ll send a secure link to your email to sign in.
                <br />
                <Link component="button" onClick={() => setPasswordMode(true)}>
                  Use a password instead.
                </Link>
              </Typography>
            </>
          )}

          {passwordMode && (
            <>
              <Box>
                <FormInput
                  label="Password"
                  placeholder="Enter Password"
                  required
                  fullWidth
                  onChange={onChangePassword}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onClickShowPassword}
                          onMouseDown={onMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Flex justifyContent="flex-end" marginTop="8px">
                  <Link
                    sx={{ cursor: 'pointer' }}
                    variant="bodySmallest"
                    underline="always"
                    onClick={handleResetPassword}
                  >
                    Recover Password
                  </Link>
                </Flex>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={!isValidEmail(email) || isEmptyString(password) || submitting}
              >
                Sign in
              </Button>
            </>
          )}
        </Column>
      </form>
    </Column>
  );
};

import { gql } from '@apollo/client';

export const CREATE_SEGMENT = gql`
  mutation CreateSegment($input: UpsertSegmentInput!) {
    upsertSegment(input: $input) {
      result {
        id
      }
    }
  }
`;

type SegmentKind = ':segment.kind/dynamic' | ':segment.kind/static' | ':segment.kind/icp';

export type SegmentRuleConditionOperator =
  | ':segment.rule.condition.operator/is'
  | ':segment.rule.condition.operator/is-any-of'
  | ':segment.rule.condition.operator/is-not'
  | ':segment.rule.condition.operator/is-not-any-of'
  | ':segment.rule.condition.operator/includes-any-of'
  | ':segment.rule.condition.operator/includes-all-of'
  | ':segment.rule.condition.operator/includes-none-of'
  | ':segment.rule.condition.operator/after'
  | ':segment.rule.condition.operator/before'
  | ':segment.rule.condition.operator/is-greater-than'
  | ':segment.rule.condition.operator/is-less-than';

export type SegmentRuleConditionField =
  | 'layer'
  | 'seniority'
  | 'organization'
  | 'title'
  | 'gridStatus'
  | 'lastActive'
  | 'country'
  | 'city'
  | 'frequency'
  | 'consistency'
  | 'impact'
  | 'source'
  | 'firstActive'
  | 'signupDate'
  | 'organization_industry'
  | 'organization_city'
  | 'organization_state'
  | 'organization_country'
  | 'organization_founded_year'
  | 'organization_funds_raised'
  | 'organization_employee_range'
  | 'organization_employee_count'
  | 'organization_annual_revenue'
  | 'organization_estimated_annual_revenue'
  | 'organization_twitter_followers';

export type SegmentInterRuleOperator = ':segment.rule.inter-rule-operator/and' | ':segment.rule.inter-rule-operator/or';
export type SegmentInterConditionOperator =
  | ':segment.rule.conditions.inter-condition-operator/all'
  | ':segment.rule.conditions.inter-condition-operator/any';

export interface SegmentRuleCondition {
  id?: string;
  field: SegmentRuleConditionField;
  operator: SegmentRuleConditionOperator;
  values: string[];
}

export interface SegmentRule {
  id?: string;
  interConditionOperator: SegmentInterConditionOperator;
  conditions: SegmentRuleCondition[];
  interRuleOperator: SegmentInterRuleOperator;
}

export interface GqlCreateSegmentRequestType {
  input: {
    name?: string;
    description?: string;
    segmentKind?: SegmentKind;
    memberIds: (string | undefined)[] | undefined;
    segmentRules?: SegmentRule[];
  };
}

export interface GqlCreateSegmentResponseType {
  upsertSegment: {
    result: {
      id: string;
    };
  };
}

import { FilterProps, Row } from '@caravel/components/src';
import { InlineFilter } from '@caravel/components/src/filters/inline-filter';
import { SearchFilter } from '@caravel/components/src/filters/search-filter';
import { FilterCondition } from '@caravel/types/src';
import { createGQLClient, getFilterQuery, GqlPeopleFacetRequestType, GqlPeopleFacetResponseType } from '@caravel/utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'stores';
import { PEOPLE_FILTER_OPTIONS_CONFIG } from 'stores/filters';

import { PeopleFilterMenu } from './filters/people-filter-menu';
interface PeopleFiltersProps {
  hideFilters?: boolean;
}
export const PeopleFilters = observer((props: PeopleFiltersProps) => {
  const { hideFilters } = props;
  const store = useStore();
  const people = store.teams.people;
  const filterStore = store.teams.filters;
  const filters = filterStore.peopleFilters.slice();
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleRemoveFilter = (index: number) => () => {
    filterStore.onRemovePeopleFilter(index);
  };

  const handleSubmit = async () => {
    await store.teams.people.refreshCommunity();
  };

  const handleUpdateDynamicFilterQuery = async (
    collectionType: string,
    type: string,
    value: string,
    first: number,
    after: string,
  ) => {
    const query = getFilterQuery(collectionType, type);
    if (!query) {
      throw Error('Invalid query');
    }
    const { teamId, token } = await store.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);
    const response = await graphqlClient.query<GqlPeopleFacetRequestType, GqlPeopleFacetResponseType>(query, {
      query: value,
      first,
      after,
    });
    const matchedOptions = response.community.peopleFacets.hits.map(hit => ({
      id: hit.value,
      name: hit.value,
    }));
    return {
      matchedOptions,
      totalHits: response.community.peopleFacets.totalHits,
    };
  };

  return (
    <Row sx={{ padding: '14px 30px' }}>
      <Row
        sx={{
          flex: 1,
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        {filters.map((filter, i) => {
          const key = `${filter.type}-${i}`;
          const filterProps: FilterProps = {
            ...filter,
            options: PEOPLE_FILTER_OPTIONS_CONFIG[filter.type],
            onChangeFilter: (
              {
                value,
                condition,
              }: {
                value: string[];
                condition: FilterCondition | null;
              },
              skipRefresh = false,
            ) =>
              filterStore.onChangePeopleFilter(
                i,
                {
                  ...filter,
                  value,
                  condition,
                },
                skipRefresh,
              ),
            onRemoveFilter: handleRemoveFilter(i),
          };
          switch (filter.type) {
            case 'search':
              return (
                <SearchFilter
                  key={key}
                  {...filterProps}
                  updateDynamicFilterQuery={handleUpdateDynamicFilterQuery}
                  handleSubmitSearch={handleSubmit}
                  collectionType="people"
                />
              );
            default:
              if (hideFilters) {
                return;
              }
              return (
                <InlineFilter
                  key={key}
                  {...filterProps}
                  anchorRef={buttonRef}
                  refreshCollection={people.refreshCommunity}
                  updateDynamicFilterQuery={handleUpdateDynamicFilterQuery}
                  collectionType="people"
                />
              );
          }
        })}
        {hideFilters != true && (
          <PeopleFilterMenu ref={buttonRef} onAddFilter={filter => filterStore.onAddPeopleFilter(filter)} />
        )}
      </Row>
    </Row>
  );
});

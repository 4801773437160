import { Link, LinkProps as MUILinkProps } from '@mui/material';
import React from 'react';
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom';

export type RouterLinkProps = MUILinkProps & RRLinkProps;

export const RouterLinkView = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
  const { to, ...rest } = props;
  return <Link ref={ref} component={RRLink} to={to} {...rest} />;
});

RouterLinkView.displayName = 'RouterLinkView';

export default RouterLinkView;

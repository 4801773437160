import {
  AddButton,
  Column,
  Flex,
  FormLabel,
  FormLabelDescription,
  NarrowColumn,
  PageHeader,
  Row,
} from '@caravel/components/src';
import { LIGHT_GREY } from '@caravel/utils';
import { Divider, ListItemText, MenuItem, MenuList, Popover, Select, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStore } from 'stores';

import { ConditionView } from '../../conditions/condition-view';
import { TroveTabFooter } from './trove-tab-footer';

export const TroveTriggersTab = observer(() => {
  const store = useStore();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const form = store.ui.troveForm;
  const open = Boolean(anchorEl);

  useEffect(() => {
    form.checkForChangedQuestions();
  });

  if (!form.formProps) {
    return null;
  }

  const handleSubmit = () => {
    history.push(`${form.basePath}/design`);
  };

  return (
    <NarrowColumn>
      <PageHeader headline="Triggers" description="Configure events that trigger your popup form" />

      <Divider />
      <Column sx={{ marginTop: '30px' }}>
        <Row>
          <Typography variant="body" sx={{ marginBottom: '10px' }}>
            Events
          </Typography>
          <Flex sx={{ flex: 1 }} />
          <AddButton onClick={e => setAnchorEl(e.currentTarget)}>Add event</AddButton>
          <Popover
            id={open ? 'add-question-options' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuList dense sx={{ backgroundColor: LIGHT_GREY, minWidth: '140px' }}>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  form.onAddEvent('url');
                }}
              >
                <ListItemText>Page URL</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  form.onAddEvent('click');
                }}
              >
                <ListItemText>Button Click</ListItemText>
              </MenuItem>
            </MenuList>
          </Popover>
        </Row>

        {form.formProps.settings.triggers.events.length > 0 && (
          <Column
            sx={{
              backgroundColor: LIGHT_GREY,
              padding: '20px',
              marginTop: theme => theme.spacing(2),
            }}
          >
            {form.formProps.settings.triggers.events.map((event, i) => {
              return (
                <ConditionView
                  key={`${event.attribute}-${i}`}
                  index={i}
                  listIndex={0}
                  condition={event}
                  attributes={[
                    {
                      name: 'Page URL',
                      value: 'url',
                    },
                    {
                      name: 'Click',
                      value: 'click',
                    },
                  ]}
                  autocompleteParams={{}}
                  onChange={form.onChangeEvent}
                  onRemove={form.onRemoveEvent}
                />
              );
            })}
          </Column>
        )}
      </Column>

      <Divider sx={{ marginTop: '30px' }} />
      <Column sx={{ marginTop: '30px' }}>
        <Typography variant="body" sx={{ marginBottom: '20px' }}>
          Options
        </Typography>
        <Row alignItems="center">
          <Column flex={1}>
            <FormLabel>Delay</FormLabel>
            <FormLabelDescription
              sx={{
                marginRight: '10px',
              }}
            >
              Number of seconds to wait after the events to display the popup form
            </FormLabelDescription>
          </Column>
          <Select
            value={form.formProps.settings.triggers.delay ?? 0}
            onChange={e => form.onChangeTriggersProp('delay', e.target.value)}
          >
            <MenuItem value={0}>0s</MenuItem>
            <MenuItem value={1}>1s</MenuItem>
            <MenuItem value={2}>2s</MenuItem>
            <MenuItem value={3}>3s</MenuItem>
            <MenuItem value={4}>4s</MenuItem>
            <MenuItem value={5}>5s</MenuItem>
          </Select>
        </Row>
      </Column>

      {form.formProps.settings.questions.length > 0 && (
        <>
          <Divider sx={{ marginTop: theme => theme.spacing(4) }} />
          <TroveTabFooter tab="embed" onSubmit={handleSubmit} disabled={!form.designCanContinue} />
        </>
      )}
    </NarrowColumn>
  );
});

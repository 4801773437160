import { gql } from '@apollo/client';

export const MERGE_MEMBERS = gql`
  mutation MergeMembers($input: MergeMembersInput!) {
    mergeMembers(input: $input) {
      result {
        id
      }
    }
  }
`;

export interface GqlMergeMembersRequestType {
  input: {
    memberIds: string[];
    winningId: string;
  };
}

export interface GqlMergeMembersResponseType {
  mergeMembers: {
    result: {
      id: string;
      // Add additional fields here
    };
  };
}

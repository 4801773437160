import { ProofSourceType } from '@caravel/types/src';
import {
  createGQLClient,
  GqlUpsertProofRequestType,
  GqlUpsertProofResponseType,
  isEmptyString,
  UPSERT_PROOF,
} from '@caravel/utils';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import React from 'react';

import { BaseStore } from '../base';
import { Proof } from '../proof-library';
import { RootStore } from '../root';

const host = process.env.GRAPHQL_HOST!;

export class ProofForm extends BaseStore {
  initialProps?: Proof = undefined;
  formProps?: Proof = undefined;
  proofId?: string = undefined;
  formLoading = false;
  showErrors = false;
  showProofForm = false;

  get customerNameInvalid() {
    return isEmptyString(this.formProps?.customerName);
  }

  get contentInvalid() {
    return isEmptyString(this.formProps?.content);
  }

  get sourceLinkInvalid() {
    return (
      isEmptyString(this.formProps?.sourceLink) ||
      !this.formProps?.sourceLink?.includes('.') ||
      (!this.formProps?.sourceLink?.startsWith('http://') && !this.formProps?.sourceLink?.startsWith('https://'))
    );
  }

  get videoSourceInvalid() {
    return (
      isEmptyString(this.formProps?.sourceLink) ||
      (!this.formProps?.sourceLink?.includes('youtube.com') &&
        !this.formProps?.sourceLink?.includes('youtu.be') &&
        !this.formProps?.sourceLink?.includes('vimeo.com'))
    );
  }

  get validVideoSource() {
    return !this.customerNameInvalid && !this.sourceLinkInvalid && !this.videoSourceInvalid;
  }

  get validSocialMediaSource() {
    return !this.customerNameInvalid && !this.sourceLinkInvalid;
  }

  get validTextQuoteSource() {
    return !this.customerNameInvalid && !this.contentInvalid;
  }

  get validCaseStudySource() {
    return !this.contentInvalid;
  }

  get canSave() {
    switch (this.formProps?.sourceType) {
      case 'VIDEO':
        return this.validVideoSource;
      case 'TWITTER':
      case 'LINKEDIN':
        return this.validSocialMediaSource;
      case 'CASESTUDY':
        return this.validCaseStudySource;
      case 'TEXTQUOTE':
        return this.validTextQuoteSource;
      default:
        return false;
    }
  }

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      initialProps: observable,
      formProps: observable,
      proofId: observable,
      showErrors: observable,
      showProofForm: observable,
      customerNameInvalid: computed,
      contentInvalid: computed,
      sourceLinkInvalid: computed,
      videoSourceInvalid: computed,
      validVideoSource: computed,
      validSocialMediaSource: computed,
      validTextQuoteSource: computed,
      validCaseStudySource: computed,
      canSave: computed,
    });
  }

  showFormModal = () => {
    runInAction(() => {
      this.showProofForm = true;
    });
  };

  closeFormModal = () => {
    runInAction(() => {
      this.showProofForm = false;
    });
  };

  resetFormProps = () => {
    runInAction(() => {
      this.initialProps = undefined;
      this.formProps = undefined;
      this.proofId = undefined;
      this.showErrors = false;
    });
  };

  updateFormProps = (update: Partial<Proof>) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      ...update,
    };
  };

  createProof(type?: ProofSourceType) {
    const props: Proof = {
      sourceType: type ?? 'VIDEO',
      proofTags: [],
    };
    runInAction(() => {
      this.showErrors = false;
      this.initialProps = props;
      this.formProps = props;
    });
  }

  changeProof = (event: Partial<Proof>) => {
    runInAction(() => {
      if (!this.formProps) {
        return;
      }
      this.formProps = {
        ...this.formProps,
        ...event,
      };
    });
  };

  setProofForm = (proof: Proof, id?: string) => {
    runInAction(() => {
      this.proofId = id;
      this.initialProps = {
        ...proof,
      };
      this.formProps = {
        ...proof,
      };
    });
  };

  saveChanges = async () => {
    if (!this.canSave) {
      runInAction(() => (this.showErrors = true));
      return;
    }
    runInAction(() => (this.formLoading = true));
    await this.rootStore.ui.workOn(async () => {
      const { teamId, token } = await this.rootStore.getTeamAndToken();
      const graphqlClient = createGQLClient(teamId, token, host);
      const clientMutationId = `upsert-proof-${teamId}-${Date.now()}`;
      try {
        const response = await graphqlClient.query<GqlUpsertProofRequestType, GqlUpsertProofResponseType>(
          UPSERT_PROOF,
          {
            clientMutationId,
            id: this.proofId ?? undefined,
            customerName: this.formProps?.customerName ?? undefined,
            content: this.formProps?.content ?? undefined,
            sourceType: this.formProps?.sourceType ?? undefined,
            sourceLink: this.formProps?.sourceLink ?? undefined,
            dateCreated: this.formProps?.dateCreated ?? new Date().toISOString(),
            championMemberId: this.formProps?.champion?.id ?? undefined,
            employmentTitle: this.formProps?.employmentTitle ?? undefined,
            organizationName: this.formProps?.organizationName ?? undefined,
            proofTags: this.formProps?.proofTags?.map(tag => ({ name: tag.name })) ?? [],
          },
        );
        console.info(response.upsertProof.clientMutationId);
      } catch (e) {
        console.warn(e);
        this.rootStore.notifications.display({
          severity: 'error',
          message: 'Failed to create proof',
          duration: 5000,
        });
      }
    });
    runInAction(() => {
      this.formLoading = false;
      this.showProofForm = false;
    });
  };
}

import { GREY_PALETTE, NEUTRALS } from '@caravel/utils';
import {
  ListSubheader as MuiListSubheader,
  ListSubheaderProps as MuiListSubheaderProps,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  MenuProps as MuiMenuProps,
} from '@mui/material';
import React, { forwardRef } from 'react';

export interface MenuProps extends MuiMenuProps {
  horizontal?: 'left' | 'right';
}

export const Menu = forwardRef<HTMLDivElement, MenuProps>(({ children, horizontal = 'right', sx, ...props }, ref) => (
  <MuiMenu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal,
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal,
    }}
    {...props}
    ref={ref}
    PaperProps={{
      variant: 'elevation',
      elevation: 3,
    }}
    sx={{
      '& .MuiPaper-root': {
        backgroundColor: NEUTRALS.CHAMOMILE,
        border: 'none',
        boxShadow: '0px 4px 16px 0px #231F203D',
        marginTop: theme => theme.spacing(1),
      },
      ...sx,
    }}
  >
    {children}
  </MuiMenu>
));

Menu.displayName = 'Menu';

export interface MenuItemProps extends MuiMenuItemProps {}

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(({ children, ...props }, ref) => (
  <MuiMenuItem
    {...props}
    ref={ref}
    sx={{
      fontSize: '13px',
      padding: theme => theme.spacing(1, 4, 1, 1),
      ...props.sx,
    }}
  >
    {children}
  </MuiMenuItem>
));

MenuItem.displayName = 'MenuItem';

export const GroupSubheader = forwardRef<HTMLLIElement, MuiListSubheaderProps>(({ children, ...props }, ref) => (
  <MuiListSubheader
    {...props}
    ref={ref}
    sx={{
      backgroundColor: 'transparent',
      color: GREY_PALETTE[7],
      fontSize: '13px',
      lineHeight: '26px',
      display: 'flex',
      alignItems: 'center',
      padding: theme => theme.spacing(1, 4, 1, 1),
      '& svg': {
        width: '20px',
        marginRight: theme => theme.spacing(1),
      },
      ...props.sx,
    }}
  >
    {children}
  </MuiListSubheader>
));

GroupSubheader.displayName = 'GroupSubheader';

import { Filter, FilterCondition, FilterOptionsConfig } from '@caravel/types/src';

import { MenuProps } from '..';

export interface FilterProps extends Filter {
  options: FilterOptionsConfig;
  onChangeFilter: (changes: { value: string[]; condition: FilterCondition | null }, skipRefresh?: boolean) => void;
  onClickFilter?: (filter: { condition: FilterCondition | null; value: string[] }) => void;
  onCloseDateMenu?: () => void;
  onRemoveFilter: () => void;
  // Required if dynamic-filter-menu is used
  updateDynamicFilterQuery?: (
    collectionType: string,
    type: string,
    value: string,
    first: number,
    after: string,
  ) => Promise<{ matchedOptions: { id: string; name: string }[]; totalHits: number }>;
  handleSubmitSearch?: () => Promise<void>;
}

export function getFilterPropsFrom(obj: MenuProps & FilterProps): { menuProps: MenuProps; filterProps: FilterProps } {
  const {
    type,
    value,
    condition,
    graphProperty,
    label,
    onChangeFilter,
    onClickFilter,
    onCloseDateMenu,
    onRemoveFilter,
    options,
    pluralized,
    ...menuProps
  } = obj;
  return {
    menuProps,
    filterProps: {
      type,
      value,
      condition,
      graphProperty,
      label,
      pluralized,
      options,
      onChangeFilter,
      onClickFilter,
      onCloseDateMenu,
      onRemoveFilter,
    },
  };
}

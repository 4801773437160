import React from 'react';

export interface TroveIcon {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}

export const FiveRatingIcon = (props: TroveIcon) => {
  const { fill, stroke, width, height } = props;
  return (
    <svg width={width ?? '20px'} height={height ?? '20px'} fill={fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0075 13.0696C11.0838 13.0696 11.8346 12.3388 11.8346 11.3271C11.8346 10.3477 11.1534 9.64169 10.2287 9.64169C9.81609 9.64169 9.45068 9.80575 9.26176 10.0295H9.23193L9.37859 8.67969H11.5686V7.90909H8.61545L8.34947 10.5341L9.18221 10.6708C9.35373 10.4819 9.67688 10.3551 9.98264 10.3576C10.5419 10.3601 10.9446 10.7727 10.9422 11.3494C10.9446 11.9187 10.5519 12.3239 10.0075 12.3239C9.54762 12.3239 9.17724 12.033 9.14492 11.6154H8.25004C8.2749 12.4606 9.01318 13.0696 10.0075 13.0696Z"
        fill={stroke ?? '#1A1B21'}
      />
      <rect x="1.5" y="4.5" width="17" height="11" rx="1.5" stroke={stroke ?? '#231F20'} />
    </svg>
  );
};

export const TenRatingIcon = (props: TroveIcon) => {
  const { fill, stroke, width, height } = props;
  return (
    <svg width={width ?? '20px'} height={height ?? '20px'} fill={fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.50697 7.91016H7.62948L6.36421 8.72301V9.56818L7.55491 8.80753H7.58474V13.0011H8.50697V7.91016ZM11.6813 13.098C12.9093 13.1005 13.6426 12.131 13.6426 10.4606C13.6426 8.80007 12.9043 7.84056 11.6813 7.84056C10.4583 7.84056 9.72252 8.79759 9.72004 10.4606C9.72004 12.1286 10.4533 13.098 11.6813 13.098ZM11.6813 12.32C11.0475 12.32 10.6497 11.6836 10.6522 10.4606C10.6547 9.24752 11.0499 8.60867 11.6813 8.60867C12.3152 8.60867 12.7104 9.24752 12.7129 10.4606C12.7129 11.6836 12.3177 12.32 11.6813 12.32Z"
        fill={stroke ?? '#1A1B21'}
      />
      <rect x="1.5" y="4.5" width="17" height="11" rx="1.5" stroke={stroke ?? '#231F20'} />
    </svg>
  );
};

export const DeleteIcon = (props: TroveIcon) => {
  const { fill, width, height } = props;
  return (
    <svg width={width ?? '20px'} height={height ?? '21px'} fill={fill ?? '#1A1B21'} xmlns="http://www.w3.org/2000/svg">
      <path d="M5 4C4.44772 4 4 4.44772 4 5V6H16V5C16 4.44772 15.5523 4 15 4H13C13 3.44772 12.5523 3 12 3H8C7.44772 3 7 3.44772 7 4H5Z" />
      <path d="M15 8H5V17C5 17.5523 5.44772 18 6 18H14C14.5523 18 15 17.5523 15 17V8Z" />
    </svg>
  );
};

export const InfoIcon = () => (
  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99984 1.16675C3.77984 1.16675 1.1665 3.78008 1.1665 7.00008C1.1665 10.2201 3.77984 12.8334 6.99984 12.8334C10.2198 12.8334 12.8332 10.2201 12.8332 7.00008C12.8332 3.78008 10.2198 1.16675 6.99984 1.16675ZM7.69986 9.91675H6.29987V6.30011H7.69986V9.91675ZM7.69986 5.60011H6.29987V4.20011H7.69986V5.60011Z"
      fill="#696969"
    />
  </svg>
);

export const MultipleChoiceIcon = (props: TroveIcon) => {
  const { fill, width, height } = props;
  return (
    <svg width={width ?? '18px'} height={height ?? '20px'} fill={fill ?? '#1A1B21'} xmlns="http://www.w3.org/2000/svg">
      <path d="M3.48959 11.6667H6.82293V8.33332H3.48959V11.6667ZM3.48959 15.8333H6.82293V12.5H3.48959V15.8333ZM3.48959 7.49999H6.82293V4.16666H3.48959V7.49999ZM7.65626 11.6667H17.6563V8.33332H7.65626V11.6667ZM7.65626 15.8333H17.6563V12.5H7.65626V15.8333ZM7.65626 4.16666V7.49999H17.6563V4.16666H7.65626Z" />
    </svg>
  );
};

export const TextResponseIcon = (props: TroveIcon) => {
  const { fill, stroke, width, height } = props;
  return (
    <svg width={width ?? '20px'} height={height ?? '20px'} fill={fill ?? '#1A1B21'} xmlns="http://www.w3.org/2000/svg">
      <line x1="2" y1="6" x2="14.1562" y2="6" stroke={stroke ?? '#1A1B21'} strokeWidth="2" strokeLinecap="round" />
      <line x1="2" y1="9" x2="14.1562" y2="9" stroke={stroke ?? '#1A1B21'} strokeWidth="2" strokeLinecap="round" />
      <line x1="2" y1="12" x2="8.59375" y2="12" stroke={stroke ?? '#1A1B21'} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

import { Column, Row } from '@caravel/components/src';
import { ChampionsSettingsConfig } from '@caravel/types/src';
import { Box, Skeleton, SvgIcon, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { CommsorBranding } from './champions-settings-icons';

export interface ChampionsPreviewProps {
  settings: ChampionsSettingsConfig;
}

export const ChampionsPreview = observer((props: ChampionsPreviewProps) => {
  const { settings } = props;

  return (
    <Row sx={{ height: '100%', boxShadow: 3 }}>
      <Column
        sx={{
          background: settings.styles?.sidebar?.background,
          height: '100%',
          flexGrow: 1,
          padding: '30px',
          width: '120px',
        }}
      >
        <Box
          sx={{
            '& img': { maxHeight: '40px', maxWidth: '60%' },
          }}
        >
          <img src={settings.logo} />
        </Box>
        <Column
          sx={{
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            gap: 4,
          }}
        >
          <Column sx={{ gap: 0.2, width: '90%' }}>
            <Skeleton
              variant="text"
              sx={{
                fontSize: '8px',
                width: '60%',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
          </Column>
          <Column sx={{ gap: 0.2, width: '90%' }}>
            <Skeleton
              variant="text"
              sx={{
                fontSize: '8px',
                width: '60%',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
          </Column>
          <Column sx={{ gap: 0.2, width: '90%' }}>
            <Skeleton
              variant="text"
              sx={{
                fontSize: '8px',
                width: '60%',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '4px',
                background: settings.styles?.sidebar?.color,
              }}
              animation={false}
            />
          </Column>
        </Column>
      </Column>
      <Column
        sx={{
          background: '#FFFFFF',
          height: '100%',
          flexGrow: 3,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Column
          sx={{
            width: '50%',
            height: '100%',
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <Skeleton
            variant="text"
            sx={{
              fontSize: '14px',
              width: '40%',
              background: '#E5EAF5',
              alignSelf: 'center',
            }}
            animation={false}
          />
          <Skeleton
            variant="text"
            sx={{
              fontSize: '4px',
              background: '#E5EAF5',
              alignSelf: 'center',
              width: '70%',
            }}
            animation={false}
          />
          <Skeleton
            variant="text"
            sx={{
              fontSize: '4px',
              background: '#E5EAF5',
              alignSelf: 'center',
              width: '65%',
            }}
            animation={false}
          />
          <Skeleton
            variant="text"
            sx={{
              fontSize: '4px',
              background: '#E5EAF5',
              alignSelf: 'center',
              width: '60%',
            }}
            animation={false}
          />
          <Skeleton
            variant="rounded"
            height={40}
            sx={{
              background: '#F7F9FC',
              alignSelf: 'center',
              width: '100%',
              margin: '4px',
            }}
            animation={false}
          />
          <Skeleton
            variant="rounded"
            height={40}
            sx={{
              background: '#F7F9FC',
              alignSelf: 'center',
              width: '100%',
              margin: '4px',
            }}
            animation={false}
          />
          <Skeleton
            variant="rounded"
            height={40}
            sx={{
              background: '#F7F9FC',
              alignSelf: 'center',
              width: '100%',
              margin: '4px',
            }}
            animation={false}
          />
          <Skeleton
            variant="rounded"
            height={40}
            sx={{
              background: '#F7F9FC',
              alignSelf: 'center',
              width: '100%',
              margin: '4px',
            }}
            animation={false}
          />
          <Row>
            <Skeleton
              variant="rounded"
              height={20}
              sx={{
                background: '#E5EAF5',
                alignSelf: 'center',
                width: '80%',
                margin: '4px',
              }}
              animation={false}
            />
            <Skeleton
              variant="rounded"
              height={20}
              sx={{
                background: settings.styles?.buttons?.primary.background,
                alignSelf: 'center',
                width: '80%',
                margin: '4px',
              }}
              animation={false}
            />
          </Row>
          <Row sx={{ alignItems: 'center', gap: '2px', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: '8px' }}>powered by</Typography>
            <SvgIcon sx={{ width: '40px', height: 'auto' }}>
              <CommsorBranding />
            </SvgIcon>
          </Row>
        </Column>
      </Column>
    </Row>
  );
});

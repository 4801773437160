import { Column } from '@caravel/components/src';
import { Card, Divider, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from 'stores';

import { AppToolbar } from '../../app-toolbar';
import { NewSegmentCardHeader } from '../new-segment-card-header';
import { DynamicSegmentForm } from './dynamic-segment-form';
import { SegmentKindSelector } from './segment-kind-selector';
import { StaticSegmentForm } from './static-segment-form';

export const SegmentForm = observer(() => {
  const store = useStore();
  const history = useHistory();
  const theme = useTheme();

  const { segmentId } = useParams<{ segmentId: string }>();
  const isUpdate = !!segmentId;
  const {
    ui: { SegmentForm },
    teams: { segments },
  } = store;

  useEffect(() => {
    if (isUpdate) {
      SegmentForm.editSegment(segmentId);
    }
    return () => SegmentForm.resetFormProps();
  }, [SegmentForm, isUpdate, segmentId]);

  const { formProps } = SegmentForm;

  let segmentKind = '';
  if (formProps?.kind) segmentKind = formProps.kind === ':segment.kind/static' ? 'static' : 'dynamic';

  async function handleSaveSegment() {
    await store.ui.workOn(async () => {
      const success = isUpdate ? await SegmentForm.updateSegment() : await SegmentForm.createSegment();
      if (success) {
        segments.fetchSegments();
        isUpdate ? history.goBack() : history.push('/segments');
      }
    });
  }

  return (
    <Column>
      {segmentKind ? (
        <AppToolbar
          actionButton={{
            disabled: !SegmentForm.isValid || SegmentForm.creating || SegmentForm.updating || !SegmentForm.hasChanges,
            text: 'Save Segment',
            onClick: handleSaveSegment,
          }}
          backButton
          title={
            isUpdate ? `Editing ${formProps?.name}` : `New ${segmentKind === 'static' ? 'Static' : 'Dynamic'} Segment`
          }
        />
      ) : (
        <AppToolbar title="New Segment" backButton />
      )}

      {segmentKind ? (
        <Column sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Card
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              border: 'none',
              width: '65vw',
              marginTop: theme.spacing(8),
            }}
          >
            <NewSegmentCardHeader />

            <Divider />

            {segmentKind === 'static' && <StaticSegmentForm />}
            {segmentKind === 'dynamic' && <DynamicSegmentForm />}
          </Card>
        </Column>
      ) : (
        <SegmentKindSelector />
      )}
    </Column>
  );
});

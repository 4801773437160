import { Flex } from '@caravel/components/src';
import { Filter } from '@caravel/types/src';
import { NEUTRALS } from '@caravel/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { Organization } from 'models/organization';
import React, { useMemo, useState } from 'react';
import { useStore } from 'stores';

interface OrgPeopleFiltersProps {
  organization: Organization;
  numSuperUsers: number;
  numConnections: number;
  numTeamConnections: number;
  numContributors: number;
}

export const OrgPeopleFilters = observer((props: OrgPeopleFiltersProps) => {
  const { organization, numSuperUsers, numConnections, numTeamConnections, numContributors } = props;
  const store = useStore();
  const {
    auth,
    teams: { teamMembers },
  } = store;
  const filterStore = store.teams.filters;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const open = Boolean(anchorEl);
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    filterStore.onChangePeopleFilter(2, filterOptions[index]);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const teamMemberIds = useMemo(() => {
    const teamMem = teamMembers.collection.slice();
    return teamMem.map(mem => mem.id);
  }, [teamMembers.collection]);

  interface OrgPeopleFilter extends Filter {
    count: string | number;
    bottomDivider?: boolean;
    onClick?: () => void;
  }
  const filterOptions: OrgPeopleFilter[] = [
    {
      count: organization.peopleCount,
      type: 'layer',
      graphProperty: '',
      label: 'All People',
      pluralized: '',
      condition: 'is',
      value: [],
      onClick: () => {
        setSelectedIndex(0);
        filterStore.onRemovePeopleFilter(2);
        setAnchorEl(null);
      },
      bottomDivider: true,
    },
    {
      count: numConnections,
      type: 'layer',
      graphProperty: 'connectedTo',
      label: 'Direct Connections',
      pluralized: 'Direct Connections',
      condition: 'any-of',
      value: [auth.user?.uid ?? ''],
    },
    {
      count: numTeamConnections,
      type: 'layer',
      graphProperty: 'connectedTo',
      label: 'Team Connections',
      pluralized: 'Team Connections',
      condition: 'any-of',
      value: teamMemberIds,
    },
    {
      count: organization.memberCount,
      type: 'layer',
      graphProperty: 'position',
      label: 'Community Members',
      pluralized: 'Community Members',
      condition: 'is',
      value: ['member'],
    },
    {
      count: organization.activeMemberCount,
      type: 'grid-status',
      graphProperty: 'gridStatus',
      label: 'Active Members',
      pluralized: 'Active Members',
      condition: 'not-any-of',
      value: ['Inactive', 'none'],
    },
    {
      count: numSuperUsers,
      type: 'grid-status',
      graphProperty: 'gridStatus',
      label: 'Super Users',
      pluralized: 'Super Users',
      condition: 'any-of',
      value: ['superuser', 'superfan'],
    },
    {
      count: numContributors,
      type: 'grid-status',
      graphProperty: 'gridStatus',
      label: 'Contributors',
      pluralized: 'Contributors',
      condition: 'is',
      value: ['contributor'],
    },
    {
      count: organization.memberCount - organization.activeMemberCount,
      type: 'grid-status',
      graphProperty: 'gridStatus',
      label: 'Inactive',
      pluralized: 'Inactives',
      condition: 'is',
      value: ['inactive'],
    },
    // { label: 'High Sentiment People'},
    // { label: 'Low Sentiment People'},
  ];

  return (
    <Flex>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableRipple
        variant="contained"
        onClick={handleButtonClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        sx={{
          backgroundColor: 'white',
          color: NEUTRALS.ESPRESSO,
          fontSize: '13px',
          fontWeight: 400,
          lineHeight: 1,
          padding: '6px 8px',
          whiteSpace: 'nowrap',
          '&:hover': { backgroundColor: 'white' },
        }}
      >
        {filterOptions[selectedIndex].label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPaper-root': {
            border: 'none',
            boxShadow:
              '0px 4px 14px -10px rgba(0, 0, 0, 0.07), 0px 10px 18px rgba(0, 0, 0, 0.04), 0px 30px 42px rgba(0, 0, 0, 0.12)',
            padding: 0,
          },
          '& .MuiList-root': {
            padding: 0,
          },
          '& .MuiMenuItem-divider': {
            borderColor: NEUTRALS.JASMINE,
          },
        }}
      >
        {filterOptions.map((option, i) => {
          return (
            <MenuItem
              key={`org-people-option-${i}`}
              onClick={evt => (option.onClick ? option.onClick() : handleClick(evt, i))}
              sx={{ padding: '8px' }}
              divider={option.bottomDivider}
            >
              <Flex justifyContent="space-between" columnGap="16px" width="100%">
                <Typography variant="bodySmaller" color={NEUTRALS.ESPRESSO}>
                  {option.label}
                </Typography>
                <Typography variant="bodySmaller" color={NEUTRALS.EARL_GREY}>
                  {option.count}
                </Typography>
              </Flex>
            </MenuItem>
          );
        })}
      </Menu>
    </Flex>
  );
});

import { gql } from '@apollo/client';
import { SegmentKind } from '@caravel/types';

import { SegmentRule } from './create-segment.gql';

export const UPDATE_SEGMENT = gql`
  mutation UpdateSegment($input: UpsertSegmentInput!) {
    upsertSegment(input: $input) {
      result {
        id
      }
    }
  }
`;

export interface GqlUpdateSegmentRequestType {
  input: {
    id?: string;
    name?: string;
    description?: string;
    segmentKind?: SegmentKind;
    memberIds: (string | undefined)[] | undefined;
    segmentRules: SegmentRule[] | undefined;
  };
}

export interface GqlUpdateSegmentResponseType {
  upsertSegment: {
    result: {
      id: string;
    };
  };
}

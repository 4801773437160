import { Column } from '@caravel/components';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { isEmptyString, isValidEmail, isValidPassword } from '@caravel/utils';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Button, IconButton, InputAdornment, Link, Typography } from '@mui/material';
import React, { useState } from 'react';

interface SignUpEmailProps {
  onSignUpWithEmail: (email: string) => void;
  onSignUp: (email: string, password: string) => void;
}

export const SignUpEmail = (props: SignUpEmailProps) => {
  const { onSignUpWithEmail, onSignUp } = props;
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordMode, setPasswordMode] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const onClickShowPassword = () => setShowPassword(show => !show);

  const onMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSignUpWithEmail = async () => {
    setSubmitting(true);
    if (isEmptyString(email)) {
      return;
    }
    await onSignUpWithEmail(email!);
    setSubmitting(false);
  };

  const handleSignUp = async () => {
    setSubmitting(true);
    if (isEmptyString(email)) {
      return;
    }
    await onSignUp(email!, password!);
    setSubmitting(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (passwordMode) {
      handleSignUp();
    } else {
      handleSignUpWithEmail();
    }
  };

  return (
    <Column alignItems="stretch" width="100%" maxWidth="360px">
      <Typography variant="headline1" component="h1" marginBottom="50px" textAlign="center">
        Sign up to Commsor
      </Typography>
      <form onSubmit={handleSubmit}>
        <Column gap="20px">
          <FormInput label="Work Email Address" placeholder="Enter Email" required fullWidth onChange={onChangeEmail} />

          {!passwordMode && (
            <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={!isValidEmail(email) || submitting}
                onClick={handleSubmit}
              >
                Send Secure Link
              </Button>
              <Typography variant="bodySmallest" textAlign="center">
                We&rsquo;ll send a secure link to your email to sign in.
                <br />
                <Link component="button" onClick={() => setPasswordMode(true)}>
                  Use a password instead.
                </Link>
              </Typography>
            </>
          )}

          {passwordMode && (
            <>
              <FormInput
                label="Password"
                placeholder="Enter Password"
                description="Use a minimum of 8 alphanumeric characters"
                required
                fullWidth
                onChange={onChangePassword}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onClickShowPassword}
                        onMouseDown={onMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={!isValidEmail(email) || !isValidPassword(password) || submitting}
                onClick={handleSignUp}
              >
                Sign up
              </Button>
            </>
          )}
        </Column>
      </form>
    </Column>
  );
};

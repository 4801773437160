import { gql } from '@apollo/client';
import { Person, ProofSourceType, ProofTag } from '@caravel/types';

export const GET_PROOF_COLLECTION = gql`
  query getProofs {
    community {
      proof {
        id
        content
        champion {
          id
          familyName
          givenName
          email
          avatar
          employmentTitle
          primaryOrganization {
            id
            name
            domain
          }
          organizations {
            id
            name
            domain
          }
        }
        dateCreated
        sourceType
        sourceLink
        customerName
        dateCreated
        employmentTitle
        organizationName
        proofTags {
          community
          name
        }
      }
    }
  }
`;

export interface GqlProofCollectionResponseType {
  community: {
    proof: {
      id: string;
      customerName?: string; // In case this customer does not exist as a champion, yet just display a name
      sourceType?: ProofSourceType;
      sourceLink?: string;
      content?: string;
      dateCreated?: string;
      champion?: Partial<Person>;
      employmentTitle?: string;
      organizationName?: string;
      proofTags?: ProofTag[];
    }[];
  };
}

import {
  CaravelSpinner,
  Column,
  ConnectionsTableCell,
  CustomerFitChip,
  Flex,
  KebabMenuOption,
  SortableTableHead,
} from '@caravel/components/src';
import {
  Filter,
  SegmentInterConditionOperator,
  SegmentInterRuleOperator,
  SegmentRule,
  SegmentRuleCondition,
} from '@caravel/types/src';
import { GREY_PALETTE } from '@caravel/utils';
import { Avatar, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { formatDistance } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-cool-inview';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { AppToolbar } from '../app-toolbar';
import { MergePeopleModal } from './merge-people-modal';
import { PeopleFilters } from './people-filters';
import { AddPerson, CreateDynamicSegment, DefaultOrganizationIcon, ExportPeople, ImportPeople } from './people-icons';
import { PeopleSelectionBar } from './people-selection-bar';

// Matches to filter param or defaults to no filter
export const getFilter = (filter: string): Filter | undefined => {
  switch (filter) {
    case 'super-user':
      return {
        type: 'grid-status',
        graphProperty: 'gridStatus',
        label: 'Grid Status',
        pluralized: 'statuses',
        condition: 'is',
        value: ['Super User'],
      };
    case 'observer':
      return {
        type: 'grid-status',
        graphProperty: 'gridStatus',
        label: 'Grid Status',
        pluralized: 'statuses',
        condition: 'is',
        value: ['Observer'],
      };
    case 'inactive':
      return {
        type: 'grid-status',
        graphProperty: 'gridStatus',
        label: 'Grid Status',
        pluralized: 'statuses',
        condition: 'is',
        value: ['Inactive'],
      };
    case 'neutral':
      return {
        type: 'grid-status',
        graphProperty: 'gridStatus',
        label: 'Grid Status',
        pluralized: 'statuses',
        condition: 'is',
        value: ['Neutral'],
      };
    case 'regular':
      return {
        type: 'grid-status',
        graphProperty: 'gridStatus',
        label: 'Grid Status',
        pluralized: 'statuses',
        condition: 'is',
        value: ['Regular'],
      };
    case 'potential':
      return {
        type: 'grid-status',
        graphProperty: 'gridStatus',
        label: 'Grid Status',
        pluralized: 'statuses',
        condition: 'is',
        value: ['Potential'],
      };
    case 'contributor':
      return {
        type: 'grid-status',
        graphProperty: 'gridStatus',
        label: 'Grid Status',
        pluralized: 'statuses',
        condition: 'is',
        value: ['Contributor'],
      };
    case 'member':
      return {
        type: 'layer',
        graphProperty: 'position',
        label: 'Layer',
        pluralized: 'layers',
        condition: 'is',
        value: ['Member'],
      };
    default:
      return undefined;
  }
};

export const truncateText = (text: string, limit: number) => {
  if (text.length > limit) {
    return text.slice(0, limit).concat('...');
  }
  return text;
};

export interface PeopleListProps {
  filter?: string;
}

export const PeopleList = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { filter } = useParams<{ filter: string }>();
  const [currentFilter, setCurrentFilter] = React.useState<string | undefined>(undefined);
  const loadFilter = !(filter === currentFilter);
  const {
    ui,
    teams: { people, teamMembers },
  } = store;
  const filterStore = store.teams.filters;

  const canLoad = store.team && (!people.pageInfo || people.pageInfo?.hasNextPage) && !people.loading;
  const community = people.collection.slice();

  const handleRequestSort = (id: string) => {
    filterStore.onChangePeopleOrder(id);
    loadMore();
  };

  const handleCreateDynamicSegment = () => {
    const segmentRules: SegmentRule[] = filterStore.peopleFilters.slice(1).map(filter => {
      return {
        interConditionOperator: 'all' as SegmentInterConditionOperator,
        interRuleOperator: 'and' as SegmentInterRuleOperator,
        conditions: [
          {
            field: filter.type,
            operator: convertFilterConditionToOperator(filter),
            values: filter.value,
          },
        ] as SegmentRuleCondition[],
      };
    });
    ui.SegmentForm.setSegment({
      name: '',
      description: '',
      kind: ':segment.kind/dynamic',
      segmentRules,
    });
    history.push('/segments/new');
  };

  const loadMore = useCallback(() => {
    if (!canLoad && !loadFilter) return;
    ui.workOn(async () => {
      // Check to see if we need to load filters
      if (loadFilter) {
        const currentFilter = getFilter(filter ?? '');
        if (currentFilter) {
          await filterStore.forceAddPeopleFilter(currentFilter);
        } else {
          await filterStore.clearPeopleFilters();
          await people.refreshCommunity();
        }
      } else {
        await people.fetchCommunity();
      }
    });
    setCurrentFilter(filter);
  }, [canLoad, filter, filterStore, loadFilter, people, ui]);

  const { observe } = useInView({
    onEnter: () => {
      loadMore();
    },
  });

  useEffect(() => {
    if (!community.length || loadFilter) {
      loadMore();
    }
  }, [community.length, loadFilter, loadMore]);

  const menuOptions: KebabMenuOption[] = [
    {
      enabled: true,
      divider: false,
      icon: <ExportPeople style={{ width: '20px', height: '20px' }} />,
      label: 'Export people',
      onClick: async () => {
        store.ui.workOn(() => people.exportPeople());
        console.log('Export people clicked');
      },
    },
    {
      enabled: false,
      divider: true,
      icon: <ImportPeople style={{ width: '20px', height: '20px' }} />,
      label: 'Import people from CSV',
      onClick: () => {
        console.log('Import people from CSV clicked');
      },
    },
    {
      enabled: false,
      icon: <AddPerson style={{ width: '20px', height: '20px' }} />,
      label: 'Add a person',
      onClick: () => {
        console.log('Add a person clicked');
      },
    },
  ];

  const hasFilters =
    filterStore.peopleFacets.length > 0 &&
    filterStore.peopleFacets.some(facet => facet.type && facet.name && facet.values.length > 0);
  return (
    <>
      <MergePeopleModal />
      <Column>
        <AppToolbar
          title="People"
          subtitle={
            !people.loading
              ? `${
                  people.totalHits !== people.communitySize ? `${people.totalHits.toLocaleString()} of ` : ''
                }${people.communitySize.toLocaleString()}`
              : ''
          }
          kebabMenuOptions={menuOptions}
          actionButton={
            hasFilters
              ? {
                  startIcon: <CreateDynamicSegment />,
                  onClick: handleCreateDynamicSegment,
                  text: 'Create Dynamic Segment',
                }
              : undefined
          }
        />
        <PeopleFilters />
        <PeopleSelectionBar />
        <TableContainer>
          <Table size="small">
            <SortableTableHead
              checkbox
              cells={[
                {
                  id: 'name',
                  label: 'Name',
                  disablePadding: true,
                  minWidth: '200px',
                  width: '280px',
                  maxWidth: '280px',
                },
                {
                  id: 'organization',
                  label: 'Organization',
                  minWidth: '100px',
                  width: `calc(100%/${6})`,
                  maxWidth: '280px',
                },
                {
                  id: 'employmentTitle',
                  label: 'Title',
                  minWidth: '200px',
                  width: `calc(100%/${5})`,
                  maxWidth: '400px',
                },
                {
                  id: 'customerFit',
                  label: 'Customer Fit',
                  width: `calc(100%/${6})`,
                  maxWidth: '300px',
                },
                {
                  id: 'connectedTo',
                  label: 'Connections',
                  width: `calc(100%/${6})`,
                  maxWidth: '300px',
                  disabledSort: true,
                },
                {
                  id: 'lastActive',
                  label: 'Last Active',
                  minWidth: '140px',
                  width: `calc(100%/${6})`,
                  maxWidth: '300px',
                },
              ]}
              order={filterStore.peopleOrder}
              orderBy={filterStore.peopleOrderBy}
              onSort={handleRequestSort}
              numSelected={people.numSelected}
              rowCount={people.collection.length}
              onSelectAllClick={() => {
                people.toggleAllSelected();
              }}
            />
            <TableBody>
              {community.map(row => (
                <TableRow key={row.id} sx={{ minHeight: '50px', height: '100%' }}>
                  <TableCell sx={{ padding: '0 0 0 18px', width: '20px' }}>
                    <Checkbox
                      color="primary"
                      checked={row.selected ?? false}
                      size="small"
                      onChange={() => {
                        people.toggleSelected(row.id!);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={{
                      tableLayout: 'fixed',
                      padding: '10px 0',
                    }}
                  >
                    <RouterLink
                      to={`/people/${row.id}`}
                      color="textPrimary"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      title={row.name}
                    >
                      <Avatar
                        src={row.avatar}
                        alt={row.name}
                        sx={{
                          width: 30,
                          height: 30,
                          marginRight: '10px',
                          fontSize: '12px',
                        }}
                      >
                        {row.initials}
                      </Avatar>
                      <Typography variant="headline4">{truncateText(row.name ?? '', 20)}</Typography>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    {row.organization ? (
                      <RouterLink
                        to={`/organizations/${row.organization.id}`}
                        color="textPrimary"
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                        title={row.organization.name}
                      >
                        <Avatar
                          src={row.organizationIcon}
                          alt={row.organization?.name}
                          sx={{
                            width: 30,
                            height: 30,
                            marginRight: '10px',
                            fontSize: '12px',
                          }}
                        >
                          <DefaultOrganizationIcon />
                        </Avatar>
                        <Typography variant="headline4">
                          {truncateText(row.organization && row.organization.name ? row.organization.name : '', 20)}
                        </Typography>
                      </RouterLink>
                    ) : (
                      <Typography variant="headline4">---</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <RouterLink to={`/people/${row.id}`} color="textPrimary" title={row.employmentTitle}>
                      <Typography variant="headline4" sx={{ wordBreak: 'break-word' }}>
                        {truncateText(row.employmentTitle || '---', 80)}
                      </Typography>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <CustomerFitChip customerFit={row.customerFit} />
                  </TableCell>
                  <TableCell>
                    <ConnectionsTableCell
                      userId={store.auth.user!.uid}
                      person={row}
                      teamConnections={teamMembers.getTeamConnections(row.connectedTo)}
                      handleAskIntroduction={store.teams.people.askForIntroduction}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="headline4" sx={{ color: GREY_PALETTE[6] }}>
                      {row.lastSeen ? formatDistance(new Date(row.lastSeen ?? new Date()), new Date()) + ' ago' : '---'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Column sx={{ padding: '28px' }}>
          {!people.loading && <Column ref={observe} />}
          {(people.loading || people.pageInfo?.hasNextPage) && <CaravelSpinner />}
          {people.pageInfo && !people.pageInfo.hasNextPage && <Typography>No more people</Typography>}
          <Flex flex={1} sx={{ paddingTop: '20px', width: '100%' }} />
        </Column>
      </Column>
    </>
  );
});

const convertFilterConditionToOperator = ({ condition, type }: Filter) => {
  if (type === 'source') {
    if (condition === 'is' || condition === 'any-of') return 'includes-any-of';
    if (condition === 'not' || condition === 'not-any-of') {
      return 'includes-none-of';
    }
  }
  return condition;
};

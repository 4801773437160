import { Column, Flex } from '@caravel/components';
import illustrationJson from '@caravel/web/src/assets/lottie/sign-up-page.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as CommsorDino } from '../../assets/svgs/commsor-dino-large.svg';

interface OnboardingWrapperProps {
  children: React.ReactNode;
}

export const OnboardingWrapper = ({ children }: OnboardingWrapperProps) => {
  return (
    <Flex
      sx={{
        backgroundColor: 'white',
        height: '100vh',

        overflowY: 'auto',
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <Column
            justifyContent="space-between"
            alignItems="center"
            minHeight="100vh"
            height="100%"
            paddingY="24px"
            gap="24px"
          >
            <Box>
              <CommsorDino style={{ width: '38px', marginTop: '50px' }} />
            </Box>

            {children}

            <Typography variant="bodySmallest">
              Need help? Contact{' '}
              <Link href="mailto:support@commsor.com" rel="noopener noreferrer" target="_blank">
                support@commsor.com
              </Link>
            </Typography>
          </Column>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Box padding="24px">
            <Flex
              width="100%"
              height="100%"
              borderRadius="16px"
              overflow="hidden"
              sx={{
                '&  > *': {
                  width: '100%',
                  height: 'calc(100vh - 48px)',
                },
              }}
            >
              <Player
                autoplay
                loop
                src={illustrationJson}
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice', id: 'jared' }}
                style={{ height: '100%' }}
              />
            </Flex>
          </Box>
        </Grid>
      </Grid>
    </Flex>
  );
};

import { INFO_PALETTE } from '@caravel/utils';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Row } from './flex';

interface HelperBarProps {
  icon?: React.ReactNode;
  text: string;
  classes?: any;
  className?: string;
}

const HelperBarUnstyled: React.FC<HelperBarProps> = ({ icon, text, className }) => {
  return (
    <Row
      sx={{
        width: 'full',
        gap: '10px',
        border: `2px solid ${INFO_PALETTE[300]}`,
        backgroundColor: INFO_PALETTE[50],
        color: INFO_PALETTE[300],
        padding: '10px',
        borderRadius: '5px',
        alignItems: 'center',
      }}
      className={className}
    >
      {icon}
      <Typography color={'inherit'} variant="headline4" sx={{ fontSize: '13px' }}>
        {text}
      </Typography>
    </Row>
  );
};

export const HelperBar = styled(HelperBarUnstyled)({});

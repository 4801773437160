import { gql } from '@apollo/client';

export const DELETE_SEGMENT = gql`
  mutation DeleteSegment($id: String!) {
    deleteSegment(input: { id: $id }) {
      result {
        id
      }
    }
  }
`;

export interface GqlDeleteSegmentResponseType {
  deleteSegment: {
    result: {
      id: string;
    };
  };
}

import { Column } from '@caravel/components/src';
import { Activity } from '@caravel/types/src/activities';
import { GREY_PALETTE, NEUTRALS, PRIMARY_PALETTE, SUCCESS_PALETTE, WARNING_PALETTE } from '@caravel/utils';
import { Box, Grid, Typography } from '@mui/material';
import { Serie } from '@nivo/line';
import { format, formatISO, startOfDay, sub } from 'date-fns';
import { observer } from 'mobx-react';
import { Person } from 'models/person';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'stores';

import { PlainLineGraph } from '../../home/graphs/plain-line-graph';

const getActivityBetweenDates = (start: Date, end: Date, activities: { id: string; occurredAt: Date }[]) => {
  const activityInLast30: { date: Date; total: number }[] = [];
  const currentDate = new Date(start);
  while (currentDate <= end) {
    const tempDate = new Date(currentDate);
    activityInLast30.push({
      date: tempDate,
      total: 0,
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }

  activityInLast30.forEach(a => {
    const totals = activities.reduce(
      (t, e) =>
        e.occurredAt.getFullYear() === a.date.getFullYear() &&
        e.occurredAt.getMonth() === a.date.getMonth() &&
        e.occurredAt.getDate() === a.date.getDate()
          ? t + 1
          : t,
      0,
    );
    a.total = totals;
  });

  return activityInLast30;
};

const formatEngagement = (eng: string) => {
  let text = '--';
  if (eng.length > 0) {
    text = `${eng.charAt(0).toUpperCase()}${eng.slice(1)}`;
  }
  let color = GREY_PALETTE[4];
  switch (eng) {
    case 'none':
      color = GREY_PALETTE[4];
      break;
    case 'medium':
      color = WARNING_PALETTE[200];
      break;
    case 'high':
      color = SUCCESS_PALETTE[200];
      break;
    default:
      color = GREY_PALETTE[2];
      break;
  }

  return (
    <Typography variant="bodySmallest" color={color}>
      {text}
    </Typography>
  );
};

export const PersonStats = observer((props: { person: Person }) => {
  const store = useStore();
  const {
    ui,
    teams: { people },
  } = store;
  const { person } = props;
  const engagement = person.engagement;
  const canLoad =
    (!people.activityCountsPageInfo || people.activityCountsPageInfo?.hasNextPage) && !people.activityCountsLoading;
  const activities: Activity[] = people.activityCounts.slice();

  const endDate = useMemo(() => {
    return startOfDay(new Date());
  }, []);

  const startDate = useMemo(() => {
    return startOfDay(sub(new Date(), { days: 30 }));
  }, []);

  const filter = useMemo<{ start: string; end: string }>(() => {
    return { start: formatISO(startDate), end: formatISO(endDate) };
  }, [startDate, endDate]);

  const dailyActivities: { id: string; occurredAt: Date }[] = activities.map(activity => {
    return {
      id: activity.id,
      occurredAt: new Date(activity.occurredAt),
    };
  });

  const activityInLast30 = useMemo(() => {
    return getActivityBetweenDates(startDate, endDate, dailyActivities);
  }, [dailyActivities, endDate, startDate]);

  const max = Math.max(...activityInLast30.map(a => a.total));
  const maxBuffer = max * 0.2;
  const data: Serie[] = useMemo(() => {
    return [
      {
        id: 'Activities',
        color: PRIMARY_PALETTE[200],
        data: activityInLast30.map(a => ({
          x: a.date.toISOString(),
          y: a.total,
        })),
      },
    ];
  }, [activityInLast30]);

  const loadMore = useCallback(() => {
    if (!canLoad) return;
    ui.workOn(async () => {
      await people.fetchActivityCounts(person.id!, people.activitiesPageInfo?.endCursor, 2000, filter);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoad, ui, filter]);

  useEffect(() => {
    if (canLoad) {
      loadMore();
    }
  }, [activities.length, canLoad, loadMore]);

  return (
    <Column sx={{ width: '100%', padding: '20px 24px' }}>
      <Typography variant="headline4" component="h4" marginTop="16px" marginBottom="8px">
        Engagement
      </Typography>
      <Grid container sx={{ justifyContent: 'center', width: '100%' }}>
        <Grid item xs={4}>
          <Typography variant="bodySmallest" color={NEUTRALS.OOLONG} lineHeight={1} component="p">
            Frequency
          </Typography>
          {engagement ? formatEngagement(engagement!.frequency) : formatEngagement('--')}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="bodySmallest" color={NEUTRALS.OOLONG} lineHeight={1} component="p">
            Consistency
          </Typography>
          {engagement ? formatEngagement(engagement!.consistency) : formatEngagement('--')}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="bodySmallest" color={NEUTRALS.OOLONG} lineHeight={1} component="p">
            Impact
          </Typography>
          {engagement ? formatEngagement(engagement!.impact) : formatEngagement('--')}
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '26px' }}>
          <Box borderBottom={`1px solid ${NEUTRALS.JASMINE}`} height="100px" paddingTop="4px">
            <PlainLineGraph data={data} max={max} maxBuffer={maxBuffer} enableGridY={false} />
          </Box>
          <Grid item container xs={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Typography variant="microcopy">{format(startDate, 'MMM d')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="microcopy" sx={{ textAlign: 'right' }}>
                {format(endDate, 'MMM d')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Column>
  );
});

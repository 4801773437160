import { PRIMARY_PALETTE } from '@caravel/utils';
import { Button, ButtonProps, Snackbar, SnackbarContent, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DASHBOARD_MENU_WIDTH } from 'components/app-navigation/app-navigation';
import React from 'react';
export interface SelectionBarProps {
  selectionBarActions: JSX.Element;
  snackbarMessage: JSX.Element;
  open: boolean;
}

export const OutlinedSelectionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.grey[0],
  borderColor: theme.grey[2],
  backgroundColor: theme.palette.transparent.main,
  '&:hover': {
    borderColor: theme.grey[0],
    backgroundColor: theme.palette.transparent.main,
  },
}));

export const PrimaryContainedSelectionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.grey[0],
  '&.MuiButton-root': {
    '&.Mui-disabled': {
      color: theme.grey[4],
      backgroundColor: theme.grey[3],
      svg: {
        '& path': {
          stroke: theme.grey[4],
        },
      },
    },
    svg: {
      '& path': {
        stroke: theme.grey[0],
      },
    },
  },
}));

export const SecondaryContainedSelectionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.grey[1],
  '&:hover': {
    color: theme.grey[5],
    backgroundColor: theme.grey[3],
  },
  '&.MuiButton-root': {
    '&.Mui-disabled': {
      color: theme.grey[4],
      backgroundColor: theme.grey[3],
      svg: {
        '& path': {
          stroke: theme.grey[4],
        },
      },
    },
    svg: {
      '& path': {
        stroke: PRIMARY_PALETTE[200],
      },
    },
  },
}));

export const SelectionBar: React.FC<SelectionBarProps> = ({ selectionBarActions, snackbarMessage, open }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ zIndex: 1300, paddingLeft: `${DASHBOARD_MENU_WIDTH}px` }}
    >
      <Stack>
        <SnackbarContent
          sx={{
            minWidth: '800px',
            width: '70vw',
            backgroundColor: PRIMARY_PALETTE[400],
            '& .MuiSnackbarContent-message': { padding: 0 },
          }}
          message={snackbarMessage}
          action={selectionBarActions}
        />
      </Stack>
    </Snackbar>
  );
};

import { GOSSIPY_GOLDEN, LONG_WINDED_LILAC, SILENT_BLUE_SKY } from '@caravel/utils/src/constants/colors';
import { CircularProgress, CircularProgressProps, SxProps } from '@mui/material';
import React from 'react';

import { Flex } from '../flex';

export function CaravelSpinner(props: CircularProgressProps & { sx?: SxProps }) {
  const { sx } = props;
  const { size = 20, thickness = 6 } = props;

  return (
    <Flex sx={sx} style={{ position: 'relative' }}>
      <svg width="48" height="48" style={{ position: 'absolute' }}>
        <linearGradient id="bottomColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor={LONG_WINDED_LILAC} />
          <stop offset="100%" stopColor={GOSSIPY_GOLDEN} />
        </linearGradient>
      </svg>
      <svg width="48" height="48" style={{ position: 'absolute' }}>
        <linearGradient id="topColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor={SILENT_BLUE_SKY} />
          <stop offset="100%" stopColor={LONG_WINDED_LILAC} />
        </linearGradient>
      </svg>
      <CircularProgress
        value={100}
        variant="determinate"
        sx={{
          opacity: 0.5,
          animation: '$spin 1s linear infinite',
          animationTimingFunction: 'ease-in-out',
          '@keyframes spin': {
            '0%': {
              transform: 'rotateZ(0deg)',
            },
            '100%': {
              transform: 'rotateZ(360deg)',
            },
          },
          '& .MuiCircularProgress-circle': {
            stroke: 'url(#bottomColors)',
            strokeLinecap: 'round',
          },
        }}
        size={size}
        thickness={thickness}
      />
      <CircularProgress
        sx={{
          position: 'absolute',
          '& .MuiCircularProgress-circle': {
            stroke: 'url(#topColors)',
            strokeLinecap: 'round',
            animationDuration: '2s',
          },
          '& .MuiCircularProgress-circleIndeterminate': {
            animationDuration: '3s',
          },
        }}
        size={size}
        thickness={thickness}
      />
    </Flex>
  );
}

import { TeamConnection } from '@caravel/types/src';
import { LAVENDER, makeDateString, makeInitials } from '@caravel/utils/src';
import { Avatar, Button, Card, CardActions, CardContent, Popover, Typography, useTheme } from '@mui/material';
import { formatDistance, fromUnixTime } from 'date-fns';
import React, { forwardRef } from 'react';

import { Column, Row } from './flex';
import { useTeamConnections } from './hooks/use-team-connections';

type Origin = { horizontal: 'center' | 'left' | 'right' | number; vertical: 'bottom' | 'center' | 'top' | number };

interface ConnectionPopoverProps {
  userId: string;
  teamConnections: TeamConnection[];
  open: boolean;
  anchorEl: HTMLElement | null;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  anchorOrigin?: Origin;
  transformOrigin?: Origin;
  currentConnection: TeamConnection;
  setCurrentConnection: (connection: TeamConnection) => void;
  handleOpenIntroductionModal: () => void;
}

export const ConnectionPopover = forwardRef<HTMLDivElement, ConnectionPopoverProps>((props, ref) => {
  const theme = useTheme();

  const {
    open,
    anchorEl,
    onMouseEnter,
    onMouseLeave,
    teamConnections,
    anchorOrigin,
    transformOrigin,
    userId,
    currentConnection,
    setCurrentConnection,
    handleOpenIntroductionModal,
  } = props;

  const { teamConnectionsContainsSelf } = useTeamConnections(teamConnections, userId);

  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={transformOrigin || { vertical: 'top', horizontal: 'left' }}
      onClose={onMouseLeave}
      TransitionProps={{ onExited: () => setCurrentConnection(teamConnections[0]) }}
      PaperProps={{ onMouseLeave, onMouseEnter, sx: { pointerEvents: 'auto' } }}
    >
      <Card sx={{ width: '240px' }}>
        <CardContent sx={{ padding: '12px' }}>
          <Row paddingBottom={theme.spacing(1)}>
            {teamConnections.map((connection, index) => {
              if (index > 5) return null;
              const isOverflow = index === 5 && teamConnections.length > 6;
              const remainingConnections = teamConnections.length - 5;
              return (
                <Avatar
                  key={index}
                  src={isOverflow ? '' : connection.photoUrl}
                  alt={isOverflow ? '' : connection.name}
                  onClick={() => {
                    if (isOverflow) console.log('open person detail page');
                    else setCurrentConnection(connection);
                  }}
                  sx={{
                    width: '32px',
                    height: '32px',
                    cursor: 'pointer',
                    opacity: currentConnection.id === connection.id ? 1 : 0.5,
                    border: `2px solid ${currentConnection.id === connection.id ? LAVENDER[200] : 'white'}`,
                    boxSizing: 'content-box',
                  }}
                >
                  {isOverflow ? `+${remainingConnections}` : makeInitials({ name: connection.name })}
                </Avatar>
              );
            })}
          </Row>
          <Row paddingBottom={theme.spacing(1)}>
            <Typography variant="headline4">{currentConnection.name}</Typography>
          </Row>

          <Row justifyContent="space-between" paddingBottom="2px">
            <Column>
              <Typography variant="bodySmallest">Connected since</Typography>
            </Column>
            <Column>
              <Typography variant="bodySmallest">
                {makeDateString(fromUnixTime(currentConnection.connectedSince || 0))}
              </Typography>
            </Column>
          </Row>
          <Row justifyContent="space-between" paddingBottom="2px">
            <Column>
              <Typography variant="bodySmallest">Total meetings</Typography>
            </Column>
            <Column>
              <Typography variant="bodySmallest">{currentConnection.totalMeetings || 0}</Typography>
            </Column>
          </Row>
          <Row justifyContent="space-between" paddingBottom="2px">
            <Column>
              <Typography variant="bodySmallest">Last exchange</Typography>
            </Column>
            <Column>
              <Typography variant="bodySmallest">
                {currentConnection.lastExchange
                  ? formatDistance(fromUnixTime(currentConnection.lastExchange), new Date()) + ' ago'
                  : '---'}
              </Typography>
            </Column>
          </Row>
        </CardContent>
        {!teamConnectionsContainsSelf && (
          <CardActions sx={{ padding: '0 12px 12px 12px' }}>
            <Button
              onClick={handleOpenIntroductionModal}
              variant="contained"
              fullWidth
              disabled={teamConnectionsContainsSelf}
            >
              Get introduced
              {teamConnections.length > 1 ? '' : ` by ${currentConnection.name.split(' ')[0]}`}
            </Button>
          </CardActions>
        )}
      </Card>
    </Popover>
  );
});

ConnectionPopover.displayName = 'ConnectionPopover';

import { Flex } from '@caravel/components/src';
import { useTheme } from '@mui/material';
import { DASHBOARD_MENU_WIDTH } from 'components/app-navigation/app-navigation';
import { DarkToast } from 'components/notifications/dark-toast';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

export const DarkSnackbar = observer(() => {
  const store = useStore();
  const notificationIds = store.teams.notifications.collection.slice().map(n => n.id);
  const theme = useTheme();

  return (
    <Flex
      sx={{
        position: 'fixed',
        bottom: 12,
        left: 12 + DASHBOARD_MENU_WIDTH,
        zIndex: theme.zIndex.snackbar,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(1),
        transition: theme.transitions.create('left', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      {notificationIds.map(id => {
        return <DarkToast key={id} notificationId={id} />;
      })}
    </Flex>
  );
});

import { Column, WideColumn } from '@caravel/components/src';
import { FormSectionHeader } from '@caravel/components/src/forms/form-section-header';
import { IconTile, IconTileProps } from '@caravel/components/src/icon-tile';
import { ChannelKind } from '@caravel/types/src';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { ChannelDescriptions, ChannelIcons } from '.';

export const IntegrationsList: FC = observer(() => {
  const store = useStore();
  const integrations = store.teams.integrations.collection.slice();
  const history = useHistory();

  const tileProps: IconTileProps[] = integrations
    .filter(integration => {
      if (integration.name === 'Email' && !store.features.hasNylas) {
        return false;
      }
      return true;
    })
    .map(integration => {
      const integrationType = `:${integration.name.toLowerCase()}`;
      let name = integration.name;
      if (integration.name === 'Email') {
        name = 'Google';
      }
      return {
        icon: ChannelIcons[integrationType as ChannelKind],
        name,
        description: ChannelDescriptions[integrationType as ChannelKind],
        actionHandler: () => {
          history.push(`/settings/integrations/${name.toLowerCase()}`);
        },
      };
    });

  return (
    <Column>
      <WideColumn sx={{ padding: '24px', alignSelf: 'center' }}>
        <FormSectionHeader title="Integrations" subtitle="Connect and manage your integrations" divider />
        <Grid container spacing={2} sx={{ flexBasis: 0, marginTop: theme => theme.spacing(1) }}>
          {tileProps.map(t => (
            <IconTile {...t} key={t.name} />
          ))}
        </Grid>
      </WideColumn>
    </Column>
  );
});

export default IntegrationsList;

import RightArrow from '@mui/icons-material/East';
import { SvgIcon } from '@mui/material';
import React from 'react';

import { ReactComponent as CloseDrawer } from '../../assets/svgs/close-drawer.svg';
import { ReactComponent as FilterIcon } from '../../assets/svgs/filter.svg';
import { ReactComponent as SearchIcon } from '../../assets/svgs/search.svg';
import { ReactComponent as SettingsIcon } from '../../assets/svgs/settings.svg';
import { ReactComponent as AddChampion } from '../../assets/svgs/users-plus.svg';

function InfoIcon() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 10.8333V12.4999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4168 7.49992C10.4168 7.73004 10.2303 7.91658 10.0002 7.91658C9.77004 7.91658 9.5835 7.73004 9.5835 7.49992C9.5835 7.2698 9.77004 7.08325 10.0002 7.08325C10.2303 7.08325 10.4168 7.2698 10.4168 7.49992Z"
        stroke="currentColor"
      />
      <path
        d="M16.0418 9.99992C16.0418 13.3366 13.3369 16.0416 10.0002 16.0416C6.66344 16.0416 3.9585 13.3366 3.9585 9.99992C3.9585 6.6632 6.66344 3.95825 10.0002 3.95825C13.3369 3.95825 16.0418 6.6632 16.0418 9.99992Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function DeleteIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 448 512">
      <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
    </SvgIcon>
  );
}

export { AddChampion, CloseDrawer, DeleteIcon, FilterIcon, RightArrow, SearchIcon, SettingsIcon, InfoIcon };

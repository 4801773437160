import { Column, Row } from '@caravel/components/src';
import { CHARCOAL_BLACK, COMMSOR_PURPLE, MEDIUM_GREY, NEUTRALS } from '@caravel/utils/src';
import { Avatar, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'stores';

import { AppToolbar } from '../app-toolbar';
import { RouterLink } from '../router-link';

export const ComposerDetail: FC = observer(() => {
  const store = useStore();

  const {
    teams: { composers },
  } = store;
  const { composerId } = useParams<{ composerId: string }>();

  const composer = composers.collection.find(n => n.id === composerId);
  const segment = composer && toJS(composer.segment);
  const messagesSent = composer && composer.messagesSent;
  if (!composer) return null;

  return (
    <Column sx={{ height: '100%' }}>
      <AppToolbar title={composer.title} backButton />
      <Row sx={{ height: '100%' }}>
        <Column sx={{ width: '70%' }}>
          <Column
            sx={{
              margin: '24px 32px',
              border: `1px solid ${NEUTRALS.JASMINE}`,
              padding: '10px',
              borderRadius: '4px',
            }}
          >
            <Row sx={{ width: '100%', justifyContent: 'space-between', marginBottom: '5px' }}>
              <Typography variant="headline4">{composer.title}</Typography>
              <Typography variant="bodySmallest" sx={{ color: NEUTRALS.EARL_GREY }}>
                {format(new Date(composer.sentAt), 'dd/LL/yyyy hh:mm b')}
              </Typography>
            </Row>
            <Typography
              variant="bodySmall"
              sx={{ color: NEUTRALS.EARL_GREY, marginBottom: '15px', lineHeight: '18px' }}
            >
              To:
              {segment && (
                <RouterLink to={`/segments/${segment.id}`} color={COMMSOR_PURPLE[200]} sx={{ marginLeft: '5px' }}>
                  {segment.name}
                </RouterLink>
              )}
            </Typography>
            <Typography variant="bodySmall" sx={{ lineHeight: '18px' }}>
              {composer.content}
            </Typography>
          </Column>
        </Column>
        <Divider orientation="vertical" sx={{ height: '100%', borderColor: MEDIUM_GREY, marginRight: '30px' }} />
        <Column sx={{ width: '30%' }}>
          <Typography variant="headline4" sx={{ marginTop: '44px', color: CHARCOAL_BLACK }}>
            Message Recipients
          </Typography>
          <Divider sx={{ margin: '10px 0 5px', borderColor: NEUTRALS.JASMINE }} />
          {messagesSent &&
            messagesSent.length > 0 &&
            messagesSent.map(message => (
              <Row key={message.member.id} sx={{ marginTop: '15px', alignItems: 'center' }}>
                <Avatar
                  src={message.member.avatar}
                  alt={message.member.name}
                  sx={{
                    height: 30,
                    width: 30,
                  }}
                />
                <Typography variant="bodySmall" sx={{ marginLeft: '10px', color: NEUTRALS.CEYLON }}>
                  {message.member && message.member.name}
                </Typography>
              </Row>
            ))}
        </Column>
      </Row>
    </Column>
  );
});

import { ConditionSelectOption } from '@caravel/types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, TextField, useTheme } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/useAutocomplete';
import { getAutocompleteOptions, useDebounce } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function ConditionAutocompleteMulti(props: {
  disabled?: boolean;
  openOnFocus?: boolean;
  onBlur?: () => void;
  endpoint: string;
  placeholder?: string;
  onChangeSelected: (value: ConditionSelectOption<unknown>[]) => void;
  autocompleteParams: { [key: string]: any };
  value: ConditionSelectOption<unknown>[];
}) {
  const store = useStore();
  const {
    disabled,
    openOnFocus = false,
    endpoint,
    onBlur,
    onChangeSelected,
    placeholder = 'Search values',
    autocompleteParams,
    value,
  } = props;
  const [options, setOptions] = useState<ConditionSelectOption<unknown>[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const theme = useTheme();

  useEffect(() => {
    const loadOptions = async () => {
      setLoading(true);
      const { teamId, ...ids } = await store.getTeamAndToken();
      const params = { ...autocompleteParams, teamId, value: debouncedSearchTerm };

      const results = await getAutocompleteOptions(endpoint, params, ids);

      setLoading(false);
      if (results.ok) {
        setOptions([...results.options]);
      }
    };

    loadOptions();
  }, [debouncedSearchTerm, store, endpoint, autocompleteParams]);

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeSelected = (
    e: React.ChangeEvent<unknown>,
    newValue: ConditionSelectOption<unknown>[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ConditionSelectOption<unknown>>,
  ) => {
    if (reason === 'selectOption' && value.findIndex(v => v.value === details?.option.value) > -1) {
      // remove already selected value
      newValue.splice(newValue.findIndex(v => v.value === details?.option.value));
    }
    onChangeSelected(newValue);
  };

  return (
    <Autocomplete
      multiple
      limitTags={2}
      disabled={disabled}
      sx={{
        width: '100%',
        paddingRight: theme.spacing(2),
        '& .MuiAutocomplete-groupLabel': {
          height: 40,
          paddingTop: 0,
          paddingBottom: 0,
        },
        '& .MuiAutocomplete-input': {
          '&::placeholder': {
            opacity: 0.5,
          },
        },
      }}
      loading={loading}
      value={value}
      options={options.sort((a, b) => a.name.localeCompare(b.name))}
      onChange={handleChangeSelected}
      getOptionLabel={o => o.name}
      openOnFocus={openOnFocus}
      isOptionEqualToValue={option => {
        return value.find(v => v.value === option.value) !== undefined;
      }}
      renderOption={({ className: listClassName, ...listProps }, o, { selected }) => {
        const checked = selected || value.findIndex(v => v.value === o.value) !== -1;
        return (
          <li {...listProps} key={o.name} className={listClassName} style={{ alignItems: 'center', width: '100%' }}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={checked} />
            {o.name}
          </li>
        );
      }}
      renderInput={params => {
        params.InputProps = {
          ...params.InputProps,
          sx: {
            '&&[class*="MuiOutlinedInput-root"]': {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: '14px',
              background: theme.palette.common.white,
            },
            '&&[class*="MuiOutlinedInput-root"] $input': {
              padding: '10px 14px 10px 0px',
            },
            '&$cssFocused $notchedOutline': {
              borderColor: `${theme.palette.primary.dark} !important`,
            },
            '&.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: `${theme.grey[3]} !important`,
            },
          },
        } as any;
        return (
          <TextField
            {...params}
            // autoFocus
            variant="outlined"
            placeholder={value.length ? '' : placeholder}
            onBlur={onBlur}
            onChange={handleChangeText}
          />
        );
      }}
    />
  );
}

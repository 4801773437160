import { Logger, useStoreMount } from 'helpers';
import { useAppReadyCheck } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

import { IntegrationsList } from '../integrations';
import { IntegrationsDetail } from '../integrations/integration-detail';

const { debug } = new Logger('integrations-routes');

export const IntegrationsRoutes: FC = observer(() => {
  const store = useStore();
  useStoreMount(store, [store.teams.integrations]);

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> rendering integrations`);

  return (
    <Switch>
      <Route path="/settings/integrations/:type" component={IntegrationsDetail} />
      <Route path="/settings/integrations" component={IntegrationsList} />
    </Switch>
  );
});

import { Column, Flex, NarrowColumn, PageHeader, Row, TextFieldWithAdornment } from '@caravel/components';
import { TeamApiKey } from '@caravel/types';
import { isEmptyString } from '@caravel/utils';
import { DARK_GREY, GIBBERING_GREY, LIGHT_GREY, RED, WHITE } from '@caravel/utils/src/constants/colors';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FlexBox } from 'components/flex-box';
import { updateDoc } from 'firebase/firestore';
import { createTeamApiKey, getTeamApiKey, updateTeamApiKey } from 'helpers/services/teams';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores';

import { AppToolbar } from '../../app-toolbar';

export const AccountView = observer(() => {
  const store = useStore();
  const [username, setUsername] = useState<string | undefined>(store.members.current?.username);
  const [fullName, setFullName] = useState<string | undefined>(store.members.current?.name);
  const [apiKey, setApiKey] = useState<undefined | TeamApiKey | null>(undefined);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const currentUsername = store.members.current?.username;
  const currentFullName = store.members.current?.name;
  const hasApiKeyFeature = store.features.hasFeature('apiKey');

  useEffect(() => {
    if (loading || !hasApiKeyFeature || apiKey !== undefined) {
      return;
    }
    setLoading(true);
    store.ui.workOn(async () => {
      const { teamId, token, userId } = await store.getTeamAndToken();
      try {
        const key = await getTeamApiKey({ teamId }, { userId, token }, [teamId]);
        setApiKey(key);
      } catch (e) {
        console.error(e);
        setApiKey(null);
      }
      setLoading(false);
    });
  }, [apiKey, hasApiKeyFeature, loading, store]);

  const onChangeFullName = (name: string) => {
    setFullName(name);
  };

  const onChangeUsername = (name: string) => {
    setUsername(name);
  };

  const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUsername(e.currentTarget.value);
  };

  const handleChangeFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeFullName(e.currentTarget.value);
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await onSaveProfile();
    }
  };

  const onSaveProfile = async () => {
    if (username === currentUsername && fullName === currentFullName) {
      return;
    }
    if (!isEmptyString(fullName) || !isEmptyString(username)) {
      await store.ui.workOn(async () => {
        await updateDoc(store.members.current!.docRef, {
          name: fullName,
          username,
        });
        store.notifications.display({
          message: `Profile updated`,
          severity: 'success',
        });

        //Segment Analytics
        analytics.identify(store.members.current!.uid, {
          name: fullName,
        });
        analytics.track('User Updated');
      });
    } else {
      store.notifications.display({
        message: `Name can't be blank`,
        severity: 'error',
      });
    }
  };

  const onRegenerateApiKey = async () => {
    if (loading) return;
    setLoading(true);
    store.ui.workOn(async () => {
      const { teamId, token, userId } = await store.getTeamAndToken();
      try {
        const key = apiKey
          ? await updateTeamApiKey({ teamId }, { userId, token }, [teamId])
          : await createTeamApiKey({ teamId }, { userId, token }, [teamId]);
        setApiKey(key);
      } catch (e) {
        setApiKey(null);
      }
      setLoading(false);
    });
  };

  return (
    <Column>
      <AppToolbar title="Account" />
      <NarrowColumn>
        <PageHeader headline="Account" description="Account Manage your personal account settings" />
        <Box
          sx={{
            background: GIBBERING_GREY,
            borderRadius: '5px',
            height: '246px',
            padding: '20px',
            width: '100%',
          }}
        >
          <Typography variant="inputLabel">Full name</Typography>
          <TextField
            onChange={handleChangeFullName}
            sx={{ margin: '10px 0', background: WHITE }}
            fullWidth
            value={fullName}
            id="name"
            onKeyPress={handleKeyPress}
          />
          <Typography variant="inputLabel">Username</Typography>
          <TextField
            onChange={handleChangeUsername}
            sx={{ margin: '10px 0', background: WHITE }}
            fullWidth
            value={username}
            id="username"
            onKeyPress={handleKeyPress}
          />
          <FlexBox justifyContent="flex-end">
            <Button
              type="submit"
              sx={{ width: '94px' }}
              color="primary"
              onClick={onSaveProfile}
              variant="contained"
              disabled={
                (fullName === currentFullName && currentUsername === username) ||
                isEmptyString(fullName) ||
                isEmptyString(username)
              }
            >
              Update
            </Button>
          </FlexBox>
        </Box>
        <Divider sx={{ margin: '30px 0' }} />

        {store.features.hasFeature('apiKey') && (
          <Column>
            <Typography variant="inputLabel">API Key</Typography>
            {apiKey && (
              <>
                <Column
                  sx={{
                    borderRadius: '5px',
                    border: `2px solid ${RED}`,
                    padding: '10px',
                    marginTop: '20px',
                  }}
                >
                  <Typography variant="inputLabelSmall" sx={{ color: RED, marginBottom: '10px' }}>
                    Keep it secret. Keep it safe.
                  </Typography>
                  <Typography variant="inputLabelLine" sx={{ color: RED }}>
                    Don't share it online, including via public version control repositories.
                  </Typography>
                </Column>
                <TextFieldWithAdornment
                  value={apiKey.token}
                  endIcon={
                    <IconButton onClick={() => store.ui.copyToClipboard(apiKey.token)}>
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect
                          x="6.75"
                          y="8.03363"
                          width="9.5"
                          height="9.5"
                          rx="1.25"
                          stroke="#AEAEAE"
                          strokeWidth="1.5"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4 4.78363H11C11.2761 4.78363 11.5 5.00749 11.5 5.28363V6.28363H13V5.28363C13 4.17906 12.1046 3.28363 11 3.28363H4C2.89543 3.28363 2 4.17906 2 5.28363V12.2836C2 13.3882 2.89543 14.2836 4 14.2836H5V12.7836H4C3.72386 12.7836 3.5 12.5598 3.5 12.2836V5.28363C3.5 5.00749 3.72386 4.78363 4 4.78363Z"
                          fill="#AEAEAE"
                        />
                      </svg>
                    </IconButton>
                  }
                  sx={{
                    borderRadius: '5px',
                    color: DARK_GREY,
                    '& .MuiInputBase-input': {
                      fontSize: '13px',
                      fontFamily: `'Roboto Mono', monospace`,
                      lineHeight: '17px',
                    },
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: LIGHT_GREY,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      padding: 0,
                    },
                  }}
                />
              </>
            )}
            <Row sx={{ marginTop: '20px' }}>
              <Flex flex={1} />
              <Button
                disabled={loading}
                onClick={() => (apiKey ? setShowConfirm(true) : onRegenerateApiKey())}
                variant="contained"
                color="primary"
                sx={{ height: '32px' }}
                startIcon={
                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.7083 2.94378C10.5 1.73544 8.84167 0.985443 7.00001 0.985443C3.31667 0.985443 0.341675 3.96878 0.341675 7.65211C0.341675 11.3354 3.31667 14.3188 7.00001 14.3188C10.1083 14.3188 12.7 12.1938 13.4417 9.31878H11.7083C11.025 11.2604 9.17501 12.6521 7.00001 12.6521C4.24167 12.6521 2.00001 10.4104 2.00001 7.65211C2.00001 4.89378 4.24167 2.65211 7.00001 2.65211C8.38334 2.65211 9.61667 3.22711 10.5167 4.13544L7.83334 6.81878H13.6667V0.985443L11.7083 2.94378Z"
                      fill="white"
                    />
                  </svg>
                }
              >
                {apiKey ? 'Regenerate key' : 'Generate key'}
              </Button>
            </Row>
            <Dialog open={showConfirm} onClose={() => setShowConfirm(false)}>
              <DialogTitle>Are you sure you want to continue?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Any applications using this API key will no longer have access to Caravel's API. This can't be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" color="inherit" onClick={() => setShowConfirm(false)}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowConfirm(false);
                    onRegenerateApiKey();
                  }}
                >
                  Regenerate
                </Button>
              </DialogActions>
            </Dialog>
          </Column>
        )}
      </NarrowColumn>
    </Column>
  );
});

export default AccountView;

import { ACCEPT_INVITE, ADD_CARAVEL_ANALYST, CREATE_INVITE, isEmptyString } from '@caravel/utils';
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DependentDisposer, getDb, Logger } from 'helpers';
import { action, makeObservable, observable, when } from 'mobx';
import { Invite, InviteProps, Team } from 'models';

import { FirebaseStore } from './base';
import { RootStore } from './root';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug, warn } = new Logger('invite-store');

export interface PendingInviteData {
  teamId: string;
  inviteId: string;
}

export class InviteStore extends FirebaseStore<Invite, InviteProps> {
  pendingInviteData?: PendingInviteData = undefined;

  private accepting = false;
  private teamSubs = new DependentDisposer(() => this.rootStore.teams.currentTeam, 'team-subs');

  constructor(rootStore: RootStore) {
    super({
      rootStore,
      modelConstructor: Invite,
    });

    makeObservable(this, {
      pendingInviteData: observable,
      updatePending: action,
    });
  }

  async mount() {
    await this.teamSubs.initialize();
    this.teamSubs.addEffect('invites', this.subToInvites);
  }

  unmount() {
    this.pendingInviteData = undefined;
    this.teamSubs.teardown();
  }

  acceptInvite = async (pendingInviteData: PendingInviteData) => {
    if (this.accepting || !pendingInviteData) {
      return;
    }
    this.accepting = true;

    const acceptInvite = httpsCallable(getFunctions(), ACCEPT_INVITE);
    await acceptInvite(pendingInviteData);

    this.updatePending(undefined);

    // wait for team to appear in store
    await when(() => {
      const newTeam = this.rootStore.teams.teams.slice().find(t => t.id === pendingInviteData.teamId);
      return newTeam !== undefined;
    });

    // load team
    await this.rootStore.teams.loadTeam(pendingInviteData.teamId);

    //Segment Analytics
    analytics.group(pendingInviteData.teamId, {
      groupId: pendingInviteData.teamId,
      employees: this.rootStore.teams.teamMembers.collection.slice().length,
      pending_invites: this.rootStore.teams.invites.collection.slice().length,
    });
    analytics.track(
      'User Joined Workspace',
      {
        groupId: pendingInviteData.teamId,
      },
      {
        context: {
          groupId: pendingInviteData.teamId,
        },
      },
    );

    this.accepting = false;
  };

  acceptPendingInvite = async (): Promise<boolean> => {
    const pending = this.pendingInviteData;
    if (!pending) {
      return false;
    }
    await this.rootStore.ui.workOn(async () => {
      await this.acceptInvite(pending);
      this.updatePending(undefined);
    });
    return true;
  };

  addCaravelAnalyst = async (uid: string) => {
    const teamId = await this.getTeam();
    const addCaravelAnalyst = httpsCallable(getFunctions(), ADD_CARAVEL_ANALYST);
    await addCaravelAnalyst({ uid, teamId });
  };

  inviteWithEmail = async (email: string) => {
    if (isEmptyString(email)) {
      return;
    }

    const team = this.rootStore.teams.currentTeam;
    if (!team) {
      warn('Cannot send invite without a loaded team.');
      return false;
    }

    if (this.rootStore.teams.teamMembers.collection.find(tm => tm.email === email)) {
      throw new Error('Already a team member');
    }

    const existingRef = query(collection(getDb(), team.docRef.path, 'invites'), where('email', '==', email));

    const existing = await getDocs(existingRef);
    if (!existing.empty) {
      throw new Error('Invite already exists');
    }

    const createInvite = httpsCallable(getFunctions(), CREATE_INVITE);
    await createInvite({ email, teamId: team.id });

    this.rootStore.analytics.trackEvent('Member Invited', { email, team_id: team.id });
    this.rootStore.sendHubEvent(`invited a teammate at ${email}`);
  };

  updatePending = (pendingInviteData?: PendingInviteData) => {
    this.pendingInviteData = pendingInviteData;
  };

  private subToInvites = (team: Team) => {
    this.collection.clear();
    const invitesRef = collection(getDb(), team.docRef.path, 'invites');
    return onSnapshot(invitesRef, this.updateFromSnapshot);
  };
}

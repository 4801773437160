import { Person, TeamConnection } from '@caravel/types/src';
import { makeInitials } from '@caravel/utils/src';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import React, { forwardRef, useRef, useState } from 'react';

import { ConnectionPopover } from './connection-popover';
import { Row } from './flex';
import { useTeamConnections } from './hooks/use-team-connections';
import { IntroductionModal } from './modals';

export interface ConnectionsTableCellProps {
  teamConnections: TeamConnection[];
  userId: string;
  person: Person;
  handleAskIntroduction: (teamMemberId: string, personId: string, message: string) => Promise<string | undefined>;
}

export const ConnectionsTableCell = forwardRef<HTMLDivElement, ConnectionsTableCellProps>((props, ref) => {
  const theme = useTheme();

  const popoverAnchor = useRef(null);
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverEnter = () => setOpenedPopover(true);
  const popoverLeave = () => setOpenedPopover(false);

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenIntroductionModal = () => setModalOpen(true);
  const handleCloseIntroductionModal = () => setModalOpen(false);

  const { teamConnections, userId, person, handleAskIntroduction } = props;
  const [currentConnection, setCurrentConnection] = useState<TeamConnection>(teamConnections[0]);

  if (!teamConnections || !teamConnections.length) return <>---</>;

  const { teamConnectionsContainsSelf, selfIndex } = useTeamConnections(teamConnections, userId);
  let sortedTeamConnections = teamConnections;
  if (teamConnectionsContainsSelf) {
    sortedTeamConnections = [
      teamConnections[selfIndex],
      ...teamConnections.slice(0, selfIndex),
      ...teamConnections.slice(selfIndex + 1),
    ];
  }

  const handleAskIntroductionAndClose = async (teamMemberId: string, connectionId: string, message: string) => {
    const response = await handleAskIntroduction(teamMemberId, connectionId, message);
    if (response) handleCloseIntroductionModal();
  };

  return (
    <>
      <Box ref={popoverAnchor} onMouseEnter={popoverEnter} onMouseLeave={popoverLeave} sx={{ cursor: 'pointer' }}>
        <Row alignItems="center">
          <Avatar src={sortedTeamConnections[0].photoUrl} sx={{ width: '24px', height: '24px' }}>
            {makeInitials({ name: sortedTeamConnections[0].name })}
          </Avatar>
          <Typography paddingLeft={theme.spacing(1)} variant="bodySmall">
            {teamConnectionsContainsSelf ? 'You' : sortedTeamConnections[0].name}{' '}
            {sortedTeamConnections.length > 1 && `+${sortedTeamConnections.length - 1}`}
          </Typography>
        </Row>
      </Box>
      <ConnectionPopover
        teamConnections={sortedTeamConnections}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        userId={userId}
        setCurrentConnection={setCurrentConnection}
        currentConnection={currentConnection}
        handleOpenIntroductionModal={handleOpenIntroductionModal}
      />
      <IntroductionModal
        onOpen={handleOpenIntroductionModal}
        onClose={handleCloseIntroductionModal}
        open={modalOpen}
        teamConnections={sortedTeamConnections}
        initialConnection={currentConnection}
        person={person}
        userId={userId}
        handleAskIntroduction={handleAskIntroductionAndClose}
      />
    </>
  );
});

ConnectionsTableCell.displayName = 'ConnectionsTableCell';

import { NEUTRALS } from '@caravel/utils/src';
import { Typography } from '@mui/material';
import React from 'react';

import { Column } from './flex';

export interface PageHeaderProps {
  headline: string;
  description?: string;
}

export const PageHeader = ({ headline, description }: PageHeaderProps) => {
  return (
    <Column paddingTop="32px" paddingBottom="24px">
      <Typography variant="headline2">{headline}</Typography>
      {description && (
        <Typography variant="bodySmall" color={NEUTRALS.OOLONG} sx={{ marginTop: '10px' }}>
          {description}
        </Typography>
      )}
    </Column>
  );
};

import { NEUTRALS, PRIMARY_PALETTE, WHITE } from '@caravel/utils/src';
import { BoxProps, Button, Grid, Typography } from '@mui/material';
import React from 'react';

import { Column, Flex } from './flex';

export interface IconTileProps {
  uid?: string;
  icon: React.ReactNode;
  name: string;
  description?: string;
  actionText?: string;
  actionHandler?: () => void | any;
  actionDisabled?: boolean;
  sx?: BoxProps['sx'];
  xs?: number;
  sm?: number;
  md?: number;
}

export function IconTile(props: IconTileProps) {
  const {
    icon,
    name,
    description,
    actionText = 'Select',
    actionHandler,
    actionDisabled,
    sx,
    xs = 12,
    sm = 6,
    md = 4,
  } = props;
  return (
    <Grid item xs={xs} sm={sm} md={md} sx={sx}>
      <Column
        sx={{
          borderColor: NEUTRALS.CHAMOMILE,
          borderWidth: 2,
          borderStyle: 'solid',
          borderRadius: '5px',
          padding: '44px 10px 40px',
          height: '100%',
          backgroundColor: NEUTRALS.CHAMOMILE,
          transition: 'all 150ms ease-in-out',
          cursor: 'pointer',
          '&:hover, &:focus': {
            borderColor: PRIMARY_PALETTE[200],
            backgroundColor: WHITE,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)',
          },
        }}
        onClick={() => !actionDisabled && actionHandler && actionHandler()}
      >
        <Column
          sx={{
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          <Flex
            sx={{
              height: '40px',
              width: '40px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '5px',
              svg: {
                height: '40px',
                width: '40px',
              },
            }}
          >
            {icon}
          </Flex>
          <Typography variant="headline3" sx={{ margin: '24px 0' }}>
            {name}
          </Typography>
          <Typography variant="bodySmaller" sx={{ color: NEUTRALS.OOLONG, textAlign: 'center', margin: '0 20px' }}>
            {description}
          </Typography>
          <Flex flex={1} />
          <Button
            disabled={actionDisabled}
            variant="contained"
            color="primary"
            onClick={actionHandler}
            sx={{
              marginTop: '30px',
              height: '32px',
              padding: '0 16px',
            }}
          >
            {actionText}
          </Button>
        </Column>
      </Column>
    </Grid>
  );
}

import { CaravelSpinner, Flex, TextField } from '@caravel/components/src';
import { SvgIcon, TextFieldProps } from '@mui/material';
import React from 'react';

import { SearchFilterIcon } from './filter-icons';

export const FilterMenuSearch = React.forwardRef<HTMLDivElement, TextFieldProps & { loading?: boolean }>(
  ({ loading, ...props }, ref) => {
    return (
      <TextField
        {...props}
        ref={ref}
        InputProps={{
          startAdornment: loading ? (
            <Flex
              sx={{
                minWidth: '20px',
                width: '20px',
                height: '20px',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <CaravelSpinner size={16} />
            </Flex>
          ) : (
            <SvgIcon component={SearchFilterIcon} sx={{ fill: 'none', width: '20px', height: '20px' }} />
          ),
        }}
        sx={{
          width: '100%',
          '& .MuiTextField-root': {
            padding: '8px 0',
            backgroundColor: 'white',
          },
          '& .MuiInputBase-input': {
            fontSize: '13px',
            lineHeight: '16px',
            padding: 0,
            marginLeft: '4px',
            height: '18px',
            '& .Mui-focused': {
              backgroundColor: 'none',
            },
          },
          '& .MuiOutlinedInput-root': {
            background: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            padding: 0,
          },
          '& .MuiInputBase-adornedStart': {
            paddingLeft: 0,
          },
          ...props.sx,
        }}
      />
    );
  },
);

FilterMenuSearch.displayName = 'FilterMenuSearch';

import { Column, Flex } from '@caravel/components/src';
import { NEUTRALS, WHITE } from '@caravel/utils';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react';
import { Person } from 'models/person';
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores';

import { ReactComponent as NoteIcon } from '../../../assets/svgs/note.svg';
import { PersonNotes } from '../../people/person-detail/person-notes';
import { ChampionNotes } from '../champion-notes';

export const ChampionTabs = observer((props: { person: Person }) => {
  const { person } = props;
  const store = useStore();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const VERTICAL_PADDING = '20px';

  const [tabsPinned, setTabsPinned] = useState(false);
  const refTabsContain = React.createRef<HTMLElement>();
  const refContainer = React.createRef<HTMLElement>();

  useEffect(() => {
    const tabsRef = refTabsContain.current;
    if (!tabsRef) return;
    const observer = new IntersectionObserver(([e]) => setTabsPinned(e.intersectionRatio < 1), { threshold: [1] });
    observer.observe(tabsRef);
    return () => observer.unobserve(tabsRef);
  }, [refTabsContain]);

  useEffect(() => {
    if (tabsPinned && refContainer.current) {
      refContainer.current.style.minHeight = `calc(100vh - ${store.ui.appBarHeight}px - ${refTabsContain.current?.clientHeight}px - ${VERTICAL_PADDING})`;
    }
  }, [refContainer, refTabsContain, store.ui.appBarHeight, tabsPinned]);

  return (
    <Column sx={{ width: '100%', padding: `${VERTICAL_PADDING} 24px` }}>
      <Box
        borderBottom={`2px solid ${NEUTRALS.JASMINE}`}
        sx={{ background: WHITE, position: 'sticky', top: '-1px', zIndex: 1 }}
        ref={refTabsContain}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Tabs
            value={currentTab}
            onChange={(evt: React.SyntheticEvent, value: number) => setCurrentTab(value)}
            aria-label="Activity Tabs"
            sx={{
              justifyContent: 'flex-start',
              minHeight: 40,
              border: 'none',
              marginBottom: '-2px',
              width: 'auto',
              '& .MuiTabs-scroller': {
                justifyContent: 'flex-start',
              },
              '& .MuiTab-root': {
                padding: '0px 16px',
                minWidth: 120,
                minHeight: 0,
                width: 'auto',
              },
            }}
          >
            <Tab label="Notes" value={0} />
          </Tabs>

          {currentTab === 0 && (
            <Box>
              <Button
                variant="contained"
                size="small"
                color="tertiary"
                onClick={() => store.teams.champions.onAddPersonNote()}
                startIcon={<NoteIcon />}
              >
                Add Note
              </Button>
            </Box>
          )}
        </Flex>
      </Box>
      <Box ref={refContainer}>{currentTab === 0 && <ChampionNotes person={person} />}</Box>
    </Column>
  );
});

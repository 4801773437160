import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Column, Row } from './flex';

interface CollapsibleContainerProps {
  headerTitle: JSX.Element;
  headerDescription?: JSX.Element;
  classes?: any;
  className?: string;
  children?: any;
}

const CollapsibleContainerUnstyled: React.FC<CollapsibleContainerProps> = ({
  children,
  headerTitle,
  headerDescription,
  className,
}) => {
  const [open, setOpen] = React.useState(true);
  return (
    <Box sx={{ width: 'full' }} className={className}>
      <Column sx={{ gap: '10px' }}>
        <Row sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {headerTitle}
          <Button
            sx={{ color: 'black', padding: 0, minWidth: '24px', height: '24px' }}
            disableRipple
            onClick={() => setOpen(!open)}
          >
            <KeyboardArrowDownIcon
              sx={{
                width: '18px',
                height: '18px',
                transform: open ? 'rotate(0)' : 'rotate(-90deg)',
                transition: '0.2s',
              }}
            />
          </Button>
        </Row>
        {headerDescription}
      </Column>

      <Collapse in={open}>{children}</Collapse>
    </Box>
  );
};

export const CollapsibleContainer = styled(CollapsibleContainerUnstyled)({});

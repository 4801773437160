import { gql } from '@apollo/client';

export const GET_COMMUNITY_GRID = gql`
  query getGridStatus {
    community {
      stats {
        gridStatus {
          name
          count
        }
        position {
          name
          count
        }
      }
    }
  }
`;

export interface GqlCommunityGridResponseType {
  community: {
    id: string;
    stats: {
      gridStatus: {
        name: string;
        count: number;
      }[];
      position: {
        name: string;
        count: number;
      }[];
    };
  };
}

import { createGQLClient } from '@caravel/utils';
import {
  CONNECT_CHANNEL,
  GqlConnectChannelRequestType,
  GqlConnectChannelResponseType,
} from '@caravel/utils/src/gql/mutations/connect-channel.gql';
import { useAppReadyCheck } from 'helpers';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useStore } from 'stores';

const OauthRedirect = observer(() => {
  const store = useStore();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const cookie = Cookies.get('state-cookie')!;
  const integrationType = Cookies.get('integration-type') ?? '';
  const integrationId = Cookies.get(cookie)!;
  const state = params.get('state')!;
  const code = params.get('code')!;
  const [working, setWorking] = React.useState(false);

  const commitConnection = useCallback(async () => {
    const { teamId, token } = await store.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);
    try {
      await graphqlClient.mutate<GqlConnectChannelRequestType, GqlConnectChannelResponseType>(CONNECT_CHANNEL, {
        input: {
          clientMutationId: 'connect-channel',
          integrationId,
          oauthCode: code,
          state,
          storedState: cookie,
        },
      });
      store.notifications.display({
        message: 'Connected integration',
        severity: 'success',
        duration: 5000,
      });
    } catch (e) {
      console.error(e);
      store.notifications.display({
        message: 'Failed to connect integration',
        severity: 'error',
        duration: 5000,
      });
    }
    history.push(`/settings/integrations/${integrationType}`);
  }, [store, integrationId, code, state, cookie, history, integrationType]);

  useEffect(() => {
    if (working) return;
    store.ui.workOn(async () => {
      setWorking(true);
      await commitConnection();
    });
  }, [working, commitConnection, store.ui]);

  return null;
});

export const OauthRoutes: FC = observer(() => {
  const store = useStore();

  if (!useAppReadyCheck(store)) {
    return null;
  }

  return (
    <Switch>
      <Route path="/oauth/redirect" component={OauthRedirect} />
    </Switch>
  );
});

import { Flex } from '@caravel/components';
import { Dialog, Typography } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { getDb } from 'helpers';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useStore } from 'stores';

type Status = 'download' | 'not-found' | 'redirecting';

export const ExportDownloaderContainer = observer(() => {
  const store = useStore();
  const params = useParams<{ exportId: string }>();
  const [status, setStatus] = useState<Status>('download');
  const [link, setLink] = useState<string>();

  const teamId = store.team?.id;

  useEffect(() => {
    let isCancelled = false;
    const { exportId } = params;

    const run = async () => {
      if (!teamId) {
        return;
      }
      const exportRef = doc(getDb(), 'teams', teamId, 'exports', exportId);
      const exportDoc = await getDoc(exportRef);
      if (isCancelled) {
        return;
      }
      if (!exportDoc.exists || exportDoc.get('archivedAt')) {
        setStatus('not-found');
        return;
      }
      const path = exportDoc.get('storagePath');
      if (!path) {
        setStatus('not-found');
        return;
      }

      const downloadUrl = await getDownloadURL(ref(getStorage(), path));
      if (!downloadUrl) {
        setStatus('not-found');
        return;
      }

      setStatus('download');
      setLink(downloadUrl);
      window.open(downloadUrl);
    };

    store.ui.workOn(run);

    return () => {
      isCancelled = true;
    };
  }, [params, store.ui, teamId]);

  if (!link) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flex={1}
      sx={{
        height: '100%',
      }}
    >
      {status === 'not-found' && (
        <Dialog open>
          <Typography variant="h5">
            <span role="img" aria-label="thinking face">
              🤔
            </span>
            {` Not found`}
          </Typography>
        </Dialog>
      )}
      {status === 'redirecting' && <Redirect to="/home" />}
    </Flex>
  );
});

export default ExportDownloaderContainer;

import { Integration as IntegrationProps } from '@caravel/types';
import { makeObservable, observable } from 'mobx';
import { BaseModel, makeToJS, makeUpdate } from 'models/base';

const skeletonProps: IntegrationProps = {
  id: '',
  name: '',
  description: '',
  strategy: '',
  authMethod: '',
  launchedAt: new Date(),
  popularity: 0,
  oauthUrl: '',
  channels: [],
};

export class Integration extends BaseModel<IntegrationProps> implements IntegrationProps {
  id = skeletonProps.id;
  name = skeletonProps.name;
  description = skeletonProps.description;
  strategy = skeletonProps.strategy;
  authMethod = skeletonProps.authMethod;
  launchedAt = skeletonProps.launchedAt;
  popularity = skeletonProps.popularity;
  channels = skeletonProps.channels;
  oauthUrl = skeletonProps.oauthUrl;

  constructor(props: IntegrationProps) {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      strategy: observable,
      authMethod: observable,
      launchedAt: observable,
      popularity: observable,
      channels: observable,
      oauthUrl: observable,
    });
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

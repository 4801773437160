import { Column } from '@caravel/components/src';
import { Grid } from '@mui/material';
import { useDualPanels } from 'helpers';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'stores';

import { AppToolbar } from '../../app-toolbar';
import { PersonDetails } from '../../people/person-detail/person-details';
import { ChampionTabs } from './champion-tabs';

export const ChampionSimpleDetail = observer(() => {
  const store = useStore();
  const { containerStyle, panelStyle } = useDualPanels();
  const { championId } = useParams<{ championId: string }>();

  const inMemoryChampion = store.teams.champions.getInMemoryChampion(championId);
  const champion = store.teams.champions.currentChampion ?? inMemoryChampion;
  const canLoad = store.team && !store.teams.champions.currentChampion && !store.teams.champions.currentChampionLoading;

  useEffect(() => {
    return () => {
      store.teams.champions.resetCurrentChampion();
    };
  }, [store.teams.champions]);

  useEffect(() => {
    if (!canLoad) return;
    store.ui.workOn(async () => {
      await store.teams.champions.fetchChampion(championId);
    });
  }, [canLoad, championId, store.teams.champions, store.ui]);

  if (!champion) return null;

  return (
    <Column sx={containerStyle}>
      <AppToolbar title="Champions" subtitle={champion.name} backButton />
      <Grid container sx={containerStyle}>
        <Grid item xs={12} md={6} lg={5} xl={4} sx={panelStyle}>
          <PersonDetails person={champion} previousPage={'champions'} />
        </Grid>
        <Grid item xs={12} md={6} lg={7} xl={8} sx={panelStyle}>
          <ChampionTabs person={champion} />
        </Grid>
      </Grid>
    </Column>
  );
});

import { SUBDOMAIN_REGEX } from '@caravel/utils';
import { doc, getDoc } from 'firebase/firestore';
import { getDb, Logger, reactTo } from 'helpers';
import debounce from 'lodash/debounce';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from 'stores/root';

import { BaseStore } from '../base';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('team-form-store');

type SlugState = 'unknown' | 'invalid' | 'valid';

export class TeamFormStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable<TeamFormStore, '_slugState' | '_slug'>(this, {
      slugUniqueness: computed,
      _slugState: observable,
      _slug: observable,
      checkSlug: action,
    });
  }

  get slugUniqueness(): SlugState {
    return this._slugState;
  }

  private _slugState: SlugState = 'unknown';
  private _slug = '';

  async setup() {
    await super.setup();
    this.addDisposable(this.reactToSlug());
  }

  teardown() {
    this._slug = '';
    this._slugState = 'unknown';
    super.teardown();
  }

  /**
   * From the create team from, use this function to check a slug for uniqueness. This triggers an **indirect** result
   * that can be read in `slugUniqueness`.
   */
  checkSlug = (slug: string) => {
    if (slug !== this._slug) {
      this._slugState = 'unknown';
      this._slug = slug;
    }
  };

  private checkValidSlug = debounce(async slug => {
    runInAction(() => {
      this._slugState = 'unknown';
    });
    const slugDoc = await getDoc(doc(getDb(), 'slugs', slug));
    runInAction(() => {
      if (!SUBDOMAIN_REGEX.test(slug) || slugDoc.exists()) {
        this._slugState = 'invalid';
      } else {
        this._slugState = 'valid';
      }
    });
  }, 500);

  private reactToSlug = () =>
    reactTo(
      () => this._slug,
      slug => {
        if (!slug || slug.trim() === '') {
          this._slugState = 'unknown';
          return;
        }
        this.checkValidSlug(slug);
      },
      'team-create-slug',
    );
}

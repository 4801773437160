import { DARK_GREY, NEUTRALS } from '@caravel/utils';
import { Button, Card, CardContent, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ReactComponent as NoResultsIcon } from './assets/svgs/no-results.svg';
import { Flex } from './flex';

export interface NoResultsProps {
  title: string;
  message?: string;
  icon?: React.ReactNode;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  onButtonClick?: () => void;
  sx?: SxProps;
}

export const NoResults = observer((props: NoResultsProps) => {
  const theme = useTheme();
  const { title, message, icon, buttonIcon, buttonText, onButtonClick, sx } = props;

  return (
    <Flex
      sx={{
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Card variant="elevation" elevation={1} sx={{ maxWidth: '555px', width: '75%' }}>
        <CardContent sx={{ padding: '48px 80px !important' }}>
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: theme.spacing(3),
            }}
          >
            {icon ? icon : <NoResultsIcon />}
          </Flex>

          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="headline1" align="center" sx={{ marginBottom: theme.spacing(1) }}>
              {title}
            </Typography>
            {message && (
              <Typography variant="bodySmall" align="center" sx={{ color: NEUTRALS.OOLONG }}>
                {message}
              </Typography>
            )}
            {buttonText && buttonIcon && (
              <Button
                sx={{
                  marginTop: theme.spacing(3),
                  fontWeight: '500',
                }}
                variant="contained"
                startIcon={buttonIcon}
                onClick={onButtonClick}
              >
                {buttonText}
              </Button>
            )}
          </Flex>
        </CardContent>
      </Card>
    </Flex>
  );
});

export const getQueryInt = (query: URLSearchParams, key: string, defaultVal = 0): number => {
  const val = key && query.get(key);
  if (val) {
    return parseInt(val);
  }

  return defaultVal;
};

export const getQueryFloat = (query: URLSearchParams, key: string, defaultVal = 0.0): number => {
  const val = key && query.get(key);
  if (val) {
    return parseFloat(val);
  }

  return defaultVal;
};

export const getQueryBool = (query: URLSearchParams, key: string): boolean => {
  return query.get(key)?.toLowerCase() === 'true';
};

import { GREY_PALETTE, WARM_SHADES_PALETTE } from '@caravel/utils/src';
import { Box, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import ImageUploading from 'react-images-uploading';

import { Row } from '../flex';
import { CaravelSpinner } from '../loaders';

type Dispatcher<S> = Dispatch<SetStateAction<S>>;

export interface ImageUploaderProps {
  image: any[];
  setImage: Dispatcher<any[]>;
  loading: boolean;
  existingImageUrl: string | undefined;
}

export const ImageUploader = (props: ImageUploaderProps) => {
  const { image = [], setImage, loading = false, existingImageUrl } = props;

  const onImageChange = (image: any) => {
    setImage(image);
  };

  return (
    <ImageUploading value={image} onChange={onImageChange} dataURLKey="data_url" maxNumber={1}>
      {({ imageList, onImageUpload, dragProps, onImageUpdate }) => (
        <Row>
          <button
            style={{
              height: '48px',
              width: '100%',
              borderRadius: '4px',
              backgroundColor: 'white',
              border: `1px solid ${GREY_PALETTE[4]}`,
              padding: 0,
              overflow: 'hidden',
            }}
            onClick={() => {
              if (imageList[0]) {
                onImageUpdate(0);
              } else {
                onImageUpload();
              }
            }}
            {...dragProps}
          >
            <Row sx={{ height: '100%', alignItems: 'center', cursor: 'pointer' }}>
              <Box
                sx={{
                  backgroundColor: GREY_PALETTE[1],
                  height: '100%',
                  minWidth: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '12px 14px 12px 14px',
                  marginRight: '16px',
                }}
              >
                <Typography variant="bodySmall" sx={{ color: GREY_PALETTE[7] }}>
                  Choose File
                </Typography>
              </Box>
              {!image[0] && !existingImageUrl ? (
                <Typography variant="bodySmall" sx={{ color: WARM_SHADES_PALETTE[2] }}>
                  No file chosen
                </Typography>
              ) : loading ? (
                <Box
                  sx={{
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    marginLeft: '-16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CaravelSpinner />
                </Box>
              ) : existingImageUrl ? (
                <Typography variant="bodySmall" sx={{ color: WARM_SHADES_PALETTE[4] }}>
                  Image Uploaded
                </Typography>
              ) : (
                <Typography variant="bodySmall" sx={{ color: WARM_SHADES_PALETTE[4] }}>
                  {imageList[0].file?.name}
                </Typography>
              )}
            </Row>
          </button>
        </Row>
      )}
    </ImageUploading>
  );
};

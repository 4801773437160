import { useEffect, useState } from 'react';

export const useKeyPress = (key: string, onKeyPress: () => void) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === key) {
        onKeyPress();
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [key, onKeyPress]);
};

export const useKeyUp = (key: string, onKeyUp: () => void) => {
  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (event.key === key) {
        onKeyUp();
      }
    };
    window.addEventListener('keyup', handleKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
    };
  }, [key, onKeyUp]);
};

export const useCommandEnter = (onAction: () => void) => {
  const [command, setCommand] = useState(false);
  useKeyPress('Meta', () => setCommand(true));
  useKeyUp('Meta', () => setCommand(false));
  useKeyPress('Enter', () => {
    if (command) {
      onAction();
    }
  });
};

import { gql } from '@apollo/client';

export const GET_SEGMENT_CHANNEL_MEMBERSHIP = gql`
  query segmentChannelMembership($input: SegmentChannelMembershipInput!) {
    segmentChannelMembership(input: $input) {
      id
    }
  }
`;

export interface GqlSegmentChannelMembershipRequestType {
  input: {
    channelId: string;
    segmentId: string;
  };
}

export interface GqlSegmentChannelMembershipResponseType {
  segmentChannelMembership: {
    id: string;
  }[];
}

import { Column, NarrowColumn, PageHeader, Row } from '@caravel/components/src';
import { DARK_GREY, LIGHT_GREY } from '@caravel/utils';
import { Divider, IconButton, Link, SvgIcon, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import { useStore } from 'stores';

import { ReactComponent as CopyClipboardIcon } from '../../../assets/svgs/copy-clipboard-small.svg';

const staticHost = process.env.CARAVEL_STATIC_HOST;
const troveHost = process.env.CARAVEL_TROVE_HOST;

export const TroveEmbedTab = observer(() => {
  const store = useStore();
  const form = store.ui.troveForm;
  const teamId = store.team?.id;
  const troveKey = form.formProps?.settings.key;
  const email = 'user@company.com';

  if (!form.formProps) {
    return null;
  }

  const handleCopy = (text: string) => {
    store.ui.copyToClipboard(text);
  };

  const simpleEmbed = [
    `<script`,
    `  defer`,
    `  src="${staticHost}/capture.js"`,
    `  data-trove-team="${teamId}"`,
    `></script>`,
  ].join('\n');

  const withEmail = [
    `<script`,
    `  defer`,
    `  src="${staticHost}/capture.js"`,
    `  data-trove-team="${teamId}"`,
    `  data-trove-email="${email}"`,
    `></script>`,
  ].join('\n');

  const buttonEmbed = [
    `<script`,
    `  defer`,
    `  src="${staticHost}/capture.js"`,
    `  data-trove-team="${teamId}"`,
    `></script>`,
    `<button`,
    `  data-trove-survey="${troveKey}"`,
    `  data-trove-email="${email}"`,
    `>`,
    `  Give feedback`,
    `</button>`,
  ].join('\n');

  const buttonWithTrigger = [
    `<script`,
    `  defer`,
    `  src="${staticHost}/capture.js"`,
    `  data-trove-team="${teamId}"`,
    `></script>`,
    `<button class="custom-target">`,
    `  Give feedback`,
    `</button>`,
  ].join('\n');

  const inlineEmbed = [
    `<script`,
    `  defer`,
    `  src="${staticHost}/capture.js"`,
    `  data-trove-team="${teamId}"`,
    `></script>`,
    `<div`,
    `  data-trove-inline`,
    `  data-trove-survey="${troveKey}"`,
    `></div>`,
  ].join('\n');

  const inlineWithEmail = [
    `<script`,
    `  defer`,
    `  src="${staticHost}/capture.js"`,
    `  data-trove-team="${teamId}"`,
    `></script>`,
    `<div`,
    `  data-trove-inline`,
    `  data-trove-survey="${troveKey}"`,
    `  data-trove-email="${email}"`,
    `></div>`,
  ].join('\n');

  const hostedHref = `${troveHost}?team=${teamId}&trove=${troveKey}`;

  return (
    <NarrowColumn>
      <PageHeader headline="Embed" description="Copy and paste the following code to embed your form on your site" />
      <Divider />

      <Column sx={{ marginTop: '20px', paddingTop: '20px' }}>
        <Typography variant="headline4">Simple embed</Typography>
        <Typography variant="bodySmall" sx={{ color: DARK_GREY, marginTop: '10px' }}>
          Specify the data-trove-survey attribute on the script element.
        </Typography>
        <Column sx={{ position: 'relative', margin: '20px 0' }}>
          <SyntaxHighlighter
            showLineNumbers
            language="html"
            customStyle={{
              fontSize: '13px',
              backgroundColor: LIGHT_GREY,
              margin: 0,
              borderRadius: '5px',
            }}
          >
            {simpleEmbed}
          </SyntaxHighlighter>
          <IconButton
            onClick={() => handleCopy(simpleEmbed)}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <SvgIcon viewBox="0 0 20 20" component={CopyClipboardIcon} sx={{ fill: 'none' }} />
          </IconButton>
        </Column>

        <Typography variant="bodySmall" sx={{ color: DARK_GREY, marginTop: '10px' }}>
          Specify the data-trove-email attribute to auto-include the user's email.
        </Typography>
        <Column sx={{ position: 'relative', margin: '20px 0' }}>
          <SyntaxHighlighter
            showLineNumbers
            language="html"
            customStyle={{
              fontSize: '13px',
              backgroundColor: LIGHT_GREY,
              margin: 0,
              borderRadius: '5px',
            }}
          >
            {withEmail}
          </SyntaxHighlighter>
          <IconButton
            onClick={() => handleCopy(withEmail)}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <SvgIcon viewBox="0 0 20 20" component={CopyClipboardIcon} sx={{ fill: 'none' }} />
          </IconButton>
        </Column>
      </Column>

      <Column sx={{ marginTop: '20px', paddingTop: '20px' }}>
        <Typography variant="headline4">Button trigger</Typography>
        <Typography variant="bodySmall" sx={{ color: DARK_GREY, marginTop: '10px' }}>
          You can trigger the form to popup when a button is clicked by using triggers or by specifying the
          data-trove-survey attribute within the button's html.
        </Typography>
        <Column sx={{ position: 'relative', margin: '20px 0' }}>
          <SyntaxHighlighter
            showLineNumbers
            language="html"
            customStyle={{
              fontSize: '13px',
              backgroundColor: LIGHT_GREY,
              margin: 0,
              borderRadius: '5px',
            }}
          >
            {buttonEmbed}
          </SyntaxHighlighter>
          <IconButton
            onClick={() => handleCopy(buttonEmbed)}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <SvgIcon viewBox="0 0 20 20" component={CopyClipboardIcon} sx={{ fill: 'none' }} />
          </IconButton>
        </Column>

        <Typography variant="bodySmall" sx={{ color: DARK_GREY, marginTop: '10px' }}>
          If using a click trigger, this form will automatically be opened when the targeted button is clicked.
        </Typography>
        <Column sx={{ position: 'relative', margin: '20px 0' }}>
          <SyntaxHighlighter
            showLineNumbers
            language="html"
            customStyle={{
              fontSize: '13px',
              backgroundColor: LIGHT_GREY,
              margin: 0,
              borderRadius: '5px',
            }}
          >
            {buttonWithTrigger}
          </SyntaxHighlighter>
          <IconButton
            onClick={() => handleCopy(buttonWithTrigger)}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <SvgIcon viewBox="0 0 20 20" component={CopyClipboardIcon} sx={{ fill: 'none' }} />
          </IconButton>
        </Column>
      </Column>

      <Column sx={{ marginTop: '20px', paddingTop: '20px' }}>
        <Typography variant="headline4">Inline embed</Typography>
        <Typography variant="bodySmall" sx={{ color: DARK_GREY, marginTop: '10px' }}>
          Specify the data-trove-inline and data-trove-survey attributes on an HTML Element to embed the form directly
          into your HTML.
        </Typography>
        <Column sx={{ position: 'relative', margin: '20px 0' }}>
          <SyntaxHighlighter
            showLineNumbers
            language="html"
            customStyle={{
              fontSize: '13px',
              backgroundColor: LIGHT_GREY,
              margin: 0,
              borderRadius: '5px',
            }}
          >
            {inlineEmbed}
          </SyntaxHighlighter>
          <IconButton
            onClick={() => handleCopy(inlineEmbed)}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <SvgIcon viewBox="0 0 20 20" component={CopyClipboardIcon} sx={{ fill: 'none' }} />
          </IconButton>
        </Column>

        <Typography variant="bodySmall" sx={{ color: DARK_GREY, marginTop: '10px' }}>
          Specify the data-trove-email attribute to auto-include the user's email.
        </Typography>
        <Column sx={{ position: 'relative', margin: '20px 0' }}>
          <SyntaxHighlighter
            showLineNumbers
            language="html"
            customStyle={{
              fontSize: '13px',
              backgroundColor: LIGHT_GREY,
              margin: 0,
              borderRadius: '5px',
            }}
          >
            {inlineWithEmail}
          </SyntaxHighlighter>
          <IconButton
            onClick={() => handleCopy(inlineWithEmail)}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <SvgIcon viewBox="0 0 20 20" component={CopyClipboardIcon} sx={{ fill: 'none' }} />
          </IconButton>
        </Column>
      </Column>

      <Column sx={{ marginTop: '20px', paddingTop: '20px' }}>
        <Typography variant="headline4">Hosted form</Typography>
        <Typography variant="bodySmall" sx={{ color: DARK_GREY, marginTop: '10px' }}>
          {`Use this `}
          <Link href={hostedHref} target="_blank" rel="noopener noreferrer">
            link
          </Link>
          {` to send users to a hosted version of your form.`}
        </Typography>
        <Column sx={{ position: 'relative', margin: '20px 0' }}>
          <Row
            sx={{
              fontSize: '13px',
              backgroundColor: LIGHT_GREY,
              margin: 0,
              borderRadius: '5px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="bodySmaller"
              sx={{
                padding: '15px',
              }}
            >
              {hostedHref}
            </Typography>
            <IconButton onClick={() => handleCopy(hostedHref)}>
              <SvgIcon viewBox="0 0 20 20" component={CopyClipboardIcon} sx={{ fill: 'none' }} />
            </IconButton>
          </Row>
        </Column>
      </Column>

      {form.formProps.settings.questions.length > 0 && (
        <>
          <Divider sx={{ marginTop: theme => theme.spacing(4) }} />
        </>
      )}
    </NarrowColumn>
  );
});

import { BillingPlanId, MemberRoles } from '@caravel/types';
import { logEvent as analyticsLogEvent, setUserProperties } from 'firebase/analytics';
import { getAnalytics } from 'helpers';

// https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
// Event names follow "Object-Action". ex "Project Created", "User Signed Up", "Sample Deleted"
// Event properties use snake_case
export type EventNames =
  // when a new automation is created
  | 'Automation Created'
  // when a new connection is created
  | 'Connection Created'
  // When a new feed is created
  | 'Feed Created'
  // When an existing feed is deleted
  | 'Feed Deleted'
  // When someone has been sent an email to join a team
  | 'Member Invited'
  // When someone adds a sample through the note form
  | 'Note Added'
  // When someone imports classifiers via CSV
  | 'Classifiers Imported'
  // When a new classifier is created
  | 'Classifier Created'
  // When an existing classifier is deleted
  | 'Classifier Deleted'
  // When a classifier is updated (name)
  | 'Classifier Updated'
  // When a new source is created
  | 'Source Created'
  // When a new team is created
  | 'Team Created'
  // When a training text is added to a classifier
  | 'Training Text Added'
  // When an existing community event is deleted
  | 'Event Deleted';

export const logEvent = (eventName: EventNames, eventParams?: { [key: string]: any }) => {
  const analytics = getAnalytics();
  analyticsLogEvent(analytics, eventName, eventParams);
};

export interface OnboardingProperties {}

export interface CaravelUserProperties extends OnboardingProperties {
  name?: string;
  email?: string;
  role?: MemberRoles;
  created_at?: number;
  team_id?: string;
  team_name?: string;
  plan_id?: BillingPlanId;
}

export const updateUserProperties = (userProps: Partial<CaravelUserProperties>) => {
  const analytics = getAnalytics();
  setUserProperties(analytics, userProps);
};

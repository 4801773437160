import { AddButton, Column, FilterMenuItem, MenuItem, Row, Select } from '@caravel/components/src';
import {
  SegmentInterConditionOperator,
  SegmentInterRuleOperator,
  SegmentRuleCondition,
  SegmentRuleConditionField,
  SegmentRuleConditionOperator,
} from '@caravel/types';
import {
  createGQLClient,
  getFilterQuery,
  GqlPeopleFacetRequestType,
  GqlPeopleFacetResponseType,
  GREY_PALETTE,
} from '@caravel/utils';
import { Button, Divider, IconButton, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { TrashCanIcon, XIcon } from '../segments-icons';
import { ConditionFieldSelect } from './condition-field-select';
export const SegmentRule = observer((props: { ruleIndex: number; handleRemoveRule: (index: number) => void }) => {
  const store = useStore();
  const {
    ui: { SegmentForm },
  } = store;

  const { ruleIndex, handleRemoveRule } = props;
  const rules = SegmentForm.formProps?.segmentRules;
  const rule = rules?.[ruleIndex];
  if (!rules || !rule) return null;

  const setInterRuleOperator = (operator: SegmentInterRuleOperator) => {
    SegmentForm.onRuleChange(
      {
        interRuleOperator: operator,
        interConditionOperator: operator === 'and' ? 'all' : rule.interConditionOperator,
      },
      ruleIndex,
    );
  };

  const setInterConditionOperator = (operator: SegmentInterConditionOperator) => {
    SegmentForm.onRuleChange({ interConditionOperator: operator }, ruleIndex);
  };

  const handleAddCondition = () => {
    SegmentForm.addCondition(ruleIndex);
  };

  const handleRemoveCondition = (index: number) => {
    SegmentForm.removeCondition(ruleIndex, index);
  };

  const handleOnConditionChange = (condition: Partial<SegmentRuleCondition>, conditionIndex: number) => {
    // if new type != current type, change operator to 1st of new type
    SegmentForm.onConditionChange(condition, ruleIndex, conditionIndex);
  };

  const operatorSelectSx = {
    padding: '0px',
    marginRight: '10px',
  };

  return (
    <Column sx={{ background: GREY_PALETTE[1], border: 0, padding: '10px', margin: '0 0 20px 0' }}>
      <Row alignItems="center" justifyContent="space-between" margin="5px">
        <Column sx={{ ...operatorSelectSx, width: '150px' }}>
          <Select
            value={rule.interRuleOperator}
            onChange={e => {
              setInterRuleOperator(e.target.value as SegmentInterRuleOperator);
            }}
          >
            {displayOptions(interRuleOperatorOptions)}
          </Select>
        </Column>

        {rule.interRuleOperator === 'or' && (
          <Column sx={{ ...operatorSelectSx, width: '150px' }}>
            <Select
              value={rule.interConditionOperator}
              onChange={e => {
                setInterConditionOperator(e.target.value as SegmentInterConditionOperator);
              }}
            >
              {displayOptions(interConditionOperatorOptions)}
            </Select>
          </Column>
        )}

        <Typography flex={1} sx={{ fontSize: '14px' }}>
          of the following conditions
        </Typography>

        <Button
          variant="outlined"
          color="error"
          sx={{
            padding: '5px',
            minWidth: '20px',
          }}
          onClick={() => handleRemoveRule(ruleIndex)}
        >
          <TrashCanIcon />
        </Button>
      </Row>

      {rule.conditions.map((condition, conditionIndex) => {
        const isMultiple =
          condition.operator === 'any-of' ||
          condition.operator === 'not-any-of' ||
          condition.operator === 'includes-any-of' ||
          condition.operator === 'includes-all-of' ||
          condition.operator === 'includes-none-of';
        return (
          <Row key={conditionIndex} alignItems="center" justifyContent="space-between" margin="5px">
            <Typography sx={{ width: '60px', fontSize: '14px', marginRight: '10px', textAlign: 'right' }}>
              {conditionIndex === 0 ? 'WHERE' : rule.interConditionOperator === 'all' ? 'AND' : 'OR'}
            </Typography>

            <Column sx={{ ...operatorSelectSx, width: '200px', fontSize: '50px' }}>
              <Select
                SelectDisplayProps={{
                  style: { fontSize: '13px' },
                }}
                MenuProps={{ style: { maxHeight: '60vh' } }}
                value={condition.field}
                onChange={e => {
                  const field = e.target.value as SegmentRuleConditionField;
                  const type = fieldOptionType[field];
                  const currentType = fieldOptionType[condition.field];
                  const operator: SegmentRuleConditionOperator =
                    type !== currentType
                      ? conditionOperatorOptions[type as keyof ConditionOperatorOptions][0].value
                      : condition.operator;
                  handleOnConditionChange({ field, operator, values: defaultValues }, conditionIndex);
                }}
              >
                {displayOptions(fieldOptions)}
              </Select>
            </Column>

            <Column sx={{ width: '175px', marginRight: '10px' }}>
              <Select
                SelectDisplayProps={{
                  style: { fontSize: '13px' },
                }}
                value={condition.operator}
                onChange={e => {
                  const operator = e.target.value as SegmentRuleConditionOperator;
                  const values =
                    isMultiple && (operator === 'is' || operator === 'not') ? defaultValues : condition.values;
                  handleOnConditionChange({ operator, values }, conditionIndex);
                }}
              >
                {displayOptions(
                  conditionOperatorOptions[fieldOptionType[condition.field] as keyof ConditionOperatorOptions],
                )}
              </Select>
            </Column>

            {fieldOptionType[condition.field] === 'number' ? (
              <TextField
                sx={{ flex: 1, marginRight: '10px' }}
                inputProps={{
                  sx: {
                    fontSize: '13px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  },
                }}
                type="number"
                value={condition.values}
                onChange={e => handleOnConditionChange({ values: [e.target.value] }, conditionIndex)}
              />
            ) : (
              <ConditionFieldSelect
                condition={condition}
                handleOnConditionChange={handleOnConditionChange}
                conditionIndex={conditionIndex}
              />
            )}

            <IconButton sx={{ marginLeft: '10px' }} onClick={() => handleRemoveCondition(conditionIndex)}>
              <XIcon />
            </IconButton>
          </Row>
        );
      })}
      <Row alignItems="center" justifyContent="space-between" margin="5px">
        <AddButton onClick={handleAddCondition}>Add Condition</AddButton>
      </Row>
    </Column>
  );
});

type Option = {
  value: string;
  display: string;
  divider?: boolean;
  // condition: SegmentRuleConditionOperator[];
  // values?: string[]
};

const defaultValues: string[] = [];

const displayOptions = (options: Option[]) => {
  return options.reduce((acc: JSX.Element[], { value, display, divider }) => {
    acc.push(
      <MenuItem key={value} value={value}>
        <Typography variant="bodySmaller">{display}</Typography>
      </MenuItem>,
    );
    if (divider) acc.push(<Divider key={`divider-${display}`} />);
    return acc;
  }, []);
};

const interRuleOperatorOptions: Option[] = [
  { value: 'and', display: 'All' },
  { value: 'or', display: 'Or' },
];

const interConditionOperatorOptions: Option[] = [
  { value: 'all', display: 'All' },
  { value: 'any', display: 'Any' },
];

const fieldOptions: Option[] = [
  { value: 'layer', display: 'Layer' },
  { value: 'source', display: 'Source' },
  { value: 'seniority', display: 'Seniority' },
  { value: 'organization', display: 'Organization' },
  { value: 'title', display: 'Title' },
  { value: 'grid-status', display: 'Grid Status', divider: true },
  { value: 'first-active', display: 'First Active' },
  { value: 'last-active', display: 'Last Active', divider: true },
  // { value: 'signup-date', display: 'Signed Up', divider: true },
  { value: 'city', display: 'City' },
  { value: 'country', display: 'Country' },
  { value: 'frequency', display: 'Frequency' },
  { value: 'consistency', display: 'Consistency' },
  { value: 'impact', display: 'Impact', divider: true },
  { value: 'organization-kind', display: 'Organization: Kind' },
  { value: 'organization-industry', display: 'Organization: Industry' },
  { value: 'organization-employee-count', display: 'Organization: Employee Count' },
  { value: 'organization-employee-range', display: 'Organization: Employee Range' },
  { value: 'organization-annual-revenue', display: 'Organization: Annual Revenue' },
  { value: 'organization-estimated-annual-revenue', display: 'Organization: Estimated Annual Revenue' },
  { value: 'organization-funds-raised', display: 'Organization: Funds Raised' },
  { value: 'organization-twitter-followers', display: 'Organization: Twitter Followers' },
  { value: 'organization-founded-year', display: 'Organization: Founded Year', divider: true },
  { value: 'organization-city', display: 'Organization: City' },
  { value: 'organization-state', display: 'Organization: State' },
  { value: 'organization-country', display: 'Organization: Country' },
];

export const fieldOptionType = {
  layer: 'scalar',
  source: 'seq',
  seniority: 'scalar',
  organization: 'scalar',
  title: 'scalar',
  'grid-status': 'scalar',
  'last-active': 'timestamp',
  'first-active': 'timestamp',
  'signup-date': 'timestamp',
  country: 'scalar',
  city: 'scalar',
  frequency: 'scalar',
  consistency: 'scalar',
  impact: 'scalar',
  'organization-kind': 'scalar',
  'organization-industry': 'scalar',
  'organization-founded-year': 'year',
  'organization-funds-raised': 'number',
  'organization-employee-range': 'scalar',
  'organization-employee-count': 'number',
  'organization-annual-revenue': 'number',
  'organization-estimated-annual-revenue': 'scalar',
  'organization-twitter-followers': 'number',
  'organization-city': 'scalar',
  'organization-state': 'scalar',
  'organization-country': 'scalar',
};

type ConditionOperatorOptions = {
  seq: {
    value: SegmentRuleConditionOperator;
    display: string;
  }[];
  scalar: {
    value: SegmentRuleConditionOperator;
    display: string;
  }[];
  timestamp: {
    value: SegmentRuleConditionOperator;
    display: string;
  }[];
  number: {
    value: SegmentRuleConditionOperator;
    display: string;
  }[];
  year: {
    value: SegmentRuleConditionOperator;
    display: string;
  }[];
};

const conditionOperatorOptions: ConditionOperatorOptions = {
  seq: [
    { value: 'includes-all-of', display: 'Includes all of' },
    { value: 'includes-any-of', display: 'Includes any of' },
    { value: 'includes-none-of', display: 'Includes none of' },
  ],
  scalar: [
    { value: 'is', display: 'Is' },
    { value: 'not', display: 'Is not' },
    { value: 'any-of', display: 'Is any of' },
    { value: 'not-any-of', display: 'Is not any of' },
  ],
  timestamp: [
    { value: 'after', display: 'After' },
    { value: 'before', display: 'Before' },
  ],
  number: [
    { value: 'is', display: 'Is' },
    // { value: 'any-of', display: 'Is any of' },
    { value: 'greater-than', display: 'Is greater than' },
    { value: 'less-than', display: 'Is less than' },
  ],
  year: [
    { value: 'is', display: 'Is' },
    { value: 'any-of', display: 'Is any of' },
    { value: 'greater-than', display: 'Is greater than' },
    { value: 'less-than', display: 'Is less than' },
  ],
};

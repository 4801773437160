import { DINO_STANDING_TWO_LEGS } from '@caravel/utils';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { RootStore } from './root';

const storeContext = React.createContext<RootStore | null>(null);
const store = new RootStore();

if (process.env.NODE_ENV === 'development') {
  (window as any).store = store;
}

export const StoreProvider = observer(
  class StoreProvider extends Component<{ children?: any }> {
    componentDidMount() {
      store.initialize();
      if (process.env.NODE_ENV !== 'development') {
        console.info(DINO_STANDING_TWO_LEGS);
      }
    }

    componentWillUnmount() {
      store.teardown();
    }

    render() {
      const { children } = this.props;
      // Don't even bother providing the store if it is not ready for use. If this proves a bit heavy-handed, each store
      // provides an `isInitialized` property.
      return store.isInitialized ? <storeContext.Provider value={store}>{children}</storeContext.Provider> : null;
    }
  },
);

/**
 * The root store hook
 */
export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return store;
};

import { ProofSourceType, ProofTag } from '@caravel/types/src';
import {
  createGQLClient,
  GET_PROOF_COLLECTION,
  GqlProofCollectionResponseType,
  GqlRetractProofRequestType,
  GqlRetractProofResponseType,
  RETRACT_PROOF,
} from '@caravel/utils';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Person } from 'models/person';

import { BaseStore } from './base';
import { RootStore } from './root';

/**
 * Because we use the models Person type, I can't just use the Proof type from the types package.
 * This feels clumsy, but I'll resolve later
 *
 * Also note that the intention is to enrich off of the Customer Name in the future, but for now
 * we'll allow for manual entry of customer info
 *
 * THIS DOES NOT CREATE A CHAMPION IN THE SYSTEM YET!!
 */
export interface Proof {
  id?: string;
  champion?: Partial<Person>;
  sourceType?: ProofSourceType;
  sourceLink?: string;
  content?: string;
  dateCreated?: string;
  proofTags?: ProofTag[];
  //Temp fields
  customerName?: string;
  employmentTitle?: string;
  organizationName?: string;
  email?: string;
}

export interface AuthorInfo {
  customerName?: string;
  employmentTitle?: string;
  organizationName?: string;
  orgIcon?: string;
  icon?: string;
}

const host = process.env.GRAPHQL_HOST!;
/**
 * Store which manages fetching and providing proof data
 */
export class ProofLibraryStore extends BaseStore {
  collection = observable.array<Proof>([]);
  selected = observable.set<string>([]);
  proofsLoading = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  async setup() {
    await super.setup();

    makeObservable(this, {
      collection: observable,
      selected: observable,
      proofsLoading: observable,
      getCustomerInfo: computed,
    });
  }

  teardown() {
    this.collection.clear();
    super.teardown();
  }

  resetProofCollection = () => {
    runInAction(() => {
      this.collection.clear();
      this.proofsLoading = false;
    });
  };

  refreshChampions = async () => {
    await this.fetchProofCollection();
  };

  getDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  getOrganization = (champion: Partial<Person>) => {
    if (!champion.primaryOrganization && !champion.organizations) {
      return undefined;
    }
    if (champion.primaryOrganization) {
      return champion.primaryOrganization;
    }
    return champion.organizations![champion.organizations!.length - 1];
  };

  getCustomerInfo = (proof: Proof): AuthorInfo => {
    if (proof.champion) {
      const org = this.getOrganization(proof.champion);
      return {
        customerName: proof.champion.givenName + ' ' + proof.champion.familyName,
        employmentTitle: proof.champion.employmentTitle,
        organizationName: org?.name,
        orgIcon: org ? `https://logo.clearbit.com/${org.domain}` : undefined,
        icon: proof.champion.avatar,
      };
    } else {
      return {
        customerName: proof.customerName,
        employmentTitle: proof.employmentTitle,
        organizationName: proof.organizationName,
        orgIcon: undefined,
        icon: undefined,
      };
    }
  };

  fetchProofCollection = async () => {
    if (this.proofsLoading) {
      console.debug('Proof Library already loading');
      return;
    }
    runInAction(() => (this.proofsLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      const response = await graphqlClient.query<Record<string, string>, GqlProofCollectionResponseType>(
        GET_PROOF_COLLECTION,
        {},
      );
      const proofs: Proof[] = response.community.proof.map(proof => ({
        ...proof,
        champion: proof.champion ? (proof.champion as Person) : undefined,
      }));
      runInAction(() => {
        this.collection.replace(proofs);
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to fetch proof',
        duration: 5000,
      });
    } finally {
      runInAction(() => {
        this.proofsLoading = false;
      });
    }
  };

  isSelected = (proofId: string) => {
    return this.selected.has(proofId);
  };

  selectProof = (proofId: string) => {
    const proof = this.collection.find(proof => proof.id === proofId);
    if (!proof) {
      return;
    }
    runInAction(() => {
      this.selected.replace(this.selected.add(proofId));
    });
  };

  deselectProof = (proofId: string) => {
    runInAction(() => {
      this.selected.delete(proofId);
    });
  };

  retractProof = async (proofId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const clientMutationId = `retract-proof-${teamId}-${Date.now()}`;
    try {
      const response = await graphqlClient.mutate<GqlRetractProofRequestType, GqlRetractProofResponseType>(
        RETRACT_PROOF,
        {
          clientMutationId,
          id: proofId ?? undefined,
        },
      );
      console.info(response);
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to delete proof');
    }
  };

  selectAll = () => {
    runInAction(() => {
      this.selected.replace(this.collection.map(proof => proof.id!));
    });
  };

  clearSelected = () => {
    runInAction(() => {
      this.selected.clear();
    });
  };
}

import { GREY_PALETTE } from '@caravel/utils/src';
import { Button, Divider, InputAdornment, ListItemText, Menu, MenuItem, TextField } from '@mui/material';
import { Person } from 'models/person';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStore } from 'stores';

import { NewSegmentModal } from '../new-segment-modal';
import { AddIcon, SearchIcon } from '../segments-icons';

type Dispatcher<S> = Dispatch<SetStateAction<S>>;

type Origin = { horizontal: 'center' | 'left' | 'right' | number; vertical: 'bottom' | 'center' | 'top' | number };
interface MenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatcher<HTMLElement | null>;
  people: Person[];
  anchorOrigin?: Origin;
  transformOrigin?: Origin;
}

export const AddToSegmentPopoverMenu = (props: MenuProps) => {
  const { anchorEl, setAnchorEl, people, anchorOrigin, transformOrigin } = props;

  const store = useStore();
  const {
    ui: { SegmentForm },
    teams: { segments },
  } = store;

  useEffect(() => {
    if (!segments.collection.length) {
      segments.fetchSegments();
    }
  }, [segments]);

  const [searchValue, setSearchValue] = useState<string>('');
  const collection = segments.collection.slice().filter(n => n.kind === ':segment.kind/static');

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSearchValue('');
  };

  const handleAddPeopleToSegment = async (segmentId: string) => {
    await store.ui.workOn(async () => {
      SegmentForm.editSegment(segmentId);
      if (SegmentForm.formProps!.people) {
        SegmentForm.onChange({
          people: [...SegmentForm.formProps!.people.slice(), ...people],
        });
      }

      const success = await SegmentForm.updateSegment();
      if (success) {
        await segments.fetchSegments();
        SegmentForm.resetFormProps();
        store.teams.people.deselectAll();
        handleCloseMenu();
      }
    });
  };

  const handleOpenNewSegmentModal = async () => {
    await store.ui.workOn(async () => {
      SegmentForm.newStaticSegment();

      SegmentForm.onChange({
        name: searchValue,
        people,
      });

      SegmentForm.openModal();
    });
  };

  return (
    <>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin ?? {
            vertical: 'top',
            horizontal: 'right',
          }
        }
        transformOrigin={
          transformOrigin ?? {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        PaperProps={{
          sx: {
            minWidth: '400px',
          },
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0',
            background: GREY_PALETTE[1],
            boxShadow: '0px 4px 8px rgb(35 31 32 / 12%)',
          },
          '& .MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: GREY_PALETTE[2],
            },
            padding: '8px 8px',
            '& .MuiListItemIcon-root': {
              minWidth: '30px',
            },
          },
          '& .MuiMenuItem-divider': {
            borderColor: GREY_PALETTE[2],
          },
          '& .MuiTypography-root': {
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '16px',
          },
        }}
      >
        <TextField
          onKeyDown={e => e.stopPropagation()}
          fullWidth
          variant="standard"
          placeholder="Find or create segment..."
          value={searchValue}
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: '13px',
              padding: '0 4px 4px 4px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          required
          onChange={e => setSearchValue(e.target.value)}
        />
        <Divider />
        {collection
          .filter(row => filterOptions(searchValue, row.name))
          .map((row, index) => (
            <MenuItem
              key={index}
              divider={false}
              onClick={() => handleAddPeopleToSegment(row.id)}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <ListItemText primary={row.name} />
            </MenuItem>
          ))}
        {searchValue && <Divider />}
        {searchValue && (
          <Button
            sx={{
              background: '#EFEDFF',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              margin: '5px 5px 0 5px',
              width: 'calc(100% - 10px)',
            }}
            startIcon={<AddIcon />}
            onClick={handleOpenNewSegmentModal}
          >
            Create Segment: "{searchValue}"
          </Button>
        )}
      </Menu>
      <NewSegmentModal />
    </>
  );
};

const filterOptions = (searchValue: string, option: string) => {
  return option.toLowerCase().replace(/\s+/g, '').includes(searchValue.toLowerCase().replace(/\s+/g, ''));
};

import { getThumbnail, GREY_PALETTE } from '@caravel/utils';
import { Box, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores';
import { Proof } from 'stores/proof-library';

import { ReactComponent as VideoIcon } from '../../assets/svgs/video-camera.svg';

export const ProofThumbnail = (props: { proof: Proof }) => {
  const { proof } = props;
  const store = useStore();
  const ui = store.ui;
  const [thumbnail, setThumbnail] = useState<string | undefined>('');

  useEffect(() => {
    ui.workOn(async () => {
      if (proof.sourceType !== 'VIDEO' || !proof.sourceLink) {
        return '';
      }

      const thumbnail = await getThumbnail(proof.sourceLink);
      setThumbnail(thumbnail);
    });
  }, [proof, ui]);

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '4px',
        height: '54px',
        width: '74px',
        background: GREY_PALETTE[3],
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {thumbnail && <img src={thumbnail} style={{ borderRadius: '4px', height: '54px', width: '74px' }} />}
      {!thumbnail && <VideoIcon style={{ height: '30px', width: '30px', color: GREY_PALETTE[2] }} />}
    </Box>
  );
};

import { ERROR_PALETTE, GREY_PALETTE, WARM_SHADES_PALETTE } from '@caravel/utils/src';
import { Box, SvgIcon } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import ImageUploading from 'react-images-uploading';

import { Column, Row } from '../flex';
import { CaravelSpinner } from '../loaders';

type Dispatcher<S> = Dispatch<SetStateAction<S>>;

const UploadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V16M4.75 16V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V12.25L16.5856 9.43947C15.7663 8.48581 14.2815 8.51598 13.5013 9.50017L13.4914 9.51294C13.3977 9.63414 11.9621 11.4909 10.9257 12.8094M4.75 16L7.49619 12.5067C8.2749 11.5161 9.76453 11.4837 10.5856 12.4395L10.9257 12.8094M10.9257 12.8094L12.25 14.25M10.9257 12.8094C10.9221 12.814 10.9186 12.8185 10.915 12.823"
      stroke="#8B99B5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17 14.75V19.25" stroke="#8B99B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.25 17L14.75 17" stroke="#8B99B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CancelIcon = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 4.5L4.5 11.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 4.5L11.5 11.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export interface ImageUploaderWithPreviewProps {
  image: any[];
  setImage: Dispatcher<any[]>;
  loading: boolean;
  onDelete: () => void;
  existingImageUrl: string | undefined;
  setExistingImageUrl: Dispatcher<string | undefined>;
  circle?: boolean;
  size?: 'small' | 'large';
}

export const ImageUploaderWithPreview = (props: ImageUploaderWithPreviewProps) => {
  const {
    image = [],
    setImage,
    loading = false,
    onDelete,
    existingImageUrl,
    setExistingImageUrl,
    circle = false,
    size = 'large',
  } = props;
  const componentSize = size === 'large' ? '100px' : '64px';

  const onImageChange = (image: any) => {
    setImage(image);
  };

  return (
    <ImageUploading value={image} onChange={onImageChange} dataURLKey="data_url" maxNumber={1}>
      {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
        <Row sx={{ width: circle ? componentSize : '100%' }}>
          <Column sx={{ width: circle ? componentSize : '100%' }}>
            {loading ? (
              <Box
                sx={{
                  height: componentSize,
                  width: circle ? componentSize : '100%',
                  borderRadius: circle ? '50%' : '4px',
                  backgroundColor: WARM_SHADES_PALETTE[0],
                  border: `1px dashed ${GREY_PALETTE[4]}`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CaravelSpinner />
              </Box>
            ) : !image[0] && !existingImageUrl ? (
              <button
                style={{
                  height: componentSize,
                  width: circle ? componentSize : '100%',
                  borderRadius: circle ? '50%' : '4px',
                  backgroundColor: WARM_SHADES_PALETTE[0],
                  border: `1px dashed ${GREY_PALETTE[4]}`,
                  cursor: 'pointer',
                }}
                onClick={onImageUpload}
                {...dragProps}
              >
                <SvgIcon component={UploadIcon} sx={{ fill: 'none' }} />
              </button>
            ) : existingImageUrl && !image[0] ? (
              <Row>
                <div
                  style={{
                    height: componentSize,
                    width: circle ? componentSize : '100%',
                    borderRadius: circle ? '50%' : '4px',
                    backgroundColor: WARM_SHADES_PALETTE[0],
                    border: `1px dashed ${GREY_PALETTE[4]}`,
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={existingImageUrl}
                    alt={'uploaded image'}
                    style={{
                      height: `calc(${componentSize} - 2px)`,
                      width: circle ? `calc(${componentSize} - 2px)` : '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end',
                    margin: `${circle ? '0px' : '7px'} 0 0 ${circle ? '-20px' : '-12px'}`,
                  }}
                >
                  <button
                    style={{
                      height: '24px',
                      width: '24px',
                      padding: '4px',
                      backgroundColor: ERROR_PALETTE['200'],
                      borderRadius: '50%',
                      border: 'none',
                      zIndex: '1000',
                      marginRight: circle ? '-20px' : '-12px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      onDelete();
                      setExistingImageUrl(undefined);
                    }}
                  >
                    <SvgIcon component={CancelIcon} sx={{ fill: 'none' }} />
                  </button>
                </div>
              </Row>
            ) : (
              imageList.map((image, index) => (
                <Row key={index}>
                  <div
                    style={{
                      height: componentSize,
                      width: circle ? componentSize : '100%',
                      borderRadius: circle ? '50%' : '4px',
                      backgroundColor: WARM_SHADES_PALETTE[0],
                      border: `1px dashed ${GREY_PALETTE[4]}`,
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={image['data_url']}
                      alt={'uploaded image'}
                      style={{
                        height: `calc(${componentSize} - 2px)`,
                        width: circle ? `calc(${componentSize} - 2px)` : '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'end',
                      margin: `${circle ? '0px' : '7px'} 0 0 ${circle ? '-20px' : '-12px'}`,
                    }}
                  >
                    <button
                      style={{
                        height: '24px',
                        width: '24px',
                        padding: '4px',
                        cursor: 'pointer',
                        backgroundColor: ERROR_PALETTE['200'],
                        borderRadius: '50%',
                        border: 'none',
                        zIndex: '1000',
                        marginRight: circle ? '-20px' : '-12px',
                      }}
                      onClick={() => {
                        onImageRemove(index);
                        onDelete();
                      }}
                    >
                      <SvgIcon component={CancelIcon} sx={{ fill: 'none' }} />
                    </button>
                  </div>
                </Row>
              ))
            )}
          </Column>
        </Row>
      )}
    </ImageUploading>
  );
};

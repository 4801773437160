import chunk from 'lodash/chunk';

/**
 * Wait for all promises to resolve in any order
 * @param promises
 * @returns T
 */
export async function inParallel<T = any>(promises: Promise<T>[]): Promise<T[]> {
  return await Promise.all(promises);
}

/**
 * Chunk promises into smaller groups and process concurrently
 * @param promises
 * @param options
 * @returns
 */
export async function concurrently<T = any>(
  promises: (() => Promise<T>)[],
  options: {
    chunkSize?: number;
    onChunkEnd?: (info: { duration: number; chunk: number; count: number; chunkSize: number }) => void;
  } = {},
): Promise<{ values: T[]; errors: any[] }> {
  const { chunkSize = 10, onChunkEnd } = options;
  const chunks = chunk(promises, chunkSize);
  let values: T[] = [];
  let errors: Error[] = [];
  let i = 0;
  for (const group of chunks) {
    i += 1;
    const start = Date.now();
    const all = await Promise.allSettled(group.map(p => p()));
    values = [...values, ...all.filter(result => result.status === 'fulfilled').map(result => (result as any).value)];
    errors = [...errors, ...all.filter(result => result.status === 'rejected').map(result => (result as any).reason)];
    if (onChunkEnd) {
      onChunkEnd({
        duration: Date.now() - start,
        chunk: i,
        count: chunks.length,
        chunkSize: group.length,
      });
    }
  }
  return {
    values,
    errors,
  };
}

/**
 * Wait for a specified duration
 * @param ms sleep duration in milliseconds
 * @returns
 */
export function sleepAsync(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Throw an error if a promise takes longer than the timeout to resolve
 * @param promise
 * @param timeout
 * @returns
 */
export function timeoutAfter<T = void>(promise: Promise<T>, timeout: number) {
  let timer: NodeJS.Timeout;
  return Promise.race([
    new Promise((resolve, reject) => {
      timer = setTimeout(reject, timeout);
      return timer;
    }),
    promise.then(value => {
      clearTimeout(timer);
      return value;
    }),
  ]);
}

/**
 * Wraps a synchronous function in a Promise
 * @param fn The synchronous function to wrap
 * @returns The function wrapped in a promise
 */
export const AsyncWrapper = (fn: any) =>
  function asyncWrap(...args: any[]): Promise<any> {
    const fnReturn = fn(...args);
    const next = args[args.length - 1];
    return Promise.resolve(fnReturn).catch(next);
  };

import { Channel, ChannelKind, ChannelStatus } from '@caravel/types/src';
import { GINGER, NEUTRALS, parseDate, ROOBIOS, SENCHA, upperFirst } from '@caravel/utils/src';
import { Avatar, Card, CardProps, SvgIcon, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { forwardRef, ReactNode } from 'react';

import { Row } from '../flex';
import { ReactComponent as GoogleIcon } from './icons/google.svg';
import { ReactComponent as SlackIcon } from './icons/slack.svg';

export const ChannelIcons: Record<ChannelKind, ReactNode> = {
  ':slack': <SlackIcon />,
  ':zendesk': null,
  ':api': null,
  ':activecampaign': null,
  ':circle': null,
  ':clearbit': null,
  ':csv-import': null,
  ':discourse': null,
  ':drip': null,
  ':email': <GoogleIcon />,
  ':events': null,
  ':github': null,
  ':github-public': null,
  ':hubspot': null,
  ':insided': null,
  ':mailchimp': null,
  ':manual': null,
  ':override': null,
  ':pipedrive': null,
  ':signup': null,
  ':twitter': null,
  ':vanilla': null,
  ':zapier': null,
  ':zoom': null,
};

const statusColors: Record<ChannelStatus, string> = {
  ':channel.status/archived': NEUTRALS.EARL_GREY,
  ':channel.status/errored': ROOBIOS[100],
  ':channel.status/initializing': GINGER[100],
  ':channel.status/ok': SENCHA[200],
  ':channel.status/paused': GINGER[100],
  ':channel.status/syncing': SENCHA[200],
};

const statusText: Record<ChannelStatus, string> = {
  ':channel.status/archived': 'Archived',
  ':channel.status/errored': 'Errored',
  ':channel.status/initializing': 'Initializing',
  ':channel.status/ok': 'Connected',
  ':channel.status/paused': 'Paused',
  ':channel.status/syncing': 'Syncing',
};

const StatusChip = ({ status }: { status: null | Channel['status'] }) => {
  const text = status ? statusText[status] : 'Connected';
  const backgroundColor = status ? statusColors[status] : SENCHA[200];
  return (
    <Typography
      variant="chip"
      sx={{
        color: NEUTRALS.WHITE,
        backgroundColor,
        borderRadius: '4px',
        padding: '4px 12px',
        fontSize: '12px',
      }}
    >
      {upperFirst(text)}
    </Typography>
  );
};

export interface ConnectionCardProps extends CardProps, Omit<Channel, 'id'> {
  connectedBy?: { name: string; avatar?: string } | null;
  onDisconnect: () => void;
  onDelete: () => void;
}

export const ConnectionCard = forwardRef<HTMLDivElement, ConnectionCardProps>((props, ref) => {
  const {
    connectedBy,
    name,
    status,
    createdBy,
    createdAt,
    errors,
    kind,
    lastActivity,
    onDisconnect,
    onDelete,
    ...cardProps
  } = props;
  const icon = ChannelIcons[props.kind] ?? null;
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: theme => theme.spacing(1, 2),
        gap: theme => theme.spacing(1),
        width: '100%',
        background: NEUTRALS.CHAMOMILE,
        borderRadius: '5px',
        ...cardProps.sx,
        '&.MuiPaper-outlined': {
          border: 'none',
        },
      }}
      {...cardProps}
      ref={ref}
    >
      <Row sx={{ alignItems: 'center', height: '32px' }}>
        <SvgIcon sx={{ fontSize: '18px', marginRight: theme => theme.spacing(1) }}>{icon}</SvgIcon>
        <Typography
          sx={{ fontWeight: '500', fontSize: '13px', marginRight: theme => theme.spacing(1), lineHeight: '16px' }}
        >
          {name}
        </Typography>
        <StatusChip status={status} />
      </Row>
      {connectedBy && createdAt && (
        <Row sx={{ alignItems: 'center', height: '20px' }}>
          <Typography sx={{ fontWeight: '500', fontSize: '13px', color: NEUTRALS.OOLONG }}>Connected by</Typography>
          <Avatar
            sx={{ marginLeft: '10px', height: '20px', width: '20px', fontSize: '20px' }}
            src={connectedBy?.avatar}
          />
          <Typography sx={{ fontWeight: '500', fontSize: '13px', color: NEUTRALS.OOLONG, marginLeft: '10px' }}>
            {connectedBy?.name ?? ''} on {format(parseDate(createdAt)!, 'MMM d, yyyy')}
          </Typography>
        </Row>
      )}
    </Card>
  );
});

ConnectionCard.displayName = 'Connection Card';

import { LIGHT_GREY } from '@caravel/utils/src/constants/colors';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';

export type Sortable = 'name' | 'messages' | 'lastUpdated' | 'createdBy' | 'more' | 'email' | 'role';

const cells: {
  id: Sortable;
  label: string;
}[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'more',
    label: '',
  },
];

export interface TableHeadViewProps {
  order: 'asc' | 'desc';
  orderBy: Sortable;
  onRequestSort: (event: React.MouseEvent<unknown>, property: Sortable) => void;
}

export const TableHeadView = ({ order, orderBy, onRequestSort }: TableHeadViewProps) => {
  const makeSortHandler = (property: Sortable) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        background: LIGHT_GREY,
      }}
    >
      <TableRow>
        {cells.map(cell => (
          <TableCell key={cell.id} padding="normal" sortDirection={orderBy === cell.id ? order : false}>
            <TableSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={makeSortHandler(cell.id)}
            >
              {cell.label}
              {orderBy === cell.id ? (
                <span
                  style={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

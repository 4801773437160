import { Column, Form, NarrowColumn, PageHeader, Row } from '@caravel/components/src';
import { TroveProps } from '@caravel/types/src';
import { DARK_GREY } from '@caravel/utils';
import { Divider, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { SliderSwitch } from 'components/slider-switch';
import { ColorField } from 'components/sources/trove/color-field';
import { isEnterprisePlan, isTeamPlan } from 'helpers/billing-plans';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { TroveTabFooter } from './trove-tab-footer';

export const TroveDesignTab = observer(() => {
  const store = useStore();
  const history = useHistory();
  const form = store.ui.troveForm;
  const teamId = store.team?.id;

  if (!form.formProps || !teamId) {
    return null;
  }

  const design = form.formProps.settings.design;

  const handleSubmit = async () => {
    const result = await store.ui.workOn(form.saveChanges);
    if (result?.ok) {
      history.push(`${form.basePath}/embed`);
    }
  };

  return (
    <NarrowColumn>
      <PageHeader headline="Design" description="Customize the look of your form" />
      <Divider />
      <Column
        sx={{
          margin: '40px 0',
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '350px',
          width: '500px',
          boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.1)',
          borderRadius: '3px',
        }}
      >
        <Column
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '3px',
            overflow: 'hidden',
          }}
        >
          <Form
            open
            onClose={() => ({})}
            teamId={teamId}
            trove={{
              ...(form.formProps.settings as TroveProps),
              inline: true,
            }}
            onSubmit={async data => {
              console.debug(data);
            }}
          />
        </Column>
      </Column>
      <Divider />
      <Column sx={{ marginTop: '20px' }}>
        <Typography variant="body" sx={{ marginBottom: '10px' }}>
          Colors
        </Typography>
        <Row
          sx={{
            gap: theme => theme.spacing(4),
          }}
        >
          <Column sx={{ flex: 1, alignItems: 'center' }}>
            <ColorField
              label="Background"
              color={design.background}
              onChange={c => form.onChangeDesignProp('background', c)}
            />
            <ColorField
              label="Questions"
              color={design.questions}
              onChange={c => form.onChangeDesignProp('questions', c)}
            />
            <ColorField label="Answers" color={design.answers} onChange={c => form.onChangeDesignProp('answers', c)} />
            <ColorField
              label="Answer background"
              color={design.inputBackground}
              onChange={c => form.onChangeDesignProp('inputBackground', c)}
            />
            <ColorField
              label="Rating text"
              color={design.ratingText}
              onChange={c => form.onChangeDesignProp('ratingText', c)}
            />
          </Column>
          <Column sx={{ flex: 1 }}>
            <ColorField label="Buttons" color={design.buttons} onChange={c => form.onChangeDesignProp('buttons', c)} />
            <ColorField
              label="Button text"
              color={design.buttonText}
              onChange={c => form.onChangeDesignProp('buttonText', c)}
            />
            <ColorField
              label="Secondary buttons"
              color={design.secondaryButtons}
              onChange={c => form.onChangeDesignProp('secondaryButtons', c)}
            />
            <ColorField
              label="Secondary text"
              color={design.secondaryText}
              onChange={c => form.onChangeDesignProp('secondaryText', c)}
            />
            <ColorField
              label="Rating buttons"
              color={design.ratingButtons}
              onChange={c => form.onChangeDesignProp('ratingButtons', c)}
            />
          </Column>
        </Row>
      </Column>
      <Divider />
      <Column sx={{ marginTop: '20px' }}>
        <Typography variant="body" sx={{ marginBottom: '30px' }}>
          Other
        </Typography>

        <Row alignItems="center">
          <Column flex={1}>
            <Typography variant="inputLabel">Location</Typography>
            <Typography variant="inputLabelLine" sx={{ color: DARK_GREY, marginTop: '10px' }}>
              Location of the popup form in the browser
            </Typography>
          </Column>
          <Select value={design.location ?? ''} onChange={e => form.onChangeDesignProp('location', e.target.value)}>
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="top-left">Top left</MenuItem>
            <MenuItem value="top-right">Top right</MenuItem>
            <MenuItem value="bottom-right">Bottom right</MenuItem>
            <MenuItem value="bottom-left">Bottom left</MenuItem>
          </Select>
        </Row>

        {design.location !== 'center' && (
          <>
            <Row alignItems="center" sx={{ marginTop: '30px' }}>
              <Column flex={1}>
                <Typography variant="inputLabel">Horizontal padding</Typography>
                <Typography variant="inputLabelLine" sx={{ color: DARK_GREY, marginTop: '10px' }}>
                  Add space around the sides of your widget
                </Typography>
              </Column>
              <TextField
                type="number"
                value={design.paddingHorizontal}
                fullWidth={false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.onChangeDesignProp('paddingHorizontal', e.target.valueAsNumber)
                }
                sx={{
                  width: 100,
                  marginLeft: '10px',
                }}
                inputProps={{
                  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur();
                    }
                  },
                  min: 0,
                  max: 100,
                  step: 1,
                  sx: {
                    boxSizing: 'border-box',
                    fontSize: 13,
                    height: 32,
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">px</InputAdornment>,
                }}
              />
            </Row>
            <Row alignItems="center" sx={{ marginTop: '30px' }}>
              <Column flex={1}>
                <Typography variant="inputLabel">Vertical padding</Typography>
                <Typography variant="inputLabelLine" sx={{ color: DARK_GREY, marginTop: '10px' }}>
                  Add space around the top and bottom of your widget
                </Typography>
              </Column>
              <TextField
                type="number"
                value={design.paddingVertical}
                fullWidth={false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.onChangeDesignProp('paddingVertical', e.target.valueAsNumber)
                }
                sx={{
                  width: 100,
                  marginLeft: '10px',
                }}
                inputProps={{
                  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur();
                    }
                  },
                  min: 0,
                  max: 100,
                  step: 1,
                  sx: {
                    boxSizing: 'border-box',
                    fontSize: 13,
                    height: 32,
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">px</InputAdornment>,
                }}
              />
            </Row>
          </>
        )}

        <Row alignItems="center" sx={{ marginTop: '30px' }}>
          <Column flex={1}>
            <Typography variant="inputLabel">Add overlay</Typography>
            <Typography variant="inputLabelLine" sx={{ color: DARK_GREY, marginTop: '10px' }}>
              Add an overlay background behind the popup form
            </Typography>
          </Column>
          <SliderSwitch checked={design.overlay ?? false} onChange={(e, v) => form.onChangeDesignProp('overlay', v)} />
        </Row>

        {design.overlay && (
          <Row alignItems="center" sx={{ marginTop: '30px' }}>
            <ColorField
              label="Overlay color"
              color={design.overlayColor}
              opacity={design.overlayOpacity}
              onChange={c => form.onChangeDesignProp('overlayColor', c)}
              onChangeOpacity={c => form.onChangeDesignProp('overlayOpacity', c)}
            />
          </Row>
        )}

        <Row alignItems="center" sx={{ marginTop: '30px' }}>
          <Column flex={1}>
            <Typography variant="inputLabel">Hide Commsor branding</Typography>
            <Typography variant="inputLabelLine" sx={{ color: DARK_GREY, marginTop: '10px' }}>
              Requires team plan
            </Typography>
          </Column>
          <SliderSwitch
            disabled={store.team && !(isTeamPlan(store.team?.planId) || isEnterprisePlan(store.team?.planId))}
            checked={design.disableBranding || false}
            onChange={(e, v) => form.onChangeDesignProp('disableBranding', v)}
          />
        </Row>
      </Column>

      {form.formProps.settings.questions.length > 0 && (
        <>
          <Divider sx={{ marginTop: theme => theme.spacing(4) }} />
          <TroveTabFooter tab="design" onSubmit={handleSubmit} disabled={!form.designCanContinue} />
        </>
      )}
    </NarrowColumn>
  );
});

import { Column, Row } from '@caravel/components';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useStore } from 'stores';

import { AppNavigation } from '../app-navigation';

export interface AppChromeProps {
  children: React.ReactNode;
  noAppBar?: boolean;
  backgroundColor?: string;
}

export const AppChrome: FC<AppChromeProps> = observer(({ children, noAppBar, backgroundColor }) => {
  const store = useStore();
  return (
    <Row
      sx={{
        backgroundColor: backgroundColor ? backgroundColor : theme => theme.palette.background.paper,
      }}
    >
      <AppNavigation />
      {!noAppBar && (
        <Column
          sx={{
            overflow: 'auto',
            marginTop: `${store.ui.appBarHeight}px`,
            padding: '0',
            height: `calc(100vh - ${store.ui.appBarHeight}px)`,
            width: '100%',
          }}
          onScroll={e => {
            const target = e.target as any;
            if (target.scrollHeight - target.scrollTop === target.clientHeight) {
              store.ui.events.emit('scroll-to-bottom');
            }
          }}
        >
          {children}
        </Column>
      )}
      {noAppBar && (
        <Column
          sx={{
            overflow: 'auto',
            padding: '0',
            width: '100%',
            height: '100vh',
          }}
          onScroll={e => {
            const target = e.target as any;
            if (target.scrollHeight - target.scrollTop === target.clientHeight) {
              store.ui.events.emit('scroll-to-bottom');
            }
          }}
        >
          {children}
        </Column>
      )}
    </Row>
  );
});

export default AppChrome;

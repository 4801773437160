import { DARK_GREY } from '@caravel/utils/src/constants/colors';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as SyncIcon } from '../../../assets/svgs/check-small.svg';
import { FeatureColumn } from './feature-column';
import { PlanFeatureType } from './plans-info';

export interface FeaturesContainerProps {
  feature: PlanFeatureType;
}

const FeaturesContainer = (props: FeaturesContainerProps) => {
  const { title, features } = props.feature;

  return (
    <>
      <Grid item xs={12} sx={{ margin: '40px 0 30px' }}>
        <Grid container columnSpacing={2}>
          <FeatureColumn>
            <Typography variant="inputLabel">{title}</Typography>
          </FeatureColumn>
          <FeatureColumn />
          <FeatureColumn />
          <FeatureColumn />
          <FeatureColumn />
        </Grid>
      </Grid>
      {features.map((featureData, idx) => (
        <Grid item xs={12} key={idx}>
          <Grid container columnSpacing={2} alignItems="flex-end">
            <FeatureColumn divider>
              <Typography variant="inputLabelSmall" sx={{ color: DARK_GREY, display: 'block', padding: '10px 0' }}>
                {featureData.title}
              </Typography>
            </FeatureColumn>
            <FeatureColumn divider>
              <Box sx={{ width: '20px', minHeight: '34px', marginLeft: '15px', padding: '4px 0' }}>
                {featureData.free && <SyncIcon />}
              </Box>
            </FeatureColumn>
            <FeatureColumn divider>
              <Box sx={{ width: '20px', minHeight: '34px', marginLeft: '15px', padding: '4px 0' }}>
                {featureData.basic && <SyncIcon />}
              </Box>
            </FeatureColumn>
            <FeatureColumn divider>
              <Box sx={{ width: '20px', minHeight: '34px', marginLeft: '15px', padding: '4px 0' }}>
                {featureData.team && <SyncIcon />}
              </Box>
            </FeatureColumn>
            <FeatureColumn divider>
              <Box sx={{ width: '20px', minHeight: '34px', marginLeft: '15px', padding: '4px 0' }}>
                {featureData.enterprise && <SyncIcon />}
              </Box>
            </FeatureColumn>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default FeaturesContainer;

import { BoxProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styles from './flex-box.module.scss';

export interface FlexBoxViewProps extends BoxProps {
  children?: any;
  classes?: any;
}

export const FlexBoxView = React.forwardRef<HTMLDivElement, FlexBoxViewProps>(
  ({ children, className, classes, style, ...rest }, ref) => {
    return (
      <div className={clsx(styles.root, classes?.root, className)} style={{ ...(rest as any), ...style }} ref={ref}>
        {children}
      </div>
    );
  },
);

FlexBoxView.displayName = 'FlexBox';

export default FlexBoxView;

import { Column, FormInputContainer, Row } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { GREY_PALETTE } from '@caravel/utils';
import { Button, Chip, IconButton, Popover, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from 'stores';

import { ReactComponent as CloseIcon } from '../../../assets/svgs/close-drawer.svg';
import { ReactComponent as TagIcon } from '../../../assets/svgs/tag.svg';

export const ProofTagsInput = observer(() => {
  const store = useStore();
  const { ui } = store;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [pendingTag, setPendingTag] = React.useState<string>('');
  const addTagsOpen = Boolean(anchorEl);

  const handleOpenAddTags = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    console.log('open');
  };

  const handleCloseAddTags = () => {
    setAnchorEl(null);
    console.log(anchorEl);
  };

  const handleAddTag = () => {
    setAnchorEl(null);
    ui.ProofForm.changeProof({
      proofTags: ui.ProofForm.formProps?.proofTags?.concat({ name: pendingTag }) ?? [{ name: pendingTag }],
    });
    setPendingTag('');
    console.log('add');
  };

  const handleDeleteTag = (name: string) => {
    ui.ProofForm.changeProof({ proofTags: ui.ProofForm.formProps?.proofTags?.filter(t => t.name !== name) });
  };
  return (
    <Row>
      <FormInputContainer
        label="Tags"
        description="Create tags to filter by products, customer persona, or specific keywords of your choice."
      >
        <Row sx={{ gap: 1 }}>
          <Button
            variant="outlined"
            sx={{
              padding: '2px 8px',
              width: 'fit-content',
              borderRadius: '8px',
              color: GREY_PALETTE[5],
              borderColor: GREY_PALETTE[4],
            }}
            startIcon={<TagIcon style={{ width: '16px', height: '16px' }} />}
            onClick={handleOpenAddTags}
          >
            <Typography variant="bodySmallest">Add a tag</Typography>
          </Button>
          {ui.ProofForm.formProps?.proofTags?.map((tag, index) => (
            <Chip
              sx={{
                padding: '4px 8px',
                span: { paddingRight: '10px' },
                background: GREY_PALETTE[2],
                borderRadius: '8px',
              }}
              key={index}
              label={tag.name}
              deleteIcon={
                <CloseIcon style={{ width: '12px', height: '12px', opacity: 1, color: 'inherit', marginRight: 0 }} />
              }
              onDelete={() => handleDeleteTag(tag.name)}
            />
          ))}
        </Row>
      </FormInputContainer>
      <Popover
        open={addTagsOpen}
        anchorEl={anchorEl}
        onClose={handleCloseAddTags}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus={true}
        PaperProps={{
          elevation: 3,
          style: {
            marginTop: '2px',
            border: 'none',
            borderRadius: '4px',
            boxShadow:
              '0px 30px 42px 0px rgba(0, 0, 0, 0.12),0px 10px 18px 0px rgba(0, 0, 0, 0.04),0px 4px 14px -10px rgba(0, 0, 0, 0.07)',
          },
        }}
      >
        <Column sx={{ padding: '12px 18px', gap: 1 }}>
          <Typography id="tag-label" variant="bodySmall" sx={{ color: GREY_PALETTE[5] }}>
            Press enter to add tag
          </Typography>
          <TextField
            aria-labelledby={'tag-label'}
            value={pendingTag}
            onChange={e => {
              setPendingTag(e.target.value);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleAddTag();
                handleCloseAddTags();
              }
            }}
          />
        </Column>
      </Popover>
    </Row>
  );
});

import { ComposerMessageKind } from '@caravel/types';
import {
  CREATE_COMPOSER,
  createGQLClient,
  GET_COMPOSERS,
  GET_SEGMENT_CHANNEL_MEMBERSHIP,
  GqlComposersRequestType,
  GqlComposersResponseType,
  GqlCreateComposerRequestType,
  GqlCreateComposerResponseType,
  GqlSegmentChannelMembershipRequestType,
  GqlSegmentChannelMembershipResponseType,
  GqlTestComposerRequestType,
  GqlTestComposerResponseType,
  TEST_COMPOSER_MESSAGE,
} from '@caravel/utils';
import { computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { Composer } from 'models/composer';

import { BaseStore } from './base';
import { RootStore } from './root';

interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}

export class ComposerStore extends BaseStore {
  collection = observable.array<Composer>([]);
  loading = false;
  loaded = false;
  pageInfo?: PageInfo = undefined;
  composersCount = 0;
  segmentChannelMemberCount = observable.array<any>([{ id: '' }]);

  composer?: Composer = undefined;

  order: 'asc' | 'desc' = 'desc';
  orderBy = 'sentAt';

  get orderByOptions() {
    return {
      field: this.orderBy,
      direction: this.order.toUpperCase(),
    };
  }

  onChangeOrder = (orderBy: string) => {
    const isAsc = orderBy === this.orderBy && this.order === 'asc';
    runInAction(() => {
      this.order = isAsc ? 'desc' : 'asc';
      this.orderBy = orderBy;
      this.resetCollection();
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      pageInfo: observable,
      composer: observable,
      order: observable,
      orderBy: observable,
      orderByOptions: computed,
    });
  }

  async mount() {
    await this.fetchComposers();
  }

  teardown() {
    this.loaded = true;
    this.collection.clear();
    super.teardown();
  }

  resetCollection = () => {
    runInAction(() => {
      this.collection.clear();
      this.pageInfo = undefined;
    });
  };

  resetComposer = () => {
    runInAction(() => {
      this.composer = undefined;
    });
  };

  fetchComposers = async () => {
    if (this.loading) {
      console.debug('Composers already loading');
      return;
    }
    runInAction(() => (this.loading = true));

    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);

    try {
      const response: GqlComposersResponseType = await graphqlClient.query<
        GqlComposersRequestType,
        GqlComposersResponseType
      >(GET_COMPOSERS, { orderBy: this.orderByOptions });

      const pageInfo = response.community.composerMessages.pageInfo;
      const composers: Composer[] = response.community.composerMessages.edges
        ?.filter(edge => edge.node)
        .map(edge => new Composer(edge.node));

      runInAction(() => {
        this.pageInfo = pageInfo;
        if (pageInfo.startCursor === '0') {
          this.collection.replace(composers);
        } else {
          this.collection.replace(this.collection.concat(composers));
        }
        this.loading = false;
        this.loaded = true;
        this.composersCount = response.community.composerMessages.edges.length;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to fetch composers',
        duration: 5000,
      });
    }
  };

  createComposerMessage = async (title: string, content: string, segmentId: string, channelId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);
    try {
      const response = await graphqlClient.mutate<GqlCreateComposerRequestType, GqlCreateComposerResponseType>(
        CREATE_COMPOSER,
        {
          input: {
            title,
            content,
            messageKind: 'SLACK' as ComposerMessageKind,
            segmentId,
            channelId,
          },
        },
      );
      runInAction(() => {
        const result = response.sendComposerMessage.result;
        this.fetchComposers();
        return result;
      });
    } catch (e) {
      console.error('create message error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error Sending Message',
        duration: 5000,
      });
    }
  };

  testComposerMessage = async (content: string, channelId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);
    try {
      const response = await graphqlClient.mutate<GqlTestComposerRequestType, GqlTestComposerResponseType>(
        TEST_COMPOSER_MESSAGE,
        {
          input: {
            content,
            messageKind: 'SLACK' as ComposerMessageKind,
            channelId,
          },
        },
      );
      runInAction(() => {
        const result = response.testComposerMessage.success;
        this.fetchComposers();
        return result;
      });
    } catch (e) {
      console.error('Test message error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error Sending Test Message',
        duration: 5000,
      });
    }
  };

  segmentChannelMembership = async (channelId: string, segmentId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);
    try {
      const response = await graphqlClient.query<
        GqlSegmentChannelMembershipRequestType,
        GqlSegmentChannelMembershipResponseType
      >(GET_SEGMENT_CHANNEL_MEMBERSHIP, {
        input: {
          channelId,
          segmentId,
        },
      });
      runInAction(() => {
        const result = toJS(response.segmentChannelMembership);
        this.segmentChannelMemberCount.replace(result);
      });
    } catch (e) {
      console.error('segment channel membership error', e);
    }
  };
}

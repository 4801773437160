import { Column } from '@caravel/components/src';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Drawer, ListItemText, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from 'stores';
import { FeaturesStores } from 'stores/features';

import { ReactComponent as WorkspaceIcon } from '../../../assets/svgs/navigation/workspace.svg';
import { NAV_FONT_SIZE, StyledList, StyledListItem, StyledListItemIcon } from '../app-navigation';
import { CollapsibleList } from '../collapsible-list';

interface SettingsDrawerProps {
  open: boolean;
  onBack: () => void;
}

export const SettingsDrawer = observer((props: SettingsDrawerProps) => {
  const { open, onBack } = props;
  const store = useStore();
  const features = store.features;
  const theme = useTheme();

  const settingsDrawerContents = (
    <Column
      sx={{
        width: 240,
      }}
    >
      <Column>
        <StyledList
          sx={{
            margin: '20px 9px 20px 13px',
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
        >
          <StyledListItem
            sx={{
              margin: '0 0 18px 0',
              height: '32px',
              '& .MuiListItemText-root': {
                '& > *': {
                  fontSize: `${NAV_FONT_SIZE + 2}px`,
                  lineHeight: `${NAV_FONT_SIZE + 2}px`,
                },
              },
            }}
            onClick={onBack}
          >
            <StyledListItemIcon>
              <KeyboardArrowLeftIcon />
            </StyledListItemIcon>
            <ListItemText primary="Settings" />
          </StyledListItem>

          <CollapsibleList
            startOpen
            label="Workspace"
            icon={WorkspaceIcon}
            listItems={[
              { label: 'Account', display: true, path: '/settings/account' },
              { label: 'Team', display: store.members.current?.canWrite, path: '/settings/team' },
              {
                label: 'References',
                display: features?.hasChampions,
                path: '/settings/references',
              },
              {
                label: 'Plans',
                display: features?.hasSettingsPlans,
                path: '/settings/plans',
              },
              {
                label: 'Billing',
                display: features?.hasSettingsBilling,
                path: '/settings/billing',
              },
            ]}
          />
        </StyledList>
      </Column>
    </Column>
  );

  return (
    <div>
      <Drawer
        anchor="left"
        open={open}
        sx={{
          zIndex: theme.zIndex.modal,
          width: 240,
        }}
        hideBackdrop={true}
        ModalProps={{
          disableEnforceFocus: true,
          keepMounted: true,
        }}
      >
        {settingsDrawerContents}
      </Drawer>
    </div>
  );
});

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { useStore } from 'stores';

export const CropImageModal = observer(() => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const store = useStore();
  const open = store.ui.openModals.includes('crop-image');

  useEffect(() => {
    setZoom(1);
  }, [open]);

  const handleClose = () => {
    store.ui.closeModal('crop-image');
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const data = store.ui.modalData.get('crop-image');
  const imageUrl = data?.imageUrl;

  if (!open || !imageUrl) {
    return null;
  }

  const handleCropImage = async () => {
    if (croppedAreaPixels) {
      const file: File | null = await getCroppedImg(imageUrl, croppedAreaPixels);
      if (file) data.setImage([{ data_url: URL.createObjectURL(file), file }]);
      handleClose();
    }
  };

  const boxSx = {
    height: '220px',
    width: '418px',
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography fontWeight={500} fontSize="20px" sx={{ flex: 1 }}>
          Crop Image
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* This box is a spacer for the absolute positioned cropper */}
        <Box sx={boxSx} />

        <Box
          sx={{
            ...boxSx,
            position: 'absolute',
            top: '75px',
          }}
        >
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            cropShape="round"
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
          />
        </Box>

        <Stack spacing={2} direction="row" sx={{ mb: 1, marginTop: '20px' }} alignItems="center">
          <Typography>0%</Typography>
          <Slider size="small" value={zoom} onChange={(e, zoom) => setZoom(Number(zoom))} min={1} max={3} step={0.05} />
          <Typography>100%</Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleCropImage} disabled={zoom === 1}>
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
    image.src = url;
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
async function getCroppedImg(imageSrc: string, pixelCrop: Area): Promise<File | null> {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // set canvas size to match the bounding box
  canvas.width = image.width;
  canvas.height = image.height;

  // draw image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a File
  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      if (file) resolve(new File([file], 'logo.jpeg', { type: 'image/jpeg' }));
    }, 'image/jpeg');
  });
}

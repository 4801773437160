import _get from 'lodash/get';

type StringAny = Record<string, any>;
type Comparator = (a: StringAny, b: StringAny, orderBy?: string) => number;

/**
 * Compares two objects properties and returns a sort value
 * @param a
 * @param b
 * @param orderBy Object property to be used in comparison
 * @returns -1, 0, 1
 */
export function descendingComparator(a: StringAny, b: StringAny, orderBy: string) {
  let aValue = _get(a, orderBy);
  let bValue = _get(b, orderBy);
  if (aValue instanceof Date) {
    aValue = aValue.getTime();
  }
  if (bValue instanceof Date) {
    bValue = bValue.getTime();
  }
  if (typeof aValue === 'string') {
    aValue = aValue.toLowerCase();
  }
  if (typeof bValue === 'string') {
    bValue = bValue.toLowerCase();
  }
  if (bValue < aValue || aValue === undefined) {
    return -1;
  }
  if (bValue > aValue || bValue === undefined) {
    return 1;
  }
  return 0;
}

/**
 * Gets a comparator function for given order and order prop
 * @param order The sort order
 * @param orderBy The property to order by
 * @returns A comparator function
 */
export function getComparator(order: 'asc' | 'desc', orderBy: string): Comparator {
  return order === 'desc'
    ? (a: StringAny, b: StringAny) => descendingComparator(a, b, orderBy)
    : (a: StringAny, b: StringAny) => -descendingComparator(a, b, orderBy);
}

/**
 * Sorts a copy of a given array
 * @param array The array to sort
 * @param comparator A comparator function that handles different types
 * @returns A sorted copy of the original array
 */
export function stableSort<T extends StringAny>(array: T[], comparator: Comparator) {
  const stabilizedThis: [T, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

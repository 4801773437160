import { CardHeader, TextField, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

export const NewSegmentCardHeader = observer(() => {
  const store = useStore();
  const {
    ui: { SegmentForm },
  } = store;

  const theme = useTheme();
  const formProps = SegmentForm.formProps;
  if (!formProps) {
    return null;
  }

  return (
    <CardHeader
      sx={{ marginBottom: theme.spacing(4), padding: 0 }}
      title={
        <TextField
          fullWidth
          required
          variant="standard"
          placeholder="Segment Title"
          value={formProps!.name}
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: '18px',
            },
          }}
          onChange={e => SegmentForm.onChange({ name: e.target.value })}
        />
      }
      titleTypographyProps={{
        marginBottom: theme.spacing(1),
      }}
      subheader={
        <TextField
          fullWidth
          variant="standard"
          placeholder="Add description... (optional)"
          value={formProps!.description}
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: '14px',
            },
          }}
          onChange={e => SegmentForm.onChange({ description: e.target.value })}
        />
      }
      subheaderTypographyProps={{ variant: 'bodySmall' }}
    />
  );
});

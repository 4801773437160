import { sleepAsync } from '@caravel/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { Toast, ToastProps } from 'models';
import { SyntheticEvent } from 'react';
import { RootStore } from 'stores/root';

import { BaseStore } from './base';

/**
 * This is a data store for app-level 'toast' messages.
 * ```ts
 * store.ui.appToasts.display({ message: 'Uh oh!', severity: 'error' });
 * ```
 */
export class AppToastStore extends BaseStore {
  debugName = 'AppToastStore';

  /**
   * Observable store for app level toast messages
   */
  toasts = observable.array<Toast>([]);

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable<AppToastStore, '_opened'>(this, {
      toasts: observable,
      opened: computed,
      _opened: observable,
      closeToast: action,
      display: action,
      handleClose: action,
    });
  }

  /**
   * The app snackbar's 'open' state.
   */
  get opened() {
    return this._opened;
  }

  private _opened = false;

  teardown() {
    this._opened = false;
    this.toasts.clear();
    super.teardown();
  }

  closeToast = (toast: Toast) => {
    const index = this.toasts.findIndex(t => t.uid === toast.uid);
    this.toasts.splice(index, 1);
  };

  /**
   * DEPRECATED: use notifications store
   * Display an app-level message
   * Use { duration: 0 } to show the message indefinitely
   */
  display = (toastProps: ToastProps) => {
    return this.rootStore.notifications.display(toastProps);
  };

  /**
   * Callback handler used to mark the snackbar closed.
   */
  handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this._opened = false;
  };

  private startAutohide = async (toast: Toast) => {
    if (toast.timerStarted) {
      return;
    }
    toast.timerStarted = true;
    await sleepAsync(toast.duration || 0);
    this.closeToast(toast);
  };
}

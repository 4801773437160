import { BackgroundLoader } from '@caravel/components/src';
import { FlexBox } from 'components/flex-box';
import { Logger, useQuery } from 'helpers';
import { observer } from 'mobx-react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

const { debug } = new Logger('finish-sign-in');

export const FinishSignInContainer = observer(() => {
  const store = useStore();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    store.ui.workOn(async () => {
      const error = await store.auth.finishSignInWithEmail();
      if (!error) {
        const teamId = query.get('teamId') || undefined;
        const inviteId = query.get('inviteId') || undefined;
        if (teamId && inviteId) {
          debug('Accepting team invitation', { teamId, inviteId });
          await store.teams.invites.acceptInvite({ teamId, inviteId });
        }
        history.push('/');
      } else {
        console.error(error);
      }
    });
  }, [history, query, store.auth, store.teams.invites, store.ui]);

  return (
    <FlexBox height="100vh" width="100vw">
      <BackgroundLoader title="Signing you in..." />
    </FlexBox>
  );
});

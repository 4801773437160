import { gql } from '@apollo/client';
import { ManualPersonaAttribute } from '@caravel/types';

export const DELETE_MANUAL_RECORD = gql`
  mutation deleteManualAttribute($input: DeleteManualAttributeInput!) {
    deleteManualAttribute(input: $input) {
      result {
        id
      }
    }
  }
`;

export interface GqlDeletePersonaRequestType {
  input: {
    memberId: string;
    attribute: ManualPersonaAttribute;
  };
}

export interface GqlDeletePersonaResponseType {
  deleteManualAttribute: {
    result: {
      id: string;
    };
  };
}

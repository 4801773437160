import { gql } from '@apollo/client';

export const DELETE_CHAMPION = gql`
  mutation deleteChampion($championId: String) {
    deleteChampion(input: { championMemberId: $championId }) {
      result
    }
  }
`;

export interface GqlDeleteChampionRequestType {
  championId: string;
}

export interface GqlDeleteChampionResponseType {
  deleteChampion: {
    result: string;
  };
}

import { GREY_PALETTE } from '@caravel/utils';
import { NestedMenuItem, NestedMenuItemProps } from 'mui-nested-menu';
import React from 'react';

export const FilterNestedMenuItem = React.forwardRef((props: NestedMenuItemProps, ref: React.Ref<HTMLLIElement>) => {
  return (
    <NestedMenuItem
      onKeyDown={e => e.stopPropagation()}
      {...props}
      ref={ref}
      sx={{
        padding: '5px 8px',
        minWidth: '185px',
        width: '100%',
        '&:hover': {
          backgroundColor: GREY_PALETTE[2],
        },
        '& .MuiTypography-root': {
          fontSize: '13px',
          fontWeight: 400,
        },
        ...props.sx,
      }}
    />
  );
});

FilterNestedMenuItem.displayName = 'FilterNestedMenuItem';

import isEqual from 'lodash/isEqual';

// Re-export third-party utility functions
export { isEqual };

/**
 * Shorthand to check if a value is undefined or null
 * @param arg An unknown value
 * @returns Whether the given value is undefined or null
 */
export const isUndefinedOrNull = (arg?: unknown): boolean => arg === undefined || arg === null;

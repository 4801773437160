import { ERROR_PALETTE } from '@caravel/utils';
import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface FormLabelProps extends TypographyProps {
  required?: boolean;
}

export const FormLabel = React.forwardRef<HTMLSpanElement, FormLabelProps>(
  ({ required = false, children, ...props }, ref) => (
    <Typography ref={ref} variant="inputLabel" {...props}>
      {children}
      {required && (
        <Typography color={ERROR_PALETTE[200]} variant="inputLabel" marginLeft="4px">
          *
        </Typography>
      )}
    </Typography>
  ),
);

FormLabel.displayName = 'FormLabel';

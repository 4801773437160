import { gql } from '@apollo/client';

export const ADD_CHAMPIONS = gql`
  mutation AddChampions($championEmails: String, $canReceiveReferences: Boolean) {
    addChampions(input: { championEmails: $championEmails, canReceiveReferences: $canReceiveReferences }) {
      result
    }
  }
`;

export interface GqlAddChampionsRequestType {
  championEmails: string; // Comma-separated list of emails
  canReceiveReferences: boolean;
}

export interface GqlAddChampionsResponseType {
  addChampions: {
    result: string;
  };
}

import { CaravelSpinner, Column, SortableTableHead } from '@caravel/components/src';
import { NoResults } from '@caravel/components/src/no-results';
import { NEUTRALS } from '@caravel/utils';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Box, SvgIcon, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { AppToolbar } from '../app-toolbar';
import { RouterLink } from '../router-link';
import { ReactComponent as Slack } from './svgs/slack.svg';
import { ReactComponent as SlackWhite } from './svgs/slack-white.svg';

export const ComposerList: FC = observer(() => {
  const history = useHistory();
  const store = useStore();
  const {
    ui,
    teams: { composers, teamMembers },
  } = store;

  const canLoad = store.team && (!composers.pageInfo || composers.pageInfo?.hasNextPage) && !composers.loading;
  const collection = composers.collection.slice();
  const noComposers = composers.loaded && composers.composersCount === 0;

  const loadMore = useCallback(() => {
    if (!canLoad) return;
    ui.workOn(async () => {
      await composers.fetchComposers();
    });
  }, [canLoad, composers, ui]);

  const handleRequestSort = (id: string) => {
    ui.workOn(async () => {
      await composers.onChangeOrder(id);
      await composers.fetchComposers();
    });
  };

  useEffect(() => {
    if (!collection.length) {
      loadMore();
    }
  }, [collection.length, loadMore]);

  return (
    <Column sx={{ height: '100%' }}>
      <AppToolbar
        title="Composer"
        actionButton={{
          startIcon: <SlackWhite />,
          onClick: () => history.push('/composer/new'),
          text: 'Compose Message',
        }}
      />
      {!noComposers ? (
        <TableContainer>
          <Table size="small" sx={{ tableLayout: 'fixed' }}>
            <SortableTableHead
              cells={[
                { id: 'title', label: 'Message', padding: '20px 48px', width: '600px' },
                { id: 'sentAt', label: 'Time & Date' },
                { id: 'recepientCount', label: 'Recipients' },
                // { id: '', label: 'Replies', disabledSort: true },
                { id: '', label: 'Sender', disabledSort: true },
              ]}
              order={composers.order}
              orderBy={composers.orderBy}
              onSort={handleRequestSort}
              rowCount={composers.collection.length}
            />
            <TableBody>
              {collection.map(row => {
                const sender = row.createdBy && teamMembers.get(row.createdBy);
                return (
                  <TableRow key={row.id} sx={{ minHeight: '55px', height: '100%' }}>
                    <TableCell
                      padding="none"
                      width={600}
                      sx={{
                        tableLayout: 'fixed',
                        padding: '10px 0 10px 55px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        maxWidth: '550px',
                        minWidth: '400px',
                      }}
                    >
                      <SvgIcon
                        component={Slack}
                        sx={{
                          width: '24px',
                          height: '24px',
                          fill: 'none',
                        }}
                      />
                      <Column
                        sx={{
                          marginLeft: '15px',
                          maxWidth: '90%',
                        }}
                      >
                        <RouterLink to={`/composer/${row.id}`} color="textPrimary">
                          <Typography variant="headline4" sx={{ color: NEUTRALS.ASSAM }}>
                            {row.title}
                          </Typography>
                        </RouterLink>
                        <Typography
                          variant="bodySmallest"
                          sx={{
                            color: NEUTRALS.OOLONG,
                            whiteSpace: 'noWrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: '18px',
                          }}
                        >
                          {row.content}
                        </Typography>
                      </Column>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmallest" sx={{ color: NEUTRALS.CEYLON }}>
                        {format(new Date(row.sentAt), 'dd/LL/yyyy hh:mm b')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall" sx={{ color: NEUTRALS.ASSAM }}>
                        {row.recepientCount}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Typography variant="headline4">
                        <NumberChip value="---" jasmine sx={{ width: 'fit-content' }} />
                      </Typography>
                    </TableCell> */}
                    <TableCell sx={{ padding: '10px 60px 10px 16px' }}>
                      {sender && (
                        <>
                          <Avatar
                            src={sender.image}
                            alt={sender.name}
                            sx={{
                              height: 30,
                              width: 30,
                            }}
                          >
                            {sender.name.charAt(0)}
                          </Avatar>
                          <Typography variant="headline4">{row.sentBy}</Typography>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoResults
          title="No Bulk Messages Created"
          message="Get started with your first bulk message"
          buttonText="Compose Message"
          buttonIcon={<AddIcon />}
          onButtonClick={() => history.push('/composer/new')}
        />
      )}
      {(composers.loading || composers.pageInfo?.hasNextPage) && (
        <Box
          sx={{
            backgroundColor: 'white',
            height: '100%',
            width: '100%',
            marginLeft: '-16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CaravelSpinner />
        </Box>
      )}
    </Column>
  );
});

import { Row } from '@caravel/components/src';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

interface DeleteProofModalProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

export const DeleteProofModal = ({ open, handleClose, handleDelete }: DeleteProofModalProps) => {
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '600px',
          width: '100%',
          background: '#fff',
          borderRadius: '8px',
          padding: '30px 36px',
          boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.04)',
        },
      }}
    >
      <DialogTitle>
        <Row sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'end' }}>
          <Typography variant="headline2" sx={{ fontWeight: 600, marginBottom: '10px' }}>
            Are you sure you want to delete this item?
          </Typography>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body">This action cannot be reversed</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="tertiary" sx={{ padding: '10px 20px' }} autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" sx={{ padding: '10px 20px' }} onClick={handleDelete} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

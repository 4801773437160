import { styled, Switch } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

export const SliderSwitchView = styled(Switch)(({ theme }) => ({
  padding: 0,
  height: 24,
  width: 48,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 3,
    color: theme.palette.common.white,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    padding: 0,
    margin: 0,
  },
  track: {
    height: 24,
    width: 48,
    borderRadius: 12,
    border: 'none',
    backgroundColor: theme.grey[6],
    opacity: 1,
  },
  checked: {},
  sizeSmall: {},
}));

export default SliderSwitchView;

import { Row, theme } from '@caravel/components/src';
import { Avatar, AvatarGroup, IconButton, SvgIcon, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStore } from 'stores';

import { AddToSegmentPopoverMenu } from '../segments/add-to-segment-popover-menu';
import {
  PrimaryContainedSelectionButton,
  SecondaryContainedSelectionButton,
  SelectionBar,
} from '../selection-bar/selection-bar';
import { AddToSegmentIcon, CloseIcon, MergeIcon } from './people-icons';

export const PeopleSelectionBar = observer(() => {
  const store = useStore();
  const selectedPeople = store.teams.people.selectedPeople.slice(0).reverse();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const snackbarMessage = (
    <Row sx={{ alignItems: 'center' }}>
      <AvatarGroup
        max={3}
        sx={{
          marginRight: '10px',
          '& .MuiAvatarGroup-avatar': { width: '30px', height: '30px' },
        }}
      >
        {selectedPeople.map((p, i) => (
          <Avatar key={i} src={p.avatar} alt={p.name} sx={{ width: 30, height: 30, fontSize: '12px' }}>
            {p.initials}
          </Avatar>
        ))}
      </AvatarGroup>
      <Typography variant={'bodySmall'}>
        {store.teams.people.numSelected} {store.teams.people.numSelected > 1 ? 'People' : 'Person'} Selected
      </Typography>
    </Row>
  );

  const selectionBarActions = (
    <Row>
      {store.features.hasMergeMembers && (
        <SecondaryContainedSelectionButton
          disabled={store.teams.people.numSelected < 2 || store.teams.people.numSelected > 10}
          startIcon={<MergeIcon width="20px" height="20px" />}
          onClick={() => {
            store.teams.people.openMergingPeopleModal();
          }}
          sx={{ padding: '6px 24px 6px 12px' }}
        >
          Merge
        </SecondaryContainedSelectionButton>
      )}
      <PrimaryContainedSelectionButton
        variant={'contained'}
        startIcon={<AddToSegmentIcon width="20px" height="20px" />}
        onClick={e => setAnchorEl(e.currentTarget)}
        sx={{
          marginLeft: store.features.hasMergeMembers ? '10px' : '0px',
          padding: '6px 24px 6px 12px',
        }}
      >
        Add to Static Segment
      </PrimaryContainedSelectionButton>
      <AddToSegmentPopoverMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} people={selectedPeople} />
      <IconButton
        sx={{ marginLeft: '10px' }}
        onClick={() => {
          store.teams.people.deselectAll();
        }}
      >
        <SvgIcon viewBox="0 0 20 21" sx={{ width: 20, height: 20, fill: theme.grey[0] }} component={CloseIcon} />
      </IconButton>
    </Row>
  );

  return (
    <SelectionBar
      open={store.teams.people.numSelected > 0}
      snackbarMessage={snackbarMessage}
      selectionBarActions={selectionBarActions}
    />
  );
});

import { gql } from '@apollo/client';
import { Organization } from '@caravel/types';

export const GET_ORGANIZATION = gql`
  query getOrganization($orgId: ID!) {
    community {
      id
      organizations(filterBy: { id__eq: $orgId }) {
        edges {
          node {
            id
            activeMemberCount
            annualRevenue
            description
            domain
            employeesRange
            facebookHandle
            firstActive
            firstMember {
              id
              avatar
              name
              givenName
              familyName
              engagement {
                gridStatus
              }
            }
            foundedYear
            fundsRaised
            industry
            linkedinHandle
            location
            locationCity
            locationCountry
            locationState
            memberCount
            name
            newMembers {
              id
              avatar
              name
              givenName
              familyName
              engagement {
                gridStatus
              }
            }
            notes {
              id
              createdAt
              author {
                id
                firebaseUserId
                firstName
                lastName
              }
              text
            }
            peopleCount
            ticker
            topGridStatusMember {
              id
              avatar
              name
              givenName
              familyName
              engagement {
                gridStatus
              }
            }
            twitterHandle
            type
          }
        }
        meta {
          facets {
            counts {
              count
              value
            }
            name
            totalValues
          }
          hits
          query
        }
      }
    }
  }
`;

export interface GqlOrganizationRequestType {
  orgId: string;
}

export interface GqlOrganizationResponseType {
  community: {
    id: string;
    organizations: {
      edges: {
        node: Organization;
      }[];
    };
  };
}

import {
  CaravelSpinner,
  Column,
  ConnectionsTableCell,
  CustomerFitChip,
  Flex,
  NoResults,
  SortableTableHeadV2,
  TextWithEmoji,
} from '@caravel/components/src';
import { useTeamConnections } from '@caravel/components/src/hooks/use-team-connections';
import { COMMSOR_PURPLE, getComparator, GREY_PALETTE, NEUTRALS, SENCHA, stableSort, WHITE } from '@caravel/utils';
import AddIcon from '@mui/icons-material/Add';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { RouterLink } from 'components/router-link';
import { formatDistance } from 'date-fns';
import { observer } from 'mobx-react';
import { Organization } from 'models/organization';
import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-cool-inview';
import { useStore } from 'stores';

import { ReactComponent as LinkedIcon } from '../../../assets/svgs/linked.svg';
import { ReactComponent as NoteIcon } from '../../../assets/svgs/note.svg';
import { ReactComponent as SpeechBubbleIcon } from '../../../assets/svgs/speech-bubble.svg';
import { OrganizationNote } from './org-note';
import { OrgPeopleFilters } from './org-people-filters';

export const OrgPeopleList = observer(
  // eslint-disable-next-line complexity
  (props: { organization: Organization; previousPage: string }) => {
    const { organization, previousPage } = props;
    const store = useStore();
    const {
      ui,
      teams: { organizations, people, teamMembers },
    } = store;
    const filterStore = store.teams.filters;
    const userId = store.auth.user?.uid;
    const fromChampions = previousPage && previousPage === 'champions';
    const notes = stableSort(organization.notes ?? [], getComparator('desc', 'createdAt')).filter(n => n.author);
    const [currentTab, setCurrentTab] = useState<number>(fromChampions ? 3 : 0);
    const [numSuperUsers, setNumSuperUsers] = useState<number>(0);
    const [numContributors, setNumContributors] = useState<number>(0);
    const [numConnections, setNumConnections] = useState<number>(0);
    const [numTeamConnections, setNumTeamConnections] = useState<number>(0);

    const canLoad = store.team && (!people.pageInfo || people.pageInfo?.hasNextPage) && !people.loading;
    const community = people.collection.slice();
    const dateNow = new Date();

    const handleRequestSort = (id: string) => {
      filterStore.onChangePeopleOrder(id);
      ui.workOn(async () => {
        await people.fetchCommunity();
      });
    };

    const changeFilter = useCallback(() => {
      ui.workOn(async () => {
        if (fromChampions) {
          await filterStore.onChangePeopleFilter(1, {
            condition: 'includes',
            graphProperty: 'channel',
            label: 'Source',
            pluralized: 'sources',
            type: 'source',
            value: ['champions'],
          });
        } else {
          await filterStore.onChangePeopleFilter(1, {
            condition: 'is',
            graphProperty: 'organization',
            label: 'Organization',
            pluralized: 'organizations',
            type: 'organization',
            value: [organization.name],
          });
        }
        // Save numSuperUsers and numContributors from
        // our collection of people in this organization.
        setNumSuperUsers(people.numSuperUsers);
        setNumContributors(people.numContributors);
        setNumConnections(people.numConnections);
        setNumTeamConnections(people.numTeamConnections);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization.name, ui, filterStore]);

    const loadMore = useCallback(() => {
      if (!canLoad) return;
      ui.workOn(async () => {
        await people.fetchCommunity();
      });
    }, [canLoad, people, ui]);

    const { observe } = useInView({
      onEnter: () => {
        loadMore();
      },
    });

    useEffect(() => {
      ui.workOn(async () => {
        await changeFilter();
      });
      return () => {
        filterStore.clearPeopleFilters();
        people.refreshCommunity();
      };
    }, [changeFilter, people, ui, organization.name, filterStore]);

    return (
      <Column sx={{ width: '100%', padding: '0 32px 20px' }}>
        <Box
          borderBottom={`2px solid ${NEUTRALS.JASMINE}`}
          sx={{
            background: WHITE,
            paddingTop: '20px',
            position: 'sticky',
            top: '0',
            zIndex: 1,
          }}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Tabs
              value={currentTab}
              onChange={(evt: React.SyntheticEvent, value: number) => setCurrentTab(value)}
              aria-label="People tabs"
              sx={{
                justifyContent: 'flex-start',
                minHeight: 40,
                border: 'none',
                marginBottom: '-2px',
                width: 'auto',
                '& .MuiTabs-scroller': {
                  justifyContent: 'flex-start',
                },
                '& .MuiTab-root': {
                  padding: '0px 16px',
                  minWidth: 120,
                  minHeight: 0,
                  width: 'auto',
                },
              }}
            >
              {fromChampions && <Tab label="Champions" value={3} />}
              {!fromChampions && <Tab label="People" value={0} />}
              <Tab label="Notes" value={1} />
            </Tabs>
            {currentTab === 0 && (
              <OrgPeopleFilters
                organization={organization}
                numConnections={numConnections}
                numTeamConnections={numTeamConnections}
                numContributors={numContributors}
                numSuperUsers={numSuperUsers}
              />
            )}
            {currentTab === 1 && (
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  color="tertiary"
                  onClick={() => organizations.onAddNote()}
                  startIcon={<NoteIcon />}
                >
                  Add Note
                </Button>
              </Box>
            )}
          </Flex>
        </Box>
        {currentTab === 0 && (
          <Box marginTop="24px">
            <TableContainer>
              <Table size="small">
                <SortableTableHeadV2
                  cells={[
                    {
                      id: 'name',
                      label: 'Member',
                      minWidth: '200px',
                    },
                    {
                      id: 'customerFit',
                      label: 'Customer Fit',
                      minWidth: '85px',
                      width: 'calc(100%/5)',
                      maxWidth: '300px',
                    },
                    {
                      id: 'connectedTo',
                      label: 'Connections',
                      minWidth: '180px',
                      width: 'calc(100%/5)',
                      maxWidth: '400px',
                      disabledSort: true,
                    },
                    {
                      // id: 'ask',
                      // label: 'Ask',
                      // disabledSort: true,
                      id: 'lastActive',
                      label: 'Last Active',
                      minWidth: '140px',
                      width: `calc(100%/${5})`,
                      maxWidth: '300px',
                    },
                  ]}
                  order={filterStore.peopleOrder}
                  orderBy={filterStore.peopleOrderBy}
                  onSort={handleRequestSort}
                />
                <TableBody>
                  {community.map(row => {
                    const teamConnections = teamMembers.getTeamConnections(row.connectedTo);
                    const { teamConnectionsContainsSelf } = useTeamConnections(teamConnections, userId);
                    const rowAvatar = (
                      <Avatar src={row.avatar} alt={row.name} sx={{ width: 40, height: 40, fontSize: '12px' }}>
                        {row.initials}
                      </Avatar>
                    );
                    return (
                      <TableRow key={row.id} sx={{ minHeight: '50px', height: '100%' }}>
                        <TableCell
                          padding="none"
                          sx={{
                            padding: '16px 0',
                            wordBreak: 'break-word',
                            borderBottomColor: NEUTRALS.JASMINE,
                          }}
                        >
                          <RouterLink
                            to={`/people/${row.id}`}
                            color="textPrimary"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            title={row.name}
                          >
                            {teamConnectionsContainsSelf ? (
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                badgeContent={
                                  <Avatar
                                    sx={{
                                      width: 18,
                                      height: 18,
                                      background: SENCHA[200],
                                      boxShadow: `0 0 0 2px white`,
                                      color: 'white',
                                    }}
                                  >
                                    <LinkedIcon width="12px" height="12px" />
                                  </Avatar>
                                }
                              >
                                {rowAvatar}
                              </Badge>
                            ) : (
                              <>{rowAvatar}</>
                            )}
                            <Box marginLeft="16px">
                              <Typography variant="inputLabel" component="p" marginBottom="4px">
                                {row.name}
                              </Typography>
                              <Typography variant="bodySmallest" component="p">
                                {row.employmentTitle}
                                {row.activities?.edges[0]?.node.title && (
                                  <>
                                    {row.employmentTitle && <>,</>}
                                    <TextWithEmoji
                                      text={row.activities.edges[0].node.title}
                                      variant="bodySmallest"
                                      color={COMMSOR_PURPLE[200]}
                                    />
                                    {` `}
                                    {formatDistance(
                                      new Date(row.activities.edges[0].node.occurredAt ?? dateNow),
                                      dateNow,
                                    ) + ' ago'}
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </RouterLink>
                        </TableCell>
                        <TableCell sx={{ borderBottomColor: NEUTRALS.JASMINE }}>
                          <CustomerFitChip customerFit={row.customerFit} />
                        </TableCell>
                        <TableCell sx={{ borderBottomColor: NEUTRALS.JASMINE }}>
                          <ConnectionsTableCell
                            person={row}
                            userId={userId!}
                            teamConnections={teamConnections}
                            handleAskIntroduction={store.teams.people.askForIntroduction}
                          />
                        </TableCell>
                        <TableCell sx={{ borderBottomColor: NEUTRALS.JASMINE }}>
                          {/* {teamConnections.length && (
                            <IconButton
                              sx={{ background: NEUTRALS.CHAMOMILE }}
                              disabled={!teamConnections.length}
                              onClick={() => console.log('open introduction modal')}
                            >
                              <SpeechBubbleIcon />
                            </IconButton>
                          )} */}
                          <Typography variant="headline4" sx={{ color: GREY_PALETTE[6] }}>
                            {row.lastSeen
                              ? formatDistance(new Date(row.lastSeen ?? new Date()), new Date()) + ' ago'
                              : '---'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Column sx={{ padding: '28px 0' }}>
              {!people.loading && <Column ref={observe} />}
              {(people.loading || people.pageInfo?.hasNextPage) && <CaravelSpinner />}
              <Flex flex={1} sx={{ paddingTop: '20px', width: '100%' }} />
            </Column>
          </Box>
        )}
        {currentTab === 1 && (
          <>
            {notes.length > 0 ? (
              <>
                {notes.map(note => (
                  <OrganizationNote key={note.id} note={note} />
                ))}
              </>
            ) : (
              <NoResults
                title="No Notes"
                buttonText="Add Note"
                buttonIcon={<AddIcon />}
                onButtonClick={() => organizations.onAddNote()}
                sx={{ background: 'transparent' }}
              />
            )}
          </>
        )}
        {currentTab === 3 && (
          <Box marginTop="24px">
            <TableContainer>
              <Table size="small">
                <SortableTableHeadV2
                  cells={[
                    {
                      id: 'name',
                      label: 'Champion',
                      minWidth: '200px',
                    },
                    {
                      id: 'referenceCount',
                      label: 'References',
                      minWidth: '85px',
                      width: 'calc(100%/5)',
                      maxWidth: '300px',
                      disabledSort: true,
                    },
                  ]}
                  order={filterStore.peopleOrder}
                  orderBy={filterStore.peopleOrderBy}
                  onSort={handleRequestSort}
                />
                <TableBody>
                  {community.map(row => (
                    <TableRow key={row.id} sx={{ minHeight: '50px', height: '100%' }}>
                      <TableCell
                        padding="none"
                        sx={{
                          padding: '16px 0',
                          wordBreak: 'break-word',
                          borderBottomColor: NEUTRALS.JASMINE,
                        }}
                      >
                        <RouterLink
                          to={`/people/${row.id}`}
                          color="textPrimary"
                          sx={{ display: 'flex', alignItems: 'center' }}
                          title={row.name}
                        >
                          <Avatar
                            src={row.avatar}
                            alt={row.name}
                            sx={{
                              width: 40,
                              height: 40,
                              marginRight: '16px',
                              fontSize: '12px',
                            }}
                          >
                            {row.initials}
                          </Avatar>
                          <Box>
                            <Typography variant="inputLabel" component="p" marginBottom="4px">
                              {row.name}
                            </Typography>
                            <Typography variant="bodySmallest" component="p">
                              {row.employmentTitle}
                            </Typography>
                          </Box>
                        </RouterLink>
                      </TableCell>
                      <TableCell sx={{ borderBottomColor: NEUTRALS.JASMINE }}>
                        <Chip
                          label={row.referenceCount}
                          sx={{
                            color: GREY_PALETTE[5],
                            background: GREY_PALETTE[2],
                            borderRadius: '8px',
                            padding: '4px 8px',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Column sx={{ padding: '28px 0' }}>
              {!people.loading && <Column ref={observe} />}
              {people.loading || (people.pageInfo?.hasNextPage && <CaravelSpinner />)}
              <Flex flex={1} sx={{ paddingTop: '20px', width: '100%' }} />
            </Column>
          </Box>
        )}
      </Column>
    );
  },
);

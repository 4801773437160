import { MenuItem, MenuItemProps } from '@caravel/components/src';
import { GREY_PALETTE } from '@caravel/utils';
import React from 'react';

export const FilterMenuItem = React.forwardRef((props: MenuItemProps, ref: React.Ref<HTMLLIElement>) => {
  return (
    <MenuItem
      onKeyDown={e => e.stopPropagation()}
      {...props}
      ref={ref}
      sx={{
        padding: '5px 8px',
        minWidth: '185px',
        width: '100%',
        '&:hover': {
          backgroundColor: GREY_PALETTE[2],
        },
        ...props.sx,
      }}
    />
  );
});

FilterMenuItem.displayName = 'FilterMenuItem';

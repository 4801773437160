import { FilterMenuItem, FilterMenuSearch, FilterProps, SearchMultiFilterMenu } from '@caravel/components/src';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, SyntheticEvent, useCallback, useEffect, useState } from 'react';

// Copied from web/helpers as its unavailable outside web
function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * This component is used to render a filter menu that dynamically fetches options
 */
export const DynamicFilterMenu = observer((props: PropsWithChildren<FilterProps>) => {
  const { updateDynamicFilterQuery, collectionType, ...filterProps } = props;
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState<{ name: string; id: string }[] | null>(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const [pagePosition, setPagePosition] = useState(0);
  const [totalHits, setTotalHits] = useState(0);
  const DEFAULT_HITS_LENGTH = 50;
  const updateQuery = useCallback(
    async (value: string, first: number, after: string) => {
      // If we don't know what type of collection we're filtering, we can't continue
      if (!collectionType || !updateDynamicFilterQuery) {
        console.warn('DynamicFilterMenu: Missing required props');
        return;
      }
      setLoadingOptions(true);
      try {
        const response = await updateDynamicFilterQuery(collectionType, filterProps.type, value, first, after);
        return response;
      } catch (e) {
        console.error(e);
        return {
          matchedOptions: [],
          totalHits: 0,
        };
      } finally {
        setLoadingOptions(false);
      }
    },
    [collectionType, filterProps.type, updateDynamicFilterQuery],
  );

  const loadMore = async () => {
    let first = DEFAULT_HITS_LENGTH;
    const after = pagePosition.toString();
    const currentSearch = search;
    if (options && totalHits - options.length < DEFAULT_HITS_LENGTH) {
      first = totalHits - options.length;
    }

    if (first <= 0) {
      return;
    }

    updateQuery(currentSearch, first, after).then(results => {
      if (results) {
        setTotalHits(results.totalHits);
        if (options) {
          setOptions(options.concat(results.matchedOptions));
          setPagePosition(results.matchedOptions.length + pagePosition);
        } else {
          setOptions(results.matchedOptions);
          setPagePosition(results.matchedOptions.length);
        }
      }
    });
  };

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearch) {
        updateQuery(debouncedSearch, DEFAULT_HITS_LENGTH, '0').then(results => {
          if (results) {
            setOptions(results.matchedOptions);
            setTotalHits(results.totalHits);
            setPagePosition(results.matchedOptions.length);
          }
        });
      } else {
        setOptions([]);
      }
    },
    [debouncedSearch, updateQuery], // Only call effect if debounced search term changes
  );

  useEffect(() => {
    updateQuery('', DEFAULT_HITS_LENGTH, '0').then(results => {
      if (results) {
        setOptions(results.matchedOptions);
        setTotalHits(results.totalHits);
        setPagePosition(results.matchedOptions.length);
      }
    });
  }, [updateQuery]);

  const scrollHandler = (e: SyntheticEvent) => {
    if (e.currentTarget) {
      const target = e.currentTarget as Element;
      if (target.scrollHeight - target.scrollTop === target.clientHeight && !loadingOptions) {
        loadMore();
      }
    }
  };

  return (
    <>
      <FilterMenuItem
        sx={{
          minWidth: '250px',
          paddingLeft: '8px',
          '&.Mui-focusVisible': {
            backgroundColor: 'unset',
          },
        }}
      >
        <FilterMenuSearch
          value={search}
          onChange={e => {
            setSearch(e.currentTarget.value);
          }}
          loading={loadingOptions}
          autoFocus
        />
      </FilterMenuItem>
      {/* TODO ENG-2754: Add list of selected options */}
      <SearchMultiFilterMenu
        {...{
          ...filterProps,
          handleScroll: scrollHandler,
          options: {
            ...filterProps.options,
            values: options ? options!.filter(o => !filterProps.value.includes(o.name)).map(o => o.name) : [],
          },
        }}
      />
    </>
  );
});

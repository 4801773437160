import { SEND_WELCOME_EMAIL } from '@caravel/utils';
import {
  AuthError,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
  updateProfile,
  User,
  UserCredential,
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseApp, getDb, getFirestoreAuth, history, Logger, serverTimestamp } from 'helpers';
import { action, computed, makeObservable, observable, when } from 'mobx';
import { RootStore } from 'stores/root';

import { BaseStore } from './base';

const { debug } = new Logger('auth-store');

const nameFromEmail = (email: string) => email.substring(0, email.indexOf('@'));

export interface InviteParams {
  teamId: string;
  inviteId: string;
}

export type MaybeAuthError = AuthError | undefined;

/**
 * This is a data store for authentication with firebase
 */
export class AuthStore extends BaseStore {
  debugName = 'AuthStore';

  /**
   * Is there a user signed in?
   */
  get signedIn(): boolean {
    return this.user !== undefined;
  }

  /**
   * The current firebase authentication user, if present
   */
  get user(): User | undefined {
    return this._user || undefined;
  }

  _user?: User | null = undefined;
  _initStatus = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable<AuthStore>(this, {
      signedIn: computed,
      user: computed,
      _user: observable,
      _initStatus: observable,
      onAuthStateChanged: action,
    });
  }

  async setup() {
    await super.setup();

    this.addDisposable(onAuthStateChanged(getFirestoreAuth(), user => this.onAuthStateChanged(user)));
    try {
      // There really isn't a firebase function to just get the logged in state. There is only the `onAuthStateChanged`
      // handler, and the `.currentUser` property. Our app however, is not considered to be initialized without that
      // very first authentication check, which happens on the event loop. To get around this, we flip an observable
      // flag in the auth callback and we wait for it here. This way, when the app loads we'll know for certain
      // (unless a timeout occurs) that the user is logged in or not.
      await when(() => this._initStatus, { timeout: 5000 });
    } catch (error) {
      debug('timed out waiting for init status', error);
    }
  }

  teardown() {
    this._user = undefined;
    this._initStatus = false;
    super.teardown();
  }

  /**
   * Send a 'Password Reset' email to the given email address.
   */
  resetPassword = async (email: string): Promise<MaybeAuthError> => {
    try {
      await sendPasswordResetEmail(getFirestoreAuth(), email);
    } catch (error: any) {
      debug('error sending reset password email', error);
      return error;
    }
  };

  /**
   * Sign the user in with an email and password
   */
  signIn = async (email: string, password: string): Promise<MaybeAuthError> => {
    try {
      const credentials = await signInWithEmailAndPassword(getFirestoreAuth(), email, password);
      if (!credentials.user) {
        throw new Error();
      }
      await this.createMemberIfNotExists(credentials, credentials.user.displayName || nameFromEmail(email));
    } catch (error: any) {
      debug('error signing in', error);
      return error;
    }
  };

  signInWithEmail = async (email: string, inviteParams?: InviteParams): Promise<MaybeAuthError> => {
    const origin = location.origin;
    try {
      const url = inviteParams
        ? `${origin}/finish-sign-in?inviteId=${inviteParams.inviteId}&teamId=${inviteParams.teamId}`
        : `${origin}/finish-sign-in`;
      await sendSignInLinkToEmail(getFirestoreAuth(), email, {
        handleCodeInApp: true,
        url,
      });
      window.localStorage.setItem('emailForSignIn', email);
    } catch (error: any) {
      debug('error signing in', error);
      return error;
    }
  };

  /**
   * Sign the user in with Google auth provider
   */
  signInWithGoogle = async (): Promise<MaybeAuthError> => {
    try {
      const provider = new GoogleAuthProvider();
      const credentials = await signInWithPopup(getFirestoreAuth(), provider);
      if (!credentials.user) {
        throw new Error();
      }
      await this.createMemberIfNotExists(
        credentials,
        credentials.user.displayName || nameFromEmail(credentials.user.email!),
      );
    } catch (error: any) {
      debug('error signing in', error);
      return error;
    }
  };

  /**
   * Sign the user out of firebase
   */
  signOut = async (): Promise<MaybeAuthError> => {
    if (!this.signedIn) {
      return;
    }
    try {
      await signOut(getFirestoreAuth());
      analytics.track('User Signed Out');
      analytics.reset();
    } catch (error: any) {
      debug('error signing out', error);
      return error;
    }
  };

  identifyAndTrackSignUp = (user: User | null) => {
    if (user) {
      analytics.identify(user.uid, {
        name: user.displayName,
        email: user.email,
        avatar: user.photoURL,
        createdAt: user.metadata.creationTime,
      });
      analytics.track('User Signed Up', {
        full_name: user.displayName,
        email: user.email,
      });
    }
  };

  /**
   * Sign the user up with an email and password
   */
  signUp = async (email: string, password: string): Promise<MaybeAuthError> => {
    try {
      const passes = await this.checkEmailDenylist(email);
      if (!passes) {
        throw new Error('email denied');
      }
      const credentials = await createUserWithEmailAndPassword(getFirestoreAuth(), email, password);
      if (!credentials.user) {
        throw new Error();
      }
      sendEmailVerification(credentials.user);
      await this.createMemberIfNotExists(
        credentials,
        credentials.user.displayName || nameFromEmail(credentials.user.email!),
      );
      this.identifyAndTrackSignUp(credentials.user);
    } catch (error: any) {
      debug('error signing up', error);
      return error;
    }
  };

  signUpWithGoogle = async (): Promise<MaybeAuthError> => {
    try {
      const provider = new GoogleAuthProvider();
      const credentials = await signInWithPopup(getFirestoreAuth(), provider);
      if (!credentials.user) {
        throw new Error();
      }
      await this.createMemberIfNotExists(
        credentials,
        credentials.user.displayName || nameFromEmail(credentials.user.email!),
      );
      this.identifyAndTrackSignUp(credentials.user);
    } catch (error: any) {
      debug('error signing up', error);
      return error;
    }
  };

  signUpWithEmail = async (email: string, inviteParams?: InviteParams): Promise<MaybeAuthError> => {
    try {
      const passes = await this.checkEmailDenylist(email);
      if (!passes) {
        throw new Error('email denied');
      }
      const url = inviteParams
        ? `${origin}/finish-sign-up?inviteId=${inviteParams.inviteId}&teamId=${inviteParams.teamId}`
        : `${origin}/finish-sign-up`;
      await sendSignInLinkToEmail(getFirestoreAuth(), email, {
        handleCodeInApp: true,
        url,
      });
      window.localStorage.setItem('emailForSignUp', email);
    } catch (error: any) {
      debug('error signing up', error);
      return error;
    }
  };

  getEmailFromStorage = (key: string) => {
    let email = window.localStorage.getItem(key);
    window.localStorage.removeItem(key);
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }
    return email;
  };

  finishSignUp = async (name: string): Promise<MaybeAuthError> => {
    if (isSignInWithEmailLink(getFirestoreAuth(), window.location.href)) {
      const email = this.getEmailFromStorage('emailForSignUp');
      try {
        const credentials = await signInWithEmailLink(getFirestoreAuth(), email!, window.location.href);
        if (!credentials.user) {
          throw new Error();
        }
        await this.createMemberIfNotExists(credentials, name || nameFromEmail(email!));
        this.identifyAndTrackSignUp(credentials.user);
      } catch (error: any) {
        debug('error finishing sign up', error);
        return error;
      }
    }
  };

  finishSignInWithEmail = async () => {
    if (isSignInWithEmailLink(getFirestoreAuth(), window.location.href)) {
      const email = this.getEmailFromStorage('emailForSignIn');
      try {
        const credentials = await signInWithEmailLink(getFirestoreAuth(), email!, window.location.href);
        if (!credentials.user) {
          throw new Error();
        }
        await this.createMemberIfNotExists(credentials, credentials.user.displayName || nameFromEmail(email!));
      } catch (error: any) {
        debug('error finishing sign in', error);
        return error;
      }
    }
  };

  createMemberIfNotExists = async (credentials: UserCredential, name: string) => {
    if (!credentials.user) {
      throw new Error();
    }
    const { uid, email } = credentials.user;
    if (!email || !uid) {
      throw new Error('Missing credentials');
    }
    const memberDoc = await getDoc(doc(getDb(), 'members', uid));
    if (!memberDoc.exists()) {
      if (!(await this.checkEmailDenylist(email))) {
        history.push('/sign-up');
        return;
      }
      debug('creating member document');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await updateProfile(getFirestoreAuth().currentUser, {
        displayName: name,
      });
      await setDoc(doc(getDb(), 'members', uid), {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        archivedAt: null,
        email,
        name,
      });
      when(
        () => this.rootStore.members.current !== undefined,
        async () => {
          this.rootStore.sendHubEvent(`${email} just created an account!`);
          const functions = getFunctions(firebaseApp);
          await httpsCallable(functions, SEND_WELCOME_EMAIL)({ email, name });
        },
      );
    }
  };

  checkEmailDenylist = async (email: string): Promise<boolean> => {
    debug('checking email denylist');

    try {
      const configRef = doc(getDb(), 'configs', 'email');
      const emailConfig = await getDoc(configRef);
      const domainDenylist: string[] = emailConfig.get('domainDenylist') || [];
      const emailPasslist: string[] = emailConfig.get('emailPasslist') || [];
      const matchedDomains = domainDenylist.filter(domain => email.endsWith(domain));
      if (!matchedDomains.length || emailPasslist.includes(email)) {
        debug('passed email check');
        return true;
      }

      debug('failed email check');
      this.rootStore.notifications.display({
        message: 'Please use your business email address, or contact support@commsor.com',
        severity: 'error',
      });
      await this.signOut();

      return false;
    } catch (e: any) {
      debug('what', e);
      throw e;
    }
  };

  onAuthStateChanged = (user: User | null) => {
    if (!this._initStatus) {
      this._initStatus = true;
    }
    this._user = user;
    if (this._user) {
      analytics.track('User Signed In');
    }
  };
}

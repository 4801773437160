export type Emotions =
  | 'admiration'
  | 'amusement'
  | 'anger'
  | 'annoyance'
  | 'approval'
  | 'caring'
  | 'confusion'
  | 'curiosity'
  | 'desire'
  | 'disgust'
  | 'disappointment'
  | 'disapproval'
  | 'embarrassed'
  | 'excitement'
  | 'fear'
  | 'gratitude'
  | 'grief'
  | 'joy'
  | 'love'
  | 'nervousness'
  | 'neutral'
  | 'optimism'
  | 'pride'
  | 'realization'
  | 'relief'
  | 'remorse'
  | 'sadness'
  | 'surprise';

export const ENABLED_EMOTIONS: Emotions[] = [
  'admiration',
  'amusement',
  'anger',
  'annoyance',
  'approval',
  'caring',
  'confusion',
  'curiosity',
  'desire',
  'disgust',
  'disappointment',
  'disapproval',
  'embarrassed',
  'excitement',
  'fear',
  'gratitude',
  'grief',
  'joy',
  'love',
  'nervousness',
  'optimism',
  'pride',
  'realization',
  'relief',
  'remorse',
  'sadness',
  'surprise',
];

export const REPORT_NAMES: Record<string, string> = {
  'cx-summary': 'CX digest',
  'churn-risks': 'Churn risks',
  'emerging-themes': 'Emerging themes',
  'csat-nps': 'CSAT & NPS drivers',
  'customer-service-qa': 'Internal quality',
  'ratings-themes': 'Rating & review drivers',
  'sentiment-trends': 'Sentiment trends',
  'topic-trends': 'Topic trends',
  'community-advocates': 'Advocates',
  'user-complaints': 'Complainers',
  'team-qa': 'Team QA',
};

export const REPORT_DESCRIPTIONS: Record<string, string> = {
  'churn-risks': 'Identify at-risk companies and ones in good standing',
  'csat-nps': `Calculate CSAT and NPS scores from survey ratings and understand what's influencing your scores`,
  'customer-service-qa': 'Report on how your team is performing according to internal standards',
  'ratings-themes': `Get insight into your average ratings and reviews and understand what's influencing your scores`,
  'sentiment-trends': 'Dive deep into sentiment and break it down by topic, company, and users',
  'topic-trends': `Analyze classification trends over-time and surface what's influencing positive or negative sentiment`,
  'community-advocates': 'Surface which users are your greatest advocates',
  'user-complaints': 'Identify your most complacent users and the issues that influence them',
  'cx-summary': 'Get an overview of feed engagement by reporting on sentiment and classifier mentions',
  'emerging-themes': 'Automatically identify trending key phrases and group similar responses into themes',
  'team-qa': 'Track team members outcomes and their customer’s satisfaction',
};

export const REPORT_BADGES: Record<string, string> = {
  'cx-summary': 'New',
  'emerging-themes': 'New',
  'churn-risks': 'Coming soon',
  'csat-nps': `Coming soon`,
  'customer-service-qa': 'Coming soon',
  'ratings-themes': `Coming soon`,
  'sentiment-trends': 'Coming soon',
  'topic-trends': `Coming soon`,
  'community-advocates': 'Coming soon',
  'user-complaints': 'Coming soon',
  'team-qa': 'New',
};

export const DISABLED_REPORTS: string[] = [
  'csat-nps',
  'customer-service-qa',
  'ratings-themes',
  'community-advocates',
  'user-complaints',
];

/**
 * Default report metric configurations
 */

export type ReportMetrics =
  | 'mentions'
  | 'sentiment'
  | 'aspects'
  | 'approval'
  | 'last-response'
  | 'total-survey-responses'
  | 'average-rating'
  | 'csat'
  | 'nps'
  | 'themes'
  | 'team-sentiment'
  | 'customer-sentiment'
  | 'team-csat'
  | 'team-qa';

export type ReportMetricVariant = 'summary' | 'trend';

export interface MetricConfig {
  type: ReportMetrics;
  variant: ReportMetricVariant;
}

export const MENTIONS: MetricConfig = {
  type: 'mentions',
  variant: 'summary',
};

export const SENTIMENT: MetricConfig = {
  type: 'sentiment',
  variant: 'summary',
};

export const ASPECTS: MetricConfig = {
  type: 'aspects',
  variant: 'summary',
};

export const APPROVAL: MetricConfig = {
  type: 'approval',
  variant: 'summary',
};

export const LAST_RESPONSE: MetricConfig = {
  type: 'last-response',
  variant: 'summary',
};

export const TOTAL_SURVEY_RESPONSES: MetricConfig = {
  type: 'total-survey-responses',
  variant: 'summary',
};

export const AVERAGE_RATING: MetricConfig = {
  type: 'average-rating',
  variant: 'summary',
};

// CSAT is different from TEAM_CSAT used in team-qa
export const CSAT: MetricConfig = {
  type: 'csat',
  variant: 'summary',
};

export const NPS: MetricConfig = {
  type: 'nps',
  variant: 'summary',
};

export const THEMES: MetricConfig = {
  type: 'themes',
  variant: 'trend',
};

export const TEAM_SENTIMENT: MetricConfig = {
  type: 'team-sentiment',
  variant: 'summary',
};

export const CUSTOMER_SENTIMENT: MetricConfig = {
  type: 'customer-sentiment',
  variant: 'summary',
};

export const TEAM_CSAT: MetricConfig = {
  type: 'team-csat',
  variant: 'trend',
};

export const TEAM_QA: MetricConfig = {
  type: 'team-qa',
  variant: 'trend',
};

/**
 * Report configurations
 */

export const REPORT_PROPS: Record<string, Partial<any>> = {
  'churn-risks': {},
  'community-advocates': {},
  'csat-nps': {},
  'customer-service-qa': {},
  'cx-summary': {
    metrics: [
      SENTIMENT,
      APPROVAL,
      MENTIONS,
      { ...SENTIMENT, variant: 'trend' },
      { ...MENTIONS, variant: 'trend' },
      { ...ASPECTS, variant: 'trend' },
    ],
  },
  'ratings-themes': {},
  'sentiment-trends': {},
  'emerging-themes': {
    metrics: [THEMES],
  },
  'topic-trends': {},
  'user-complaints': {},
  'team-qa': {
    metrics: [
      TEAM_SENTIMENT,
      CUSTOMER_SENTIMENT,
      MENTIONS,
      { ...CUSTOMER_SENTIMENT, variant: 'trend' },
      TEAM_CSAT,
      { ...MENTIONS, variant: 'trend' },
      TEAM_QA,
    ],
  },
};

import { Column } from '@caravel/components/src';
import { Button, Typography } from '@mui/material';
import { useQuery } from 'helpers';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { OnboardingWrapper } from '../onboarding-wrapper';
import { SignInEmail } from './sign-in-email';
import { SignInView } from './sign-in-view';

type SignInStep = 'signin' | 'signin-email';

export const SignInContainer = observer(() => {
  const store = useStore();
  const history = useHistory();
  const query = useQuery();
  const [message, setMessage] = useState<string | undefined>();
  const [step, setStep] = useState<SignInStep>('signin');

  const onSwitchToEmail = () => {
    setStep('signin-email');
  };

  const onSignInWithGoogle = async () => {
    await store.ui.workOn(async () => {
      setMessage(`If an account associated with the provided email address exists, we'll take you to your feed.`);
      const error = await store.auth.signInWithGoogle();
      if (!error) {
        history.push('/');
      }
    });
  };

  const onSignInWithEmail = async (email: string) => {
    await store.ui.workOn(async () => {
      setMessage(
        `If an account associated with the provided email address exists, an email containing instructions for signing in will be sent.`,
      );
      const teamId = query.get('teamId') || undefined;
      const inviteId = query.get('inviteId') || undefined;
      await store.auth.signInWithEmail(email, teamId && inviteId ? { teamId, inviteId } : undefined);
    });
  };

  const onSignIn = async (email: string, password: string) => {
    await store.ui.workOn(async () => {
      setMessage(`If an account associated with the provided email address exists, we'll take you to your feed.`);
      try {
        const error = await store.auth.signIn(email, password);
        if (!error) {
          history.push('/');
        }
      } catch {
        // do nothing
      }
    });
  };

  const onResetPassword = async (email: string) => {
    await store.ui.workOn(async () => {
      setMessage(
        `If an account associated with the provided email address exists, an email containing instructions for resetting your password will be sent.`,
      );
      try {
        await store.auth.resetPassword(email);
      } catch {
        // do nothing
      }
    });
  };

  const onBackToSignIn = () => {
    setMessage(undefined);
    setStep('signin');
  };

  if (message) {
    return (
      <OnboardingWrapper>
        <Column alignItems="stretch" width="100%" maxWidth="360px">
          <Typography variant="headline1" component="h1" marginBottom="40px" textAlign="center">
            {message}
          </Typography>
          <Button color="primary" variant="contained" fullWidth size="large" onClick={onBackToSignIn}>
            Back
          </Button>
        </Column>
      </OnboardingWrapper>
    );
  }

  return (
    <OnboardingWrapper>
      {step === 'signin' && <SignInView onSignInWithGoogle={onSignInWithGoogle} onSwitchToEmail={onSwitchToEmail} />}
      {step === 'signin-email' && (
        <SignInEmail onSignInWithEmail={onSignInWithEmail} onSignIn={onSignIn} onResetPassword={onResetPassword} />
      )}
    </OnboardingWrapper>
  );
});

import React from 'react';

import { ReactComponent as ExportPeople } from '../../assets/svgs/align-arrow-down.svg';
import { ReactComponent as AppsIcon } from '../../assets/svgs/apps-large.svg';
import { ReactComponent as BoltIcon } from '../../assets/svgs/bolt.svg';
import { ReactComponent as SpeechBubbleIcon } from '../../assets/svgs/speech-bubble.svg';
import { ReactComponent as TargetIcon } from '../../assets/svgs/target.svg';
const SegmentsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2083 3.95837H5.62499C4.70452 3.95837 3.95833 4.70457 3.95833 5.62504V10.2084C3.95833 11.1288 4.70452 11.875 5.62499 11.875H10.2083C11.1288 11.875 11.875 11.1288 11.875 10.2084V5.62504C11.875 4.70457 11.1288 3.95837 10.2083 3.95837Z"
      stroke="#141414"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0417 14.375V9.79167C16.0417 8.87119 15.2955 8.125 14.375 8.125H9.79167C8.87119 8.125 8.125 8.87119 8.125 9.79167V14.375C8.125 15.2955 8.87119 16.0417 9.79167 16.0417H14.375C15.2955 16.0417 16.0417 15.2955 16.0417 14.375Z"
      stroke="#141414"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const AddIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4.79169V15.2084" stroke="#6246EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.2082 10H4.7915" stroke="#6246EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const SearchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0418 16.0417L12.9168 12.9167M3.9585 9.16666C3.9585 6.29018 6.29035 3.95833 9.16683 3.95833C12.0433 3.95833 14.3752 6.29018 14.3752 9.16666C14.3752 12.0431 12.0433 14.375 9.16683 14.375C6.29035 14.375 3.9585 12.0431 3.9585 9.16666Z"
      stroke="#4A5980"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const EditIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.95825 16.0417L7.49992 15.2083L15.244 7.46425C15.5694 7.13881 15.5694 6.61118 15.244 6.28574L13.7142 4.75592C13.3887 4.43048 12.8611 4.43048 12.5357 4.75592L4.79159 12.5L3.95825 16.0417Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.0416 16.0416H11.4583" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
const TrashCanIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.79175 6.45837L5.49271 14.5194C5.5676 15.3807 6.2886 16.0417 7.15311 16.0417H12.0137C12.8782 16.0417 13.5992 15.3807 13.6741 14.5194L14.3751 6.45837H4.79175Z"
      stroke="#B23858"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.125 8.95837V13.5417" stroke="#B23858" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.0417 8.95837V13.5417"
      stroke="#B23858"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.29175 6.45837V5.62504C7.29175 4.70457 8.03794 3.95837 8.95841 3.95837H10.2084C11.1289 3.95837 11.8751 4.70457 11.8751 5.62504V6.45837"
      stroke="#B23858"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.95825 6.45837H15.2083"
      stroke="#B23858"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const XIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.05018 6.46447L8.58572 10L5.05018 13.5355L6.4644 14.9497L9.99993 11.4142L13.5355 14.9497L14.9497 13.5355L11.4141 10L14.9497 6.46447L13.5355 5.05025L9.99993 8.58579L6.4644 5.05025L5.05018 6.46447Z"
      fill="#4A5980"
    />
  </svg>
);
export {
  SegmentsIcon,
  AppsIcon,
  BoltIcon,
  SpeechBubbleIcon,
  TargetIcon,
  TrashCanIcon,
  EditIcon,
  SearchIcon,
  AddIcon,
  XIcon,
  ExportPeople,
};

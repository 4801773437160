import { gql } from '@apollo/client';
import { ComposerMessageKind } from '@caravel/types';

export const TEST_COMPOSER_MESSAGE = gql`
  mutation testComposerMessage($input: TestComposerMessageInput!) {
    testComposerMessage(input: $input) {
      success
    }
  }
`;

export interface GqlTestComposerRequestType {
  input: {
    content: string;
    messageKind: ComposerMessageKind;
    channelId: string;
  };
}

export interface GqlTestComposerResponseType {
  testComposerMessage: {
    success: boolean;
  };
}

import { AddButton, FilterMenu, FilterMenuItem } from '@caravel/components/src';
import { Filter } from '@caravel/types/src';
import { LAVENDER } from '@caravel/utils';
import { Divider, Typography } from '@mui/material';
import React, { forwardRef, PropsWithChildren, useState } from 'react';

export const AddFilterMenu = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<{
    onAddFilter: (filter: Filter) => void;
    anchorEl: HTMLButtonElement | null;
    handleSetAnchor: (event: React.MouseEvent<HTMLButtonElement> | null) => void;
  }>
>((props, ref) => {
  const { anchorEl, handleSetAnchor } = props;
  return (
    <>
      <AddButton
        ref={ref}
        onClick={e => handleSetAnchor(e)}
        sx={{
          ...(anchorEl ? { backgroundColor: LAVENDER[50] } : {}),
          borderColor: LAVENDER[300],
          minWidth: 0,
          width: '44px',
          '& .MuiButton-startIcon': {
            margin: 0,
            color: LAVENDER[300],
          },
        }}
      />
      <FilterMenu open={Boolean(anchorEl)} onClose={() => handleSetAnchor(null)} anchorEl={anchorEl}>
        <FilterMenuItem disabled>
          <Typography variant="bodySmaller">Filter attributes by:</Typography>
        </FilterMenuItem>
        <Divider
          sx={{
            '&.MuiDivider-root': {
              marginTop: '5px',
              marginBottom: '5px',
            },
          }}
        />
        {props.children}
      </FilterMenu>
    </>
  );
});
AddFilterMenu.displayName = 'AddFilterMenu';

export type StyleTokens =
  | 'Action Blue'
  | 'Action Blue Hover'
  | 'Action Blue Pressed'
  | 'Avatar 1'
  | 'Avatar 2'
  | 'Avatar 3'
  | 'Avatar 4'
  | 'Black'
  | 'Button Secondary Shadow Color'
  | 'Dark Grey'
  | 'Dialog Shadow Color'
  | 'Light Grey'
  | 'Light Soft Grey'
  | 'Medium Grey'
  | 'Menu'
  | 'Menu Shadow Color'
  | 'Mixed'
  | 'Mixed Light'
  | 'Negative'
  | 'Negative Light'
  | 'Neutral'
  | 'Neutral Light'
  | 'Offwhite'
  | 'Positive'
  | 'Positive Light'
  | 'Soft Grey'
  | 'Steel Grey'
  | 'Switch Track'
  | 'Text Highlight'
  | 'Tooltip'
  | 'Transparent'
  | 'Very Negative'
  | 'Very Negative Light'
  | 'Very Positive'
  | 'Very Positive Light'
  | 'White'
  | 'White Hover'
  | 'White Pressed';
const colorTokens = {
  'Action Blue': 'rgb(55, 110, 255)',
  'Action Blue Hover': 'rgb(83, 131, 255)',
  'Action Blue Pressed': 'rgb(44, 94, 241)',
  'Avatar 1': 'rgb(198, 232, 210)',
  'Avatar 2': 'rgb(245, 175, 160)',
  'Avatar 3': 'rgb(172, 224, 234)',
  'Avatar 4': 'rgb(223, 212, 189)',
  Black: 'rgb(26, 27, 33)',
  'Button Secondary Shadow Color': 'rgba(238, 240, 246, 0.48)',
  'Dark Grey': 'rgb(103, 106, 125)',
  'Dialog Shadow Color': 'rgba(26, 27, 33, 0.32)',
  'Light Grey': 'rgb(224, 227, 238)',
  'Light Soft Grey': 'rgb(245, 246, 249)',
  'Medium Grey': 'rgb(149, 153, 175)',
  Menu: 'rgba(26, 27, 33, 0.96)',
  'Menu Shadow Color': 'rgba(26, 27, 33, 0.16)',
  Mixed: 'rgb(251, 212, 83)',
  'Mixed Light': 'rgb(255, 249, 232)',
  Negative: 'rgb(243, 117, 117)',
  'Negative Light': 'rgb(253, 240, 240)',
  Neutral: 'rgb(188, 193, 202)',
  'Neutral Light': 'rgb(247, 248, 249)',
  Offwhite: 'rgb(246, 247, 250)',
  Positive: 'rgb(118, 213, 116)',
  'Positive Light': 'rgb(240, 250, 240)',
  'Soft Grey': 'rgb(236, 238, 245)',
  'Steel Grey': 'rgb(184, 187, 204)',
  'Switch Track': 'rgb(94, 214, 252)',
  'Text Highlight': 'rgb(255, 241, 146)',
  Tooltip: 'rgba(26, 27, 33, 0.8)',
  Transparent: 'rgba(0, 0, 0, 0)',
  'Very Negative': 'rgb(209, 34, 34)',
  'Very Negative Light': 'rgb(249, 232, 232)',
  'Very Positive': 'rgb(36, 166, 33)',
  'Very Positive Light': 'rgb(232, 245, 231)',
  White: 'rgb(255, 255, 255)',
  'White Hover': 'rgba(255, 255, 255, 0.7)',
  'White Pressed': 'rgba(255, 255, 255, 0.5)',
};

/**
 * @deprecated Please use provided theme palette or use color-context where absolutely necessary.
 */
export function getColor(name: StyleTokens): string {
  const color = colorTokens[name];
  if (!color) {
    console.error('Unknown color named', name);
    // visualize the error with an obviously-out-of-place purple.
    return 'rgb(128,0,128)';
  }
  return color;
}

import { OrganizationDetail } from 'components/organizations/organization-detail';
import { OrganizationsList } from 'components/organizations/organizations-list';
import { Logger, useAppReadyCheck } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('feeds-routes');

export const OrganizationsRoutes: FC = observer(() => {
  const store = useStore();

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> rendering organizations`);

  return (
    <Switch>
      <Route path="/organizations/:orgId" component={OrganizationDetail} />
      <Route path="/organizations" component={OrganizationsList} />
    </Switch>
  );
});

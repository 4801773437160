import { FirestoreTimestamp } from 'helpers';
import isEqual from 'lodash/isEqual';
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { v4 } from 'uuid';

const uuid = v4;

/**
 * These ID constants are useful when working with 'guaranteed' ids from firestore
 */
export const PENDING_ID = 'caravel-pending';
export const ERROR_ID = 'caravel-error';

export interface FirestoreProps {
  id: string;
  createdAt: FirestoreTimestamp;
  updatedAt?: FirestoreTimestamp;
}

/**
 * Creates a `toJS` method to serialize a model to a plain object
 * @param skeleton An 'empty' model implementing `ModelProps`
 * @param model A `BaseModel` instance to modify
 */
export function makeToJS<ModelProps extends Record<string, any>>(skeleton: ModelProps, model: BaseModel<ModelProps>) {
  return (): ModelProps => {
    const out: any = {};
    ['uid', ...Object.keys(skeleton)].forEach(k => (out[k] = toJS(model[k])));
    return out;
  };
}

/**
 * Creates an `update` method that accepts partial `ModelProps` to update a model. Runs updates within a mobx action.
 * @param skeleton An 'empty' model implementing `ModelProps`
 * @param model A `BaseModel` instance to modify
 */
export function makeUpdate<ModelProps extends Record<string, any>>(skeleton: ModelProps, model: BaseModel<ModelProps>) {
  const allowedProps = Object.keys(skeleton);
  return (props: Partial<ModelProps>) => {
    runInAction(() => {
      Object.keys(props)
        .filter(p => allowedProps.includes(p))
        .forEach(key => {
          model[key] = props[key];
        });
    });
  };
}

/**
 * This is a serializable model that is useful for mirroring firestore document types.
 * Each subclass needs a `toJS` and `update` method implemented with `makeToJS` and `makeUpdate`.
 */
export class BaseModel<ModelProps extends Record<string, any>> {
  [key: string]: any;

  readonly uid = uuid();
  cache?: ModelProps = undefined;

  get isStateCached() {
    return this.cache !== undefined;
  }

  get hasChanges() {
    return !isEqual(this.cache, this.toJS());
  }

  constructor() {
    makeObservable(this, {
      isStateCached: computed,
      cache: observable,
      hasChanges: computed,
      cacheState: action,
      applyCache: action,
    });
  }

  applyCache = (props?: string[]) => {
    if (this.cache) {
      if (props) {
        for (const prop of props) {
          if (this.cache[prop]) {
            this.update({
              [prop]: this.cache[prop],
            });
          }
        }
      } else {
        this.update(this.cache);
      }
      this.cache = undefined;
    }
  };

  cacheState = () => {
    this.cache = this.toJS();
  };

  clearCache = () => (this.cache = undefined);

  getCachedProp = (prop: string) => {
    return this.cache ? this.cache[prop] : undefined;
  };
}

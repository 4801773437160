import { CaravelSpinner, Column, Flex, Row } from '@caravel/components/src';
import { Person } from '@caravel/types/src';
import { Activity } from '@caravel/types/src/activities';
import { ChannelKind } from '@caravel/types/src/channels';
import { GREY_PALETTE } from '@caravel/utils';
import { List, SvgIcon, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-cool-inview';
import { useStore } from 'stores';

import { ActivityClassification, ActivitySentiment } from '../activities';
import { ActivityHeader } from '../activities/ActivityHeader';
import { CalendarIcon, GmailIcon, SlackIcon, TwitterIcon } from '../people-icons';
import { ActivityFeedContent } from './feed-content';

const iconMap: Record<ChannelKind | ':nylas', React.ReactNode> = {
  ':api': null,
  ':activecampaign': null,
  ':circle': null,
  ':clearbit': null,
  ':csv-import': null,
  ':discourse': null,
  ':drip': null,
  ':email': <GmailIcon />,
  ':nylas': <GmailIcon />,
  ':events': <CalendarIcon />,
  ':github': null,
  ':github-public': null,
  ':hubspot': null,
  ':insided': null,
  ':mailchimp': null,
  ':manual': null,
  ':override': null,
  ':pipedrive': null,
  ':slack': <SlackIcon />,
  ':signup': null,
  ':twitter': <TwitterIcon />,
  ':vanilla': null,
  ':zendesk': null,
  ':zapier': null,
  ':zoom': null,
};

const ActivityFeedItem = observer((props: { activity: Activity }) => {
  const { activity } = props;
  const Icon = iconMap[activity.channel.kind];
  const classifications = activity.article?.classifications?.slice();
  const sentiment = activity.article?.sentiment;

  return (
    <Row
      component="li"
      sx={{
        width: '100%',
      }}
    >
      <Column sx={{ alignItems: 'center' }}>
        <SvgIcon
          sx={{
            boxSizing: 'border-box',
            width: 30,
            height: 30,
            borderRadius: '50%',
            border: `2px solid ${GREY_PALETTE[2]}`,
            padding: '4px',
          }}
        >
          {Icon}
        </SvgIcon>
        <Flex
          sx={{
            height: '100%',
            minHeight: '20px',
            width: '2px',
            backgroundColor: GREY_PALETTE[2],
          }}
        />
      </Column>
      <Column sx={{ width: '100%' }}>
        {/**
         * TODO: Update This header, maybe extract into own component?
         */}
        <ActivityHeader activity={activity} />
        {activity.textContent && (
          <Column
            sx={{
              margin: '5px 0 0 10px',
            }}
          >
            <ActivityFeedContent text={activity.textContent} type={activity.kind} />
            {/**
             * TODO: Update footer to contain both sentiment and classifications
             */}
            <Row
              sx={{
                marginTop: '10px',
                flexWrap: 'wrap',
                gap: '10px',
                ...(classifications?.length ? { marginBottom: '10px' } : {}),
              }}
            >
              {sentiment ? <ActivitySentiment sentiment={sentiment} /> : null}
              {classifications?.length
                ? classifications.map(c => <ActivityClassification key={`${activity.id}-${c}`} classification={c} />)
                : null}
            </Row>
          </Column>
        )}
      </Column>
    </Row>
  );
});

export const ActivityFeed = observer((props: { person: Person }) => {
  const { person } = props;
  const store = useStore();
  const {
    ui,
    teams: { people },
  } = store;

  const canLoad = (!people.activitiesPageInfo || people.activitiesPageInfo?.hasNextPage) && !people.activitiesLoading;
  const activities = people.activities.slice();

  const loadMore = useCallback(() => {
    if (!canLoad) return;
    ui.workOn(async () => {
      await people.fetchActivities(person.id!, people.activitiesPageInfo?.endCursor, 40);
    });
  }, [canLoad, people, person.id, ui]);

  const { observe } = useInView({
    onEnter: () => {
      loadMore();
    },
  });

  useEffect(() => {
    if (!activities.length) {
      loadMore();
    }
  }, [activities.length, loadMore]);

  return (
    <Column paddingY="16px">
      <Column component={List}>
        {activities.map(activity => (
          <ActivityFeedItem key={activity.id} activity={activity} />
        ))}
      </Column>
      {!people.activitiesLoading && <Column ref={observe} />}
      {(people.activitiesLoading || people.activitiesPageInfo?.hasNextPage) && <CaravelSpinner />}
      {people.activitiesPageInfo && !people.activitiesPageInfo.hasNextPage && <Typography>No more activity</Typography>}
      <Flex flex={1} sx={{ paddingTop: '40px', width: '100%' }} />
    </Column>
  );
});

import { GREY_PALETTE } from '@caravel/utils';
import { SvgIcon, SxProps, Typography } from '@mui/material';
import { borderRadius } from '@mui/system';
import React from 'react';

import { Row } from './flex';

export const IconPill = (props: { icon: React.ReactNode; text: string; sx?: SxProps }) => {
  const { icon, text, sx } = props;
  return (
    <Row
      sx={{
        ...{
          borderRadius: '8px',
          padding: '4px 8px',
          width: 'fit-content',
          background: GREY_PALETTE[2],
          color: GREY_PALETTE[5],
          gap: 0.5,
          alignItems: 'center',
        },
        ...{ ...sx },
      }}
    >
      {icon}
      <Typography variant="bodySmallest">{text}</Typography>
    </Row>
  );
};

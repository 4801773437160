import { IconButton, SvgIcon, Tooltip, useTheme } from '@mui/material';
import React from 'react';

const InformationIcon = () => {
  const theme = useTheme();
  return (
    <>
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 10.8333V12.4999"
          stroke={theme.grey[3]}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4168 7.49992C10.4168 7.73004 10.2303 7.91658 10.0002 7.91658C9.77004 7.91658 9.5835 7.73004 9.5835 7.49992C9.5835 7.2698 9.77004 7.08325 10.0002 7.08325C10.2303 7.08325 10.4168 7.2698 10.4168 7.49992Z"
          stroke={theme.grey[3]}
        />
        <path
          d="M16.0418 9.99992C16.0418 13.3366 13.3369 16.0416 10.0002 16.0416C6.66344 16.0416 3.9585 13.3366 3.9585 9.99992C3.9585 6.6632 6.66344 3.95825 10.0002 3.95825C13.3369 3.95825 16.0418 6.6632 16.0418 9.99992Z"
          stroke={theme.grey[3]}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export interface HelpLinkProps {
  href?: string;
  title?: string;
  size?: number;
  gutterLeft?: number;
  gutterRight?: number;
  className?: string;
  disableRipple?: boolean;
  disableHref?: boolean;
}

export function HelpLink(props: HelpLinkProps) {
  const {
    className,
    href = 'https://support.commsor.com/',
    title = 'Support documentation for Commsor',
    disableRipple = false,
  } = props;
  return (
    <Tooltip title={title}>
      <IconButton
        component="a"
        href={href}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
        size="large"
        disableRipple={disableRipple}
        sx={{
          display: 'flex',
          marginLeft: props.gutterLeft || 0,
          marginRight: props.gutterRight || 0,
          padding: 0,
          width: '20px',
          height: '20px',
          '&:active': {
            pointerEvents: props.disableHref ? 'none' : 'all',
          },
          cursor: props.disableHref ? 'default' : 'pointer',
        }}
      >
        <SvgIcon component={InformationIcon} sx={{ fill: 'none', height: '20px', width: '20px' }} />
      </IconButton>
    </Tooltip>
  );
}

import { AlertProps } from '@mui/lab';

import { BaseModel, makeToJS, makeUpdate } from './base';

export interface ToastProps {
  message: string;
  severity: 'error' | 'info' | 'success';
  variant?: AlertProps['variant'];
  actionText?: string;
  duration?: number;
  actionHandler?: () => any;
}

const skeletonProps: ToastProps = {
  message: '',
  severity: 'info',
  variant: undefined,
  actionText: undefined,
  actionHandler: undefined,
  duration: 6000,
};

/**
 * Represents a 'toast' message to be displayed via MUI snackbar
 */
export class Toast extends BaseModel<ToastProps> implements ToastProps {
  message = skeletonProps.message;
  severity = skeletonProps.severity;
  variant = skeletonProps.variant;
  actionText = skeletonProps.actionText;
  actionHandler = skeletonProps.actionHandler;
  duration = skeletonProps.duration;

  timerStarted = false;

  constructor(props: ToastProps) {
    super();
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

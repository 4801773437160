import { gql } from '@apollo/client';

export const RETRACT_PROOF = gql`
  mutation retractProof($clientMutationId: String, $id: String) {
    retractProof(input: { clientMutationId: $clientMutationId, id: $id }) {
      clientMutationId
      result
    }
  }
`;

export interface GqlRetractProofRequestType {
  clientMutationId?: string;
  id?: string;
}

export interface GqlRetractProofResponseType {
  retractProof: {
    clientMutationId?: string;
    result: string;
  };
}

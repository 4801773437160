import { GLOW_GRADIENT } from '@caravel/utils';
import { Box, Fade, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

const height = 2;

export const CaravelLineActivity = observer(() => {
  const loading = useStore().ui.isWorking;
  const theme = useTheme();
  return (
    <Fade in={loading} appear>
      <Box
        sx={{
          height,
          width: '100vw',
          overflow: 'hidden',
          position: 'absolute',
          bottom: -1,
          left: 0,
          zIndex: theme.zIndex.appBar + 1,
        }}
      >
        <Box
          sx={{
            height,
            width: '400vw',
            background: `${GLOW_GRADIENT} repeat-x`,
            animation: '$slide 2000ms linear infinite',
            '@keyframes slide': {
              '0%': {
                transform: `translate3d(-300vw, 0, 0)`,
              },
              '100%': {
                transform: 'translate3d(0, 0, 0)',
              },
            },
          }}
        />
      </Box>
    </Fade>
  );
});

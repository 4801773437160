import { Column, Row, useExtendedTroveDesign } from '@caravel/components';
import { TroveProps, TroveRequestBody, TroveResponse } from '@caravel/types';
import { NEUTRALS, notEmptyString, WHITE } from '@caravel/utils';
import * as colorContext from '@caravel/utils/src/constants/color-context';
import {
  createTheme,
  Dialog,
  IconButton,
  Link,
  SvgIcon,
  ThemeOptions,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import chroma from 'chroma-js';
import React, { useMemo, useState } from 'react';

import { commonThemeOptions } from '../theme';
import { QuestionTypography } from './controls';
import { EmailQuestion, MultipleChoiceQuestion, RatingQuestion, TextQuestion } from './questions';
import { ReactComponent as CloseIcon } from './svgs/close-small.svg';
import { ReactComponent as CommsorDino } from './svgs/commsor-dino-large.svg';
import { ReactComponent as SuccessIcon } from './svgs/success.svg';

// TODO: PROD-1291 - Add question skip logic to widget

export interface FormProps {
  open: boolean;
  teamId: string;
  trove?: TroveProps;
  troveEmail?: string;
  onClose: () => void;
  onSubmit: (data: TroveRequestBody) => Promise<any>;
}

const CloseButton = ({ onClose }: Pick<FormProps, 'onClose'>) => (
  <IconButton
    onClick={onClose}
    sx={{
      height: '26px',
      width: '26px',
      padding: 0,
      position: 'absolute',
      top: '25px',
      right: '20px',
    }}
  >
    <SvgIcon viewBox="0 0 20 20" sx={{ width: 26, height: 26, fill: NEUTRALS.JASMINE }} component={CloseIcon} />
  </IconButton>
);

export function Form(props: FormProps) {
  const { open, teamId, trove, troveEmail, onClose, onSubmit } = props;
  const [working, setWorking] = useState(false);
  const [qindex, setQindex] = useState(0);
  const [responses, setResponses] = useState<TroveResponse[]>([]);
  const [showCaptureEmail, setShowCaptureEmail] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [otherStash, setOtherStash] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState<string[]>([]);
  const design = useExtendedTroveDesign(trove?.design);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const multipleChoiceOtherLabel = 'Other';
  const multipleChoiceOtherSeperator = ':';

  const designedTheme = useMemo(() => {
    const mode = chroma(design.background).get('lab.l') < 70 ? 'dark' : 'light';

    const designedThemeOptions: ThemeOptions = {
      grey: {
        0: colorContext.GREY_PALETTE[0],
        1: colorContext.GREY_PALETTE[1],
        2: colorContext.GREY_PALETTE[2],
        3: colorContext.GREY_PALETTE[3],
        4: colorContext.GREY_PALETTE[4],
        5: colorContext.GREY_PALETTE[5],
        6: colorContext.GREY_PALETTE[6],
        7: colorContext.GREY_PALETTE[7],
      },
      palette: {
        mode,
        transparent: undefined,
        menu: undefined,

        primary: {
          main: design.buttons,
        },
        secondary: {
          main: design.secondaryButtons ?? WHITE,
        },

        background: {
          default: design.background,
        },
      },

      typography: {
        allVariants: {
          color: design.questions,
        },
      },

      components: {
        MuiButton: {
          defaultProps: {
            disableElevation: true,
          },
          styleOverrides: {
            containedInherit: {
              backgroundColor: design.ratingButtons,
              color: design.ratingText,
              '&:hover': {
                backgroundColor: design.buttons,
                color: design.buttonText,
              },
            },
            containedPrimary: {
              color: design.buttonText,
            },
            containedSecondary: {
              color: design.secondaryText,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: design.secondaryText,
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              height: '50px',
              margin: '20px 0',
              '& .MuiInputBase-root': {
                backgroundColor: design.inputBackground,
              },
              '& .MuiOutlinedInput-root': {
                padding: 0,
                '& fieldset': {
                  borderWidth: '2px',
                  borderColor: design.ratingButtons,
                },
                '&:hover fieldset': {
                  borderColor: design.borderColor,
                },
                '&.Mui-focused fieldset': {
                  borderColor: design.buttons,
                },
              },
              '& input': {
                padding: '15px',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '-0.2%',
                color: design.answers,
              },
              '& textarea': {
                boxSizing: 'border-box',
                padding: '22px 20px',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '-0.2%',
                color: design.answers,
                height: '100% !important',
              },
            },
          },
        },
      },
    };

    return createTheme(deepmerge(commonThemeOptions, designedThemeOptions));
  }, [design]);

  if (!trove) {
    return null;
  }

  const question: TroveResponse = {
    ...trove.questions.slice()[qindex],
    ...responses[qindex],
  };

  const hasNextQuestion = Boolean(trove?.questions.at(qindex + 1));
  const hasBackQuestion = Boolean(qindex - 1 >= 0 && trove?.questions.at(qindex - 1));
  const count = trove.questions.length;
  const last = qindex + 1 === count && notEmptyString(troveEmail);
  const inline = trove.inline;

  const handleBack = () => {
    if (showCaptureEmail) {
      setShowCaptureEmail(false);
    } else if (hasBackQuestion) {
      setQindex(qindex - 1);
    } else {
      setQindex(0);
    }
  };

  const handleChangeResponse = async (response: TroveResponse) => {
    const questions = trove.questions.slice();
    let answers = responses.slice();
    if (!answers.length) {
      answers = questions.slice();
    }
    answers.splice(qindex, 1, response);
    if (hasNextQuestion) {
      setResponses(answers);
      setQindex(qindex + 1);
    } else if ((trove.askForEmail ?? true) && !troveEmail && !showCaptureEmail) {
      setResponses(answers);
      setShowCaptureEmail(true);
    } else {
      try {
        setErrors([]);
        setWorking(true);
        await onSubmit({
          teamId,
          troveKey: trove.key,
          email: troveEmail,
          responses: answers,
        });
        setQindex(0);
        setResponses([]);
        setShowCaptureEmail(false);
        setWorking(false);
        setShowSuccess(true);
      } catch (e) {
        console.error(e);
        setWorking(false);
      }
    }
  };

  const handleChangeTextAnswer = (text: string) => {
    const response: TroveResponse = {
      ...question,
      answer: text,
    };
    const questions = trove.questions.slice();
    let answers = responses.slice();
    if (!answers.length) {
      answers = questions.slice();
    }
    answers.splice(qindex, 1, response);
    setResponses(answers);
  };

  const handleChangeTextResponse = (text: string) => {
    const response: TroveResponse = {
      ...question,
      answer: text,
    };
    handleChangeResponse(response);
  };

  const handleChangeMultipleChoiceResponse = (selection: string) => {
    const prefix = multipleChoiceOtherLabel + multipleChoiceOtherSeperator;

    if (question.choices && question.choices.length > 0) {
      const selectionIndex = question.choices.indexOf(selection);
      if (selectionIndex >= 0 || selection.startsWith(prefix)) {
        const response: TroveResponse = {
          ...question,
          answer: selection,
        };
        handleChangeResponse(response);
      }
    }
    setOtherStash('');
  };

  const handleChangeMultipleChoiceAnswer = (selection: string) => {
    if (selection === '') {
      return;
    }

    const prefix = multipleChoiceOtherLabel + multipleChoiceOtherSeperator;
    let answer: string = selection;

    if (question.answer?.startsWith(prefix)) {
      setOtherStash(question.answer);
    }

    if (selection === multipleChoiceOtherLabel && !question.answer?.startsWith(prefix)) {
      if (otherStash.startsWith(prefix)) {
        answer = otherStash;
      } else {
        answer = prefix + otherStash;
      }
    }

    const response: TroveResponse = {
      ...question,
      answer: answer,
    };
    const questions = trove.questions.slice();
    let answers = responses.slice();
    if (!answers.length) {
      answers = questions.slice();
    }
    answers.splice(qindex, 1, response);
    setResponses(answers);
  };

  const handleOtherTextChange = (otherWriteIn: string) => {
    setOtherStash(otherWriteIn);
    const response: TroveResponse = {
      ...question,
      answer: otherWriteIn,
    };
    const questions = trove.questions.slice();
    let answers = responses.slice();
    if (!answers.length) {
      answers = questions.slice();
    }
    answers.splice(qindex, 1, response);
    setResponses(answers);
  };

  const handleChangeRatingResponse = (rating: number) => {
    const response: TroveResponse = {
      ...question,
      rating,
    };
    handleChangeResponse(response);
  };

  const handleSubmitWithEmail = async (email?: string) => {
    try {
      setErrors([]);
      setWorking(true);
      await onSubmit({
        teamId,
        troveKey: trove.key,
        email,
        responses,
      });
      setQindex(0);
      setResponses([]);
      setShowCaptureEmail(false);
      setWorking(false);
      setShowSuccess(true);
    } catch (e) {
      console.error(e);
      setWorking(false);
    }
  };

  // eslint-disable-next-line complexity
  const renderForm = () => {
    return (
      <Column
        sx={{
          backgroundColor: design.background,
          height: '100%',
          flex: 1,
          padding: trove.questions.length > 1 ? '60px 40px' : '24px',
          ...(inline
            ? {}
            : {
                position: 'relative',
              }),
        }}
      >
        {!inline && !trove.hosted && <CloseButton onClose={onClose} />}

        <Column
          sx={{
            flex: 1,
            height: '100%',
          }}
        >
          {trove.questions.length > 1 && !showCaptureEmail && !showSuccess && (
            <QuestionTypography sx={{ fontSize: '14px', lineHeight: '19px', opacity: '0.8' }}>
              {qindex + 1}/{trove.questions.length}
            </QuestionTypography>
          )}
          {!showCaptureEmail && !showSuccess && (
            <QuestionTypography
              sx={{
                marginTop: trove.questions.length > 1 ? 0 : '24px',
                padding: '14px 26px 0 26px',
                fontWeight: '500',
              }}
            >
              {question.label}
            </QuestionTypography>
          )}
          {showCaptureEmail && !showSuccess && (
            <QuestionTypography sx={{ margin: '60px 0 24px', padding: '0 26px' }}>
              {trove.copy?.emailPrompt ?? `Would you like to add your email so our team can respond to your request?`}
            </QuestionTypography>
          )}

          <Column
            sx={{
              flex: 1,
              height: '100%',
            }}
          >
            {question.type === 'text' && !showCaptureEmail && !showSuccess && (
              <TextQuestion
                working={working}
                copy={trove.copy}
                design={design}
                answer={question.answer ?? ''}
                onChangeAnswer={handleChangeTextAnswer}
                question={question}
                last={last}
                inline={inline}
                onBack={hasBackQuestion ? handleBack : undefined}
                onSubmit={handleChangeTextResponse}
              />
            )}
            {question.type === 'multiple-choice' && !showCaptureEmail && !showSuccess && (
              <MultipleChoiceQuestion
                working={working}
                copy={trove.copy}
                design={design}
                answer={question.answer ?? 'Select a Choice'}
                question={question}
                last={last}
                inline={inline}
                onBack={hasBackQuestion ? handleBack : undefined}
                onSubmit={handleChangeMultipleChoiceResponse}
                onChangeAnswer={handleChangeMultipleChoiceAnswer}
                onChangeOther={handleOtherTextChange}
                placeholder={'Select a Choice'}
                otherLabel={multipleChoiceOtherLabel}
                otherSeperator={multipleChoiceOtherSeperator}
              />
            )}
            {question.type === 'five-rating' && !showCaptureEmail && !showSuccess && (
              <RatingQuestion
                count={5}
                working={working}
                copy={trove.copy}
                design={design}
                question={question}
                last={last}
                inline={inline}
                onBack={hasBackQuestion ? handleBack : undefined}
                onSubmit={handleChangeRatingResponse}
              />
            )}
            {question.type === 'ten-rating' && !showCaptureEmail && !showSuccess && (
              <RatingQuestion
                count={10}
                working={working}
                copy={trove.copy}
                design={design}
                question={question}
                last={last}
                inline={inline}
                onBack={hasBackQuestion ? handleBack : undefined}
                onSubmit={handleChangeRatingResponse}
              />
            )}
            {showCaptureEmail && (
              <EmailQuestion
                required={trove.emailRequired ?? false}
                working={working}
                copy={trove.copy}
                design={design}
                inline={inline}
                onBack={handleBack}
                onSubmit={handleSubmitWithEmail}
                onSkip={() => handleSubmitWithEmail(undefined)}
              />
            )}
            {showSuccess && (
              <Column
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '60px 0 0 0',
                  ...(!inline
                    ? {
                        height: '188px',
                      }
                    : {
                        height: '100%',
                      }),
                }}
              >
                <Column
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '100%',
                    maxHeight: '100px',
                  }}
                >
                  <SuccessIcon role="img" title="celebration" />
                  <Typography variant="body" sx={{ fontFamily: theme => theme.typography.fontFamily }}>
                    {trove.copy?.submitSuccess ?? `Thank you! We received your feedback.`}
                  </Typography>
                </Column>
              </Column>
            )}
          </Column>
        </Column>
        {!trove.design.disableBranding && (
          <Row
            justifyContent="center"
            alignItems="center"
            sx={{
              marginTop: '32px',
            }}
          >
            <SvgIcon
              viewBox="0 0 40 40"
              component={CommsorDino}
              sx={{
                width: 14,
                height: 15,
                marginRight: '5px',
              }}
            />
            <Link
              href="https://commsor.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                fontFamily: theme => theme.typography?.fontFamily,
                fontSize: '10px',
                lineHeight: '13.5px',
                color: design.linkColor,
                '&:hover': {
                  color: design.questions,
                },
              }}
            >
              Powered by Commsor
            </Link>
          </Row>
        )}
      </Column>
    );
  };

  const location = design.location ?? 'center';
  let left = 'unset';
  let right = 'unset';
  let top = 'unset';
  let bottom = 'unset';
  const hpadding = design.paddingHorizontal ?? 20;
  const vpadding = design.paddingVertical ?? 20;

  if (!trove.hosted) {
    if (location === 'top-left') {
      top = `${vpadding}px`;
      left = `${hpadding}px`;
    } else if (location === 'top-right') {
      top = `${vpadding}px`;
      right = `${hpadding}px`;
    } else if (location === 'bottom-right') {
      right = `${hpadding}px`;
      bottom = `${vpadding}px`;
    } else if (location === 'bottom-left') {
      bottom = `${vpadding}px`;
      left = `${hpadding}px`;
    }
  }

  const disableOverlay = trove.hosted || !design.overlay;

  return (
    <ThemeProvider theme={designedTheme}>
      {inline ? (
        renderForm()
      ) : (
        <Dialog
          open={open}
          onClose={disableOverlay ? undefined : onClose}
          {...(disableOverlay ? { BackdropComponent: () => null } : {})}
          id={trove.key}
          fullWidth={matches}
          BackdropProps={{
            style: {
              opacity: design.overlayOpacity / 100,
            },
          }}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: design.overlayColor,
            },
            '& .MuiDialog-paper': {
              flex: 1,
              maxWidth: trove.hosted || location === 'center' ? '500px' : '400px',
              width: '100%',
              margin: 0,
              top,
              right,
              bottom,
              left,
              position: 'absolute',

              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                borderRadius: 0,
                position: 'fixed',
                top: 'unset',
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
          }}
        >
          {renderForm()}
        </Dialog>
      )}
    </ThemeProvider>
  );
}

import {
  AddButton,
  Column,
  Flex,
  FormLabel,
  FormLabelDescription,
  NarrowColumn,
  PageHeader,
  Row,
} from '@caravel/components/src';
import { DARK_GREY, LIGHT_GREY, NEUTRALS, WHITE } from '@caravel/utils';
import {
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { TroveTabFooter } from 'components/sources/trove/trove-tab-footer';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { SliderSwitch } from '../../slider-switch';
import { TextResponseIcon } from './trove-icons';
import { DeleteIcon } from './trove-icons';
import { InfoIcon } from './trove-icons';
import { MultipleChoiceIcon } from './trove-icons';
import { FiveRatingIcon } from './trove-icons';
import { TenRatingIcon } from './trove-icons';
import { QuestionLogic } from './trove-question-logic';
import { QuestionMenu } from './trove-question-menu';

// TODO: PROD-1291 - Configure Skip Logic

export const TroveConfigureTab = observer(() => {
  const store = useStore();
  const history = useHistory();
  const form = store.ui.troveForm;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    form.checkForChangedQuestions();
  });

  if (!form.formProps) {
    return null;
  }

  const handleSubmit = () => {
    history.push(`${form.basePath}/design`);
  };

  function changeChoice(id: number, choices: string[], value: string) {
    const choicesCopy = choices.slice();
    choicesCopy.splice(id, 1, value);
    return { choices: choicesCopy };
  }

  function addChoice(choices?: string[]) {
    let newChoices: string[] = [];
    if (choices !== undefined) {
      newChoices = choices.slice();
    }
    newChoices.push('');
    return { choices: newChoices };
  }

  function removeChoice(id: number, choices?: string[]) {
    let newChoices: string[] = [];

    if (choices === undefined || choices.length <= 1) {
      return {
        choices: choices,
      };
    }

    if (choices !== undefined) {
      newChoices = choices.slice();
    }
    newChoices.splice(id, 1);
    return { choices: newChoices };
  }

  return (
    <NarrowColumn>
      <PageHeader headline="Steps" description="Customize your form's questions and 'thank you' card" />
      <Divider />
      <Column sx={{ marginTop: '30px' }}>
        <Row>
          <Typography variant="body" sx={{ marginBottom: '10px' }}>
            Questions
          </Typography>
          <Flex sx={{ flex: 1 }} />
          <AddButton onClick={e => setAnchorEl(e.currentTarget)}>Add question</AddButton>
          <Popover
            id={open ? 'add-question-options' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuList dense sx={{ backgroundColor: LIGHT_GREY }}>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  form.onAddQuestion('text');
                }}
              >
                <ListItemIcon>
                  <SvgIcon viewBox="0 0 17 20" sx={{ width: 20, height: 20 }} component={TextResponseIcon} />
                </ListItemIcon>
                <ListItemText>Text response</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  form.onAddQuestion('multiple-choice');
                }}
              >
                <ListItemIcon>
                  <SvgIcon
                    viewBox="0 0 20 20"
                    sx={{ width: 20, height: 20, fill: 'none' }}
                    component={MultipleChoiceIcon}
                  />
                </ListItemIcon>
                <ListItemText>{`Multiple Choice Response`}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  form.onAddQuestion('five-rating');
                }}
              >
                <ListItemIcon>
                  <SvgIcon
                    viewBox="0 0 20 20"
                    sx={{ width: 20, height: 20, fill: 'none' }}
                    component={FiveRatingIcon}
                  />
                </ListItemIcon>
                <ListItemText>{`1-5 Rating`}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  form.onAddQuestion('ten-rating');
                }}
              >
                <ListItemIcon>
                  <SvgIcon viewBox="0 0 20 20" sx={{ width: 20, height: 20, fill: 'none' }} component={TenRatingIcon} />
                </ListItemIcon>
                <ListItemText>{`1-10 Rating`}</ListItemText>
              </MenuItem>
            </MenuList>
          </Popover>
        </Row>
      </Column>
      <Column
        sx={{
          marginTop: theme => theme.spacing(1),
        }}
      >
        {!form.formProps.settings.questions.length && (
          <Typography
            variant="bodySmall"
            sx={{
              margin: theme => theme.spacing(2, 0),
              color: DARK_GREY,
            }}
          >
            Add a question to continue.
          </Typography>
        )}
        {form.formProps?.settings.questions.map((q, i) => {
          return (
            <Fade in appear key={`question-${q.type}-${i}`}>
              <Column
                sx={{
                  borderRadius: '5px',
                  backgroundColor: LIGHT_GREY,
                  margin: theme => theme.spacing(1, 0),
                  padding: theme => theme.spacing(2),
                }}
              >
                <Row
                  sx={{
                    alignItems: 'center',
                    marginBottom: theme => theme.spacing(1),
                  }}
                >
                  <Typography>{i + 1 + '. ' + q.label ?? ''}</Typography>
                  <Flex sx={{ flex: 1 }} />
                  <QuestionMenu id={i} handleRemove={form.onRemoveQuestion} />
                </Row>
                <Row
                  sx={{
                    alignItems: 'center',
                    marginBottom: theme => theme.spacing(1),
                    marginLeft: '8px',
                  }}
                >
                  {q.type === 'text' && <TextResponseIcon stroke="#696969" />}
                  {q.type === 'multiple-choice' && <MultipleChoiceIcon fill="#696969" />}
                  {q.type === 'five-rating' && <FiveRatingIcon stroke="#696969" />}
                  {q.type === 'ten-rating' && <TenRatingIcon stroke="#696969" />}
                  <Typography
                    variant="caption"
                    sx={{
                      marginLeft: theme => theme.spacing(1),
                    }}
                  >
                    {q.type === 'text' && 'Text response'}
                    {q.type === 'multiple-choice' && 'Multiple choice response'}
                    {q.type === 'five-rating' && '1-5 rating'}
                    {q.type === 'ten-rating' && '1-10 rating'}
                  </Typography>
                </Row>
                <Divider sx={{ marginBottom: '10px' }} />
                <Column sx={{ marginLeft: '10px', marginRight: '10px' }}>
                  <Column>
                    <Typography variant="inputLabelSmall" sx={{ marginBottom: '10px' }}>
                      Question label
                    </Typography>
                    <TextField
                      value={q.label ?? ''}
                      onChange={e =>
                        form.onChangeQuestion(i, {
                          label: e.currentTarget.value,
                        })
                      }
                      sx={{ backgroundColor: WHITE }}
                      inputProps={{
                        sx: {
                          boxSizing: 'border-box',
                          fontSize: 14,
                          height: 48,
                        },
                      }}
                    />
                  </Column>

                  {q.type === 'text' && (
                    <FormControlLabel
                      sx={{
                        marginTop: theme => theme.spacing(2),
                        '& .MuiFormControlLabel-label': {
                          fontSize: '14px',
                        },
                      }}
                      label="Required"
                      control={
                        <Checkbox
                          checked={q.required ?? false}
                          onChange={e => form.onChangeQuestion(i, { required: e.target.checked })}
                          color="primary"
                          name="required-option"
                        />
                      }
                    />
                  )}

                  {q.type === 'multiple-choice' && (
                    <>
                      <Divider sx={{ marginTop: '30px' }} />
                      <Typography variant="inputLabelSmall" sx={{ marginTop: '10px' }}>
                        Choices
                      </Typography>
                      {q.choices?.map((choice, ind) => {
                        return (
                          <Column key={ind} sx={{ marginLeft: '20px', marginRight: '20px' }}>
                            <Row sx={{ marginTop: '10px', alignItems: 'center' }}>
                              <Column flex={2}>
                                <TextField
                                  value={q.choices ? q.choices[ind] : ''}
                                  onChange={e =>
                                    form.onChangeQuestion(i, changeChoice(ind, q.choices!, e.currentTarget.value))
                                  }
                                  sx={{ backgroundColor: WHITE }}
                                  inputProps={{
                                    sx: {
                                      boxSizing: 'border-box',
                                      fontSize: 14,
                                      height: 48,
                                    },
                                  }}
                                />
                              </Column>
                              <Column>
                                {q.choices!.length > 1 && (
                                  <IconButton
                                    size="small"
                                    onClick={() => form.onChangeQuestion(i, removeChoice(ind, q.choices))}
                                    sx={{
                                      marginLeft: '8px',
                                    }}
                                  >
                                    <DeleteIcon fill="#696969" />
                                  </IconButton>
                                )}
                                {q.choices!.length === 1 && (
                                  <IconButton
                                    disabled
                                    size="small"
                                    onClick={() => form.onChangeQuestion(i, removeChoice(ind, q.choices))}
                                    sx={{
                                      marginLeft: '8px',
                                    }}
                                  >
                                    <SvgIcon
                                      viewBox="0 0 20 21"
                                      sx={{ width: 20, height: 20, fill: NEUTRALS.JASMINE }}
                                      component={DeleteIcon}
                                    />
                                  </IconButton>
                                )}
                              </Column>
                            </Row>
                          </Column>
                        );
                      })}
                      <Column sx={{ alignItems: 'center', marginTop: '10px' }}>
                        <Row>
                          <Button onClick={() => form.onChangeQuestion(i, addChoice(q.choices))}>Add Choice</Button>
                        </Row>
                      </Column>
                      <Divider sx={{ marginTop: '20px' }} />
                      <Column sx={{ marginTop: '20px', marginBottom: '10px', marginLeft: '20px', marginRight: '20px' }}>
                        <Row alignItems="center">
                          <Column flex={1}>
                            <Row alignItems={'center'}>
                              <FormLabel>Add an 'Other' choice</FormLabel>
                              <Tooltip
                                title={`Allow users to indicate that none of the above choices fit their response.
                                  Otherwise, users will need to select the choice which fits their response best to complete this question.`}
                              >
                                <IconButton
                                  size="small"
                                  sx={{ padding: 0, marginLeft: '5px', width: '20px', height: '20px' }}
                                >
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </Row>
                          </Column>
                          <SliderSwitch
                            key={'other-option-' + i}
                            checked={q.otherChoice ?? false}
                            onChange={e => form.onChangeQuestion(i, { otherChoice: e.target.checked })}
                          />
                        </Row>
                      </Column>
                    </>
                  )}

                  {q.type !== 'text' && q.type !== 'multiple-choice' && (
                    <>
                      <Column
                        sx={{
                          margin: theme => theme.spacing(2, 0),
                        }}
                      >
                        <Typography variant="inputLabelSmall" sx={{ marginBottom: '10px' }}>
                          Min value label
                        </Typography>
                        <TextField
                          value={q.minLabel ?? ''}
                          onChange={e =>
                            form.onChangeQuestion(i, {
                              minLabel: e.currentTarget.value,
                            })
                          }
                          sx={{ backgroundColor: WHITE }}
                          inputProps={{
                            sx: {
                              boxSizing: 'border-box',
                              fontSize: 14,
                              height: 48,
                            },
                          }}
                        />
                      </Column>
                      <Column>
                        <Typography variant="inputLabelSmall" sx={{ marginBottom: '10px' }}>
                          Max value label
                        </Typography>
                        <TextField
                          value={q.maxLabel ?? ''}
                          onChange={e =>
                            form.onChangeQuestion(i, {
                              maxLabel: e.currentTarget.value,
                            })
                          }
                          sx={{ backgroundColor: WHITE }}
                          inputProps={{
                            sx: {
                              boxSizing: 'border-box',
                              fontSize: 14,
                              height: 48,
                            },
                          }}
                        />
                      </Column>
                    </>
                  )}
                </Column>
                {store.features.hasTroveSkipLogic && (
                  <QuestionLogic question={q} index={i} questions={form.formProps?.settings.questions} />
                )}
              </Column>
            </Fade>
          );
        })}
      </Column>

      <Divider sx={{ marginTop: '30px' }} />
      <Column sx={{ marginTop: '30px' }}>
        <Typography variant="headline3">'Thank you' card</Typography>
        <Typography variant="bodySmallest" sx={{ color: DARK_GREY, marginTop: '10px', marginBottom: '20px' }}>
          Customize the 'thank you' card your user's will see when submitting their response.
        </Typography>
        <FormLabel sx={{ marginBottom: '10px' }}>Submit button</FormLabel>
        <TextField
          value={form.formProps.settings.copy.submitButton ?? ''}
          onChange={e => form.onChangeCopyProp('submitButton', e.target.value)}
          inputProps={{
            sx: {
              boxSizing: 'border-box',
              fontSize: 14,
              height: 48,
            },
          }}
        />
      </Column>
      <Column sx={{ marginTop: '30px' }}>
        <FormLabel sx={{ marginBottom: '10px' }}>Thank you message</FormLabel>
        <TextField
          value={form.formProps.settings.copy.submitSuccess ?? ''}
          onChange={e => form.onChangeCopyProp('submitSuccess', e.target.value)}
          inputProps={{
            sx: {
              boxSizing: 'border-box',
              fontSize: 14,
              height: 48,
            },
          }}
        />
      </Column>

      <Divider sx={{ marginTop: '30px' }} />
      <Column sx={{ marginTop: '30px' }}>
        <Row alignItems="center">
          <Column flex={1}>
            <FormLabel>Ask for email</FormLabel>
            <FormLabelDescription
              sx={{
                maxWidth: '350px',
                width: '100%',
              }}
            >
              If a trove-email attribute isn't attached, ask the user for their email so you can reply to their
              requests.
            </FormLabelDescription>
          </Column>
          <SliderSwitch
            checked={form.formProps.settings.askForEmail ?? true}
            onChange={e => form.onChangeAskForEmail(e.target.checked)}
          />
        </Row>
      </Column>
      {(form.formProps.settings.askForEmail ?? true) && (
        <>
          <Column sx={{ marginTop: '30px' }}>
            <FormLabel sx={{ marginBottom: '10px' }}>Email prompt</FormLabel>
            <TextField
              value={form.formProps.settings.copy.emailPrompt ?? ''}
              onChange={e => form.onChangeCopyProp('emailPrompt', e.target.value)}
              inputProps={{
                sx: {
                  boxSizing: 'border-box',
                  fontSize: 14,
                  height: 48,
                },
              }}
            />
          </Column>
          <Column sx={{ marginTop: '20px' }}>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                },
              }}
              label="Require email before completing survey"
              control={
                <Checkbox
                  checked={form.formProps.settings.emailRequired ?? false}
                  color="primary"
                  onChange={e => form.onChangeEmailRequired(e.target.checked)}
                  name="email-required-option"
                />
              }
            />
          </Column>
        </>
      )}

      {form.formProps.settings.questions.length > 0 && (
        <>
          <Divider sx={{ marginTop: theme => theme.spacing(4) }} />
          <TroveTabFooter tab="configure" onSubmit={handleSubmit} disabled={!form.configureCanContinue} />
        </>
      )}
    </NarrowColumn>
  );
});

import { TeamDocument } from '@caravel/types';
import { doc, DocumentReference } from 'firebase/firestore';
import { FirestoreTimestamp, getDb } from 'helpers';
import { computed, makeObservable, observable } from 'mobx';

import { BaseModel, makeToJS, makeUpdate, PENDING_ID } from './base';

export type TeamProps = TeamDocument & Record<string, any>;

const skeletonProps: TeamProps = {
  createdAt: undefined,
  updatedAt: undefined,
  archivedAt: undefined,
  id: PENDING_ID,
  displayName: '',
  slug: '',
  superAccess: undefined,
  planId: 'free',
  plan: undefined,
  deidentification: { deidentify: false },
  companyDomains: [],
  logoUrl: undefined,
  workspaceType: 'team',
};

export class Team extends BaseModel<TeamProps> implements TeamProps {
  createdAt?: FirestoreTimestamp = undefined;
  updatedAt?: FirestoreTimestamp = undefined;
  archivedAt?: FirestoreTimestamp = undefined;

  id = skeletonProps.id;
  displayName = skeletonProps.displayName;
  logoUrl = skeletonProps.logoUrl;
  slug = skeletonProps.slug;
  superAccess = skeletonProps.superAccess;
  planId = skeletonProps.planId;
  plan = skeletonProps.plan;
  deidentification = skeletonProps.deidentification;
  companyDomains = observable.array(skeletonProps.companyDomains || []);
  workspaceType = skeletonProps.workspaceType;

  working = false;

  get docRef(): DocumentReference {
    return doc(getDb(), 'teams', this.id);
  }

  constructor(props: TeamProps) {
    super();

    makeObservable(this, {
      createdAt: observable,
      updatedAt: observable,
      archivedAt: observable,
      id: observable,
      displayName: observable,
      slug: observable,
      superAccess: observable,
      planId: observable,
      plan: observable,
      deidentification: observable,
      companyDomains: observable,
      working: observable,
      logoUrl: observable,
      workspaceType: observable,
      docRef: computed,
    });

    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

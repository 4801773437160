import { GREY_PALETTE } from '@caravel/utils';
import { Button, ButtonProps } from '@mui/material';
import React from 'react';

export const FilterButton = React.forwardRef(
  (props: { label: string } & ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { label, onClick } = props;
    return (
      <Button
        {...props}
        ref={ref}
        variant="text"
        onClick={onClick}
        sx={{ minWidth: 'unset', padding: `0 5px`, margin: 0, height: '100%', color: GREY_PALETTE[5], ...props.sx }}
      >
        {label}
      </Button>
    );
  },
);

FilterButton.displayName = 'FilterButton';

import { Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const QuestionTypography = forwardRef<HTMLParagraphElement, TypographyProps>(({ ...props }, ref) => (
  <Typography
    ref={ref}
    {...props}
    sx={{
      fontSize: '16px',
      lineHeight: '22.4px',
      letterSpacing: '-0.4%',
      textAlign: 'center',
      ...props.sx,
    }}
  />
));
QuestionTypography.displayName = 'QuestionTypography';

export const DesignedButton = forwardRef<HTMLButtonElement, ButtonProps & { children?: any }>(({ ...props }, ref) => {
  return (
    <Button
      ref={ref}
      variant="contained"
      color="primary"
      {...props}
      sx={{
        fontSize: '13px',
        fontWeight: 600,
        ...props.sx,
      }}
    />
  );
});
DesignedButton.displayName = 'DesignedButton';

export const BackButton = forwardRef<HTMLButtonElement, ButtonProps & { children?: any }>((props, ref) => (
  <DesignedButton
    ref={ref}
    color="secondary"
    {...props}
    sx={{
      marginRight: '10px',
      height: '36px',
      ...props.sx,
    }}
  />
));
BackButton.displayName = 'BackButton';

export const NextButton = forwardRef<HTMLButtonElement, ButtonProps & { children?: any }>((props, ref) => (
  <DesignedButton
    ref={ref}
    {...props}
    sx={{
      height: '36px',
      ...props.sx,
    }}
  />
));
NextButton.displayName = 'NextButton';

export const RatingButton = forwardRef<HTMLButtonElement, ButtonProps & { isSelected: boolean }>(
  ({ isSelected, ...props }, ref) => (
    <DesignedButton
      ref={ref}
      {...props}
      color={isSelected ? 'primary' : 'inherit'}
      sx={{
        borderWidth: 0,
        flex: 1,
        minWidth: 0,
        width: '100%',
        borderRadius: '5px',
        fontSize: '28px',
        fontWeight: 500,
        ...props.sx,
      }}
    />
  ),
);
RatingButton.displayName = 'RatingButton';

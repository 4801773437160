import { gql } from '@apollo/client';

export const GET_COMMUNITY_EVENT = gql`
  query Event($filter: FindEventFilterInput!) {
    event(filter: $filter) {
      title
      timezone
      startTime
      endTime
      meetingUrl
      slug
      bannerUrl
      community {
        slug
        name
        contactEmail
      }
      attendees {
        responses {
          value
          label
        }
        registeredAt
      }
    }
  }
`;

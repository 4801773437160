import { Countries, Provinces } from 'country-and-province';
import camelCase from 'lodash/camelCase';
import snakeCaseLodash from 'lodash/snakeCase';
import upperFirst from 'lodash/upperFirst';
import objectHash from 'object-hash';
import ShortUniqueId from 'short-unique-id';
import Slugify from 'url-slug';
import { v4 as uuid } from 'uuid';

// Re-export third-party utility functions
export { camelCase, upperFirst, uuid };

export const makeShortId = new ShortUniqueId({ length: 10 });
export const makeTokenKey = new ShortUniqueId({ length: 32 });

export type MaybeString = string | undefined | null;

/**
 * Checks if a value is an empty string
 * @param str The value to test
 * @returns Whether the value loosely equals an empty string ('', ' ', undefined, null)
 */
export const isEmptyString = (str?: MaybeString) => !str || Boolean(str && str.trim() === '');

export const notEmptyString = (str?: MaybeString) => !isEmptyString(str);

/**
 * Checks a list of strings for empty entries
 * @param texts A list of texts to check
 * @returns Whether any empty strings were found or if the list is empty
 */
export const hasEmptyStrings = (texts?: MaybeString[]) =>
  !texts?.length || Boolean(texts?.filter(isEmptyString)?.length);

/**
 * Tests positive for valid email address (via https://emailregex.com/)
 */
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Checks if a value is a valid email address
 * @param str The string to test
 * @returns Whether the string was a valid email address
 */
export const isValidEmail = (str?: MaybeString) => !isEmptyString(str) && EMAIL_REGEX.test(str!);

export const isValidPassword = (str?: MaybeString) => !isEmptyString(str) && /^[\s\S]{8,}$/.test(str!);

/**
 * Tests positive for valid subdomain values
 */
export const SUBDOMAIN_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$/;

/**
 * Checks if a value is a valid subdomain segment
 * @param str The string to test
 * @returns Whether the string was a valid subdomain
 */
export const isValidSubdomain = (str?: MaybeString) => !isEmptyString(str) && SUBDOMAIN_REGEX.test(str!);

/**
 * Replaces newlines and excessive whitespace
 * @param text
 */

/**
 * Replaces newlines and excessive whitespace
 * @param text The multiline text to flatten
 * @returns A string with newlines replaced with a single space
 */
export function flattenText(text: string): string {
  return text
    .trim()
    .replace(/[\r\n]+/g, ' ')
    .replace(/\s{2,}/g, ' ');
}

/**
 * Generate a reproducible hash from an object or array
 * @param object
 * @returns
 */
export function generateHash(object: Record<string | number, any> | null) {
  return objectHash(object);
}

/**
 * Pluralizes text given a count of items
 * @param text The word to pluralize
 * @param count The number of things representing this word
 * @param suffix The suffix to be used if plural
 * @returns Pluralized text if given count is more than 1
 */
export function pluralize(text: string, count: number, suffix = 's') {
  const plural = `${text}${suffix}`;
  if (!count) return plural;
  return Math.abs(count) > 1 ? plural : text;
}

/**
 * Trims text to a desired character length
 * @param text The text to trim
 * @param length Maximum character length of text
 * @returns The trimmed text
 */
export function trimToMax(text: string, length = 255) {
  return text.substr(0, length);
}

/**
 * Truncates text to a desired character length. If text is trimmed, an ellipsis will be appended
 * @param text The text to truncate
 * @param length Maximum character length of text
 * @returns The truncated text
 */
export function truncate(text: MaybeString, length = 255): string {
  if (!text) return '';
  if (text.length <= length) return text;
  return `${trimToMax(text, length)}...`;
}

/**
 * Transforms text to "Title Case"
 * @param text The text to transform
 * @returns The transformed text
 */
export function titleCase(text?: MaybeString) {
  if (!text) return '';
  return text.replace(/\w\S*/g, t => t.charAt(0).toUpperCase() + t.substr(1).toLowerCase());
}

/**
 * Transforms text to "snake_case"
 * @param text The text to transform
 * @returns The transformed text
 */
export function snakeCase(text?: MaybeString): string {
  return snakeCaseLodash(text || '');
}

/**
 * Transforms text to "slug-format"
 * @param text The text to transform
 * @returns the slugified text
 */
export function slugify(text?: MaybeString): string {
  if (!text) return '';
  return Slugify(text!);
}

/**
 * Transforms text of dollar amount to abbreivated version with a letter suffix
 * @param text The text to transform
 * @returns the abbreviated amount
 */
export function abbreviateDollarAmount(text?: MaybeString) {
  if (!text) return '';

  const place = Math.floor((text.length - 1) / 3);

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const suffix = suffixes[place];

  const toDivide = [1, 1000, 1000000, 1000000000, 1000000000000];
  const divided = Number.parseInt(text) / toDivide[place];

  // hundreds need toFixed(0), tens need toFixed(1), and ones need toFixed(2)
  const abbreviated = divided.toFixed(2 - ((text.length - 1) % 3));

  // edge case where 999.9 gets rounded to 1000 and needs to get "bumped" up a suffix place
  if (abbreviated === '1000') return `$1${suffixes[place + 1]}`;

  return `$${Number.parseFloat(abbreviated).toLocaleString()}${suffix}`;
}

export function makeInitials(data: { givenName?: string; familyName?: string; name?: string }): string {
  const names = data.name?.split(' ') ?? [];
  const namesFirstInit = names[0]?.at(0) ?? '';
  const namesLastInit = names.length > 1 ? names.at(-1)?.at(0) : '';
  const first = data.givenName?.at(0) ?? namesFirstInit;
  const last = data.familyName?.at(0) ?? namesLastInit;
  return `${first}${last}`.toUpperCase();
}

export function locationFormatted(data: { city?: string; state?: string; country?: string }): string {
  const locationParts = [];
  if (data.city) locationParts.push(data.city);
  if (data.state) {
    const stateData = Provinces.byName(data.state);
    if (!data.city) return `${data.state}, ${stateData.countryCode}`;
    locationParts.push(stateData.code ?? data.state);
  }
  if (data.country) {
    if (!data.city && !data.state) return data.country;
    locationParts.push(Countries.byName(data.country).code ?? data.country);
  }
  return locationParts.join(', ');
}

import { gql } from '@apollo/client';

export const GET_NEW_MEMBERS = gql`
  query getNewMembers($startDate: Date, $endDate: Date) {
    community {
      id
      stats {
        newMembers(input: { start: $startDate, end: $endDate }) {
          previousDelta
          windowSize
          total
          delta
          counts {
            date
            delta
            total
          }
        }
      }
    }
  }
`;

export interface GqlNewMembersRequestType {
  startDate: string;
  endDate: string;
}

export interface GqlNewMembersResponseType {
  community: {
    id: string;
    stats: {
      newMembers: {
        previousDelta: number;
        windowSize: number;
        total: number;
        delta: number;
        counts: {
          date: string;
          delta: number;
          total: number;
        }[];
      };
    };
  };
}

export const apiDomain = process.env.CARAVEL_WEB_API_HOST;
const apiBaseUrl = `${apiDomain}/v1.0`;

export const servicesConfig = {
  endpointFromPath: (path = '') => `${apiBaseUrl}${path}`,

  toggleFirebaseObjectPublicStatus: `${apiBaseUrl}/gcs/toggle-object-public-status`,

  createTeam: `${apiBaseUrl}/teams`,
  getTeamApiKey: (teamId: string) => `${apiBaseUrl}/teams/${teamId}/api-key`,
  createTeamApiKey: (teamId: string) => `${apiBaseUrl}/teams/${teamId}/api-key`,
  updateTeamApiKey: (teamId: string) => `${apiBaseUrl}/teams/${teamId}/api-key`,
};

import { GREY_PALETTE } from '@caravel/utils';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

export const NewSegmentModal = observer(() => {
  const store = useStore();
  const {
    ui: { SegmentForm },
    teams: { segments, people },
  } = store;
  const open = SegmentForm.newSegmentModalOpen;

  const { formProps } = SegmentForm;
  if (!formProps) return null;

  async function handleCreateSegment() {
    await store.ui.workOn(async () => {
      const success = await SegmentForm.createSegment();
      if (success) {
        await segments.fetchSegments();
        SegmentForm.resetFormProps();
        SegmentForm.closeModal();
        people.deselectAll();
      }
    });
  }
  return (
    <Modal open={open} onClose={SegmentForm.closeModal}>
      <Box
        sx={{
          maxHeight: '90vh',
          width: '700px',
          bgcolor: GREY_PALETTE[1],
          borderRadius: '5px',
          padding: '40px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography variant="popUpHeadline" sx={{ display: 'block', marginBottom: '24px' }}>
          Create Static Segment
        </Typography>
        <Typography variant="inputLabel">Name</Typography>
        <TextField
          value={formProps.name}
          onChange={e => SegmentForm.onChange({ name: e.target.value })}
          sx={{ marginBottom: '20px' }}
          fullWidth
          placeholder="Name..."
        />
        <Typography variant="inputLabel">Description</Typography>
        <TextField
          value={formProps.description}
          onChange={e => SegmentForm.onChange({ description: e.target.value })}
          sx={{ marginBottom: '24px' }}
          multiline
          rows={4}
          fullWidth
          placeholder="Add description..."
        />
        <Grid item xs={12} container sx={{ flexDirection: 'row-reverse' }}>
          <Button variant="contained" disabled={SegmentForm.creating} onClick={handleCreateSegment}>
            Create
          </Button>
          <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={store.ui.SegmentForm.closeModal}>
            Cancel
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
});

import AssessmentIcon from '@mui/icons-material/Assessment';
import { Typography } from '@mui/material';
import { BaseActionModal } from 'components/modals/base-action-modal';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

export const TrainAModelModal = observer(() => {
  const store = useStore();
  const isOpen = store.ui.openModals.includes('train-a-model');

  if (!isOpen) {
    return null;
  }

  const onClose = () => store.ui.closeModal('train-a-model');
  const onAction = () => window.open(`mailto:support@commsor.com?subject=${encodeURI('Train a model')}`, '_blank');

  return (
    <BaseActionModal
      open={isOpen}
      icon={<AssessmentIcon />}
      title="Train a model"
      subtitle="Have deeper prediction needs?"
      buttonText="Get in touch"
      onClose={onClose}
      onAction={onAction}
    >
      <Typography gutterBottom>
        Get in touch with our team so we can set you up with the best for your needs. Our team makes it easy, helping
        with data, training, and performance.
      </Typography>
      <Typography gutterBottom>
        {'Make your request '}
        <a href="mailto:support@commsor.com" target="_blank" rel="noopener noreferrer">
          support@commsor.com
        </a>
        {' or click below '}
        <span role="img">👇</span>
      </Typography>
    </BaseActionModal>
  );
});

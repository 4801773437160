import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { Column, Row } from '../flex';
import { HelpLink } from '../helplink';

export interface FormSectionHeaderProps {
  title: string;
  subtitle?: string;
  info?: string;
  infoLink?: string;
  divider?: boolean;
}

export const FormSectionHeader = (props: FormSectionHeaderProps) => {
  const { title, subtitle, info, infoLink, divider } = props;
  const theme = useTheme();
  return (
    <>
      <Column sx={{ gap: '10px' }}>
        <Typography variant="headline2">{title}</Typography>
        {subtitle && (
          <Row sx={{ gap: '10px', alignItems: 'center' }}>
            <Typography variant="headline4" color={theme.grey[5]} sx={{ fontWeight: '400' }}>
              {subtitle}
            </Typography>
            {info && (
              <HelpLink
                title={info}
                disableRipple
                {...(infoLink ? { disableHref: false, href: infoLink } : { disableHref: true })}
              />
            )}
          </Row>
        )}
      </Column>
      {divider && <Divider sx={{ margin: '30px 0 20px 0' }} />}
    </>
  );
};

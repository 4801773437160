import { gql } from '@apollo/client';
import { Channel } from '@caravel/types/src';

export const GET_CHANNELS = gql`
  query getChannels {
    community {
      id
      channels {
        id
        name
        kind
        createdBy
      }
    }
  }
`;

export interface GqlChannelsResponseType {
  community: {
    id: string;
    channels: Channel[];
  };
}

import { DateRange, DateWindows, DefinedRange, TimestampRange } from '@caravel/types';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInMilliseconds,
  endOfDay,
  endOfMonth,
  endOfYear,
  isSameDay,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMilliseconds,
} from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';

import { parseDate } from './datetime';
import { getComparator, stableSort } from './sorting';

export const ALL_DATE_WINDOWS: DateWindows[] = [
  'all',
  'today',
  'yesterday',
  'last7',
  'last30',
  'last90',
  'last180',
  'last360',
  'lastmonth',
  'lastyear',
  'week',
  'month',
  'quarter',
  'year',
];

export const ROLLING_DATE_WINDOWS: DateWindows[] = ['last7', 'last30', 'last90', 'last180', 'last360'];

export const getDefinedRanges = (date: Date): DefinedRange[] => [
  {
    id: 'today',
    label: 'Today',
    startDate: startOfDay(date),
    endDate: endOfDay(date),
  },
  {
    id: 'yesterday',
    label: 'Yesterday',
    startDate: startOfDay(addDays(date, -1)),
    endDate: endOfDay(addDays(date, -1)),
  },
  {
    id: 'last7',
    label: 'Last 7 days',
    startDate: startOfDay(addWeeks(date, -1)),
    endDate: endOfDay(date),
  },
  {
    id: 'last30',
    label: 'Last 30 days',
    startDate: startOfDay(addDays(date, -30)),
    endDate: endOfDay(date),
  },
  {
    id: 'last90',
    label: 'Last 90 days',
    startDate: startOfDay(addDays(date, -90)),
    endDate: endOfDay(date),
  },
  {
    id: 'last180',
    label: 'Last 180 days',
    startDate: startOfDay(addDays(date, -180)),
    endDate: endOfDay(date),
  },
  {
    id: 'last360',
    label: 'Last 360 days',
    startDate: startOfDay(addDays(date, -360)),
    endDate: endOfDay(date),
  },
  {
    id: 'lastmonth',
    label: 'Last Month',
    startDate: startOfDay(startOfMonth(addMonths(date, -1))),
    endDate: endOfDay(endOfMonth(addMonths(date, -1))),
  },
  {
    id: 'lastyear',
    label: 'Last Year',
    startDate: startOfDay(startOfYear(addYears(date, -1))),
    endDate: endOfDay(endOfYear(addYears(date, -1))),
  },
  {
    id: 'week',
    label: 'Week to date',
    startDate: startOfDay(startOfWeek(date)),
    endDate: endOfDay(date),
  },
  {
    id: 'month',
    label: 'Month to date',
    startDate: startOfDay(startOfMonth(date)),
    endDate: endOfDay(date),
  },
  {
    id: 'quarter',
    label: 'Quarter to date',
    startDate: startOfDay(startOfQuarter(date)),
    endDate: endOfDay(date),
  },
  {
    id: 'year',
    label: 'Year to date',
    startDate: startOfDay(startOfYear(date)),
    endDate: endOfDay(date),
  },
];

/**
 * Checks if two date ranges are equivalent to the day
 * @param first
 * @param second
 * @returns whether the given ranges are equivalent
 */
export const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

/**
 * Gets the previous date range of the same duration of a given range.
 * @param range The range to compare against
 * @returns The previous date range of the same duration
 */
export const getPreviousRange = (range: DateRange) => {
  if (!range.startDate || !range.endDate) {
    throw new Error('Invalid range');
  }
  const prev: DateRange = {
    startDate: undefined,
    endDate: range.startDate,
  };
  const diff = differenceInMilliseconds(range.endDate, range.startDate);
  prev.startDate = subMilliseconds(prev.endDate!, diff);
  return prev;
};

/**
 * Constructs a date range label based on a given range.
 * @param range The range object to format
 * @param fmt The date format string (moment)
 * @param fallback A fallback string, defaults to 'n/a'
 * @returns A labeled date range
 */
export function getLabelFromRange(
  range: DateRange & { label?: string },
  fmt = 'MMM D, YYYY',
  fallback = 'n/a',
): string {
  if (!range.startDate || !range.endDate || range.label) {
    return (range as DefinedRange)?.label ?? fallback;
  }
  const start = moment(range.startDate).format(fmt);
  const end = moment(range.endDate).format(fmt);
  return `${start} — ${end}`; // the is the special emdash character
}

/**
 * Parses an unknown date range into the `DateRange` type if possible
 * @param range The unknown range
 * @returns A parsed date range or undefined
 */
export function parseDateRange(range?: DateRange | { startDate?: string; endDate?: string }) {
  if (!range || !range.endDate || !range.startDate) {
    // invalid range
    return undefined;
  }
  return {
    startDate: parseDate(range?.startDate),
    endDate: parseDate(range?.endDate),
  };
}

/**
 * Converts a date range to a firestore timestamp range
 * @param range The date range to convert
 * @returns A date range as firestore timestamps
 */
export function dateToStampRange(range: Required<DateRange>): TimestampRange {
  return {
    startDate: Timestamp.fromDate(range.startDate),
    endDate: Timestamp.fromDate(range.endDate),
  };
}

/**
 * Checks whether a date range has both required properties.
 * @param range The date range to test
 * @returns whether the range has both required props
 */
export function isRequiredRange(range?: DateRange): boolean {
  return Boolean(range && range.startDate && range.endDate);
}

/**
 * Checks whether a date is within a date range given a date window.
 * @param date The date to confirm
 * @param window The date window describing a date range
 * @param definedRanges A list of defined ranges to pull the window from
 * @returns Whether the date is within a date range
 */
export function withinWindow(date: Date, window: DateWindows, definedRanges: DefinedRange[]): boolean {
  if (window === 'all') return true;
  const range = definedRanges.find(r => r.id === window);
  if (!range) return false;
  try {
    return isWithinInterval(date, { start: range.startDate, end: range.endDate });
  } catch {
    return false;
  }
}

/**
 * Gets a date range for a date window from a list of possible ranges.
 * @param window The name of the date range
 * @param ranges A list of defined ranges to search from
 * @returns A defined date range if found
 */
export function getWindowRange(window: DateWindows, ranges: DefinedRange[]): DefinedRange | undefined {
  return ranges.find(range => range.id === window);
}

/**
 * Finds the oldest date in a list of date windows
 * @param windows A list of date windows
 * @param ranges A list of defined ranges to get ranges
 * @returns The oldest date from given windows
 */
export function oldestDateInWindows(windows: DateWindows[], ranges: DefinedRange[]): Date {
  const sorted = stableSort(
    // we have to add this coersion here because TS doesn't know that filter will remove undefined values
    windows.map(window => getWindowRange(window, ranges)).filter(range => range !== undefined) as DefinedRange[],
    getComparator('asc', 'startDate'),
  );
  return sorted.shift()?.startDate ?? new Date();
}

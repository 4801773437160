import { ApolloClient, DocumentNode, HttpLink, InMemoryCache, OperationVariables } from '@apollo/client';
import fetch from 'cross-fetch';

type Query = <QV extends OperationVariables, RT>(query: DocumentNode, variables?: QV, context?: QV) => Promise<RT>;
type Mutate = <MV extends OperationVariables, RT>(mutation: DocumentNode, variables?: MV) => Promise<RT>;

export type GraphQLClient = {
  query: Query;
  mutate: Mutate;
  // FIXME: update type
  client: ApolloClient<any>;
};

export const createGQLClient = (teamId: string, token: string, host: string): GraphQLClient => {
  const baseLink = new HttpLink({
    uri: `${host}/${teamId}/graphql`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    fetch,
  });

  const client = new ApolloClient({
    link: baseLink,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  // @deprecated
  const query: Query = (query, variables, context) => {
    return client
      .query({
        query,
        variables,
        context,
      })
      .then(({ data }) => data);
  };

  // @deprecated
  const mutate: Mutate = (mutation, variables) => {
    return client
      .mutate({
        mutation,
        variables,
      })
      .then(({ data }) => data);
  };

  return { query, mutate, client };
};

import { gql } from '@apollo/client';
import { ComposerMessageKind, ComposerMessages } from '@caravel/types';

export const CREATE_COMPOSER = gql`
  mutation sendComposerMessage($input: SendComposerMessageInput!) {
    sendComposerMessage(input: $input) {
      result {
        id
      }
    }
  }
`;

export interface GqlCreateComposerRequestType {
  input: {
    title: string;
    content: string;
    messageKind: ComposerMessageKind;
    segmentId: string;
    channelId: string;
  };
}

export interface GqlCreateComposerResponseType {
  sendComposerMessage: {
    result: ComposerMessages;
  };
}

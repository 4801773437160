import { IconPill } from '@caravel/components/src/icon-pill';
import { upperFirst } from '@caravel/utils/src/strings';
import React, { useMemo } from 'react';

function Neutral() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8337 8.00002C12.8337 10.6694 10.6697 12.8334 8.00033 12.8334C5.33095 12.8334 3.16699 10.6694 3.16699 8.00002C3.16699 5.33064 5.33095 3.16669 8.00033 3.16669C10.6697 3.16669 12.8337 5.33064 12.8337 8.00002Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Negative() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1663 8.16669L3.83301 8.16669"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Positive() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.83331V12.1666"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1663 8H3.83301"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ActivitySentiment(props: { sentiment: string }) {
  const sentiment = useMemo(() => {
    return upperFirst(props.sentiment.split('/').pop()?.replace('-', ' '));
  }, [props.sentiment]);

  const Icon = useMemo(() => {
    switch (props.sentiment) {
      case ':article.sentiment/positive':
      case ':article.sentiment/very-positive':
        return Positive;

      case ':article.sentiment/negative':
      case ':article.sentiment/very-negative':
        return Negative;

      default:
        return Neutral;
    }
  }, [props.sentiment]);

  const Style = useMemo(() => {
    switch (props.sentiment) {
      case ':article.sentiment/positive':
      case ':article.sentiment/very-positive':
        return { background: '#D1FADF', color: '#02434F' };

      case ':article.sentiment/negative':
      case ':article.sentiment/very-negative':
        return { background: '#FFEAE0', color: '#7C1543' };

      default:
        return { background: '#FEF5C6', color: '#794100' };
    }
  }, [props.sentiment]);

  return <IconPill text={sentiment} icon={<Icon />} sx={{ color: Style.color, background: Style.background }} />;
}

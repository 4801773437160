import * as colorContext from '@caravel/utils/src/constants/color-context';
import * as colors from '@caravel/utils/src/constants/colors';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    headline1: React.CSSProperties;
    headline2: React.CSSProperties;
    headline3: React.CSSProperties;
    headline4: React.CSSProperties;
    body: React.CSSProperties;
    bodySmall: React.CSSProperties;
    bodySmaller: React.CSSProperties;
    bodySmallest: React.CSSProperties;
    inputLabel: React.CSSProperties;
    inputLabelLine: React.CSSProperties;
    inputLabelSmall: React.CSSProperties;
    chip: React.CSSProperties;
    chipTiny: React.CSSProperties;
    tooltip: React.CSSProperties;
    metricValue: React.CSSProperties;
    microcopy: React.CSSProperties;
    popUpHeadline: React.CSSProperties;
    popUpBody: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headline1?: React.CSSProperties;
    headline2?: React.CSSProperties;
    headline3?: React.CSSProperties;
    headline4?: React.CSSProperties;
    body?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
    bodySmaller?: React.CSSProperties;
    bodySmallest?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    inputLabelLine?: React.CSSProperties;
    inputLabelSmall?: React.CSSProperties;
    chip?: React.CSSProperties;
    chipTiny?: React.CSSProperties;
    tooltip?: React.CSSProperties;
    metricValue?: React.CSSProperties;
    microcopy?: React.CSSProperties;
    popUpHeadline?: React.CSSProperties;
    popUpBody?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headline1: true;
    headline2: true;
    headline3: true;
    headline4: true;
    body: true;
    bodySmall: true;
    bodySmaller: true;
    bodySmallest: true;
    inputLabel: true;
    inputLabelLine: true;
    inputLabelSmall: true;
    chip: true;
    chipTiny: true;
    tooltip: true;
    metricValue: true;
    microcopy: true;
    popUpHeadline: true;
    popUpBody: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

interface CustomThemeProps {
  grey: {
    0: React.CSSProperties['color'];
    1: React.CSSProperties['color'];
    2: React.CSSProperties['color'];
    3: React.CSSProperties['color'];
    4: React.CSSProperties['color'];
    5: React.CSSProperties['color'];
    6: React.CSSProperties['color'];
    7: React.CSSProperties['color'];
  };
}

declare module '@mui/material/styles' {
  interface Theme extends CustomThemeProps {}

  interface Palette {
    menu: Palette['primary'];
    transparent: Palette['primary'];
    positive_sentiment?: Palette['primary'];
    negative_sentiment?: Palette['primary'];
    neutral_sentiment?: Palette['primary'];
    tertiary?: Palette['primary'];
  }

  interface PaletteOptions {
    menu: PaletteOptions['primary'];
    transparent: PaletteOptions['primary'];
    positive_sentiment?: PaletteOptions['primary'];
    negative_sentiment?: PaletteOptions['primary'];
    neutral_sentiment?: PaletteOptions['primary'];
    tertiary?: PaletteOptions['primary'];
  }

  interface ThemeOptions extends CustomThemeProps {}
}

const { palette } = createTheme();

export const commonThemeOptions: ThemeOptions = {
  spacing: 10,

  grey: {
    0: colorContext.GREY_PALETTE[0],
    1: colorContext.GREY_PALETTE[1],
    2: colorContext.GREY_PALETTE[2],
    3: colorContext.GREY_PALETTE[3],
    4: colorContext.GREY_PALETTE[4],
    5: colorContext.GREY_PALETTE[5],
    6: colorContext.GREY_PALETTE[6],
    7: colorContext.GREY_PALETTE[7],
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    headline1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '30px',
    },
    headline2: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '26px',
    },
    headline3: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    headline4: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    body: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    bodySmall: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    bodySmaller: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    bodySmallest: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    inputLabel: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '14px',
    },
    inputLabelLine: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    inputLabelSmall: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    chip: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.5px',
    },
    chipTiny: {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '12px',
    },
    tooltip: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.5px',
    },
  },

  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
        position: 'sticky',
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#fff',
          height: '60px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlinedInherit: {
          borderColor: '#696969',
          color: '#696969',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.MEDIUM_GREY,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 24,
          width: 48,
          display: 'flex',
        },
        switchBase: {
          padding: 3,
          color: '#fff',
          '&$checked': {
            transform: 'translateX(24px)',
            '& + $track': {
              opacity: 1,
              backgroundColor: colorContext.PRIMARY_PALETTE[200],
            },
          },
        },
        thumb: {
          width: 18,
          height: 18,
          backgroundColor: '#fff',
          boxShadow: 'none',
          padding: 0,
          margin: 0,
        },
        track: {
          height: 24,
          width: 48,
          borderRadius: 12,
          border: 'none',
          backgroundColor: '#b8bbcc',
          opacity: 1,
        },
        checked: {},
        sizeSmall: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          width: '160px',
          '&:hover': {
            color: 'rgb(95, 139, 255)',
          },
          '&:focus': {
            color: 'rgb(38, 77, 178)',
          },
          '&$selected': {
            color: '#376eff',
          },
        },
        selected: {},
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          width: '100%',
          borderBottom: `1px solid ${colors.MEDIUM_GREY}`,
        },
        fixed: {
          display: 'flex',
          justifyContent: 'center',
        },
        indicator: {
          backgroundColor: '#376eff',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.LIGHT_GREY}`,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
  },
};

export const lightTheme = createTheme({
  ...commonThemeOptions,

  palette: {
    mode: 'light',
    primary: palette.augmentColor({ color: { main: colors.ACTION_BLUE } }),
    secondary: palette.augmentColor({ color: { main: colors.CAUTION_RED } }),
    info: palette.augmentColor({ color: { main: '#2196f3' } }),
    error: {
      main: '#f44336',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      secondary: '#676a7d',
      disabled: '#b8bbcc',
      primary: '#1a1b21',
    },
    menu: {
      main: colorContext.MENU.main,
      dark: colorContext.MENU.shadow,
    },
    transparent: {
      main: colorContext.DEFAULT_TRANSPARENT,
    },
  },

  components: {
    ...commonThemeOptions.components,
  },
});

export const darkTheme = createTheme({
  ...commonThemeOptions,

  palette: {
    mode: 'dark',
    primary: palette.augmentColor({ color: { main: colors.ACTION_BLUE } }),
    secondary: palette.augmentColor({ color: { main: colors.CAUTION_RED } }),
    info: palette.augmentColor({ color: { main: '#2196f3' } }),
    error: {
      main: '#f44336',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      secondary: '#676a7d',
      disabled: '#b8bbcc',
      primary: '#1a1b21',
    },
    menu: {
      main: colorContext.MENU.main,
      dark: colorContext.MENU.shadow,
    },
    transparent: {
      main: colorContext.DEFAULT_TRANSPARENT,
    },
  },

  components: {
    ...commonThemeOptions.components,
  },
});

export const theme = createTheme({
  grey: {
    0: colorContext.GREY_PALETTE[0],
    1: colorContext.GREY_PALETTE[1],
    2: colorContext.GREY_PALETTE[2],
    3: colorContext.GREY_PALETTE[3],
    4: colorContext.GREY_PALETTE[4],
    5: colorContext.GREY_PALETTE[5],
    6: colorContext.GREY_PALETTE[6],
    7: colorContext.GREY_PALETTE[7],
  },
  palette: {
    mode: 'light',
    primary: palette.augmentColor({ color: { main: colors.ACTION_BLUE } }),
    secondary: palette.augmentColor({ color: { main: colors.RED } }),
    info: palette.augmentColor({ color: { main: '#2196f3' } }),
    error: {
      main: '#f44336',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      secondary: '#676a7d',
      disabled: '#b8bbcc',
      primary: '#1a1b21',
    },
    menu: {
      main: colorContext.MENU.main,
      dark: colorContext.MENU.shadow,
    },
    transparent: {
      main: colorContext.DEFAULT_TRANSPARENT,
    },
  },

  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    headline1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '30px',
    },
    headline2: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '26px',
    },
    headline3: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    headline4: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    body: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    bodySmall: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    bodySmaller: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    bodySmallest: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    inputLabel: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '14px',
    },
    inputLabelLine: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    inputLabelSmall: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    chip: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.5px',
    },
    chipTiny: {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '12px',
    },
    tooltip: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.5px',
    },
  },

  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
        position: 'sticky',
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#fff',
          height: '60px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: '18px',
          paddingLeft: '20px',
          paddingRight: '20px',
        },
        colorInherit: {
          color: colors.DARK_GREY,
        },
        containedInherit: {
          backgroundColor: colors.SECONDARY_GREY,
        },
        outlinedInherit: {
          background: colors.WHITE,
          borderColor: colors.DARK_GREY,
        },
        outlinedPrimary: {
          background: colors.WHITE,
        },
        outlinedSecondary: {
          background: colors.WHITE,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.LIGHT_GREY,
          padding: '35px 40px',
          maxWidth: '500px',
          width: '100%',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '32px',
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '40px 0 0 0',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.MEDIUM_GREY,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        sizeSmall: {
          height: '32px',
          width: '32px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 24,
          width: 48,
          display: 'flex',
        },
        switchBase: {
          padding: 3,
          color: '#fff',
          '&$checked': {
            transform: 'translateX(24px)',
            '& + $track': {
              opacity: 1,
              backgroundColor: '#4579ff',
            },
          },
        },
        thumb: {
          width: 18,
          height: 18,
          backgroundColor: '#fff',
          boxShadow: 'none',
          padding: 0,
          margin: 0,
        },
        track: {
          height: 24,
          width: 48,
          borderRadius: 12,
          border: 'none',
          backgroundColor: '#b8bbcc',
          opacity: 1,
        },
        checked: {},
        sizeSmall: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          width: '160px',
          '&:hover': {
            color: 'rgb(95, 139, 255)',
          },
          '&:focus': {
            color: 'rgb(38, 77, 178)',
          },
          '&$selected': {
            color: '#376eff',
          },
        },
        selected: {},
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          width: '100%',
          borderBottom: `1px solid ${colors.MEDIUM_GREY}`,
        },
        fixed: {
          display: 'flex',
          justifyContent: 'center',
        },
        indicator: {
          backgroundColor: '#376eff',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.LIGHT_GREY}`,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
  },
});

export const commsorRebranding = createTheme({
  grey: {
    0: colorContext.GREY_PALETTE[0],
    1: colorContext.GREY_PALETTE[1],
    2: colorContext.GREY_PALETTE[2],
    3: colorContext.GREY_PALETTE[3],
    4: colorContext.GREY_PALETTE[4],
    5: colorContext.GREY_PALETTE[5],
    6: colorContext.GREY_PALETTE[6],
    7: colorContext.GREY_PALETTE[7],
  },
  palette: {
    mode: 'light',
    primary: {
      light: colorContext.PRIMARY_PALETTE[100],
      main: colorContext.PRIMARY_PALETTE[200],
      dark: colorContext.PRIMARY_PALETTE[300],
      contrastText: colorContext.PRIMARY_PALETTE[50],
    },
    secondary: {
      light: colorContext.SECONDARY_PALETTE[100],
      main: colorContext.SECONDARY_PALETTE[200],
      dark: colorContext.SECONDARY_PALETTE[300],
      contrastText: colorContext.SECONDARY_PALETTE[50],
    },
    tertiary: {
      light: colorContext.TERTIARY_PALETTE[100],
      main: colorContext.TERTIARY_PALETTE[200],
      dark: colorContext.TERTIARY_PALETTE[300],
      contrastText: colorContext.TERTIARY_PALETTE[50],
    },
    info: {
      light: colorContext.INFO_PALETTE[100],
      main: colorContext.INFO_PALETTE[200],
      dark: colorContext.INFO_PALETTE[300],
      contrastText: colorContext.INFO_PALETTE[50],
    },
    error: {
      light: colorContext.ERROR_PALETTE[100],
      main: colorContext.ERROR_PALETTE[300],
      dark: colorContext.ERROR_PALETTE[300],
      contrastText: colorContext.ERROR_PALETTE[50],
    },
    warning: {
      light: colorContext.WARNING_PALETTE[100],
      main: colorContext.WARNING_PALETTE[200],
      dark: colorContext.WARNING_PALETTE[300],
      contrastText: colorContext.WARNING_PALETTE[50],
    },
    success: {
      light: colorContext.SUCCESS_PALETTE[100],
      main: colorContext.SUCCESS_PALETTE[200],
      dark: colorContext.SUCCESS_PALETTE[300],
      contrastText: colorContext.SUCCESS_PALETTE[50],
    },
    background: {
      default: colors.NEUTRALS.CHAMOMILE,
    },
    text: {
      secondary: colors.NEUTRALS.OOLONG,
      disabled: colors.NEUTRALS.IRISH_BREAKFAST,
      primary: colors.NEUTRALS.ESPRESSO,
    },
    menu: {
      main: colorContext.MENU.main,
      dark: colorContext.MENU.shadow,
    },
    transparent: {
      main: colorContext.DEFAULT_TRANSPARENT,
    },
  },

  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    headline1: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '30px',
      letterSpacing: '-0.02em',
    },
    headline2: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '26px',
    },
    headline3: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
    },
    headline4: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    body: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    bodySmall: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    bodySmaller: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    bodySmallest: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    inputLabel: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '14px',
    },
    inputLabelLine: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    inputLabelSmall: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    chip: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.5px',
    },
    chipTiny: {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '12px',
    },
    tooltip: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.5px',
    },
    metricValue: {
      fontSize: '22px',
      fontWeight: 700,
      lineHeight: '30px',
    },
    microcopy: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '12.1px',
    },
    popUpHeadline: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22px',
    },
    popUpBody: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
  },

  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
        position: 'sticky',
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#fff',
          height: '60px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          backgroundColor: colors.NEUTRALS.JASMINE,
          color: colors.NEUTRALS.ESPRESSO,
          textAlign: 'center',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: '18px',
          paddingLeft: '20px',
          paddingRight: '20px',
        },
        sizeLarge: {
          padding: '14px 20px',
        },
        colorInherit: {
          color: colors.NEUTRALS.OOLONG,
        },
        containedInherit: {
          backgroundColor: colors.SECONDARY_GREY,
        },
        outlinedInherit: {
          background: colors.WHITE,
          borderColor: colors.NEUTRALS.OOLONG,
        },
        outlinedPrimary: {
          background: colors.WHITE,
        },
        outlinedSecondary: {
          background: colors.WHITE,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '5px 16px',
          height: '25px',
        },
        label: {
          padding: 0,
        },
        outlined: {
          backgroundColor: colors.NEUTRALS.JASMINE,
          borderColor: colors.NEUTRALS.IRISH_BREAKFAST,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.NEUTRALS.CHAMOMILE,
          padding: '35px 40px',
          maxWidth: '500px',
          width: '100%',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '32px',
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '40px 0 0 0',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.NEUTRALS.JASMINE,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        sizeSmall: {
          height: '32px',
          width: '32px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 24,
          width: 48,
          display: 'flex',
        },
        switchBase: {
          padding: 3,
          color: '#fff',
          '&$checked': {
            transform: 'translateX(24px)',
            '& + $track': {
              opacity: 1,
              backgroundColor: colorContext.PRIMARY_PALETTE[200],
            },
          },
        },
        thumb: {
          width: 18,
          height: 18,
          backgroundColor: '#fff',
          boxShadow: 'none',
          padding: 0,
          margin: 0,
        },
        track: {
          height: 24,
          width: 48,
          borderRadius: 12,
          border: 'none',
          backgroundColor: '#b8bbcc',
          opacity: 1,
        },
        checked: {},
        sizeSmall: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          width: '160px',
          '&:hover': {
            color: colorContext.PRIMARY_PALETTE[100],
          },
          '&:focus': {
            color: colorContext.PRIMARY_PALETTE[200],
          },
          '&$selected': {
            color: colorContext.PRIMARY_PALETTE[200],
          },
        },
        selected: {},
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          width: '100%',
          borderBottom: `1px solid ${colors.MEDIUM_GREY}`,
        },
        fixed: {
          display: 'flex',
          justifyContent: 'center',
        },
        indicator: {
          backgroundColor: colorContext.PRIMARY_PALETTE[200],
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colorContext.GREY_PALETTE[1]}`,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        autoCapitalize: 'off',
        autoComplete: 'off',
        autoCorrect: 'off',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
  },
});

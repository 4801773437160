import { Row } from '@caravel/components/src';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'stores';

// import { AddFilterMenu } from './filters/add-filter-menu';
// import { FilterProps } from './filters/filter-props';
// import { InlineFilter } from './filters/inline-filter';
import { SearchFilter } from '../organizations/filters/search-filter';

export const OrganizationsFilters = observer(() => {
  const store = useStore();
  const organizations = store.teams.organizations;
  const filters = organizations.filters.slice();
  // const buttonRef = React.useRef<HTMLButtonElement>(null);
  // const handleRemoveFilter = (index: number) => () => {
  //   organizations.onRemoveFilter(index);
  // };

  return (
    <Row sx={{ padding: '14px 30px' }}>
      <Row
        sx={{
          flex: 1,
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        {filters.map((filter, i) => {
          const key = `${filter.type}-${i}`;
          const filterProps = {
            // const filterProps: FilterProps = {
            ...filter,
            // options: ORGANIZATIONS_FILTER_OPTIONS_CONFIG[filter.type],
            onChangeFilter: ({ value }: { value: string[] }, skipRefresh = false) =>
              organizations.onChangeFilter(i, { ...filter, value }, skipRefresh),
          };
          switch (filter.type) {
            case 'search':
              return <SearchFilter key={key} {...filterProps} />;
            default:
              return;
            // return <InlineFilter key={key} {...filterProps} anchorRef={buttonRef} />;
          }
        })}
        {/* <AddFilterMenu ref={buttonRef} onAddFilter={filter => people.onAddFilter(filter)} /> */}
      </Row>
    </Row>
  );
});

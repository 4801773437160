import { gql } from '@apollo/client';
import { SegmentKind } from '@caravel/types';
import { Person } from '@caravel/types';

import { SegmentRule } from '../mutations';
export const GET_SEGMENTS = gql`
  query getSegments($query: String, $orderBy: ItemOrder) {
    community {
      id
      segments(query: $query, orderBy: $orderBy) {
        edges {
          node {
            id
            kind
            name
            description
            createdBy
            createdAt
            updatedBy
            updatedAt
            peopleCount
            segmentRules {
              interConditionOperator
              interRuleOperator
              conditions {
                field
                operator
                values
              }
            }
          }
        }
        meta {
          facets {
            counts {
              count
              value
            }
            name
            totalValues
          }
          hits
          query
        }
      }
    }
  }
`;

export interface Segment {
  id: string;
  kind?: SegmentKind;
  name: string;
  description?: string;
  createdBy: string;
  createdAt: string;
  updatedBy?: string;
  updatedAt?: string;
  people?: Person[];
  peoplePaginated?: {
    edges: {
      node: Person;
    }[];
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
    };
  };
  peopleCount?: number;
  segmentRules?: SegmentRule[];
}

export interface GqlSegmentsRequestType {
  query?: string;
  orderBy?: { field: string; direction: string };
  filterBy?: {
    id__eq?: string;
  };
}

export interface GqlSegmentsResponseType {
  community: {
    id: string;
    segments: {
      edges: {
        node: Segment;
      }[];
      meta: {
        facets:
          | {
              counts: {
                count: number;
                value: string;
              }[];
              name: string;
              totalValues: number;
            }[]
          | null;
        hits: number;
        query: string;
      };
    };
  };
}

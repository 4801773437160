import {
  CaravelSpinner,
  Column,
  ConnectionsTableCell,
  CustomerFitChip,
  Flex,
  SortableTableHead,
} from '@caravel/components/src';
import { GREY_PALETTE, makeInitials } from '@caravel/utils';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { formatDistance } from 'date-fns';
import { observer } from 'mobx-react';
import { Segment } from 'models/segment';
import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-cool-inview';
import { useStore } from 'stores';

export const SegmentPeopleList = observer((props: { segment: Segment }) => {
  const store = useStore();
  const {
    ui,
    teams: { segments },
  } = store;

  const people = segments.segment?.peoplePaginated || [];

  const canLoad =
    store.team && (!segments.segmentPageInfo || segments.segmentPageInfo?.hasNextPage) && !segments.segmentLoading;

  const loadMore = useCallback(() => {
    if (!canLoad) return;
    ui.workOn(async () => {
      await segments.fetchSegment(props.segment.id);
    });
  }, [canLoad, segments, ui, props.segment.id]);

  useEffect(() => {
    if (!people.length) {
      loadMore();
    }
  }, [people.length, loadMore]);

  useEffect(() => {
    return () => {
      segments.resetSegment();
    };
  }, [segments]);

  const handleRequestSort = (id: string) => {
    segments.onChangeSegmentOrder(id);
    loadMore();
  };

  const { observe } = useInView({
    onEnter: () => {
      loadMore();
    },
  });

  return (
    <Column sx={{ width: '100%' }}>
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            cells={[
              {
                id: 'name',
                label: 'Name',
                disablePadding: false,
                minWidth: '200px',
                width: '280px',
                maxWidth: '280px',
              },
              {
                id: 'employmentTitle',
                label: 'Title',
                minWidth: '200px',
                width: `calc(100%/${5})`,
                maxWidth: '400px',
              },
              { id: 'customerFit', label: 'Customer Fit', width: `calc(100%/${5})`, maxWidth: '300px' },
              {
                id: 'connectedTo',
                label: 'Connections',
                width: `calc(100%/${5})`,
                maxWidth: '300px',
                disabledSort: true,
              },
              {
                id: 'lastActive',
                label: 'Last Active',
                minWidth: '140px',
                width: `calc(100%/${5})`,
                maxWidth: '300px',
              },
            ]}
            order={segments.segmentOrder}
            orderBy={segments.segmentOrderBy}
            onSort={handleRequestSort}
          />
          <TableBody>
            {people.map(row => (
              <TableRow key={row.id} sx={{ minHeight: '50px', height: '100%' }}>
                <TableCell
                  padding="none"
                  sx={{
                    tableLayout: 'fixed',
                    padding: '10px 0 10px 30px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <RouterLink
                    to={`/people/${row.id}`}
                    color="textPrimary"
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    title={row.name}
                  >
                    <Avatar
                      src={row.avatar}
                      alt={row.name}
                      sx={{ width: 30, height: 30, marginRight: '10px', fontSize: '12px' }}
                    >
                      {makeInitials({ name: row.name, givenName: row.givenName, familyName: row.familyName })}
                    </Avatar>
                    <Typography
                      variant="headline4"
                      maxWidth="280px"
                      display="block"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {row.name}
                    </Typography>
                  </RouterLink>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="headline4"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    maxWidth="400px"
                    display="block"
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {row.employmentTitle || '---'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <CustomerFitChip customerFit={row.customerFit} />
                </TableCell>
                <TableCell>
                  <ConnectionsTableCell
                    userId={store.auth.user!.uid}
                    person={row}
                    teamConnections={store.teams.teamMembers.getTeamConnections(row.connectedTo)}
                    handleAskIntroduction={store.teams.people.askForIntroduction}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="headline4" sx={{ color: GREY_PALETTE[6] }}>
                    {row.lastSeen ? formatDistance(new Date(row.lastSeen ?? new Date()), new Date()) + ' ago' : '---'}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Column sx={{ padding: '28px' }}>
        {!segments.segmentLoading && <Column ref={observe} />}
        {(segments.segmentLoading || segments.segmentPageInfo?.hasNextPage) && <CaravelSpinner />}
        <Flex flex={1} sx={{ paddingTop: '20px', width: '100%' }} />
      </Column>
    </Column>
  );
});

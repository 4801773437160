/**
 * Callable function names for Firebase
 */

export const ADD_CARAVEL_ANALYST = 'addCaravelAnalyst';
export const CREATE_INVITE = 'createInvite';
export const RESEND_INVITE = 'resendInvite';
export const ACCEPT_INVITE = 'acceptInvite';

export const UPDATE_TEAM_MEMBER = 'updateTeamMember';
export const REMOVE_TEAM_MEMBER = 'removeTeamMember';

export const SEND_HUB_EVENT = 'sendHubEvent';
export const SEND_WELCOME_EMAIL = 'sendWelcomeEmail';

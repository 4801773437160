// import { UsageCounts } from '@caravel/types';
// import { doc, onSnapshot } from 'firebase/firestore';
// import { getDb } from 'helpers';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

// import { WorkspaceTabProps } from '../workspace-container';
import { BillingView } from './billing-view';

export const BillingContainer = observer(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const store = useStore();
  const team = store.team;
  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   const countsRef = store.team ? doc(getDb(), store.team.docRef.path, 'usage', 'counts') : null;
  //   if (countsRef) {
  //     const disposer = onSnapshot(countsRef, snap => {
  //       const data = snap.data() as UsageCounts | undefined;
  //       if (!data) {
  //         setCount(0);
  //         return;
  //       }
  //       setCount(data.cycleCount);
  //     });
  //     return () => {
  //       disposer && disposer();
  //     };
  //   }
  // }, [store.team]);

  if (!team || !team.plan) {
    return null;
  }

  // const planName = team.plan.name;
  // const limit = team.plan.limit;
  // const standing = store.teams.standing;

  return <BillingView />;
});

export default BillingContainer;

const FEATURES = {
  airflowBulkRealTime: false,
  airflowHistorical: false,
  airflowRealTime: false,
  apiKey: false,
  appStore: false,
  automations: false,
  bigquery: false,
  champions: true,
  communityInfo: false,
  composer: false,
  crm: false,
  darkMode: false,
  dashboard: false,
  deleteConnector: false,
  domains: false,
  emergingThemes: false,
  ensembleTesting: false,
  exportMentions: false,
  exportTrainingTexts: false,
  feeds: false,
  freshdesk: false,
  front: false,
  helpscout: false,
  hubEvents: false,
  insightsOverview: false,
  mergeMembers: false,
  nylas: false,
  optInReferences: true,
  organizations: false,
  payments: false,
  pdfExport: false,
  playStore: false,
  proofLibrary: true,
  redactions: false,
  reports: false,
  salesforce: false,
  searchMaps: false,
  segments: false,
  settingsBilling: true,
  settingsPlans: false,
  signupQuestions: false,
  slack: false,
  sources: false,
  teamLogo: false,
  teamQAReport: false,
  trove: false,
  troveSkipLogic: false,
  webhooks: false,
  zendesk: false,
};

export type Features = typeof FEATURES & { [key: string]: boolean };

export type FeatureName = keyof typeof FEATURES;

export function extractValidFeatures(obj: Record<string, unknown>): Partial<Features> {
  const features: Partial<Features> = {};
  Object.keys(FEATURES).forEach(key => {
    if (obj[key] !== undefined && typeof obj[key] === 'boolean') {
      features[key as FeatureName] = obj[key] as boolean;
    }
  });
  return features;
}

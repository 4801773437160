export class Logger {
  readonly label: string;

  constructor(label: string) {
    this.label = `[${label}]`;
  }

  trace = (...args: any) => {
    if (process.env.NODE_ENV === 'development') {
      console.trace(this.label, ...args);
    }
  };

  debug = (...args: any) => {
    if (process.env.NODE_ENV === 'development') {
      console.debug(this.label, ...args);
    }
  };

  info = (...args: any) => {
    if (process.env.NODE_ENV !== 'test') {
      console.info(this.label, ...args);
    }
  };

  warn = (...args: any) => {
    if (process.env.NODE_ENV !== 'test') {
      console.warn(this.label, ...args);
    }
  };

  error = (...args: any) => {
    if (process.env.NODE_ENV !== 'test') {
      console.error(this.label, ...args);
    }
  };
}

import { Column } from '@caravel/components/src';
import { Button, Typography } from '@mui/material';
import { sendEmailVerification } from 'firebase/auth';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useStore } from 'stores';

import { OnboardingWrapper } from '../onboarding/onboarding-wrapper';

export const VerifyEmailContainer: FC = observer(() => {
  const store = useStore();

  const onClickSendVerification = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await sendEmailVerification(store.auth.user);
  };

  return (
    <OnboardingWrapper>
      <Column alignItems="stretch" width="100%" maxWidth="360px">
        <Typography variant="headline1" component="h1" marginBottom="40px" textAlign="center">
          We&rsquo;ve sent a confirmation email to the address provided. Follow the link in the email to complete your
          account setup.
        </Typography>
        <Button color="primary" variant="contained" fullWidth size="large" onClick={onClickSendVerification}>
          I didn't get a link, please resend
        </Button>
      </Column>
    </OnboardingWrapper>
  );
});

export default VerifyEmailContainer;

import { gql } from '@apollo/client';

export const UPDATE_COMMUNITY_SLUG = gql`
  mutation ChangeSlug($firebaseId: String!, $slug: String!) {
    changeSlug(firebaseId: $firebaseId, slug: $slug) {
      id
      slug
    }
  }
`;

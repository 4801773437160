import { FacetType, FilterCondition, PeopleFilterType } from '@caravel/types';
import { DocumentNode } from 'graphql';

import {
  GET_ORGANIZATION_NAMES,
  GET_PEOPLE_CHANNELS,
  GET_PEOPLE_CITIES,
  GET_PEOPLE_COUNTRIES,
  GET_PEOPLE_ORG_CITIES,
  GET_PEOPLE_ORG_COUNTRIES,
  GET_PEOPLE_ORG_INDUSTRIES,
  GET_PEOPLE_ORG_STATES,
  GET_PEOPLE_TITLES,
} from './gql';

export const CONDITION_LABELS: Record<FilterCondition, string> = {
  is: 'is',
  not: 'is not',
  includes: 'includes',
  'not-includes': 'does not include',
  'all-of': 'is all of',
  'any-of': 'is any of',
  'not-any-of': 'is not any of',
  'not-all-of': 'is not all of',
  before: 'before',
  after: 'after',
  'greater-than': 'is greater than',
  'less-than': 'is less than',
};

export const SINGLE_CONDITIONS: FilterCondition[] = [
  'is',
  'not',
  'includes',
  'not-includes',
  'greater-than',
  'less-than',
];

export const MULTI_CONDITIONS: FilterCondition[] = ['all-of', 'any-of', 'not-any-of', 'not-all-of'];

export function isSingleCondition(condition: FilterCondition | null): boolean {
  return Boolean(condition && SINGLE_CONDITIONS.includes(condition));
}

export function isMultiCondition(condition: FilterCondition | null): boolean {
  return Boolean(condition && MULTI_CONDITIONS.includes(condition));
}

export function facetTypeFromFilterCondition(condition: FilterCondition): FacetType {
  switch (condition) {
    case 'includes':
    case 'is':
      return 'IS';
    case 'not-includes':
    case 'not':
      return 'ISNOT';
    case 'any-of':
      return 'ANY';
    case 'all-of':
      return 'EVERY';
    case 'not-any-of':
      return 'NOTANY';
    case 'not-all-of':
      return 'NOTEVERY';
    case 'before':
      return 'BEFORE';
    case 'after':
      return 'AFTER';
    case 'greater-than':
      return 'GREATERTHAN';
    case 'less-than':
      return 'LESSTHAN';
    default:
      throw new Error(`Invalid condition: ${condition}`);
  }
}

/**
 * We should abstract this further maybe? Depending on
 * how diffrent filters are from eachother.
 *
 * Below will need to be updated will all new types added
 */
export type PeopleQueryType = {
  [key in PeopleFilterType]?: DocumentNode;
};

export const PEOPLE_QUERY: PeopleQueryType = {
  'organization-industry': GET_PEOPLE_ORG_INDUSTRIES,
  'organization-city': GET_PEOPLE_ORG_CITIES,
  'organization-state': GET_PEOPLE_ORG_STATES,
  'organization-country': GET_PEOPLE_ORG_COUNTRIES,
  source: GET_PEOPLE_CHANNELS,
  organization: GET_ORGANIZATION_NAMES,
  country: GET_PEOPLE_COUNTRIES,
  city: GET_PEOPLE_CITIES,
  title: GET_PEOPLE_TITLES,
};

/**
 * search the correct map based on collectionType and type for the query we need
 * @param collectionType the type of collection we are filtering
 * @param type the type of point we are filtering on
 * @returns DocumentNode | undefined
 */
export function getFilterQuery(collectionType: string, type: string): DocumentNode | undefined {
  try {
    switch (collectionType) {
      case 'people':
        return PEOPLE_QUERY[type as PeopleFilterType];
      case 'champions':
        return PEOPLE_QUERY[type as PeopleFilterType];
      default:
        return undefined;
    }
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

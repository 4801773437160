import { GREY_PALETTE, parseDate, PRIMARY_PALETTE } from '@caravel/utils';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Collapse,
  Grid,
  Link,
  Modal,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { forwardRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useStore } from 'stores';

import { BackArrow, MergeArrow } from './people-icons';

export const MergePeopleModal = observer(() => {
  const store = useStore();
  const [viewDraft, setViewDraft] = useState(false);
  const open = store.teams.people.canMergePeople;
  const selectedPeople = store.teams.people.selectedPeople;
  const priorityPerson = selectedPeople[selectedPeople.length - 1];
  const otherPeople = selectedPeople.filter(p => p != priorityPerson);
  const firstOtherPerson = otherPeople[0] ?? { name: '', avatar: '' };

  const initiateMerge = async () => {
    const memberIds = selectedPeople.map(p => p.id!);
    const priorityId = priorityPerson.id!;
    await store.teams.people.mergeMembers(memberIds, priorityId);
  };
  // Borrowed styles from person detail drawer
  const KeyTableCell = forwardRef<HTMLTableCellElement, TableCellProps>((props, ref) => (
    <TableCell
      {...props}
      ref={ref}
      sx={{
        color: GREY_PALETTE[7],
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: 500,
        ...props.sx,
      }}
    />
  ));
  KeyTableCell.displayName = 'KeyTableCell';

  const ValueTableCell = forwardRef<HTMLTableCellElement, TableCellProps>((props, ref) => (
    <TableCell
      {...props}
      ref={ref}
      sx={{
        color: GREY_PALETTE[7],
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: 400,
        ...props.sx,
      }}
    />
  ));
  ValueTableCell.displayName = 'ValueTableCell';

  const truncateName = (name: string) => {
    const limit = 20;
    if (name.length < limit) {
      return name;
    }

    return name.slice(0, limit).concat('...');
  };

  const mergePreview = (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiGrid-item': { marginRight: '6px' },
      }}
    >
      <Grid item>
        <AvatarGroup
          max={2}
          sx={{
            '& .MuiAvatarGroup-avatar': {
              width: '20px',
              height: '20px',
              fontSize: '10px',
              background: GREY_PALETTE[2],
            },
          }}
        >
          {otherPeople.map((p, i) => (
            <Avatar key={i} src={p.avatar} alt={p.name} sx={{ width: 20, height: 20, fontSize: '10px' }}>
              {p.initials}
            </Avatar>
          ))}
        </AvatarGroup>
      </Grid>
      <Grid item>
        <Typography variant="microcopy">
          {truncateName(firstOtherPerson.name ?? '')}
          {otherPeople.length > 1 && ` and ${otherPeople.length - 1} others`}
        </Typography>
      </Grid>
      <Grid item>
        <MergeArrow width={26} />
      </Grid>
      <Grid item>
        <Avatar
          src={priorityPerson?.avatar}
          alt={priorityPerson?.name}
          sx={{
            width: 20,
            height: 20,
            fontSize: '10px',
            background: GREY_PALETTE[2],
          }}
        >
          {priorityPerson?.initials}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="microcopy">{`${truncateName(priorityPerson?.name ?? '')} (Primary)`}</Typography>
      </Grid>
    </Grid>
  );

  const draftPreview = (
    <Table
      size="small"
      sx={{
        width: '70%',
        margin: 'auto',
        '& .MuiTableCell-root': {
          border: 'unset',
          padding: '5px 0',
        },
      }}
    >
      <TableBody>
        <TableRow>
          <KeyTableCell>Name</KeyTableCell>
          <ValueTableCell>{priorityPerson?.name}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>Job Title</KeyTableCell>
          <ValueTableCell>{priorityPerson?.employmentTitle}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>Organization</KeyTableCell>
          <ValueTableCell>{priorityPerson?.organization?.name}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>Member Since</KeyTableCell>
          {priorityPerson?.firstSeen && (
            <ValueTableCell>{parseDate(priorityPerson?.firstSeen)?.toLocaleDateString()}</ValueTableCell>
          )}
        </TableRow>
        <TableRow>
          <KeyTableCell>First Active</KeyTableCell>
          {priorityPerson?.firstSeen && (
            <ValueTableCell>{parseDate(priorityPerson?.firstSeen)?.toLocaleDateString()}</ValueTableCell>
          )}
        </TableRow>
        <TableRow>
          <KeyTableCell>Last Active</KeyTableCell>
          {priorityPerson?.lastSeen && (
            <ValueTableCell>{parseDate(priorityPerson?.lastSeen)?.toLocaleDateString()}</ValueTableCell>
          )}
        </TableRow>
        <TableRow>
          <KeyTableCell>Email Address</KeyTableCell>
          <ValueTableCell>{priorityPerson?.email}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>City</KeyTableCell>
          <ValueTableCell>{priorityPerson?.city}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>State</KeyTableCell>
          <ValueTableCell>{priorityPerson?.state}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>Country</KeyTableCell>
          <ValueTableCell>{priorityPerson?.country}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>Website</KeyTableCell>
          <ValueTableCell
            sx={{
              overflow: 'hidden',
            }}
          >
            <Link
              href={priorityPerson?.website}
              title={priorityPerson?.website}
              sx={{
                display: 'inline-block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '200px',
                width: '100%',
              }}
            >
              {priorityPerson?.website}
            </Link>
          </ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>Facebook</KeyTableCell>
          <ValueTableCell>{priorityPerson?.facebookHandle}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>Twitter</KeyTableCell>
          <ValueTableCell>{priorityPerson?.twitterHandle}</ValueTableCell>
        </TableRow>
        <TableRow>
          <KeyTableCell>LinkedIn</KeyTableCell>
          <ValueTableCell>
            {priorityPerson?.linkedinHandle ? (
              <Link href={`http://linkedin.com/${priorityPerson.linkedinHandle}`}>
                {priorityPerson?.linkedinHandle}
              </Link>
            ) : null}
          </ValueTableCell>
        </TableRow>
        {/* TODO: Add custom attributes here when available */}
      </TableBody>
    </Table>
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        setViewDraft(false);
        store.teams.people.closeMergingPeopleModal();
      }}
    >
      <Box
        sx={{
          maxHeight: '90vh',
          width: '500px',
          bgcolor: GREY_PALETTE[1],
          borderRadius: '5px',
          padding: '40px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography variant="popUpHeadline" sx={{ display: 'block', marginBottom: '10px' }}>
          Are you sure you want to merge?
        </Typography>
        <Typography variant="popUpBody" sx={{ color: GREY_PALETTE[5] }}>
          Attributes from the most recently selected profile will be given priority. Attributes not given priority will
          be retained as secondary attributes in the merged profile and duplicate values will be combined. This
          operation is permanent, profiles cannot be unmerged.
        </Typography>
        <Box
          sx={{
            maxHeight: '45vh',
            overflowY: 'auto',
            marginTop: '40px',
            marginBottom: '40px',
          }}
        >
          <TransitionGroup>
            {!viewDraft && <Collapse>{mergePreview}</Collapse>}
            {viewDraft && <Collapse>{draftPreview}</Collapse>}
          </TransitionGroup>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            {viewDraft && (
              <Link
                component="button"
                onClick={() => {
                  setViewDraft(false);
                }}
                sx={{
                  paddingBottom: '2px',
                  borderBottom: '1px solid',
                  '&:hover': { textDecoration: 'none' },
                }}
              >
                <SvgIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                    verticalAlign: 'text-bottom',
                    marginRight: '4px',
                    '& path': { stroke: PRIMARY_PALETTE[200] },
                  }}
                >
                  <BackArrow />
                </SvgIcon>
                Back
              </Link>
            )}
            {!viewDraft && (
              <Link
                component="button"
                onClick={() => {
                  setViewDraft(true);
                }}
                sx={{
                  paddingBottom: '2px',
                  borderBottom: '1px solid',
                  '&:hover': { textDecoration: 'none' },
                }}
              >
                View Draft
              </Link>
            )}
          </Grid>
          <Grid item xs={6} container sx={{ flexDirection: 'row-reverse' }}>
            <Button variant="contained" onClick={() => initiateMerge()}>
              Merge
            </Button>
            <Button
              variant="outlined"
              sx={{ marginRight: '10px' }}
              onClick={() => {
                setViewDraft(false);
                store.teams.people.closeMergingPeopleModal();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
});

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, MenuItem, Popover } from '@mui/material';
import React, { useState } from 'react';

import { DeleteIcon } from './trove-icons';

export interface QuestionMenuProps {
  id: number;
  handleRemove: (id: number) => void;
}

export const QuestionMenu = (props: QuestionMenuProps) => {
  const { id, handleRemove } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={'question-options-' + id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            minWidth: '120px',
          },
        }}
      >
        <MenuItem onClick={() => handleRemove(id)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: 'center' }}>Delete</ListItemText>
        </MenuItem>
      </Popover>
    </>
  );
};

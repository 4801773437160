import { Column } from '@caravel/components';
import { Button, Link, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as EmailOpenIcon } from '../../../assets/svgs/email-open.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/svgs/google.svg';
import { RouterLink } from '../../router-link';

interface SignUpViewProps {
  onSignUpWithGoogle: () => void;
  onSwitchToEmail: () => void;
}

export function SignUpView(props: SignUpViewProps) {
  const { onSignUpWithGoogle, onSwitchToEmail } = props;

  const handleSignUpWithGoogle = async () => {
    await onSignUpWithGoogle();
  };

  return (
    <Column alignItems="stretch" width="100%" maxWidth="260px">
      <Typography variant="headline1" textAlign="center" component="h1" marginBottom="15px">
        👋
      </Typography>
      <Typography variant="headline1" textAlign="center" component="h1" marginBottom="15px">
        Welcome to Commsor Champions
      </Typography>
      <Typography variant="bodySmall" textAlign="center" marginBottom="30px">
        By continuing you agree to the
        <br />
        <Link href="https://www.commsor.com/terms" rel="noopener noreferrer" target="_blank">
          terms of service
        </Link>{' '}
        and{' '}
        <Link href="https://www.commsor.com/terms?page=privacy" rel="noopener noreferrer" target="_blank">
          privacy policy
        </Link>
        .
      </Typography>
      <Button
        variant="outlined"
        size="large"
        startIcon={<GoogleIcon width="20px" height="20px" />}
        onClick={handleSignUpWithGoogle}
        fullWidth
        sx={{ marginBottom: '8px' }}
      >
        Sign up with Google
      </Button>
      <Button
        variant="outlined"
        size="large"
        startIcon={<EmailOpenIcon width="20px" height="20px" />}
        fullWidth
        sx={{ marginBottom: '18px' }}
        onClick={onSwitchToEmail}
      >
        Sign up with Work Email
      </Button>
      <Typography variant="bodySmallest" textAlign="center">
        Already have an account? <RouterLink to={`/sign-in?${location.search}`}>Sign in</RouterLink>.
      </Typography>
    </Column>
  );
}

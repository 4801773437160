import { Flex } from '@caravel/components/src';
import { DARK_GREEN, NEUTRALS, RED, WHITE, YELLOW } from '@caravel/utils';
import { SxProps } from '@mui/material';
import chroma from 'chroma-js';
import React from 'react';

const gradient = chroma.scale([RED, YELLOW, DARK_GREEN]);

const transformRange = (value: number) => {
  return (value - 0.5) * 2;
};

export interface NumberChipProps {
  value: string | number;
  gradientValue?: number;
  green?: boolean;
  yellow?: boolean;
  red?: boolean;
  jasmine?: boolean;

  small?: boolean;

  className?: string;
  sx?: SxProps;
}

export function NumberChip(props: NumberChipProps) {
  const { className, value, gradientValue, green, yellow, red, jasmine, small, sx } = props;
  const scaledValue = gradientValue === undefined ? undefined : transformRange(gradientValue);
  return (
    <Flex
      className={className}
      sx={{
        backgroundColor: NEUTRALS.JASMINE,
        color: WHITE,
        alignSelf: 'flex-start',
        fontSize: 12,
        lineHeight: '16px',
        padding: '4px 12px',
        borderRadius: '4px',
        letterSpacing: '-0.4%',
        ...(green ? { backgroundColor: DARK_GREEN } : {}),
        ...(yellow ? { backgroundColor: YELLOW } : {}),
        ...(red ? { backgroundColor: RED } : {}),
        ...(jasmine ? { backgroundColor: NEUTRALS.JASMINE, color: NEUTRALS.ESPRESSO } : {}),
        ...(small
          ? {
              fontSize: 10,
              fontWeight: 600,
              lineHeight: '12.1px',
              letterSpacing: '2%',
              padding: '4px 10px',
            }
          : {}),
        ...sx,
        ...(scaledValue === undefined
          ? {}
          : {
              backgroundColor: gradient(scaledValue).css(),
            }),
      }}
    >
      {`${value}`}
    </Flex>
  );
}

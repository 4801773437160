import {
  createGQLClient,
  GET_INTEGRATIONS,
  getComparator,
  GqlIntegrationsRequestType,
  GqlIntegrationsResponseType,
  isEmptyString,
  notEmptyString,
} from '@caravel/utils';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Integration } from 'models/integration';

import { BaseStore } from './base';
import { RootStore } from './root';
export class IntegrationStore extends BaseStore {
  collection = observable.array<Integration>([]);
  loading = false;
  loaded = false;
  integration?: Integration = undefined;

  order: 'asc' | 'desc' = 'desc';
  orderBy = 'launchedAt';

  get orderByOptions() {
    return {
      field: this.orderBy,
      direction: this.order.toUpperCase(),
    };
  }

  onChangeOrder = (orderBy: string) => {
    const isAsc = orderBy === this.orderBy && this.order === 'asc';
    runInAction(() => {
      this.order = isAsc ? 'desc' : 'asc';
      this.orderBy = orderBy;
      this.resetCollection();
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      orderBy: observable,
      integration: observable,
      orderByOptions: computed,
    });
  }

  teardown() {
    this.collection.clear();
    super.teardown();
  }

  resetCollection = () => {
    runInAction(() => {
      this.collection.clear();
    });
  };

  async mount() {
    await this.fetchIntegrations();
  }

  unmount() {
    this.resetCollection();
  }

  fetchIntegrations = async () => {
    if (this.loading) {
      console.debug('Integrations already loading');
      return;
    }
    runInAction(() => (this.loading = true));

    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);

    try {
      const response: GqlIntegrationsResponseType = await graphqlClient.query<
        GqlIntegrationsRequestType,
        GqlIntegrationsResponseType
      >(GET_INTEGRATIONS, { orderBy: this.orderByOptions });

      const integrations: Integration[] = response.community.integrations.map(
        integration =>
          new Integration({
            ...integration,
            channels: integration.channels
              .filter(channel => notEmptyString(channel.name))
              .sort(getComparator('desc', 'createdAt'))
              .map(channel => ({
                ...channel,
                name: isEmptyString(channel.name) ? 'Unnamed' : channel.name,
              })),
          }),
      );

      runInAction(() => {
        this.collection.replace(integrations);
        this.loading = false;
        this.loaded = true;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to fetch integrations',
        duration: 5000,
      });
    }
  };
}

import { User } from 'firebase/auth';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { getDb, Logger } from 'helpers';
import { Membership, MembershipProps, READER_ROLES } from 'models';

import { FirebaseStore } from './base';
import { RootStore } from './root';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('membership-store');

export class MembershipStore extends FirebaseStore<Membership, MembershipProps> {
  constructor(rootStore: RootStore) {
    super({
      rootStore,
      modelConstructor: Membership,
    });
  }

  async setup() {
    await super.setup();
    this.authSubs.addEffect('memberships', this.subToMemberships);
  }

  teardown() {
    super.teardown();
  }

  private subToMemberships = (user: User) => {
    this.collection.clear();
    const membershipsRef = collection(getDb(), 'members', user.uid, 'memberships');

    return onSnapshot(query(membershipsRef, where('role', 'in', READER_ROLES)), this.updateFromSnapshot);
  };
}

import { gql } from '@apollo/client';

export const GET_COMMUNITY_LAYERS = gql`
  query getCommunityLayers {
    community {
      id
      stats {
        position {
          name
          count
        }
      }
    }
  }
`;

export interface GqlCommunityLayersResponseType {
  community: {
    id: string;
    stats: {
      position: {
        name: string;
        count: number;
      }[];
    };
  };
}

import { AddButton, Column, Flex, NarrowColumn, PageHeader, Row } from '@caravel/components';
import { MemberRoles } from '@caravel/types';
import { getComparator, isEmptyString, isValidEmail, stableSort } from '@caravel/utils';
import { DARK_GREY, LIGHT_GREY, MEDIUM_GREY } from '@caravel/utils/src/constants/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { FlexBox } from 'components/flex-box';
import { RouterLink } from 'components/router-link';
import { DomainsForm } from 'components/workspace/domains-form';
import { useQuery } from 'helpers';
import React, { ChangeEvent, FormEvent, MouseEvent, useState } from 'react';
import { useStore } from 'stores';

import { AppToolbar } from '../../app-toolbar';
import { TeamLogoUploader } from './team-logo-uploader';
import { TeamNameInput } from './team-name-input';
import { Sortable, TableHeadView } from './teammates-table-head-view';

const anchorOrigin: MenuProps['anchorOrigin'] = {
  horizontal: 'right',
  vertical: 'bottom',
};

const transformOrigin: MenuProps['transformOrigin'] = {
  horizontal: 'right',
  vertical: 'top',
};

export interface TeammatesRowProps {
  id: string;
  name: string;
  role: MemberRoles;
  photo?: string;
  email: string;
  working: boolean;
  inviteId?: string;
}

export interface TeammatesViewProps {
  order: 'asc' | 'desc';
  orderBy: Sortable;
  canWrite: boolean;
  rows: TeammatesRowProps[];
  // table
  onRequestSort: (event: React.MouseEvent<unknown>, property: Sortable) => void;
  // members
  onChangeRole: (teamMember: TeammatesRowProps, role: MemberRoles) => void;
  onRemoveTeamMember: (teamMember: TeammatesRowProps) => Promise<void>;
  // invites
  onInviteWithEmail: (email: string) => Promise<boolean>;
  onResendInvite: (id: string) => Promise<void>;
}

export const TeammatesView: React.FC<TeammatesViewProps> = ({
  rows,
  order,
  orderBy,
  canWrite,
  onRequestSort,
  onChangeRole,
  onRemoveTeamMember,
  onInviteWithEmail,
  onResendInvite,
}) => {
  const query = useQuery();
  const store = useStore();

  const sorted = stableSort(rows, getComparator(order, orderBy));

  const [moreAnchorEl, setMoreAnchorEl] = useState<EventTarget & Element>();
  const [moreId, setMoreId] = useState<string | undefined>();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [inviteDialogOpen, setInviteDialogOpen] = useState(Boolean(query.get('invite')) || false);
  const [working, setWorking] = useState(false);
  const hasEmailError = !isEmptyString(emailError);

  const onClickAdd = () => setInviteDialogOpen(true);
  const onClickMore = (rowId: string) => (e: MouseEvent) => {
    setMoreAnchorEl(e.currentTarget);
    setMoreId(rowId);
  };
  const onCloseMoreMenu = () => {
    setMoreAnchorEl(undefined);
    setMoreId(undefined);
  };

  const onCloseInviteDialog = () => {
    setInviteDialogOpen(false);
    setEmail('');
    setEmailError('');
  };

  const onChangeRoleSelect = (teamMember: TeammatesRowProps) => (e: SelectChangeEvent<MemberRoles>) => {
    onChangeRole(teamMember, e.target.value as MemberRoles);
  };

  const onChangeEmail = (e: ChangeEvent<{ value: unknown }>) => {
    const email = e.target.value as string;
    if (isValidEmail(email)) {
      setEmailError('');
    } else {
      setEmailError('Invalid email');
    }
    setEmail(email);
  };

  const onSendInvite = async (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    if (hasEmailError || working) {
      return;
    }
    setWorking(true);
    setInviteDialogOpen(false);
    if (await onInviteWithEmail(email)) {
      setEmail('');
    }
    setWorking(false);
  };

  return (
    <Column>
      <AppToolbar title="Team" />
      <NarrowColumn>
        <PageHeader headline="Team" description="Manage your team workspace" />

        <TeamNameInput />

        {store.features.hasTeamLogo && <TeamLogoUploader />}

        <DomainsForm />

        <Divider sx={{ width: '100%', marginBottom: '30px', marginTop: '30px' }} />

        <Row sx={{ marginBottom: '30px', alignItems: 'center' }}>
          <Typography variant="inputLabel">Team members</Typography>
          <Flex flex={1} />
          <AddButton color="primary" onClick={onClickAdd}>
            Invite team member
          </AddButton>
        </Row>

        <TableContainer sx={{ paddingBottom: '40px' }}>
          <Table
            sx={{
              borderCollapse: 'separate',
              border: `1px solid ${MEDIUM_GREY}`,
              borderRadius: '5px',
              overflow: 'hidden',
            }}
          >
            <TableHeadView order={order} orderBy={orderBy} onRequestSort={onRequestSort} />
            <TableBody>
              {sorted.map((row, index) => {
                const labelId = `${row.id}-${index}`;
                const { inviteId } = row;
                const removeText = inviteId ? 'Remove Invite' : 'Remove Teammate';
                return (
                  <TableRow
                    key={row.id}
                    sx={{
                      borderBottom: `1px solid ${LIGHT_GREY}`,
                    }}
                  >
                    <TableCell>
                      <FlexBox alignItems="center">
                        <Avatar
                          src={row.photo}
                          sx={{
                            height: '30px',
                            width: '30px',
                            marginRight: '8px',
                          }}
                        />
                        <Typography variant="headline4">{row.name}</Typography>
                      </FlexBox>
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row">
                      <RouterLink to={`/teammate/${row.id}`}>
                        <FlexBox flexDirection="row" alignItems="center">
                          <div>
                            <Typography sx={{ color: DARK_GREY }} variant="headline4">
                              {row.email}
                            </Typography>
                          </div>
                        </FlexBox>
                      </RouterLink>
                    </TableCell>
                    <TableCell>
                      <Select
                        value={row.role}
                        disabled={row.working || !canWrite}
                        onChange={onChangeRoleSelect(row)}
                        sx={{
                          height: '32px',
                          paddingTop: 0,
                          paddingBottom: 0,
                          fontSize: '13px',
                          width: '100%',
                        }}
                      >
                        <MenuItem value="admin">Owner</MenuItem>
                        <MenuItem value="manager">Manager</MenuItem>
                        <MenuItem value="reader">Member</MenuItem>
                        {row.role === 'super' && <MenuItem value="super">Super</MenuItem>}
                        {row.role === 'analyst' && <MenuItem value="analyst">Caravel Analyst</MenuItem>}
                      </Select>
                    </TableCell>
                    <TableCell align="right" padding="none">
                      <IconButton onClick={onClickMore(row.id)} size="large">
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        sx={{
                          marginTop: 0,
                        }}
                        anchorOrigin={anchorOrigin}
                        transformOrigin={transformOrigin}
                        anchorEl={moreAnchorEl}
                        open={Boolean(moreAnchorEl) && moreId === row.id}
                        onClose={onCloseMoreMenu}
                      >
                        {inviteId && (
                          <MenuItem
                            onClick={() => {
                              onResendInvite(inviteId);
                              onCloseMoreMenu();
                            }}
                          >
                            <ListItemText primary="Resend Invite" />
                          </MenuItem>
                        )}
                        <MenuItem
                          disabled={row.working || !canWrite}
                          onClick={() => {
                            onRemoveTeamMember(row);
                            onCloseMoreMenu();
                          }}
                        >
                          <ListItemText primary={removeText} />
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={inviteDialogOpen} onClose={onCloseInviteDialog}>
          <DialogTitle>
            <PersonAddIcon sx={{ marginRight: '5px' }} />
            Invite a teammate
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Teammates start with <strong>Member</strong> access. Access can be updated once the invite is created.
            </DialogContentText>
            <TextField
              sx={{
                marginTop: theme => theme.spacing(3),
              }}
              value={email}
              onChange={onChangeEmail}
              disabled={working}
              id="email"
              type="email"
              label="Email Address"
              autoFocus
              autoCorrect="off"
              autoComplete="off"
              autoCapitalize="off"
              fullWidth
              error={hasEmailError}
              helperText={emailError}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseInviteDialog} variant="outlined" color="inherit">
              Cancel
            </Button>
            <Button
              onClick={onSendInvite}
              variant="contained"
              disabled={working || hasEmailError || isEmptyString(email)}
            >
              Invite
            </Button>
          </DialogActions>
        </Dialog>
      </NarrowColumn>
    </Column>
  );
};

export default TeammatesView;

import { Flex } from '@caravel/components/src';
import { GREY_PALETTE } from '@caravel/utils';
import { Button, Card, CardContent, SvgIcon, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

export const NewSegmentKindCard = observer(
  (props: { kind: string; description: string; icon: any; onSelect: () => void }) => {
    const { kind, description, icon, onSelect } = props;
    const theme = useTheme();

    return (
      <Card
        sx={{
          border: '2px solid',
          borderColor: GREY_PALETTE[2],
          width: '49%',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              padding: theme.spacing(3),
            }}
          >
            <SvgIcon component={icon} sx={{ fill: 'none', width: '24px', height: '24px' }} />
          </Flex>

          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              height: '50%',
              marginTop: theme.spacing(2),
            }}
          >
            <Typography marginBottom={theme.spacing(1)} align="center" variant="headline3">
              {kind}
            </Typography>
            <Typography align="center" height={'48px'} color={GREY_PALETTE[5]} variant="bodySmall">
              {description}
            </Typography>
            <Button
              sx={{
                margin: theme.spacing(3),
                fontWeight: '500',
              }}
              variant="contained"
              onClick={onSelect}
            >
              Select
            </Button>
          </Flex>
        </CardContent>
      </Card>
    );
  },
);

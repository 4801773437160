import { Column, Row } from '@caravel/components';
import { Button, Typography } from '@mui/material';
import { TroveFormTabs } from 'components/sources/trove/trove-form';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

export interface TroveTabFooterProps {
  tab: TroveFormTabs;
  disabled?: boolean;
  buttonText?: string;
  onSubmit: () => void;
}

export const TroveTabFooter = observer((props: TroveTabFooterProps) => {
  const { tab, buttonText = 'Next', disabled = false, onSubmit } = props;
  const store = useStore();
  const tabErrors = store.ui.troveForm.tabErrors.get(tab) || [];
  return (
    <Row sx={{ margin: theme => theme.spacing(4, 0) }}>
      <Column sx={{ flex: 1 }}>
        {tabErrors.map((msg, i) => (
          <Typography key={`${msg.slice(0, 8)}-${i}`} variant="caption" color="secondary">
            {msg}
          </Typography>
        ))}
      </Column>
      <Button disabled={disabled} variant="contained" onClick={onSubmit}>
        {buttonText}
      </Button>
    </Row>
  );
});

import { Logger, useAppReadyCheck } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

import { NewSurvey } from '../surveys/new-survey';
import { SurveyDetail } from '../surveys/survey-detail';
import { SurveysList } from '../surveys/surveys-list';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('feedback-routes');

export const FeedbackRoutes: FC = observer(() => {
  const store = useStore();

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> rendering feedback`);

  return (
    <Switch>
      <Route path="/feedback/new" component={NewSurvey} />
      <Route path="/feedback/:surveyId" component={SurveyDetail} />
      <Route path="/feedback" component={SurveysList} />
    </Switch>
  );
});

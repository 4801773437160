import { DateRange, DateWindows } from '@caravel/types';
import { getDefinedRanges, getWindowRange } from '@caravel/utils';
import { MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

export interface DateWindowSelectProps {
  window: DateWindows;
  windows: DateWindows[];
  onChange: (window: DateWindows, range: Required<DateRange>) => void;
}

export const DateWindowSelect = observer((props: DateWindowSelectProps) => {
  const { window, windows, onChange } = props;
  const dateRanges = getDefinedRanges(new Date());
  return (
    <Select
      size="small"
      sx={{
        height: '32px',
        fontSize: '14px',
      }}
      value={window}
      onChange={e => {
        const dateWindow = e.target.value as DateWindows;
        const dateRange = getWindowRange(dateWindow, dateRanges)!;
        onChange(dateWindow, dateRange);
      }}
    >
      {dateRanges
        .filter(range => windows.includes(range.id))
        .map(range => (
          <MenuItem key={range.id} value={range.id}>
            {range.label}
          </MenuItem>
        ))}
    </Select>
  );
});

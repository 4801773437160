import { TeamMemberDocument } from '@caravel/types';
import { GREY_PALETTE, NEUTRALS, WARM_SHADES_PALETTE } from '@caravel/utils';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Checkbox,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { format, formatDistance } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { Flex, Row } from '.';

export const InfoIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99984 1.16675C3.77984 1.16675 1.1665 3.78008 1.1665 7.00008C1.1665 10.2201 3.77984 12.8334 6.99984 12.8334C10.2198 12.8334 12.8332 10.2201 12.8332 7.00008C12.8332 3.78008 10.2198 1.16675 6.99984 1.16675ZM7.69986 9.91675H6.29987V6.30011H7.69986V9.91675ZM7.69986 5.60011H6.29987V4.20011H7.69986V5.60011Z"
      fill="#696969"
    />
  </svg>
);

/** */
export interface SortableTableHeadProps {
  cells: {
    id: string;
    label: string;
    disablePadding?: boolean;
    padding?: string;
    minWidth?: string;
    disabledSort?: boolean;
    width?: string;
    maxWidth?: string;
    info?: {
      title: string;
      url?: string;
      alt?: string;
    };
  }[];
  order: 'asc' | 'desc';
  orderBy: string;
  checkbox?: boolean;
  numSelected?: number;
  rowCount?: number;
  cellWidth?: string;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSort: (id: string) => void;
}

export const SortableTableHead = (props: SortableTableHeadProps) => {
  const { cells, order, orderBy, checkbox, numSelected = 0, rowCount = 0, onSelectAllClick, onSort } = props;
  return (
    <TableHead sx={{ backgroundColor: GREY_PALETTE[1] }}>
      <TableRow>
        {checkbox && (
          <TableCell sx={{ padding: '0 0 0 18px', width: '20px' }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              size="small"
              inputProps={{
                'aria-label': 'Select all',
              }}
            />
          </TableCell>
        )}
        {cells.map((cell, i) => (
          <TableCell
            key={`${i}-${cell.id}`}
            sortDirection={orderBy === cell.id ? order : false}
            {...(cell.disablePadding ? { padding: 'none' } : {})}
            sx={{
              padding: cell.padding ? cell.padding : cell.disablePadding ? 0 : i === 0 ? '20px 30px' : '20px 16px',
              '& .MuiTableSortLabel-icon': {
                margin: 0,
              },
              whiteSpace: 'nowrap',
              maxWidth: cell.maxWidth ?? 'auto',
              minWidth: cell.minWidth ?? 'auto',
              width: cell.width ?? 'auto',
            }}
          >
            <TableSortLabel
              disabled={cell.disabledSort}
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={() => onSort(cell.id)}
            >
              <Typography variant="headline4" sx={{ color: GREY_PALETTE[5] }}>
                {cell.label}
              </Typography>
              {cell.info && (
                <Tooltip data-html2canvas-ignore title={cell.info.title}>
                  <IconButton
                    size="small"
                    sx={{
                      padding: 0,
                      marginLeft: '5px',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
              {orderBy === cell.id ? (
                <span
                  style={{
                    // visually hide this but keep rendering for screen readers
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface SortableTableHeadV2Props {
  cells: {
    id: string;
    label: string;
    disablePadding?: boolean;
    padding?: string;
    minWidth?: string;
    disabledSort?: boolean;
    width?: string;
    maxWidth?: string;
  }[];
  order: 'asc' | 'desc';
  orderBy: string;
  cellWidth?: string;
  onSort: (id: string) => void;
}

export const SortableTableHeadV2 = (props: SortableTableHeadV2Props) => {
  const { cells, order, orderBy, onSort } = props;
  return (
    <TableHead>
      <TableRow>
        {cells.map((cell, i) => (
          <TableCell
            key={`${i}-${cell.id}`}
            sortDirection={orderBy === cell.id ? order : false}
            {...(cell.disablePadding ? { padding: 'none' } : {})}
            sx={{
              borderBottomColor: NEUTRALS.JASMINE,
              '& .MuiTableSortLabel-icon': {
                fontSize: '12px',
                margin: 0,
              },
              lineHeight: 1,
              maxWidth: cell.maxWidth ?? 'auto',
              minWidth: cell.minWidth ?? 'auto',
              padding: cell.padding ? cell.padding : cell.disablePadding ? 0 : i === 0 ? '7px 16px 7px 0' : '7px 16px',
              whiteSpace: 'nowrap',
              width: cell.width ?? 'auto',
            }}
          >
            <TableSortLabel
              disabled={cell.disabledSort}
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={() => onSort(cell.id)}
            >
              <Typography variant="bodySmallest" color={NEUTRALS.OOLONG}>
                {cell.label}
              </Typography>
              {orderBy === cell.id ? (
                <span
                  style={{
                    // visually hide this but keep rendering for screen readers
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

/** */
export const TableSectionHeader = ({ children, colSpan }: { children?: any; colSpan: number }) => (
  <TableRow sx={{ backgroundColor: WARM_SHADES_PALETTE[1] }}>
    <TableCell colSpan={colSpan} sx={{ padding: '10px 30px', borderBottom: 0 }}>
      <Typography
        variant="headline4"
        sx={{
          color: WARM_SHADES_PALETTE[3],
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {children}
      </Typography>
    </TableCell>
  </TableRow>
);

/** */

export interface TableCellData {
  value: React.ReactNode;
  onClick?: () => void;
}

export interface TableMenuItem {
  label: string;
  labelColor?: string;
  icon?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  dividerBelow?: boolean;
}

export interface TableDatumProps {
  row: string | number;
  icon: React.ReactNode;
  data: TableCellData[];
  date?: Date;
  updatedAt?: Date;
  createdBy?: TeamMemberDocument;
  menuItems?: (TableMenuItem | undefined)[];
  superMenuItems?: TableMenuItem[];
}

export const TableDatum = (props: TableDatumProps) => {
  const { row, icon, data, date, updatedAt, createdBy, menuItems, superMenuItems } = props;
  const renderMenu = (menuItems && menuItems.length > 0) || (superMenuItems && superMenuItems.length > 0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      {data.map((datum, i) => {
        const key = `${row}-${i}`;
        const isFirst = i === 0;
        return (
          <TableCell
            key={key}
            sx={{
              ...(isFirst ? { padding: '15px 30px', margin: 0 } : {}),
            }}
          >
            {!isFirst && datum.value}
            {isFirst && (
              <Row alignItems="center" color={NEUTRALS.ESPRESSO}>
                {icon && (
                  <Flex
                    sx={{
                      boxSizing: 'border-box',
                      marginRight: '10px',
                      width: 36,
                      height: 36,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {icon}
                  </Flex>
                )}
                {datum.value}
              </Row>
            )}
          </TableCell>
        );
      })}

      {date && (
        <TableCell>
          <Typography variant="headline4" sx={{ color: GREY_PALETTE[5] }}>
            {format(new Date(date), 'eee' + ', ' + 'MMM' + ' ' + 'd')}
          </Typography>
        </TableCell>
      )}

      {updatedAt && (
        <TableCell>
          <Typography variant="headline4" sx={{ color: GREY_PALETTE[6] }}>
            {formatDistance(new Date(updatedAt), new Date()) + ' ago'}
          </Typography>
        </TableCell>
      )}

      {(createdBy || renderMenu) && (
        <TableCell>
          <Row alignItems="center">
            {createdBy && (
              <Avatar
                src={createdBy.photoUrl}
                alt={createdBy.name}
                sx={{
                  height: 30,
                  width: 30,
                }}
              >
                {createdBy.name.charAt(0)}
              </Avatar>
            )}
            <Flex flex={1} />
            {renderMenu && (
              <>
                <IconButton onClick={handleClickActions}>
                  <MoreVertIcon sx={{ color: GREY_PALETTE[3] }} />
                </IconButton>
                <Menu
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseActions}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    sx: {
                      minWidth: '200px',
                    },
                  }}
                >
                  {menuItems && menuItems.length > 0 && (
                    <MenuList>
                      {menuItems.map(item => {
                        return (
                          item && (
                            <div key={item.label}>
                              <MenuItem
                                disabled={item.disabled}
                                onClick={() => {
                                  handleCloseActions();
                                  item.onClick();
                                }}
                              >
                                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                <ListItemText sx={{ color: item.labelColor }} primary={item.label} />
                              </MenuItem>
                              {item.dividerBelow ? <Divider /> : ''}
                            </div>
                          )
                        );
                      })}
                    </MenuList>
                  )}
                  {superMenuItems && superMenuItems.length > 0 && (
                    <MenuList subheader={<ListSubheader>Super Actions</ListSubheader>}>
                      {superMenuItems.map(item => {
                        return (
                          item && (
                            <div key={item.label}>
                              <MenuItem
                                disabled={item.disabled}
                                onClick={() => {
                                  handleCloseActions();
                                  item.onClick();
                                }}
                              >
                                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                <ListItemText sx={{ color: item.labelColor }} primary={item.label} />
                              </MenuItem>
                              {item.dividerBelow ? <Divider /> : ''}
                            </div>
                          )
                        );
                      })}
                    </MenuList>
                  )}
                </Menu>
              </>
            )}
          </Row>
        </TableCell>
      )}
    </TableRow>
  );
};

export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Flex sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Flex>
  );
}

export interface RowActionMenuProps {
  id: string;
  options: React.ReactNode[];
  canOpen: boolean;
}

export const RowActionMenu = (props: RowActionMenuProps) => {
  const { id, options, canOpen } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl && canOpen);

  useEffect(() => {
    if (!open) {
      setAnchorEl(null);
    }
  }, [open]);

  const clickContextMenuIcon = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={clickContextMenuIcon}>
        <MoreVertIcon sx={{ color: GREY_PALETTE[3] }} />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={id}
        MenuListProps={{
          sx: {
            '& .MuiButtonBase-root:hover': {
              background: GREY_PALETTE[2],
            },
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            border: 'none',
            background: '#FFFFFF',
            boxShadow:
              '0px 4px 14px -10px rgba(0, 0, 0, 0.07), 0px 10px 18px rgba(0, 0, 0, 0.04), 0px 30px 42px rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
          },
        }}
      >
        {options.map(option => option)}
      </Menu>
    </>
  );
};

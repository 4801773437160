import { Column } from '@caravel/components/src';
import { Grid } from '@mui/material';
import { useDualPanels } from 'helpers';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from 'stores';

import { ReactComponent as AddToSegment } from '../../../assets/svgs/segment-add-to.svg';
import { AppToolbar } from '../../app-toolbar';
import { AddToSegmentPopoverMenu } from '../../segments/add-to-segment-popover-menu';
import { PersonActivity } from './person-activity';
import { PersonDetails } from './person-details';
import { PersonStats } from './person-stats';

export const PersonDetail = observer(() => {
  const store = useStore();
  const history = useHistory();
  const previousPage = history.location.state as { from: string } | undefined;
  const { containerStyle, panelStyle } = useDualPanels();
  const { personId } = useParams<{ personId: string }>();

  const inMemoryPerson = store.teams.people.get(personId);
  const person = store.teams.people.person ?? inMemoryPerson;
  const canLoad = store.team && !store.teams.people.person && !store.teams.people.personLoading;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    return () => {
      store.teams.people.resetPerson();
      store.teams.people.resetActivities();
      store.teams.people.resetActivityCounts();
    };
  }, [store.teams.people]);

  useEffect(() => {
    if (!canLoad) return;
    store.ui.workOn(async () => {
      await store.teams.people.fetchPerson(personId);
    });
  }, [canLoad, personId, store.teams.people, store.ui]);

  if (!person) return null;

  return (
    <Column sx={containerStyle}>
      <AppToolbar
        title="People"
        subtitle={person.name}
        backButton
        actionButton={{
          startIcon: <AddToSegment />,
          text: 'Add to Static Segment',
          onClick: e => setAnchorEl(e.currentTarget),
        }}
      />
      <Grid container sx={containerStyle}>
        <Grid item xs={12} md={6} lg={5} xl={4} sx={panelStyle}>
          <PersonDetails person={person} previousPage={previousPage?.from ?? undefined} />
        </Grid>
        <Grid item xs={12} md={6} lg={7} xl={8} sx={panelStyle}>
          <PersonStats person={person} />
          <PersonActivity person={person} />
        </Grid>
      </Grid>
      <AddToSegmentPopoverMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        people={[person]}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Column>
  );
});

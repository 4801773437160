import { Condition, ConditionSelectOption } from '@caravel/types';
import isEqual from 'lodash/isEqual';
import take from 'lodash/take';
import takeRight from 'lodash/takeRight';

/**
 * Checks whether the given arrays of objects are equivalent
 * @param a A list of objects
 * @param b A list of objects to compare against
 * @returns Whether the arrays are equivalent
 */
export function arraysAreEqual(a?: Record<string, any>[], b?: Record<string, any>[]): boolean {
  if (!a || !b) return false;
  if (a.length !== b.length) {
    return false;
  }
  return a.map((item, i) => isEqual(item, b[i])).filter(equal => !equal).length === 0;
}

/**
 * Checks whether the two arrays of conditions are equal
 * @param a The source list of conditions
 * @param b The list of conditions to compare against
 * @returns Whether the lists are equal
 */
export function conditionsAreEqual(
  a: Condition<ConditionSelectOption>[],
  b: Condition<ConditionSelectOption>[],
): boolean {
  if (a.length !== b.length) {
    return false;
  }
  return (
    a
      .map((item, i) => {
        const shallow = isEqual(item, b[i]);
        const value = isEqual(item.value, b[i].value);
        return shallow && value;
      })
      .filter(equal => !equal).length === 0
  );
}

/**
 * Collapses an array to its 0, -1, -2 index items; useful for previewing text content.
 * @param arr A list to collapse (unmodified)
 * @returns A tuple containing a slice of the input array and whether the array was collapsed
 */
export function collapseToPreview<T>(arr: T[]): [T[], boolean] {
  if (arr.length > 3) {
    return [[...take(arr, 1), ...takeRight(arr, 2)], true];
  }
  return [arr.slice(), false];
}

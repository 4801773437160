import { Row } from '@caravel/components/src';
import { Box, Divider, Link } from '@mui/material';
import { Checkbox, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, UIEventHandler } from 'react';

import { FilterMenuItem } from './filter-menu-item';
import { FilterProps } from './filter-props';
import { MultiFilterMenu } from './multi-filter-menu';

export interface FilterPropsWithScroll extends FilterProps {
  handleScroll: UIEventHandler<HTMLDivElement>;
}

export const SearchMultiFilterMenu = observer((props: PropsWithChildren<FilterPropsWithScroll>) => {
  const selectAllConditions = ['any-of', 'not-any-of'];
  return (
    <>
      {selectAllConditions.includes(props.condition!) && (
        <>
          <Row sx={{ alignItems: 'center', width: '100%', justifyContent: 'space-between', padding: '4px 10px' }}>
            <Typography variant="bodySmaller">{props.value.length} Selected</Typography>
            {props.value.length > 1 && (
              <Link
                component="button"
                variant="bodySmaller"
                onClick={() => {
                  const newValues: string[] = [];
                  props.onChangeFilter({ value: newValues, condition: props.condition }, true);
                }}
              >
                Clear
              </Link>
            )}
          </Row>
        </>
      )}
      {props.value.length > 0 && <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />}
      {props.value?.map((value, i) => (
        <FilterMenuItem
          key={i}
          sx={{
            minWidth: '250px',
            paddingLeft: 0,
            '&.Mui-focusVisible': {
              backgroundColor: 'unset',
            },
          }}
          onClick={() => {
            props.onChangeFilter(
              {
                condition: props.condition,
                value: props.value.filter(v => v !== value),
              },
              true,
            );
          }}
        >
          <Checkbox
            checked={true}
            size="small"
            sx={{
              width: '14px',
              heigth: '14px',
              padding: 0,
              margin: '0 8px',
              '& .MuiSvgIcon-root': {
                width: '14px',
                heigth: '14px',
              },
            }}
          />
          <Typography variant="bodySmaller">{value}</Typography>
        </FilterMenuItem>
      ))}
      <Divider />
      <Box onScroll={props.handleScroll} sx={{ maxHeight: '300px', overflow: 'auto' }}>
        <MultiFilterMenu {...props} />
      </Box>
    </>
  );
});

// Get the display name of a user
export const getPersonDisplayName = (givenName: string | undefined, familyName: string | undefined, email: string) => {
  if (!givenName || !familyName) {
    return email;
  }
  return `${givenName} ${familyName}`;
};

// Truncate text
export const truncateText = (text: string, limit: number) => {
  if (text.length > limit) {
    return text.slice(0, limit).concat('...');
  }
  return text;
};

export const getInitials = (firstName: string | undefined, lastName: string | undefined) => {
  const firstInitial = firstName?.charAt(0).toLocaleUpperCase() ?? 'U';
  const lastInitial = lastName?.charAt(0).toLocaleUpperCase() ?? 'U';
  return `${firstInitial}${lastInitial}`;
};

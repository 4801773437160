import { Flex } from '@caravel/components/src';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';

export interface BaseModalProps {
  open: boolean;
  icon?: JSX.Element;
  title: string;
  subtitle?: string;
  children?: any;
  data?: any;
  onClose: () => void;
}

export function BaseModal(props: BaseModalProps) {
  const theme = useTheme();
  const { open, icon, title, subtitle, children, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: 480,
          width: '100%',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingRight: theme.spacing(2),
        }}
      >
        {icon}
        <Typography
          component="h2"
          variant="h6"
          sx={{
            flex: 1,
            padding: theme.spacing(0, 1),
          }}
        >
          {title}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {subtitle && (
          <Typography
            component="strong"
            sx={{
              fontWeight: 600,
              fontSize: 15,
            }}
          >
            {subtitle}
          </Typography>
        )}
        <Flex sx={{ padding: theme.spacing(1, 0), flexDirection: 'column' }}>{children}</Flex>
      </DialogContent>
    </Dialog>
  );
}

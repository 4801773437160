import { when } from 'mobx';
import { useEffect } from 'react';
import { RootStore } from 'stores/root';

export const useLoadTeam = (store: RootStore, workName: string) => {
  useEffect(() => {
    const load = async () => {
      const workId = store.ui.startWork(workName);
      await when(() => store.members.current !== undefined);

      if (!store.teams.currentTeam && store.members.current?.lastTeamId) {
        await store.teams.loadTeam(store.members.current.lastTeamId);
      }

      await when(() => store.teams.currentTeam !== undefined);
      store.ui.stopWork(workId);
    };

    load();
  }, [store.members, store.teams, store.ui, workName]);
};

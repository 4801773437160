import { CaravelSpinner, Column, Flex, NoResults, NumberChip, SortableTableHead } from '@caravel/components/src';
import { abbreviateDollarAmount } from '@caravel/utils/src/strings';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { formatDistance } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-cool-inview';
import { useStore } from 'stores';

import { AppToolbar } from '../app-toolbar';
import { OrganizationsFilters } from './organizations-filters';

export const OrganizationsList = observer(() => {
  const store = useStore();
  const {
    ui,
    teams: { organizations },
  } = store;

  const canLoad =
    store.team && (!organizations.pageInfo || organizations.pageInfo?.hasNextPage) && !organizations.loading;
  const collection = organizations.collection.slice();
  const noOrganizations =
    organizations.loaded && organizations.organizationsCount === 0 && organizations.totalHits === 0;
  const handleRequestSort = (id: string) => {
    organizations.onChangeOrder(id);
    loadMore();
  };

  const loadMore = useCallback(() => {
    if (!canLoad) return;
    ui.workOn(async () => {
      await organizations.fetchOrganizations();
    });
  }, [canLoad, organizations, ui]);

  const { observe } = useInView({
    onEnter: () => {
      loadMore();
    },
  });

  useEffect(() => {
    if (!collection.length) {
      loadMore();
    }
  }, [collection.length, loadMore]);

  return (
    <Column sx={{ height: '100%' }}>
      <AppToolbar
        title="Organizations"
        subtitle={
          !organizations.loading
            ? `${
                organizations.totalHits !== organizations.organizationsCount
                  ? `${organizations.totalHits.toLocaleString()} of `
                  : ''
              }${organizations.organizationsCount.toLocaleString()}`
            : ''
        }
      />
      {!noOrganizations ? (
        <Column>
          <OrganizationsFilters />
          <TableContainer>
            <Table size="small">
              <SortableTableHead
                cells={[
                  { id: 'name', label: 'Organization', disablePadding: false },
                  { id: 'memberCount', label: 'Members' },
                  { id: 'employeesRange', label: 'Employees' },
                  { id: 'location', label: 'Location' },
                  { id: 'annualRevenue', label: 'Annual revenue' },
                  { id: 'foundedYear', label: 'Founded' },
                  { id: 'firstActive', label: 'First active' },
                ]}
                order={organizations.order}
                orderBy={organizations.orderBy}
                onSort={handleRequestSort}
                // numSelected={organizations.numSelected}
                rowCount={organizations.collection.length}
                // onSelectAllClick={() => {
                //   organizations.toggleAllSelected();
                // }}
              />
              <TableBody>
                {collection.map(row => (
                  <TableRow key={row.id} sx={{ minHeight: '50px', height: '100%' }}>
                    <TableCell
                      padding="none"
                      sx={{
                        tableLayout: 'fixed',
                        padding: '10px 0 10px 30px',
                      }}
                    >
                      <RouterLink
                        to={`/organizations/${row.id}`}
                        color="textPrimary"
                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Avatar
                          src={`https://logo.clearbit.com/${row.domain}`}
                          alt={row.name}
                          sx={{ width: 30, height: 30, marginRight: '10px', fontSize: '12px' }}
                        />
                        <Typography variant="headline4">{row.name}</Typography>
                      </RouterLink>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headline4">
                        <NumberChip
                          value={row.memberCount.toLocaleString()}
                          jasmine
                          sx={{
                            fontWeight: '400',
                            width: 'fit-content',
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headline4">{row.employeesRange}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headline4">{row.location}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headline4">{abbreviateDollarAmount(row.annualRevenue)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headline4">{row.foundedYear}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="headline4">
                        {formatDistance(new Date(row.firstActive ?? new Date()), new Date()) + ' ago'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Column sx={{ padding: '28px' }}>
            {!organizations.loading && <Column ref={observe} />}
            {(organizations.loading || organizations.pageInfo?.hasNextPage) && <CaravelSpinner />}
            <Flex flex={1} sx={{ paddingTop: '20px', width: '100%' }} />
          </Column>
        </Column>
      ) : (
        <NoResults title="No Organizations found" message="Connect your data to get insights" />
      )}
    </Column>
  );
});

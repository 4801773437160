import 'react-day-picker/dist/style.css';

import { DateRange, DefinedRange } from '@caravel/types';
import { getDefinedRanges, getLabelFromRange, GREY_PALETTE, isSameRange, PRIMARY_PALETTE, WHITE } from '@caravel/utils';
import CalendarIcon from '@mui/icons-material/DateRange';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  SxProps,
  Tooltip,
} from '@mui/material';
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker';
import { YearPicker } from '@mui/x-date-pickers/YearPicker';
import chroma from 'chroma-js';
import { addMonths, addYears, format, isAfter, isBefore } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { addToRange, DayPicker, useNavigation } from 'react-day-picker';

import { Column, Flex, Row } from '..';

const MIN_DATE = addYears(new Date(), -20);
const MAX_DATE = addYears(new Date(), 1);

export interface MonthSelectProps {
  minDate: Date;
  maxDate: Date;
  month: Date;
  onChange: (date: Date | null) => void;
}

export function MonthSelect(props: MonthSelectProps) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const month = format(props.month, 'MMM');
  return (
    <>
      <Button onClick={e => setAnchor(e.currentTarget)}>{month}</Button>
      <Menu
        open={open}
        onClose={() => setAnchor(null)}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MonthPicker
          minDate={props.minDate}
          maxDate={props.maxDate}
          date={props.month}
          onChange={d => {
            setAnchor(null);
            props.onChange(d);
          }}
          sx={{
            '& .Mui-selected': {
              backgroundColor: `${PRIMARY_PALETTE[200]} !important`,
              '&:hover': {
                backgroundColor: `${PRIMARY_PALETTE[300]} !important`,
              },
            },
          }}
        />
      </Menu>
    </>
  );
}

export interface YearSelectProps {
  minDate: Date;
  maxDate: Date;
  year: Date;
  onChange: (date: Date | null) => void;
}

export function YearSelect(props: YearSelectProps) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const year = format(props.year, 'yyyy');
  return (
    <>
      <Button onClick={e => setAnchor(e.currentTarget)}>{year}</Button>
      <Menu
        open={open}
        onClose={() => setAnchor(null)}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            maxWidth: '300px',
          }}
        >
          <YearPicker
            minDate={props.minDate}
            maxDate={props.maxDate}
            date={props.year}
            onChange={d => {
              setAnchor(null);
              props.onChange(d);
            }}
          />
        </Flex>
      </Menu>
    </>
  );
}

export function Arrow(props: { direction: 'left' | 'right' }) {
  const { direction } = props;
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: direction === 'right' ? 'rotate(180deg)' : 'unset',
      }}
    >
      <path d="M21 14H7" stroke="#6246EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 21L7 14L14 7" stroke="#6246EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

interface DaySelectProps {
  side: 'startDate' | 'endDate';
  minDate: Date;
  maxDate: Date;
  dateRange: DateRange;
  onChange: (dateRange: DateRange) => void;
  onReset: () => void;
  calendar?: Date;
  onChangeCalendar: (calendar: Date | undefined) => void;
}

function DaySelect(props: DaySelectProps) {
  const { side, minDate, maxDate, dateRange, onChange, onReset, calendar, onChangeCalendar } = props;
  const { startDate: from, endDate: to } = dateRange;
  const { nextMonth, previousMonth, goToMonth } = useNavigation();

  const handleChangeCalendar = (d: Date | null) => {
    if (!d) {
      onChangeCalendar(dateRange[side]);
      return;
    }
    if (isAfter(d, maxDate)) {
      onChangeCalendar(maxDate);
    } else if (isBefore(d, minDate)) {
      onChangeCalendar(minDate);
    } else {
      onChangeCalendar(d);
    }
  };

  return (
    <Row
      sx={{
        alignItems: 'center',
        '& .caravel-date-range-picker': {
          '& .DayPicker-Month': {
            borderCollapse: 'separate',
            borderSpacing: '0 10px',
            width: '100%',
            maxWidth: `${32 * 7}px`,
            margin: '1em 25px',
          },

          '& .DayPicker-Week': {},
          '& .DayPicker-wrapper': { padding: 0 },

          '& .DayPicker-Day': {
            borderRadius: '0',
            height: '32px',
            width: '32px',
            overflow: 'hidden',
            fontSize: '14px',
            margin: 0,
            padding: 0,
            position: 'relative',
            zIndex: 1,
          },

          '& .DayPicker-Day--today': {
            fontWeight: 'unset',
          },

          '& .DayPicker-Day--start:not(.DayPicker-Day--outside)': {
            borderTopLeftRadius: '16px',
            borderBottomLeftRadius: '16px',

            '&::after': {
              content: `""`,
              backgroundColor: PRIMARY_PALETTE[200],
              borderRadius: '50%',
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
            },
          },

          '& .DayPicker-Day--end:not(.DayPicker-Day--outside)': {
            borderTopRightRadius: '16px',
            borderBottomRightRadius: '16px',

            '&::after': {
              content: `""`,
              backgroundColor: PRIMARY_PALETTE[200],
              borderRadius: '50%',
              position: 'absolute',
              height: '100%',
              width: '100%',
              margin: '0',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
            },
          },

          '& .DayPicker-Day--outside': {
            color: GREY_PALETTE[3],
          },

          '& .DayPicker-Week :not(.DayPicker-Day--outside).DayPicker-Day--selected:nth-of-type(1)': {
            borderTopLeftRadius: '16px',
            borderBottomLeftRadius: '16px',
          },
          '& .DayPicker-Week :not(.DayPicker-Day--outside).DayPicker-Day--selected:last-child': {
            borderTopRightRadius: '16px',
            borderBottomRightRadius: '16px',
          },

          '& .DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
            '&:not(.DayPicker-Day--start):not(.DayPicker-Day--end)': {
              backgroundColor: PRIMARY_PALETTE[100],
              color: GREY_PALETTE[6],
              '&:hover': {
                backgroundColor: GREY_PALETTE[2],
              },
            },
            '&.DayPicker-Day--start, &.DayPicker-Day--end': {
              backgroundColor: PRIMARY_PALETTE[100],
              color: WHITE,
              '&:hover': {
                backgroundColor: GREY_PALETTE[2],
              },
            },
          },

          '& .DayPicker-Day:not(.DayPicker-Day--selected):hover': {
            backgroundColor: `${GREY_PALETTE[2]} !important`,
          },
        },
      }}
    >
      <DayPicker
        className="caravel-date-range-picker"
        numberOfMonths={1}
        mode="range"
        selected={{ from: dateRange[side], to: dateRange[side] }}
        fromDate={from}
        toDate={to}
        month={calendar}
        fromMonth={minDate}
        toMonth={maxDate}
        showOutsideDays
        fixedWeeks
        onDayClick={day => {
          const range = addToRange(day, { from, to });
          const hasRange = range && (range.from || range.to);
          if (hasRange) {
            onChange({ startDate: range.from ?? undefined, endDate: range.to ?? undefined });
          } else {
            onReset();
          }
        }}
        components={{
          Caption: props => {
            return (
              <div className="DayPicker-Caption">
                <Row
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <MonthSelect
                    minDate={minDate}
                    maxDate={maxDate}
                    month={props.displayMonth}
                    onChange={handleChangeCalendar}
                  />
                  <YearSelect
                    minDate={minDate}
                    maxDate={maxDate}
                    year={props.displayMonth}
                    onChange={handleChangeCalendar}
                  />
                </Row>
                <Row
                  className="DayPicker-NavBar"
                  sx={{
                    justifyContent: 'space-between',
                    marginBottom: '-48px',
                    padding: '0 20px',
                  }}
                >
                  {previousMonth ? (
                    <IconButton onClick={() => goToMonth(addMonths(props.displayMonth, -1))}>
                      <Arrow direction="left" />
                    </IconButton>
                  ) : (
                    <Flex flex={1} />
                  )}
                  {nextMonth && (
                    <IconButton onClick={() => goToMonth(addMonths(props.displayMonth, 1))}>
                      <Arrow direction="right" />
                    </IconButton>
                  )}
                </Row>
              </div>
            );
          },
        }}
      />
    </Row>
  );
}

export interface DateRangeSelectProps {
  disabled?: boolean;
  dateRange: DateRange;
  onChangeDateRange: (dateRange: DateRange) => void;
  includeAll?: boolean;
  sx?: SxProps;
}

export const DateRangeSelect = observer((props: DateRangeSelectProps) => {
  const { disabled, dateRange, includeAll, onChangeDateRange, sx } = props;
  const { startDate: from, endDate: to } = dateRange;
  const definedRanges = [
    ...(includeAll
      ? [
          {
            id: 'all',
            label: 'All',
            startDate: null,
            endDate: null,
            // hack to allow DateRangePicker to 'deselect' as an option
          } as unknown as DefinedRange,
        ]
      : []),
    ...getDefinedRanges(new Date()),
  ];
  const [open, setOpen] = useState(false);
  const [startCalendar, setStartCalendar] = useState<undefined | Date>(from);
  const [endCalendar, setEndCalendar] = useState<undefined | Date>(to);
  const label = getLabelFromRange(dateRange);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDateRange = (range: DateRange) => {
    setStartCalendar(range.startDate);
    setEndCalendar(range.endDate);
    onChangeDateRange(range);
  };

  const handleResetDateRange = () => {
    const initialRange = definedRanges.find(r => r.id === 'last30') ?? definedRanges[0];
    onChangeDateRange(initialRange);
  };

  const handleChangeStartCalendar = (date?: Date) => {
    setStartCalendar(date ?? from);
  };

  const handleChangeEndCalendar = (date?: Date) => {
    setEndCalendar(date ?? to);
  };

  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        startIcon={<CalendarIcon fontSize="small" />}
        onClick={handleOpen}
        sx={sx}
      >
        {label}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            padding: '20px',
            maxWidth: '848px',
          },
        }}
      >
        <DialogContent>
          <Row>
            <Column alignItems="center" sx={{ flex: 1 }}>
              <DialogContentText>
                {from && to && (
                  <Tooltip title="Reset to last 30 days">
                    <Button
                      className="link"
                      variant="contained"
                      color="inherit"
                      onClick={handleResetDateRange}
                      sx={{
                        marginBottom: '20px',
                      }}
                    >
                      {!from && !to && 'Select the first day'}
                      {from && !to && 'Select the last day'}
                      {from && to && `${format(from, 'MMMM do, yyyy')} - ${format(to, 'MMMM do, yyyy')}`}
                    </Button>
                  </Tooltip>
                )}
              </DialogContentText>
              <Row>
                <DaySelect
                  side="startDate"
                  dateRange={dateRange}
                  minDate={MIN_DATE}
                  maxDate={to ? addMonths(to, -1) : MAX_DATE}
                  onChange={handleChangeDateRange}
                  onReset={handleResetDateRange}
                  calendar={startCalendar}
                  onChangeCalendar={handleChangeStartCalendar}
                />
                <DaySelect
                  side="endDate"
                  dateRange={dateRange}
                  minDate={from ? addMonths(from, 1) : MIN_DATE}
                  maxDate={MAX_DATE}
                  onChange={handleChangeDateRange}
                  onReset={handleResetDateRange}
                  calendar={endCalendar}
                  onChangeCalendar={handleChangeEndCalendar}
                />
              </Row>
            </Column>
            <Column sx={{ borderLeft: `1px solid ${GREY_PALETTE[3]}` }}>
              <List sx={{ marginLeft: '20px' }}>
                {definedRanges.map(range => (
                  <ListItem
                    key={range.label}
                    button
                    onClick={() => handleChangeDateRange(range)}
                    sx={{
                      borderRadius: '5px',
                      backgroundColor: isSameRange(dateRange, range)
                        ? chroma(GREY_PALETTE[2]).alpha(0.5).css()
                        : undefined,
                      padding: '0 10px',
                      height: '38px',
                      margin: '1px 0',
                    }}
                  >
                    <ListItemText primaryTypographyProps={{ variant: 'bodySmall' }}>{range.label}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Column>
          </Row>
        </DialogContent>
      </Dialog>
    </>
  );
});

import { Column, Flex } from '@caravel/components';
import { CHARCOAL_BLACK } from '@caravel/utils';
import { Button, Card, Link, Typography } from '@mui/material';
import { Team } from 'models';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { OnboardingBackground } from '../onboarding-background';
import { OnboardingDivider, OnboardingDividerContainer, OnboardingDividerText } from '../onboarding-styled';

export interface TeamsViewProps {
  teams: Team[];
  onLoadTeam: (teamId: string) => void;
}

export function TeamsView({ teams, onLoadTeam }: TeamsViewProps) {
  const history = useHistory();

  const handleLoadTeam = (teamId: string) => () => {
    onLoadTeam(teamId);
  };

  return (
    <OnboardingBackground>
      <Card
        variant="elevation"
        elevation={0}
        sx={{ padding: '48px 24px 24px', borderRadius: '16px', width: '100%', maxWidth: '388px', overflowY: 'auto' }}
      >
        <Column gap="40px" alignItems="stretch">
          <Typography variant="headline1" component="h1" color={CHARCOAL_BLACK} align="center">
            Load an existing workspace
          </Typography>
          <Column gap="24px">
            {teams.length !== 0 && (
              <Column gap="24px" alignItems="stretch">
                {teams.map(t => (
                  <Card
                    variant="elevation"
                    elevation={3}
                    key={t.uid}
                    onClick={handleLoadTeam(t.id)}
                    sx={{
                      padding: '16px',
                    }}
                  >
                    <Flex justifyContent="space-between" alignItems="center" gap="10px">
                      <Typography variant="bodySmall">{t.displayName}</Typography>
                      <Link component="button" onClick={handleLoadTeam(t.id)} underline="always" variant="bodySmall">
                        Go to Dashboard
                      </Link>
                    </Flex>
                  </Card>
                ))}
                <OnboardingDividerContainer>
                  <OnboardingDivider />
                  <OnboardingDividerText>OR</OnboardingDividerText>
                  <OnboardingDivider />
                </OnboardingDividerContainer>
              </Column>
            )}
            <Column alignItems="stretch" gap="19px">
              <Button variant="outlined" color="primary" fullWidth size="large" href="/teams/new">
                Create a New Workspace
              </Button>
              <Typography variant="bodySmallest" textAlign="center">
                Not seeing your workspace?
                <br />
                <Link component="button" onClick={() => history.push('/sign-out')}>
                  Try a different email.
                </Link>
              </Typography>
            </Column>
          </Column>
        </Column>
      </Card>
    </OnboardingBackground>
  );
}

import { Row } from '@caravel/components/src';
import { SvgIcon, Typography } from '@mui/material';
import React from 'react';

import { CaseStudyIcon, LinkedInIcon, QuoteIcon, TwitterIcon, VideoIcon } from './source-icons';

export const SourceBadge = (props: { source: string }) => {
  const getIcon = (source: string) => {
    switch (source.toLocaleLowerCase()) {
      case 'linkedin':
        return <LinkedInIcon style={{ height: '20px', width: '20px' }} />;
      case 'textquote':
        return <QuoteIcon style={{ height: '20px', width: '20px' }} />;
      case 'video':
        return <VideoIcon style={{ height: '20px', width: '20px' }} />;
      case 'casestudy':
        return <CaseStudyIcon style={{ height: '20px', width: '20px' }} />;
      case 'twitter':
        return <TwitterIcon style={{ height: '20px', width: '20px' }} />;
      default:
        return <CaseStudyIcon style={{ height: '20px', width: '20px' }} />;
    }
  };

  const getDisplayText = (source: string) => {
    switch (source.toLocaleLowerCase()) {
      case 'linkedin':
        return 'LinkedIn';
      case 'textquote':
        return 'Quote';
      case 'video':
        return 'Video';
      case 'casestudy':
        return 'Case Study';
      case 'twitter':
        return 'Twitter';
      default:
        return 'Unknown Source';
    }
  };

  return (
    <Row>
      {getIcon(props.source)}
      <Typography variant="bodySmall" sx={{ ml: 0.5 }}>
        {getDisplayText(props.source)}
      </Typography>
    </Row>
  );
};

import { OrganizationDetail } from 'components/organizations/organization-detail';
import { Logger, useAppReadyCheck, useStoreMount } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

import { ChampionSimpleDetail } from '../champions/champion-simple-detail/champion-simple-detail';
import { ChampionsList } from '../champions/champions-list';
import { ReferencesList } from '../champions/references-list';
import { ProofLibraryList } from '../proof-library/proof-library-list';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('chamions-routes');

export const ChampionsRoutes: FC = observer(() => {
  const store = useStore();
  useStoreMount(store, [store.teams.champions, store.teams.proofLibrary]);

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> rendering champions`);

  return (
    <Switch>
      <Route path="/champions/:championId" component={ChampionSimpleDetail} />
      <Route path="/champions/organizations/:orgId" component={OrganizationDetail} />
      <Route path="/champions" component={ChampionsList} />
      <Route path="/references" component={ReferencesList} />
      <Route path="/proof-library" component={ProofLibraryList} />
    </Switch>
  );
});

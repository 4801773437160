import { GREY_PALETTE } from '@caravel/utils';
import { IconButton, SvgIcon } from '@mui/material';
import React from 'react';

const CloseIcon = () => (
  <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.464582 2.37868L2.5859 4.5L0.464582 6.62132L1.8788 8.03553L4.00012 5.91421L6.12144 8.03553L7.53565 6.62132L5.41433 4.5L7.53565 2.37868L6.12144 0.964466L4.00012 3.08579L1.8788 0.964466L0.464582 2.37868Z"
      fill="#8B99B5"
    />
  </svg>
);

export const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <IconButton
      size="small"
      sx={{
        backgroundColor: GREY_PALETTE[2],
        width: '20px',
        height: '20px',
      }}
      onClick={onClose}
    >
      <SvgIcon component={CloseIcon} sx={{ fill: GREY_PALETTE[5] }} />
    </IconButton>
  );
};

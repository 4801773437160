import { Button, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { observer } from 'mobx-react';
import { Toast } from 'models';
import React, { FC } from 'react';

import { FlexBox } from '../flex-box';

export interface AppSnackbarViewProps {
  opened: boolean;
  toasts: Toast[];
  onClose: () => void;
  onCloseToast: (toast: Toast) => void;
}

export const AppSnackbarView: FC<AppSnackbarViewProps> = observer(({ opened, toasts, onClose, onCloseToast }) => {
  const handleClose = (toast: Toast) => () => {
    onCloseToast(toast);
  };

  const handleClickAction = (toast: Toast) => () => {
    toast.actionHandler && toast.actionHandler();
    onCloseToast(toast);
  };

  return (
    <Snackbar
      open={opened}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <FlexBox flexDirection="column" justifyContent="flex-end" alignItems="flex-start">
        {toasts.map(toast => (
          <Alert
            key={toast.uid}
            severity={toast.severity}
            variant={toast.variant}
            onClose={handleClose(toast)}
            action={toast.actionText && <Button onClick={handleClickAction(toast)}>{toast.actionText}</Button>}
            sx={{
              marginTop: theme => theme.spacing(2),
            }}
          >
            {toast.message}
          </Alert>
        ))}
      </FlexBox>
    </Snackbar>
  );
});

export default AppSnackbarView;

import { ReactComponent as ExportPeople } from '../../assets/svgs/align-arrow-down.svg';
import { ReactComponent as AddToSegmentIcon } from '../../assets/svgs/apps.svg';
import { ReactComponent as MergeArrow } from '../../assets/svgs/Arrow 1.svg';
import { ReactComponent as BackArrow } from '../../assets/svgs/arrow-left.svg';
import { ReactComponent as DefaultOrganizationIcon } from '../../assets/svgs/building-transparent.svg';
import { ReactComponent as CalendarIcon } from '../../assets/svgs/calendar-small.svg';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-small.svg';
import { ReactComponent as CreateDynamicSegment } from '../../assets/svgs/create-dynamic-segment.svg';
import { ReactComponent as DoorExitIcon } from '../../assets/svgs/door-exit.svg';
import { ReactComponent as MergeIcon } from '../../assets/svgs/git-merge.svg';
import { ReactComponent as GmailIcon } from '../../assets/svgs/gmail.svg';
import { ReactComponent as GoogleIcon } from '../../assets/svgs/google.svg';
import { ReactComponent as SlackIcon } from '../../assets/svgs/slack-small.svg';
import { ReactComponent as ImportPeople } from '../../assets/svgs/table-rows.svg';
import { ReactComponent as ClassificationTag } from '../../assets/svgs/tag.svg';
import { ReactComponent as TwitterIcon } from '../../assets/svgs/twitter-small.svg';
import { ReactComponent as AddPerson } from '../../assets/svgs/user-plus.svg';

export {
  AddPerson,
  AddToSegmentIcon,
  BackArrow,
  CalendarIcon,
  ClassificationTag,
  CloseIcon,
  CreateDynamicSegment,
  DefaultOrganizationIcon,
  ExportPeople,
  GoogleIcon,
  GmailIcon,
  ImportPeople,
  MergeArrow,
  MergeIcon,
  SlackIcon,
  TwitterIcon,
  DoorExitIcon,
};

import { useEffect } from 'react';
import { BaseStore } from 'stores/base';
import { RootStore } from 'stores/root';

export const useStoreMount = (rootStore: RootStore, store: BaseStore | BaseStore[]) => {
  const stores = Array.isArray(store) ? store : [store];
  const teamId = rootStore.teams._currentTeamId;

  useEffect(() => {
    if (teamId) {
      rootStore.ui.workOn(async () => {
        for (const store of stores) {
          try {
            await store.mount();
          } catch (e) {
            console.error(e);
          }
        }
      });
    }

    return () => {
      stores.forEach(store => store.unmount());
    };

    // The only thing we want to trigger an effect here is the teamId changing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);
};

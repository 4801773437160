import { getEditedProps } from '@caravel/helpers/src/comparison';
import {
  ConditionChange,
  SourceSettingsTrove,
  TroveCopy,
  TroveDesign,
  TroveEventCondition,
  TroveEventType,
  TroveQuestion,
  TroveQuestionType,
  TroveTriggers,
} from '@caravel/types/src';
import { GREY_PALETTE, isEmptyString, isEqual, makeShortId, PRIMARY_PALETTE } from '@caravel/utils';
import { TroveFormTabs } from 'components/sources/trove/trove-form';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { BaseStore } from 'stores/base';
import { RootStore } from 'stores/root';

const CREATE_BASE_PATH = /^\/sources\/new/;
const EDIT_BASE_PATH = /^\/sources\/(.*)/;

interface TroveFormProps {
  name: string;
  description: string;
  settings: SourceSettingsTrove;
  isActive?: boolean;
}

function selectLabelPlaceholder(type: TroveQuestionType) {
  const textLabel = 'How can we improve your experience?';
  const ratingLabel = 'How would you rate your experience so far?';
  const multipleChoiceLabel = 'How did you hear about our services?';
  const defaultLabel = '';

  let label = '';
  switch (type) {
    case 'text':
      label = textLabel;
      break;
    case 'multiple-choice':
      label = multipleChoiceLabel;
      break;
    case 'five-rating':
    case 'ten-rating':
      label = ratingLabel;
      break;
    default:
      label = defaultLabel;
      break;
  }

  return label;
}

function makeTroveQuestion(type: TroveQuestionType) {
  return {
    type,
    label: selectLabelPlaceholder(type),
    choices: ['Youtube', 'Facebook', 'Google Ad'],
    minLabel: 'Very dissatisfied',
    maxLabel: 'Very satisfied',
  };
}

function makeTroveEventCondition(attribute: TroveEventType): TroveEventCondition {
  const operator = attribute === 'click' ? 'selector' : 'contains';
  const operatorOptions =
    attribute === 'click' ? [{ name: 'CSS Selector', value: 'selector' }] : [{ name: 'Contains', value: 'contains' }];
  const placeholder = attribute === 'click' ? '#survey-button' : '/survey';
  return {
    attribute,
    operator,
    operatorOptions,
    value: '',
    valueInput: {
      type: 'text-input',
      placeholder,
    },
  };
}

function makeTroveProps(props: Partial<TroveFormProps>): TroveFormProps {
  const { name = '', description = '', isActive = true } = props;
  const settings: SourceSettingsTrove = {
    deidentifyRequired: props.settings?.deidentifyRequired ?? false,
    cleanHtml: props.settings?.cleanHtml ?? true,
    key: props.settings?.key ?? makeShortId(),
    questions: props.settings?.questions ?? [makeTroveQuestion('text')],
    askForEmail: props.settings?.askForEmail ?? true,
    emailRequired: props.settings?.emailRequired ?? false,
    copy: {
      submitButton: 'Send feedback',
      submitSuccess: 'Thank you! We received your feedback.',
      emailPrompt: 'Would you like to add your email so our team can respond to your feedback?',
      ...props.settings?.copy,
    },
    design: {
      background: GREY_PALETTE[1],
      inputBackground: GREY_PALETTE[0],
      questions: GREY_PALETTE[6],
      answers: GREY_PALETTE[6],
      buttons: PRIMARY_PALETTE[200],
      buttonText: GREY_PALETTE[0],
      secondaryButtons: GREY_PALETTE[0],
      secondaryText: GREY_PALETTE[6],
      ratingButtons: GREY_PALETTE[2],
      ratingText: GREY_PALETTE[6],
      location: 'center',
      paddingHorizontal: 20,
      paddingVertical: 20,
      overlay: true,
      overlayColor: GREY_PALETTE[6],
      overlayOpacity: 80,
      disableBranding: false,
      ...props.settings?.design,
    },
    triggers: {
      events: [],
      delay: 0,
      ...props.settings?.triggers,
    },
  };
  return {
    name,
    description,
    settings,
    isActive,
  };
}

export class TroveForm extends BaseStore {
  basePath = '';
  sourceId?: string = undefined;
  initialProps?: TroveFormProps = undefined;
  formProps?: TroveFormProps = undefined;

  tabErrors = observable.map<TroveFormTabs, string[]>([]);
  hasChangedQuestions = false;

  get hasChanges() {
    if (!this.formProps && !this.initialProps) {
      return false;
    }
    if (this.formProps && !this.initialProps) {
      // new source
      return true;
    }
    if (this.hasChangedQuestions) {
      return true;
    }
    if (!isEqual(this.formProps?.settings.design, this.initialProps?.settings.design)) {
      return true;
    }
    if (this.formProps?.settings.deidentifyRequired !== this.initialProps?.settings?.deidentifyRequired) {
      return true;
    }
    if (this.formProps?.settings.askForEmail !== this.initialProps?.settings?.askForEmail) {
      return true;
    }
    if (
      this.rootStore.members.current?.isSuper &&
      this.initialProps?.settings?.cleanHtml !== undefined &&
      this.formProps?.settings.cleanHtml !== this.initialProps?.settings?.cleanHtml
    ) {
      return true;
    }
    return this.changedProps.length > 0;
  }

  get changedProps(): string[] {
    if (!this.formProps || !this.initialProps) {
      return [];
    }
    return getEditedProps(this.formProps, this.initialProps, ['clearCache', 'getCachedProp', 'toJS', 'update']);
  }

  // Can commit changes
  get isCreatePath() {
    return CREATE_BASE_PATH.test(this.basePath);
  }

  get isEditPath() {
    return !this.isCreatePath && EDIT_BASE_PATH.test(this.basePath);
  }

  get nameInvalid() {
    return isEmptyString(this.formProps?.name);
  }

  get questionsInvalid() {
    const formProps = this.getFormProps();
    if (!formProps) {
      return true;
    }
    if (!formProps.settings.questions?.length) {
      return true;
    }
    const invalidQuestions = formProps.settings.questions.filter(q => {
      if (isEmptyString(q.label)) {
        return true;
      }
      if (q.type !== 'text' && (isEmptyString(q.minLabel) || isEmptyString(q.maxLabel))) {
        return true;
      }
      return false;
    });
    return invalidQuestions.length;
  }

  get setupCanContinue() {
    return !this.nameInvalid;
  }

  get configureCanContinue() {
    return this.setupCanContinue && !this.questionsInvalid;
  }

  get designCanContinue() {
    return this.configureCanContinue;
  }

  get canCreate() {
    return this.configureCanContinue;
  }

  get canSave() {
    return this.configureCanContinue && this.hasChanges;
  }

  get canCommit() {
    return (
      (this.isCreatePath && !this.sourceId && this.canCreate) ||
      ((this.isEditPath || this.sourceId) && this.canSave) ||
      false
    );
  }

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      basePath: observable,
      sourceId: observable,
      initialProps: observable,
      formProps: observable,
      tabErrors: observable,
      hasChangedQuestions: observable,
      questionsInvalid: computed,
      hasChanges: computed,
      changedProps: computed,
      nameInvalid: computed,
      isCreatePath: computed,
      isEditPath: computed,
      setupCanContinue: computed,
      configureCanContinue: computed,
      designCanContinue: computed,
      canCreate: computed,
      canSave: computed,
      canCommit: computed,
      resetFormProps: action,
      initializeForm: action,
      checkForChangedQuestions: action,
      onAddQuestion: action,
      onChangeQuestion: action,
      onRemoveQuestion: action,
      onChangeName: action,
      onChangeDescription: action,
      onChangeAskForEmail: action,
      onChangeDesignProp: action,
      onChangeCopyProp: action,
      onChangeEmailRequired: action,
    });
  }

  async setup() {
    await super.setup();
  }

  teardown() {
    super.teardown();
  }

  getFormProps() {
    return toJS(this.formProps);
  }

  getSourceProps = async (): Promise<any | undefined> => {
    const formProps = this.getFormProps();
    if (!formProps) {
      return undefined;
    }
    const { teamId, userId } = await this.getTeamAndToken();
    return {
      name: formProps.name,
      description: formProps.description,
      settings: formProps.settings,
      teamId,
      color: GREY_PALETTE[0],
      createdBy: userId,
      isActive: true,
      sourceTypeName: 'trove',
    };
  };

  resetFormProps = () => {
    this.initialProps = undefined;
    this.formProps = undefined;
    this.basePath = '';
    this.sourceId = undefined;
    this.hasChangedQuestions = false;
  };

  initializeForm = (basePath: string, source?: Partial<TroveFormProps>, sourceId?: string) => {
    this.resetFormProps();
    if (source) {
      const editProps = makeTroveProps(source);
      this.initialProps = { ...editProps };
      this.formProps = { ...editProps };
      this.basePath = basePath;
      this.sourceId = sourceId;
    } else {
      const newProps = makeTroveProps({});
      this.initialProps = { ...newProps };
      this.formProps = { ...newProps };
      this.basePath = basePath;
      this.sourceId = undefined;
    }
  };

  checkForChangedQuestions = () => {
    const initQuestions = this.initialProps?.settings.questions || [];
    const formQuestions = this.formProps?.settings.questions || [];

    if (initQuestions.length !== formQuestions.length) {
      this.hasChangedQuestions = true;
      console.debug('mismatched question length');
      return;
    }

    const changed = initQuestions.map((initQuestion, i) => {
      const formQuestion = formQuestions[i];
      if (!formQuestion) {
        console.debug('no form question');
        return true;
      }
      if (!isEqual(initQuestion, formQuestion)) {
        console.debug('questions not equal');
        return true;
      }
      return false;
    });

    this.hasChangedQuestions = changed.filter(c => c).length > 0;
  };

  onChangeName = (name: string) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      name,
    };
  };

  onChangeDescription = (description: string) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      description,
    };
  };

  onChangeIsActive = (isActive: boolean) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      isActive,
    };
  };

  onChangeDeidentifyRequired = (deidentifyRequired: boolean) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        deidentifyRequired,
      },
    };
  };

  onChangeCleanHtml = (cleanHtml: boolean) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        cleanHtml,
      },
    };
  };

  onChangeCopyProp = (prop: keyof TroveCopy, value: string | boolean) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        copy: {
          ...this.formProps.settings.copy,
          [prop]: value,
        },
      },
    };
  };

  onChangeDesignProp = (prop: keyof TroveDesign, value: string | number | boolean) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        design: {
          ...this.formProps.settings.design,
          [prop]: value,
        },
      },
    };
  };

  onChangeTriggersProp = (prop: keyof TroveTriggers, value: string | number | boolean) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        triggers: {
          ...this.formProps.settings.triggers,
          [prop]: value,
        },
      },
    };
  };

  onChangeQuestion = (index: number, props: Partial<TroveQuestion>) => {
    if (!this.formProps) {
      return;
    }
    const questions = this.formProps.settings.questions.slice();
    questions.splice(index, 1, {
      ...questions[index],
      ...props,
    });
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        questions,
      },
    };
  };

  onAddQuestion = (type: TroveQuestionType) => {
    if (!this.formProps) {
      return;
    }
    const questions = this.formProps.settings.questions.slice();
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        questions: [...questions, makeTroveQuestion(type)],
      },
    };
  };

  onRemoveQuestion = (index: number) => {
    if (!this.formProps) {
      return;
    }
    const questions = this.formProps.settings.questions.slice();
    questions.splice(index, 1);
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        questions,
      },
    };
  };

  onAddEvent = (type: TroveEventType) => {
    if (!this.formProps) {
      return;
    }
    const events = this.formProps.settings.triggers.events.slice();
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        triggers: {
          ...this.formProps.settings.triggers,
          events: [...events, makeTroveEventCondition(type)],
        },
      },
    };
  };

  onChangeEvent = (change: ConditionChange) => {
    if (!this.formProps) {
      return;
    }
    const events = this.formProps.settings.triggers.events.slice();
    events.splice(change.index, 1, {
      ...events[change.index],
      [change.param]: change.value,
    });
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        triggers: {
          ...this.formProps.settings.triggers,
          events,
        },
      },
    };
  };

  onRemoveEvent = (index: number) => {
    if (!this.formProps) {
      return;
    }
    const events = this.formProps.settings.triggers.events.slice();
    events.splice(index, 1);
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        triggers: {
          ...this.formProps.settings.triggers,
          events,
        },
      },
    };
  };

  onChangeAskForEmail = (askForEmail: boolean) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        askForEmail,
      },
    };
  };

  onChangeEmailRequired = (emailRequired: boolean) => {
    if (!this.formProps) {
      return;
    }
    this.formProps = {
      ...this.formProps,
      settings: {
        ...this.formProps.settings,
        emailRequired,
      },
    };
  };

  saveChanges = async () => {
    // FIXME: use Core API to save changes
  };
}

import { GREY_PALETTE } from '@caravel/utils';
import { Tabs as MuiTabs, TabsProps } from '@mui/material';
import React from 'react';

export const Tabs = React.forwardRef<HTMLButtonElement, TabsProps>(({ ...props }, ref) => (
  <MuiTabs
    {...props}
    ref={ref}
    TabIndicatorProps={{
      sx: {
        backgroundColor: 'transparent !important',
        display: 'flex',
        justifyContent: 'center',
      },
    }}
    sx={{
      width: 'fit-content',
      minHeight: 'fit-content',
      borderRadius: '4px',
      '& .MuiTabs-scroller': {
        backgroundColor: GREY_PALETTE[2],
        padding: '2px',
        height: 'fit-content',
        borderRadius: '4px',
      },
    }}
  />
));

Tabs.displayName = 'Tabs';

import { AppModals } from 'components/modals';
import { DarkSnackbar } from 'components/notifications/dark-snackbar';
import { Logger } from 'helpers';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useStore } from 'stores';

import { AppSnackbar } from '../app-snackbar';
import { AuthenticatedRoutes } from './authenticated-routes';
import { UnauthenticatedRoutes } from './unauthenticated-routes';

const { debug } = new Logger('app-routes');

export const AppRoutes = observer(() => {
  const location = useLocation();
  const store = useStore();
  const history = useHistory();

  // track page change events --
  const prevRef = useRef<string>();
  useEffect(() => {
    prevRef.current = location.pathname;
  });
  const prevLocation = prevRef.current;

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname !== prevLocation) {
      store.analytics.trackPage();
    }
  });
  // -- track page change events

  useEffect(() => {
    const load = async () => {
      await when(() => store.members.current !== undefined);
      if (!store.teams.currentTeam && store.members.current?.lastTeamId) {
        debug('loading team');
        await store.teams.loadTeam(store.members.current.lastTeamId);
      } else if (store.teams.teams.length > 0) {
        return history.push('/teams');
      } else {
        return history.push('/teams/new');
      }

      await when(() => store.teams.currentTeam !== undefined && !store.teams.isLoading);
    };

    load();
  }, [history, store.members, store.teams, store.teams.teams.length]);

  const starRender = () => {
    if (store.auth.user) {
      if (location.pathname.startsWith('/settings/integrations')) {
        // need to stay at integrations path for external auth redirects
        return;
      }

      debug('is signed in => /champions');
      return <Redirect to="/champions" />;
    } else {
      return <Redirect to="/sign-up" />;
    }
  };

  if (!store.auth.isInitialized) {
    return null;
  }

  return (
    <>
      {store.auth.signedIn ? (
        <AuthenticatedRoutes starRender={starRender} />
      ) : (
        <UnauthenticatedRoutes starRender={starRender} />
      )}
      <DarkSnackbar />
      <AppSnackbar />
      <AppModals />
    </>
  );
});

import { AddButton, Column } from '@caravel/components/src';
import { CaravelSpinner } from '@caravel/components/src';
import { CardContent, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { SegmentRule } from './segment-rule';

export const DynamicSegmentForm = observer(() => {
  const store = useStore();
  const theme = useTheme();

  const {
    ui: { SegmentForm },
  } = store;

  const formProps = SegmentForm.formProps;
  if (!formProps) {
    return <CaravelSpinner sx={{ marginBottom: theme.spacing(2) }} />;
  }

  function handleAddRule() {
    SegmentForm.addRule();
  }

  function handleRemoveRule(index: number) {
    SegmentForm.removeRule(index);
  }

  return (
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: `${theme.spacing(4)} 0`,
        height: '100%',
      }}
    >
      <Column
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Typography>Rules</Typography>

        <AddButton onClick={handleAddRule}>Add Rule</AddButton>
      </Column>
      <Column sx={{ height: '100%', overflow: 'auto' }}>
        {SegmentForm.formProps!.segmentRules!.map((rule, index) => (
          <SegmentRule key={index} ruleIndex={index} handleRemoveRule={handleRemoveRule} />
        ))}
      </Column>
    </CardContent>
  );
});

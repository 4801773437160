import { MenuProps } from '@caravel/components/src';
import { CONDITION_LABELS, isMultiCondition, isSingleCondition } from '@caravel/utils';
import { Typography } from '@mui/material';
import React, { forwardRef } from 'react';

import { FilterMenu } from './filter-menu';
import { FilterMenuItem } from './filter-menu-item';
import { FilterProps, getFilterPropsFrom } from './filter-props';

export interface InlineConditionsMenuProps extends MenuProps, FilterProps {}

export const InlineConditionsMenu = forwardRef<HTMLDivElement, InlineConditionsMenuProps>((props, ref) => {
  const { menuProps, filterProps } = getFilterPropsFrom(props);
  return (
    <FilterMenu ref={ref} {...menuProps}>
      {filterProps.options.conditions
        .filter(condition => {
          if (isMultiCondition(condition) && filterProps.value.length === 1) {
            return false;
          } else if (isSingleCondition(condition) && filterProps.value.length > 1) {
            return false;
          }
          return true;
        })
        .map((condition, i) => (
          <FilterMenuItem
            key={`${filterProps.type}-${condition}-${i}`}
            onClick={() => {
              let nextValue = {
                condition: condition,
                value: filterProps.value,
              };
              if (isMultiCondition(condition) && isSingleCondition(condition) && filterProps.value.length > 1) {
                // from multi to single
                nextValue = {
                  ...nextValue,
                  value: [filterProps.value[0]],
                };
              }
              filterProps.onClickFilter && filterProps.onClickFilter(nextValue);
            }}
            sx={{
              minWidth: '105px',
            }}
          >
            <Typography variant="bodySmaller">{CONDITION_LABELS[condition]}</Typography>
          </FilterMenuItem>
        ))}
    </FilterMenu>
  );
});

InlineConditionsMenu.displayName = 'InlineConditionsMenu';

import max from 'lodash/max';
import mean from 'lodash/mean';
import min from 'lodash/min';
import sum from 'lodash/sum';
import take from 'lodash/take';
import takeRight from 'lodash/takeRight';

export { max, min, mean, sum };

/**
 * Computes the median given an array of numbers. Returns 0 if array is empty.
 * From: https://stackoverflow.com/a/70806192/473486
 */
export function median(arr: number[]) {
  if (!arr.length) return 0;
  const s = [...arr].sort((a, b) => a - b);
  const mid = Math.floor(s.length / 2);
  return s.length % 2 === 0 ? (s[mid - 1] + s[mid]) / 2 : s[mid];
}

/**
 * Manually calculates the first quartile without including the median for odd sized sets
 * @param arr Full set of numbers
 * @returns The first quartile
 */
export function firstQuartile(arr: number[]): number {
  const sorted = arr.slice().sort((a, b) => a - b);
  const size = Math.floor(sorted.length / 2);
  const subset = take(sorted, size);
  if (!size || !subset.length) {
    return sorted[0] ?? 0;
  }
  return median(subset);
}

/**
 * Manually calculates the third quartile without including the median for odd sized sets
 * @param arr Full set of numbers
 * @returns The third quartile
 */
export function thirdQuartile(arr: number[]): number {
  const sorted = arr.slice().sort((a, b) => a - b);
  const size = Math.floor(sorted.length / 2);
  const subset = takeRight(sorted, size);
  if (!size || !subset.length) {
    return sorted[sorted.length - 1] ?? 0;
  }
  return median(subset);
}

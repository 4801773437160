import { GREY_PALETTE, PRIMARY_PALETTE, WHITE } from '@caravel/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Collapse, List, ListItemButton, ListItemText, SvgIcon } from '@mui/material';
import React, { SVGProps, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { RouterLink } from '../router-link';
import { NAV_FONT_SIZE, StyledListItem, StyledListItemIcon } from './app-navigation';

interface CollapsibleListItemProps {
  icon?: JSX.Element;
  label: string;
  customLabelComponent?: JSX.Element;
  display?: boolean;
  path: string;
}

interface CollapsibleListProps {
  label: string;
  icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  listItems: Array<CollapsibleListItemProps>;
  startOpen?: boolean;
}

export const CollapsibleList = (props: CollapsibleListProps) => {
  const { label, listItems, icon, startOpen = false } = props;
  const [open, setOpen] = React.useState(startOpen);
  const location = useLocation();
  const matchesPath = useMemo(() => {
    return (
      listItems
        .map(item => {
          return location.pathname.startsWith(item.path);
        })
        .filter(Boolean).length > 0
    );
  }, [listItems, location.pathname]);

  return (
    <Box>
      <StyledListItem
        onClick={() => {
          if (matchesPath) return;
          setOpen(!open);
        }}
        sx={{ minHeight: '30px' }}
      >
        {icon && (
          <StyledListItemIcon>
            <SvgIcon
              component={icon}
              sx={{
                fill: 'none',
                stroke: 'none',
                '& [data-bg-fill]': {
                  fill: matchesPath ? PRIMARY_PALETTE[100] : GREY_PALETTE[2],
                },
                '& [data-bg-stroke]': {
                  stroke: matchesPath ? PRIMARY_PALETTE[100] : GREY_PALETTE[2],
                },
                '& [data-fg-fill]': {
                  fill: matchesPath ? WHITE : GREY_PALETTE[5],
                },
                '& [data-fg-stroke]': {
                  stroke: matchesPath ? WHITE : GREY_PALETTE[5],
                },
              }}
            />
          </StyledListItemIcon>
        )}
        <ListItemText primary={label} />
        <KeyboardArrowDownIcon
          sx={{
            mr: '8px',
            width: '18px',
            height: '18px',
            transform: matchesPath || open ? 'rotate(0)' : 'rotate(-90deg)',
            transition: '0.2s',
          }}
        />
      </StyledListItem>
      <Collapse in={matchesPath || open}>
        <List
          sx={{
            padding: '0 0 13px 0',
          }}
        >
          {listItems.map((item, i) => {
            if (!item.display!) {
              return null;
            }
            const matchesItemPath = location.pathname.startsWith(item.path);
            return (
              <RouterLink
                key={`${item.path}-${i}`}
                to={item.path}
                id={label + '-menu-list-' + item.label + '-item'}
                title={item.label}
                color="textPrimary"
                underline="none"
              >
                <ListItemButton
                  sx={{
                    padding: '0 0px',
                    borderRadius: '4px',
                    marginLeft: '32px',
                    marginBottom: '4px',
                    minHeight: '30px',
                    minWidth: '39px',
                    '&:hover': {
                      backgroundColor: GREY_PALETTE[2],
                    },
                    '&:active': {
                      backgroundColor: PRIMARY_PALETTE['50'],
                    },
                    '& .MuiListItemText-root': {
                      '& > *': {
                        fontSize: `${NAV_FONT_SIZE}px`,
                        lineHeight: `${NAV_FONT_SIZE}px`,
                      },
                    },
                    ...(matchesItemPath ? { backgroundColor: PRIMARY_PALETTE['50'] } : {}),
                  }}
                >
                  <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                  {item.customLabelComponent ? item.customLabelComponent : <ListItemText primary={item.label} />}
                </ListItemButton>
              </RouterLink>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
};

import { ConditionSelectOption } from '@caravel/types';
import { TextField, Typography, useTheme } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { getAutocompleteOptions, useDebounce } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores';

export function ConditionAutocomplete(props: {
  disabled?: boolean;
  openOnFocus?: boolean;
  onBlur?: () => void;
  endpoint: string;
  value: ConditionSelectOption<unknown> | null;
  onChangeSelected: (value: ConditionSelectOption<unknown> | null) => void;
  placeholder?: string;
  autocompleteParams: { [key: string]: any };
}) {
  const store = useStore();
  const { disabled, openOnFocus = false, endpoint, value, onBlur, onChangeSelected, autocompleteParams } = props;
  const [options, setOptions] = useState<ConditionSelectOption[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const theme = useTheme();

  useEffect(() => {
    const loadOptions = async () => {
      setLoading(true);
      const { teamId, ...ids } = await store.getTeamAndToken();
      const params = { ...autocompleteParams, teamId, value: debouncedSearchTerm };

      const results = await getAutocompleteOptions(endpoint, params, ids);

      setLoading(false);
      if (results.ok) {
        setOptions([...results.options]);
      }
    };

    loadOptions();
  }, [debouncedSearchTerm, store, endpoint, autocompleteParams]);

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeSelected = (e: React.ChangeEvent<unknown>, newValue: ConditionSelectOption<unknown> | null) => {
    onChangeSelected(newValue);
  };

  return (
    <Autocomplete
      disabled={disabled}
      sx={{
        width: '100%',
        paddingRight: theme.spacing(2),
        '& .MuiAutocomplete-groupLabel': {
          height: 40,
          paddingTop: 0,
          paddingBottom: 0,
        },
        '& .MuiAutocomplete-input': {
          '&::placeholder': {
            opacity: 0.5,
          },
        },
      }}
      loading={loading}
      value={value}
      options={options.sort((a, b) => a.name.localeCompare(b.name))}
      onChange={handleChangeSelected}
      getOptionLabel={o => o.name}
      openOnFocus={openOnFocus}
      isOptionEqualToValue={(o, v) => {
        return o.value === v.value;
      }}
      renderOption={({ className: listClassName, ...listProps }, o) => {
        return (
          <li {...listProps} key={o.name} className={listClassName} style={{ alignItems: 'center', width: '100%' }}>
            <Typography>{o.name}</Typography>
          </li>
        );
      }}
      renderInput={params => {
        params.InputProps = {
          ...params.InputProps,
          sx: {
            '&&[class*="MuiOutlinedInput-root"]': {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: '14px',
              background: theme.palette.common.white,
            },
            '&&[class*="MuiOutlinedInput-root"] $input': {
              padding: '10px 14px 10px 0px',
            },
            '&$cssFocused $notchedOutline': {
              borderColor: `${theme.palette.primary.dark} !important`,
            },
            '&.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: `${theme.grey[3]} !important`,
            },
          },
        } as any;
        return (
          <TextField
            {...params}
            // autoFocus
            variant="outlined"
            onBlur={onBlur}
            onChange={handleChangeText}
          />
        );
      }}
    />
  );
}

import { Menu, MenuProps } from '@caravel/components/src';
import { GREY_PALETTE } from '@caravel/utils';
import React from 'react';

export interface FilterMenuProps extends MenuProps {}

export const FilterMenu = React.forwardRef<HTMLDivElement, FilterMenuProps>((props, ref) => {
  return (
    <Menu
      {...props}
      ref={ref}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPaper-root': {
          marginTop: '10px',
          backgroundColor: GREY_PALETTE[1],
          boxShadow: `0px 4px 16px rgba(35, 31, 32, 0.24)`,
          border: 'none',
        },
      }}
    />
  );
});

FilterMenu.displayName = 'FilterMenu';

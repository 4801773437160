import { Column } from '@caravel/components/src';
import { useAppReadyCheck } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useStore } from 'stores';

export const AutomationRoutes: FC = observer(() => {
  const store = useStore();

  if (!useAppReadyCheck(store)) {
    return null;
  }

  return <Column />;
});

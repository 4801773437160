import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const AddXSmall = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ alignSelf: 'center' }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15625 6V9H6.15625V11H9.15625V14H11.1562V11H14.1562V9H11.1562V6H9.15625Z"
    />
  </SvgIcon>
);

export const LogInfo = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="10" fill="#92BE6F" />
    <path d="M5 9.5L8.23629 13L11.6181 9.5L15 6" stroke="white" strokeWidth="2" fill="none" />
  </SvgIcon>
);

export const LogWarning = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0Z"
      fill="#F0D471"
    />
  </SvgIcon>
);

export const LogError = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0Z"
      fill="#EE5C5C"
    />
  </SvgIcon>
);

import { Column, Row } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { FormSectionHeader } from '@caravel/components/src/forms/form-section-header';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { ProofTagsInput } from './proof-tags-input';

export const VideoForm = observer(() => {
  const store = useStore();
  const { ui } = store;
  const form = ui.ProofForm;

  const formProps = form.formProps;
  if (!formProps) {
    return null;
  }

  return (
    <Column sx={{ gap: 2, width: '100%', marginTop: '20px' }}>
      <Row>
        <FormInput
          label={'Customer name'}
          value={formProps.customerName}
          required
          error={form.showErrors && form.customerNameInvalid}
          helperText={form.showErrors && form.customerNameInvalid ? 'Customer name is required' : ''}
          placeholder={'Fullname'}
          fullWidth
          aria-label={'customer name'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              customerName: e.target.value,
            })
          }
        />
      </Row>
      <Row sx={{ gap: 2 }}>
        <FormInput
          label={'Title'}
          value={formProps.employmentTitle}
          placeholder={'Head of Sales'}
          fullWidth
          aria-label={'customer employment title'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              employmentTitle: e.target.value,
            })
          }
        />
        <FormInput
          label={'Organization'}
          value={formProps.organizationName}
          placeholder={'Google'}
          fullWidth
          aria-label={'customer organization name'}
          onChange={e =>
            form.changeProof({
              ...formProps,
              organizationName: e.target.value,
            })
          }
        />
      </Row>
      <Row>
        <FormInput
          label={'Video URL'}
          value={formProps.sourceLink}
          required
          error={form.showErrors && (form.sourceLinkInvalid || form.videoSourceInvalid)}
          helperText={
            form.showErrors && (form.sourceLinkInvalid || form.videoSourceInvalid)
              ? 'Only valid YouTube or Vimeo links are allowed'
              : ''
          }
          description={'Embed videos from YouTube or Vimeo'}
          placeholder={'https://www.youtube.com/your-video'}
          fullWidth
          onChange={e =>
            form.changeProof({
              ...formProps,
              sourceLink: e.target.value,
            })
          }
        />
      </Row>
      <Row>
        <FormInput
          label={'Video title or short quote'}
          value={formProps.content}
          description="Something to help you easily recognize the video"
          placeholder={'Life-changing software! A total game-changer'}
          fullWidth
          onChange={e =>
            form.changeProof({
              ...formProps,
              content: e.target.value,
            })
          }
        />
      </Row>
      <ProofTagsInput />
    </Column>
  );
});

import { Box, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, useState } from 'react';

import { FilterProps } from './filter-props';

export const NumberInputFilterMenu = observer((props: PropsWithChildren<FilterProps>) => {
  const [value, setValue] = useState(props.value[0] || '');

  const handleSubmit = () => {
    if (value) props.onChangeFilter({ value: [value], condition: props.condition });
  };

  return (
    <Box sx={{ padding: '8px', maxHeight: '300px', overflow: 'auto' }}>
      <TextField
        label={props.label}
        autoFocus
        type="number"
        value={value}
        onSubmit={handleSubmit}
        onBlur={handleSubmit}
        onChange={e => setValue(e.target.value)}
      />
    </Box>
  );
});

import { PersonaType } from '@caravel/types';
import { GREY_PALETTE, NEUTRALS } from '@caravel/utils/src';
import {
  Divider as MuiDivider,
  IconButton,
  MenuItem,
  Select,
  SelectProps,
  styled,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Row } from '../flex';
import { ReactComponent as AddNewIcon } from './svgs/add-icon.svg';
import { ReactComponent as DeleteIcon } from './svgs/delete-icon.svg';
import { ReactComponent as EditIcon } from './svgs/edit-menu-items.svg';

const Divider = styled(MuiDivider)({
  margin: '0 !important',
});

export interface AttributeFieldOption {
  value?: string;
  label?: string;
  custom?: boolean;
  id?: string;
  personaId?: string;
}

export interface AttributeFieldProps extends SelectProps {
  attributeName?: string;
  value: AttributeFieldOption;
  options: AttributeFieldOption[];
  customizable?: boolean;
  attributeType: PersonaType;
  autoFocus?: boolean;
  loading?: boolean;
  onChangeAttribute: (attributeType: PersonaType, option: AttributeFieldOption, personaId?: string) => void;
  onDeleteAttribute: (attributeType: PersonaType, personaId?: string) => void;
  addItemLabel?: string;
}

export const AttributeField = forwardRef<HTMLDivElement, AttributeFieldProps>((props, ref) => {
  const {
    attributeName,
    value,
    options,
    onChangeAttribute,
    onDeleteAttribute,
    customizable,
    attributeType,
    autoFocus,
    addItemLabel = 'Add New Item',
    loading = false,
  } = props;
  const [addNewItem, setAddNewItem] = useState(false);
  const [transparentState, setTransparentState] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [newItems, setNewItems] = useState<AttributeFieldOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<AttributeFieldOption[]>([]);
  const [attributeValue, setAttributeValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnclickMenuItem = (option: AttributeFieldOption) => {
    setAttributeValue(option);
    onChangeAttribute(
      attributeType,
      {
        value: option.value,
        personaId: option.personaId,
      },
      option.personaId,
    );
  };

  const handleOnChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(event.target.value);
  };

  const handleOnDeleteNewItem = (option: AttributeFieldOption) => {
    onDeleteAttribute(attributeType, option.personaId);
    setTransparentState(true);
  };

  const handleOnAddingNewItem = () => {
    setAttributeValue({ value: newValue });
    inputRef.current?.blur();
    if (newValue.length === 0) {
      setAddNewItem(false);
    } else {
      const newId = uuid();
      onChangeAttribute(attributeType, { value: newValue, id: newId });
      setNewItems([{ value: newValue, id: newId }]);
      setNewValue('');
      setAddNewItem(false);
    }
  };

  const filterUniqueOptions = (options: AttributeFieldOption[]) => {
    const uniqueArray =
      options &&
      options.filter((element, id) => id === options.findIndex(opt => opt.value && opt.value === element.value));
    return Array.from(uniqueArray);
  };

  const handleMenuToggle = (menu: boolean, transparent: boolean) => {
    setTransparentState(transparent);
    setMenuOpen(menu);
  };

  const handleOnBlur = () => {
    setAddNewItem(false);
    handleMenuToggle(false, true);
  };

  useEffect(() => {
    setFilteredOptions(filterUniqueOptions(options));
  }, [options]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} ref={ref}>
      {attributeName && (
        <Typography color={GREY_PALETTE[7]} variant="bodySmallest" sx={{ marginRight: '25px' }}>
          {attributeName}
        </Typography>
      )}
      {addNewItem ? (
        <TextField
          contentEditable={!loading}
          suppressContentEditableWarning
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeValue(event)}
          autoFocus={autoFocus}
          onBlur={handleOnBlur}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleOnAddingNewItem();
            }
          }}
          sx={{
            color: NEUTRALS.ESPRESSO,
            width: '100%',
            height: '16px',
            '& .MuiInput': {
              '&-root': {
                fontSize: '12px',
                '&:before': {
                  borderBottom: `1px solid ${NEUTRALS.IRISH_BREAKFAST}`,
                },
                '&:after': {
                  borderBottom: `1px solid ${NEUTRALS.IRISH_BREAKFAST}`,
                },
                '&:hover:not(.Mui-disabled, .Mui-error):before': {
                  borderBottom: `1px solid ${NEUTRALS.IRISH_BREAKFAST}`,
                },
              },
              '&-input': {
                padding: '0 0 2px',
              },
            },
          }}
        />
      ) : (
        <Select
          contentEditable={!loading}
          suppressContentEditableWarning
          IconComponent={!transparentState ? EditIcon : undefined}
          variant="filled"
          disableUnderline={true}
          onMouseEnter={() => handleMenuToggle(false, false)}
          onMouseLeave={() => setTransparentState(true)}
          onOpen={() => handleMenuToggle(true, false)}
          onClose={() => handleMenuToggle(false, true)}
          disabled={loading}
          inputProps={{
            sx: {
              '&:hover': {
                background: NEUTRALS.CHAMOMILE,
              },
              '&:focus': {
                background: NEUTRALS.CHAMOMILE,
              },
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                margin: '-16px 0px',
                width: '210px',
                border: 'none',
                boxShadow: '0px 3px 14px 2px rgba(0,0,0,0.12),0px 10px 15px -3px rgba(0,0,0,0.1)',
                borderRadius: '4px',
                maxHeight: '60vh',
                '& .MuiList-root': {
                  background: NEUTRALS.CHAMOMILE,
                  borderRadius: '4px',
                  whiteSpace: 'noWrap',
                  padding: '0',
                  '& .MuiMenuItem-root': {
                    fontSize: '12px',
                    padding: '8px',
                  },
                },
              },
            },
          }}
          sx={{
            color: NEUTRALS.ESPRESSO,
            width: '100%',
            height: '16px',
            padding: '0px, 6px',
            background: 'transparent',
            fontSize: '12px',
            '&.Mui-disabled': {
              background: NEUTRALS.CHAMOMILE,
              opacity: '0.8',
            },
            '&:focus-within': {
              background: 'transparent',
              '&:hover': {
                background: NEUTRALS.CHAMOMILE,
              },
            },
            '&:hover': {
              background: NEUTRALS.CHAMOMILE,
            },
            '& .MuiInputBase-input': {
              margin: '0',
              padding: '0',
              background: 'transparent',
              '&:hover': {
                background: NEUTRALS.CHAMOMILE,
              },
              '&:focus': {
                background: 'transparent',
              },
            },
            '& .MuiSelect': {
              '&-icon': {
                top: 'unset',
              },
              '&-select': {
                '&:hover': {
                  background: NEUTRALS.CHAMOMILE,
                },
                '&:focus': {
                  background: 'transparent',
                },
              },
            },
            '& .MuiSvgIcon-root': {
              display: 'none',
            },
          }}
          value={attributeValue.label || attributeValue.value}
        >
          <MenuItem value={attributeValue.value} disabled sx={{ display: 'none' }}>
            {attributeValue.label || attributeValue.value}
          </MenuItem>
          {filteredOptions.map((option, key) => {
            newItems && newItems.length && <Divider />;
            return (
              <MenuItem
                key={key}
                value={option.value}
                onClick={() => handleOnclickMenuItem(option)}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                {option.label || option.value}
                {option.custom && menuOpen && (
                  <IconButton onClick={() => handleOnDeleteNewItem(option)}>
                    <SvgIcon
                      component={DeleteIcon}
                      sx={{
                        fill: 'none',
                        height: '24px',
                        width: '24px',
                        viewBox: '0 0 20 20',
                      }}
                    />
                  </IconButton>
                )}
              </MenuItem>
            );
          })}
          {newItems && newItems.length && <Divider />}
          {customizable && <Divider />}
          {customizable && (
            <MenuItem sx={{ alignItems: 'center' }} onClick={() => setAddNewItem(true)} disabled={loading}>
              <Row alignItems="center">
                <AddNewIcon />
                <Typography variant="inherit" sx={{ padding: '0 8px' }}>
                  {addItemLabel}
                </Typography>
              </Row>
            </MenuItem>
          )}
        </Select>
      )}
    </div>
  );
});

AttributeField.displayName = 'Attribute Field';

import { gql } from '@apollo/client';
import { Channel } from '@caravel/types';

export const CONNECT_CHANNEL = gql`
  mutation ConnectChannel($input: ConnectChannelInput!) {
    connectChannel(input: $input) {
      result {
        id
      }
    }
  }
`;

export interface GqlConnectChannelRequestType {
  input: {
    clientMutationId: string;
    integrationId: string;
    oauthCode: string;
    state: string;
    storedState: string;
  };
}

export interface GqlConnectChannelResponseType {
  result: Channel;
}

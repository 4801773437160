import { Segment as SegmentProps } from '@caravel/types';
import { makeObservable, observable } from 'mobx';

import { BaseModel, makeToJS, makeUpdate, PENDING_ID } from '.';

const skeletonProps: SegmentProps = {
  id: PENDING_ID,
  kind: undefined,
  name: '',
  description: undefined,
  createdBy: '',
  createdAt: '',
  updatedBy: undefined,
  updatedAt: undefined,
  people: [],
  peoplePaginated: [],
  peopleCount: undefined,
  segmentRules: [],
};

export class Segment extends BaseModel<SegmentProps> implements SegmentProps {
  id = skeletonProps.id;
  kind = skeletonProps.kind;
  name = skeletonProps.name;
  description = skeletonProps.description;
  createdBy = skeletonProps.createdBy;
  createdAt = skeletonProps.createdAt;
  updatedBy = skeletonProps.updatedBy;
  updatedAt = skeletonProps.updatedAt;
  people = skeletonProps.people;
  peoplePaginated = skeletonProps.peoplePaginated;
  peopleCount = skeletonProps.peopleCount;
  segmentRules = skeletonProps.segmentRules;

  constructor(props: SegmentProps) {
    super();
    makeObservable(this, {
      id: observable,
      kind: observable,
      name: observable,
      description: observable,
      createdBy: observable,
      createdAt: observable,
      updatedBy: observable,
      people: observable,
      peoplePaginated: observable,
      peopleCount: observable,
      segmentRules: observable,
    });
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

import { gql } from '@apollo/client';

export const GET_SEGMENT_EMAILS = gql`
  query getSegmentEmails($segmentId: ID!) {
    community {
      id
      segments(filterBy: { id__eq: $segmentId }) {
        edges {
          node {
            id
            people {
              email
            }
          }
        }
        meta {
          facets {
            counts {
              count
              value
            }
            name
            totalValues
          }
          hits
          query
        }
      }
    }
  }
`;

export interface GqlSegmentEmailsRequestType {
  segmentId: string;
}

import {
  PeopleExportTask,
  SegmentInterConditionOperator,
  SegmentInterRuleOperator,
  SegmentRule as SegmentRuleProps,
  SegmentRuleCondition as SegmentRuleConditionProps,
  SegmentRuleConditionOperator as SegmentRuleConditionOperatorProps,
} from '@caravel/types/src';
import {
  createGQLClient,
  DELETE_SEGMENT,
  GET_ICP_SEGMENT,
  GET_SEGMENT,
  GET_SEGMENT_EMAILS,
  GET_SEGMENTS,
  GqlDeleteSegmentResponseType,
  GqlSegmentEmailsRequestType,
  GqlSegmentRequestType,
  GqlSegmentsRequestType,
  GqlSegmentsResponseType,
  SegmentRule,
  SegmentRuleCondition,
  SegmentRuleConditionOperator,
} from '@caravel/utils';
import { format } from 'date-fns';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Segment } from 'models/segment';

import { BaseStore } from './base';
import { RootStore } from './root';
const host = process.env.GRAPHQL_HOST!;

interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}

export class SegmentStore extends BaseStore {
  collection = observable.array<Segment>([]);
  loading = false;
  loaded = false;
  creating = false;
  updating = false;
  searching = false;
  segmentLoading = false;
  segmentPageInfo?: PageInfo = undefined;
  segment?: Segment = undefined;
  segmentEmailsLoading = false;

  order: 'asc' | 'desc' = 'desc';
  orderBy = 'updatedAt';

  segmentOrder: 'asc' | 'desc' = 'desc';
  segmentOrderBy = 'lastActive';

  icpSegment = {} as Segment;

  get orderByOptions() {
    return {
      field: this.orderBy,
      direction: this.order.toUpperCase(),
    };
  }

  get segmentOrderByOptions() {
    return {
      field: this.segmentOrderBy,
      direction: this.segmentOrder.toUpperCase(),
    };
  }

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      creating: observable,
      updating: observable,
      order: observable,
      orderBy: observable,
      orderByOptions: computed,
      icpSegment: observable,
      segmentLoading: observable,
      segmentPageInfo: observable,
      segmentOrder: observable,
      segmentOrderBy: observable,
      segmentOrderByOptions: computed,
      segment: observable,
      segmentEmailsLoading: observable,
    });
  }

  async mount() {
    await this.fetchSegments();
  }

  unmount() {
    this.collection.clear();
  }

  teardown() {
    this.unmount();
    super.teardown();
  }

  resetSegment = () => {
    runInAction(() => {
      this.segment = undefined;
      this.segmentPageInfo = undefined;
    });
  };

  createGraphQLClient = async () => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    return createGQLClient(teamId, token, host);
  };

  fetchSegments = async () => {
    if (this.loading) {
      console.debug('Segments already loading');
      return;
    }
    runInAction(() => (this.loading = true));
    const graphqlClient = await this.createGraphQLClient();
    try {
      const response = await graphqlClient.query<GqlSegmentsRequestType, GqlSegmentsResponseType>(GET_SEGMENTS, {
        orderBy: this.orderByOptions,
      });
      const segments: Segment[] = response.community.segments.edges
        ?.filter(edge => edge.node)
        .map(edge => {
          if (edge.node.kind === ':segment.kind/dynamic' && edge.node.segmentRules)
            return new Segment({
              ...edge.node,
              peoplePaginated: edge.node.peoplePaginated?.edges.map(edge => edge.node),
              segmentRules: formatSegmentRulesToProps(edge.node.segmentRules),
            });
          else return new Segment(edge.node as Segment);
        });
      runInAction(() => {
        this.collection.replace(segments);
        this.loading = false;
        this.loaded = true;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to fetch segment');
    }
  };

  // used for inviting all members of a segment
  fetchSegmentEmails = async (id: string): Promise<string[] | undefined> => {
    if (this.segmentEmailsLoading) {
      console.debug('Segment emails already loading');
      return;
    }
    runInAction(() => (this.segmentEmailsLoading = true));

    const graphqlClient = await this.createGraphQLClient();

    try {
      const response = await graphqlClient.query<GqlSegmentEmailsRequestType, GqlSegmentsResponseType>(
        GET_SEGMENT_EMAILS,
        {
          segmentId: id,
        },
      );
      const segment = response.community.segments?.edges?.at(0)?.node;
      runInAction(() => (this.segmentEmailsLoading = false));
      if (segment && segment.people) {
        return segment.people.filter(person => person.email).map(person => person.email!);
      }
      return;
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to fetch segment emails');
      runInAction(() => {
        this.segmentEmailsLoading = false;
      });
    }
  };

  fetchSegment = async (id: string) => {
    if (this.segmentLoading) {
      console.debug('Segment already loading');
      return;
    }
    runInAction(() => (this.segmentLoading = true));
    const after = this.segmentPageInfo?.endCursor;

    const graphqlClient = await this.createGraphQLClient();

    try {
      const response = await graphqlClient.query<GqlSegmentRequestType, GqlSegmentsResponseType>(GET_SEGMENT, {
        segmentId: id,
        after,
        orderBy: this.segmentOrderByOptions,
      });
      const segment = response.community.segments?.edges?.at(0)?.node;
      const pageInfo = segment?.peoplePaginated?.pageInfo;
      if (segment) {
        runInAction(() => {
          this.segmentPageInfo = pageInfo;

          if (pageInfo?.startCursor === '0') {
            this.segment = new Segment({
              ...segment,
              peoplePaginated: segment.peoplePaginated?.edges.map(edge => edge.node),
              segmentRules: formatSegmentRulesToProps(segment.segmentRules || []),
            });
          } else if (this.segment) {
            this.segment.peoplePaginated = [
              ...this.segment.peoplePaginated!,
              ...(segment.peoplePaginated?.edges.map(edge => edge.node) || []),
            ];
          }

          this.segmentLoading = false;
        });
      }
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to fetch segment');
      runInAction(() => {
        this.segmentLoading = false;
      });
    }
  };

  fetchICPSegment = async () => {
    if (this.loading) {
      console.debug('ICP Segment already loading');
      return;
    }
    runInAction(() => (this.loading = true));
    const graphqlClient = await this.createGraphQLClient();
    try {
      const response = await graphqlClient.query<GqlSegmentsRequestType, GqlSegmentsResponseType>(GET_ICP_SEGMENT, {});
      const icpSegment: Segment = response.community.segments.edges
        ?.filter(edge => edge.node)
        .map(edge => {
          return new Segment({
            ...edge.node,
            peoplePaginated: edge.node.peoplePaginated?.edges.map(edge => edge.node),
            segmentRules: formatSegmentRulesToProps(edge.node.segmentRules || []),
          });
        })[0];
      runInAction(() => {
        this.icpSegment = icpSegment;
        this.loading = false;
        this.loaded = true;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to fetch ICP segment');
    }
  };

  onDeleteSegment = async (id: string) => {
    const index = this.collection.findIndex(segment => segment.id === id);
    if (index > -1) {
      runInAction(() => {
        const next = this.collection.slice();
        next.splice(index, 1);
        this.collection.replace(next);
      });
      this.rootStore.notifications.display({
        severity: 'success',
        message: 'Successfully deleted segment',
        duration: 5000,
      });
    } else return;
  };
  deleteSegment = async (id: string) => {
    const graphqlClient = await this.createGraphQLClient();
    try {
      const response: GqlDeleteSegmentResponseType = await graphqlClient.query(DELETE_SEGMENT, { id });
      const deletedSegmentId = response.deleteSegment.result.id;
      await this.onDeleteSegment(deletedSegmentId);
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to delete segment');
    }
  };

  onChangeOrder = (orderBy: string) => {
    const isAsc = orderBy === this.orderBy && this.order === 'asc';
    runInAction(() => {
      this.order = isAsc ? 'desc' : 'asc';
      this.orderBy = orderBy;
      this.collection.clear();
    });
  };

  onChangeSegmentOrder = (orderBy: string) => {
    const isAsc = orderBy === this.segmentOrderBy && this.segmentOrder === 'asc';
    runInAction(() => {
      this.segmentOrder = isAsc ? 'desc' : 'asc';
      this.segmentOrderBy = orderBy;
      this.resetSegment();
    });
  };

  exportSegment = async (id: string) => {
    try {
      const enqueuePeopleExport = httpsCallable(getFunctions(), 'enqueuePeopleExport');
      const { teamId, userId } = await this.getTeamAndToken();
      const options: PeopleExportTask = {
        communityId: teamId,
        userId,
        segmentId: id,
      };
      await enqueuePeopleExport(options);
      await this.rootStore.notifications.displaySuccess(`Segment export started`);
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to export segment');
    }
  };
}
const kebabize = (s: string) => s.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

const formatSegmentRulesToProps = (rules: SegmentRule[]): SegmentRuleProps[] => {
  return rules.map(rule => ({
    id: rule.id,
    interConditionOperator: rule.interConditionOperator.split('/')[1] as SegmentInterConditionOperator,
    interRuleOperator: rule.interRuleOperator.split('/')[1] as SegmentInterRuleOperator,
    conditions: rule.conditions.map(condition => ({
      id: condition.id,
      field: kebabize(condition.field),
      operator: formatOperator(condition.operator) as SegmentRuleConditionOperatorProps,
      values: formatValues(condition),
    })) as SegmentRuleConditionProps[],
  }));
};
function formatISOStringToDisplay(date: string): string {
  switch (date) {
    case ':1-day-ago':
      return '1 day ago';
    case ':1-week-ago':
      return '1 week ago';
    case ':1-month-ago':
      return '1 month ago';
    case ':3-months-ago':
      return '3 months ago';
    default:
      if (isNaN(Date.parse(date))) return date;
      return format(new Date(date), 'yyyy-MM-dd');
  }
}
const camelCaseToTitleCase = (s: string) => {
  return (
    s[0].toLocaleUpperCase() +
    s
      .slice(1)
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
  );
};

const formatValues = (condition: SegmentRuleCondition) => {
  switch (condition.field) {
    case 'lastActive':
    case 'firstActive':
    case 'signupDate':
      return condition.values.map(value => formatISOStringToDisplay(value));
    case 'consistency':
    case 'frequency':
    case 'impact':
    case 'layer':
    case 'seniority':
      return condition.values.map(value => camelCaseToTitleCase(value));
    case 'gridStatus':
      return condition.values.map(value => (value === 'superfan' ? 'Super User' : camelCaseToTitleCase(value)));
    case 'source':
    case 'organization':
    case 'title':
    case 'city':
    case 'country':
    default:
      return condition.values;
  }
};

const formatOperator = (operator: SegmentRuleConditionOperator) => {
  switch (operator) {
    case ':segment.rule.condition.operator/is-any-of':
    case ':segment.rule.condition.operator/is-not':
    case ':segment.rule.condition.operator/is-not-any-of':
    case ':segment.rule.condition.operator/is-greater-than':
    case ':segment.rule.condition.operator/is-less-than':
      return operator.split('is-')[1];
    case ':segment.rule.condition.operator/includes-any-of':
    case ':segment.rule.condition.operator/includes-all-of':
    case ':segment.rule.condition.operator/includes-none-of':
    case ':segment.rule.condition.operator/is':
    case ':segment.rule.condition.operator/after':
    case ':segment.rule.condition.operator/before':
    default:
      return operator.split('/')[1];
  }
};

import { PersonaType } from '@caravel/types/src';
import { GREY_PALETTE, NEUTRALS } from '@caravel/utils/src';
import { IconButton, Link, styled, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { AttributeFieldOption } from '../attribute-field';
import { ReactComponent as EditIcon } from '../svgs/edit-menu-items.svg';

const EditableField = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: '12px',
  color: NEUTRALS.ESPRESSO,
  '&:hover': {
    background: NEUTRALS.CHAMOMILE,
    cursor: 'text',
    '& svg': {
      // When EditableField is hovered, show the icon
      display: 'block',
    },
  },
  '&:focus-within': {
    background: 'transparent',
    '&:hover': {
      background: NEUTRALS.CHAMOMILE,
    },
  },
  '& svg': {
    display: 'none', // Initially, the icon is not displayed
  },
}));

const StyledTextField = styled(TextField)({
  width: '100%',
  height: '16px',
  '& .MuiInput': {
    '&-root': {
      fontSize: '12px',
      '&:before': {
        borderBottom: `1px solid ${NEUTRALS.IRISH_BREAKFAST}`,
      },
      '&:after': {
        borderBottom: `1px solid ${NEUTRALS.IRISH_BREAKFAST}`,
      },
      '&:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: `1px solid ${NEUTRALS.IRISH_BREAKFAST}`,
      },
    },
    '&-input': {
      padding: '0 0 2px',
    },
  },
});

const isUrl = (s: string): boolean => {
  try {
    new URL(s);
    return true;
  } catch {
    return false;
  }
};

interface TextAttributeFieldProps {
  attributeName?: string;
  value: { value: string };
  options: AttributeFieldOption[];
  customizable?: boolean;
  attributeType: PersonaType;
  onChangeAttribute: (attributeType: PersonaType, option: AttributeFieldOption, personaId?: string) => void;
  onDeleteAttribute: (attributeType: PersonaType, personaId?: string) => void;
  loading?: boolean;
}
export const TextAttributeField: React.FC<TextAttributeFieldProps> = ({
  attributeName,
  value,
  options,
  attributeType,
  customizable,
  onChangeAttribute,
  onDeleteAttribute,
  loading = false,
}) => {
  const [editable, setEditable] = useState(false);
  const [transparentState, setTransparentState] = useState(true);
  const [newValue, setNewValue] = useState('');
  const [attributeValue, setAttributeValue] = useState(value);
  const [isAttributeValueUrl, setIsAttributeValueUrl] = useState(isUrl(value.value));
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEdit = () => {
    if (customizable) {
      setEditable(true);
      setNewValue(attributeValue.value);
    }
  };

  const handleOnChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(event.target.value);
  };
  const handleBlur = () => {
    setEditable(false);
  };

  const handleOnAddingNewItem = () => {
    setAttributeValue({ value: newValue });
    inputRef.current?.blur();
    if (newValue.length === 0) {
      setEditable(false);
      onDeleteAttribute(attributeType, options[0].personaId);
    } else {
      const newId = uuid();
      onChangeAttribute(attributeType, { value: newValue, id: newId });
      setNewValue('');
      setEditable(false);
    }
  };

  const handleMouseEnter = () => {
    setTransparentState(true);
  };

  const handleMouseLeave = () => {
    setTransparentState(false);
  };

  useEffect(() => {
    setIsAttributeValueUrl(isUrl(attributeValue.value));
  }, [attributeValue]);

  if (editable && !loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {attributeName && (
          <Typography color={GREY_PALETTE[7]} variant="bodySmallest" sx={{ marginRight: '25px' }}>
            {attributeName}
          </Typography>
        )}
        <StyledTextField
          variant="standard"
          autoFocus
          value={newValue}
          onChange={handleOnChangeValue}
          onBlur={handleBlur}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleOnAddingNewItem();
            }
          }}
          inputRef={inputRef}
        />
      </div>
    );
  }

  if (isAttributeValueUrl) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {attributeName && (
          <Typography color={GREY_PALETTE[7]} variant="bodySmallest" sx={{ marginRight: '25px' }}>
            {attributeName}
          </Typography>
        )}
        <EditableField onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Link href={attributeValue.value}>{attributeValue.value}</Link>
          {customizable && (
            <IconButton
              disabled={!transparentState}
              onClick={handleEdit}
              sx={{
                backgroundColor: 'transparent',
                height: '16px',
                '&:hover': {
                  backgroundColor: 'transparent !important', // Increase specificity
                },
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </EditableField>
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {attributeName && (
          <Typography color={GREY_PALETTE[7]} variant="bodySmallest" sx={{ marginRight: '25px' }}>
            {attributeName}
          </Typography>
        )}
        <EditableField onClick={handleEdit}>
          <span>{attributeValue.value}</span>
          {customizable && (
            <IconButton
              size="small"
              onClick={handleEdit}
              sx={{
                backgroundColor: 'transparent',
                height: '16px',
                '&:hover': {
                  backgroundColor: 'transparent !important', // Increase specificity
                },
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </EditableField>
      </div>
    );
  }
};

import { FilterProps, Row } from '@caravel/components/src';
import { FilterCondition } from '@caravel/types/src';
import { GREY_PALETTE } from '@caravel/utils';
import { Collapse, IconButton, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import React from 'react';
import { useStore } from 'stores';
import { PEOPLE_FILTER_OPTIONS_CONFIG } from 'stores/filters';

import { SearchIcon } from './champions-icons';
/**
 * Champions Toolbar Search
 *
 * This isn't a perfect solution, as it shares code with
 * other existing components but in the interest of time,
 * this works adequately.
 *
 * TODO: ENG-4274 Extract this component to make it more reusable by other
 * list views
 */
export const ChampionsToolbarSearch = observer(() => {
  const store = useStore();
  const [focusSearch, setFocusSearch] = useState<boolean>(false);
  const filterType = 'champions';
  const filterStore = store.teams.filters;
  const filters = filterStore.getAttributes(filterType).filters.slice(); //skip source filter
  const searchPropsID = filters.findIndex(filter => filter.type === 'search');
  const searchProps = filters.at(searchPropsID);
  const [searchQuery, setSearchQuery] = useState<string>(filterStore.championsSearchQuery);

  if (!searchProps) {
    return null;
  }

  const handleRemoveFilter = (index: number) => () => {
    filterStore.onRemoveFilter(filterType, index);
  };

  const handleSubmit = async () => {
    filterProps.onChangeFilter({ value: [searchQuery], condition: filterProps.condition }, true);
    await store.teams.champions.refreshChampions();
  };

  const filterProps: FilterProps = {
    ...searchProps,
    options: PEOPLE_FILTER_OPTIONS_CONFIG[searchProps?.type],
    onChangeFilter: (
      {
        value,
        condition,
      }: {
        value: string[];
        condition: FilterCondition | null;
      },
      skipRefresh = false,
    ) =>
      filterStore.onChangeFilter(
        filterType,
        searchPropsID,
        {
          ...searchProps,
          value,
          condition,
        },
        skipRefresh,
      ),
    onRemoveFilter: handleRemoveFilter(searchPropsID),
  };

  return (
    <Row sx={{ alignItems: 'center' }}>
      <Collapse orientation="horizontal" in={focusSearch}>
        <TextField
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          InputProps={{
            startAdornment: <SearchIcon style={{ width: '26px', height: '26px' }} />,
            sx: { fontSize: '13px', padding: '1px 10px', input: { paddingLeft: '10px' } },
          }}
          placeholder="search"
          sx={{ display: focusSearch ? 'flex' : 'none' }}
          inputRef={input => {
            if (input != null) {
              input.focus();
            }
          }}
          onBlur={() => {
            if (!searchQuery) {
              setFocusSearch(false);
            }
          }}
        />
      </Collapse>
      <IconButton
        sx={{ color: GREY_PALETTE[6], display: focusSearch ? 'none' : 'flex' }}
        onClick={() => {
          setFocusSearch(true);
        }}
      >
        <SearchIcon style={{ width: '20px', height: '20px' }} />
      </IconButton>
    </Row>
  );
});

import { Organization as OrganizationProps } from '@caravel/types';
import { makeObservable, observable } from 'mobx';
import { BaseModel, makeToJS, makeUpdate } from 'models/base';

const skeletonProps: OrganizationProps = {
  activeMemberCount: 0,
  annualRevenue: '',
  description: '',
  domain: '',
  employeesRange: '',
  facebookHandle: '',
  firstActive: '',
  firstMember: undefined,
  foundedYear: '',
  fundsRaised: 0,
  id: '',
  industry: '',
  linkedinHandle: '',
  location: '',
  locationCity: '',
  locationState: '',
  locationCountry: '',
  memberCount: 0,
  name: '',
  newMembers: [],
  notes: [],
  peopleCount: 0,
  ticker: '',
  topGridStatusMember: undefined,
  twitterHandle: '',
  type: '',
};

export class Organization extends BaseModel<OrganizationProps> implements OrganizationProps {
  activeMemberCount = skeletonProps.activeMemberCount;
  annualRevenue = skeletonProps.annualRevenue;
  description = skeletonProps.description;
  domain = skeletonProps.domain;
  employeesRange = skeletonProps.employeesRange;
  facebookHandle = skeletonProps.facebookHandle;
  firstActive = skeletonProps.firstActive;
  firstMember = skeletonProps.firstMember;
  foundedYear = skeletonProps.foundedYear;
  fundsRaised = skeletonProps.fundsRaised;
  id = skeletonProps.id;
  industry = skeletonProps.industry;
  linkedinHandle = skeletonProps.linkedinHandle;
  location = skeletonProps.location;
  locationCity = skeletonProps.locationCity;
  locationState = skeletonProps.locationState;
  locationCountry = skeletonProps.locationCountry;
  memberCount = skeletonProps.memberCount;
  name = skeletonProps.name;
  newMembers = skeletonProps.newMembers;
  notes = skeletonProps.notes;
  peopleCount = skeletonProps.peopleCount;
  ticker = skeletonProps.ticker;
  topGridStatusMember = skeletonProps.topGridStatusMember;
  twitterHandle = skeletonProps.twitterHandle;
  type = skeletonProps.type;

  selected = false;

  constructor(props: OrganizationProps) {
    super();
    makeObservable(this, {
      activeMemberCount: observable,
      annualRevenue: observable,
      description: observable,
      domain: observable,
      employeesRange: observable,
      facebookHandle: observable,
      firstActive: observable,
      firstMember: observable,
      foundedYear: observable,
      fundsRaised: observable,
      id: observable,
      industry: observable,
      linkedinHandle: observable,
      location: observable,
      locationCity: observable,
      locationState: observable,
      locationCountry: observable,
      memberCount: observable,
      name: observable,
      newMembers: observable,
      notes: observable,
      peopleCount: observable,
      ticker: observable,
      topGridStatusMember: observable,
      twitterHandle: observable,
      type: observable,
    });
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

import { DaySelect, FilterMenuItem, FilterMenuSearch, Flex, MenuItem, Row, Select } from '@caravel/components/src';
import { SegmentRuleCondition, SegmentRuleConditionField } from '@caravel/types';
import {
  createGQLClient,
  GET_ORGANIZATION_NAMES,
  GET_PEOPLE_CHANNELS,
  GET_PEOPLE_CITIES,
  GET_PEOPLE_COUNTRIES,
  GET_PEOPLE_ORG_CITIES,
  GET_PEOPLE_ORG_COUNTRIES,
  GET_PEOPLE_ORG_INDUSTRIES,
  GET_PEOPLE_ORG_STATES,
  GET_PEOPLE_TITLES,
  GqlPeopleCitiesRequestType,
  GqlPeopleCitiesResponseType,
  makeDateString,
  parseDate,
} from '@caravel/utils';
import { Dialog, Divider, SvgIcon, Typography } from '@mui/material';
import { addYears } from 'date-fns';
import { DocumentNode } from 'graphql';
import { useDebounce } from 'helpers';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useStore } from 'stores';
import { generateYearOptions } from 'stores/filters';

import { ReactComponent as CalendarIcon } from '../../../assets/svgs/calendar-small.svg';
import { fieldOptionType } from './segment-rule';

export const ConditionFieldSelect = observer(
  (props: {
    condition: SegmentRuleCondition;
    handleOnConditionChange: (condition: Partial<SegmentRuleCondition>, conditionIndex: number) => void;
    conditionIndex: number;
  }) => {
    const store = useStore();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ name: string; id: string }[] | null>(null);
    const [search, setSearch] = useState('');
    const [daySelectOpen, setDaySelectOpen] = useState(false);
    const debouncedSearch = useDebounce(search, 500);

    const [pagePosition, setPagePosition] = useState(0);
    const [totalHits, setTotalHits] = useState(0);
    const DEFAULT_HITS_LENGTH = 50;
    const { condition, handleOnConditionChange, conditionIndex } = props;

    const updateQuery = useCallback(
      async (value: string, first: number, after: string) => {
        const query = (): DocumentNode | undefined => {
          switch (condition.field) {
            case 'organization':
              return GET_ORGANIZATION_NAMES;
            case 'title':
              return GET_PEOPLE_TITLES;
            case 'city':
              return GET_PEOPLE_CITIES;
            case 'country':
              return GET_PEOPLE_COUNTRIES;
            case 'source':
              return GET_PEOPLE_CHANNELS;
            case 'organization-industry':
              return GET_PEOPLE_ORG_INDUSTRIES;
            case 'organization-city':
              return GET_PEOPLE_ORG_CITIES;
            case 'organization-state':
              return GET_PEOPLE_ORG_STATES;
            case 'organization-country':
              return GET_PEOPLE_ORG_COUNTRIES;
            default:
              return undefined;
          }
        };
        if (!query()) return;
        setLoading(true);
        try {
          const { teamId, token } = await store.getTeamAndToken();
          const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);
          const response = await graphqlClient.query<GqlPeopleCitiesRequestType, GqlPeopleCitiesResponseType>(
            query()!,
            {
              query: value,
              first,
              after,
            },
          );
          const matchedOptions = response.community.peopleFacets.hits.map(hit => ({
            id: hit.value,
            name: hit.value,
          }));
          return {
            matchedOptions,
            totalHits: response.community.peopleFacets.totalHits,
          };
        } catch (e) {
          console.error(e);
          return {
            matchedOptions: [],
            totalHits: 0,
          };
        } finally {
          setLoading(false);
        }
      },
      [store, condition.field],
    );
    const loadMore = async () => {
      let first = DEFAULT_HITS_LENGTH;
      const after = pagePosition.toString();
      const currentSearch = search;
      if (options && totalHits - options.length < DEFAULT_HITS_LENGTH) {
        first = totalHits - options.length;
      }

      if (first <= 0) {
        return;
      }

      updateQuery(currentSearch, first, after).then(results => {
        if (!results) return;
        setTotalHits(results.totalHits);
        if (options) {
          setOptions(options.concat(results.matchedOptions));
          setPagePosition(results.matchedOptions.length + pagePosition);
        } else {
          setOptions(results.matchedOptions);
          setPagePosition(results.matchedOptions.length);
        }
      });
    };

    // Effect for API call
    useEffect(
      () => {
        updateQuery(debouncedSearch, DEFAULT_HITS_LENGTH, '0').then(results => {
          if (!results) return;
          setOptions(results.matchedOptions);
          setTotalHits(results.totalHits);
          setPagePosition(results.matchedOptions.length);
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [debouncedSearch], // Only call effect if debounced search term changes
    );

    useEffect(() => {
      updateQuery('', DEFAULT_HITS_LENGTH, '0').then(results => {
        if (!results) return;
        setOptions(results.matchedOptions);
        setTotalHits(results.totalHits);
        setPagePosition(results.matchedOptions.length);
      });
    }, [updateQuery]);

    const scrollHandler = (e: SyntheticEvent) => {
      if (e.currentTarget) {
        const target = e.currentTarget as Element;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && !loading) {
          loadMore();
        }
      }
    };

    const displayDynamicOptions = (): JSX.Element[] => {
      return (options || []).map(option => (
        <FilterMenuItem key={option.id} value={option.name}>
          {option.name}
        </FilterMenuItem>
      ));
    };

    const isMultiple =
      condition.operator === 'any-of' ||
      condition.operator === 'not-any-of' ||
      condition.operator === 'includes-any-of' ||
      condition.operator === 'includes-all-of' ||
      condition.operator === 'includes-none-of';

    const hasStaticOptions = !!valuesOptions[condition.field as keyof ValuesOptionsType];
    return (
      <Select
        multiple={isMultiple}
        SelectDisplayProps={{
          style: { fontSize: '13px' },
        }}
        MenuProps={{ PaperProps: { onScroll: scrollHandler }, style: { maxHeight: '50vh' } }}
        sx={{
          flex: 1,
          fontSize: '13px',
          marginRight: '10px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        size="small"
        value={condition.values}
        onChange={e => {
          handleOnConditionChange(
            {
              values: isMultiple ? [...(e.target.value as string[])] : [e.target.value as string],
            },
            conditionIndex,
          );
        }}
      >
        {!hasStaticOptions && (
          <FilterMenuItem
            sx={{
              minWidth: '250px',
              paddingLeft: '8px',
              '&.Mui-focusVisible': {
                backgroundColor: 'unset',
              },
            }}
          >
            <FilterMenuSearch
              value={search}
              onChange={e => setSearch(e.currentTarget.value)}
              loading={loading}
              autoFocus
            />
          </FilterMenuItem>
        )}
        {!hasStaticOptions && <Divider key="divider" />}
        {hasStaticOptions
          ? displayStaticOptions(valuesOptions[condition.field as keyof ValuesOptionsType])
          : displayDynamicOptions()}
        {fieldOptionType[condition.field] === 'timestamp' && <Divider key="divider" />}
        {fieldOptionType[condition.field] === 'timestamp' && (
          <FilterMenuItem onClick={() => setDaySelectOpen(true)}>
            <Row sx={{ alignItems: 'center' }}>
              <Flex
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <SvgIcon
                  component={CalendarIcon}
                  sx={{
                    fill: 'none',
                    width: '20px',
                    height: '20px',
                    marginTop: '1px',
                  }}
                />
              </Flex>
              <Typography variant="bodySmaller">Select a date</Typography>
            </Row>
          </FilterMenuItem>
        )}
        {fieldOptionType[condition.field] === 'timestamp' && (
          <Dialog
            open={daySelectOpen}
            onClose={() => {
              setDaySelectOpen(false);
            }}
            sx={{
              '& .MuiDialog-paper': {
                maxWidth: 'unset',
                width: 'auto',
                padding: 0,
              },
            }}
          >
            <DaySelect
              date={parseDate(condition.values.at(0)) ?? new Date()}
              calendar={parseDate(condition.values.at(0)) ?? new Date()}
              maxDate={new Date()}
              minDate={addYears(new Date(), -2)}
              onChange={d => {
                setDaySelectOpen(false);
                handleOnConditionChange({ values: [makeDateString(d)] }, conditionIndex);
              }}
              onChangeCalendar={d => {
                if (d) {
                  handleOnConditionChange({ values: [makeDateString(d)] }, conditionIndex);
                }
              }}
            />
          </Dialog>
        )}
        {/* dummy MenuItem to display selected date */}
        {fieldOptionType[condition.field] === 'timestamp' &&
          !timestampOptions.includes(condition.values.at(0) ?? '') && (
            <MenuItem sx={{ display: 'none' }} value={condition.values.at(0)}>
              {condition.values.at(0) ?? ''}
            </MenuItem>
          )}
        {/* dummy MenuItem to display selected dynamic options when they aren't loaded yet */}
        {condition.values &&
          condition.values.length > 0 &&
          !hasStaticOptions &&
          condition.values.map((value, index) => {
            if (!options?.map(option => option.name).includes(value)) {
              return (
                <MenuItem sx={{ display: 'none' }} key={index} value={value}>
                  {value}
                </MenuItem>
              );
            }
          })}
      </Select>
    );
  },
);

type Option = {
  value: string;
  display: string;
  type?: 'scalar' | 'seq' | 'timestamp';
};

const startCase = (camelCase: string) =>
  camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim();

const createOptions = (values: string[]) => {
  return values.map(value => ({
    value,
    display: value,
  }));
};

const displayStaticOptions = (options: Option[] | undefined) => {
  return (options || []).map(({ value, display }) => (
    <MenuItem key={value} value={value}>
      <Typography variant="bodySmaller">{display}</Typography>
    </MenuItem>
  ));
};

type ValuesOptionsType = {
  [key in SegmentRuleConditionField]?: Option[];
};

const timestampOptions = ['1 day ago', '1 week ago', '1 month ago', '3 months ago'];

const valuesOptions: ValuesOptionsType = {
  layer: createOptions(['Audience', 'Member', 'Internal']),
  seniority: createOptions(['Executive', 'Manager', 'Director']),
  'grid-status': createOptions([
    'Super User',
    'Contributor',
    'Potential',
    'Regular',
    'Neutral',
    'Observer',
    'Inactive',
  ]),
  frequency: createOptions(['Low', 'Medium', 'High']),
  consistency: createOptions(['Low', 'Medium', 'High']),
  impact: createOptions(['Low', 'Medium', 'High']),
  'first-active': createOptions(timestampOptions),
  'last-active': createOptions(timestampOptions),
  // 'signup-date': createOptions(timestampOptions),
  'organization-kind': createOptions(['Private', 'Public', 'Nonprofit', 'Education', 'Personal', 'Government']),
  'organization-employee-range': createOptions([
    '1-10',
    '11-50',
    '51-250',
    '251-1K',
    '1K-5K',
    '5K-10K',
    '10K-50K',
    '50K-100K',
    '100K+',
  ]),
  'organization-estimated-annual-revenue': createOptions([
    '$1M-$10M',
    '$10M-$50M',
    '$50M-$100M',
    '$100M-$250M',
    '$250M-$500M',
    '$500M-$1B',
    '$1B-$10B',
    '$10B+',
  ]),
  'organization-founded-year': createOptions(generateYearOptions()),
};

import React from 'react';

export const CalendarIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.95825 7.29156C3.95825 6.37108 4.70444 5.62488 5.62492 5.62488H14.3749C15.2954 5.62488 16.0416 6.37108 16.0416 7.29156V14.3749C16.0416 15.2954 15.2954 16.0416 14.3749 16.0416H5.62492C4.70444 16.0416 3.95825 15.2954 3.95825 14.3749V7.29156Z"
        stroke="#4A5980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 3.95825V6.87494"
        stroke="#4A5980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 3.95825V6.87494"
        stroke="#4A5980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.45825 8.95825H13.5416"
        stroke="#4A5980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SearchFilterIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4481 18.4478L14.8543 14.8541M4.55225 10.5416C4.55225 7.23363 7.23387 4.552 10.5418 4.552C13.8498 4.552 16.5314 7.23363 16.5314 10.5416C16.5314 13.8495 13.8498 16.5312 10.5418 16.5312C7.23387 16.5312 4.55225 13.8495 4.55225 10.5416Z"
        stroke="#4A5980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

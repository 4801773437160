import { BillingPlanId } from '@caravel/types';

export function isFreePlan(id?: BillingPlanId) {
  return id === 'free';
}

export function isBasicPlan(id?: BillingPlanId) {
  const targets: BillingPlanId[] = ['basic-1', 'basic-2', 'basic-3'];
  return id && targets.includes(id);
}

export function isTeamPlan(id?: BillingPlanId) {
  const targets: BillingPlanId[] = ['team-1', 'team-2', 'team-3'];
  return id && targets.includes(id);
}

export function isEnterprisePlan(id?: BillingPlanId) {
  return id === 'enterprise';
}

export function isUpgrade(from: BillingPlanId, to: BillingPlanId) {
  if (isFreePlan(from) && !isFreePlan(to)) {
    return true;
  }
  if (isBasicPlan(from) && (isTeamPlan(to) || isEnterprisePlan(to))) {
    return true;
  }
  if (isTeamPlan(from) && isEnterprisePlan(to)) {
    return true;
  }
  return false;
}

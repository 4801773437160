import { Column } from '@caravel/components/src';
import { NoResults } from '@caravel/components/src/no-results';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { AppToolbar } from '../app-toolbar';

export const SurveysList = observer(() => {
  const history = useHistory();

  return (
    <Column sx={{ height: '100%' }}>
      <AppToolbar
        title="Surveys"
        actionButton={{
          startIcon: <AddIcon />,
          onClick: () => history.push('/feedback/new'),
          text: 'New Survey',
        }}
      />
      <NoResults
        title="No Surveys"
        message="Create a survey to begin collecting feedback"
        buttonText="Create Survey"
        buttonIcon={<AddIcon />}
        onButtonClick={() => history.push('/feedback/new')}
      />
    </Column>
  );
});

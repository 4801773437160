import { FilterButton, FilterProps, InlineConditionsMenu, InlineValueMenu, Row } from '@caravel/components/src';
import { CloseButton } from '@caravel/components/src/filters/close-button';
import { CONDITION_LABELS, GREY_PALETTE, isSingleCondition, upperFirst } from '@caravel/utils';
import React, { useCallback, useRef, useState } from 'react';

export const InlineFilter = (
  props: FilterProps & {
    anchorRef: React.RefObject<HTMLButtonElement>;
    refreshCollection: () => void;
    updateDynamicFilterQuery: (
      collectionType: string,
      type: string,
      value: string,
      first: number,
      after: string,
    ) => Promise<{ matchedOptions: { id: string; name: string }[]; totalHits: number }>;
    collectionType: string | undefined;
  },
) => {
  const { anchorRef, refreshCollection, updateDynamicFilterQuery, collectionType, ...filterProps } = props;
  const [conditionAnchorEl, setConditionAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [valueAnchorEl, setValueAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [initialValuesSet, setInitialValuesSet] = useState(filterProps.value.length > 0);

  const conditionsRef = useRef<HTMLButtonElement>(null);
  const valuesRef = useRef<HTMLButtonElement>(null);

  const conditionsOpen = Boolean(conditionAnchorEl);
  const valuesOpen = Boolean(valueAnchorEl);

  const onOpenCondition = (e: React.MouseEvent<HTMLButtonElement>) => {
    setConditionAnchorEl(e.currentTarget);
  };
  const onOpenValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValueAnchorEl(e.currentTarget);
  };

  const displayValue = useCallback(() => {
    if (filterProps.value.length === 1) {
      return `${filterProps.value[0]}`;
    } else {
      return `${filterProps.value.length} ${
        filterProps.value.length === 1 ? filterProps.label.toLowerCase() : filterProps.pluralized
      }`;
    }
  }, [filterProps.label, filterProps.pluralized, filterProps.value]);

  // when this component first mounts and there is no condition value selected, open the condition menu
  // by anchoring to the anchorRef prop
  if (!filterProps.condition) {
    return (
      <InlineConditionsMenu
        {...filterProps}
        anchorEl={anchorRef.current}
        open={true}
        onClickFilter={next => filterProps.onChangeFilter(next, true)}
        onClose={filterProps.onRemoveFilter}
      />
    );
  }

  // when this component first mounts and there is no value selected, open the value menu
  // by anchoring to the anchorRef prop
  if (!initialValuesSet) {
    return (
      <InlineValueMenu
        {...filterProps}
        updateDynamicFilterQuery={updateDynamicFilterQuery}
        collectionType={collectionType}
        anchorEl={anchorRef.current}
        open={true}
        onChangeFilter={(next, skipRefresh) => {
          if (isSingleCondition(filterProps.condition)) {
            // if the condition is not multi, we want to close the menu
            filterProps.onChangeFilter(next, false);
            setInitialValuesSet(true);
          } else filterProps.onChangeFilter(next, skipRefresh);
        }}
        onClose={() => {
          if (filterProps.value.length === 0) {
            filterProps.onRemoveFilter();
          } else {
            refreshCollection();
            setInitialValuesSet(true);
          }
        }}
        onCloseDateMenu={() => setInitialValuesSet(true)}
      />
    );
  }

  // when this component first mounts and there is a value selected, render values inline.
  // condition and value menus should be closed and when opened should anchor to either the condition or value button
  return (
    <Row
      alignItems="center"
      sx={{
        height: '32px',
        padding: '0 5px',
        border: `1px solid ${GREY_PALETTE[5]}`,
        borderRadius: '4px',
      }}
    >
      <FilterButton label={filterProps.label ?? upperFirst(filterProps.type)} sx={{ pointerEvents: 'none' }} />
      <FilterButton
        ref={conditionsRef}
        label={CONDITION_LABELS[filterProps.condition]}
        onClick={onOpenCondition}
        sx={{
          borderRadius: 0,
          backgroundColor: conditionsOpen ? GREY_PALETTE[2] : 'transparent',
          color: conditionsOpen ? GREY_PALETTE[5] : GREY_PALETTE[4],
        }}
      />
      <InlineConditionsMenu
        {...filterProps}
        anchorEl={conditionAnchorEl}
        open={conditionsOpen}
        onClickFilter={nextFilter => {
          const skipRefresh = Boolean(nextFilter.condition === filterProps.condition);
          filterProps.onChangeFilter(nextFilter, skipRefresh);
          setConditionAnchorEl(null);
        }}
        onClose={() => {
          setConditionAnchorEl(null);
        }}
      />
      <FilterButton
        ref={valuesRef}
        label={displayValue() ?? ''}
        onClick={onOpenValue}
        sx={{
          borderRadius: 0,
          backgroundColor: valuesOpen ? GREY_PALETTE[2] : 'transparent',
          marginRight: '5px',
        }}
      />
      <InlineValueMenu
        {...filterProps}
        updateDynamicFilterQuery={updateDynamicFilterQuery}
        collectionType={collectionType}
        anchorEl={valueAnchorEl}
        open={valuesOpen}
        onClose={() => {
          setValueAnchorEl(null);
          refreshCollection();
        }}
        onCloseDateMenu={() => {
          setValueAnchorEl(null);
          refreshCollection();
        }}
      />
      <CloseButton
        onClose={() => {
          filterProps.onRemoveFilter();
        }}
      />
    </Row>
  );
};

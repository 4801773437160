import { gql } from '@apollo/client';

export const GET_ICP_SEGMENT = gql`
  query getICPSegment {
    community {
      id
      segments(
        first: 50
        after: "0"
        filterBy: { facets: [{ name: "kind", type: IS, values: [":segment.kind/icp"] }] }
      ) {
        edges {
          cursor
          node {
            id
            name
            kind
            peopleCount
            segmentRules {
              conditions {
                field
                operator
                values
              }
              interConditionOperator
              interRuleOperator
            }
            peoplePaginated(
              first: 50
              after: "0"
              orderBy: { direction: DESC, field: "lastActive" }
              filterBy: { facets: [] }
            ) {
              edges {
                cursor
                node {
                  id
                  givenName
                  customerFit
                }
              }
            }
          }
        }
      }
    }
  }
`;

import { Organization, Person as PersonProps } from '@caravel/types';
import { makeInitials } from '@caravel/utils';
import { computed, makeObservable, observable } from 'mobx';
import { BaseModel, makeToJS, makeUpdate } from 'models/base';

const skeletonProps: PersonProps = {
  activities: undefined,
  avatar: undefined,
  biography: undefined,
  canReceiveReferences: undefined,
  city: undefined,
  country: undefined,
  connectedTo: [],
  customAttributes: [],
  customerFit: undefined,
  email: undefined,
  emails: [],
  employmentSeniority: undefined,
  employmentTitle: undefined,
  engagement: undefined,
  facebookHandle: undefined,
  familyName: undefined,
  firstSeen: undefined,
  githubFollowers: undefined,
  githubFollowing: undefined,
  githubHandle: undefined,
  givenName: undefined,
  id: undefined,
  lastSeen: undefined,
  linkedinHandle: undefined,
  name: undefined,
  notes: [],
  primaryOrganization: undefined,
  organizations: [],
  personas: [],
  position: undefined,
  referenceCount: undefined,
  references: undefined,
  state: undefined,
  swagupId: undefined,
  tags: [],
  twitterFollowers: undefined,
  twitterFollowing: undefined,
  twitterHandle: undefined,
  website: undefined,
};

export class Person extends BaseModel<PersonProps> implements PersonProps {
  activities = skeletonProps.activities;
  avatar = skeletonProps.avatar;
  biography = skeletonProps.biography;
  canReceiveReferences = skeletonProps.canReceiveReferences;
  city = skeletonProps.city;
  country = skeletonProps.country;
  connectedTo = skeletonProps.connectedTo;
  customAttributes = skeletonProps.customAttributes;
  customerFit = skeletonProps.customerFit;
  email = skeletonProps.email;
  emails = skeletonProps.emails;
  employmentSeniority = skeletonProps.employmentSeniority;
  employmentTitle = skeletonProps.employmentTitle;
  engagement = skeletonProps.engagement;
  facebookHandle = skeletonProps.facebookHandle;
  familyName = skeletonProps.familyName;
  firstSeen = skeletonProps.firstSeen;
  githubFollowers = skeletonProps.githubFollowers;
  githubFollowing = skeletonProps.githubFollowing;
  githubHandle = skeletonProps.githubHandle;
  givenName = skeletonProps.givenName;
  id = skeletonProps.id;
  lastSeen = skeletonProps.lastSeen;
  linkedinHandle = skeletonProps.linkedinHandle;
  name = skeletonProps.name;
  notes = skeletonProps.notes;
  primaryOrganization = skeletonProps.primaryOrganization;
  organizations = skeletonProps.organizations;
  personas = skeletonProps.personas;
  position = skeletonProps.position;
  referenceCount = skeletonProps.referenceCount;
  references = skeletonProps.references;
  state = skeletonProps.state;
  swagupId = skeletonProps.swagupId;
  tags = skeletonProps.tags;
  twitterFollowers = skeletonProps.twitterFollowers;
  twitterFollowing = skeletonProps.twitterFollowing;
  twitterHandle = skeletonProps.twitterHandle;
  website = skeletonProps.website;

  selected = false;

  get organization(): Organization | undefined {
    return this.primaryOrganization;
  }

  get organizationIcon(): string | undefined {
    if (!this.organization) {
      return;
    }

    return `https://logo.clearbit.com/${this.organization.domain}`;
  }

  get lastActive(): string | undefined {
    return this.activities?.edges.at(0)?.node.occurredAt;
  }

  get gridStatus(): string | undefined {
    return this.engagement?.gridStatus;
  }

  get initials(): string {
    return makeInitials({ givenName: this.givenName, familyName: this.familyName, name: this.name });
  }

  constructor(props: PersonProps) {
    super();
    makeObservable(this, {
      activities: observable,
      canReceiveReferences: observable,
      avatar: observable,
      biography: observable,
      city: observable,
      country: observable,
      connectedTo: observable,
      customAttributes: observable,
      email: observable,
      emails: observable,
      employmentSeniority: observable,
      employmentTitle: observable,
      engagement: observable,
      facebookHandle: observable,
      familyName: observable,
      firstSeen: observable,
      githubFollowers: observable,
      githubFollowing: observable,
      githubHandle: observable,
      givenName: observable,
      id: observable,
      lastSeen: observable,
      linkedinHandle: observable,
      name: observable,
      notes: observable,
      primaryOrganization: observable,
      organizations: observable,
      personas: observable,
      position: observable,
      referenceCount: observable,
      references: observable,
      state: observable,
      swagupId: observable,
      tags: observable,
      twitterFollowers: observable,
      twitterFollowing: observable,
      twitterHandle: observable,
      website: observable,
      selected: observable,
      organization: computed,
      organizationIcon: computed,
      lastActive: computed,
    });
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

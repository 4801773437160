import { Row } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { GREY_PALETTE } from '@caravel/utils';
import { Box, Button, Modal, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import React, { useState } from 'react';

import { SliderSwitch } from '../slider-switch';
import { InfoIcon } from './champions-icons';

const PublicReferenceTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2A385B',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2A385B',
    color: GREY_PALETTE[1],
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const AddChampionsModal = (props: {
  handleClose: () => void;
  handleAddChampions: (value: string, canReceiveReferences: boolean) => void;
  open: boolean;
}) => {
  const { handleClose, handleAddChampions, open } = props;
  const [value, setValue] = useState<string>('');
  const [canReceiveReferences, setCanReceiveReferences] = useState<boolean>(false);
  return (
    <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        sx={{
          background: 'white',
          width: '700px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '35px',
          paddingBottom: '35px',
          gap: '10px',
        }}
      >
        <Typography variant="headline2" sx={{ fontWeight: '500' }}>
          Add Champions
        </Typography>
        <FormInput
          sx={{
            width: '100%',
            marginTop: '10px',
          }}
          multiline
          rows={4}
          placeholder={'i.e. steggie.saurus@salesforce.com, chompy.charlie@loom.com'}
          onChange={e => setValue(e.target.value)}
          label="Email addresses"
        />
        <Row sx={{ gap: '4px' }}>
          <Typography variant="bodySmall" sx={{ color: GREY_PALETTE[5], paddingLeft: '20px' }} component="ul">
            <li>Add champions using email - separate multiple email addresses using commas</li>
            <li>The app will enrich people's profiles automatically – pulling in their name and other useful data</li>
            <li>No email notifications will be sent</li>
          </Typography>
        </Row>
        <Row sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <Typography variant="bodySmall" sx={{ color: GREY_PALETTE[7] }}>
              Opt in champions into public reference
            </Typography>
            <PublicReferenceTooltip
              title={
                <Box sx={{ padding: '4px' }}>
                  <Typography variant="subtitle1" sx={{ color: GREY_PALETTE[1], marginBottom: '10px' }}>
                    Public Reference
                  </Typography>
                  <Typography variant="bodySmall" sx={{ color: GREY_PALETTE[2] }}>
                    Create a public link so that anyone can be matched with the perfect customer champion.
                  </Typography>
                </Box>
              }
              arrow
            >
              <SvgIcon sx={{ color: GREY_PALETTE[5], width: '20px', height: '20px' }}>
                <InfoIcon />
              </SvgIcon>
            </PublicReferenceTooltip>
          </Box>

          <SliderSwitch
            checked={canReceiveReferences}
            onChange={e => {
              e.currentTarget.checked ? setCanReceiveReferences(true) : setCanReceiveReferences(false);
            }}
          />
        </Row>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-end',
            gap: '8px',
          }}
        >
          <Button variant="contained" color="tertiary" sx={{ minWidth: '80px' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ minWidth: '80px' }}
            onClick={() => handleAddChampions(value, canReceiveReferences)}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

import {
  Column,
  Flex,
  FormLabel,
  FormLabelDescription,
  NarrowColumn,
  PageHeader,
  Row,
  TextField,
} from '@caravel/components/src';
import { Button, Checkbox, Divider, FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { SliderSwitch } from '../slider-switch';

export interface SourceFormTabProps {
  submitText?: string;
  submitDisabled?: boolean;
  onSubmit: () => void;
}

export interface SetupTabProps extends SourceFormTabProps {
  sourceId?: string;
  name: string;
  onChangeName: (name: string) => void;
  description: string;
  onChangeDescription: (description: string) => void;
  isActive: boolean;
  onChangeIsActive: (isActive: boolean) => void;
  deidentifyRequired: boolean;
  onChangeDeidentifyRequired: (deidentifyRequired: boolean) => void;
  cleanHtml: boolean;
  onChangeCleanHtml: (cleanHtml: boolean) => void;
}

export const SetupTab = observer((props: SetupTabProps) => {
  const {
    sourceId,
    submitText = 'Create',
    submitDisabled = false,
    onSubmit,
    name,
    onChangeName,
    description,
    onChangeDescription,
    isActive,
    onChangeIsActive,
    deidentifyRequired,
    onChangeDeidentifyRequired,
    cleanHtml,
    onChangeCleanHtml,
  } = props;
  const store = useStore();

  return (
    <NarrowColumn>
      <PageHeader headline="Setup" />
      <Divider />

      <Column sx={{ marginTop: '20px', paddingTop: '20px' }}>
        <FormLabel sx={{ marginBottom: '10px' }}>Name</FormLabel>
        <TextField
          autoFocus
          placeholder="Your form name"
          value={name}
          onChange={e => onChangeName(e.target.value)}
          inputProps={{
            sx: {
              boxSizing: 'border-box',
              fontSize: 14,
              height: 48,
            },
          }}
        />
      </Column>

      <Column sx={{ marginTop: '30px' }}>
        <FormLabel sx={{ marginBottom: '10px' }}>Description</FormLabel>
        <TextField
          placeholder="Your form description"
          multiline
          minRows={6}
          value={description}
          onChange={e => onChangeDescription(e.target.value)}
          inputProps={{
            sx: {
              height: 'unset',
            },
          }}
        />
      </Column>

      <Column sx={{ marginTop: '30px' }}>
        <Row alignItems="center">
          <Column flex={1}>
            <FormLabel>Active</FormLabel>
            <FormLabelDescription>Toggle on/off the real-time classification of this form.</FormLabelDescription>
          </Column>
          <SliderSwitch checked={isActive} onChange={e => onChangeIsActive(e.target.checked)} />
        </Row>
      </Column>

      <Divider sx={{ marginTop: '30px' }} />
      <Column sx={{ marginTop: '30px' }}>
        <FormLabel>Options</FormLabel>
        <FormLabelDescription />
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
            },
          }}
          label="Redact sensitive information"
          control={
            <Checkbox
              checked={deidentifyRequired}
              color="primary"
              onChange={e => onChangeDeidentifyRequired(e.target.checked)}
              name="deidentify-option"
            />
          }
        />
        {store.members.current?.isSuper && (
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '14px',
              },
            }}
            label="Clean HTML content"
            control={
              <Checkbox
                checked={cleanHtml}
                color="primary"
                onChange={e => onChangeCleanHtml(e.target.checked)}
                name="clean-html-option"
              />
            }
          />
        )}
      </Column>

      {!sourceId && (
        <>
          <Divider sx={{ marginTop: theme => theme.spacing(4) }} />
          <Row sx={{ margin: theme => theme.spacing(4, 0) }}>
            <Flex flex={1} />
            <Button disabled={submitDisabled} variant="contained" onClick={onSubmit}>
              {submitText}
            </Button>
          </Row>
        </>
      )}
    </NarrowColumn>
  );
});

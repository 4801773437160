import { MemberRoles } from '@caravel/types';
import { REMOVE_TEAM_MEMBER, RESEND_INVITE } from '@caravel/utils';
import { deleteDoc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Logger, useStoreMount } from 'helpers';
import { observer } from 'mobx-react';
import { MEMBER_ROLES_WEIGHT } from 'models';
import React from 'react';
import { useStore } from 'stores';

import { Sortable } from './teammates-table-head-view';
import { TeammatesRowProps, TeammatesView } from './teammates-view';

const { debug } = new Logger('team-editor-container');

export const TeammatesContainer = observer(() => {
  const store = useStore();
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<Sortable>('name');
  const teamMembers = store.teams.teamMembers.collection.slice();
  const currentMember = store.members.current;
  const currentMemberCanWrite = Boolean(currentMember?.canWrite);

  useStoreMount(store, [store.teams.invites]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: Sortable) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const canChangeRole = (prevRole: MemberRoles, targetRole: MemberRoles) => {
    if (!currentMember || !currentMember.role || !currentMemberCanWrite) {
      return false;
    }
    if (prevRole === targetRole) {
      return false;
    }
    // Current member cannot promote anyone to a level higher than themself
    // Current member cannot demote anyone starting higher than themself
    const currentMemberRoleWeight = MEMBER_ROLES_WEIGHT[currentMember.role];
    if (
      MEMBER_ROLES_WEIGHT[targetRole] > currentMemberRoleWeight ||
      MEMBER_ROLES_WEIGHT[prevRole] > currentMemberRoleWeight
    ) {
      return false;
    }
    return true;
  };

  const onChangeRole = async (row: TeammatesRowProps, role: MemberRoles) => {
    const { inviteId } = row;
    if (inviteId) {
      if (!canChangeRole(row.role, role)) {
        store.notifications.displayError('Permission denied');
        return;
      }
      await store.ui.workOn(async () => {
        try {
          await updateDoc(store.teams.invites.get(inviteId)!.docRef, { role });
          store.notifications.displaySuccess('Invite updated');
        } catch (e) {
          store.notifications.displayError('Permission denied');
        }
      });
      return;
    }
    const teamMember = store.teams.teamMembers.get(row.id);
    if (!teamMember) {
      return;
    }
    debug('onChangeRole', teamMember, role);
    const currRole = teamMember.role;
    if (!canChangeRole(currRole, role)) {
      return;
    }
    try {
      await store.ui.workOn(async () => {
        store.teams.teamMembers.get(teamMember.id)?.update({ role });
        await store.teams.teamMembers.changeRole(teamMember, role);
      });

      //Segment Analytics
      analytics.track(
        'Workspace Role Updated',
        {
          new_role: role,
          old_role: currRole,
          user_name: teamMember.name,
          groupId: store.teams.currentTeam!.id,
        },
        {
          context: {
            groupId: store.teams.currentTeam!.id,
          },
        },
      );

      store.notifications.display({
        message: 'Team member updated',
        severity: 'success',
        duration: 3000,
      });
    } catch (error) {
      store.teams.teamMembers.get(teamMember.id)?.update({ role: currRole });
      store.notifications.display({
        message: 'Permission denied',
        severity: 'error',
        duration: 5000,
      });
    }
  };

  const onRemoveTeamMember = async (row: TeammatesRowProps) => {
    const teamId = store.team?.id;
    if (!teamId) {
      throw new Error('Expected to have access to current team');
    }
    const { inviteId } = row;
    if (inviteId) {
      // This row is an invite, delete doc ref
      await store.ui.workOn(async () => {
        try {
          const inviteIdRef = store.teams.invites.get(inviteId);
          if (inviteIdRef) {
            await deleteDoc(inviteIdRef.docRef);

            //Segment Analytics
            analytics.group(teamId, {
              groupId: teamId,
              pending_invites: store.teams.invites.collection.slice().length,
            });
            analytics.track(
              'Workspace Removed Invite',
              {
                invitee_email: row.email,
                groupId: teamId,
              },
              {
                context: {
                  groupId: teamId,
                },
              },
            );

            store.notifications.display({ message: 'Removed invitation', severity: 'info', duration: 3000 });
          }
        } catch (e) {
          store.notifications.display({ message: 'Permission denied', severity: 'error', duration: 5000 });
        }
      });
      return;
    }
    const teamMember = store.teams.teamMembers.get(row.id);
    if (!teamMember) {
      return;
    }
    const removeTeamMember = httpsCallable(getFunctions(), REMOVE_TEAM_MEMBER);
    await store.ui.workOn(async () => {
      try {
        await removeTeamMember({
          memberId: teamMember.id,
          teamId,
        });

        //Segment Analytics
        analytics.group(teamId, {
          groupId: teamId,
          employees: store.teams.teamMembers.collection.slice().length,
        });
        analytics.track(
          'Workspace Removed User',
          {
            user_name: teamMember.name,
            groupId: teamId,
          },
          {
            context: {
              groupId: teamId,
            },
          },
        );

        store.notifications.display({
          message: 'Removed team member',
          severity: 'success',
          duration: 3000,
        });
      } catch (e) {
        store.notifications.display({
          severity: 'error',
          message: 'Permission denied',
          duration: 5000,
        });
      }
    });
  };

  const onInviteWithEmail = async (email: string): Promise<boolean> => {
    try {
      await store.ui.workOn(async () => {
        await store.teams.invites.inviteWithEmail(email);
      });

      analytics.group(store.team!.id, {
        groupId: store.team?.id,
        employees: store.teams.teamMembers.collection.slice().length,
        pending_invites: store.teams.invites.collection.slice().length,
      });
      analytics.track(
        'Workspace Invited User',
        {
          invitee_email: email,
          groupId: store.team?.id,
        },
        {
          context: {
            groupId: store.team?.id,
          },
        },
      );

      store.notifications.display({
        message: 'Invite sent!',
        severity: 'success',
        duration: 3000,
      });
      return true;
    } catch (error: any) {
      if (error.message === 'Already a team member') {
        store.notifications.display({
          message: 'Teammate is already a member of your workspace.',
          severity: 'error',
          duration: 5000,
        });
      } else {
        store.notifications.display({
          message: 'Try again later',
          severity: 'error',
          duration: 5000,
        });
      }
      return false;
    }
  };

  const onResendInvite = async (inviteId: string) => {
    const teamId = store.team?.id;
    if (!teamId) {
      throw new Error('Expected to have access to current team');
    }
    const resendInvite = httpsCallable(getFunctions(), RESEND_INVITE);
    try {
      await store.ui.workOn(async () => {
        const result = await resendInvite({ teamId, inviteId });
        if (result && (result.data as any).ok) {
          store.notifications.display({
            message: 'Invite sent!',
            severity: 'success',
            duration: 3000,
          });
        } else {
          store.notifications.display({
            message: "Couldn't resend invite 🧐",
            severity: 'error',
            duration: 5000,
          });
        }
      });
    } catch (e) {
      debug('Failed to resend email');
    }
  };

  const rows: TeammatesRowProps[] = teamMembers.map(t => {
    return {
      id: t.id,
      name: t?.name,
      role: t.role,
      photo: t?.photoUrl,
      email: t.email,
      working: t.working,
    };
  });

  for (const invite of store.teams.invites.collection.slice()) {
    rows.push({
      id: invite.id,
      inviteId: invite.id,
      name: 'Pending invite',
      role: invite.role || 'reader',
      photo: undefined,
      email: invite.email,
      working: false,
    });
  }

  return (
    <TeammatesView
      canWrite={currentMemberCanWrite}
      rows={rows}
      order={order}
      orderBy={orderBy}
      onChangeRole={onChangeRole}
      onRemoveTeamMember={onRemoveTeamMember}
      onRequestSort={handleRequestSort}
      onResendInvite={onResendInvite}
      onInviteWithEmail={onInviteWithEmail}
    />
  );
});

export default TeammatesContainer;

import { Logger, useAppReadyCheck, useStoreMount } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

import { ComposerDetail, ComposerList, NewComposer } from '../composers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('composer-routes');

export const ComposerRoutes: FC = observer(() => {
  const store = useStore();
  useStoreMount(store, [store.teams.composers]);

  if (!useAppReadyCheck(store)) {
    return null;
  }

  return (
    <Switch>
      <Route path="/composer/new" component={NewComposer} />
      <Route path="/composer/:composerId" component={ComposerDetail} />
      <Route path="/composer" component={ComposerList} />
    </Switch>
  );
});

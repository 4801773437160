import { SEND_HUB_EVENT } from '@caravel/utils';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Disposer, Logger, reactTo } from 'helpers';

import { AnalyticsStore } from './analytics';
import { AuthStore } from './auth';
import { FeaturesStores } from './features';
import { MemberStore } from './members';
import { TeamStore } from './teams';
import { UIStore } from './ui';

const { debug } = new Logger('root-store');

/**
 * The root data store
 */
export class RootStore extends Disposer {
  debugName = 'RootStore';

  readonly auth = new AuthStore(this);
  readonly features = new FeaturesStores(this);
  readonly members = new MemberStore(this);
  readonly teams = new TeamStore(this);
  readonly ui = new UIStore(this);
  readonly analytics = new AnalyticsStore(this);

  get team() {
    return this.teams.currentTeam;
  }

  get notifications() {
    return this.teams.notifications;
  }

  getToken = async () => {
    const token = await this.auth.user?.getIdToken();
    const userId = this.auth.user?.uid;

    if (!token || !userId) {
      throw new Error('Expected to be logged in');
    }

    return { token, userId };
  };

  getTeam = () => {
    const teamId = this.team?.id;
    if (!teamId) {
      throw new Error('Expected to have access to current team');
    }
    return teamId;
  };

  getTeamAndToken = async () => {
    const token = await this.auth.user?.getIdToken();
    const teamId = this.team?.id;
    const userId = this.auth.user?.uid;

    if (!token || !userId) {
      throw new Error('Expected to be logged in');
    }

    if (!teamId) {
      throw new Error('Expected to have access to current team');
    }

    return { teamId, token, userId };
  };

  async setup() {
    await super.setup();
    await this.auth.initialize();
    await this.ui.initialize();
    await this.members.initialize();
    await this.features.initialize();
    await this.teams.initialize();

    this.addDisposable(this.reactToAuthUser());
    this.addDisposable(this.reactToTeam());
  }

  teardown() {
    this.features.teardown();
    this.teams.teardown();
    this.members.teardown();
    this.ui.teardown();
    this.auth.teardown();
    super.teardown();
  }

  sendHubEvent = async (actionText: string, extras?: Record<string, string>) => {
    try {
      const sendHubEvent = httpsCallable(getFunctions(), SEND_HUB_EVENT);
      const teamId = this.team?.id || 'n/a';
      const teamName = this.team?.displayName || 'n/a';
      const memberId = this.members.current?.id || 'n/a';
      const memberName = this.members.current?.name || 'n/a';
      const memberEmail = this.members.current?.email || 'n/a';
      debug('Hub event:', { teamId, teamName, memberId, memberName, memberEmail, actionText, extras });
      if (this.features.hasHubEvents) {
        await sendHubEvent({ teamId, teamName, memberId, memberName, memberEmail, actionText, extras });
      }
    } catch {
      console.debug('Could not send hub event');
    }
  };

  private reactToAuthUser = () =>
    reactTo(
      () => this.auth.user,
      async user => {
        if (user) {
          this.members.canInitalize && (await this.members.initialize());
          this.teams.canInitalize && (await this.teams.initialize());
        } else {
          this.teams.teardown();
          this.members.teardown();
        }
      },
      'setup-teardown-auth-dependent-stores',
    );

  private reactToTeam = () =>
    reactTo(
      () => this.team,
      async team => {
        if (team !== undefined) {
          this.features.setTeamFeatures(team.id);
        }
      },
      'update-team-dependent-stores',
    );
}

import { GREY_PALETTE, WARM_SHADES_PALETTE } from '@caravel/utils';
import { MenuItem, Select, Typography } from '@mui/material';
import { EditorState, Modifier } from 'draft-js';
import React from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';

import { Column } from '../flex';
import { FormLabel } from './form-label';

interface CustomOptionProps {
  onChange?: (editorState: EditorState) => void;
  editorState: EditorState;
}

interface PushTextOptionProps extends CustomOptionProps {
  label: string;
  textOptions: { label: string; value: string }[];
}

export const PushTextOption = (props: PushTextOptionProps) => {
  const { editorState, onChange, label, textOptions } = props;
  const addCustomText = (text: string): void => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle(),
    );
    const result = EditorState.push(editorState, contentState, 'insert-characters');
    if (onChange) {
      onChange(result);
    }
  };

  return (
    <Select
      MenuProps={{
        PaperProps: {
          sx: {
            '& .MuiList-root': {
              '& .MuiMenuItem-root': {
                fontSize: '13px',
                padding: '4px 10px',
              },
              '& .MuiButtonBase-root': {
                '&:hover': {
                  backgroundColor: GREY_PALETTE[2],
                },
              },
            },
          },
        },
      }}
      sx={{
        color: GREY_PALETTE[5],
        marginLeft: '4px',
        marginRight: '4px',
        '& .MuiSelect-select': {
          padding: '4px 4px',
        },
        '& .MuiInputBase-input': {
          fontSize: '13px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: GREY_PALETTE[4],
        },
      }}
      value={label}
    >
      <MenuItem value={label} disabled sx={{ display: 'none' }}>
        {label}
      </MenuItem>
      {textOptions.map((option, key) => {
        return (
          <MenuItem key={key} onClick={() => addCustomText(option.value)} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

/*
 * Default styles and props for editor.
 * If wrapperStyle, toolbarStyle, and/or editorStyle are provided
 * Editor will use those styles instead.
 */
const defaultEditorProps = {
  wrapperStyle: {
    border: `1px solid ${GREY_PALETTE[3]}`,
    borderRadius: '4px',
  },
  toolbarStyle: {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    maxHeight: '52px',
    flexDirection: 'row',
    padding: '8px 12px 8px 12px',
    borderBottom: `1px solid ${GREY_PALETTE[3]}`,
    background: GREY_PALETTE[1],
  },
  editorStyle: {
    minHeight: '146px',
    height: 'fit-content',
    padding: '12px 16px 12px 16px',
  },
  toolbar: {
    options: ['inline', 'link', 'list', 'blockType'],
    inline: {
      options: ['bold', 'italic'],
      bold: { className: `toolbarItem` },
      italic: { className: `toolbarItem` },
      className: `toolbarGroupInline`,
    },
    link: {
      inDropdown: false,
      options: ['link'],
      className: `toolbarGroupLink`,
      link: { className: `toolbarItem` },
      linkCallback: (link: any) => {
        return { title: link.title, target: link.target, targetOption: '_blank' };
      },
    },
    list: {
      inDropdown: false,
      options: ['unordered', 'ordered'],
      className: `toolbarGroupList`,
      unordered: { className: `toolbarItem` },
      ordered: { className: `toolbarItem` },
    },
    blockType: {
      inDropdown: false,
      options: ['H1', 'H2', 'H3'],
      className: `toolbarGroupBlockType`,
      H1: { className: `toolbarItem` },
      H2: { className: `toolbarItem` },
      H3: { className: `toolbarItem` },
    },
  },
};

export interface FormEditorInputProps extends EditorProps {
  description?: string;
  label?: string;
  required?: boolean;
}
/*
 * Wrapper for Editor Class with additional customizations
 * Use the 'toolbarCustomButtons' prop from 'EditorProps' to pass custom options
 */
export const FormEditorInput = (props: FormEditorInputProps) => {
  const wrapperStyle = props.wrapperStyle ? props.wrapperStyle : defaultEditorProps.wrapperStyle;
  const toolbarStyle = props.toolbarStyle ? props.toolbarStyle : defaultEditorProps.toolbarStyle;
  const editorStyle = props.editorStyle ? props.editorStyle : defaultEditorProps.editorStyle;
  const toolbar = props.toolbar ? props.toolbar : defaultEditorProps.toolbar;
  return (
    <Column
      sx={{
        gap: '10px',
        '& .toolbarItem': {
          height: '24px',
          width: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'grey',
          cursor: 'pointer',
          border: 'none',
          background: 'none',
          outline: 'none',
          '&:hover,&:focus': {
            backgroundColor: GREY_PALETTE[2],
            borderRadius: '4px',
          },
        },
        '& .toolbarGroupInline': {
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '16px',
          paddingRight: '12px',
        },
        '& .toolbarGroupLink': {
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '16px',
          position: 'relative',
          '& .rdw-option-wrapper': {
            height: '24px',
            width: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            outline: 'none',
            '&:hover,&:focus': {
              backgroundColor: GREY_PALETTE[2],
              borderRadius: '4px',
            },
          },
          '& .rdw-link-modal': {
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            position: 'absolute',
            backgroundColor: 'white',
            minWidth: '290px',
            transform: 'translate(0px, 35px)',
            boxShadow: '0px 8px 16px rgba(35, 31, 32, 0.24)',
            borderRadius: '5px',
            padding: '12px 16px',
            zIndex: '1',
            '& .rdw-link-modal-target-option': {
              display: 'none',
            },
            '& .rdw-link-modal-buttonsection': {
              display: 'flex',
              gap: '5px',
            },
            '& .rdw-link-modal-input': {
              border: '1px solid #8B99B5',
              borderRadius: '4px',
              height: '32px',
              padding: '0px 16px',
              fontFamily: 'Inter',
              fontSize: '13px',
              lineHeight: '13px',
              fontWeight: 'normal',
            },
            '& .rdw-link-modal-btn': {
              fontFamily: 'Inter',
              fontSize: '13px',
              lineHeight: '13px',
              fontWeight: 'normal',
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '8px 12px 8px 12px',
              border: `1px solid ${GREY_PALETTE[7]}`,
            },
          },
        },
        '& .toolbarGroupList': {
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0 12px 0 16px',
          borderRight: '1px solid black',
          gap: '16px',
        },
        '& .toolbarGroupBlockType': {
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0 12px 0 12px',
          borderRight: '1px solid black',
          gap: '16px',
          '& .rdw-option-wrapper': {
            display: 'flex',
            height: '24px',
            width: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            outline: 'none',
            '&:hover,&:focus': {
              backgroundColor: GREY_PALETTE[2],
              borderRadius: '4px',
            },
          },
        },
      }}
    >
      {props.label && <FormLabel required={props.required}>{props.label}</FormLabel>}
      {props.description && (
        <Typography variant="inputLabelLine" color={WARM_SHADES_PALETTE[3]}>
          {props.description}
        </Typography>
      )}
      <Editor {...{ ...props, wrapperStyle, toolbarStyle, editorStyle, toolbar }} />
    </Column>
  );
};

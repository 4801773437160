import { InviteDocument } from '@caravel/types';
import { doc, DocumentReference } from 'firebase/firestore';
import { FirestoreTimestamp, getDb, Logger } from 'helpers';
import { computed, makeObservable, observable } from 'mobx';

import { BaseModel, makeToJS, makeUpdate, PENDING_ID } from './base';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('invite-model');

export type InviteProps = InviteDocument & Record<string, any>;

const skeletonProps: InviteProps = {
  createdAt: undefined,
  updatedAt: undefined,
  archivedAt: undefined,
  id: PENDING_ID,
  email: '',
  teamId: PENDING_ID,
  role: undefined,
};

export class Invite extends BaseModel<InviteProps> implements InviteProps {
  createdAt?: FirestoreTimestamp = undefined;
  updatedAt?: FirestoreTimestamp = undefined;
  archivedAt?: FirestoreTimestamp = undefined;

  id = skeletonProps.id;
  email = skeletonProps.email;
  teamId = skeletonProps.teamId;
  role = skeletonProps.role;

  get docRef(): DocumentReference {
    return doc(getDb(), 'teams', this.teamId, 'invites', this.id);
  }

  constructor(props: InviteProps) {
    super();

    makeObservable(this, {
      createdAt: observable,
      updatedAt: observable,
      archivedAt: observable,
      id: observable,
      email: observable,
      teamId: observable,
      role: observable,
      docRef: computed,
    });

    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

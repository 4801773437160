import { Column, WideColumn } from '@caravel/components';
import { FormSectionHeader } from '@caravel/components/src/forms/form-section-header';
import { observer } from 'mobx-react';
import React from 'react';

import { IdealCustomerProfileView } from './ideal-customer-profile-view';

export const IdealCustomerProfileContainer = observer(() => {
  return (
    <Column sx={{ width: '100%', maxWidth: '972px' }}>
      <Column sx={{ padding: '0 20px' }}>
        <FormSectionHeader
          title="Ideal Customer Profile (ICP)"
          subtitle="Define which attributes make a high fit customer"
          divider
        />
      </Column>
      <WideColumn>
        <IdealCustomerProfileView />
      </WideColumn>
    </Column>
  );
});

export default IdealCustomerProfileContainer;

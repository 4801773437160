import { TextFieldProps } from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import * as React from 'react';

export interface DateTimePickerProps {
  value?: Date | null | undefined;
  onChange: (value: Date | null | undefined, keyboardInputValue?: string | undefined) => void;
  disablePast?: boolean | undefined;
  renderInput: (props: TextFieldProps) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  minDateTime?: Date | undefined;
  disableMaskedInput?: boolean;
}

export const DateTimeSelect: React.FC<DateTimePickerProps> = (props: DateTimePickerProps) => {
  const { value, onChange, disablePast, renderInput, minDateTime, disableMaskedInput } = props;

  return (
    <DateTimePicker
      disableMaskedInput={disableMaskedInput}
      value={value}
      onChange={onChange}
      disablePast={disablePast}
      renderInput={renderInput}
      inputFormat="EEE, MMM dd, yyyy  h:mm aa"
      minDateTime={minDateTime}
    />
  );
};

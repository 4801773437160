import {
  FilterCondition,
  Note,
  Organization as OrganizationProps,
  OrganizationsFilter,
  PeopleExportTask,
} from '@caravel/types/src';
import {
  createGQLClient,
  GET_ORGANIZATION,
  GET_ORGANIZATIONS,
  GqlOrganizationRequestType,
  GqlOrganizationResponseType,
  GqlOrganizationsRequestType,
  GqlOrganizationsResponseType,
  uuid,
} from '@caravel/utils';
import {
  CREATE_ORGANIZATION_NOTE,
  GqlCreateOrganizationNoteRequestType,
  GqlCreateOrganizationNoteResponseType,
} from '@caravel/utils/src/gql/mutations/create-organization-note.gql';
import { DELETE_ORGANIZATION_NOTE } from '@caravel/utils/src/gql/mutations/delete-organization-note.gql';
import {
  EDIT_ORGANIZATION_NOTE,
  GqlEditOrganizationNoteRequestType,
  GqlEditOrganizationNoteResponsetType,
} from '@caravel/utils/src/gql/mutations/edit-organization-note.gql';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Organization } from 'models/organization';

import { BaseStore } from './base';
import { RootStore } from './root';

interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}

export interface FilterOptionsConfig {
  type: 'search' | 'select' | 'multi' | 'multi-search' | 'date';
  conditions: FilterCondition[];
  values: string[];
}

export const ORGANIZATIONS_FILTER_OPTIONS_CONFIG = {
  search: { type: 'search', values: [], conditions: [] },
};

const host = process.env.GRAPHQL_HOST!;

export class OrganizationStore extends BaseStore {
  collection = observable.array<Organization>([]);
  loading = false;
  loaded = false;
  pageInfo?: PageInfo = undefined;

  organization?: Organization = undefined;

  totalHits = 0;
  organizationsCount = 0;

  filters = observable.array<OrganizationsFilter>([
    {
      type: 'search',
      graphProperty: 'query',
      label: 'Search...',
      pluralized: 'Searches',
      condition: 'is',
      value: [],
    },
  ]);

  order: 'asc' | 'desc' = 'desc';
  orderBy = 'firstActive';

  get orderByOptions() {
    return {
      field: this.orderBy,
      direction: this.order.toUpperCase(),
    };
  }

  get searchQuery() {
    return (
      this.filters
        .filter(f => f.type === 'search')
        .at(0)
        ?.value.at(0) ?? ''
    );
  }

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      totalHits: observable,
      organizationsCount: observable,
      pageInfo: observable,
      order: observable,
      orderBy: observable,
      organization: observable,
      orderByOptions: computed,
    });
  }

  teardown() {
    this.collection.clear();
    super.teardown();
  }

  resetCollection = () => {
    runInAction(() => {
      this.collection.clear();
      this.pageInfo = undefined;
      // this.entireSegmentSelected = false;
    });
  };

  resetOrganization = () => {
    runInAction(() => {
      this.organization = undefined;
    });
  };

  refreshOrganizations = async () => {
    this.resetCollection();
    await this.fetchOrganizations();
  };

  get = (orgId: string) => this.collection.find(org => org.id === orgId);

  fetchOrganizations = async () => {
    if (this.loading) {
      console.debug('Organizations already loading');
      return;
    }
    runInAction(() => (this.loading = true));

    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);

    try {
      const response: GqlOrganizationsResponseType = await graphqlClient.query<
        GqlOrganizationsRequestType,
        GqlOrganizationsResponseType
      >(GET_ORGANIZATIONS, {
        after: this.pageInfo?.endCursor,
        query: this.searchQuery,
        orderBy: this.orderByOptions,
      });

      const pageInfo = response.community.organizations.pageInfo;
      const organizations: Organization[] = response.community.organizations.edges
        ?.filter(edge => edge.node)
        .map(edge => new Organization(edge.node));

      runInAction(() => {
        this.pageInfo = pageInfo;
        if (pageInfo.startCursor === '0') {
          this.collection.replace(organizations);
        } else {
          this.collection.replace(this.collection.concat(organizations));
        }
        this.totalHits = response.community.organizations.meta.hits ?? 0;
        this.organizationsCount = response.community.stats.organizationsCount ?? 0;
        this.loading = false;
        this.loaded = true;
      });
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to fetch organizations',
        duration: 5000,
      });
    }
  };

  fetchOrganization = async (orgId: string) => {
    if (this.loading) {
      console.debug('Organization already loading');
      return;
    }
    runInAction(() => (this.loading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlOrganizationRequestType, GqlOrganizationResponseType>(
      GET_ORGANIZATION,
      {
        orgId,
      },
    );
    runInAction(() => {
      const props: OrganizationProps = { ...response.community.organizations.edges[0].node };
      if (this.organization) {
        this.organization.update(props);
      } else {
        this.organization = new Organization(props);
      }
      this.loading = false;
      this.collection.replace(this.collection.map(p => (p.id === orgId ? this.organization! : p)));
    });
  };

  onChangeOrder = (orderBy: string) => {
    const isAsc = orderBy === this.orderBy && this.order === 'asc';
    runInAction(() => {
      this.order = isAsc ? 'desc' : 'asc';
      this.orderBy = orderBy;
      this.resetCollection();
    });
  };

  onChangeFilter = async (index: number, filter: OrganizationsFilter, skipRefresh = false) => {
    runInAction(() => {
      const next = this.filters.slice();
      const existing = next[index];
      next.splice(index, 1, { ...existing, ...filter });
      this.filters.replace(next);
    });
    if (!skipRefresh) {
      await this.refreshOrganizations();
    }
  };

  checkNoteOwnership = (noteId: string) => {
    const note = this.organization!.notes!.find(v => v.id === noteId);
    if (!note) {
      throw new Error('Note does not exist');
    }

    if (!this.rootStore.auth.user || this.rootStore.auth.user.uid !== note.author.firebaseUserId) {
      throw new Error('User does not have permission to modify this note');
    }
  };

  createOrganizationNote = async (organizationId: string, tempId: string, text: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    let note = null;
    try {
      const response = await graphqlClient.mutate<
        GqlCreateOrganizationNoteRequestType,
        GqlCreateOrganizationNoteResponseType
      >(CREATE_ORGANIZATION_NOTE, {
        organizationId,
        text,
      });
      runInAction(() => {
        note = response.upsertOrganizationNote.result;
        const currentNotes = this.organization?.notes ?? [];
        const existingIndex = currentNotes.findIndex(n => n.id === tempId);
        currentNotes.splice(existingIndex, 1, note);
        this.organization?.update({ notes: currentNotes });
      });
    } catch (e) {
      console.error('create organization note error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error creating note',
        duration: 5000,
      });
    }
    return note;
  };

  editOrganizationNote = async (organizationId: string, noteId: string, text: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    let note = null;
    if (this.loading) {
      return;
    }
    try {
      this.checkNoteOwnership(noteId);
      const response = await graphqlClient.mutate<
        GqlEditOrganizationNoteRequestType,
        GqlEditOrganizationNoteResponsetType
      >(EDIT_ORGANIZATION_NOTE, {
        organizationId,
        id: noteId,
        text,
      });
      runInAction(() => {
        note = response.upsertOrganizationNote.result;
        const currentNotes = this.organization?.notes ?? [];
        const existingIndex = currentNotes.findIndex(n => n.id === noteId);
        currentNotes.splice(existingIndex, 1, note);
        this.organization?.update({ notes: currentNotes });
      });
    } catch (e) {
      console.error('edit organization note error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error editing note',
        duration: 5000,
      });
    }
  };

  deleteOrganizationNote = async (noteId: string) => {
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    try {
      this.checkNoteOwnership(noteId);
      await graphqlClient.query(DELETE_ORGANIZATION_NOTE, { id: noteId });
      const note = this.organization?.notes?.find(n => n.id === noteId);
      if (note) {
        this.onDeleteNote(note);
      }
    } catch (e) {
      console.error('delete organization note error', e);
      this.rootStore.notifications.display({
        severity: 'error',
        message: 'Error deleting note',
        duration: 5000,
      });
    }
  };

  onAddNote = () => {
    const member = this.rootStore.members.current;
    const teamMember = member?.teamMember;
    if (!this.organization || !teamMember || !member) {
      return;
    }
    const newNoteId = uuid();
    runInAction(() => {
      const next = this.organization?.notes?.slice() ?? [];
      const names = member.name.split(' ');
      const note: Note = {
        text: '',
        pending: true,
        id: newNoteId,
        author: {
          id: member.id,
          firebaseUserId: member.id,
          role: teamMember.role as any, // FIXME
          email: member.email,
          firstName: names[0],
          lastName: names.at(-1) ?? '',
        },
        createdAt: new Date(),
      };
      next.unshift(note);
      this.organization!.notes = next;
    });
  };

  onDeleteNote = (note: Note) => {
    this.checkNoteOwnership(note.id);
    const organization = this.organization;
    if (!organization) {
      return;
    }
    const index = (organization.notes ?? []).findIndex(n => n.id === note.id);
    if (index > -1) {
      runInAction(() => {
        const next = this.organization?.notes?.slice() ?? [];
        next.splice(index, 1);
        organization.notes = next;
        this.organization = organization;
      });
    }
  };

  exportPeople = async (orgId: string) => {
    const org = this.get(orgId);
    if (!org) {
      await this.rootStore.notifications.displayError('Failed to export people');
      return;
    }
    try {
      const enqueuePeopleExport = httpsCallable(getFunctions(), 'enqueuePeopleExport');
      const { teamId, userId } = await this.getTeamAndToken();
      const options: PeopleExportTask = {
        communityId: teamId,
        userId,
        facets: [
          {
            name: 'organization',
            type: 'IS',
            values: [org.name],
          },
        ],
      };
      await enqueuePeopleExport(options);
      await this.rootStore.notifications.displaySuccess('People export started');
    } catch (e) {
      console.warn(e);
      this.rootStore.notifications.displayError('Failed to export people');
    }
  };
}

import { Logger } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC, PropsWithChildren } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStore } from 'stores';

const { debug } = new Logger('authenticated-route-container');

export const AuthenticatedRouteContainer: FC<PropsWithChildren<RouteProps>> = observer(
  ({ children, component: C, render, ...rest }) => {
    const store = useStore();
    return (
      <Route
        {...rest}
        render={routeProps => {
          const { location } = routeProps;
          const doRender = () => {
            return render ? (
              // did we supply a `render` function?
              render(routeProps)
            ) : C ? (
              // what about the `component` prop?
              <C {...routeProps} />
            ) : (
              // otherwise we'll use any children
              children
            );
          };
          if (store.auth.user && store.auth.user.emailVerified) {
            return doRender();
          } else if (store.auth.user) {
            debug('redirecting to /verify');
            return <Redirect to={{ pathname: '/verify' }} />;
          } else {
            debug('redirecting to /sign-in');
            return <Redirect to={{ pathname: '/sign-in', state: { from: location } }} />;
          }
        }}
      />
    );
  },
);

export default AuthenticatedRouteContainer;

import { Typography } from '@mui/material';
import { ReactComponent as ScienceIcon } from 'components/icons/science-24px.svg';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { BaseActionModal } from './base-action-modal';

export const GatedAnalystModal = observer(() => {
  const store = useStore();
  const history = useHistory();
  const open = store.ui.openModals.includes('analyst');

  const handleClose = () => {
    store.ui.closeModal('analyst');
  };

  const handleUpgrade = () => {
    history.push('/workspace/plans');
    handleClose();
  };

  return (
    <BaseActionModal
      open={open}
      onClose={handleClose}
      onAction={handleUpgrade}
      icon={<ScienceIcon />}
      title="Dedicated Analyst"
      subtitle="Want help from a Caravel Analyst?"
    >
      <Typography>Get dedicated help with a premium plan.</Typography>
    </BaseActionModal>
  );
});

import 'firebase/database';
import 'firebase/storage';

import { Analytics, initializeAnalytics } from 'firebase/analytics';
import { getApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import {
  connectFirestoreEmulator,
  deleteField,
  Firestore,
  initializeFirestore,
  Timestamp as FTimestamp,
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const useEmulators = process.env.REACT_APP_LOCAL_FUNCTIONS === 'true';
let db: Firestore;
let firestoreAuth: Auth;
let analytics: Analytics;
export const firebaseApp = initializeApp(firebaseConfig);

if (process.env.NODE_ENV !== 'test') {
  db = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
  if (useEmulators) {
    console.log('Using locally emulated firestore');
    connectFirestoreEmulator(db, 'localhost', 8090);
  }
  analytics = initializeAnalytics(firebaseApp, {
    config: {
      cookie_flags: 'Secure',
    },
  });
}

if (useEmulators) {
  console.log('Using locally emulated functions and auth');
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, 'localhost', 5001);

  firestoreAuth = getAuth();
  connectAuthEmulator(firestoreAuth, 'http://localhost:9099');
} else {
  firestoreAuth = getAuth(firebaseApp);
}

export const getDb = (): Firestore => db;
export const setDb = (_db: Firestore) => (db = _db);
export const getFirestoreAuth = (): Auth => firestoreAuth;
export const getAnalytics = (): Analytics => analytics;

export const Timestamp = FTimestamp;
export type FirestoreTimestamp = FTimestamp;

export { serverTimestamp } from 'firebase/firestore';

export function deleteValue() {
  return deleteField();
}

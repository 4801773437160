import { Divider, Grid, GridSize } from '@mui/material';
import React from 'react';

export interface FeatureColumnType {
  children?: React.ReactNode;
  divider?: boolean;
}

export const FeatureColumn = (props: FeatureColumnType) => {
  return (
    <Grid item xs={(12 / 5) as GridSize}>
      {props.children}
      {props.divider && <Divider variant="middle" sx={{ width: '100%', marginLeft: '0' }} />}
    </Grid>
  );
};

import { EventEmitter } from 'events';
import { User } from 'firebase/auth';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { DependentDisposer, Disposer, Logger, StringAny } from 'helpers';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { BaseModel } from 'models';

import { RootStore } from './root';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('base-store');

/**
 * A special extension of `Disposer` meant to be subclassed by all stores except `RootStore`. Provides methods bound
 * to firebase authentication which automates setup/teardown of subscriptions.
 */
export class BaseStore extends Disposer {
  debugName = 'BaseStore';

  protected authSubs: DependentDisposer<User>;

  constructor(readonly rootStore: RootStore) {
    super();

    makeObservable(this, {
      teardown: action,
      unmount: action,
    });

    this.authSubs = new DependentDisposer(() => this.rootStore.auth.user, 'auth-subs');
  }

  async setup() {
    await this.authSubs.initialize();
  }

  teardown() {
    this.unmount();
    this.authSubs.teardown();
    super.teardown();
  }

  async mount() {
    //
  }

  unmount() {
    //
  }

  getTeam = () => {
    return this.rootStore.getTeam();
  };

  getTeamAndToken = async () => {
    return await this.rootStore.getTeamAndToken();
  };

  getToken = async () => {
    return await this.rootStore.getToken();
  };
}

const { warn } = new Logger('firebase-store');

type ModelConstructor<M, MP> = new (props: MP, store: any) => M;

export interface FirebaseStoreProps<Model, ModelProps> {
  rootStore: RootStore;
  modelConstructor: ModelConstructor<Model, ModelProps>;
}

/**
 * The `FirebaseStore` class helps sync a collection from firestore to a local datastore. As a generic, it is given
 * the model class to be used as the collection type, the model-props type that defines required properties for
 * construction.
 */
export class FirebaseStore<Model extends BaseModel<ModelProps>, ModelProps extends StringAny> extends BaseStore {
  collection: IObservableArray<Model>;
  modelConstructor: ModelConstructor<Model, ModelProps>;
  events = new EventEmitter();

  hydrated = false;
  get isHydrated() {
    return this.hydrated;
  }

  constructor({ rootStore, modelConstructor }: FirebaseStoreProps<Model, ModelProps>) {
    super(rootStore);
    this.collection = observable.array([], {
      name: `${this.debugName}-collection`,
    });
    this.modelConstructor = modelConstructor;
    makeObservable(this, {
      collection: observable,
      hydrated: observable,
      isHydrated: computed,
      addToCollection: action,
      dehydrate: action,
      remove: action,
      updateFromSnapshot: action,
    });
  }

  teardown() {
    this.hydrated = false;
    this.events.removeAllListeners();
    super.teardown();
  }

  addToCollection = (record: Model) => {
    const index = this.collection.findIndex(r => r.id === record.id);
    if (index > -1) {
      // we already have this record locally, but let's take the latest version just in case
      this.collection.splice(index, 1, record);
    } else {
      // its a new record
      this.collection.push(record);
    }
    this.events.emit('added', record.id);
  };

  dehydrate = () => {
    this.collection.clear();
    this.hydrated = false;
  };

  get = (id: string): Model | undefined => this.collection.find(r => r.id === id);

  remove = (id: string) => {
    const index = this.collection.findIndex(c => c.id === id);
    if (index > -1) {
      this.collection.splice(index, 1);
      this.events.emit('removed', id);
    }
  };

  readonly updateFromSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    snapshot.docChanges().forEach(change => {
      const id = change.doc.id;
      const index = this.collection.findIndex(d => d.id === id);
      if (change.type === 'removed') {
        this.collection.splice(index, 1);
        this.events.emit('removed', id);
        return;
      }
      const props = change.doc.data() as ModelProps;
      if (change.type === 'modified') {
        if (index > -1) {
          this.collection[index].update({ id, ...props });
          this.events.emit('modified', id);
        } else {
          // somehow, we don't have this record locally already. create a new one
          warn('Expected to have record available locally...');
          const record = new this.modelConstructor({ id, ...props }, this);
          this.collection.splice(index, 1, record);
          this.events.emit('added', id);
        }
      } else if (change.type === 'added') {
        if (index > -1) {
          // we already have this record locally, but let's take the latest version just in case
          const record = new this.modelConstructor({ id, ...props }, this);
          this.collection.splice(index, 1, record);
        } else {
          // its a new record
          this.collection.push(new this.modelConstructor({ id, ...props }, this));
        }
        this.events.emit('added', id);
      }
    });
    if (!this.hydrated) {
      this.hydrated = true;
    }
  };
}

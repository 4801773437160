import { Column, Flex, Row } from '@caravel/components/src';
import { isEmptyString } from '@caravel/utils';
import { GIBBERING_GREY, WHITE } from '@caravel/utils/src/constants/colors';
import { Button, Divider, TextField, Typography } from '@mui/material';
import { updateDoc } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStore } from 'stores';

export interface ProfileViewProps {
  name?: string;
  currentName?: string;
  onChangeName: (name: string) => void;
  onSaveProfile: () => void;
}

export const TeamNameInput = observer(() => {
  const store = useStore();
  const [name, setName] = useState<string | undefined>(store.teams.currentTeam?.displayName);
  const currentName = store.teams.currentTeam?.displayName;
  const teamId = store.team?.id;

  const onChangeName = (name: string) => {
    setName(name);
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeName(e.currentTarget.value);
  };

  const onSaveProfile = async () => {
    if (currentName === name) {
      return;
    }
    if (!isEmptyString(name)) {
      await store.ui.workOn(async () => {
        await updateDoc(store.teams.currentTeam!.docRef, {
          displayName: name,
        });

        //Segment Analytics
        analytics.group(store.teams.currentTeam!.id, {
          groupId: store.teams.currentTeam?.id,
          name: store.teams.currentTeam?.displayName,
          plan: store.teams.currentTeam?.plan?.name,
          employees: store.teams.teamMembers.collection.slice().length,
          slug: store.teams.currentTeam?.slug,
          createdAt: store.teams.currentTeam?.createdAt?.seconds
            ? DateTime.fromSeconds(store.teams?.currentTeam?.createdAt?.seconds).toUTC().toISO()
            : undefined,
        });
        analytics.track(
          'Workspace Updated',
          {
            workspace_name: store.teams.currentTeam!.displayName,
            groupId: store.teams.currentTeam!.id,
          },
          {
            context: {
              groupId: store.teams.currentTeam!.id,
            },
          },
        );

        store.notifications.display({
          message: `Successfully updated your team name`,
          severity: 'success',
        });
      });
    } else {
      store.notifications.display({
        message: `Name can't be blank`,
        severity: 'error',
      });
    }
  };

  return (
    <Column>
      <Column
        sx={{
          background: GIBBERING_GREY,
          borderRadius: '5px',
          height: '159px',
          padding: '20px',
          width: '100%',
        }}
      >
        <Typography variant="inputLabel">Name</Typography>
        <TextField
          onChange={handleChangeName}
          sx={{ margin: '10px 0', background: WHITE }}
          fullWidth
          value={name}
          id="team-name"
          onKeyPress={async e => {
            if (e.key === 'Enter') {
              await onSaveProfile();
            }
          }}
        />
        <Row alignItems="center">
          <Flex flex={1} />
          {teamId && (
            <Button variant="text" color="inherit" onClick={() => store.ui.copyToClipboard(teamId)}>
              Copy Team ID
            </Button>
          )}
          <Button
            type="submit"
            sx={{ width: '94px', marginLeft: '10px' }}
            color="primary"
            onClick={onSaveProfile}
            variant="contained"
            disabled={name === currentName || isEmptyString(name)}
          >
            Update
          </Button>
        </Row>
      </Column>
      <Divider sx={{ margin: '30px 0' }} />
    </Column>
  );
});

import { Flex, KebabMenu, KebabMenuOption } from '@caravel/components/src';
import { NEUTRALS } from '@caravel/utils';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Button, ButtonProps, Toolbar, Typography } from '@mui/material';
import { AppBar } from 'components/app-bar';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface ActionButtonProps extends ButtonProps {
  text: string;
}

interface Props {
  backButton?: boolean;
  onBackButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  subtitle?: string;
  actionButton?: ActionButtonProps;
  kebabMenuOptions?: KebabMenuOption[];
  children?: React.ReactNode;
}

export const AppToolbar = observer((props: Props) => {
  const history = useHistory();
  const { backButton, onBackButtonClick, title, subtitle, actionButton, kebabMenuOptions, children } = props;

  return (
    <AppBar>
      <Toolbar>
        <Flex gap="10px" alignItems="center">
          {backButton && (
            <Button
              onClick={onBackButtonClick ? onBackButtonClick : () => history.goBack()}
              variant="contained"
              color="tertiary"
              sx={{
                minWidth: 0,
                padding: '6px',
                '&.MuiButton-contained': {
                  background: NEUTRALS.CHAMOMILE,
                  margin: '0',
                },
              }}
            >
              <ArrowBackRoundedIcon />
            </Button>
          )}

          <Typography
            variant="headline3"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '500px',
            }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="bodySmall" sx={{ color: NEUTRALS.OOLONG }}>
              {subtitle}
            </Typography>
          )}
        </Flex>

        <Flex flex={1} />

        {children}

        <Flex gap="10px" alignItems="center">
          {actionButton && (
            <Button variant="contained" sx={{ padding: '6px 16px' }} {...actionButton}>
              {actionButton.text}
            </Button>
          )}

          {kebabMenuOptions && <KebabMenu options={kebabMenuOptions} />}
        </Flex>
      </Toolbar>
    </AppBar>
  );
});

export default AppToolbar;

import { gql } from '@apollo/client';

export const ADD_MANUAL_RECORD = gql`
  mutation addManualRecord($input: AddManualPersonaInput!) {
    addManualPersona(input: $input) {
      result {
        id
        city
        state
        country
        emails
        employmentTitle
        facebookHandle
        githubHandle
        twitterHandle
        website
      }
    }
  }
`;

export interface GqlManualPersonaRequestType {
  input: {
    memberId: string;
    city?: string;
    state?: string;
    country?: string;
    emails?: string[];
    employmentTitle?: string;
    facebookHandle?: string;
    githubHandle?: string;
    twitterHandle?: string;
    website?: string;
  };
}

export interface GqlCreatePersonaResponsetType {
  addManualPersona: {
    result: {
      id: string;
      city?: string;
      state?: string;
      country?: string;
      emails?: string[];
      employmentTitle?: string;
      facebookHandle?: string;
      githubHandle?: string;
      twitterHandle?: string;
      website?: string;
    };
  };
}

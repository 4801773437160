import { ReactComponent as CaseStudyIcon } from '../../assets/svgs/file-text-2.svg';
import { ReactComponent as GmailIcon } from '../../assets/svgs/gmail.svg';
import { ReactComponent as GoogleIcon } from '../../assets/svgs/google.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/svgs/linkedin.svg';
import { ReactComponent as QuoteIcon } from '../../assets/svgs/quote.svg';
import { ReactComponent as SlackIcon } from '../../assets/svgs/slack-small.svg';
import { ReactComponent as TwitterIcon } from '../../assets/svgs/twitter-small.svg';
import { ReactComponent as VideoIcon } from '../../assets/svgs/video-camera.svg';

export { GoogleIcon, GmailIcon, SlackIcon, TwitterIcon, LinkedInIcon, QuoteIcon, VideoIcon, CaseStudyIcon };

import { Column, Row } from '@caravel/components/src';
import { BUTTERFLYPEA, GREY_PALETTE, INFO_PALETTE } from '@caravel/utils';
import { Box, Skeleton, Typography } from '@mui/material';
import { linearGradientDef } from '@nivo/core';
import { useMotionConfig } from '@nivo/core';
import { ResponsiveLine, Serie } from '@nivo/line';
import { animated, useSpring } from '@react-spring/web';
import { observer } from 'mobx-react';
import React from 'react';

interface PlainLineGraphProps {
  data: Serie[];
  max: number;
  maxBuffer: number;
  enableGridY?: boolean;
}

export const PlainLineGraph = observer(({ data, max, maxBuffer, enableGridY = true }: PlainLineGraphProps) => {
  const gridYValues = [0, max * 0.25, max * 0.5, max * 0.75, max].map(v => Math.round(v));

  const PointHighlight = (props: any) => {
    const { currentPoint } = props;
    if (currentPoint) {
      return (
        <g>
          <circle fill={GREY_PALETTE[0]} r={5} fillOpacity={0.5} cx={currentPoint.x} cy={currentPoint.y} />
          <circle fill={INFO_PALETTE[200]} r={3} fillOpacity={1} cx={currentPoint.x} cy={currentPoint.y} />
        </g>
      );
    }
  };

  // Work around to implement custom CrosshairLine from Nivo
  // Matching https://github.com/plouc/nivo/blob/master/packages/tooltip/src/CrosshairLine.tsx
  const CustomCrossHairLine = (props: any) => {
    const { x0, x1, y0, y1, stroke } = props;
    const { animate, config: springConfig } = useMotionConfig();

    const animatedProps = useSpring({
      x1: x0,
      x2: x1,
      y1: y0,
      y2: y1,
      config: springConfig,
      immediate: !animate,
    });

    return (
      <animated.line
        {...animatedProps}
        fill="none"
        stroke={stroke}
        strokeWidth={1}
        style={{
          transition: 'all 0.5s',
        }}
      />
    );
  };

  const CustomCrossHair = (props: any) => {
    const { currentPoint, height } = props;

    if (currentPoint) {
      return (
        <>
          <CustomCrossHairLine
            x0={currentPoint.x}
            x1={currentPoint.x}
            y0={currentPoint.y}
            y1={height}
            stroke={INFO_PALETTE[200]}
          />
          <CustomCrossHairLine
            x0={currentPoint.x}
            x1={currentPoint.x}
            y0={currentPoint.y - 18}
            y1={currentPoint.y}
            stroke={INFO_PALETTE[100]}
          />
        </>
      );
    }
  };

  return (
    <ResponsiveLine
      data={data}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 0,
        max: max + maxBuffer,
        stacked: true,
        reverse: false,
      }}
      curve={'monotoneX'}
      useMesh={true}
      isInteractive={true}
      layers={['grid', 'axes', 'areas', 'lines', 'points', CustomCrossHair, PointHighlight, 'mesh', 'crosshair']}
      defs={[
        linearGradientDef('area', [
          { offset: 12, color: BUTTERFLYPEA[200] },
          { offset: 95, color: BUTTERFLYPEA[200], opacity: 0 },
        ]),
      ]}
      fill={[{ match: '*', id: 'area' }]}
      areaOpacity={0.23}
      tooltip={({ point }) => {
        const date = new Date(point.data.xFormatted);
        const formattedDate = date.toLocaleString('default', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        return (
          <>
            <Box
              sx={{
                color: GREY_PALETTE[6],
                padding: '4px 12px',
                borderRadius: '8px',
                background: '#FFFFFF',
                boxShadow:
                  '0px 4px 14px -10px rgba(0, 0, 0, 0.07), 0px 10px 18px rgba(0, 0, 0, 0.04), 0px 30px 42px rgba(0, 0, 0, 0.12)',
              }}
            >
              <Column>
                <Row sx={{ gap: 1, alignItems: 'center' }}>
                  <Skeleton sx={{ bgcolor: INFO_PALETTE[200] }} variant="circular" width={6} height={6} />
                  <Typography variant="bodySmallest">
                    {data[0].id /** TODO: This is kinda not great, we should change this in a refactor */}
                  </Typography>
                  <Typography variant="bodySmallest">{point.data.yFormatted}</Typography>
                </Row>
                <Row sx={{ gap: 1 }}>
                  <Box sx={{ width: '6px' }} />
                  <Typography variant="bodySmallest" sx={{ opacity: '0.5' }}>
                    {formattedDate}
                  </Typography>
                  <Box />
                </Row>
              </Column>
            </Box>
          </>
        );
      }}
      enableCrosshair={false}
      crosshairType={'bottom'}
      enableArea={true}
      areaBaselineValue={0}
      enableGridX={false}
      enableGridY={enableGridY}
      gridYValues={gridYValues}
      enablePoints={true}
      lineWidth={1}
      pointSize={1}
      animate={true}
      motionConfig={'stiff'}
      colors={INFO_PALETTE[200]}
      theme={{
        crosshair: {
          line: {
            strokeWidth: 1,
            strokeDasharray: '0',
            stroke: BUTTERFLYPEA[200],
            strokeOpacity: 1,
          },
        },
        grid: {
          line: {
            stroke: GREY_PALETTE[2],
          },
        },
      }}
    />
  );
});

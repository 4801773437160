export function removeKey(value: any, discardedKey: string): any {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => removeKey(v, discardedKey));
  } else if (typeof value === 'object') {
    const newObj: any = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== discardedKey) {
        newObj[key] = removeKey(v, discardedKey);
      }
    });
    return newObj;
  }
  return value;
}

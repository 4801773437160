import React from 'react';

import { CropImageModal } from './crop-image-modal';
import { GatedAnalystModal } from './gated-analyst-modal';
import { TrainAModelModal } from './train-a-model';
import { UsageExceededModal } from './usage-exceeded-modal';
import { UsageLimitedModal } from './usage-limited-modal';

export function AppModals() {
  return (
    <>
      <UsageExceededModal />
      <UsageLimitedModal />
      <GatedAnalystModal />
      <TrainAModelModal />
      <CropImageModal />
    </>
  );
}

import { Column } from '@caravel/components/src';
import { Button, Typography } from '@mui/material';
import { useQuery } from 'helpers';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { OnboardingWrapper } from '../onboarding-wrapper';
import { SignUpEmail } from './sign-up-email';
import { SignUpView } from './sign-up-view';

type SignUpStep = 'signup' | 'signup-email';

export const SignUpContainer = observer(() => {
  const store = useStore();
  const history = useHistory();
  const query = useQuery();
  const teamId = query.get('teamId') || undefined;
  const inviteId = query.get('inviteId') || undefined;
  const [message, setMessage] = useState<string | undefined>();
  const [step, setStep] = useState<SignUpStep>('signup');

  const MESSAGE_EMAIL =
    "We've sent a confirmation email to the address provided. Follow the link in the email to complete your account setup";

  const onSwitchToEmail = () => {
    setStep('signup-email');
  };

  const onSignUpWithGoogle = async () => {
    await store.ui.workOn(async () => {
      setMessage(
        `If a valid Google email address is provided, a welcome email with additional documentation will be sent.`,
      );
      const error = await store.auth.signUpWithGoogle();
      if (!error) {
        if (teamId && inviteId) {
          await store.teams.invites.acceptInvite({ teamId, inviteId });
        }
        history.push('/teams/new');
      }
    });
  };

  const onSignUpWithEmail = async (email: string) => {
    await store.ui.workOn(async () => {
      try {
        await store.auth.signUpWithEmail(email, teamId && inviteId ? { teamId, inviteId } : undefined);
        setMessage(MESSAGE_EMAIL);
      } catch (e) {
        console.error(e);
        store.notifications.displayError('Failed to sign up');
      }
    });
  };

  const onSignUp = async (email: string, password: string) => {
    return await store.ui.workOn(async () => {
      try {
        await store.auth.signUp(email, password);
        setMessage(MESSAGE_EMAIL);
      } catch (e) {
        console.error(e);
        store.notifications.displayError('Failed to sign up');
      }
    });
  };

  const onBackToSignUp = () => {
    setMessage(undefined);
    setStep('signup');
  };

  if (message) {
    return (
      <OnboardingWrapper>
        <Column alignItems="stretch" width="100%" maxWidth="360px">
          <Typography variant="headline1" component="h1" marginBottom="40px" textAlign="center">
            {message}
          </Typography>
          <Button color="primary" variant="contained" fullWidth size="large" onClick={onBackToSignUp}>
            Back
          </Button>
        </Column>
      </OnboardingWrapper>
    );
  }

  return (
    <OnboardingWrapper>
      {step === 'signup' && <SignUpView onSignUpWithGoogle={onSignUpWithGoogle} onSwitchToEmail={onSwitchToEmail} />}
      {step === 'signup-email' && <SignUpEmail onSignUpWithEmail={onSignUpWithEmail} onSignUp={onSignUp} />}
    </OnboardingWrapper>
  );
});

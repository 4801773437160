import { TeamMemberDocument } from '@caravel/types';
import { doc } from 'firebase/firestore';
import { FirestoreTimestamp, getDb } from 'helpers';
import { action, computed, makeObservable, observable } from 'mobx';
import { TeamMemberStore } from 'stores/team-members';

import { BaseModel, makeToJS, makeUpdate, PENDING_ID } from './base';

export type TeamMemberProps = TeamMemberDocument & Record<string, any>;

const skeletonProps: TeamMemberProps = {
  createdAt: undefined,
  updatedAt: undefined,
  archivedAt: undefined,
  id: PENDING_ID,
  role: 'reader',
  name: '',
  email: '',
  photoUrl: undefined,
};

export class TeamMember extends BaseModel<TeamMemberProps> implements TeamMemberProps {
  createdAt?: FirestoreTimestamp = undefined;
  updatedAt?: FirestoreTimestamp = undefined;
  archivedAt?: FirestoreTimestamp = undefined;

  id = skeletonProps.id;
  role = skeletonProps.role;
  name = skeletonProps.name;
  email = skeletonProps.email;
  photoUrl = skeletonProps.photoUrl;

  get online() {
    return this._online;
  }

  get status(): 'online' | 'offline' {
    return this.online ? 'online' : 'offline';
  }

  get working() {
    return this._working;
  }

  private _online = false;
  private _working = false;

  readonly store: TeamMemberStore;

  get docRef() {
    if (this.store.rootStore.team) {
      return doc(getDb(), this.store.rootStore.team.docRef.path, 'team-members', this.id);
    }
  }

  constructor(props: TeamMemberProps, store: TeamMemberStore) {
    super();

    makeObservable<TeamMember, '_online' | '_working'>(this, {
      createdAt: observable,
      updatedAt: observable,
      archivedAt: observable,
      id: observable,
      role: observable,
      name: observable,
      email: observable,
      photoUrl: observable,
      online: computed,
      status: computed,
      working: computed,
      _online: observable,
      _working: observable,
      docRef: computed,
      setOnline: action,
      setWorking: action,
    });

    this.store = store;
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);

  setOnline = (online: boolean) => (this._online = online);
  setWorking = (working: boolean) => (this._working = working);
}

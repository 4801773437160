import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export const TextFieldWithAdornment = React.forwardRef<
  HTMLDivElement,
  TextFieldProps & {
    inputHeight?: number;
    endIcon?: React.ReactNode;
  }
>(({ inputHeight = 48, endIcon, ...props }, ref) => (
  <TextField
    ref={ref}
    {...props}
    sx={{
      marginTop: '10px',
      '& .MuiOutlinedInput-root': {
        paddingRight: '6px',
      },
      ...props.sx,
    }}
    inputProps={{
      sx: {
        boxSizing: 'border-box',
        fontSize: 14,
        height: inputHeight,
        ...props.inputProps?.sx,
      },
    }}
    InputProps={{
      endAdornment: <InputAdornment position="end">{endIcon}</InputAdornment>,
      ...props.InputProps,
    }}
  />
));

TextFieldWithAdornment.displayName = 'TextFieldWithAdornment';

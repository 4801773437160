import { Column, ImageUploaderWithPreview } from '@caravel/components/src';
import { FormInput } from '@caravel/components/src/forms/form-input';
import { CHARCOAL_BLACK, isEmptyString, isValidSubdomain, slugify, uuid } from '@caravel/utils';
import { Button, Card, Typography } from '@mui/material';
import { collection, onSnapshot, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getDb } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { OnboardingBackground } from '../onboarding-background';

export const TeamsCreate = () => {
  const { teams, ui, notifications } = useStore();
  const history = useHistory();
  const working = ui.isWorking;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [slugs, setSlugs] = useState<string[]>([]);

  const [logo, setLogo] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [existingLogoUrl, setExistingLogoUrl] = useState<string | undefined>();
  const [publicLogoUrl, setPublicLogoUrl] = useState<string | undefined>();

  const isSlugValid = working ? true : !slugs.includes(slug) && isValidSubdomain(slug);

  const onLogoDelete = async () => {
    await ui.workOn(async () => {
      await updateDoc(teams.currentTeam!.docRef, {
        logoUrl: null,
      });
    });
  };

  const uploadLogo = useCallback(async () => {
    setUploading(true);
    try {
      const fileName = uuid();
      const destinationRef = ref(getStorage(), `/teams/logos/${fileName}`);
      await uploadBytes(destinationRef, logo[0].file);
      const publicURL = `https://firebasestorage.googleapis.com/v0/b/${process.env
        .REACT_APP_FIREBASE_STORAGE_BUCKET!}/o/teams%2Flogos%2F${fileName}?alt=media`;

      return publicURL;
    } catch (e) {
      console.error(e);
      notifications.display({
        message: 'Failed to upload image',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      setUploading(false);
    }
  }, [logo, notifications]);

  useEffect(() => {
    if (logo[0]) {
      uploadLogo().then(setPublicLogoUrl);
    }
  }, [logo, uploadLogo]);

  const onChangeDisplayName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dName = e.currentTarget.value;
    setDisplayName(dName);
    setSlug(slugify(dName));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await ui.workOn(async () => {
      setSubmitting(true);
      try {
        const { teamId } = await teams.createTeam(displayName!, slug!, publicLogoUrl);
        if (await teams.loadTeam(teamId, 20000)) {
          return history.push('/');
        }
      } catch (e) {
        //do nothing, alerts are taken care of in the store
      } finally {
        setSubmitting(false);
      }
    });
  };

  useEffect(() => {
    let isCancelled = false;
    const slugsRef = collection(getDb(), 'slugs');
    const disposer = onSnapshot(slugsRef, snapshot => {
      if (!isCancelled) {
        setSlugs(snapshot.docs.map(d => d.id));
      }
    });

    return () => {
      isCancelled = true;
      disposer();
    };
  }, []);

  return (
    <OnboardingBackground>
      <Card
        variant="elevation"
        elevation={0}
        sx={{ padding: '48px 24px', borderRadius: '16px', width: '100%', maxWidth: '388px' }}
      >
        <Column gap="40px" alignItems="center">
          <ImageUploaderWithPreview
            circle
            image={logo}
            setImage={setLogo}
            loading={uploading}
            onDelete={onLogoDelete}
            existingImageUrl={existingLogoUrl}
            setExistingImageUrl={setExistingLogoUrl}
            size="small"
          />
          <Typography variant="headline1" component="h1" color={CHARCOAL_BLACK}>
            Set up your team workspace
          </Typography>
          <form onSubmit={handleSubmit} autoComplete="off" style={{ width: '100%' }}>
            <Column gap="20px">
              <FormInput
                label="Team Name"
                value={displayName}
                required
                fullWidth
                onChange={onChangeDisplayName}
                error={!isSlugValid && !isEmptyString(slug)}
                helperText={!isSlugValid && !isEmptyString(slug) ? 'Team name already taken' : undefined}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={submitting || !isSlugValid || isEmptyString(slug)}
                onClick={handleSubmit}
              >
                Create team
              </Button>
            </Column>
          </form>
        </Column>
      </Card>
    </OnboardingBackground>
  );
};

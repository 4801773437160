import { Column, NoResults, Row, RowActionMenu, SortableTableHead } from '@caravel/components/src';
import { COMMSOR_PURPLE, ERROR_PALETTE, GREY_PALETTE, parseDate, PRIMARY_PALETTE, truncateText } from '@caravel/utils';
import {
  Box,
  Checkbox,
  IconButton,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'stores';
import { Proof } from 'stores/proof-library';

import { ReactComponent as NoProofsIcon } from '../../assets/svgs/no-results-proof.svg';
import { AppToolbar } from '../app-toolbar';
import { FilterIcon } from '../common-icons';
import { FlexBox } from '../flex-box';
import { DeleteProofModal } from './proof-delete-modal';
import { ProofFormModal } from './proof-form/proof-form-modal';
import { AddProofIcon, DeleteIcon, EditIcon } from './proof-icons';
import { ProofOwnerCell } from './proof-name-cell';
import { ProofTags } from './proof-tag';
import { ProofThumbnail } from './proof-thumbnail';
import { ProofTweetModal } from './proof-tweet-modal';
import { SourceBadge } from './source-badge';

export const ProofLibraryList = observer(() => {
  const store = useStore();
  const [deleteProofModalVisibility, setDeleteProofModalVisibility] = useState<boolean>(false);
  const [tweetModalOpen, setTweetModalOpen] = useState<boolean>(false);
  const [tweetModalId, setTweetModalId] = useState<string>('');
  const [currentProof, setCurrentProof] = useState<Proof | undefined>(undefined);
  const {
    teams: { proofLibrary },
    ui,
  } = store;
  const canLoad = store.team && proofLibrary.collection.length < 1 && !proofLibrary.proofsLoading;

  const proofs: Proof[] = proofLibrary.collection
    .slice()
    .filter(proof => proof.dateCreated !== undefined)
    .sort((a, b) => Date.parse(b.dateCreated!) - Date.parse(a.dateCreated!)); //Default to most recent for now
  const hasProofs = proofs.length > 0;

  const loadMore = useCallback(() => {
    if (!canLoad) return;
    ui.workOn(async () => {
      await proofLibrary.fetchProofCollection();
    });
  }, [canLoad, proofLibrary, ui]);

  useEffect(() => {
    if (!proofs.length) {
      loadMore();
    }
    // Until we receive paged data from the API, only fetch
    // Proof Library data once, assuming that we get the entire
    // collection up front.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAddProof = () => {
    ui.ProofForm.createProof();
    ui.ProofForm.showFormModal();
  };

  const openEditProof = (proofId?: string, proof?: Proof) => {
    if (proof) {
      ui.ProofForm.setProofForm(proof, proofId);
    }
    ui.ProofForm.showFormModal();
  };

  const openDeleteProof = (proof: Proof) => {
    setCurrentProof(proof);
    setDeleteProofModalVisibility(true);
  };

  const closeDeleteProof = () => {
    setDeleteProofModalVisibility(false);
    setCurrentProof(undefined);
  };

  const toggleSelection = (id: string) => {
    if (proofLibrary.isSelected(id)) {
      proofLibrary.deselectProof(id);
    } else {
      proofLibrary.selectProof(id);
    }
  };

  const toggleSelectAll = () => {
    if (proofLibrary.selected.size === proofs.length) {
      proofLibrary.clearSelected();
    } else {
      proofLibrary.selectAll();
    }
  };

  const handleDeleteProof = async () => {
    if (!currentProof) return;
    ui.workOn(async () => {
      await proofLibrary.retractProof(currentProof.id!);
      closeDeleteProof();
      await proofLibrary.refreshChampions();
    });
  };

  const handleRequestSort = () => {
    // TODO: Implement handleRequestSort
  };

  const toggleFiltersSection = () => {
    // TODO: Toggle Filter Section
  };

  const getTweetIdFromUrl = (url: string | undefined) => {
    if (!url) return '';
    const match = url.match(/twitter.com\/.*\/status\/(\d+)\D?/);
    return match ? match[1] : '';
  };

  const openTweetModal = (tweetId: string) => {
    setTweetModalOpen(true);
    setTweetModalId(tweetId);
  };

  const closeTweetModal = () => {
    setTweetModalOpen(false);
    setTweetModalId('');
  };

  return (
    <Column sx={{ height: '100%' }}>
      <AppToolbar
        title="Proof Library"
        subtitle={!proofLibrary.proofsLoading && hasProofs ? `${proofs.length}` : ''}
        actionButton={{
          startIcon: <AddProofIcon width="20px" />,
          onClick: openAddProof,
          text: 'Add Proof',
        }}
      >
        <FlexBox gap={10} marginRight="18px">
          <IconButton sx={{ color: GREY_PALETTE[6] }} onClick={toggleFiltersSection}>
            <FilterIcon style={{ width: '20px', height: '20px' }} />
          </IconButton>
        </FlexBox>
      </AppToolbar>
      {/* <PeopleFilters hideFilters /> */}
      {hasProofs ? (
        <Column>
          <TableContainer sx={{ overflowX: 'unset' }}>
            <Table stickyHeader size="small">
              <SortableTableHead
                checkbox
                cells={[
                  {
                    id: 'name',
                    label: 'Name',
                    minWidth: '200px',
                    width: '330px',
                    maxWidth: '330px',
                  },
                  {
                    id: 'source',
                    label: 'Source',
                    minWidth: '100px',
                    width: `200px`,
                    maxWidth: '200px',
                  },
                  {
                    id: 'proof',
                    label: 'Proof',
                    minWidth: '200px',
                    maxWidth: '516px',
                  },
                  {
                    id: 'date-created',
                    label: 'Date Created',
                    width: `100px`,
                    maxWidth: '200px',
                    disabledSort: true,
                  },
                  {
                    id: 'context-menu',
                    label: '',
                    width: `40px`,
                    maxWidth: '40px',
                    disabledSort: true,
                  },
                ]}
                order={'desc'}
                orderBy={''}
                onSort={handleRequestSort}
                onSelectAllClick={toggleSelectAll}
                rowCount={proofs.length}
                numSelected={proofLibrary.selected.size}
              />
              <TableBody>
                {proofs.map(row => {
                  const customerInfo = proofLibrary.getCustomerInfo(row);
                  return (
                    <TableRow key={row.id} sx={{ minHeight: '50px', height: '100%' }}>
                      <TableCell sx={{ padding: '0 0 0 18px', width: '20px' }}>
                        <Checkbox
                          color="primary"
                          checked={row.id ? proofLibrary.isSelected(row.id) : false}
                          size="small"
                          onChange={() => {
                            if (row.id) {
                              toggleSelection(row.id);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <ProofOwnerCell authorInfo={customerInfo} sourceType={row.sourceType} />
                      </TableCell>
                      <TableCell>
                        <Row
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <SourceBadge source={row.sourceType ?? ''} />
                        </Row>
                      </TableCell>
                      <TableCell>
                        {row.sourceLink && (
                          <>
                            {row.sourceType === 'TWITTER' ? (
                              <Typography
                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                variant="bodySmaller"
                                color={COMMSOR_PURPLE[200]}
                                onClick={() => {
                                  // We currently don't sanitize/validate URLs,
                                  // so add a little safeguard against a bad Tweet URL
                                  const tweetIdFromUrl = getTweetIdFromUrl(row.sourceLink);
                                  if (tweetIdFromUrl) {
                                    openTweetModal(getTweetIdFromUrl(row.sourceLink));
                                  } else {
                                    window.open(row.sourceLink, '_blank');
                                  }
                                }}
                              >
                                View Tweet
                              </Typography>
                            ) : (
                              <Link
                                href={row.sourceLink ?? ''}
                                target="_blank"
                                rel="noreferrer"
                                underline="none"
                                sx={{
                                  color: GREY_PALETTE[5],
                                  transition: 'all 0.1s ease-in-out',
                                  '& :hover': {
                                    color: PRIMARY_PALETTE[200],
                                    transition: 'all 0.1s ease-in-out',
                                    img: {
                                      transition: 'all 0.1s ease-in-out',
                                      opacity: 1,
                                    },
                                  },
                                  img: {
                                    transition: 'all 0.1s ease-in-out',
                                    opacity: 0.7,
                                  },
                                }}
                              >
                                <Row sx={{ gap: 2 }}>
                                  {row.sourceType === 'VIDEO' && (
                                    <Column>
                                      <ProofThumbnail proof={row} />
                                    </Column>
                                  )}
                                  <Column>
                                    <Typography variant="bodySmaller" sx={{ wordBreak: 'break-word' }}>
                                      {truncateText(
                                        row.content ? row.content : row.sourceLink ? 'See source' : '',
                                        256,
                                      )}
                                    </Typography>
                                  </Column>
                                </Row>
                                <Row>
                                  <Box>
                                    <ProofTags tags={row.proofTags} />
                                  </Box>
                                </Row>
                              </Link>
                            )}
                          </>
                        )}
                        {!row.sourceLink && (
                          <Row sx={{ gap: 2 }}>
                            <Column>
                              <Typography variant="bodySmaller" sx={{ wordBreak: 'break-word' }}>
                                {truncateText(row.content ?? '', 256)}
                              </Typography>
                              <Box>
                                <ProofTags tags={row.proofTags} />
                              </Box>
                            </Column>
                          </Row>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall" sx={{ wordBreak: 'break-word' }}>
                          {parseDate(row.dateCreated)?.toLocaleDateString() ?? '---'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <RowActionMenu
                          id={`champions-context-${row.id}`}
                          canOpen={!ui.ProofForm.showProofForm && !deleteProofModalVisibility}
                          options={[
                            <MenuItem
                              key={'edit-proof'}
                              sx={{
                                width: '146px',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                              }}
                              onClick={() => {
                                ui.ProofForm.setProofForm(row, row.id);
                                openEditProof(row.id, row);
                              }}
                            >
                              <Row
                                sx={{
                                  alignItems: 'center',
                                  gap: 1,
                                  width: '100%',
                                }}
                              >
                                <EditIcon style={{ width: '20px', height: '20px' }} />
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    lineHeight: '16px',
                                  }}
                                >
                                  Edit
                                </Typography>
                              </Row>
                            </MenuItem>,
                            <MenuItem
                              key={'delete-proof'}
                              sx={{
                                width: '100%',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                              }}
                              onClick={() => {
                                openDeleteProof(row);
                              }}
                            >
                              <Row
                                sx={{
                                  alignItems: 'center',
                                  gap: 1,
                                  width: '100%',
                                }}
                              >
                                <DeleteIcon style={{ width: '20px', height: '20px', color: ERROR_PALETTE[300] }} />
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    lineHeight: '16px',
                                    color: ERROR_PALETTE[300],
                                  }}
                                >
                                  Delete
                                </Typography>
                              </Row>
                            </MenuItem>,
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/*       <Column sx={{ padding: '28px' }}>
        {!champions.championsLoading && <Column ref={observe} />}
        {(champions.championsLoading || champions.pageInfo?.hasNextPage) && <CaravelSpinner />}
        <Flex flex={1} sx={{ paddingTop: '20px', width: '100%' }} />
      </Column> */}
        </Column>
      ) : (
        <NoResults
          icon={<NoProofsIcon />}
          title="No Proof items"
          message="Keep a treasure trove of glowing testimonials and social proof. Gather and store them here."
          buttonText="Add Proof"
          buttonIcon={<AddProofIcon width="20px" />}
          onButtonClick={openAddProof}
        />
      )}
      <ProofFormModal />
      <DeleteProofModal
        open={deleteProofModalVisibility}
        handleClose={closeDeleteProof}
        handleDelete={handleDeleteProof}
      />
      <ProofTweetModal open={tweetModalOpen} tweetId={tweetModalId} handleClose={closeTweetModal} />
    </Column>
  );
});

import { CaravelUserProperties, EventNames, logEvent, Logger, updateUserProperties } from '../helpers';
import { BaseStore } from './base';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('analytics');

export class AnalyticsStore extends BaseStore {
  async setup() {
    await super.setup();
  }

  teardown() {
    super.teardown();
  }

  // TODO: Track page change
  trackPage = () => ({});

  trackIdentity = (id?: string, props?: CaravelUserProperties) => {
    if (!id) {
      return;
    }

    const member = this.rootStore.members.current;
    const team = this.rootStore.team;

    const userProps: CaravelUserProperties = {
      name: member?.name,
      email: member?.email,
      role: member?.teamMember?.role,
      created_at: member?.createdAt?.toMillis(),
      team_id: team?.id,
      team_name: team?.displayName,
      plan_id: team?.planId,
      ...props,
    };

    try {
      updateUserProperties(userProps);
    } catch (e) {
      debug(e);
    }
  };

  trackEvent = (event: EventNames, eventParams?: Record<string, any>) => {
    try {
      logEvent(event, eventParams);
    } catch (e) {
      debug(e);
    }
  };

  trackOnboardingEvent = (event: EventNames, eventParams?: Record<string, any>) => {
    try {
      this.trackEvent(event, eventParams);
    } catch (e) {
      debug(e);
    }
  };
}

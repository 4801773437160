import { RootStore } from 'stores/root';

export function useAuthCheck(store: RootStore) {
  // ensure we have an authenticated user with a member instance
  if (!store.auth.user || !store.members.current) {
    return null;
  }

  return true;
}

export function useAppReadyCheck(store: RootStore) {
  // ensure we have an authenticated user with a member instance and a team is loaded
  if (!useAuthCheck(store) || !store.teams.currentTeam) {
    return null;
  }

  return true;
}

import { gql } from '@apollo/client';
import { Organization } from '@caravel/types';

export const GET_ORGANIZATIONS = gql`
  query getOrganizations($after: String, $first: Int, $query: String, $orderBy: ItemOrder) {
    community {
      id
      stats {
        organizationsCount
      }
      organizations(after: $after, first: $first, query: $query, orderBy: $orderBy) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          node {
            id
            annualRevenue
            description
            domain
            employeesRange
            facebookHandle
            firstActive
            foundedYear
            industry
            linkedinHandle
            location
            memberCount
            name
            ticker
            twitterHandle
            type
          }
        }
        meta {
          facets {
            counts {
              count
              value
            }
            name
            totalValues
          }
          hits
          query
        }
      }
    }
  }
`;

export interface GqlOrganizationsRequestType {
  after?: string;
  first?: string;
  query?: string;
  orderBy?: { field: string; direction: string };
}

export interface GqlOrganizationsResponseType {
  community: {
    id: string;
    stats: {
      organizationsCount: number;
    };
    organizations: {
      edges: {
        node: Organization;
      }[];
      meta: {
        facets:
          | {
              counts: {
                count: number;
                value: string;
              }[];
              name: string;
              totalValues: number;
            }[]
          | null;
        hits: number;
        query: string;
      };
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  };
}

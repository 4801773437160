import { gql } from '@apollo/client';
import { ComposerMessages } from '@caravel/types';

export const GET_COMPOSERS = gql`
  query composerMessages($after: String, $first: Int, $last: Int, $before: String, $orderBy: ItemOrder) {
    community {
      id
      composerMessages(after: $after, first: $first, last: $last, before: $before, orderBy: $orderBy) {
        edges {
          node {
            id
            title
            content
            kind
            channel {
              id
            }
            createdBy
            sentAt
            sentTo {
              id
            }
            recepientCount
            segment {
              id
              name
              people {
                id
                name
                avatar
              }
            }
            messagesSent {
              messageLink
              member {
                id
                name
                avatar
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

export interface GqlComposersRequestType {
  after?: string;
  first?: number;
  last?: number;
  before?: string;
  orderBy?: { field: string; direction: string };
}

export interface GqlComposersResponseType {
  community: {
    id: string;
    composerMessages: {
      edges: {
        node: ComposerMessages;
      }[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  };
}

import { Column, Flex, Row } from '@caravel/components/src';
import { BLACK, GINGER, NEUTRALS } from '@caravel/utils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, ClickAwayListener, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CrownIcon } from '../../assets/svgs/crown.svg';
import { ReactComponent as InfoIcon } from '../../assets/svgs/information.svg';
import { ReactComponent as CommsorLogo } from '../../assets/svgs/logo-white-bg-text.svg';
import onboardingImage from './champions-onboarding.png';

export const OnboardingModal = () => {
  const BETA_ONBOARDING_STORAGE_KEY = 'beta-onboarding-modal-viewed';
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const handleClose = () => {
    window.localStorage.setItem(BETA_ONBOARDING_STORAGE_KEY, 'true');
    setOnboardingModalOpen(false);
  };

  useEffect(() => {
    const hasViewedOnboarding = window.localStorage.getItem(BETA_ONBOARDING_STORAGE_KEY);
    if (!hasViewedOnboarding || hasViewedOnboarding !== 'true') {
      setOnboardingModalOpen(true);
    }
  }, []);

  return (
    <Modal open={onboardingModalOpen} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Row
        width="90%"
        maxWidth="1280px"
        height="75vh"
        maxHeight="750px"
        sx={{ background: 'white', overflowY: 'auto', '&:focus-visible': { outline: 'none' } }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Row>
            <Column width="50%">
              <Column height="100%" padding="96px 80px 32px 80px" justifyContent="space-between" gap="40px">
                <Column gap="40px">
                  <Box>
                    {/* <Flex alignItems="center" gap="10px"> */}
                    <Typography component="h1" variant="headline1" display="inline-block">
                      Welcome to Commsor Champions
                      <Box
                        component="span"
                        display="inline-block"
                        borderRadius="4px"
                        padding="8px"
                        marginLeft="10px"
                        sx={{
                          background: `linear-gradient(180deg, #FED74D 0%, #FDAB00 100%)`,
                          verticalAlign: 'middle',
                        }}
                      >
                        <Typography
                          color={NEUTRALS.CEYLON}
                          fontSize="12px"
                          fontWeight={700}
                          lineHeight="normal"
                          letterSpacing="0.24px"
                          textTransform="uppercase"
                        >
                          Beta
                        </Typography>
                      </Box>
                    </Typography>
                    {/* </Flex> */}
                  </Box>
                  <Column gap="16px">
                    <Row gap="8px">
                      <div>
                        <CrownIcon style={{ color: BLACK, width: '20px', height: '20px' }} />
                      </div>
                      <Column gap="4px">
                        <Typography component="h3" variant="headline3" color={NEUTRALS.DARJEELING} fontWeight={500}>
                          Level up your champion management
                        </Typography>
                        <Typography component="p" variant="bodySmall" color={NEUTRALS.OOLONG}>
                          Leave spreadsheets in the past. Use Commsor Champions for advanced features that track
                          champions & advocacy activities better than spreadsheets.
                        </Typography>
                      </Column>
                    </Row>
                    <Row gap="8px">
                      <div>
                        <CrownIcon style={{ color: BLACK, width: '20px', height: '20px' }} />
                      </div>
                      <Column gap="4px">
                        <Typography component="h3" variant="headline3" color={NEUTRALS.DARJEELING} fontWeight={500}>
                          Close more deals
                        </Typography>
                        <Typography component="p" variant="bodySmall" color={NEUTRALS.OOLONG}>
                          Create powerful reference connections between prospects and champions to increase your deal
                          success.
                        </Typography>
                      </Column>
                    </Row>
                    <Row gap="8px">
                      <div>
                        <CrownIcon style={{ color: BLACK, width: '20px', height: '20px' }} />
                      </div>
                      <Column gap="4px">
                        <Typography component="h3" variant="headline3" color={NEUTRALS.DARJEELING} fontWeight={500}>
                          Wall of love
                        </Typography>
                        <Typography component="p" variant="bodySmall" color={NEUTRALS.OOLONG}>
                          Store all your social proofs in one central hub with advanced capabilities. Showcase your
                          brand's successes!
                        </Typography>
                      </Column>
                    </Row>
                    <Flex
                      gap="8px"
                      borderRadius="8px"
                      padding="8px"
                      border={`1px solid ${NEUTRALS.JASMINE}`}
                      sx={{ background: NEUTRALS.CHAMOMILE }}
                    >
                      <div>
                        <InfoIcon style={{ color: GINGER[300], width: '24px', height: '24px' }} />
                      </div>
                      <Typography component="p" variant="bodySmall" color={NEUTRALS.OOLONG}>
                        Open beta ends 2023-11-03. After that time you'll need switch to a free or paid plan. Plan
                        details coming soon.
                      </Typography>
                    </Flex>
                    <Row width="100%" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ minWidth: '80px' }}
                        onClick={handleClose}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Continue to Workspace
                      </Button>
                    </Row>
                  </Column>
                </Column>
                <Box>
                  <CommsorLogo style={{ height: '20px' }} />
                </Box>
              </Column>
            </Column>
            <Column width="50%" sx={{ background: GINGER[50] }} justifyContent="center">
              <img
                src={onboardingImage}
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  display: 'block',
                  objectFit: 'contain',
                }}
              />
            </Column>
          </Row>
        </ClickAwayListener>
      </Row>
    </Modal>
  );
};

import { gql } from '@apollo/client';
import { Integration } from '@caravel/types';

export const GET_INTEGRATIONS = gql`
  query getIntegrations($orderBy: ItemOrder) {
    community {
      id
      integrations(orderBy: $orderBy) {
        id
        name
        description
        strategy
        authMethod
        launchedAt
        popularity
        oauthUrl
        channels {
          id
          name
          kind
          createdAt
          createdBy
          errors
          status
          integration {
            id
          }
          lastActivity {
            occurredAt
          }
        }
      }
    }
  }
`;

export interface GqlIntegrationsRequestType {
  orderBy?: { field: string; direction: string };
}

export interface GqlIntegrationsResponseType {
  community: {
    id: string;
    integrations: Integration[];
  };
}

import { extractValidFeatures, FeatureName } from '@caravel/utils';
import { doc, DocumentReference, getDoc, onSnapshot } from 'firebase/firestore';
import { getDb, isUndefinedOrNull } from 'helpers';
import isEmpty from 'lodash/isEmpty';
import { action, computed, makeObservable, observable, ObservableMap } from 'mobx';
import { RootStore } from 'stores/root';

import { BaseStore } from './base';

export class FeaturesStores extends BaseStore {
  private features = observable.map<FeatureName, boolean>([]);

  teamFeatures?: ObservableMap<FeatureName, boolean> = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable<FeaturesStores>(this, {
      teamFeatures: observable,
      hasAutomations: computed,
      hasBigQuery: computed,
      hasExportMentions: computed,
      hasRedactions: computed,
      hasSources: computed,
      hasFeeds: computed,
      hasSearchMaps: computed,
      hasPayments: computed,
      hasReports: computed,
      hasExportTrainingTexts: computed,
      hasHubEvents: computed,
      hasFrontFeature: computed,
      hasDeleteConnector: computed,
      hasEnsembleTesting: computed,
      hasAppReviews: computed,
      hasWebhooks: computed,
      hasTrove: computed,
      hasDomains: computed,
      hasSalesforce: computed,
      hasPdfExport: computed,
      hasTeamQAReport: computed,
      hasCommunityInfo: computed,
      hasCrm: computed,
      consumeFeatures: action,
      hasDashboard: computed,
      hasSegments: computed,
      hasOrganizations: computed,
      hasInsightsOverview: computed,
      hasTroveSkipLogic: computed,
      hasSettingsPlans: computed,
      hasSettingsBilling: computed,
      hasChampions: computed,
      hasMergeMembers: computed,
      hasComposer: computed,
      hasTeamLogo: computed,
      hasNylas: computed,
      showChampionsNav: computed,
    });
  }

  get showChampionsNav() {
    return this.hasChampions;
  }

  get hasAutomations() {
    return this.hasFeature('automations');
  }

  get hasBigQuery() {
    return this.hasFeature('bigquery');
  }

  get hasExportMentions() {
    return this.hasFeature('exportMentions');
  }

  get hasRedactions() {
    return this.hasFeature('redactions');
  }

  get hasSources() {
    return this.hasFeature('sources');
  }

  get hasFeeds() {
    return this.hasFeature('feeds');
  }

  get hasSearchMaps() {
    return this.hasFeature('searchMaps');
  }

  get hasPayments() {
    return this.hasFeature('payments');
  }

  get hasReports() {
    return this.hasFeature('reports');
  }

  get hasExportTrainingTexts() {
    return this.hasFeature('exportTrainingTexts');
  }

  get hasHubEvents() {
    return this.hasFeature('hubEvents');
  }

  get hasFrontFeature() {
    return this.hasFeature('front');
  }

  get hasDeleteConnector() {
    return this.hasFeature('deleteConnector');
  }

  get hasEnsembleTesting() {
    return this.hasFeature('ensembleTesting');
  }

  get hasAppReviews() {
    return this.hasFeature('appStore') || this.hasFeature('playStore');
  }

  get hasWebhooks() {
    return this.hasFeature('webhooks');
  }

  get hasTrove() {
    return this.hasFeature('trove');
  }

  get hasDomains() {
    return this.hasFeature('domains');
  }

  get hasHelpscout() {
    return this.hasFeature('helpscout');
  }

  get hasEmergingThemes() {
    return this.hasFeature('emergingThemes');
  }

  get hasSalesforce() {
    return this.hasFeature('salesforce');
  }

  get hasPdfExport() {
    return this.hasFeature('pdfExport');
  }

  get hasTeamQAReport() {
    return this.hasFeature('teamQAReport');
  }

  get hasCommunityInfo() {
    return this.hasFeature('communityInfo');
  }

  get hasCrm() {
    return this.hasFeature('crm');
  }

  get hasDashboard() {
    return this.hasFeature('dashboard');
  }

  get hasSegments() {
    return this.hasFeature('segments');
  }

  get hasOrganizations() {
    return this.hasFeature('organizations');
  }

  get hasInsightsOverview() {
    return this.hasFeature('insightsOverview');
  }

  get hasSettingsPlans() {
    return this.hasFeature('settingsPlans');
  }

  get hasSettingsBilling() {
    return this.hasFeature('settingsBilling');
  }

  get hasTroveSkipLogic() {
    return this.hasFeature('troveSkipLogic');
  }

  get hasChampions() {
    return this.hasFeature('champions');
  }

  get hasMergeMembers() {
    return this.hasFeature('mergeMembers');
  }

  get hasComposer() {
    return this.hasFeature('composer');
  }

  get hasNylas() {
    return this.hasFeature('nylas');
  }

  get docRef() {
    return doc(getDb(), 'features', 'global');
  }

  get hasTeamLogo() {
    return this.hasFeature('teamLogo');
  }

  setTeamFeatures(teamId: string) {
    this.disposeOf('team-features');
    const teamFeaturesRef = doc(getDb(), 'features', teamId);
    this.addDisposable(this.subToTeamFeatures(teamFeaturesRef), 'team-features');
  }

  async setup() {
    await super.setup();
    this.authSubs.addEffect('global-features', () => this.subToFeatures());
    try {
      const globalFeatures = await getDoc(this.docRef);
      this.consumeFeatures('global', globalFeatures.data() || {});
    } catch (e) {
      console.debug(e);
    }
  }

  async teardown() {
    await super.teardown();
  }

  consumeFeatures = (scope: 'global' | 'team', features: Record<string, any>) => {
    const extractedFeatures = extractValidFeatures(features);
    (Object.keys(extractedFeatures) as FeatureName[]).forEach(featureName => {
      const enabled = extractedFeatures[featureName]!;
      if (!isUndefinedOrNull(enabled)) {
        if (scope === 'global') {
          this.features.set(featureName, enabled);
        } else if (scope === 'team') {
          if (!this.teamFeatures) {
            this.teamFeatures = observable.map<FeatureName, boolean>([]);
          }
          this.teamFeatures?.set(featureName, enabled);
        }
      }
    });
  };

  getTeamFeatures = async (teamId: string) => {
    // First, clear the any existing teamFeatures to ensure that we don't
    // have stale data if we are switching teams.
    this.teamFeatures = undefined;
    const features = await getDoc(doc(getDb(), 'features', teamId));
    this.consumeFeatures('team', features.data() || {});
  };

  hasFeature = (featureName: FeatureName) => {
    if (this.teamFeatures && this.teamFeatures.has(featureName)) {
      // has team feature overrides
      return this.teamFeatures.get(featureName);
    }

    if (!this.features.has(featureName)) {
      return false;
    }
    return this.features.get(featureName);
  };

  private subToFeatures = () => {
    return onSnapshot(this.docRef, snapshot => {
      const data = snapshot.data() || {};
      this.consumeFeatures('global', data);
    });
  };

  private subToTeamFeatures = (teamFeaturesRef: DocumentReference) => {
    return onSnapshot(teamFeaturesRef, snapshot => {
      const data = snapshot.data() || {};

      if (isEmpty(data)) {
        // team has NO feature overrides
        return;
      }

      this.consumeFeatures('team', data);
    });
  };
}

import { gql } from '@apollo/client';
import { User } from '@caravel/types';

export const CREATE_NOTE = gql`
  mutation upsertNote($personId: String!, $text: String!) {
    upsertPersonNote(input: { personId: $personId, text: $text }) {
      result {
        id
        text
        author {
          id
          firebaseUserId
          email
          firstName
          lastName
          role
        }
        createdAt
      }
    }
  }
`;

export interface GqlCreateNoteRequestType {
  personId: string;
  text: string;
}

export interface GqlCreateNoteResponseType {
  upsertPersonNote: {
    result: {
      id: string;
      text: string;
      author: User;
      createdAt: Date;
    };
  };
}

import { Skeleton } from '@mui/material';
import React from 'react';

import { Column } from '../flex';

export function SkeletonTable() {
  return (
    <Column>
      <Skeleton variant="rectangular" sx={{ width: '100%', height: '103px' }} />
      <Column sx={{ padding: '15px 30px', gap: '15px' }}>
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '36px' }} />
      </Column>
    </Column>
  );
}

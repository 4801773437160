import { Logger, useAppReadyCheck } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('feeds-routes');

const TestOverview = observer(() => {
  return <></>;
});

export const OverviewRoutes: FC = observer(() => {
  const store = useStore();

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> rendering overview`);

  return (
    <Switch>
      <Route path="/overview" component={TestOverview} />
    </Switch>
  );
});

import { GREY_PALETTE } from '@caravel/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverOrigin,
  SxProps,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

export interface KebabMenuOption {
  enabled?: boolean;
  unclickable?: boolean;
  divider?: boolean;
  icon?: React.ReactNode;
  color?: string;
  label: string;
  onClick: () => void;
}

export const KebabMenu = observer(
  (props: {
    options: KebabMenuOption[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    sx?: SxProps;
  }) => {
    const theme = useTheme();
    const { options, anchorOrigin, transformOrigin, sx } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const renderMenuItem = (item: KebabMenuOption) => {
      const onClick = () => {
        handleCloseActions();
        item.onClick();
      };
      return (
        <MenuItem divider={item.divider} disabled={item.unclickable} key={item.label} onClick={onClick}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText sx={{ color: item.color || 'inherit' }} primary={item.label} />
        </MenuItem>
      );
    };

    const handleClickActions = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseActions = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClickActions}>
          <MoreVertIcon style={{ color: GREY_PALETTE[3] }} />
        </IconButton>

        <Menu
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={
            anchorOrigin ?? {
              vertical: 'bottom',
              horizontal: 'right',
            }
          }
          transformOrigin={
            transformOrigin ?? {
              vertical: 'top',
              horizontal: 'right',
            }
          }
          PaperProps={{
            sx: {
              minWidth: '200px',
            },
          }}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '0',
              background: GREY_PALETTE[1],
              boxShadow: '0px 4px 16px rgb(35 31 32 / 24%)',
            },
            '& .MuiMenuItem-root': {
              '&:hover': {
                backgroundColor: GREY_PALETTE[2],
              },
              padding: '8px 8px',
              '& .MuiListItemIcon-root': {
                minWidth: '30px',
              },
            },
            '& .MuiMenuItem-divider': {
              borderColor: theme.grey[2],
            },
            '& .MuiTypography-root': {
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '16px',
            },
            ...sx,
          }}
        >
          {options.filter(item => item.enabled).map(renderMenuItem)}
        </Menu>
      </>
    );
  },
);

import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useDualPanels = () => {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  // On medium screens and above, we want the 2 primary columns (Grid items, in this case)
  // to vertically scroll independent of one another. To achieve this, we detect
  // the medium breakpoint and set some styles accordingly.
  const containerStyle = useMemo(() => {
    return isMediumUp ? { overflow: 'hidden' } : {};
  }, [isMediumUp]);

  const panelStyle = useMemo(() => {
    return isMediumUp ? { overflowY: 'auto', height: '100%' } : {};
  }, [isMediumUp]);

  return {
    containerStyle: containerStyle,
    panelStyle: panelStyle,
  };
};

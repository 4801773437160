import { InvalidUsageReason } from '@caravel/types';
import EventEmitter from 'events';
import { Logger } from 'helpers';
import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'stores/root';
import { TroveForm } from 'stores/ui/sources/trove-form';
import { v4 as uuid } from 'uuid';

import { AppToastStore } from './app-toasts';
import { BaseStore } from './base';
import { ProofForm } from './ui/proof-form';
import { SegmentForm } from './ui/segment-form';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('ui-store');

export type CustomModals = InvalidUsageReason | 'analyst' | 'train-a-model' | 'crop-image';

export class UIStore extends BaseStore {
  debugName = 'UIStore';

  appToasts = new AppToastStore(this.rootStore);

  troveForm = new TroveForm(this.rootStore);

  ProofForm = new ProofForm(this.rootStore);

  SegmentForm = new SegmentForm(this.rootStore);

  focusedSegmentIds?: string[] = undefined;

  redactedSegmentIds = observable.array<string>([]);

  openModals = observable.array<CustomModals>([]);
  modalData = observable.map<CustomModals, Record<string, any> | undefined>([]);

  workTasks = observable.map<string, boolean>([]);

  appBarHeight = 60;

  events = new EventEmitter();

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      focusedSegmentIds: observable,
      openModals: observable,
      modalData: observable,
      appBarHeight: observable,
      isWorking: computed,
      addRedactedSegment: action,
      startWork: action,
      stopWork: action,
      workOn: action,
      setFocusedSegmentIds: action,
      openModal: action,
      closeModal: action,
    });
  }

  get isWorking() {
    return Array.from(this.workTasks.values()).filter(v => v).length > 0;
  }

  async setup() {
    await super.setup();
    await this.appToasts.initialize();
    await this.troveForm.initialize();
    await this.ProofForm.initialize();
    await this.SegmentForm.initialize();
  }

  teardown() {
    this.troveForm.teardown();
    this.ProofForm.teardown();
    this.SegmentForm.initialize();
    this.redactedSegmentIds.clear();
    this.appToasts.teardown();
    super.teardown();
  }

  addRedactedSegment = (segmentId: string) => {
    this.redactedSegmentIds.push(segmentId);
  };

  getTasks = () => Array.from(this.workTasks.keys());

  startWork = (uid: string = uuid()) => {
    this.workTasks.set(uid, true);
    return uid;
  };

  stopWork = (uid: string) => {
    this.workTasks.delete(uid);
  };

  workOn = async (func: () => Promise<any>, workId?: string) => {
    workId = this.startWork(workId);
    try {
      return await func();
    } finally {
      this.stopWork(workId);
    }
  };

  setFocusedSegmentIds(segmentIds: string[] | undefined) {
    this.focusedSegmentIds = segmentIds;
  }

  openModal = (modal: CustomModals, data?: any) => {
    if (this.openModals.includes(modal)) {
      return;
    }
    this.modalData.set(modal, data);
    this.openModals.push(modal);
  };

  closeModal = (modal: CustomModals) => {
    this.openModals.remove(modal);
  };

  copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    this.rootStore.notifications.display({
      severity: 'success',
      message: 'Copied to clipboard',
      duration: 3000,
    });
  };
}

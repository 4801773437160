import { Column } from '@caravel/components/src';
import { ChampionsSettingsConfig } from '@caravel/types/src';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useStore } from 'stores';

import { AppToolbar } from '../../app-toolbar';
import { CustomizeChampionsForm } from './customize-champions-form';

export const ChampionsSettingsContainer = observer(() => {
  const store = useStore();
  const [settingsLoaded, setSettingsLoaded] = React.useState(false);
  const [settings, setSettings] = React.useState<ChampionsSettingsConfig | undefined>(undefined);
  const [directURL, setDirectURL] = React.useState<string>('');

  const loadSettings = useCallback(() => {
    if (settingsLoaded) return;
    store.ui.workOn(async () => {
      const url = await store.teams.champions.getURL();
      setDirectURL(url ?? '');
      await store.teams.champions.fetchSettings();
      const settings = store.teams.champions.championsSettings;
      setSettings(settings ?? {});
    });
    setSettingsLoaded(true);
  }, [settingsLoaded, store.teams.champions, store.ui]);

  useEffect(() => {
    if (!settingsLoaded) {
      loadSettings();
    }
    return () => {
      store.teams.champions.resetSettings();
    };
  }, [loadSettings, settingsLoaded, store.teams.champions]);

  return (
    <Column>
      <AppToolbar title="References" subtitle={'Get your references page up and running in just one minute'} />
      <CustomizeChampionsForm url={directURL} settings={settings} />
    </Column>
  );
});

export default ChampionsSettingsContainer;

import { Row } from '@caravel/components/src';
import { ProofSourceType } from '@caravel/types/src';
import { GREY_PALETTE } from '@caravel/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from 'stores';

import { ReactComponent as CloseIcon } from '../../../assets/svgs/close-drawer.svg';
import { CaseStudyForm } from './case-study-form';
import { SocialMediaForm } from './social-media-form';
import { TextQuoteForm } from './text-quote-form';
import { VideoForm } from './video-form';

const getTab = (type: ProofSourceType | undefined) => {
  switch (type) {
    case 'VIDEO':
      return 0;
    case 'LINKEDIN':
    case 'TWITTER':
      return 1;
    case 'TEXTQUOTE':
      return 2;
    case 'CASESTUDY':
      return 3;
    default:
      return 0;
  }
};

const getSourceType = (tab: number): ProofSourceType => {
  switch (tab) {
    case 0:
      return 'VIDEO';
    case 1:
      return 'TWITTER';
    case 2:
      return 'TEXTQUOTE';
    case 3:
      return 'CASESTUDY';
    default:
      return 'VIDEO';
  }
};

export const ProofFormModal = observer(() => {
  const store = useStore();
  const proofFormStore = store.ui.ProofForm;
  const proofId = proofFormStore.proofId;

  const handleTabChange = (evt: React.SyntheticEvent, value: number) => {
    proofFormStore.createProof(getSourceType(value));
  };

  const submitProof = async () => {
    await proofFormStore.saveChanges();
    if (proofFormStore.showErrors) {
      return;
    }
    proofFormStore.resetFormProps();
    store.teams.proofLibrary.fetchProofCollection();
  };

  useEffect(() => {
    if (proofId) {
      const proof = store.teams.proofLibrary.collection.find(proof => proof.id === proofId);
      if (proof) {
        proofFormStore.setProofForm(proof, proofId);
      }
    }
  }, [proofId, proofFormStore, store.teams.proofLibrary.collection]);

  return (
    <Dialog
      open={proofFormStore.showProofForm}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: 'fit-content',
          width: '100%',
          background: '#fff',
          borderRadius: '8px',
          padding: '8px',
        },
      }}
    >
      <DialogTitle>
        <Row sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'end', marginLeft: '36px' }}>
          <Typography variant="headline2" sx={{ fontWeight: 600 }}>
            Add Proof to your library
          </Typography>
          <IconButton sx={{ marginBottom: '10px' }} onClick={proofFormStore.closeFormModal}>
            <CloseIcon />
          </IconButton>
        </Row>
      </DialogTitle>
      <DialogContent sx={{ padding: '30px 36px', paddingBottom: '0px' }}>
        <Box sx={{ width: '100%', marginTop: '20px' }}>
          <Row>
            <Tabs
              value={getTab(proofFormStore.formProps?.sourceType ?? 'VIDEO')}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab sx={{ color: GREY_PALETTE[3] }} label="Video" />
              <Tab sx={{ color: GREY_PALETTE[3] }} label="Social Media" />
              <Tab sx={{ color: GREY_PALETTE[3] }} label="Text Quotes" />
              <Tab sx={{ color: GREY_PALETTE[3] }} label="Case Study" />
            </Tabs>
          </Row>
          <Row>{getTab(proofFormStore.formProps?.sourceType ?? 'VIDEO') === 0 && <VideoForm />}</Row>
          <Row>{getTab(proofFormStore.formProps?.sourceType ?? 'VIDEO') === 1 && <SocialMediaForm />}</Row>
          <Row>{getTab(proofFormStore.formProps?.sourceType ?? 'VIDEO') === 2 && <TextQuoteForm />}</Row>
          <Row>{getTab(proofFormStore.formProps?.sourceType ?? 'VIDEO') === 3 && <CaseStudyForm />}</Row>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '30px 36px' }}>
        <Button
          variant="contained"
          color="tertiary"
          sx={{ padding: '10px 20px' }}
          autoFocus
          onClick={proofFormStore.closeFormModal}
        >
          Cancel
        </Button>
        <Button
          disabled={proofFormStore.showErrors && !proofFormStore.canSave}
          variant="contained"
          color="primary"
          sx={{ padding: '10px 20px' }}
          onClick={submitProof}
          autoFocus
        >
          {proofFormStore.formLoading ? 'Editing...' : proofFormStore.proofId ? 'Save changes' : 'Add to Library'}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

import { FinishSignInContainer as FinishSignIn } from 'components/onboarding/finish/finish-sign-in-container';
import { FinishSignUpContainer as FinishSignUp } from 'components/onboarding/finish/finish-sign-up-container';
import { SignIn } from 'components/onboarding/sign-in';
import { SignUp } from 'components/onboarding/sign-up';
import { VerifyEmail } from 'components/verify-email';
import { observer } from 'mobx-react';
import React from 'react';
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { AcceptInvite } from '../accept-invite';

export const UnauthenticatedRoutes = observer((props: { starRender: RouteProps['render'] }) => {
  const store = useStore();
  const history = useHistory();

  return (
    <Switch>
      <Route path="/accept-invite" component={AcceptInvite} />
      <Route path="/verify" component={VerifyEmail} />
      <Route path="/sign-in" component={SignIn} />
      <Route path="/sign-up" component={SignUp} />
      <Route
        path="/sign-out"
        render={() => {
          store.ui.workOn(async () => {
            await store.auth.signOut();
            history.push('/sign-in');
          });
          return null;
        }}
      />
      <Route path="/finish-sign-in" component={FinishSignIn} />
      <Route path="/finish-sign-up" component={FinishSignUp} />

      <Route path="*" render={props.starRender} />
    </Switch>
  );
});

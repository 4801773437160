import { GREY_PALETTE } from '@caravel/utils';
import { Tab as MuiTab, TabProps } from '@mui/material';
import React from 'react';

import { theme } from './theme';

export const Tab = React.forwardRef<HTMLDivElement, TabProps>(({ ...props }, ref) => (
  <MuiTab
    ref={ref}
    {...props}
    sx={{
      minHeight: '32px',
      textTransform: 'none',
      padding: '0px',
      color: GREY_PALETTE[4],
      '&.Mui-selected': {
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)',
        fontWeight: theme.typography.fontWeightMedium,
        borderBottom: 'none',
        backgroundColor: 'white',
      },
    }}
  />
));

Tab.displayName = 'Tab';

import { Column, PageHeader, WideColumn } from '@caravel/components';
import { Divider } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { PlansInfo } from './plans-info';

export const PlansContainer = observer(() => {
  const store = useStore();
  const planId = store.team?.planId;
  return (
    <Column>
      <Column
        sx={{
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <PageHeader headline="Plans & Add-ons" description="For enterprise plans, please contact us." />
        <Divider sx={{ marginBottom: '30px' }} />
      </Column>
      <WideColumn>
        <PlansInfo planId={planId} />
      </WideColumn>
    </Column>
  );
});

export default PlansContainer;

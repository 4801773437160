import { gql } from '@apollo/client';

export const GET_TOTAL_PEOPLE = gql`
  query getTotalPeople($startDate: Date, $endDate: Date) {
    community {
      id
      stats {
        peopleCount
        members(input: { start: $startDate, end: $endDate }) {
          windowSize
          delta
          previousDelta
          total
          counts {
            date
            delta
            total
          }
        }
      }
    }
  }
`;

export interface GqlTotalPeopleRequestType {
  startDate: string;
  endDate: string;
}

export interface GqlTotalPeopleResponseType {
  community: {
    id: string;
    stats: {
      peopleCount: number;
      members: {
        windowSize: number;
        delta: number;
        previousDelta: number;
        total: number;
        counts: {
          date: string;
          delta: number;
          total: number;
        }[];
      };
    };
  };
}

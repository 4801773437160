import { Toast, ToastProps } from '@caravel/components';
import { Grow } from '@mui/material';
import { observer } from 'mobx-react';
import { renderNotificationTemplate } from 'models';
import React, { useMemo } from 'react';
import { useStore } from 'stores';

export interface DarkToastProps {
  notificationId: string;
}

export const DarkToast = observer((props: DarkToastProps) => {
  const store = useStore();
  const { notificationId } = props;
  const notification = store.teams.notifications.get(notificationId);

  const icon = useMemo<ToastProps['icon']>(() => {
    if (!notification) {
      return 'info';
    }
    const { taskStatus } = notification;
    return taskStatus === 'failed' ? 'alert' : taskStatus === 'completed' ? 'check' : 'info';
  }, [notification]);

  if (!notification) {
    console.warn('Expected to have access to notification');
    return null;
  }

  const { taskType, taskStatus, templateName } = notification;
  const template = templateName || `${taskType}-${taskStatus}`;
  const message = renderNotificationTemplate(template, notification);

  const handleDismiss = async () => {
    store.teams.notifications.dismiss(notification);
  };

  return (
    <Grow appear in={notification.dismissing ? false : true}>
      <Toast
        message={message}
        icon={icon}
        dismissable={!notification.dismissing}
        onDismiss={handleDismiss}
        progress={notification.progress}
        progressType={
          notification.progress !== undefined ? 'determinate' : taskStatus === 'running' ? 'indeterminate' : undefined
        }
      />
    </Grow>
  );
});

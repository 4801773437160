export const DINO_STANDING_TWO_LEGS = `
                                                                    ▄▓███▓▄
                                                                  ▐▓█▀  ▐▓█▓
                                                                 ▄▓██    ▐██▄
                                                              ▄▓██▓▀▀ ▄▄ ▐████▓▄
                                                             ▐██▀     ▀▀     ▐██▌
                                                             ▓█▓              ▓█▓
                                                             ▓█▌      ▄▄▓▓▓▓▓██▓
                                                             ▓█▌    ▐▓██▀▀▀▀▀
                                                             ▓█▌    ▐██░
                                                            ▐▓█▒    ▐██░
                                                            ▐██▒    ▐██░
                                                            ▐██     ▐██░
                                                            ▓█▌     ▐██░
                                                           ▐██▒     ▐██░
                                                           ▓███▓    ▐██░
                                                          ▐██████   ▐██░
                                                         ▐▓██████▒  ▐██░
                                                        ▐▓██████▌   ▐██░
                                                        ▓███▌       ▐██░
                                                      ▄████▌        ▐██░
                                                    ▄▓█████▌        ▐██░
                                                   ▄████████▒       ▐██░        ▄▄
                                                ▄▓██████████▓       ▐███████████████
                                             ▄▄██████████████▒  ▄▄▄▄▓███████████████
                                           ▄▓████████████████▒      ▀▀▀▀▀▀▀▀▀▀▀▀▀▓██
                                        ▄▓█████████▓▀ ▀▓███▓░                    ▐██
                                      ▄▓███████████                              ▐██
                                    ▐▓█████████████▒                   ▄▄▄▄▄▄▓▓▓▓███
                                   ▄████████████████▒         ▄▓▓▓▓▓█████▓▀▀▀▀▀▀▀
                                  ▓█████████████████▓               ░██▌
                                 ▄███████▀▀▀████████▓░               ▓█▌
                                ▐███████▒    ▀▀████▀                 ▓█▌
                                ▓███████               ▓▓▓▓▄▄        ▓█▌
                               ▐▓███████▒                  ▀▓▓▄      ██▌
                               ▐█████████                    ▐██▒   ▐██▒
                               ▐████████▀                      ▓█▒  ▐██▒
                               ▐██▀▀▀                           ▓█▒ ▓██
                               ▐██      ▄                       ▐█▌▒▓█▒
                               ▓█▓     ▐█▒                      ▐██▓██
                              ▐██▒     ▐█▌                      ▐████▒
                             ▐▓█▌      ▓█▓                      ▐███▌
                            ▐██▌       ▓██░                     ▓██▌
                          ▄▓██▒       ▐▓██▓▄                  ▄▓██▀
                        ▄▓██▀         ▓█████▓▄            ▄▄▄▓██▀
                     ▄▄▓██▀          ▓██████████▓▄       ▓███▀▀
                 ▄▄▓███▀           ▄▓█████████████▄      ▐██▒
       ▄▄▄▄▄▄▓▓▓███▓▀           ▄▓▓██▀▓████████▓▓█▓       ▐██░
    ▄▓██████▓▀▀▀           ▄▄▄▓███▀   ▓████████▓░▓█▌      ▐▓█▓
    ███▄       ▄▄▄▄▄▄▄▓▓▓▓███▓▀▀      ▓████████▌ ▐██▌▄     ▐██▒
     ▀██████████████▓▀▀▀▀▀            ▓████████▌  ▐████████████
`;

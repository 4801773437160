import { Column } from '@caravel/components/src';
import React from 'react';

import { Account } from '../../workspace/account';
import { Billing } from '../../workspace/billing';
import { ChampionsConfig } from '../../workspace/champions';
import { IdealCustomerProfile } from '../../workspace/ideal-customer-profile';
import { Plans } from '../../workspace/plans';
import { Teammates } from '../../workspace/teammates';

const WorkspaceContainer = (props: { children: any }) => {
  const { children } = props;

  return (
    <>
      <Column
        sx={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        {children}
      </Column>
    </>
  );
};

export const TeamSettings = () => {
  return (
    <WorkspaceContainer>
      <Teammates />
    </WorkspaceContainer>
  );
};

export const PlanSettings = () => {
  return (
    <WorkspaceContainer>
      <Plans />
    </WorkspaceContainer>
  );
};

export const AccountSettings = () => {
  return (
    <WorkspaceContainer>
      <Account />
    </WorkspaceContainer>
  );
};

export const BillingSettings = () => {
  return (
    <WorkspaceContainer>
      <Billing />
    </WorkspaceContainer>
  );
};

export const ChampionsSettings = () => {
  return <ChampionsConfig />;
};

export const IdealCustomerProfileSettings = () => {
  return (
    <WorkspaceContainer>
      <IdealCustomerProfile />
    </WorkspaceContainer>
  );
};

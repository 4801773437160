import { Logger, useAppReadyCheck } from 'helpers';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'stores';

import {
  AccountSettings,
  BillingSettings,
  ChampionsSettings,
  IdealCustomerProfileSettings,
  PlanSettings,
  TeamSettings,
} from '../app-navigation/settings/setting-page-container';
import { IntegrationsRoutes } from './integrations-routes';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { debug } = new Logger('settings-routes');

export const SettingsRoutes: FC = observer(() => {
  const store = useStore();
  const features = store.features;

  if (!useAppReadyCheck(store)) {
    return null;
  }

  debug(`>> rendering settings`);

  return (
    <Switch>
      <Route path="/settings/account" component={AccountSettings} />
      {features.hasSettingsBilling && <Route path="/settings/billing" component={BillingSettings} />}
      {features.hasSettingsPlans && <Route path="/settings/plans" component={PlanSettings} />}
      {store.members.current?.canWrite && <Route path="/settings/team" component={TeamSettings} />}
      <Route path="/settings/ideal-customer-profile" component={IdealCustomerProfileSettings} />
      {features.hasChampions && <Route path="/settings/references" component={ChampionsSettings} />}
      <IntegrationsRoutes />
    </Switch>
  );
});

import { TeamConnection } from '@caravel/types/src';
import { GINGER, makeDateString, makeInitials, NEUTRALS, ROOBIOS, SENCHA } from '@caravel/utils/src';
import { Avatar, Card, CardContent, CardHeader, Link, List, ListItem, Typography, useTheme } from '@mui/material';
import { formatDistance, fromUnixTime } from 'date-fns';
import React, { forwardRef } from 'react';

import { Column, Row } from './flex';

export interface ConnectionsDetailsProps {
  teamConnections: TeamConnection[];
}
export const ConnectionsDetails = forwardRef<HTMLDivElement, ConnectionsDetailsProps>((props, ref) => {
  const theme = useTheme();

  const { teamConnections } = props;

  return (
    <Card
      elevation={0}
      variant="elevation"
      sx={{ backgroundColor: NEUTRALS.CHAMOMILE, borderRadius: '8px', padding: '20px 30px', width: '100%' }}
    >
      <CardHeader sx={{ padding: 0 }} title="Connections" titleTypographyProps={{ variant: 'headline3' }} />
      <CardContent
        sx={{
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <List>
          {teamConnections.map((connection, index) => {
            const isLastItem = teamConnections.length - 1 === index;
            return (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  borderBottom: isLastItem ? 'none' : `1px solid ${NEUTRALS.JASMINE}`,
                  padding: isLastItem ? `${theme.spacing(2)} 0 0 0` : `${theme.spacing(2)} 0`,
                }}
              >
                <Avatar
                  src={connection.photoUrl}
                  alt={connection.name}
                  sx={{ width: '48px', height: '48px', alignSelf: 'start' }}
                >
                  {makeInitials({ name: connection.name })}
                </Avatar>

                <Column flex={1} paddingLeft={theme.spacing(2)}>
                  <Row paddingBottom={theme.spacing(1)}>
                    <Column flex={1}>
                      <Typography variant="headline4">{connection.name}</Typography>
                    </Column>
                    <Column>{/* <ConnectionStatus status={connection.status} /> */}</Column>
                  </Row>
                  <Row justifyContent="space-between">
                    <Column>
                      <Typography color={NEUTRALS.EARL_GREY} variant="bodySmallest">
                        Connected Since
                      </Typography>
                      <Typography color={NEUTRALS.OOLONG} variant="bodySmallest">
                        {makeDateString(fromUnixTime(connection.connectedSince || 0))}
                      </Typography>
                    </Column>
                    <Column marginLeft={theme.spacing(1)}>
                      <Typography color={NEUTRALS.EARL_GREY} variant="bodySmallest">
                        Last Exchange
                      </Typography>
                      <Typography color={NEUTRALS.OOLONG} variant="bodySmallest">
                        {connection.lastExchange
                          ? formatDistance(fromUnixTime(connection.lastExchange), new Date()) + ' ago'
                          : '---'}
                      </Typography>
                    </Column>
                    <Column
                      width="28%"
                      marginLeft={theme.spacing(1)}
                      visibility={connection.totalMeetings ? 'visible' : 'hidden'}
                    >
                      <Typography color={NEUTRALS.EARL_GREY} variant="bodySmallest">
                        Total Meetings
                      </Typography>
                      <Typography color={NEUTRALS.OOLONG} variant="bodySmallest">
                        {connection.totalMeetings}
                      </Typography>
                    </Column>
                  </Row>
                </Column>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
});

const ConnectionStatus = (props: { status: string }) => {
  const { status } = props;
  switch (status) {
    case 'INVITED':
      return (
        <Typography color={GINGER[300]} variant="bodySmallest">
          &bull; Intro Pending
        </Typography>
      );
    case 'ACCEPTED':
      return (
        <Typography color={SENCHA[200]} variant="bodySmallest">
          &bull; Accepted
        </Typography>
      );
    case 'REJECTED':
      return (
        <Typography color={ROOBIOS[300]} variant="bodySmallest">
          &bull; Rejected
        </Typography>
      );
    default:
      return (
        <Link
          component="button"
          variant="bodySmallest"
          onClick={() => {
            console.info('Open ask intro modal');
          }}
          underline="always"
        >
          Ask
        </Link>
      );
  }
};

ConnectionsDetails.displayName = 'ConnectionsDetails';

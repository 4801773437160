import { Column, Flex, NarrowColumn, PageHeader } from '@caravel/components';
import { GINGER, NEUTRALS } from '@caravel/utils';
import { Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as InfoIcon } from '../../../assets/svgs/information.svg';
import { AppToolbar } from '../../app-toolbar';

// eslint-disable-next-line no-empty-pattern
export const BillingView = () => {
  return (
    <Column>
      <AppToolbar title="Billing" />
      <NarrowColumn>
        <PageHeader headline="Billing" description="Manage your plan settings" />
        <Flex
          gap="8px"
          borderRadius="8px"
          padding="8px"
          border={`1px solid ${NEUTRALS.JASMINE}`}
          sx={{ background: NEUTRALS.CHAMOMILE }}
        >
          <div>
            <InfoIcon style={{ color: GINGER[300], width: '24px', height: '24px' }} />
          </div>
          <Typography component="p" variant="bodySmall" color={NEUTRALS.OOLONG}>
            You are currently on Commsor Champions open beta. Open beta ends 2023-11-03. After that time you'll need
            switch to a free or paid plan. Plan details coming soon.
          </Typography>
        </Flex>
      </NarrowColumn>
    </Column>
  );
};

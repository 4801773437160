import { Channel as CoreChannel } from '@caravel/types';
import { makeObservable, observable } from 'mobx';
import { BaseModel, makeToJS, makeUpdate } from 'models/base';

type ChannelProps = Omit<CoreChannel, 'integration'>;

const skeletonProps: ChannelProps = {
  id: '',
  kind: ':override',
  name: '',
  createdAt: new Date(),
  createdBy: null,
  errors: [],
  lastActivity: null,
  status: null,
};

export class Channel extends BaseModel<ChannelProps> implements ChannelProps {
  id = skeletonProps.id;
  kind = skeletonProps.kind;
  name = skeletonProps.name;
  createdAt = skeletonProps.createdAt;
  createdBy = skeletonProps.createdBy;
  errors = skeletonProps.errors;
  lastActivity = skeletonProps.lastActivity;
  status = skeletonProps.status;

  constructor(props: ChannelProps) {
    super();
    makeObservable(this, {
      id: observable,
      kind: observable,
      name: observable,
      createdAt: observable,
      createdBy: observable,
      errors: observable,
      lastActivity: observable,
      status: observable,
    });
    this.update(props);
  }

  toJS = makeToJS(skeletonProps, this);
  update = makeUpdate(skeletonProps, this);
}

import { Typography, TypographyProps } from '@mui/material';
import EmojiConvertor from 'emoji-js';
import React, { forwardRef, useEffect, useState } from 'react';

export function useTextWithEmoji(text?: string) {
  const [parsed, setParsed] = useState('');
  useEffect(() => {
    const converter = new EmojiConvertor();
    converter.replace_mode = 'unified'; // Allow emojis to render for Windows
    setParsed(converter.replace_colons(text ?? ''));
  }, [text]);
  return parsed;
}

export interface EmojiProps extends TypographyProps {
  text: string;
}

export const TextWithEmoji = forwardRef<HTMLSpanElement, EmojiProps>(({ text, ...props }, ref) => {
  const parsed = useTextWithEmoji(text);
  return (
    <Typography {...props} ref={ref}>
      {parsed}
    </Typography>
  );
});

TextWithEmoji.displayName = 'Emoji';

import { Column } from '@caravel/components/src';
import { getComparator, stableSort } from '@caravel/utils';
import { observer } from 'mobx-react';
import { Person } from 'models/person';
import React from 'react';

import { PersonNote } from './person-note';

export const PersonNotes = observer((props: { person: Person }) => {
  const { person } = props;
  const notes = stableSort(person.notes ?? [], getComparator('desc', 'createdAt'));

  return (
    <Column sx={{ marginTop: '4px' }}>
      {notes.map(note => (
        <PersonNote key={note.id ?? ''} note={note} />
      ))}
    </Column>
  );
});

import React, { FC } from 'react';

import { AppProvider } from '../app-provider';
import { AppRoutes } from '../routes/app-routes';

export const AppContainer: FC = () => (
  <AppProvider>
    <AppRoutes />
  </AppProvider>
);

export default AppContainer;

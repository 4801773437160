import { FilterProps, Row, SearchFilter } from '@caravel/components/src';
import { InlineFilter } from '@caravel/components/src/filters/inline-filter';
import { FilterCondition } from '@caravel/types/src';
import { createGQLClient, getFilterQuery, GqlPeopleFacetRequestType, GqlPeopleFacetResponseType } from '@caravel/utils';
import { Collapse } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'stores';
import { PEOPLE_FILTER_OPTIONS_CONFIG } from 'stores/filters';

import { ChampionsFilterMenu } from './filters/champions-filter-menu';

interface ChampionsFiltersProps {
  hideFilters?: boolean;
}
export const ChampionsFilters = observer((props: ChampionsFiltersProps) => {
  const { hideFilters } = props;
  const store = useStore();
  const filterType = 'champions';
  const champions = store.teams.champions;
  const filterStore = store.teams.filters;
  const filters = filterStore.getAttributes(filterType).filters.slice(); //skip source filter
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleRemoveFilter = (index: number) => () => {
    filterStore.onRemoveFilter(filterType, index);
  };

  const handleSubmit = async () => {
    await store.teams.champions.refreshChampions();
  };

  const handleUpdateDynamicFilterQuery = async (
    collectionType: string,
    type: string,
    value: string,
    first: number,
    after: string,
  ) => {
    const query = getFilterQuery(collectionType, type);
    if (!query) {
      throw Error('Invalid query');
    }
    const { teamId, token } = await store.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, process.env.GRAPHQL_HOST!);
    const response = await graphqlClient.query<GqlPeopleFacetRequestType, GqlPeopleFacetResponseType>(query, {
      query: value,
      first,
      after,
    });
    const matchedOptions = response.community.peopleFacets.hits.map(hit => ({
      id: hit.value,
      name: hit.value,
    }));
    return {
      matchedOptions,
      totalHits: response.community.peopleFacets.totalHits,
    };
  };

  return (
    <Collapse in={hideFilters}>
      <Row sx={{ padding: '14px 30px' }}>
        <Row
          sx={{
            flex: 1,
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          {filters.map((filter, i) => {
            const key = `${filter.type}-${i}`;
            const filterProps: FilterProps = {
              ...filter,
              options: PEOPLE_FILTER_OPTIONS_CONFIG[filter.type],
              onChangeFilter: (
                {
                  value,
                  condition,
                }: {
                  value: string[];
                  condition: FilterCondition | null;
                },
                skipRefresh = false,
              ) =>
                filterStore.onChangeFilter(
                  filterType,
                  i,
                  {
                    ...filter,
                    value,
                    condition,
                  },
                  skipRefresh,
                ),
              onRemoveFilter: handleRemoveFilter(i),
            };
            switch (filter.type) {
              case 'search':
                return null;
              case 'source':
                return;
              default:
                return (
                  <InlineFilter
                    key={key}
                    {...filterProps}
                    anchorRef={buttonRef}
                    refreshCollection={champions.refreshChampions}
                    updateDynamicFilterQuery={handleUpdateDynamicFilterQuery}
                    bottomDivider={filterProps.bottomDivider}
                    collectionType="champions"
                  />
                );
            }
          })}
          <ChampionsFilterMenu ref={buttonRef} onAddFilter={filter => filterStore.onAddFilter(filterType, filter)} />
        </Row>
      </Row>
    </Collapse>
  );
});

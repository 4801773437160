import { Note as NoteType } from '@caravel/types/src';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'stores';

import { Note } from '../note/note';

export interface ChampionNoteProps {
  note: NoteType;
}

export const ChampionNote = observer((props: ChampionNoteProps) => {
  const { note } = props;
  const store = useStore();
  const {
    ui,
    teams: { champions },
  } = store;

  const handleDelete = async () => {
    if (note.pending) {
      champions.onDeletePersonNote(note);
    } else if (window.confirm('Are you sure you want to delete this note?')) {
      await ui.workOn(async () => {
        await champions.deletePersonNote(champions.currentChampion!.id!, note.id);
      });
    }
  };

  const handleSubmit = async (text: string) => {
    await ui.workOn(async () => {
      if (note.pending) {
        await champions.createPersonNote(champions.currentChampion!.id!, note.id, text);
      } else {
        await champions.editPersonNote(champions.currentChampion!.id!, note.id, text);
      }
    });
  };

  return <Note onSubmit={handleSubmit} onDelete={handleDelete} note={note} />;
});

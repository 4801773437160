import { gql } from '@apollo/client';

export const UPSERT_PROOF = gql`
  mutation upsertProof(
    $clientMutationId: String
    $id: String
    $customerName: String
    $championMemberId: String
    $content: String
    $sourceType: proofSourceType
    $sourceLink: String
    $dateCreated: Date
    $employmentTitle: String
    $organizationName: String
    $proofTags: [ProofTagInput]
  ) {
    upsertProof(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        customerName: $customerName
        championMemberId: $championMemberId
        content: $content
        sourceType: $sourceType
        sourceLink: $sourceLink
        dateCreated: $dateCreated
        employmentTitle: $employmentTitle
        organizationName: $organizationName
        proofTags: $proofTags
      }
    ) {
      clientMutationId
    }
  }
`;

export interface GqlUpsertProofRequestType {
  clientMutationId?: string;
  id?: string;
  customerName?: string;
  content?: string;
  sourceType?: string;
  sourceLink?: string;
  dateCreated?: string;
  championMemberId?: string;
  employmentTitle?: string;
  organizationName?: string;
  proofTags?: { name: string }[];
}

export interface GqlUpsertProofResponseType {
  upsertProof: {
    clientMutationId?: string;
  };
}

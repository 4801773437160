import { ChannelKind } from '@caravel/types/src';

export const ChannelDescriptions: Record<ChannelKind, string> = {
  ':slack': 'Sync Slack activity, message channels, and DM Slack users',
  ':zendesk': '',
  ':api': '',
  ':activecampaign': '',
  ':circle': '',
  ':clearbit': '',
  ':csv-import': '',
  ':discourse': '',
  ':drip': '',
  ':email': 'Sync people and activities from Gmail and Google Calendar',
  ':events': '',
  ':github': '',
  ':github-public': '',
  ':hubspot': '',
  ':insided': '',
  ':mailchimp': '',
  ':manual': '',
  ':override': '',
  ':pipedrive': '',
  ':signup': '',
  ':twitter': '',
  ':vanilla': '',
  ':zapier': '',
  ':zoom': '',
};

export const ChannelConnectionDescriptions: Record<ChannelKind, string> = {
  ':slack': 'Sync people and activities from Slack to Commsor',
  ':zendesk': '',
  ':api': '',
  ':activecampaign': '',
  ':circle': '',
  ':clearbit': '',
  ':csv-import': '',
  ':discourse': '',
  ':drip': '',
  ':email': 'Sync people and activities from Gmail and Google Calendar',
  ':events': '',
  ':github': '',
  ':github-public': '',
  ':hubspot': '',
  ':insided': '',
  ':mailchimp': '',
  ':manual': '',
  ':override': '',
  ':pipedrive': '',
  ':signup': '',
  ':twitter': '',
  ':vanilla': '',
  ':zapier': '',
  ':zoom': '',
};

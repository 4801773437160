import {
  concurrently,
  createGQLClient,
  GET_NEW_ORGANIZATIONS,
  GqlNewOrganizationsRequestType,
  GqlNewOrganizationsResponseType,
} from '@caravel/utils';
import {
  GET_ACTIVE_MEMBERS,
  GqlActiveMembersRequestType,
  GqlActiveMembersResponseType,
} from '@caravel/utils/src/gql/queries/get-active-members.gql';
import {
  GET_COMMUNITY_GRID,
  GqlCommunityGridResponseType,
} from '@caravel/utils/src/gql/queries/get-community-grid.gql';
import {
  GET_COMMUNITY_LAYERS,
  GqlCommunityLayersResponseType,
} from '@caravel/utils/src/gql/queries/get-community-layers.gql';
import {
  GET_NEW_MEMBERS,
  GqlNewMembersRequestType,
  GqlNewMembersResponseType,
} from '@caravel/utils/src/gql/queries/get-new-members.gql';
import {
  GET_TOTAL_PEOPLE,
  GqlTotalPeopleRequestType,
  GqlTotalPeopleResponseType,
} from '@caravel/utils/src/gql/queries/get-total-people.gql';
import { formatISO } from 'date-fns';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { BaseStore } from './base';
import { RootStore } from './root';

const host = process.env.GRAPHQL_HOST!;

// Interface representing data needed to create a MetricData Object
// startDate and endDate are not optional as they are used to instantiate
// blank counts
interface MetricDataProps {
  total?: number;
  startDate: Date;
  endDate: Date;
  currentDelta?: number;
  previousDelta?: number;
  windowSize?: number;
  counts?: { date: string; delta: number; total: number }[];
}

// Class which represents metric data returned from our GraphQL API
// Having a class VS type allows us to initialize some blank data cleaner within
// The store
class MetricData {
  total: number;
  startDate: Date;
  endDate: Date;
  windowChangePercent: number;
  overallChangePercent: number;
  currentDelta: number;
  startTotal: number;
  previousDelta: number;
  windowSize: number;
  counts: { date: string; delta: number; total: number }[];

  constructor(props: MetricDataProps) {
    this.total = props.total ? props.total : 0;
    this.startDate = props.startDate;
    this.endDate = props.endDate;
    // Assuming counts will always be ordered in ASC otherwise, this will break
    this.startTotal = props.counts ? props.counts[0].total - props.counts[0].delta : 0;
    // Relative delta between this window and last window as a percentage of last window
    // i.e if Last window has a higher value than current, percent change will be negative
    this.windowChangePercent =
      props.currentDelta && props.previousDelta ? this.calcChange(props.currentDelta, props.previousDelta) : 0;
    // Relative delta between this window and startTotal before the beginning of this window as a percentage of start total
    // i.e if the start total has a higher value than current, percent change will be negative
    this.overallChangePercent = this.calcChange(this.total, this.startTotal);
    this.currentDelta = props.currentDelta ? props.currentDelta : 0;
    this.previousDelta = props.previousDelta ? props.previousDelta : 0;
    this.windowSize = props.windowSize ? props.windowSize : 0;
    this.counts = props.counts ? props.counts! : this.blankCounts;
  }

  get blankCounts() {
    const blanks = [];
    for (let d = new Date(this.startDate); d < this.endDate; d.setDate(d.getDate() + 1)) {
      blanks.push({
        date: `"${d.getFullYear()}-${d.getMonth()}-${d.getDate()}"`,
        delta: 0,
        total: 1,
      });
    }
    return blanks;
  }

  /* Calculates the percent increase between two numbers,
   ** In this case the current delta to the previous delta
   **
   ** i.e. if This month had a delta of 11 (endTotal - startTotal)
   **      and last month had a delta of 21,
   **      This would give us a negative change percent as
   **      our growth was slower this month when compared to
   **      the previous month.
   */
  calcChange = (end: number, start: number) => {
    // Handle divide by zero by assuming no change
    if (start === 0) return 0;
    const increase = end - start;
    const change = Math.round((increase / start) * 100);
    return !isNaN(change) ? change : 0;
  };
}

export class DashboardStore extends BaseStore {
  windowSizeInDays = 30;
  totalPeopleLoading = false;
  newMembersLoading = false;
  communityLayersLoading = false;
  activeMembersLoading = false;
  newOrganizationsLoading = false;
  communityGridLoading = false;

  totalPeople: MetricData = new MetricData({ startDate: this.startDate, endDate: this.endDate });
  newMembers: MetricData = new MetricData({ startDate: this.startDate, endDate: this.endDate });
  activeMembers: MetricData = new MetricData({ startDate: this.startDate, endDate: this.endDate });
  newOrganizations: MetricData = new MetricData({ startDate: this.startDate, endDate: this.endDate });

  communityGrid: {
    name: string;
    count: number;
  }[] = [];

  communityLayers: {
    name: string;
    count: number;
  }[] = [];

  // TODO: make date range more dynamic somehow eventually maybe
  get endDate() {
    const date = new Date();
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  get startDate() {
    const date = new Date();
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setDate(date.getDate() - this.windowSizeInDays);
    return date;
  }

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      totalPeopleLoading: observable,
      totalPeople: observable,
      newMembersLoading: observable,
      newMembers: observable,
      communityLayersLoading: observable,
      communityLayers: observable,
      communityGridLoading: observable,
      communityGrid: observable,
      activeMembersLoading: observable,
      activeMembers: observable,
      newOrganizationsLoading: observable,
      newOrganizations: observable,
      fetchTotalPeople: action,
      fetchNewMembers: action,
      fetchCommunityLayers: action,
      fetchActiveMembers: action,
      fetchNewOrganizations: action,
      fetchCommunityGrid: action,
    });
  }

  async setup() {
    await super.setup();
  }

  async mount() {
    await concurrently([
      this.fetchTotalPeople,
      this.fetchNewMembers,
      this.fetchCommunityLayers,
      this.fetchActiveMembers,
      this.fetchNewOrganizations,
      this.fetchCommunityGrid,
    ]);
  }

  unmount() {
    runInAction(() => {
      this.totalPeople = new MetricData({ startDate: this.startDate, endDate: this.endDate });
      this.activeMembers = new MetricData({ startDate: this.startDate, endDate: this.endDate });
      this.communityLayers = [];
      this.communityGrid = [];
      this.newMembers = new MetricData({ startDate: this.startDate, endDate: this.endDate });
      this.newOrganizations = new MetricData({ startDate: this.startDate, endDate: this.endDate });
    });
  }

  teardown(): void {
    super.teardown();
  }

  /* TOTAL PEOPLE :
   ** Represents total counts of people within the community and the change from this
   ** window to the previous window
   */
  fetchTotalPeople = async () => {
    if (this.totalPeopleLoading) {
      console.debug('Total People Tile already loading');
      return;
    }
    runInAction(() => (this.totalPeopleLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlTotalPeopleRequestType, GqlTotalPeopleResponseType>(
      GET_TOTAL_PEOPLE,
      {
        startDate: formatISO(this.startDate),
        endDate: formatISO(this.endDate),
      },
    );
    runInAction(() => {
      this.totalPeople = new MetricData({
        total: response.community.stats.members.total,
        startDate: this.startDate,
        endDate: this.endDate,
        currentDelta: response.community.stats.members.delta,
        previousDelta: response.community.stats.members.previousDelta,
        windowSize: response.community.stats.members.windowSize,
        counts: response.community.stats.members.counts,
      });
      this.totalPeopleLoading = false;
    });
  };

  /* NEW MEMBERS :
   ** Represents total counts people with the position of member who were first
   ** seen in this current window.
   **
   ** Change represents the difference of new members seen in this window, vs
   ** the previous window
   */
  fetchNewMembers = async () => {
    if (this.newMembersLoading) {
      console.debug('New Members Tile already loading');
      return;
    }
    runInAction(() => (this.newMembersLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlNewMembersRequestType, GqlNewMembersResponseType>(GET_NEW_MEMBERS, {
      startDate: formatISO(this.startDate),
      endDate: formatISO(this.endDate),
    });
    runInAction(() => {
      this.newMembers = new MetricData({
        total: response.community.stats.newMembers.total,
        startDate: this.startDate,
        endDate: this.endDate,
        currentDelta: response.community.stats.newMembers.delta,
        previousDelta: response.community.stats.newMembers.previousDelta,
        windowSize: response.community.stats.newMembers.windowSize,
        counts: response.community.stats.newMembers.counts,
      });
      this.newMembersLoading = false;
    });
  };

  /* COMMUNITY LAYERS :
   ** Represents all layers within the community and their counts
   **
   */
  fetchCommunityLayers = async () => {
    if (this.communityLayersLoading) {
      console.debug('Community Layers Tile already loading');
      return;
    }
    runInAction(() => (this.communityLayersLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<Record<string, string>, GqlCommunityLayersResponseType>(
      GET_COMMUNITY_LAYERS,
      {},
    );
    runInAction(() => {
      this.communityLayers = response.community.stats.position;
      this.communityLayersLoading = false;
    });
  };

  /* COMMUNITY GRID :
   ** Grab grid status with name and counts
   **
   ** TODO: See if member should be added to the gridStatus query, or if this way is OK going forward
   */
  fetchCommunityGrid = async () => {
    if (this.communityGridLoading) {
      console.debug('Community Grid Tile already loading');
      return;
    }
    runInAction(() => (this.communityGridLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<Record<string, string>, GqlCommunityGridResponseType>(
      GET_COMMUNITY_GRID,
      {},
    );
    runInAction(() => {
      this.communityGrid = response.community.stats.gridStatus;
      const member = response.community.stats.position.find(s => s.name === 'member');
      this.communityGrid.push({
        name: 'member',
        count: member ? member.count : 0,
      });
      this.communityGridLoading = false;
    });
  };

  /* ACTIVE MEMBERS :
   ** Represents all members who have performes some sort of activity
   ** within this date window
   **
   ** Change represents the difference in member activity from this window
   ** to the previous
   */
  fetchActiveMembers = async () => {
    if (this.activeMembersLoading) {
      console.debug('Active Members Tile already loading');
      return;
    }
    runInAction(() => (this.activeMembersLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlActiveMembersRequestType, GqlActiveMembersResponseType>(
      GET_ACTIVE_MEMBERS,
      {
        startDate: formatISO(this.startDate),
        endDate: formatISO(this.endDate),
      },
    );
    runInAction(() => {
      this.activeMembers = new MetricData({
        total: response.community.stats.activeMembers.total,
        startDate: this.startDate,
        endDate: this.endDate,
        currentDelta: response.community.stats.activeMembers.delta,
        previousDelta: response.community.stats.activeMembers.previousDelta,
        windowSize: response.community.stats.activeMembers.windowSize,
        counts: response.community.stats.activeMembers.counts,
      });
      this.activeMembersLoading = false;
    });
  };

  /* NEW ORGANIZATIONS :
   ** Represents new organizations which have been added within this
   ** window
   **
   ** Change represents the difference in new orgs added from this window
   ** to the previous
   */
  fetchNewOrganizations = async () => {
    if (this.newOrganizationsLoading) {
      console.debug('New Organizations Tile already loading');
      return;
    }
    runInAction(() => (this.newOrganizationsLoading = true));
    const { teamId, token } = await this.rootStore.getTeamAndToken();
    const graphqlClient = createGQLClient(teamId, token, host);
    const response = await graphqlClient.query<GqlNewOrganizationsRequestType, GqlNewOrganizationsResponseType>(
      GET_NEW_ORGANIZATIONS,
      {
        startDate: formatISO(this.startDate),
        endDate: formatISO(this.endDate),
      },
    );
    runInAction(() => {
      this.newOrganizations = new MetricData({
        total: response.community.stats.newOrganizations.total,
        startDate: this.startDate,
        endDate: this.endDate,
        currentDelta: response.community.stats.newOrganizations.delta,
        previousDelta: response.community.stats.newOrganizations.previousDelta,
        windowSize: response.community.stats.newOrganizations.windowSize,
        counts: response.community.stats.newOrganizations.counts,
      });
      this.newOrganizationsLoading = false;
    });
  };

  // Generates some visually satisfying data to use in our metric graphs for testing purposes.
  private testMetricResponse = (isPos: boolean, startDate: Date, endDate: Date) => {
    // eslint-disable-next-line security-node/detect-insecure-randomness
    const delta = Math.floor(Math.random() * 50);
    // eslint-disable-next-line security-node/detect-insecure-randomness
    const previousDelta = Math.floor(Math.random() * 50);
    // eslint-disable-next-line security-node/detect-insecure-randomness
    const start = Math.floor(Math.random() * 100) + delta;
    const end = isPos ? start + delta : start - delta;
    const counts = [];
    let count = start;
    for (let d = new Date(startDate); d < endDate; d.setDate(d.getDate() + 1)) {
      counts.push({
        date: `"${d.getFullYear()}-${d.getMonth()}-${d.getDate()}"`,
        delta: 0,
        // eslint-disable-next-line security-node/detect-insecure-randomness
        total: isPos ? Math.floor(Math.random() * 5) + count++ : Math.floor(Math.random() * 5) + count--,
      });
    }
    return {
      community: {
        id: '',
        stats: {
          organizations: {
            delta: delta,
            previousDelta: previousDelta,
            endTotal: end,
            startTotal: start,
            counts: counts,
          },
        },
      },
    };
  };
}

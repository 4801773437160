import { GLOW_GRADIENT, WHITE } from '@caravel/utils/src/constants/colors';
import { Box, SxProps } from '@mui/material';
import React from 'react';

import { Flex } from '..';

const height = 10;
const baseW = 376;

export interface CaravelProgressProps {
  sx?: SxProps;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function CaravelProgress(props: CaravelProgressProps) {
  return (
    <Box
      sx={{
        maxWidth: baseW,
        height,
        borderRadius: 5,
        overflow: 'hidden',
        ...props.sx,
      }}
    >
      <Box
        sx={{
          width: baseW * 4,
          height,
          background: `${GLOW_GRADIENT} repeat-x`,
          animation: '$slide 2000ms linear infinite',
          '@keyframes slide': {
            '0%': {
              transform: `translate3d(-${baseW * 3}px, 0, 0)`,
            },
            '100%': {
              transform: 'translate3d(0, 0, 0)',
            },
          },
        }}
      />
    </Box>
  );
}

export interface CaravelDeterminateProgressProps {
  progress: number;
  sx?: SxProps;
}

export function CaravelDeterminateProgress(props: CaravelDeterminateProgressProps) {
  const width = props.progress * baseW;
  return (
    <Flex
      sx={{
        width: baseW,
        height,
        marginTop: '10px',
        border: `2px solid ${WHITE}`,
        borderRadius: `${height / 2}px`,
        alignItems: 'flex-start',
        ...props.sx,
      }}
    >
      <Flex
        sx={{
          width,
          height: '100%',
          backgroundColor: WHITE,
          transition: 'width 300ms',
        }}
      />
    </Flex>
  );
}

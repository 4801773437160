import { Button, useTheme } from '@mui/material';
import React from 'react';

import { BaseModal } from './base-modal';

export interface BaseActionModalProps {
  open: boolean;
  icon: JSX.Element;
  title: string;
  subtitle?: string;
  children?: any;
  buttonText?: string;
  onClose: () => void;
  onAction: () => void;
}

export function BaseActionModal(props: BaseActionModalProps) {
  const theme = useTheme();
  const { open, icon, title, subtitle, children, buttonText = 'Upgrade', onClose, onAction } = props;

  return (
    <BaseModal open={open} icon={icon} title={title} subtitle={subtitle} onClose={onClose}>
      {children}
      <Button
        variant="contained"
        color="primary"
        onClick={onAction}
        sx={{
          width: '100%',
          margin: theme.spacing(2, 0),
        }}
      >
        {buttonText}
      </Button>
    </BaseModal>
  );
}

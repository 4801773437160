import { Column } from '@caravel/components/src';
import { SENCHA, WHITE } from '@caravel/utils';
import illustrationJson from '@caravel/web/src/assets/lottie/sign-up-background.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as CommsorDino } from '../../assets/svgs/commsor-dino-large.svg';

interface OnboardingBackgroundProps {
  children: React.ReactNode;
}

export const OnboardingBackground = ({ children }: OnboardingBackgroundProps) => {
  return (
    <Box position="relative" width="100%" height="100%">
      <Player
        autoplay
        loop
        src={illustrationJson}
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        style={{ width: '100%', height: '100vh', position: 'absolute', top: 0, left: 0, zIndex: 0 }}
      />
      <Column
        justifyContent="space-between"
        alignItems="center"
        minHeight="100vh"
        height="100%"
        padding="32px"
        gap="32px"
        position="relative"
        zIndex={1}
        sx={{ overflowY: 'auto' }}
      >
        <Box>
          <CommsorDino style={{ width: '35px' }} />
        </Box>

        {children}

        <Typography variant="bodySmallest" textAlign="center" color={WHITE}>
          Need help?
          <br />
          Contact{' '}
          <Link href="mailto:support@commsor.com" rel="noopener noreferrer" target="_blank" color={SENCHA[50]}>
            support@commsor.com
          </Link>
        </Typography>
      </Column>
    </Box>
  );
};

import { gql } from '@apollo/client';

export const GET_SEGMENT = gql`
  query getSegment($segmentId: ID!, $after: String, $first: Int, $orderBy: ItemOrder) {
    community {
      id
      segments(filterBy: { id__eq: $segmentId }) {
        edges {
          node {
            id
            kind
            name
            description
            createdBy
            createdAt
            updatedBy
            updatedAt
            peopleCount
            peoplePaginated(after: $after, first: $first, orderBy: $orderBy) {
              edges {
                node {
                  id
                  name
                  email
                  avatar
                  givenName
                  familyName
                  customerFit
                  employmentTitle
                  lastSeen
                  connectedTo {
                    userId
                    connectedSince
                    lastExchange
                    totalMeetings
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
            }
            segmentRules {
              interConditionOperator
              interRuleOperator
              conditions {
                field
                operator
                values
              }
            }
          }
        }
        meta {
          facets {
            counts {
              count
              value
            }
            name
            totalValues
          }
          hits
          query
        }
      }
    }
  }
`;

export interface GqlSegmentRequestType {
  after?: string;
  first?: number;
  orderBy: { field: string; direction: string };
  segmentId: string;
}

import { gql } from '@apollo/client';
import { PersonaType } from '@caravel/types';

export const UPSERT_MANUAL_RECORD = gql`
  mutation upsertCustomAttribute($input: UpsertCustomAttributeInput!) {
    upsertCustomAttribute(input: $input) {
      result {
        name
      }
    }
  }
`;

export interface GqlUpsertCustomAttributeRequestType {
  input: {
    memberIds: string[];
    name: PersonaType;
    value?: string;
  };
}

export interface GqlUpsertCustomAttributeResponseType {
  upsertCustomAttribute: {
    result: {
      name: string;
    };
  };
}

import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import React from 'react';

export const Flex = React.forwardRef((props: BoxProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Box
      {...rest}
      ref={ref}
      sx={{
        display: 'flex',
        ...sx,
      }}
    />
  );
});

Flex.displayName = 'Flex';

export const Row = React.forwardRef((props: BoxProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Flex
      {...rest}
      ref={ref}
      sx={{
        flexDirection: 'row',
        ...sx,
      }}
    />
  );
});

Row.displayName = 'Row';

export const Column = React.forwardRef((props: BoxProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Flex
      {...rest}
      ref={ref}
      sx={{
        flexDirection: 'column',
        ...sx,
      }}
    />
  );
});

Column.displayName = 'Column';

export const NarrowColumn = React.forwardRef((props: BoxProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Column
      {...rest}
      ref={ref}
      sx={{
        maxWidth: '698px',
        padding: '0 24px',
        width: '100%',
        ...sx,
      }}
    />
  );
});

NarrowColumn.displayName = 'NarrowColumn';

export const WideColumn = React.forwardRef((props: BoxProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Column
      {...rest}
      ref={ref}
      sx={{
        maxWidth: '972px',
        padding: '0 24px',
        width: '100%',
        ...sx,
      }}
    />
  );
});

WideColumn.displayName = 'WideColumn';

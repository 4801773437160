import { GREY_PALETTE } from '@caravel/utils';
import { InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import React from 'react';

export const TextFieldWithStartAdornment = React.forwardRef<
  HTMLDivElement,
  TextFieldProps & {
    inputHeight?: number;
    prefixText?: string;
    prefixComponent?: React.ReactNode;
  }
>(({ inputHeight = 48, prefixText, prefixComponent, ...props }, ref) => (
  <TextField
    ref={ref}
    {...props}
    sx={{
      '& .MuiOutlinedInput-root': {
        paddingLeft: '0',
      },
      '& .MuiInputAdornment-root': {
        maxHeight: '100%',
        padding: '0 12px 0 12px',
        marginRight: '16px',
      },
      ...props.sx,
    }}
    inputProps={{
      sx: {
        boxSizing: 'border-box',
        fontSize: 14,
        height: inputHeight,
        ...props.inputProps?.sx,
      },
      ...props.inputProps,
    }}
    InputProps={{
      startAdornment: (
        <InputAdornment
          position="start"
          sx={{
            backgroundColor: GREY_PALETTE[1],
            height: inputHeight,
          }}
        >
          {prefixComponent}
          <Typography variant="bodySmall" sx={{ color: GREY_PALETTE[7] }}>
            {prefixText}
          </Typography>
        </InputAdornment>
      ),
      ...props.InputProps,
    }}
  />
));

TextFieldWithStartAdornment.displayName = 'TextFieldWithStartAdornment';

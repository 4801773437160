import { Column, ImageUploaderWithPreview, Row } from '@caravel/components/src';
import { uuid } from '@caravel/utils';
import { DARK_GREY, GIBBERING_GREY } from '@caravel/utils/src/constants/colors';
import { Button, Divider, Typography } from '@mui/material';
import { updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useStore } from 'stores';

type Dispatcher<S> = Dispatch<SetStateAction<S>>;
type ModalData = {
  imageUrl: string;
  setImage: Dispatcher<any[]>;
};

export const TeamLogoUploader = observer(() => {
  const [logoImage, setLogoImage] = React.useState<any[]>([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [existingLogoImageUrl, setExistingLogoImageUrl] = React.useState<string>();

  const store = useStore();

  const openCropImageModal = () => {
    const data: ModalData = { imageUrl: logoImage[0]?.data_url || existingLogoImageUrl, setImage: setLogoImage };
    store.ui.openModal('crop-image', data);
  };

  const onSetImage = async (image: any) => {
    setLogoImage(image);
  };

  const onImageDelete = async () => {
    await store.ui.workOn(async () => {
      await updateDoc(store.teams.currentTeam!.docRef, {
        logoUrl: null,
      });
    });
  };

  const uploadLogo = useCallback(async (): Promise<string | undefined> => {
    try {
      setUploadLoading(true);
      const { teamId } = await store.getTeamAndToken();
      const fileName = uuid();
      const destinationRef = ref(getStorage(), `/teams/${teamId}/logos/${fileName}`);
      await uploadBytes(destinationRef, logoImage[0].file);
      const publicURL = `https://firebasestorage.googleapis.com/v0/b/${process.env
        .REACT_APP_FIREBASE_STORAGE_BUCKET!}/o/teams%2F${teamId}%2Flogos%2F${fileName}?alt=media`;

      // TODO: remove for existing image from storage first
      await updateDoc(store.teams.currentTeam!.docRef, { logoUrl: publicURL });

      setUploadLoading(false);
      return publicURL;
    } catch (e) {
      console.warn(e);
      store.teams.champions.rootStore.notifications.display({
        severity: 'error',
        message: 'Failed to Upload Image',
        duration: 5000,
      });
      setUploadLoading(false);
    }
  }, [store, logoImage]);

  useEffect(() => {
    if (logoImage[0]) {
      uploadLogo();
    }
  }, [logoImage, uploadLogo]);

  useEffect(() => {
    if (store.teams.currentTeam?.logoUrl && !logoImage[0]) {
      setExistingLogoImageUrl(store.teams.currentTeam?.logoUrl);
    }
    if (!store.teams.currentTeam?.logoUrl) {
      setLogoImage([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.teams.currentTeam?.logoUrl]);

  return (
    <Column>
      <Column
        sx={{
          background: GIBBERING_GREY,
          borderRadius: '5px',
          height: '159px',
          padding: '20px',
          width: '100%',
        }}
      >
        <Row alignItems="center" justifyContent="start" marginBottom="12px">
          <ImageUploaderWithPreview
            circle
            image={logoImage}
            setImage={onSetImage}
            loading={uploadLoading}
            onDelete={onImageDelete}
            existingImageUrl={existingLogoImageUrl}
            setExistingImageUrl={setExistingLogoImageUrl}
          />
          {logoImage[0] || existingLogoImageUrl ? (
            <>
              <Button sx={{ marginLeft: '30px' }} color="primary" onClick={openCropImageModal} variant="outlined">
                Edit
              </Button>
            </>
          ) : (
            <Typography sx={{ marginLeft: '30px' }}>Upload a custom team logo</Typography>
          )}
        </Row>
        <Typography variant="bodySmallest" color={DARK_GREY}>
          Recommended dimensions 64px x 64px (max. 3MB)
        </Typography>
      </Column>
      <Divider sx={{ width: '100%', marginBottom: '30px', marginTop: '30px' }} />
    </Column>
  );
});

import { Column } from '@caravel/components/src';
import { GREY_PALETTE } from '@caravel/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Card, CardContent, CardHeader, Divider, Tooltip, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from 'stores';

import { AppsIcon, TargetIcon } from '../segments-icons';
import { NewSegmentKindCard } from './new-segment-kind-card';

export const SegmentKindSelector = observer(() => {
  const store = useStore();
  const theme = useTheme();

  const {
    ui: { SegmentForm },
  } = store;

  return (
    <Column
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Card
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          border: 'none',
          width: '50vw',
          marginTop: theme.spacing(8),
        }}
      >
        <CardHeader
          sx={{ marginBottom: theme.spacing(4), padding: 0 }}
          title="Choose your Segment type"
          titleTypographyProps={{ variant: 'headline2', marginBottom: theme.spacing(2) }}
          subheader={
            <>
              Create lists of people that you can manually control or have update automatically
              <Tooltip
                sx={{ margin: `0 0 0 ${theme.spacing(1)}`, height: '12px', width: '12px', color: GREY_PALETTE[4] }}
                title={'Segments tooltip'}
              >
                <InfoOutlinedIcon color="primary" />
              </Tooltip>
            </>
          }
          subheaderTypographyProps={{ variant: 'bodySmall' }}
        />

        <Divider />

        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: `${theme.spacing(4)} 0`,
          }}
        >
          <NewSegmentKindCard
            icon={AppsIcon}
            kind="Static"
            description="Search and manually add people to a list"
            onSelect={() => SegmentForm.newStaticSegment()}
          />
          <NewSegmentKindCard
            icon={TargetIcon}
            kind="Dynamic"
            description="Define rules that automatically move people in and out of a list"
            onSelect={() => SegmentForm.newDynamicSegment()}
          />
        </CardContent>
      </Card>
    </Column>
  );
});

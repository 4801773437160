import { PersonaType } from '@caravel/types';
import { GREY_PALETTE, NEUTRALS } from '@caravel/utils/src';
import {
  Autocomplete,
  AutocompleteProps,
  Divider as MuiDivider,
  IconButton,
  MenuItem,
  Select,
  SelectProps,
  styled,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Row } from '../../flex';
import { AttributeFieldOption } from '../attribute-field';
import { ReactComponent as AddNewIcon } from '../svgs/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../svgs/delete-icon.svg';
import { ReactComponent as EditIcon } from '../svgs/edit-menu-items.svg';

const Divider = styled(MuiDivider)({
  margin: '0 !important',
});

export interface SearchableAttributeFieldOption {
  value?: string;
  label?: string;
  custom?: boolean;
  id?: string;
  personaId?: string;
}

export interface SearchableAttributeFieldProps {
  attributeName?: string;
  value: SearchableAttributeFieldOption;
  options: SearchableAttributeFieldOption[];
  customizable?: boolean;
  attributeType: PersonaType;
  autoFocus?: boolean;
  loading?: boolean;
  onChangeAttribute: (attributeType: PersonaType, option: SearchableAttributeFieldOption, personaId?: string) => void;
  onDeleteAttribute: (attributeType: PersonaType, personaId?: string) => void;
  addItemLabel?: string;
  renderInput?: (params: any) => JSX.Element;
}

export const SearchableAttributeField = forwardRef<HTMLDivElement, SearchableAttributeFieldProps>((props, ref) => {
  const {
    attributeName,
    value,
    options,
    onChangeAttribute,
    onDeleteAttribute,
    customizable,
    attributeType,
    autoFocus,
    addItemLabel = 'Add New Item',
    loading = false,
    renderInput = params => <TextField {...params} variant="outlined" />,
  } = props;
  const [isAddingNewValue, setAddingNewValue] = useState(false);
  const [transparentState, setTransparentState] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [newItems, setNewItems] = useState<SearchableAttributeFieldOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<SearchableAttributeFieldOption[]>([]);
  const [attributeValue, setAttributeValue] = useState(value);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnclickMenuItem = (option: SearchableAttributeFieldOption) => {
    setAttributeValue(option);
    onChangeAttribute(
      attributeType,
      {
        value: option.value,
        personaId: option.personaId,
      },
      option.personaId,
    );
  };

  const handleOnChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(event.target.value);
  };

  const handleOnDeleteNewItem = (event: React.MouseEvent, option: SearchableAttributeFieldOption) => {
    event.stopPropagation(); // This line prevents the click event from propagating up to the Autocomplete
    onDeleteAttribute(attributeType, option.personaId);
    onChangeAttribute(attributeType, { value: '' });
    setAttributeValue({ value: '' });
    setTransparentState(true);
  };

  const handleOnAddingNewItem = () => {
    setAttributeValue({ value: newValue });
    inputRef.current?.blur();
    if (newValue.length === 0 || newValue === addItemLabel) {
      setAddingNewValue(false);
    } else {
      const newId = uuid();
      onChangeAttribute(attributeType, { value: newValue, id: newId });
      setNewItems([{ value: newValue, id: newId }]);
      setNewValue('');
      setAddingNewValue(false);
    }
  };

  const filterUniqueOptions = (options: SearchableAttributeFieldOption[]) => {
    const uniqueArray =
      options &&
      options.filter((element, id) => id === options.findIndex(opt => opt.value && opt.value === element.value));
    return Array.from(uniqueArray);
  };

  const handleMenuToggle = (menu: boolean, transparent: boolean) => {
    setTransparentState(transparent);
    setMenuOpen(menu);
  };

  const handleOnBlur = () => {
    setAddingNewValue(false);
    handleMenuToggle(false, true);
  };

  useEffect(() => {
    setFilteredOptions(filterUniqueOptions(options));
  }, [options]);

  if (isAddingNewValue) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} ref={ref}>
        {attributeName && (
          <Typography sx={{ fontSize: '13px', fontWeight: '500', marginRight: '25px' }}>{attributeName}</Typography>
        )}
        <TextField
          contentEditable={!loading}
          suppressContentEditableWarning
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeValue(event)}
          autoFocus={autoFocus}
          onBlur={handleOnBlur}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleOnAddingNewItem();
            }
          }}
          sx={{
            color: NEUTRALS.ESPRESSO,
            width: '210px',
            height: '28px',
            padding: '0px, 6px',
            background: NEUTRALS.CHAMOMILE,
            fontSize: '13px',
            borderRadius: '4px',
            '& fieldset': {
              border: `1px solid ${NEUTRALS.CHAMOMILE}`,
              '&:focus': {
                border: `1px solid ${NEUTRALS.CEYLON}`,
              },
            },
            '& .MuiOutlinedInput': {
              '&-root': {
                margin: '0',
                paddingLeft: '8px',
                background: NEUTRALS.CHAMOMILE,
                fontSize: '13px',
                borderRadius: '4px',
                height: '100%',
                paddingRight: '0',
                '&.Mui-focused fieldset': {
                  border: `1px solid ${NEUTRALS.CEYLON}`,
                },
              },
              '&-input': {
                padding: '0',
              },
            },
          }}
        />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} ref={ref}>
      {attributeName && (
        <Typography sx={{ fontSize: '13px', fontWeight: '500', marginRight: '25px' }}>{attributeName}</Typography>
      )}
      <Autocomplete
        disableClearable
        // Sometimes adding a hard height value can cause the input to be cut off, I removed it for now
        sx={{
          color: NEUTRALS.ESPRESSO,
          width: '210px',
          padding: '0px, 6px',
          background: 'transparent',
          '&.Mui-disabled': {
            background: NEUTRALS.CHAMOMILE,
            opacity: '0.8',
          },
          '&:focus-within': {
            background: 'transparent',
            '&:hover': {
              background: NEUTRALS.CHAMOMILE,
            },
          },
          '&:hover': {
            background: NEUTRALS.CHAMOMILE,
          },
          // Override the input root font size
          '& .MuiInputBase-input': {
            fontSize: '13px',
          },
          '& .MuiAutocomplete-inputRoot': {
            display: 'flex', // Add flexbox layout
            alignItems: 'center', // Center vertically
            margin: '0',
            padding: '0 6px',
            background: 'transparent',
            '&:hover': {
              background: NEUTRALS.CHAMOMILE,
            },
            '&:focus': {
              background: 'transparent',
            },
          },
          '& .MuiSelect': {
            '&-icon': {
              top: 'unset',
            },
            '&-select': {
              '&:hover': {
                background: NEUTRALS.CHAMOMILE,
              },
              '&:focus': {
                background: 'transparent',
              },
            },
          },
        }}
        options={customizable ? [...filteredOptions, { value: addItemLabel }] : filteredOptions}
        getOptionLabel={(option: AttributeFieldOption) => option.label || option.value || ''}
        value={attributeValue}
        onChange={(_, value) => handleOnclickMenuItem(value)}
        isOptionEqualToValue={(option, value) => {
          if (option && option.value && value && value.value) {
            return option.value.valueOf === value.value.valueOf;
          } else {
            return false;
          }
        }}
        size="small"
        renderInput={renderInput}
        renderOption={(props, option, { selected }) => {
          const key = option.label ? option.label : option.value ? option.value : `default-key-${Math.random()}`;

          if (option.value !== addItemLabel) {
            return (
              <MenuItem
                key={key}
                value={option.value}
                onClick={() => handleOnclickMenuItem(option)}
                sx={{
                  justifyContent: 'space-between',
                  fontSize: '13px',
                  padding: '0',
                  paddingLeft: '10px',
                  height: '28px',
                  width: '100%',
                  '&:hover': {
                    background: GREY_PALETTE[1],
                  },
                }}
              >
                {option.label ? option.label : option.value ? option.value : ''}
                {option.custom && (
                  <IconButton sx={{ marginRight: '0' }} onClick={event => handleOnDeleteNewItem(event, option)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </MenuItem>
            );
          } else if (customizable) {
            return (
              <MenuItem
                key={key}
                // Most things can be centered by using the Mui sx prop
                // I have also added in some styles from the Figma
                sx={{
                  alignItems: 'center',
                  borderTop: `1px solid ${GREY_PALETTE[2]}`,
                  width: '100%',
                  height: '28px',
                  padding: '4px 10px',
                  '&:hover': {
                    background: GREY_PALETTE[1],
                  },
                }}
                onClick={() => setAddingNewValue(true)}
                disabled={loading}
              >
                <Row
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <AddNewIcon />
                  <Typography variant="body" sx={{ padding: '0 8px', fontSize: '13px' }}>
                    {addItemLabel}
                  </Typography>
                </Row>
              </MenuItem>
            );
          }
        }}
      />
    </div>
  );
});

SearchableAttributeField.displayName = 'Attribute Field';

import { isEmptyString } from '@caravel/utils';
import { format } from 'date-fns';
import firestore from 'firebase/firestore';
import moment from 'moment';

const importableDate = 'MMM DD, YYYY HH:mm:ss';

// Calculate timezone offset and add it to date, negating it.
// https://stackoverflow.com/questions/17545708/parse-date-without-timezone-javascript
export function toUTCDate(date: Date) {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
}

/**
 * Converts a date to a predefined formatted string
 * @param date The date to convert
 * @param fallback The string to display if the date is undefined
 * @returns THe date formatted as a string
 */
export function makeDateString(date?: Date | number, fallback = 'Unknown'): string {
  if (!date) return fallback;
  return format(new Date(date), 'MMM d, yyyy');
}

/**
 * Tries to parse a date-like string into a valid Date
 * @param text A date-like string, possibly unparsable.
 */
export function parseDate(text?: string | Date | firestore.Timestamp): Date | undefined {
  if (text instanceof Date) {
    return text as Date;
  }
  if (typeof text === 'string' && isEmptyString(text)) {
    return undefined;
  }
  if (typeof text === 'object' && text.toDate) {
    return text.toDate();
  }

  let parsedDate: any | undefined;
  try {
    parsedDate = moment(Date.parse(text! as string));
  } catch {
    console.debug('failed to parse date (standard):', text);
  }

  if (!parsedDate?.isValid()) {
    try {
      parsedDate = moment(text);
    } catch {
      console.debug('failed to parse date (moment):', text);
    }
  }

  if (!parsedDate?.isValid()) {
    try {
      parsedDate = moment(text, [importableDate]);
    } catch {
      console.debug('failed to parse date (known):', text);
    }
  }

  return parsedDate?.isValid() ? parsedDate.toDate() : undefined;
}

export function safeISOString(date?: Date) {
  return date?.toISOString().slice(0, -1) || null;
}

export function makeDateFrom(date?: string | Date | firestore.Timestamp, fallback?: Date): Date {
  return toUTCDate(parseDate(date) ?? fallback ?? new Date());
}

export function makeDateStringFrom(date?: string | Date | firestore.Timestamp, fallback?: Date): string {
  return makeDateFrom(date, fallback).toISOString();
}

/**
 * Creates a duration object from a duration in milliseconds
 * @param duration duration in milliseconds
 * @returns the duration in days, hours, minutes, seconds, and milliseconds
 */
export function parseDuration(duration: number) {
  let remain = duration;

  const days = Math.floor(remain / (1000 * 60 * 60 * 24));
  remain = remain % (1000 * 60 * 60 * 24);

  const hours = Math.floor(remain / (1000 * 60 * 60));
  remain = remain % (1000 * 60 * 60);

  const minutes = Math.floor(remain / (1000 * 60));
  remain = remain % (1000 * 60);

  const seconds = Math.floor(remain / 1000);
  remain = remain % 1000;

  const milliseconds = remain;

  return {
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
}

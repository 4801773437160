import { gql } from '@apollo/client';

export const UPDATE_COMMUNITY_INFO = gql`
  mutation UpdateCommunityInfo($communityId: Int!, $data: UpdateCommunityInfoInput!) {
    updateCommunityInfo(communityId: $communityId, data: $data) {
      communityId
      basicSettings {
        adminId
        logoUrl
        name
        slug
        welcomeMessage
      }
      brandingSettings {
        domain
        emailSenderName
        matchaBrandingVisible
        themeColor
      }
      featureSettings {
        connections
        events
        memberDirectory
      }
      socialSettings {
        facebook
        github
        instagram
        linkedin
        slack
        twitter
        website
        youtube
      }
      visibilitySettings {
        public
        events
      }
    }
  }
`;

import { NEUTRALS } from '@caravel/utils';
import { Box, ClickAwayListener, Modal } from '@mui/material';
import React from 'react';
import { Tweet } from 'react-twitter-widgets';

interface ProofTweetModalProps {
  open: boolean;
  tweetId: string;
  handleClose: () => void;
}

export const ProofTweetModal = ({ open, tweetId, handleClose }: ProofTweetModalProps) => {
  return (
    <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        borderRadius="8px"
        padding="4px 14px"
        width="548px"
        maxHeight="95%"
        sx={{ background: NEUTRALS.CHAMOMILE, overflowY: 'auto', '&:focus-visible': { outline: 'none' } }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Tweet tweetId={tweetId} options={{ width: '520' }} />
          </div>
        </ClickAwayListener>
      </Box>
    </Modal>
  );
};

import {
  CaravelSpinner,
  Column,
  Flex,
  KebabMenu,
  NoResults,
  NumberChip,
  SortableTableHead,
} from '@caravel/components/src';
import AddIcon from '@mui/icons-material/Add';
import {
  ListItemText,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { RouterLink } from 'components/router-link';
import { formatDistance } from 'date-fns';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores';

import { AppToolbar } from '../app-toolbar';
import { AppsIcon, BoltIcon, ExportPeople, SpeechBubbleIcon, TargetIcon, TrashCanIcon } from './segments-icons';

export const SegmentsRoute = observer(() => {
  const store = useStore();
  const history = useHistory();
  const {
    ui,
    teams: { segments },
  } = store;

  const collection = segments.collection.slice().filter(n => n.id);

  const noSegments = !segments.loading && segments.loaded && collection.length === 0;
  const isStatic = (type?: string) => type === ':segment.kind/static';

  const handleDeleteSegment = (id: string) => {
    if (window.confirm('Are you sure you want to delete this Segment?')) {
      ui.workOn(async () => {
        segments.deleteSegment(id);
      });
    }
  };

  const handleRequestSort = (id: string) => {
    ui.workOn(async () => {
      await segments.onChangeOrder(id);
      await segments.fetchSegments();
    });
  };

  return (
    <Column sx={{ height: '100%' }}>
      <AppToolbar
        title="Segments"
        actionButton={{
          text: 'New Segment',
          onClick: () => history.push('/segments/new'),
        }}
      />
      {!noSegments ? (
        <Column>
          <TableContainer>
            <Table size="small">
              <SortableTableHead
                cells={[
                  {
                    id: 'name',
                    label: 'Name',
                    disablePadding: false,
                    minWidth: '280px',
                    width: `calc(100%/${2})`,
                    maxWidth: '500px',
                  },
                  { id: 'kind', label: 'Type', minWidth: '100px', width: `calc(100%/${8})`, maxWidth: '280px' },
                  { id: 'peopleCount', label: 'People', width: `calc(100%/${8})`, maxWidth: '300px' },
                  {
                    id: 'updatedAt',
                    label: 'Last Update',
                    minWidth: '140px',
                    width: `calc(100%/${2})`,
                    maxWidth: '300px',
                  },
                ]}
                order={segments.order}
                orderBy={segments.orderBy}
                onSort={handleRequestSort}
                rowCount={collection.length}
              />
              <TableBody>
                {collection.map((row, index) => {
                  const menuOptions = [
                    {
                      enabled: false,
                      icon: <SpeechBubbleIcon />,
                      label: 'Message Segment',
                      onClick: () => console.log('Message Segment clicked'),
                    },
                    {
                      enabled: false,
                      divider: true,
                      icon: <BoltIcon viewBox="0 0 24 24" width="20px" height="20px" />,
                      label: 'Enroll in Automation',
                      onClick: () => console.log('Enroll in Automation clicked'),
                    },
                    {
                      enabled: false,
                      divider: true,
                      icon: <ExportPeople style={{ width: '20px', height: '20px' }} />,
                      label: 'Export people',
                      onClick: () => {
                        console.log('Export people clicked');
                      },
                    },
                    {
                      enabled: true,
                      icon: <TrashCanIcon />,
                      label: 'Delete Segment',
                      color: '#B23858',
                      onClick: () => handleDeleteSegment(row.id),
                    },
                  ];
                  return (
                    <TableRow key={index} sx={{ minHeight: '54px', height: '100%' }}>
                      <TableCell>
                        <Flex alignItems="center">
                          <SvgIcon
                            component={isStatic(row.kind) ? AppsIcon : TargetIcon}
                            sx={{ fill: 'none', margin: '8px 16px' }}
                          />
                          <RouterLink to={`/segments/${row.id}`} color="textPrimary">
                            <ListItemText
                              sx={{ maxWidth: '500px', minWidth: '280px' }}
                              primary={row.name}
                              primaryTypographyProps={{
                                variant: 'headline4',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                              secondary={row.description}
                              secondaryTypographyProps={{
                                variant: 'bodySmallest',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                            />
                          </RouterLink>
                        </Flex>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headline4">{isStatic(row.kind) ? 'Static' : 'Dynamic'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headline4">
                          <NumberChip
                            value={row.peopleCount!.toLocaleString()}
                            jasmine
                            sx={{
                              fontWeight: '400',
                              width: 'fit-content',
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Typography variant="headline4">
                            {formatDistance(new Date(row.updatedAt ?? new Date()), new Date()) + ' ago'}
                          </Typography>
                          <KebabMenu options={menuOptions} />
                        </Flex>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Column sx={{ padding: '28px' }}>{segments.loading && <CaravelSpinner />}</Column>
        </Column>
      ) : (
        <NoResults
          title="No Segments"
          message="Create your first Segment to start tracking lists of people"
          buttonText="Create Segment"
          buttonIcon={<AddIcon />}
          onButtonClick={() => history.push('/segments/new')}
        />
      )}
    </Column>
  );
});

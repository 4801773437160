import * as colors from '@caravel/utils/src/constants/colors';

// Contextual color assignments

export const PRIMARY_PALETTE = colors.COMMSOR_PURPLE;
export const SECONDARY_PALETTE = colors.HIBISCUS;
export const TERTIARY_PALETTE = colors.CHAI;
export const INFO_PALETTE = colors.BUTTERFLYPEA;
export const ERROR_PALETTE = colors.ROOBIOS;
export const WARNING_PALETTE = colors.GINGER;
export const SUCCESS_PALETTE = colors.SENCHA;

// 1 - Lightest 7 - Darkest
export const GREY_PALETTE = {
  0: colors.NEUTRALS.WHITE,
  1: colors.NEUTRALS.CHAMOMILE,
  2: colors.NEUTRALS.JASMINE,
  3: colors.NEUTRALS.IRISH_BREAKFAST,
  4: colors.NEUTRALS.EARL_GREY,
  5: colors.NEUTRALS.OOLONG,
  6: colors.NEUTRALS.ASSAM,
  7: colors.NEUTRALS.ESPRESSO,
  8: colors.NEUTRALS.CEYLON,
};

export const WARM_SHADES_PALETTE = {
  0: colors.LIGHT_GREY,
  1: colors.SECONDARY_GREY,
  2: colors.PRIMARY_GREY,
  3: colors.DARK_GREY,
  4: colors.CHARCOAL_BLACK,
};

export const MENU = {
  main: getRGBAorHex(GREY_PALETTE[6], 0.96),
  shadow: getRGBAorHex(GREY_PALETTE[6], 0.16),
};

// This color was an odd one out and was not originally accounted for in the spec
// So as of right now, it only appears in Toasts
export const TOAST = {
  main: colors.NEUTRALS.CEYLON,
};

export const SENTIMENT_SCALE_PALETTE = {
  very_positive: {
    main: colors.DARK_GREEN,
  },
  positive: {
    main: colors.LIGHT_GREEN,
  },
  neutral: {
    main: colors.YELLOW,
    light: colors.LIGHT_YELLOW,
  },
  negative: {
    main: colors.LIGHT_RED,
  },
  very_negative: {
    main: colors.RED,
  },
};

export const DEFAULT_TRANSPARENT = getRGBAorHex('#000000', 0);

export const TEAM_MEMBER_STATUS_PALETTE = {
  superUser: colors.SENCHA[200],
  contributor: colors.SENCHA[100],
  potential: colors.BUTTERFLYPEA[200],
  observer: colors.NEUTRALS.OOLONG,
  inactive: colors.NEUTRALS.IRISH_BREAKFAST,
  neutral: colors.BUTTERFLYPEA[50],
  regular: colors.BUTTERFLYPEA[400],
};

// Following method from convertingcolors.com to convert to rgba if possible
function getRGBAorHex(hex: string, alpha: number): string {
  const trimmedHex = hex.replace('#', '');
  if (!(trimmedHex.length === 6)) {
    return hex;
  }
  const regex = /.{1,2}/g;
  const hexSections = trimmedHex.match(regex);
  return `rgba(${parseInt(hexSections![0], 16)}, ${parseInt(hexSections![1], 16)}, ${parseInt(
    hexSections![2],
    16,
  )}, ${alpha})`;
}
